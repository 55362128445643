import { Node, NodeProps } from '@xyflow/react'
import { ServerAzureSubscription } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { BaseEnvironmentNode } from '../../common/baseNodes/BaseEnvironmentNode.tsx'
import { NodeGroupBadge } from '../../common/NodeGroupBadge.tsx'

export type AzureSubscriptionsNodeType = Node<{ subscriptions: ServerAzureSubscription[] }, 'azureSubscriptions'>
type AzureSubscriptionsNodeProps = NodeProps<AzureSubscriptionsNodeType>

export const AzureSubscriptionsNode = ({ data: { subscriptions } }: AzureSubscriptionsNodeProps) => {
	const badgeTooltipContent = subscriptions.map((subscription, index) => (
		<span key={subscription.subscription_id || index}>{subscription.display_name || 'Unnamed subscription'}</span>
	))

	return (
		<BaseEnvironmentNode label="Azure Subscriptions">
			<NodeGroupBadge
				count={subscriptions.length}
				nodeColorType="environment"
				tooltipContent={badgeTooltipContent}
			/>
		</BaseEnvironmentNode>
	)
}
