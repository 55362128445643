import { escapeRegExp } from 'lodash'
import { ReactNode } from 'react'

export const highlightSearchText = (text?: string | null, searchText?: string): ReactNode => {
	if (!searchText || !text) return text

	const escapedSearchText = escapeRegExp(searchText)
	const parts = text.split(new RegExp(`(${escapedSearchText})`, 'gi'))
	return parts.map((part, i) =>
		part.toLowerCase() === searchText?.toLowerCase() ? (
			<span key={i} className="bg-dsPink-400/20">
				{part}
			</span>
		) : (
			<span key={i}>{part}</span>
		),
	)
}
