import { kubernetesResourcesXc1, kubernetesResourcesXc3 } from './crossContextData.ts'
import { awsIamUserData, awsPolicies, getTagsByPolicies } from './awsIamUserData.ts'
import { MockDemoTag } from '../../schemas/tags.ts'
import { IdentityActivityStatus, IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { getRecentlyCreatedDate } from '../utils.ts'
import { commonEntraIdUserData, entraIdUserIds } from './entraIdUsersData.ts'
import { awsIamRolesXcData } from './awsIamRolesXcData.ts'
import { generateOktaHighRiskSignInUsageLogs } from './oktaUsersData.ts'
import { salesforceUserData, salesforceUserUuids } from './salesforcePrincipalData.ts'
import { githubUserData } from './githubUsersData.ts'
import { adUsersData } from './adUsersData.ts'
import { demoOauthAppsXcData } from './gwOAuthAppsXcData.ts'
import { gwWideDomainData } from './gwWideDomainData.ts'
import { atlassianUsersDataWithNoIssues } from './atlassianUsersData.ts'
import { ServerAwsIamChangeLogInput } from '../../schemas/identities/awsIamChangeLogSchema.ts'
import { DemoServerIdentityInput } from '../schemas.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { GcpResourceState } from '../../schemas/identities/gcp/gcpProjectXcSchema.ts'

const getIamRoleChangeLogsWithAccessKeys = (
	roleName: string,
	affectedArn: string,
	creator: string = 'joseph.rogers@synergy.com',
	maintainer_1: string = 'joseph.rogers@synergy.com',
	maintainer_2: string = 'elizabeth.thomas@synergy.com',
): Array<ServerAwsIamChangeLogInput> => {
	return [
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '2c4f1c35-4eb2-4473-a8a3-db169edf910b',
			event_time: '2022-02-20T07:50:51Z',
			created_at: '2022-02-20T07:50:51.546241Z',
			event_name: 'CreateRole',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${creator}`,
			affected_arn: affectedArn,
			source_ip_address: '207.23.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '78972903-598d-4f46-98c0-c1191fb161af',
			event_time: '2023-10-17T11:56:57Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_1}`,
			affected_arn: affectedArn,
			source_ip_address: '207.23.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'ae07448e-900e-4389-9314-4bf69e460295',
			event_time: '2023-10-17T11:58:03Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'CreateAccessKey',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_1}`,
			affected_arn: affectedArn,
			source_ip_address: '207.23.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '04ac177d-a465-4053-a75d-928ff037652b',
			event_time: '2023-12-31T15:07:01Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'UpdateAccessKey',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				status: 'Inactive',
				roleName,
				accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '98bd256f-7bf3-4b61-81b3-229c8dc24916',
			event_time: '2023-12-31T15:07:09Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DeleteAccessKey',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '806b98a7-2952-493a-8225-e0884423b5d6',
			event_time: '2023-12-31T15:07:26Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'CreateAccessKey',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '3f1c890c-bd11-4d62-808e-99b14d8a0eac',
			event_time: '2023-12-31T15:09:37Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'PutRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyName: 'eks-permissions',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Sid": "VisualEditor0",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "eks:*",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'a2b937e0-fc93-4db7-a9b5-4f45efa5eb88',
			event_time: '2024-03-17T14:28:02Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'd2dd9957-133c-4c98-8dfd-d2273d7ccc30',
			event_time: '2024-03-18T11:47:00Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DetachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '964d09b4-46b1-47e8-b314-02457447727a',
			event_time: '2024-03-25T21:05:41Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '46.123.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::710369796047:policy/eks-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0da',
			event_time: '2024-03-25T21:05:53Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DetachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '46.123.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
	]
}

const getIamRoleChangeLogsWithoutAccessKeys = (
	roleName: string,
	affectedArn: string,
	creator: string = 'joseph.rogers@synergy.com',
	maintainer_1: string = 'joseph.rogers@synergy.com',
	maintainer_2: string = 'elizabeth.thomas@synergy.com',
	maintainer_3: string = 'elizabeth.thomas@synergy.com',
): Array<ServerAwsIamChangeLogInput> => {
	return [
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '2c4f1c35-4eb2-4473-a8a3-db169edf910b',
			event_time: '2022-02-20T07:50:51Z',
			created_at: '2022-02-20T07:50:51.546241Z',
			event_name: 'CreateRole',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${creator}`,
			affected_arn: affectedArn,
			source_ip_address: '207.32.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '78972903-598d-4f46-98c0-c1191fb161af',
			event_time: '2023-10-17T11:56:57Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_1}`,
			affected_arn: affectedArn,
			source_ip_address: '207.32.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '3f1c890c-bd11-4d62-808e-99b14d8a0eac',
			event_time: '2023-12-31T15:09:37Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'PutRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyName: 'eks-permissions',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Sid": "VisualEditor0",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "eks:*",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'a2b937e0-fc93-4db7-a9b5-4f45efa5eb88',
			event_time: '2024-03-17T14:28:02Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'd2dd9957-133c-4c98-8dfd-d2273d7ccc30',
			event_time: '2024-03-18T11:47:00Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DetachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '5.183.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '964d09b4-46b1-47e8-b314-02457447727a',
			event_time: '2024-03-25T21:05:41Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_2}`,
			affected_arn: affectedArn,
			source_ip_address: '46.123.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::710369796047:policy/eks-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0da',
			event_time: '2024-04-01T21:05:53Z',
			created_at: '2024-04-01T15:00:09.546241Z',
			event_name: 'DetachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_3}`,
			affected_arn: affectedArn,
			source_ip_address: '46.123.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0da',
			event_time: '2024-05-19T21:05:53Z',
			created_at: '2024-05-19T15:00:09.546241Z',
			event_name: 'DetachRolePolicy',
			actor_arn: `arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/${maintainer_3}`,
			affected_arn: affectedArn,
			source_ip_address: '46.123.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				roleName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
	]
}

export const identities = {
	'2': {
		id: '2',
		literal: 'arn:aws:iam::871744574088:role/SynergisticApplications',
		literal_friendly_name: 'SynergisticApplications',
		created_at: '2015-05-14T19:32:45Z',
		updated_at: '2024-04-07T07:58:15.870478Z',
		last_activity: '2024-04-07T07:39:49Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: '871744574088',
		account_literal_friendly_name: 'SA OpsAccount',
		aws_iam_role: {
			name: 'SynergisticApplications',
			created_at: '2015-05-14T19:32:45Z',
			updated_at: '2024-03-26T05:10:46.073012Z',
			last_activity: '2024-03-26T04:50:45Z',
			role_id: 'AROAZKR1GQ5RGR8LR84V2',
			arn: 'arn:aws:iam::871744574088:role/SynergisticApplications',
			last_used_region: 'us-east-1',
			aws_iam_role_details_xc: {
				Path: '/',
				RoleName: 'SynergisticApplications',
				RoleId: 'AROAZKR1GQ5RGR8LR84V',
				Arn: 'arn:aws:iam::871744574088:role/SynergisticApplications',
				CreateDate: '2015-05-14T19:32:45Z',
				AssumeRolePolicyDocument: {
					Version: '2012-10-17',
					Statement: [
						{
							Sid: '',
							Action: 'sts:AssumeRole',
							Effect: 'Allow',
							Principal: {
								Service: 'ec2.amazonaws.com',
							},
						},
					],
				},
				RolePolicyList: [],
				AttachedManagedPolicies: [awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess],
				InstanceProfileList: [
					{
						Path: '/',
						InstanceProfileName: 'SynergisticApplications',
						InstanceProfileId: 'AIPAKSVTQSEFQP02LHR9',
						Arn: 'arn:aws:iam::871744574088:instance-profile/SynergisticApplications',
						CreateDate: '2015-05-14T19:32:45Z',
						Roles: [
							{
								Path: '/',
								RoleName: 'SynergisticApplications',
								RoleId: 'AROAZKR1GQ5RGR8LR84V',
								Arn: 'arn:aws:iam::871744574088:role/SynergisticApplications',
								CreateDate: '2015-05-14T19:32:45Z',
								AssumeRolePolicyDocument: {
									Version: '2012-10-17',
									Statement: [
										{
											Sid: '',
											Action: 'sts:AssumeRole',
											Effect: 'Allow',
											Principal: {
												Service: 'ec2.amazonaws.com',
											},
										},
									],
								},
								RolePolicyList: [],
								AttachedManagedPolicies: [],
								InstanceProfileList: [],
								RoleLastUsed: null,
								Tags: [],
								PermissionsBoundary: null,
								AccountName: null,
							},
						],
					},
				],
				RoleLastUsed: {
					LastUsedDate: '2024-04-07T07:39:49Z',
					Region: 'eu-west-1',
				},
				Tags: [
					{
						Key: 'Environment',
						Value: 'SA OpsAccount',
					},
					{
						Key: 'Team',
						Value: 'CloudOps',
					},
					{
						Key: 'OwnerEmail',
						Value: 'michael.johnson@synergy.com',
					},
				],
				PermissionsBoundary: null,
				AccountName: 'SA OpsAccount',
				AccountId: '871744574088',
			},
			aws_ec2_instances_xc: [
				{
					ImageId: 'ami-0c1e80c68c046458a',
					InstanceId: 'i-0c51becf7ee1f7388',
					InstanceType: 'r5a.2xlarge',
					LaunchTime: '2020-10-09T11:37:30Z',
					PrivateIpAddress: '172.37.36.140',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'application',
							Value: 'metal-controller',
						},
						{
							Key: 'ComponentId',
							Value: '100110',
						},
						{
							Key: 'Tags:ComponentId',
							Value: '100110',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'machine',
							Value: 'metalex',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'For',
							Value: 'Cost',
						},
						{
							Key: 'Project',
							Value: 'Metal',
						},
						{
							Key: 'Name',
							Value: 'metalx',
						},
						{
							Key: 'Team',
							Value: 'metal@synergisticapplications.com',
						},
						{
							Key: 'CreatedBy',
							Value: 'metal@synergisticapplications.com',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0c1e80c68c046458a',
					InstanceId: 'i-06d958152db38a2fb',
					InstanceType: 'r5n.large',
					LaunchTime: '2022-07-18T14:55:21Z',
					PrivateIpAddress: '172.38.18.162',
					PublicIpAddress: '53.209.167.126',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100207',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Component',
							Value: 'Pixel-validator',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Process-Exporter',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'pixel-validator-vpc',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0c1e80c68c046458a',
					InstanceId: 'i-0ce035c8f8149136a',
					InstanceType: 'r5a.large',
					LaunchTime: '2022-07-18T12:53:19Z',
					PrivateIpAddress: '172.38.41.0',
					PublicIpAddress: '34.223.194.99',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100302',
						},
						{
							Key: 'Name',
							Value: 'dosa-controller-vpc',
						},
						{
							Key: 'Process-Exporter',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Role',
							Value: 'Controller',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Project',
							Value: 'Dosa',
						},
						{
							Key: 'Component',
							Value: 'DosaController',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-0f14778ce8b6ca11f',
					InstanceType: 'c7g.2xlarge',
					LaunchTime: '2022-09-23T05:40:57Z',
					PrivateIpAddress: '172.38.17.144',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-staging-worker',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0c1e80c68c046458a',
					InstanceId: 'i-07a711e769caca27f',
					InstanceType: 'r5n.large',
					LaunchTime: '2022-12-22T08:47:20Z',
					PrivateIpAddress: '172.38.20.221',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Name',
							Value: 'pixel-validator-staging-vpc',
						},
						{
							Key: 'Component',
							Value: 'Pixel-validator',
						},
						{
							Key: 'Process-Exporter',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'ComponentId',
							Value: '100207',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-00a39e4aed8c7cd24',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-09T07:58:37Z',
					PrivateIpAddress: '172.38.20.174',
					PublicIpAddress: '3.93.63.230',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-4',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-031ef593c95cab847',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-09T17:55:19Z',
					PrivateIpAddress: '172.38.22.8',
					PublicIpAddress: '53.221.78.90',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-3',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-0eb8ca369d3f232e3',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-10T05:02:07Z',
					PrivateIpAddress: '172.38.19.45',
					PublicIpAddress: '3.80.23.161',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-1',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-01f9aade4fb92fa0e',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-10T05:17:28Z',
					PrivateIpAddress: '172.38.27.165',
					PublicIpAddress: '53.87.180.1',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-2',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-022292b5a47c83b5d',
					InstanceType: 'c7g.4xlarge',
					LaunchTime: '2023-01-24T10:11:30Z',
					PrivateIpAddress: '172.38.26.189',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Airflow',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-migration-master',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-026059c1d7b396a49',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-11T12:43:37Z',
					PrivateIpAddress: '172.38.31.214',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-replenish-cache-host',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-0ceaaa760823ea19b',
					InstanceType: 'c7g.2xlarge',
					LaunchTime: '2022-09-20T00:39:17Z',
					PrivateIpAddress: '172.38.34.91',
					PublicIpAddress: '54.91.116.7',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-staging-master',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-03642b7e87fc2d4e7',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-09T07:07:20Z',
					PrivateIpAddress: '172.38.36.170',
					PublicIpAddress: '43.208.36.94',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-7',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0c1e80c68c046458a',
					InstanceId: 'i-0ed9ac56713ba3720',
					InstanceType: 't3a.small',
					LaunchTime: '2024-01-11T11:11:03Z',
					PrivateIpAddress: '172.38.44.197',
					PublicIpAddress: '18.208.133.208',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'dosa-controller-staging-vpc',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100302',
						},
						{
							Key: 'Project',
							Value: 'Dosa',
						},
						{
							Key: 'Component',
							Value: 'DosaController',
						},
						{
							Key: 'Process-Exporter',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-0f099f96bfef64ceb',
					InstanceType: 't4g.xlarge',
					LaunchTime: '2023-02-15T19:50:09Z',
					PrivateIpAddress: '172.38.18.21',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Name',
							Value: 'analytics-schema-syncer-staging-worker-1',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-02b202411e426047e',
					InstanceType: 't4g.xlarge',
					LaunchTime: '2023-02-20T13:37:51Z',
					PrivateIpAddress: '172.38.21.154',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Name',
							Value: 'analytics-schema-syncer-staging-worker-2',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-05100eeeb639ebea2',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-02-22T08:17:48Z',
					PrivateIpAddress: '172.38.20.13',
					PublicIpAddress: '53.167.28.222',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-prod-schema-syncer-worker-1',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-0d99f2f6dd0e90717',
					InstanceType: 'c7g.2xlarge',
					LaunchTime: '2023-07-13T05:41:29Z',
					PrivateIpAddress: '172.38.21.109',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-staging-worker-2',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-0185bf328e00ef84e',
					InstanceType: 'c7g.xlarge',
					LaunchTime: '2023-06-05T18:13:08Z',
					PrivateIpAddress: '172.38.24.182',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Work',
							Value: 'POC',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-staging-migration-master_2.4.0',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-09e56635236bbad8d',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-09T14:23:50Z',
					PrivateIpAddress: '172.38.31.86',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-15',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-088f81846b71b633d',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-09T14:23:50Z',
					PrivateIpAddress: '172.38.27.39',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-16',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-0302a24eeddfac571',
					InstanceType: 'c7g.xlarge',
					LaunchTime: '2023-07-14T06:38:06Z',
					PrivateIpAddress: '172.38.26.127',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Component',
							Value: 'Airflow',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-staging-migration-master-2.6.2',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-03ecd03cbca0ad08e',
					InstanceType: 'r6g.large',
					LaunchTime: '2023-07-14T06:39:07Z',
					PrivateIpAddress: '172.38.24.48',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Component',
							Value: 'Airflow',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-staging-migration-worker-1-2.6.2',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-04fa29cd234f2c1a8',
					InstanceType: 'c7g.xlarge',
					LaunchTime: '2023-07-17T13:30:42Z',
					PrivateIpAddress: '172.38.25.228',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-staging-migration-master-2.6.2',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-08732b4818cf751bf',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-03T10:30:33Z',
					PrivateIpAddress: '172.38.22.117',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-19',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-023a4e201378b883a',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-26T15:03:50Z',
					PrivateIpAddress: '172.39.17.90',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-6',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-043da3b2807eeffa3',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-26T15:03:50Z',
					PrivateIpAddress: '172.39.19.228',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-5',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-009acd893a55b6e07',
					InstanceType: 'm6g.medium',
					LaunchTime: '2023-09-05T05:12:26Z',
					PrivateIpAddress: '172.38.3.127',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'PixelMonitor',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'ComponentId',
							Value: '100308',
						},
						{
							Key: 'Project',
							Value: 'PixelMonitor',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Name',
							Value: 'prod1.pixel-monitor',
						},
						{
							Key: 'Process-Exporter',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0a3fb1ebf18cfc9c5',
					InstanceType: 't4g.medium',
					LaunchTime: '2023-09-05T05:53:27Z',
					PrivateIpAddress: '172.38.2.222',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Process-Exporter',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'staging.pixel-monitor',
						},
						{
							Key: 'Component',
							Value: 'PixelMonitor',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Project',
							Value: 'PixelMonitor',
						},
						{
							Key: 'ComponentId',
							Value: '100308',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Environment',
							Value: 'Staging',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-05b5f78e3e4018ed8',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-03T10:27:31Z',
					PrivateIpAddress: '172.38.22.20',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-18',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0fe43a806ca60901c',
					InstanceType: 'c7g.4xlarge',
					LaunchTime: '2024-01-22T07:40:30Z',
					PrivateIpAddress: '172.38.16.205',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-master-airflow-batch-2.6.2',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0608fbd21d973c03a',
					InstanceType: 't4g.medium',
					LaunchTime: '2024-01-23T04:31:25Z',
					PrivateIpAddress: '172.38.16.184',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-prod-us-2',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-074da5c29bef806bc',
					InstanceType: 'm7g.large',
					LaunchTime: '2023-06-22T12:05:31Z',
					PrivateIpAddress: '172.38.36.11',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100312',
						},
						{
							Key: 'Project',
							Value: 'GlobalJobsPipeline',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow2.4-us-east-global-jobs-prod-worker',
						},
						{
							Key: 'Component',
							Value: 'GlobalJobsPipeline',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0b785a7124df66f9e',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-03T18:59:53Z',
					PrivateIpAddress: '172.38.28.179',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-1',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0396e802eac327227',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.26.73',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-2',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-068eabfd55d03a230',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.25.202',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-3',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0495a4ddf9a140e1b',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.31.199',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-4',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-061018d5d492288d8',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.28.160',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-5',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-074bcb3a289278afe',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.31.241',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-6',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0b9a2823318767840',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.25.146',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-7',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-06319df4fa835f5b9',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.25.204',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-8',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0466556b37b24035c',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.28.141',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-9',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0511b138dee6f3edb',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.29.53',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-10',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-06d7d4b4f2a093c35',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.27.213',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-11',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-008b60aa20c10cd65',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.31.246',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-12',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-080d1f123854ad5e1',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.26.16',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-13',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0aabbb4836c092102',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-07T05:10:23Z',
					PrivateIpAddress: '172.38.25.98',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-14',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0fff077f7d532a19f',
					InstanceType: 'r6g.large',
					LaunchTime: '2023-07-17T13:23:30Z',
					PrivateIpAddress: '172.38.27.215',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-staging-migration-worker-1-2.6.2',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01d018961e044559a',
					InstanceId: 'i-08c4240aad7ad2003',
					InstanceType: 'c7g.xlarge',
					LaunchTime: '2023-07-17T09:42:39Z',
					PrivateIpAddress: '172.38.27.219',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-staging-migration-master-2.6.2-1',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Component',
							Value: 'Airflow',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-05fb35b9c5f7b0a86',
					InstanceType: 'm6g.medium',
					LaunchTime: '2023-09-05T05:17:30Z',
					PrivateIpAddress: '172.38.28.155',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'PixelMonitor',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Project',
							Value: 'PixelMonitor',
						},
						{
							Key: 'ComponentId',
							Value: '100308',
						},
						{
							Key: 'Process-Exporter',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'prod2.pixel-monitor',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-031509bf507e9258d',
					InstanceType: 'c7g.4xlarge',
					LaunchTime: '2023-10-25T16:29:16Z',
					PrivateIpAddress: '172.38.26.210',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-master',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-06d312cddcf76ff06',
					InstanceType: 't4g.medium',
					LaunchTime: '2023-09-05T05:45:37Z',
					PrivateIpAddress: '172.38.1.123',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Name',
							Value: 'mob2.pixel-monitor',
						},
						{
							Key: 'Project',
							Value: 'PixelMonitor',
						},
						{
							Key: 'Component',
							Value: 'PixelMonitor',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'ComponentId',
							Value: '100308',
						},
						{
							Key: 'Process-Exporter',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0f115079aee075438',
					InstanceType: 't4g.medium',
					LaunchTime: '2024-01-23T04:31:25Z',
					PrivateIpAddress: '172.38.6.62',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-prod-us-1',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0d71e031bffbae77a',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-03T11:21:39Z',
					PrivateIpAddress: '172.38.45.28',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-20',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0aa478ea1148d6a2f',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-23T07:36:38Z',
					PrivateIpAddress: '172.38.0.159',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Name',
							Value: 'events-management-app-api-mob2-1',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0a6cd8f30d1b82055',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-23T19:35:12Z',
					PrivateIpAddress: '172.38.5.42',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-mob1-1',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-08aacbf6c4cadde7b',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-02-08T11:29:16Z',
					PrivateIpAddress: '172.39.23.7',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-rerun',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0658da252b995c516',
					InstanceId: 'i-0ab6e9b9164870f55',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:32:24Z',
					PrivateIpAddress: '172.38.5.12',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Name',
							Value: 'events-management-app-api-staging-us-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'Environment',
							Value: 'Staging',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01ecc15321147ce3a',
					InstanceId: 'i-07c9a93efee61b557',
					InstanceType: 'c7g.2xlarge',
					LaunchTime: '2022-12-20T10:38:06Z',
					PrivateIpAddress: '172.38.208.197',
					PublicIpAddress: '54.149.145.83',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-staging-worker',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-01ecc15321147ce3a',
					InstanceId: 'i-08b4bf77440ab0876',
					InstanceType: 'c7g.2xlarge',
					LaunchTime: '2023-01-17T18:55:26Z',
					PrivateIpAddress: '172.38.193.63',
					PublicIpAddress: '53.69.101.98',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0bc1fa1db76f5e025',
					InstanceId: 'i-0ba3db8ece70dda12',
					InstanceType: 'r5.2xlarge',
					LaunchTime: '2022-07-30T19:03:12Z',
					PrivateIpAddress: '172.38.82.52',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-worker-rerun',
						},
						{
							Key: 'machine',
							Value: 'analytics-airflow-worker-eu-rerun',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0afabf8522e6e6560',
					InstanceId: 'i-0759a406ab8ebfb7e',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-10T05:43:17Z',
					PrivateIpAddress: '172.38.85.187',
					PublicIpAddress: '3.252.137.180',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-1',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-04bbd0588645d824a',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-23T12:51:04Z',
					PrivateIpAddress: '172.38.81.90',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Component',
							Value: 'Airflow',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-migration-worker-6',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-02dbadcc256e8a9b9',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-23T12:51:04Z',
					PrivateIpAddress: '172.38.83.178',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-migration-worker-3',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Component',
							Value: 'Airflow',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0afabf8522e6e6560',
					InstanceId: 'i-0db64caab81f0badd',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-02-22T08:20:58Z',
					PrivateIpAddress: '172.38.84.23',
					PublicIpAddress: '34.245.34.63',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'analytics-schema-syncer-prod-worker-1',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0aeaae9a4a84426e7',
					InstanceId: 'i-0f252a318980849c0',
					InstanceType: 'c7g.2xlarge',
					LaunchTime: '2023-03-02T17:55:33Z',
					PrivateIpAddress: '172.38.87.120',
					PublicIpAddress: '53.155.112.243',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-staging-worker-1',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Environment',
							Value: 'Dev',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0bc1fa1db76f5e025',
					InstanceId: 'i-0690796616eb8174f',
					InstanceType: 'r5.2xlarge',
					LaunchTime: '2022-07-30T19:00:31Z',
					PrivateIpAddress: '172.38.67.58',
					PublicIpAddress: null,
					State: {
						Code: 80,
						Name: 'stopped',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Name',
							Value: 'analytics-airflow-worker-5',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'machine',
							Value: 'analytics-airflow-worker-eu-5',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0afabf8522e6e6560',
					InstanceId: 'i-0af80fa404c490c3a',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-01-10T05:45:04Z',
					PrivateIpAddress: '172.38.64.16',
					PublicIpAddress: '53.78.189.184',
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-2',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0af3b13398dcad593',
					InstanceType: 'm7g.large',
					LaunchTime: '2023-06-22T12:37:17Z',
					PrivateIpAddress: '172.38.75.245',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'ComponentId',
							Value: '100312',
						},
						{
							Key: 'Project',
							Value: 'GlobalJobsPipeline',
						},
						{
							Key: 'Component',
							Value: 'GlobalJobsPipeline',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow2.4-eu-west-global-jobs-prod-worker',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0a205bb233b59080b',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-03T15:40:37Z',
					PrivateIpAddress: '172.38.65.119',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-1',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0b0b29343edd9ebd2',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-04T16:15:52Z',
					PrivateIpAddress: '172.38.69.153',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-2',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-06d165f688068b307',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-04T16:15:52Z',
					PrivateIpAddress: '172.38.71.207',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-3',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0988b9821de1d1b44',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-04T16:15:52Z',
					PrivateIpAddress: '172.38.67.181',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-4',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-02f3fba80977860ee',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-04T16:15:52Z',
					PrivateIpAddress: '172.38.67.229',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-5',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-03b7df071de2a8250',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2023-07-04T16:15:52Z',
					PrivateIpAddress: '172.38.68.171',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-6',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-09ee4f598752d0dfb',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-22T05:00:56Z',
					PrivateIpAddress: '172.38.76.127',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-8',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0aeaae9a4a84426e7',
					InstanceId: 'i-048694912c43b31b8',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-23T04:25:10Z',
					PrivateIpAddress: '172.38.73.14',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-3',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0697e73e027cf50a7',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:32:48Z',
					PrivateIpAddress: '172.38.79.205',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-prod-eu-2',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0087e886204e01b17',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-02-08T19:04:05Z',
					PrivateIpAddress: '172.38.77.76',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Exporter2',
							Value: 'airflow-monitoring',
						},
						{
							Key: 'Name',
							Value: 'analytics-turbine-prod-worker-rerun',
						},
						{
							Key: 'Component',
							Value: 'Turbine',
						},
						{
							Key: 'ComponentId',
							Value: '100309',
						},
						{
							Key: 'Project',
							Value: 'Turbine',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0ec621cab293bd92a',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:32:58Z',
					PrivateIpAddress: '172.38.67.15',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-prod-eu-1',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-08f665a7994f33b40',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:58:53Z',
					PrivateIpAddress: '172.38.65.219',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Staging',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-staging-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0cb6586b166591a28',
					InstanceType: 't4g.micro',
					LaunchTime: '2024-02-23T19:37:06Z',
					PrivateIpAddress: '172.38.71.113',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-mob1-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-00a2750030d9347d4',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-22T04:59:43Z',
					PrivateIpAddress: '172.38.80.221',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-7',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
			change_logs: getIamRoleChangeLogsWithoutAccessKeys(
				'SynergisticApplications',
				'arn:aws:iam::871744574088:role/SynergisticApplications',
				'jessica.wilson@synergy.com',
				'christopher.brown@synergy.com',
				'emily.davis@synergy.com',
				'michael.johnson@synergy.com',
			),
		},
	},
	'24': {
		id: '24',
		literal: 'arn:aws:iam::467791917130:role/coach_EC2_Role',
		literal_friendly_name: 'coach_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROAS62GK8MP1AF8MHHK',
			name: 'coach_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-03-31T16:14:09.659276',
			arn: 'arn:aws:iam::467791917130:role/coach_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'25': {
		id: '25',
		literal: 'arn:aws:iam::467791917130:role/star_EC2_Role',
		literal_friendly_name: 'star_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROA2577HUSRMG14JA2K',
			name: 'star_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-03-12T16:14:09.659730',
			arn: 'arn:aws:iam::467791917130:role/star_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'26': {
		id: '26',
		literal: 'arn:aws:iam::467791917130:role/huge_EC2_Role',
		literal_friendly_name: 'huge_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROAP153MJBWF77NGMJ0',
			name: 'huge_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-04-01T16:14:09.660086',
			arn: 'arn:aws:iam::467791917130:role/huge_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'27': {
		id: '27',
		literal: 'arn:aws:iam::467791917130:role/western_EC2_Role',
		literal_friendly_name: 'western_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROAEE7ZXVVIWA2BQ9HB',
			name: 'western_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-03-19T16:14:09.660429',
			arn: 'arn:aws:iam::467791917130:role/western_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'28': {
		id: '28',
		literal: 'arn:aws:iam::467791917130:role/enjoy_EC2_Role',
		literal_friendly_name: 'enjoy_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROAJN7GHYPGJGSIH5V6',
			name: 'enjoy_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-03-31T16:14:09.660794',
			arn: 'arn:aws:iam::467791917130:role/enjoy_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'29': {
		id: '29',
		literal: 'arn:aws:iam::467791917130:role/investment_EC2_Role',
		literal_friendly_name: 'investment_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROAAEJOPWD0PLKBA5E9',
			name: 'investment_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-03-17T16:14:09.661191',
			arn: 'arn:aws:iam::467791917130:role/investment_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'30': {
		id: '30',
		literal: 'arn:aws:iam::467791917130:role/think_EC2_Role',
		literal_friendly_name: 'think_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROA5GPMZTLYMQMCFUGC',
			name: 'think_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-03-17T16:14:09.661543',
			arn: 'arn:aws:iam::467791917130:role/think_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'31': {
		id: '31',
		literal: 'arn:aws:iam::467791917130:role/able_EC2_Role',
		literal_friendly_name: 'able_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROACSLIVO16AP3V8CFV',
			name: 'able_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-04-07T16:14:09.661889',
			arn: 'arn:aws:iam::467791917130:role/able_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'32': {
		id: '32',
		literal: 'arn:aws:iam::467791917130:role/trip_EC2_Role',
		literal_friendly_name: 'trip_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROAQ0YWBXN29E7YES2C',
			name: 'trip_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-04-02T16:14:09.662234',
			arn: 'arn:aws:iam::467791917130:role/trip_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'33': {
		id: '33',
		literal: 'arn:aws:iam::467791917130:role/ten_EC2_Role',
		literal_friendly_name: 'ten_EC2_Role',
		created_at: '2022-06-22T13:13:04Z',
		updated_at: '2024-04-11T05:10:45.826243Z',
		last_activity: '2024-04-11T04:30:50Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			role_id: 'AROAUGGMURBU6QBAFD7L',
			name: 'ten_EC2_Role',
			created_at: '2022-06-22T13:13:04Z',
			updated_at: '2022-06-22T13:13:04.365845Z',
			last_activity: '2024-04-10T16:14:09.662582',
			arn: 'arn:aws:iam::467791917130:role/ten_EC2_Role',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'44': {
		id: '44',
		literal: 'arn:aws:iam::467791917130:user/yt-it-aws',
		literal_friendly_name: 'yt-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-03-15T17:27:16.239463',
		last_activity: '2024-03-15T17:27:16.239463',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'AdminAccessRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-03-15T17:27:16.239463',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/yt-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'45': {
		id: '45',
		literal: 'arn:aws:iam::467791917130:user/uo-it-aws',
		literal_friendly_name: 'uo-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-03-13T17:27:16.239898',
		last_activity: '2024-03-13T17:27:16.239898',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'ReadOnlyAccessRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-03-13T17:27:16.239898',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/uo-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'46': {
		id: '46',
		literal: 'arn:aws:iam::467791917130:user/gx-it-aws',
		literal_friendly_name: 'gx-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-04-05T17:27:16.240296',
		last_activity: '2024-04-05T17:27:16.240296',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'LambdaExecutionRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-04-05T17:27:16.240296',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/gx-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'47': {
		id: '47',
		literal: 'arn:aws:iam::467791917130:user/zm-it-aws',
		literal_friendly_name: 'zm-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-03-27T17:27:16.240626',
		last_activity: '2024-03-27T17:27:16.240626',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'S3DataManagementRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-03-27T17:27:16.240626',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/zm-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'48': {
		id: '48',
		literal: 'arn:aws:iam::467791917130:user/zm-it-aws',
		literal_friendly_name: 'zm-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-03-23T17:27:16.240977',
		last_activity: '2024-03-23T17:27:16.240977',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'EC2InstanceRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-03-23T17:27:16.240977',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/zm-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'49': {
		id: '49',
		literal: 'arn:aws:iam::467791917130:user/mf-it-aws',
		literal_friendly_name: 'mf-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-04-03T17:27:16.241300',
		last_activity: '2024-04-03T17:27:16.241300',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'DynamoDBAccessRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-04-03T17:27:16.241300',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/mf-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'50': {
		id: '50',
		literal: 'arn:aws:iam::467791917130:user/ft-it-aws',
		literal_friendly_name: 'ft-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-04-04T17:27:16.241657',
		last_activity: '2024-04-04T17:27:16.241657',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'CloudWatchLogsRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-04-04T17:27:16.241657',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/ft-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'51': {
		id: '51',
		literal: 'arn:aws:iam::467791917130:user/ry-it-aws',
		literal_friendly_name: 'ry-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-03-21T17:27:16.241975',
		last_activity: '2024-03-21T17:27:16.241975',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'APIGatewayInvokeRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-03-21T17:27:16.241975',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/ry-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'52': {
		id: '52',
		literal: 'arn:aws:iam::467791917130:user/vp-it-aws',
		literal_friendly_name: 'vp-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-03-26T17:27:16.242310',
		last_activity: '2024-03-26T17:27:16.242310',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'RDSBackupRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-03-26T17:27:16.242310',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/vp-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'53': {
		id: '53',
		literal: 'arn:aws:iam::467791917130:user/ba-it-aws',
		literal_friendly_name: 'ba-it-aws',
		created_at: '2016-04-18T21:57:11Z',
		updated_at: '2024-04-01T17:27:16.242626',
		last_activity: '2024-04-01T17:27:16.242626',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_role: {
			name: 'ECSExecutionRole',
			created_at: '2016-04-18T21:57:11Z',
			updated_at: '2024-03-26T05:10:46.095253Z',
			last_activity: '2024-04-01T17:27:16.242626',
			role_id: 'AROALG77UOCQU9EH8JM6',
			arn: 'arn:aws:iam::467791917130:user/ba-it-aws',
			last_used_region: 'us-east-1',
			aws_ec2_instances_xc: [
				{
					InstanceId: 'i-0k31s82yh67ac2045',
					LaunchTime: '2022-06-08T14:10:14Z',
					PrivateIpAddress: '172.35.39.173',
					PublicIpAddress: '18.153.61.153',
					State: { Code: 16, Name: 'running' },
					Tags: [
						{ Key: 'Environment', Value: 'test' },
						{
							Key: 'Roles',
							Value: 'bastion,sysdig-agent',
						},
						{ Key: 'Distro', Value: 'ubuntu' },
						{ Key: 'Project', Value: 'engops' },
						{
							Key: 'Type',
							Value: 'vm',
						},
						{ Key: 'Hostname', Value: 'prod.ops.mgmt' },
						{
							Key: 'Team',
							Value: 'engops@ops.com',
						},
						{ Key: 'Name', Value: 'engops-test-bastion' },
						{ Key: 'CreatedBy', Value: 'engops@ops.com' },
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'54': {
		id: '54',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: 'analytics_role',
		literal_friendly_name: null,
		created_at: null,
		updated_at: '2024-04-11T12:23:48.710533Z',
		last_activity: null,
		env_type: EnvironmentType.AWS,
		type: IdentityType.Hybrid,
		source: IdentitySource.POSTGRES_ROLE,
		issues: [],
		tags: [],
		account_literal: '279434620138',
		account_literal_friendly_name: 'prod',
		postgres_role: {
			rds_instance: {
				host: 'prod-app.ctivvaejrlxg.us-east-1.rds.amazonaws.com',
				db_name: 'postgres',
				region: 'us-east-1',
				instance_id: 'db-XNMOTF1SWMIG7IUPKRVIEFD5',
			},
			role_name: 'analytics_role',
			role_valid_until: null,
			is_role_superuser: false,
			can_role_create_role: false,
			can_role_create_db: false,
			can_role_login: true,
			can_role_bypass_rls: false,
			role_auto_inherits_roles: true,
			role_connections_limit: -1,
			aws_ec2_instances_xc: [
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0ec621cab293bd92a',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:32:58Z',
					PrivateIpAddress: '172.38.67.15',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-prod-eu-1',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-08f665a7994f33b40',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:58:53Z',
					PrivateIpAddress: '172.38.65.219',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Staging',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-staging-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0cb6586b166591a28',
					InstanceType: 't4g.micro',
					LaunchTime: '2024-02-23T19:37:06Z',
					PrivateIpAddress: '172.38.71.113',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-mob1-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-00a2750030d9347d4',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-22T04:59:43Z',
					PrivateIpAddress: '172.38.80.221',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-7',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
			change_logs: getIamRoleChangeLogsWithAccessKeys(
				'SynergisticApplications',
				'arn:aws:iam::871744574088:role/SynergisticApplications',
				'ashley.walker@synergy.com',
				'matthew.clark@synergy.com',
				'amanda.harrid@synergy.com',
			),
			kubernetes_resources_xc: kubernetesResourcesXc3,
		},
	},
	'55': {
		id: '55',
		literal: '0ef2f502-2e36-4de1-9038-5c2119888310',
		literal_friendly_name: 'my-daemonset',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475880',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.AWS,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:53:42Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'my-daemonset',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'56': {
		id: '56',
		literal: '87b978ae-001c-46f2-9c39-97ceecb06887',
		literal_friendly_name: 'my-statefulset',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475881',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.AWS,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:50:09Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'my-statefulset',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			data: {
				mapRoles:
					'- rolearn: arn:aws:iam::123456789012:role/role-name\n  username: system:node:{{EC2PrivateDNSName}}\n  groups:\n    - system:bootstrappers\n    - system:nodes',
				mapUsers:
					'- userarn: arn:aws:iam::123456789012:user/user-name\n  username: admin\n  groups:\n    - system:masters',
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'57': {
		id: '57',
		literal: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
		literal_friendly_name: 'ubuntu-deployment-helm',
		affected_environments: {
			AWS: [
				{
					account_db_id: '871744574088',
					account_id: '871744574088',
					account_name: 'production',
				},
			],
		},
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475882',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.GCP,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T14:42:39Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'ubuntu-deployment-helm',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
			kubernetes_resources_xc: kubernetesResourcesXc1,
			change_logs: getIamRoleChangeLogsWithAccessKeys(
				'ubuntu-deployment-helm',
				'b1774f48-8f92-466d-ac86-9b82d34d24d9',
				'joshua.lee@synergy.com',
				'sarah.anderson@synergy.com',
				'daniel.martinez@synergy.com',
			),
		},
	},
	'58': {
		id: '58',
		literal: '87b978ae-001c-46f2-9c39-97ceecb06880',
		literal_friendly_name: 'k8s-pod',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475883',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.GCP,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:50:09Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'k8s-pod',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'59': {
		id: '59',
		literal: '87b978ae-001c-46f2-9c39-97ceecb06881',
		literal_friendly_name: 'prod-pod',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475884',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.AWS,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:50:09Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'prod-pod',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'60': {
		id: '60',
		literal: '87b978ae-001c-46f2-9c39-97ceecb06882',
		literal_friendly_name: 'Prod-Account',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475885',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.GCP,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:50:09Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'Prod-Account',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'61': {
		id: '61',
		literal: '87b978ae-001c-46f2-9c39-97ceecb06883',
		literal_friendly_name: 'EA-Prod',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475886',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.AWS,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:50:09Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'EA-Prod',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'62': {
		id: '62',
		literal: '87b978ae-001c-46f2-9c39-97ceecb06884',
		literal_friendly_name: 'UA-Prod',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475887',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.AWS,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:50:09Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'UA-Prod',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'63': {
		id: '63',
		literal: '87b978ae-001c-46f2-9c39-97ceecb06885',
		literal_friendly_name: 'eu-prod',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '32804147588a',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.AWS,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:50:09Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'eu-prod',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'64': {
		id: '64',
		literal: '87b978ae-001c-46f2-9c39-97ceecb06886',
		literal_friendly_name: 'au-prod',
		last_activity: null,
		type: IdentityType.Machine,
		account_literal: '328041475889',
		account_literal_friendly_name: 'prod',
		env_type: EnvironmentType.GCP,
		source: IdentitySource.KUBERNETES_RESOURCE,
		created_at: '2024-03-11T08:50:09Z',
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		kubernetes_resource: {
			name: 'au-prod',
			cluster_literal:
				'https://container.googleapis.com/v1/projects/prod/locations/us-east1/clusters/prod-cluster',
			resource_literal: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			resource_uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			kind: 'Deployment',
			resource_metadata: {
				uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
				name: 'ubuntu-deployment',
				labels: null,
				namespace: 'prod',
				self_link: null,
				finalizers: null,
				generation: 6,
				annotations: {
					'deployment.kubernetes.io/revision': '6',
					'kubectl.kubernetes.io/last-applied-configuration':
						'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"prod"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"prod-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-02-20 07:28:10+00:00',
						manager: 'kubectl-client-side-apply',
						fields_v1: {
							'f:spec': {
								'f:replicas': {},
								'f:selector': {},
								'f:strategy': {
									'f:type': {},
									'f:rollingUpdate': {
										'.': {},
										'f:maxSurge': {},
										'f:maxUnavailable': {},
									},
								},
								'f:template': {
									'f:spec': {
										'f:volumes': {
											'.': {},
											'k:{"name":"server-secrets"}': {
												'.': {},
												'f:csi': {
													'.': {},
													'f:driver': {},
													'f:readOnly': {},
													'f:volumeAttributes': {
														'.': {},
														'f:secretProviderClass': {},
													},
												},
												'f:name': {},
											},
										},
										'f:dnsPolicy': {},
										'f:containers': {
											'k:{"name":"ubuntu-container"}': {
												'.': {},
												'f:env': {
													'.': {},
													'k:{"name":"MY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_ID"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"ACCESS_KEY_SECRET"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
													'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
														'.': {},
														'f:name': {},
														'f:valueFrom': {
															'.': {},
															'f:secretKeyRef': {},
														},
													},
												},
												'f:name': {},
												'f:image': {},
												'f:ports': {
													'.': {},
													'k:{"containerPort":8888,"protocol":"TCP"}': {
														'.': {},
														'f:protocol': {},
														'f:containerPort': {},
													},
												},
												'f:command': {},
												'f:resources': {
													'.': {},
													'f:limits': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
													'f:requests': {
														'.': {},
														'f:cpu': {},
														'f:memory': {},
													},
												},
												'f:volumeMounts': {
													'.': {},
													'k:{"mountPath":"/mnt/secrets-store"}': {
														'.': {},
														'f:name': {},
														'f:readOnly': {},
														'f:mountPath': {},
													},
												},
												'f:livenessProbe': {
													'.': {},
													'f:exec': {
														'.': {},
														'f:command': {},
													},
													'f:periodSeconds': {},
													'f:timeoutSeconds': {},
													'f:failureThreshold': {},
													'f:successThreshold': {},
													'f:initialDelaySeconds': {},
												},
												'f:imagePullPolicy': {},
												'f:terminationMessagePath': {},
												'f:terminationMessagePolicy': {},
											},
										},
										'f:restartPolicy': {},
										'f:schedulerName': {},
										'f:serviceAccount': {},
										'f:securityContext': {},
										'f:serviceAccountName': {},
										'f:terminationGracePeriodSeconds': {},
									},
									'f:metadata': {
										'f:labels': {
											'.': {},
											'f:app': {},
										},
									},
								},
								'f:revisionHistoryLimit': {},
								'f:progressDeadlineSeconds': {},
							},
							'f:metadata': {
								'f:annotations': {
									'.': {},
									'f:kubectl.kubernetes.io/last-applied-configuration': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
					{
						time: '2024-03-17 00:45:17+00:00',
						manager: 'kube-controller-manager',
						fields_v1: {
							'f:status': {
								'f:replicas': {},
								'f:conditions': {
									'.': {},
									'k:{"type":"Available"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
									'k:{"type":"Progressing"}': {
										'.': {},
										'f:type': {},
										'f:reason': {},
										'f:status': {},
										'f:message': {},
										'f:lastUpdateTime': {},
										'f:lastTransitionTime': {},
									},
								},
								'f:readyReplicas': {},
								'f:updatedReplicas': {},
								'f:availableReplicas': {},
								'f:observedGeneration': {},
							},
							'f:metadata': {
								'f:annotations': {
									'f:deployment.kubernetes.io/revision': {},
								},
							},
						},
						operation: 'Update',
						api_version: 'apps/v1',
						fields_type: 'FieldsV1',
						subresource: 'status',
					},
				],
				owner_references: null,
				resource_version: '34283614',
				creation_timestamp: '2023-10-05 09:15:30+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {
				paused: null,
				replicas: 1,
				selector: {
					match_labels: {
						app: 'ubuntu',
					},
					match_expressions: null,
				},
				strategy: {
					type: 'RollingUpdate',
					rolling_update: {
						max_surge: '25%',
						max_unavailable: '25%',
					},
				},
				template: {
					spec: {
						os: null,
						volumes: [
							{
								fc: null,
								csi: {
									driver: 'secrets-store.csi.k8s.io',
									fs_type: null,
									read_only: true,
									volume_attributes: {
										secretProviderClass: 'aws-secrets',
									},
									node_publish_secret_ref: null,
								},
								nfs: null,
								rbd: null,
								name: 'server-secrets',
								iscsi: null,
								cephfs: null,
								cinder: null,
								secret: null,
								flocker: null,
								quobyte: null,
								git_repo: null,
								scale_io: null,
								empty_dir: null,
								ephemeral: null,
								glusterfs: null,
								host_path: null,
								projected: null,
								storageos: null,
								azure_disk: null,
								azure_file: null,
								config_map: null,
								flex_volume: null,
								downward_api: null,
								vsphere_volume: null,
								portworx_volume: null,
								gce_persistent_disk: null,
								photon_persistent_disk: null,
								aws_elastic_block_store: null,
								persistent_volume_claim: null,
							},
						],
						affinity: null,
						host_ipc: null,
						host_pid: null,
						hostname: null,
						overhead: null,
						priority: null,
						node_name: null,
						subdomain: null,
						containers: [
							{
								env: [
									{
										name: 'ACCESS_KEY_ID',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'ACCESS_KEY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'secret-access-key',
												name: 'awssm-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_CLUSTER_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-cluster-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET_EXTERNAL',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'my-external-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
									{
										name: 'MY_SECRET',
										value: null,
										value_from: {
											field_ref: null,
											secret_key_ref: {
												key: 'hello',
												name: 'prod-eks-secret',
												optional: null,
											},
											config_map_key_ref: null,
											resource_field_ref: null,
										},
									},
								],
								tty: null,
								args: null,
								name: 'ubuntu-container',
								image: 'ubuntu',
								ports: [
									{
										name: null,
										host_ip: null,
										protocol: 'TCP',
										host_port: null,
										container_port: 8888,
									},
								],
								stdin: null,
								command: ['sleep', '123456'],
								env_from: null,
								lifecycle: null,
								resources: {
									claims: null,
									limits: {
										cpu: '500m',
										memory: '512Mi',
									},
									requests: {
										cpu: '250m',
										memory: '256Mi',
									},
								},
								stdin_once: null,
								working_dir: null,
								resize_policy: null,
								startup_probe: null,
								volume_mounts: [
									{
										name: 'server-secrets',
										sub_path: null,
										read_only: true,
										mount_path: '/mnt/secrets-store',
										sub_path_expr: null,
										mount_propagation: null,
									},
								],
								liveness_probe: {
									grpc: null,
									_exec: {
										command: ['ls', '/tmp'],
									},
									http_get: null,
									tcp_socket: null,
									period_seconds: 5,
									timeout_seconds: 1,
									failure_threshold: 3,
									success_threshold: 1,
									initial_delay_seconds: 5,
									termination_grace_period_seconds: null,
								},
								restart_policy: null,
								volume_devices: null,
								readiness_probe: null,
								security_context: null,
								image_pull_policy: 'Always',
								termination_message_path: '/dev/termination-log',
								termination_message_policy: 'File',
							},
						],
						dns_config: null,
						dns_policy: 'ClusterFirst',
						host_users: null,
						tolerations: null,
						host_aliases: null,
						host_network: null,
						node_selector: null,
						restart_policy: 'Always',
						scheduler_name: 'default-scheduler',
						init_containers: null,
						readiness_gates: null,
						resource_claims: null,
						service_account: 'token-service-account',
						scheduling_gates: null,
						security_context: {
							sysctls: null,
							fs_group: null,
							run_as_user: null,
							run_as_group: null,
							run_as_non_root: null,
							seccomp_profile: null,
							windows_options: null,
							se_linux_options: null,
							supplemental_groups: null,
							fs_group_change_policy: null,
						},
						preemption_policy: null,
						image_pull_secrets: null,
						runtime_class_name: null,
						priority_class_name: null,
						enable_service_links: null,
						ephemeral_containers: null,
						service_account_name: 'token-service-account',
						set_hostname_as_fqdn: null,
						active_deadline_seconds: null,
						share_process_namespace: null,
						topology_spread_constraints: null,
						automount_service_account_token: null,
						termination_grace_period_seconds: 30,
					},
					metadata: {
						uid: null,
						name: null,
						labels: {
							app: 'ubuntu',
						},
						namespace: null,
						self_link: null,
						finalizers: null,
						generation: null,
						annotations: null,
						generate_name: null,
						managed_fields: null,
						owner_references: null,
						resource_version: null,
						creation_timestamp: null,
						deletion_timestamp: null,
						deletion_grace_period_seconds: null,
					},
				},
				min_ready_seconds: null,
				revision_history_limit: 10,
				progress_deadline_seconds: 600,
			},
			status: {
				replicas: 1,
				conditions: [
					{
						type: 'Progressing',
						reason: 'NewReplicaSetAvailable',
						status: 'true',
						message: 'ReplicaSet "ubuntu-deployment-9d878fb7c" has successfully progressed.',
						last_update_time: '2024-02-20 07:28:13+00:00',
						last_transition_time: '2023-10-12 12:11:57+00:00',
					},
					{
						type: 'Available',
						reason: 'MinimumReplicasAvailable',
						status: 'true',
						message: 'Deployment has minimum availability.',
						last_update_time: '2024-03-17 00:45:17+00:00',
						last_transition_time: '2024-03-17 00:45:17+00:00',
					},
				],
				ready_replicas: 1,
				collision_count: null,
				updated_replicas: 1,
				available_replicas: 1,
				observed_generation: 6,
				unavailable_replicas: null,
			},
			access_keys: {
				pod_name: 'ubuntu-deployment-9d878fb7c-bttbx',
				secret_key: 'access-key',
				secret_name: 'awssm-secret',
				access_key_id: 'AKIAY5FH5K8HMSAVMHPT',
				container_name: 'ubuntu-container',
				is_non_secret_env: false,
				pod_owner_references: [
					{
						uid: 'ff6ce8ef-38ae-4421-9dfc-9ed64378ffef',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-9d878fb7c',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
			},
			aws_iam_users_xc: [
				{
					Path: '/',
					UserName: 'eks-user',
					UserId: 'AIDAUYYGLV4YJWLIA6HJP',
					Arn: 'arn:aws:iam::328041475888:user/eks-user',
					CreateDate: '2024-02-20T07:50:51Z',
					UserPolicyList: [awsPolicies.eks_permissions],
					GroupList: [],
					AttachedManagedPolicies: [
						awsPolicies.eks_service_account_policy_for_pods,
						awsPolicies.AmazonEC2FullAccess,
					],
					Tags: [],
					GroupListPermissions: [],
				},
			],
		},
	},
	'67': {
		id: '67',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
		literal_friendly_name: 'synergy-security-prd-connector',
		created_at: '2024-04-03T11:13:57Z',
		updated_at: '2024-04-17T07:01:20.123592Z',
		last_activity: null,
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.KUBERNETES_RESOURCE,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: '296957980053',
		account_literal_friendly_name: 'prod',
		kubernetes_resource: {
			name: 'synergy-security-prd-connector',
			cluster_literal: 'arn:aws:eks:us-east-1:296957980053:cluster/dave-prod',
			resource_literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			resource_uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			kind: 'ServiceAccount',
			resource_metadata: {
				uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
				name: 'synergy-security-prd-connector',
				labels: {
					'helm.sh/chart': 'synergy-connector-1.0.0',
					'app.kubernetes.io/version': '1.0.0',
					'app.kubernetes.io/managed-by': 'Helm',
				},
				namespace: 'synergy-security-prd',
				self_link: null,
				finalizers: null,
				generation: null,
				annotations: {
					'helm.sh/resource-policy': 'keep',
					'meta.helm.sh/release-name': 'synergy-security-prd',
					'meta.helm.sh/release-namespace': 'synergy-security-prd',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-04-03 11:13:57+00:00',
						manager: 'helm',
						fields_v1: {
							'f:metadata': {
								'f:labels': {
									'.': {},
									'f:helm.sh/chart': {},
									'f:app.kubernetes.io/version': {},
									'f:app.kubernetes.io/managed-by': {},
								},
								'f:annotations': {
									'.': {},
									'f:helm.sh/resource-policy': {},
									'f:meta.helm.sh/release-name': {},
									'f:meta.helm.sh/release-namespace': {},
								},
							},
							'f:automountServiceAccountToken': {},
						},
						operation: 'Update',
						api_version: 'v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
				],
				owner_references: null,
				resource_version: '38049189',
				creation_timestamp: '2024-04-03 11:13:57+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {},
			status: {},
		},
	},
	'75': {
		id: '75',
		literal: 'john@synergy.security',
		created_at: '2024-03-10T13:55:53Z',
		updated_at: '2024-08-24T07:01:27.445679Z',
		last_activity: '2024-08-24T15:08:21Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com',
		okta_user: {
			id: 'b35dd066-d7a3-49a2-8fb2-e6ed8c9ae9de',
			name: 'john@synergy.security',
			created_at: '2024-03-10T13:55:53Z',
			updated_at: '2024-08-24T07:01:27.445679Z',
			last_activity: '2024-08-24T07:01:27.445679Z',
			email: 'john@synergy.security',
			password_changed: '2024-03-10T14:55:56Z',
			okta_user_id: '00ufovki1byKkhGlb1d7',
			activated: '2024-03-10T13:55:54Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
			demo_usage_logs: generateOktaHighRiskSignInUsageLogs('John'),
		},
	},
	'76': {
		id: '76',
		literal: 'jennifer@synergy.security',
		created_at: '2024-03-10T13:55:53Z',
		updated_at: '2024-08-24T07:01:27.445679Z',
		last_activity: '2024-08-24T07:01:27.445679Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com',
		okta_user: {
			id: 'b35dd066-d7a3-49a2-8fb2-e6ed8c9ae9de',
			name: 'jennifer@synergy.security',
			created_at: '2024-03-10T13:55:53Z',
			updated_at: '2024-08-24T07:01:27.445679Z',
			last_activity: '2024-08-24T07:01:27.445679Z',
			email: 'jennifer@synergy.security',
			password_changed: '2024-03-10T14:55:56Z',
			okta_user_id: '00ufovki1byKkhGlb1d7',
			activated: '2024-03-10T13:55:54Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
			demo_usage_logs: generateOktaHighRiskSignInUsageLogs('Jennifer'),
		},
	},
	'77': {
		id: '77',
		literal: 'linda@synergy.security',
		created_at: '2024-03-10T13:55:53Z',
		updated_at: '2024-08-24T07:01:27.445679Z',
		last_activity: '2024-08-24T07:01:27.445679Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com',
		okta_user: {
			id: 'b35dd066-d7a3-49a2-8fb2-e6ed8c9ae9de',
			name: 'linda@synergy.security',
			created_at: '2024-03-10T13:55:53Z',
			updated_at: '2024-08-24T07:01:27.445679Z',
			last_activity: '2024-08-24T07:01:27.445679Z',
			email: 'linda@synergy.security',
			password_changed: '2024-03-10T14:55:56Z',
			okta_user_id: '00ufovki1byKkhGlb1d7',
			activated: '2024-03-10T13:55:54Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
			demo_usage_logs: generateOktaHighRiskSignInUsageLogs('Linda'),
		},
	},
	'78': {
		id: '78',
		literal: 'robert@synergy.security',
		created_at: '2024-03-10T13:55:53Z',
		updated_at: '2024-08-24T07:01:27.445679Z',
		last_activity: '2024-08-24T07:01:27.445679Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		issues: [],
		account_literal: 'https://synergy.okta.com',
		okta_user: {
			id: 'b35dd066-d7a3-49a2-8fb2-e6ed8c9ae9de',
			name: 'robert@synergy.security',
			created_at: '2024-03-10T13:55:53Z',
			updated_at: '2024-08-24T07:01:27.445679Z',
			last_activity: '2024-08-24T07:01:27.445679Z',
			email: 'robert@synergy.security',
			password_changed: '2024-03-10T14:55:56Z',
			okta_user_id: '00ufovki1byKkhGlb1d7',
			activated: '2024-03-10T13:55:54Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
			demo_usage_logs: generateOktaHighRiskSignInUsageLogs('Robert'),
		},
	},
	'79': {
		id: '79',
		literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
		literal_friendly_name: 'dwh-user',
		created_at: '2024-04-03T11:13:57Z',
		updated_at: '2024-04-17T07:01:20.123592Z',
		last_activity: null,
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.KUBERNETES_RESOURCE,
		issues: [],
		tags: [],
		account_literal: '296957980053',
		account_literal_friendly_name: 'prod',
		kubernetes_resource: {
			name: 'dwh-user',
			cluster_literal: 'arn:aws:eks:us-east-1:296957980053:cluster/dave-prod',
			resource_literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			resource_uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			kind: 'ServiceAccount',
			resource_metadata: {
				uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
				name: 'dwh-user',
				labels: {
					'helm.sh/chart': 'synergy-connector-1.0.0',
					'app.kubernetes.io/version': '1.0.0',
					'app.kubernetes.io/managed-by': 'Helm',
				},
				namespace: 'synergy-security-prd',
				self_link: null,
				finalizers: null,
				generation: null,
				annotations: {
					'helm.sh/resource-policy': 'keep',
					'meta.helm.sh/release-name': 'synergy-security-prd',
					'meta.helm.sh/release-namespace': 'synergy-security-prd',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-04-03 11:13:57+00:00',
						manager: 'helm',
						fields_v1: {
							'f:metadata': {
								'f:labels': {
									'.': {},
									'f:helm.sh/chart': {},
									'f:app.kubernetes.io/version': {},
									'f:app.kubernetes.io/managed-by': {},
								},
								'f:annotations': {
									'.': {},
									'f:helm.sh/resource-policy': {},
									'f:meta.helm.sh/release-name': {},
									'f:meta.helm.sh/release-namespace': {},
								},
							},
							'f:automountServiceAccountToken': {},
						},
						operation: 'Update',
						api_version: 'v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
				],
				owner_references: null,
				resource_version: '38049189',
				creation_timestamp: '2024-04-03 11:13:57+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {},
			status: {},
		},
	},
	'80': {
		id: '80',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
		literal_friendly_name: 'synergy-security-connector',
		created_at: '2024-04-03T11:13:57Z',
		updated_at: '2024-04-17T07:01:20.123592Z',
		last_activity: null,
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.KUBERNETES_RESOURCE,
		issues: [],
		tags: [],
		account_literal: '296957980053',
		account_literal_friendly_name: 'prod',
		kubernetes_resource: {
			name: 'synergy-security-connector',
			cluster_literal: 'arn:aws:eks:us-east-1:296957980053:cluster/dave-prod',
			resource_literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			resource_uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			kind: 'ServiceAccount',
			resource_metadata: {
				uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
				name: 'synergy-security-connector',
				labels: {
					'helm.sh/chart': 'synergy-connector-1.0.0',
					'app.kubernetes.io/version': '1.0.0',
					'app.kubernetes.io/managed-by': 'Helm',
				},
				namespace: 'synergy-security-prd',
				self_link: null,
				finalizers: null,
				generation: null,
				annotations: {
					'helm.sh/resource-policy': 'keep',
					'meta.helm.sh/release-name': 'synergy-security-prd',
					'meta.helm.sh/release-namespace': 'synergy-security-prd',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-04-03 11:13:57+00:00',
						manager: 'helm',
						fields_v1: {
							'f:metadata': {
								'f:labels': {
									'.': {},
									'f:helm.sh/chart': {},
									'f:app.kubernetes.io/version': {},
									'f:app.kubernetes.io/managed-by': {},
								},
								'f:annotations': {
									'.': {},
									'f:helm.sh/resource-policy': {},
									'f:meta.helm.sh/release-name': {},
									'f:meta.helm.sh/release-namespace': {},
								},
							},
							'f:automountServiceAccountToken': {},
						},
						operation: 'Update',
						api_version: 'v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
				],
				owner_references: null,
				resource_version: '38049189',
				creation_timestamp: '2024-04-03 11:13:57+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {},
			status: {},
		},
	},
	'81': {
		id: '81',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
		literal_friendly_name: 'synergy-security-reader',
		created_at: '2024-04-03T11:13:57Z',
		updated_at: '2024-04-17T07:01:20.123592Z',
		last_activity: null,
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.KUBERNETES_RESOURCE,
		issues: [],
		tags: [],
		account_literal: '296957980053',
		account_literal_friendly_name: 'prod',
		kubernetes_resource: {
			name: 'synergy-security-reader',
			cluster_literal: 'arn:aws:eks:us-east-1:296957980053:cluster/prod',
			resource_literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			resource_uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			kind: 'ServiceAccount',
			resource_metadata: {
				uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
				name: 'synergy-security-reader',
				labels: {
					'helm.sh/chart': 'synergy-connector-1.0.0',
					'app.kubernetes.io/version': '1.0.0',
					'app.kubernetes.io/managed-by': 'Helm',
				},
				namespace: 'synergy-security-prd',
				self_link: null,
				finalizers: null,
				generation: null,
				annotations: {
					'helm.sh/resource-policy': 'keep',
					'meta.helm.sh/release-name': 'synergy-security-prd',
					'meta.helm.sh/release-namespace': 'synergy-security-prd',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-04-03 11:13:57+00:00',
						manager: 'helm',
						fields_v1: {
							'f:metadata': {
								'f:labels': {
									'.': {},
									'f:helm.sh/chart': {},
									'f:app.kubernetes.io/version': {},
									'f:app.kubernetes.io/managed-by': {},
								},
								'f:annotations': {
									'.': {},
									'f:helm.sh/resource-policy': {},
									'f:meta.helm.sh/release-name': {},
									'f:meta.helm.sh/release-namespace': {},
								},
							},
							'f:automountServiceAccountToken': {},
						},
						operation: 'Update',
						api_version: 'v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
				],
				owner_references: null,
				resource_version: '38049189',
				creation_timestamp: '2024-04-03 11:13:57+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {},
			status: {},
		},
	},
	'82': {
		id: '82',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
		literal_friendly_name: 'synergy-security-prd-reader',
		created_at: '2024-04-03T11:13:57Z',
		updated_at: '2024-04-17T07:01:20.123592Z',
		last_activity: null,
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.KUBERNETES_RESOURCE,
		issues: [],
		tags: [],
		account_literal: '296957980053',
		account_literal_friendly_name: 'prod',
		kubernetes_resource: {
			name: 'synergy-security-prd-reader',
			cluster_literal: 'arn:aws:eks:us-east-1:296957980053:cluster/dave-prod',
			resource_literal: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			resource_uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
			kind: 'ServiceAccount',
			resource_metadata: {
				uid: '6bfe2755-a8f5-46ac-833b-ed6124602715',
				name: 'synergy-security-prd-reader',
				labels: {
					'helm.sh/chart': 'synergy-connector-1.0.0',
					'app.kubernetes.io/version': '1.0.0',
					'app.kubernetes.io/managed-by': 'Helm',
				},
				namespace: 'synergy-security-prd',
				self_link: null,
				finalizers: null,
				generation: null,
				annotations: {
					'helm.sh/resource-policy': 'keep',
					'meta.helm.sh/release-name': 'synergy-security-prd',
					'meta.helm.sh/release-namespace': 'synergy-security-prd',
				},
				generate_name: null,
				managed_fields: [
					{
						time: '2024-04-03 11:13:57+00:00',
						manager: 'helm',
						fields_v1: {
							'f:metadata': {
								'f:labels': {
									'.': {},
									'f:helm.sh/chart': {},
									'f:app.kubernetes.io/version': {},
									'f:app.kubernetes.io/managed-by': {},
								},
								'f:annotations': {
									'.': {},
									'f:helm.sh/resource-policy': {},
									'f:meta.helm.sh/release-name': {},
									'f:meta.helm.sh/release-namespace': {},
								},
							},
							'f:automountServiceAccountToken': {},
						},
						operation: 'Update',
						api_version: 'v1',
						fields_type: 'FieldsV1',
						subresource: null,
					},
				],
				owner_references: null,
				resource_version: '38049189',
				creation_timestamp: '2024-04-03 11:13:57+00:00',
				deletion_timestamp: null,
				deletion_grace_period_seconds: null,
			},
			spec: {},
			status: {},
		},
	},
	'85': {
		id: '85',
		literal: 'mila.carson@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: '16a59a9b-894a-4e26-9c18-2ce659c3bd9d',
			name: 'mila.carson@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'mila.carson@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'86': {
		id: '86',
		literal: 'kimberly.decker@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: '4a0597c3-58f1-4621-b06d-2dbac9f0ebd8',
			name: 'kimberly.decker@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'kimberly.decker@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'87': {
		id: '87',
		literal: 'jennifer.hayes@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Hybrid,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: '3b7853b9-28d8-49c7-9bbc-18cecb60ee80',
			name: 'jennifer.hayes@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'jennifer.hayes@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'88': {
		id: '88',
		literal: 'kathy.jenkins@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: 'f582195b-915c-4e10-99f2-15cd36794b0a',
			name: 'kathy.jenkins@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'kathy.jenkins@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
		activity_status: IdentityActivityStatus.INACTIVE,
	},
	'89': {
		id: '89',
		literal: 'kimberly.archer@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: '86fd3bfe-a133-496c-a5ee-90fd231b3bac',
			name: 'kimberly.archer@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'kimberly.archer@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'90': {
		id: '90',
		literal: 'dr.jennifer@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: 'b902abed-2696-4f1a-8624-7cc9da575c20',
			name: 'dr.jennifer@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'dr.jennifer@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'91': {
		id: '91',
		literal: 'joseph.rogers@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: 'c93a76c0-c9fc-460d-85df-623f30dd6f29',
			name: 'joseph.rogers@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'joseph.rogers@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'92': {
		id: '92',
		literal: 'amy.ford@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: '08059097-091e-4d41-83c4-16f7bc5c072a',
			name: 'amy.ford@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'amy.ford@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
		activity_status: IdentityActivityStatus.INACTIVE,
	},
	'93': {
		id: '93',
		literal: 'bonnie.poole@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: '396cda77-a076-4668-b03f-1fda2cbbc588',
			name: 'bonnie.poole@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'bonnie.poole@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'94': {
		id: '94',
		literal: 'william.price@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: 'd52ad9b2-4d6b-494b-97f4-eafb103a48dd',
			name: 'william.price@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'william.price@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'95': {
		id: '95',
		literal: 'mr.andrew@synergy.com',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: '8e8c8cce-b0bb-4a37-ba70-3e04f8c8f055',
			name: 'mr.andrew@synergy.com',
			created_at: '2023-05-18T14:43:32Z',
			updated_at: '2023-08-24T17:54:14Z',
			last_activity: '2023-08-24T17:54:15Z',
			email: 'mr.andrew@synergy.com',
			password_changed: '2023-08-24T17:54:14Z',
			activated: '2023-05-18T14:43:35Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'96': {
		id: '96',
		literal: 'levi.sanford@synergy.com',
		created_at: '2023-09-03T09:26:03Z',
		updated_at: '2024-04-16T13:36:34.195137Z',
		last_activity: '2023-09-03T09:27:55Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Hybrid,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: 'dc2d6a97-3fce-4e3b-82df-0d976f13ae5e',
			name: 'levi.sanford@synergy.com',
			created_at: '2023-09-03T09:26:03Z',
			updated_at: '2023-09-03T09:29:23Z',
			last_activity: '2023-09-03T09:27:55Z',
			email: 'levi.sanford@synergy.com',
			password_changed: '2023-09-03T09:26:03Z',
			activated: '2023-09-03T09:26:04Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
		},
	},
	'97': {
		id: '97',
		literal: 'elizabeth.coleman@synergy.com',
		created_at: '2020-06-24T14:32:40Z',
		updated_at: '2024-08-28T07:01:19.639651Z',
		last_activity: '2024-08-28T07:01:19.639651Z',
		env_type: EnvironmentType.OKTA,
		type: IdentityType.Human,
		source: IdentitySource.OKTA,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'https://synergy.okta.com/',
		okta_user: {
			id: 'e8684748-2bb7-4cce-bc29-628e9a1a30f6',
			name: 'elizabeth.coleman@synergy.com',
			created_at: '2020-06-24T14:32:40Z',
			updated_at: '2024-03-25T10:00:53Z',
			last_activity: '2024-04-15T11:29:45Z',
			email: 'elizabeth.coleman@synergy.com',
			password_changed: '2024-03-25T10:00:53Z',
			activated: '2020-06-24T14:32:40Z',
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
			demo_usage_logs: generateOktaHighRiskSignInUsageLogs('Elizabeth Coleman'),
		},
	},
	'98': {
		id: '98',
		literal: 'dashboard@synergy.com',
		created_at: '2023-01-31T14:15:33.799000Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Dashboard User',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'dashboard@synergy.com',
			user_id: '61efadacaeacaa1df90ff4cf',
			mfa: {
				configured: false,
				exclusion: false,
				exclusion_until: null,
			},
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['1']],
			gcp_projects_xc: [
				{
					account_priority: 2.5,
					project: {
						name: 'projects/726594381072',
						parent: 'folders/829485612379',
						projectId: 'analytics-111115',
						state: GcpResourceState.ACTIVE,
						displayName: 'Analytics',
						createTime: '2024-03-08T15:17:01.694000Z',
						updateTime: '2024-03-08T15:17:01.694000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/resourcemanager.projectCreator', 'roles/resourcemanager.organizationAdmin'],
				},
				{
					account_priority: 2.5,
					project: {
						name: 'projects/538194725631',
						parent: 'folders/528492167453',
						projectId: 'disc-prj-grossmargin',
						state: GcpResourceState.ACTIVE,
						displayName: 'DISC-PRJ-GrossMargin',
						createTime: '2022-09-09T14:52:29.209000Z',
						updateTime: '2022-09-23T17:10:13.605000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/resourcemanager.projectCreator', 'roles/resourcemanager.organizationAdmin'],
				},
				{
					account_priority: 2.5,
					project: {
						name: 'projects/26187913541',
						parent: 'folders/307713099790',
						projectId: 'discovery-magento-dev',
						state: GcpResourceState.ACTIVE,
						displayName: 'discovery-magento-dev',
						createTime: '2024-04-22T09:58:44.556000Z',
						updateTime: '2024-04-22T09:58:44.556000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/resourcemanager.projectCreator', 'roles/resourcemanager.organizationAdmin'],
				},
				{
					account_priority: 2.5,
					project: {
						name: 'projects/908406820878',
						parent: 'folders/307713099790',
						projectId: 'nvidia-models-test',
						state: GcpResourceState.ACTIVE,
						displayName: 'nvidia-models-test',
						createTime: '2024-06-10T14:06:40.799000Z',
						updateTime: '2024-06-10T14:06:40.799000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/resourcemanager.projectCreator', 'roles/resourcemanager.organizationAdmin'],
				},
			],
		},
	},
	'99': {
		id: '99',
		literal: 'robert.perez@synergy.com',
		created_at: '2023-01-28T17:00:38.448409Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Robert Perez',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'robert.perez@synergy.com',
			user_id: '61dfdf571bacb5ac0f1ebf5e',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'100': {
		id: '100',
		literal: 'brandon.huff@synergy.com',
		created_at: '2023-04-02T13:38:08.118339Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Brandon Huff',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'brandon.huff@synergy.com',
			user_id: '613ca3b404fa05ef7e61818e',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'101': {
		id: '101',
		literal: 'autumn.beard@synergy.com',
		created_at: '2023-02-07T20:00:27.677930Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Autumn Beard',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'autumn.beard@synergy.com',
			user_id: '61da8ea8013f6faadaf1ec6c',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'102': {
		id: '102',
		literal: 'matthew.jones@synergy.com',
		created_at: '2023-07-13T02:11:49.621086Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Matthew Jones',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'matthew.jones@synergy.com',
			user_id: '61dc0bc36f1afeaa6b8bada1',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'103': {
		id: '103',
		literal: 'mr.albert@synergy.com',
		created_at: '2023-06-24T11:53:22.302623Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Mr. Albert Burke MD',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'mr.albert@synergy.com',
			user_id: '61bf841ba445be6abbb21bab',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'104': {
		id: '104',
		literal: 'michael.morris@synergy.com',
		created_at: '2023-01-13T03:49:26.188869Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Michael Morris',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'michael.morris@synergy.com',
			user_id: '617f1beb12219ce676dea31f',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'105': {
		id: '105',
		literal: 'brian.mitchell@synergy.com',
		created_at: '2023-05-23T17:41:14.815837Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Brian Mitchell',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'brian.mitchell@synergy.com',
			user_id: '61fbbe868baa4fbe50bdb5ae',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'106': {
		id: '106',
		literal: 'alec.adams@synergy.com',
		created_at: '2023-04-03T04:34:20.513868Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Alec Adams',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'alec.adams@synergy.com',
			user_id: '61deb387e04e733d76bbeb6d',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'107': {
		id: '107',
		literal: 'sean.barber@synergy.com',
		created_at: '2023-04-11T16:12:56.623585Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Sean Barber',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'sean.barber@synergy.com',
			user_id: '61c6eafcdb92ee59a1bcfa14',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'108': {
		id: '108',
		literal: 'ian.summers@synergy.com',
		created_at: '2023-04-05T00:25:13.792931Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Ian Summers',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'ian.summers@synergy.com',
			user_id: '618e1c64aae35f58bd9d90a3',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'109': {
		id: '109',
		literal: 'logan.reyes@synergy.com',
		created_at: '2023-07-12T22:42:56.253863Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Logan Reyes',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'logan.reyes@synergy.com',
			user_id: '6114f7fac1593fa6caac51aa',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'110': {
		id: '110',
		literal: 'scott.howell@synergy.com',
		created_at: '2023-02-18T22:48:23.506221Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Scott Howell',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'scott.howell@synergy.com',
			user_id: '61ab421b5dade1dfc40cba2a',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'111': {
		id: '111',
		literal: 'john.miranda@synergy.com',
		created_at: '2023-02-20T10:03:25.022938Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'John Miranda',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'john.miranda@synergy.com',
			user_id: '61e2041fa92f714d440e5ce1',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'112': {
		id: '112',
		literal: 'joseph.larsen@synergy.com',
		created_at: '2023-07-20T23:53:21.475333Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Joseph Larsen',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'joseph.larsen@synergy.com',
			user_id: '619dcaefdb0fdffaa2bad1d6',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'113': {
		id: '113',
		literal: 'kathleen.willis@synergy.com',
		created_at: '2023-06-06T01:51:01.473965Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Kathleen Willis',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'kathleen.willis@synergy.com',
			user_id: '61046447e501f813a9d64bfa',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'114': {
		id: '114',
		literal: 'erin.ellis@synergy.com',
		created_at: '2023-07-05T06:03:19.657750Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Erin Ellis',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'erin.ellis@synergy.com',
			user_id: '61a5bbbbd1d8f91e7bc9957c',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'115': {
		id: '115',
		literal: 'troy.young@synergy.com',
		created_at: '2023-05-19T14:17:30.629345Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Troy Young',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'troy.young@synergy.com',
			user_id: '61ec32fa63f8d170bed44ddd',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'116': {
		id: '116',
		literal: 'john.bradley@synergy.com',
		created_at: '2023-04-03T00:13:46.092414Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'John Bradley',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'john.bradley@synergy.com',
			user_id: '61315235a8cddef3eeeb8aeb',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'117': {
		id: '117',
		literal: 'mary.smith@synergy.com',
		created_at: '2023-07-16T09:44:42.751838Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Mary Smith',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'mary.smith@synergy.com',
			user_id: '61ae38bb8c41f2dbdbfd45dd',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'118': {
		id: '118',
		literal: 'rachel.ferguson@synergy.com',
		created_at: '2023-03-18T22:18:57.219587Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Rachel Ferguson',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'rachel.ferguson@synergy.com',
			user_id: '616ce999df97bddde9d57978',
			mfa: { configured: false, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'119': {
		id: '119',
		literal: entraIdUserIds.AMY_ALEXANDER,
		literal_friendly_name: commonEntraIdUserData.AMY_ALEXANDER.mail,
		created_at: '2018-05-26T01:58:48.000000Z',
		updated_at: '2024-04-04T05:13:52.000000Z',
		last_activity: '2024-04-20T23:54:27.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.AMY_ALEXANDER,
	},
	'120': {
		id: '120',
		literal: entraIdUserIds.LAURA_ZAMORA,
		literal_friendly_name: commonEntraIdUserData.LAURA_ZAMORA.mail,
		created_at: '2019-05-02T10:32:44.000000Z',
		updated_at: '2024-03-10T05:45:09.000000Z',
		last_activity: '2024-04-20T10:11:42.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.LAURA_ZAMORA,
	},
	'121': {
		id: '121',
		literal: entraIdUserIds.MARY_CHAPMAN,
		literal_friendly_name: commonEntraIdUserData.MARY_CHAPMAN.mail,
		created_at: '2019-05-03T04:05:39.000000Z',
		updated_at: '2024-02-13T16:02:19.000000Z',
		last_activity: '2024-04-20T20:53:24.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.MARY_CHAPMAN,
	},
	'122': {
		id: '122',
		literal: entraIdUserIds.PRISCILLA_DAVIS,
		literal_friendly_name: commonEntraIdUserData.PRISCILLA_DAVIS.mail,
		created_at: '2020-06-10T02:27:52.000000Z',
		updated_at: '2024-01-14T03:16:28.000000Z',
		last_activity: '2024-04-20T04:19:51.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.PRISCILLA_DAVIS,
	},
	'123': {
		id: '123',
		literal: entraIdUserIds.ELIZABETH_TRUJILLO,
		literal_friendly_name: commonEntraIdUserData.ELIZABETH_TRUJILLO.mail,
		created_at: '2020-01-27T10:03:10.000000Z',
		updated_at: '2024-04-09T22:29:33.000000Z',
		last_activity: '2024-04-20T21:47:06.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.ELIZABETH_TRUJILLO,
	},
	'124': {
		id: '124',
		literal: entraIdUserIds.BRIAN_ESPINOZA,
		literal_friendly_name: commonEntraIdUserData.BRIAN_ESPINOZA.mail,
		created_at: '2018-10-18T22:23:56.000000Z',
		updated_at: '2024-01-08T08:31:20.000000Z',
		last_activity: '2024-04-20T18:28:43.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.BRIAN_ESPINOZA,
	},
	'125': {
		id: '125',
		literal: entraIdUserIds.MARIA_GREEN,
		literal_friendly_name: commonEntraIdUserData.MARIA_GREEN.mail,
		created_at: '2018-06-10T07:26:17.000000Z',
		updated_at: '2024-01-09T21:22:45.000000Z',
		last_activity: '2024-04-20T03:18:56.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.MARIA_GREEN,
	},
	'126': {
		id: '126',
		literal: entraIdUserIds.CHARLES_CARROLL,
		literal_friendly_name: commonEntraIdUserData.CHARLES_CARROLL.mail,
		created_at: '2019-08-02T19:26:52.000000Z',
		updated_at: '2024-01-13T10:23:36.000000Z',
		last_activity: '2024-04-20T07:45:34.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.CHARLES_CARROLL,
	},
	'127': {
		id: '127',
		literal: entraIdUserIds.DEVIN_VANCE,
		literal_friendly_name: commonEntraIdUserData.DEVIN_VANCE.mail,
		created_at: '2019-11-06T08:18:39.000000Z',
		updated_at: '2024-01-17T18:17:28.000000Z',
		last_activity: '2024-04-20T16:11:42.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.DEVIN_VANCE,
	},
	'128': {
		id: '128',
		literal: entraIdUserIds.JOHN_BEARD,
		literal_friendly_name: commonEntraIdUserData.JOHN_BEARD.mail,
		created_at: '2018-03-07T02:24:59.000000Z',
		updated_at: '2024-01-22T01:11:42.000000Z',
		last_activity: '2024-04-20T17:48:02.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.JOHN_BEARD,
	},
	'139': {
		id: '139',
		literal: entraIdUserIds.GEORGE_BROWN,
		literal_friendly_name: commonEntraIdUserData.GEORGE_BROWN.mail,
		created_at: '2018-01-21T12:32:36.000000Z',
		updated_at: '2023-03-27T22:13:07.000000Z',
		last_activity: '2023-03-27T22:13:07.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		affected_environments: {
			AWS: [
				{
					account_db_id: '871744574088',
					account_id: '871744574088',
					account_name: 'production',
				},
			],
			ACTIVE_DIRECTORY: [
				{
					account_db_id: 'S-1-5-21-3623811015-3361044348-30300820',
					account_id: 'S-1-5-21-3623811015-3361044348-30300820',
					account_name: 'SYNERGY.COM',
				},
			],
		},
		entra_id_user: {
			...commonEntraIdUserData.GEORGE_BROWN,
			active_directory_user: adUsersData.GEORGE_BROWN,
		},
		activity_status: IdentityActivityStatus.INACTIVE,
	},
	'140': {
		id: '140',
		literal: entraIdUserIds.JOSEPH_VILLANUEVA,
		literal_friendly_name: commonEntraIdUserData.JOSEPH_VILLANUEVA.mail,
		created_at: '2018-08-19T20:55:29.000000Z',
		updated_at: '2023-12-22T16:45:01.000000Z',
		last_activity: '2023-12-22T16:45:01.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		affected_environments: {
			AWS: [
				{
					account_db_id: '871744574088',
					account_id: '871744574088',
					account_name: 'production',
				},
			],
			ACTIVE_DIRECTORY: [
				{
					account_db_id: 'S-1-5-21-3623811015-3361044348-30300820',
					account_id: 'S-1-5-21-3623811015-3361044348-30300820',
					account_name: 'SYNERGY.COM',
				},
			],
		},
		entra_id_user: {
			...commonEntraIdUserData.JOSEPH_VILLANUEVA,
			active_directory_user: adUsersData.JOSEPH_VILLANUEVA,
		},
		activity_status: IdentityActivityStatus.INACTIVE,
	},
	'141': {
		id: '141',
		literal: entraIdUserIds.ELIZABETH_THOMAS,
		literal_friendly_name: commonEntraIdUserData.ELIZABETH_THOMAS.mail,
		created_at: '2020-10-29T21:26:15.000000Z',
		updated_at: '2024-02-19T03:53:36.000000Z',
		last_activity: '2024-04-01T19:28:47.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		affected_environments: {
			AWS: [
				{
					account_db_id: '871744574088',
					account_id: '871744574088',
					account_name: 'production',
				},
			],
		},
		entra_id_user: commonEntraIdUserData.ELIZABETH_THOMAS,
	},
	'142': {
		id: '142',
		literal: entraIdUserIds.DERRICK_MORRIS,
		literal_friendly_name: commonEntraIdUserData.DERRICK_MORRIS.mail,
		created_at: '2020-12-02T09:13:20.000000Z',
		updated_at: '2024-04-13T10:03:32.000000Z',
		last_activity: '2024-04-07T19:07:50.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.DERRICK_MORRIS,
	},
	'143': {
		id: '143',
		literal: entraIdUserIds.MELISSA_NGUYEN,
		literal_friendly_name: commonEntraIdUserData.MELISSA_NGUYEN.mail,
		created_at: '2019-07-14T03:43:03.000000Z',
		updated_at: '2024-01-19T03:31:11.000000Z',
		last_activity: '2024-04-07T17:24:22.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.MELISSA_NGUYEN,
	},
	'144': {
		id: '144',
		literal: entraIdUserIds.BRETT_JACKSON,
		literal_friendly_name: commonEntraIdUserData.BRETT_JACKSON.mail,
		created_at: '2019-08-20T19:39:25.000000Z',
		updated_at: '2024-03-03T14:05:46.000000Z',
		last_activity: '2024-04-07T07:26:47.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.BRETT_JACKSON,
	},
	'145': {
		id: '145',
		literal: entraIdUserIds.AARON_HUBBARD,
		literal_friendly_name: commonEntraIdUserData.AARON_HUBBARD.mail,
		created_at: '2020-09-20T14:01:04.000000Z',
		updated_at: '2024-01-15T19:18:06.000000Z',
		last_activity: '2024-04-06T03:34:22.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.AARON_HUBBARD,
	},
	'146': {
		id: '146',
		literal: entraIdUserIds.RAYMOND_MARKS,
		literal_friendly_name: commonEntraIdUserData.RAYMOND_MARKS.mail,
		created_at: '2020-01-24T07:02:32.000000Z',
		updated_at: '2024-01-14T13:48:56.000000Z',
		last_activity: '2024-04-07T06:17:46.000000Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.RAYMOND_MARKS,
	},
	'147': {
		id: '147',
		literal: 'jason.underwood@synergy.com',
		created_at: '2023-07-31T00:33:27.721732Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Jason Underwood PhD',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'jason.underwood@synergy.com',
			user_id: '61b30dbce71efccdbd4ece9e',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'148': {
		id: '148',
		literal: 'jonathan.kaiser@synergy.com',
		created_at: '2023-06-16T00:57:49.416026Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Jonathan Kaiser',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'jonathan.kaiser@synergy.com',
			user_id: '61dfa1cfec1efa9174ee0fad',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'149': {
		id: '149',
		literal: 'tanner.taylor@synergy.com',
		created_at: '2023-03-10T04:56:20.442047Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Tanner Taylor',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'tanner.taylor@synergy.com',
			user_id: '61d34afc3bb4baef320c3eaa',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'150': {
		id: '150',
		literal: 'andrew.mcdonald@synergy.com',
		created_at: '2023-04-01T20:28:21.988278Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Andrew Mcdonald',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'andrew.mcdonald@synergy.com',
			user_id: '61b7fb934efe9c32ea5872bc',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'151': {
		id: '151',
		literal: 'michael.casey@synergy.com',
		created_at: '2023-03-17T04:12:23.895217Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Michael Casey',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'michael.casey@synergy.com',
			user_id: '613bf09fcfcf75021bff4aaf',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'152': {
		id: '152',
		literal: 'ethan.jacobs@synergy.com',
		created_at: '2023-03-29T00:13:53.745918Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Ethan Jacobs',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'ethan.jacobs@synergy.com',
			user_id: '612a1f06d18ffc4d2f2cafd2',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'153': {
		id: '153',
		literal: 'mrs.megan@synergy.com',
		created_at: '2023-05-01T17:49:52.140231Z',
		updated_at: '2024-04-18T05:17:38.172259Z',
		env_type: EnvironmentType.JUMPCLOUD,
		type: IdentityType.Human,
		source: IdentitySource.JUMPCLOUD_USER,
		tags: getTagsByPolicies([awsPolicies.IAMFullAccess]),
		account_literal: '6110ef9a92b04899e1536eb9',
		jumpcloud_user: {
			name: 'Mrs. Megan Richards MD',
			created_at: '2023-01-31T14:15:33.799000Z',
			email: 'mrs.megan@synergy.com',
			user_id: '617c13acec022ff4e92ad483',
			mfa: { configured: true, exclusion: false, exclusion_until: null },
			account_locked: false,
			suspended: false,
			aws_iam_roles_xc: [awsIamRolesXcData['2']],
		},
	},
	'154': {
		id: '154',
		literal: 'travis.williams@synergy.com',
		created_at: '2020-11-25T05:18:35.000000Z',
		updated_at: '2023-04-06T05:09:22.000000Z',
		last_activity: '2023-04-06T05:09:22.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '056d523d-31cf-4fa9-b69c-97bf760b30e0',
			name: 'Automation Service',
			created_at: '2020-11-25T05:18:35.000000Z',
			updated_at: '2023-04-06T05:09:22.000000Z',
			last_activity: '2023-04-06T05:09:22.000000Z',
			primary_email: 'travis.williams@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'155': {
		id: '155',
		literal: 'dean.jones@synergy.com',
		created_at: '2019-03-06T04:36:21.000000Z',
		updated_at: '2023-05-02T09:55:27.000000Z',
		last_activity: '2023-05-02T09:55:27.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '7a996360-7a4d-4ef1-884f-d5ac71fbcb0b',
			name: 'Automation Service',
			created_at: '2019-03-06T04:36:21.000000Z',
			updated_at: '2023-05-02T09:55:27.000000Z',
			last_activity: '2023-05-02T09:55:27.000000Z',
			primary_email: 'dean.jones@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'156': {
		id: '156',
		literal: 'martha.avery@synergy.com',
		created_at: '2019-01-11T22:32:28.000000Z',
		updated_at: '2023-05-23T20:03:42.000000Z',
		last_activity: '2023-05-23T20:03:42.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '820ae59c-3620-403f-87c2-a668e9920adc',
			name: 'Automation Service',
			created_at: '2019-01-11T22:32:28.000000Z',
			updated_at: '2023-05-23T20:03:42.000000Z',
			last_activity: '2023-05-23T20:03:42.000000Z',
			primary_email: 'martha.avery@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'157': {
		id: '157',
		literal: 'anthony.dalton@synergy.com',
		created_at: '2018-02-14T13:32:04.000000Z',
		updated_at: '2023-05-24T21:38:17.000000Z',
		last_activity: '2023-05-24T21:38:17.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '2118bb65-8de9-4bbb-93c7-d99807b7fae7',
			name: 'Automation Service',
			created_at: '2018-02-14T13:32:04.000000Z',
			updated_at: '2023-05-24T21:38:17.000000Z',
			last_activity: '2023-05-24T21:38:17.000000Z',
			primary_email: 'anthony.dalton@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'158': {
		id: '158',
		literal: 'dr.chelsea@synergy.com',
		created_at: '2018-05-07T06:35:06.000000Z',
		updated_at: '2023-05-09T02:07:30.000000Z',
		last_activity: '2023-05-09T02:07:30.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '499a3569-3e88-4c5c-9283-b2e720c8e032',
			name: 'Automation Service',
			created_at: '2018-05-07T06:35:06.000000Z',
			updated_at: '2023-05-09T02:07:30.000000Z',
			last_activity: '2023-05-09T02:07:30.000000Z',
			primary_email: 'dr.chelsea@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'159': {
		id: '159',
		literal: 'jacqueline.thomas@synergy.com',
		created_at: '2019-02-02T23:46:49.000000Z',
		updated_at: '2023-05-06T04:34:36.000000Z',
		last_activity: '2023-05-06T04:34:36.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: 'eb59adde-80fc-498c-9751-0c7ccfb3bace',
			name: 'Automation Service',
			created_at: '2019-02-02T23:46:49.000000Z',
			updated_at: '2023-05-06T04:34:36.000000Z',
			last_activity: '2023-05-06T04:34:36.000000Z',
			primary_email: 'jacqueline.thomas@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'160': {
		id: '160',
		literal: 'dennis.young@synergy.com',
		created_at: '2020-07-26T22:41:03.000000Z',
		updated_at: '2023-05-22T16:51:55.000000Z',
		last_activity: '2023-05-22T16:51:55.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '1eebb8b3-0738-4415-9fbe-b73ea616ab10',
			name: 'Automation Service',
			created_at: '2020-07-26T22:41:03.000000Z',
			updated_at: '2023-05-22T16:51:55.000000Z',
			last_activity: '2023-05-22T16:51:55.000000Z',
			primary_email: 'dennis.young@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'161': {
		id: '161',
		literal: 'jennifer.clark@synergy.com',
		created_at: '2018-01-08T12:53:02.000000Z',
		updated_at: '2023-03-04T21:48:14.000000Z',
		last_activity: '2023-03-04T21:48:14.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '61e744fb-715a-49e6-962d-59fe00a600ec',
			name: 'Automation Service',
			created_at: '2018-01-08T12:53:02.000000Z',
			updated_at: '2023-03-04T21:48:14.000000Z',
			last_activity: '2023-03-04T21:48:14.000000Z',
			primary_email: 'jennifer.clark@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'162': {
		id: '162',
		literal: 'brittany.martinez@synergy.com',
		created_at: '2018-02-11T11:40:46.000000Z',
		updated_at: '2023-02-03T05:49:28.000000Z',
		last_activity: '2023-02-03T05:49:28.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '6c5d1802-6c2a-4a5a-a150-4ee231a50d68',
			name: 'Automation Service',
			created_at: '2018-02-11T11:40:46.000000Z',
			updated_at: '2023-02-03T05:49:28.000000Z',
			last_activity: '2023-02-03T05:49:28.000000Z',
			primary_email: 'brittany.martinez@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'163': {
		id: '163',
		literal: 'kristen.howard@synergy.com',
		created_at: '2020-09-21T14:34:27.000000Z',
		updated_at: '2023-01-11T01:39:15.000000Z',
		last_activity: '2023-01-11T01:39:15.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '1f9af7d1-de34-49b3-b802-6782604d0d7a',
			name: 'Automation Service',
			created_at: '2020-09-21T14:34:27.000000Z',
			updated_at: '2023-01-11T01:39:15.000000Z',
			last_activity: '2023-01-11T01:39:15.000000Z',
			primary_email: 'kristen.howard@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: true,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'164': {
		id: '164',
		literal: 'james.smith@synergy.com',
		created_at: '2019-04-25T16:59:46.000000Z',
		updated_at: '2023-04-08T09:56:00.000000Z',
		last_activity: '2024-04-18T07:02:36.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: 'f12f9e64-d9ad-4d91-9512-8a326bc10693',
			name: 'Automation Service',
			created_at: '2019-04-25T16:59:46.000000Z',
			updated_at: '2023-04-08T09:56:00.000000Z',
			last_activity: '2024-04-18T07:02:36.000000Z',
			primary_email: 'james.smith@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: false,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
			demo_oauth_apps_xc: [demoOauthAppsXcData['1'], demoOauthAppsXcData['2'], demoOauthAppsXcData['3']],
		},
	},
	'165': {
		id: '165',
		literal: 'shannon.chang@synergy.com',
		created_at: getRecentlyCreatedDate(),
		updated_at: getRecentlyCreatedDate(),
		last_activity: getRecentlyCreatedDate(),
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '9910db8a-774a-46c3-821c-740603becb0a',
			name: 'Automation Service',
			created_at: getRecentlyCreatedDate(),
			updated_at: getRecentlyCreatedDate(),
			last_activity: getRecentlyCreatedDate(),
			primary_email: 'shannon.chang@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: false,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
			demo_oauth_apps_xc: [demoOauthAppsXcData['1'], demoOauthAppsXcData['4']],
		},
	},
	'166': {
		id: '166',
		literal: 'william.vargas@synergy.com',
		created_at: '2018-06-22T19:26:33.000000Z',
		updated_at: '2023-02-27T06:21:05.000000Z',
		last_activity: '2024-04-19T03:39:14.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '2c1888aa-09d3-4608-8703-18fa0cb8e08f',
			name: 'Automation Service',
			created_at: '2018-06-22T19:26:33.000000Z',
			updated_at: '2023-02-27T06:21:05.000000Z',
			last_activity: '2024-04-19T03:39:14.000000Z',
			primary_email: 'william.vargas@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: false,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'167': {
		id: '167',
		literal: 'hector.middleton@synergy.com',
		created_at: '2019-08-12T22:52:22.000000Z',
		updated_at: '2023-03-16T15:15:02.000000Z',
		last_activity: '2024-04-18T12:59:58.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '9885649b-11d5-4ad6-a0f2-21a290dceefb',
			name: 'Automation Service',
			created_at: '2019-08-12T22:52:22.000000Z',
			updated_at: '2023-03-16T15:15:02.000000Z',
			last_activity: '2024-04-18T12:59:58.000000Z',
			primary_email: 'hector.middleton@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: false,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'168': {
		id: '168',
		literal: 'gary.hansen@synergy.com',
		created_at: '2019-08-20T19:59:51.000000Z',
		updated_at: '2023-04-08T07:47:31.000000Z',
		last_activity: '2024-04-02T19:21:50.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: '384b261d-ab7e-4900-bd8b-aa51d850cde7',
			name: 'Automation Service',
			created_at: '2019-08-20T19:59:51.000000Z',
			updated_at: '2023-04-08T07:47:31.000000Z',
			last_activity: '2024-04-02T19:21:50.000000Z',
			primary_email: 'gary.hansen@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: false,
			recovery_email: '',
			recovery_phone: '',
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
		},
	},
	'169': {
		id: '169',
		literal: 'ms.shelby@synergy.com',
		created_at: '2020-10-09T08:09:43.000000Z',
		updated_at: '2023-05-05T16:54:43.000000Z',
		last_activity: '2023-05-05T16:54:43.000000Z',
		env_type: EnvironmentType.GOOGLE_WORKSPACE,
		type: IdentityType.Human,
		source: IdentitySource.GOOGLE_WORKSPACE,
		account_literal: 'C03o59ber',
		google_workspace_user: {
			id: 'c69d7556-853b-4008-b6a4-4aeb30c63bf6',
			name: 'Automation Service',
			created_at: '2020-10-09T08:09:43.000000Z',
			updated_at: '2023-05-05T16:54:43.000000Z',
			last_activity: '2023-05-05T16:54:43.000000Z',
			primary_email: 'ms.shelby@synergy.com',
			is_admin: false,
			suspended: false,
			archived: false,
			google_workspace_customer_id: 'C03o59ber',
			mfa_enabled: false,
			recovery_email: '',
			recovery_phone: '',
			aws_iam_roles_xc: [awsIamRolesXcData['3']],
			demo_domain_wide_delegation: [gwWideDomainData['1'], gwWideDomainData['2'], gwWideDomainData['3']],
			demo_oauth_apps_xc: [
				demoOauthAppsXcData['1'],
				demoOauthAppsXcData['2'],
				demoOauthAppsXcData['3'],
				demoOauthAppsXcData['4'],
			],
		},
	},
	'171': {
		id: '171',
		literal: 'arn:aws:iam::782968362597:role/OrganizationAccountAccessRole',
		literal_friendly_name: 'OrganizationAccountAccessRole',
		created_at: '2019-03-09T16:06:13Z',
		updated_at: '2024-04-25T06:52:24.615829Z',
		last_activity: '2024-04-16T09:46:05Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_ROLE,
		tags: getTagsByPolicies([awsPolicies.AdministratorAccess]),
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		account_literal: '782968362597',
		account_literal_friendly_name: 'code-stream-prod',
		aws_iam_role: {
			name: 'OrganizationAccountAccessRole',
			created_at: '2019-03-09T16:06:13Z',
			updated_at: '2024-04-24T14:39:51.033568Z',
			last_activity: '2024-04-16T09:46:05Z',
			aws_account: {
				name: 'code-stream-prod',
				id: '57607b4d-8277-4f5f-856b-6334a3871768',
				customer_id: '57607b4d-8277-4f5f-856b-6334a3871768',
				created_at: '2024-01-31T11:50:32.749588Z',
				updated_at: '2024-01-31T11:50:32.749588Z',
				enabled: true,
				account_id: '782968362597',
				is_management: false,
				priority: 5,
			},
			role_id: 'AROAVLA7IKCVZ1XWUZZP',
			arn: 'arn:aws:iam::782968362597:role/OrganizationAccountAccessRole',
			last_used_region: 'us-east-1',
			aws_iam_role_details_xc: {
				Path: '/',
				RoleName: 'OrganizationAccountAccessRole',
				RoleId: 'AROAVLA7IKCVZ1XWUZZP',
				Arn: 'arn:aws:iam::782968362597:role/OrganizationAccountAccessRole',
				CreateDate: '2019-03-09T16:06:13Z',
				AssumeRolePolicyDocument: {
					Version: '2012-10-17',
					Statement: [
						{
							Action: 'sts:AssumeRole',
							Effect: 'Allow',
							Principal: {
								AWS: 'arn:aws:iam::241255784015:root',
							},
						},
					],
				},
				RolePolicyList: [],
				AttachedManagedPolicies: [awsPolicies.AdministratorAccess],
				InstanceProfileList: [],
				RoleLastUsed: {
					LastUsedDate: '2024-04-16T09:46:05Z',
					Region: 'us-east-1',
				},
				Tags: [
					{
						Key: 'Environment',
						Value: 'SA OpsAccount',
					},
					{
						Key: 'Team',
						Value: 'CloudOps',
					},
					{
						Key: 'OwnerEmail',
						Value: 'michael.johnson@synergy.com',
					},
				],
				PermissionsBoundary: null,
				AccountName: 'code-stream-prod',
				AccountPriority: 5,
				AccountId: '782968362597',
			},
			aws_ec2_instances_xc: [
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-08f665a7994f33b40',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:58:53Z',
					PrivateIpAddress: '172.38.65.219',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Staging',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-staging-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0cb6586b166591a28',
					InstanceType: 't4g.micro',
					LaunchTime: '2024-02-23T19:37:06Z',
					PrivateIpAddress: '172.38.71.113',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-mob1-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-00a2750030d9347d4',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-22T04:59:43Z',
					PrivateIpAddress: '172.38.80.221',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-7',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
			change_logs: getIamRoleChangeLogsWithoutAccessKeys(
				'OrganizationAccountAccessRole',
				'arn:aws:iam::871744574088:role/OrganizationAccountAccessRole',
				'john.smith@synergy.com',
				'robert.miller@synergy.com',
				'olivia.moore@synergy.com',
				'william.taylor@synergy.com',
			),
		},
	},
	...awsIamUserData,
	'300': {
		id: '300',
		literal: entraIdUserIds.JASON_NICKELSON,
		literal_friendly_name: commonEntraIdUserData.JASON_NICKELSON.mail,
		created_at: getRecentlyCreatedDate(),
		updated_at: getRecentlyCreatedDate(),
		last_activity: getRecentlyCreatedDate(),
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: {
			...commonEntraIdUserData.JASON_NICKELSON,
		},
	},
	'301': {
		id: '301',
		literal: entraIdUserIds.DANTE_WALKER,
		literal_friendly_name: commonEntraIdUserData.DANTE_WALKER.mail,
		created_at: getRecentlyCreatedDate(),
		updated_at: getRecentlyCreatedDate(),
		last_activity: getRecentlyCreatedDate(),
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		entra_id_user: commonEntraIdUserData.DANTE_WALKER,
	},
	'302': {
		id: '302',
		literal: entraIdUserIds.ALEX_SIGDAG,
		literal_friendly_name: commonEntraIdUserData.ALEX_SIGDAG.mail,
		created_at: '2018-01-21T12:32:36.000000Z',
		updated_at: '2024-08-28T07:01:19.639651Z',
		last_activity: '2024-08-28T07:01:19.639651Z',
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Human,
		source: IdentitySource.ENTRA_ID_USER,
		tags: getTagsByPolicies([awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess]),
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		affected_environments: {
			AWS: [
				{
					account_db_id: '871744574088',
					account_id: '871744574088',
					account_name: 'production',
				},
			],
			ACTIVE_DIRECTORY: [
				{
					account_db_id: 'S-1-5-21-3623811015-3361044348-30300820',
					account_id: 'S-1-5-21-3623811015-3361044348-30300820',
					account_name: 'SYNERGY.COM',
				},
			],
		},
		entra_id_user: {
			...commonEntraIdUserData.ALEX_SIGDAG,
			active_directory_user: adUsersData.ALEX_SIGDAG,
		},
		activity_status: IdentityActivityStatus.ACTIVE,
	},
	'303': {
		noIssueConnectedToIdentity: true,
		...salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_4']],
	},
	'304': {
		...salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_5']],
	},
	'305': {
		...salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_7']],
	},
	'307': {
		noIssueConnectedToIdentity: true,
		...salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_9']],
	},
	'308': {
		noIssueConnectedToIdentity: true,
		...salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_10']],
	},
	'309': {
		noIssueConnectedToIdentity: true,
		...githubUserData['40010'],
	},
	'310': {
		noIssueConnectedToIdentity: true,
		...githubUserData['40011'],
	},
	'311': {
		id: '311',
		literal: adUsersData.ALEX_SIGDAG.object_sid,
		literal_friendly_name: adUsersData.ALEX_SIGDAG.sam_account_name,
		created_at: '2024-01-21T12:32:36.000000Z',
		updated_at: '2024-01-28T07:01:19.639651Z',
		last_activity: '2024-01-28T07:01:19.639651Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Human,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'ADMIN',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.INACTIVE,
		active_directory_user: {
			...adUsersData.ALEX_SIGDAG,
			entra_id_user: commonEntraIdUserData.ALEX_SIGDAG,
		},
		owners: ['george.brown'],
	},
	'312': {
		id: '312',
		literal: adUsersData.JOSEPH_VILLANUEVA.object_sid,
		literal_friendly_name: adUsersData.JOSEPH_VILLANUEVA.sam_account_name,
		created_at: '2022-07-31T00:33:27.721732Z',
		updated_at: '2022-08-31T00:33:27.721732Z',
		last_activity: '2022-08-31T00:33:27.721732Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Human,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		tags: [
			{
				id: 'f63545ec-be29-4850-5a79-bae51ed74d2a',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.INACTIVE,
		active_directory_user: {
			...adUsersData.JOSEPH_VILLANUEVA,
			entra_id_user: commonEntraIdUserData.JOSEPH_VILLANUEVA,
		},
	},
	'313': {
		id: '313',
		literal: adUsersData.ELASTIC_BACKUP_SVC.object_sid,
		literal_friendly_name: adUsersData.ELASTIC_BACKUP_SVC.sam_account_name,
		created_at: '2023-10-26T00:33:27.721Z',
		updated_at: getRecentlyCreatedDate(),
		last_activity: '2023-10-26T00:33:27.721Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Machine,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.INACTIVE,
		active_directory_user: {
			...adUsersData.ELASTIC_BACKUP_SVC,
		},
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		owners: ['joseph.villanueva'],
	},
	'314': {
		id: '314',
		literal: adUsersData.SVC_MAILBOXSCANNER3.object_sid,
		literal_friendly_name: adUsersData.SVC_MAILBOXSCANNER3.sam_account_name,
		created_at: '2023-10-26T00:33:27.721Z',
		updated_at: getRecentlyCreatedDate(),
		last_activity: '2023-10-26T00:33:27.721Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Machine,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		tags: [],
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.INACTIVE,
		active_directory_user: {
			...adUsersData.SVC_MAILBOXSCANNER3,
		},
	},
	'315': {
		id: '315',
		literal: adUsersData.AUDIT_LOG_UPLOAD.object_sid,
		literal_friendly_name: adUsersData.AUDIT_LOG_UPLOAD.sam_account_name,
		created_at: '2023-10-26T00:33:27.721Z',
		updated_at: getRecentlyCreatedDate(),
		last_activity: '2023-10-26T00:33:27.721Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Machine,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		tags: [],
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.INACTIVE,
		active_directory_user: {
			...adUsersData.AUDIT_LOG_UPLOAD,
		},
	},
	'316': {
		id: '316',
		literal: adUsersData.GEORGE_BROWN.object_sid,
		literal_friendly_name: adUsersData.GEORGE_BROWN.sam_account_name,
		created_at: '2024-02-21T12:32:36.000000Z',
		updated_at: '2024-02-23T12:32:36.000000Z',
		last_activity: '2024-02-23T12:32:36.000000Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Human,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'ADMIN',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.INACTIVE,
		active_directory_user: {
			...adUsersData.GEORGE_BROWN,
			entra_id_user: commonEntraIdUserData.GEORGE_BROWN,
		},
		owners: ['joseph.villanueva'],
	},

	'317': {
		id: '317',
		literal: adUsersData.SPLUNK_FORWARDER_SVC.object_sid,
		literal_friendly_name: adUsersData.SPLUNK_FORWARDER_SVC.sam_account_name,
		created_at: '2023-09-15T14:22:45.892Z',
		updated_at: getRecentlyCreatedDate(),
		last_activity: '2024-02-15T08:45:22.331Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Machine,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.ACTIVE,
		active_directory_user: {
			...adUsersData.SPLUNK_FORWARDER_SVC,
		},
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		owners: ['jason.nickelson'],
	},

	'318': {
		id: '318',
		literal: adUsersData.JENKINS_BUILDER_SVC.object_sid,
		literal_friendly_name: adUsersData.JENKINS_BUILDER_SVC.sam_account_name,
		created_at: '2023-11-02T09:17:33.445Z',
		updated_at: getRecentlyCreatedDate(),
		last_activity: '2024-02-16T11:22:15.778Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Machine,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.ACTIVE,
		active_directory_user: {
			...adUsersData.JENKINS_BUILDER_SVC,
		},
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'ADMIN',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		owners: ['jason.nickelson', 'alex.sigdag'],
	},

	'319': {
		id: '319',
		literal: adUsersData.PROMETHEUS_EXPORTER.object_sid,
		literal_friendly_name: adUsersData.PROMETHEUS_EXPORTER.sam_account_name,
		created_at: '2023-08-28T16:44:12.223Z',
		updated_at: getRecentlyCreatedDate(),
		last_activity: '2024-02-16T15:33:28.992Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Machine,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.ACTIVE,
		active_directory_user: {
			...adUsersData.PROMETHEUS_EXPORTER,
		},
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		owners: ['dante.walker'],
	},

	'320': {
		id: '320',
		literal: adUsersData.BACKUP_EXECUTOR_SVC.object_sid,
		literal_friendly_name: adUsersData.BACKUP_EXECUTOR_SVC.sam_account_name,
		created_at: '2023-10-05T13:28:55.667Z',
		updated_at: getRecentlyCreatedDate(),
		last_activity: '2024-02-16T02:15:33.112Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Machine,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.ACTIVE,
		active_directory_user: {
			...adUsersData.BACKUP_EXECUTOR_SVC,
		},
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		owners: ['jason.nickelson'],
	},

	'321': {
		id: '321',
		literal: adUsersData.KAFKA_CONNECT_SVC.object_sid,
		literal_friendly_name: adUsersData.KAFKA_CONNECT_SVC.sam_account_name,
		created_at: '2023-12-10T10:55:18.334Z',
		updated_at: getRecentlyCreatedDate(),
		last_activity: '2024-02-16T18:42:55.445Z',
		env_type: EnvironmentType.ACTIVE_DIRECTORY,
		type: IdentityType.Machine,
		source: IdentitySource.ACTIVE_DIRECTORY_USER,
		account_literal: 'synergy.com',
		activity_status: IdentityActivityStatus.ACTIVE,
		active_directory_user: {
			...adUsersData.KAFKA_CONNECT_SVC,
		},
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
		owners: ['alex.sigdag', 'dante.walker'],
	},
	'322': {
		id: '7262ab32-4ae7-411a-b82b-861476be827b',
		literal: 'test.user39@example.com',
		literal_friendly_name: 'test.user39',
		created_at: '2025-03-24T08:39:57.176000Z',
		updated_at: '2025-03-24T08:39:57.176000Z',
		last_activity: null,
		env_type: EnvironmentType.ONELOGIN,
		type: IdentityType.Human,
		source: IdentitySource.ONELOGIN_USER,
		affected_environments: null,
		account_literal: 'Token OneLogin sandbox',
		account_literal_friendly_name: 'Token OneLogin sandbox',
		tags_names: [],
		tags_with_env: [],
		max_priority: 1,
		issue_count: 0,
		owners: [],
		credentials: [],
		noIssueConnectedToIdentity: true,
	},
	...atlassianUsersDataWithNoIssues,
} as const satisfies Record<string, DemoServerIdentityInput>
