import { Segmented, Tooltip } from 'antd'
import { ControlButton } from '@xyflow/react'
import SparkleIcon from '../../../assets/Sparkle.svg?react'
import { IdentityGraphViewTypeEnum } from './identityGraphTypes.ts'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import UsageIcon from '../../../assets/Usage.svg?react'
import { TokenBadge } from '../../../components/common/TokenBadge.tsx'
import { LogRocketTrackEvent, trackEvent } from '../../../services/logrocket/logrocket.ts'
import { useIdentityUsageLogsGrouped } from '../../../api/identities.ts'
import { ServerIdentity } from '../../../schemas/identity.ts'

type GraphViewControlButtonProps = {
	viewType: IdentityGraphViewTypeEnum
	onViewTypeClicked: (viewType: IdentityGraphViewTypeEnum) => void
	controlButtonClassName: (isSelected: boolean) => string
	segmentedClassName: string
	identity: ServerIdentity
}

export const GraphViewControlButton = ({
	viewType,
	onViewTypeClicked,
	controlButtonClassName,
	segmentedClassName,
	identity,
}: GraphViewControlButtonProps) => {
	const {
		data: groupedUsageLogs,
		isPending: isPendingGroupedUsageLogs,
		isError: isErrorGroupedUsageLogs,
	} = useIdentityUsageLogsGrouped(identity.id)

	const isUsageGraphDisabled =
		isPendingGroupedUsageLogs || isErrorGroupedUsageLogs || !groupedUsageLogs.usage_logs_grouped?.length

	return (
		<>
			<TokenBadge>
				<SparkleIcon className="bg-textIcon-brand rounded-full p-[2px] w-5 h-4" />
			</TokenBadge>
			<Segmented
				className={segmentedClassName}
				vertical
				value={viewType}
				options={[
					{
						value: IdentityGraphViewTypeEnum.USAGE,
						disabled: isUsageGraphDisabled,
						label: (
							<Tooltip
								title={
									isUsageGraphDisabled
										? 'Usage view is not supported for this identity'
										: 'Usage view'
								}
								placement="right"
							>
								<span>
									<ControlButton
										disabled={isUsageGraphDisabled}
										onClick={() => {
											trackEvent(LogRocketTrackEvent.UsageGraphButtonClicked)
											onViewTypeClicked(IdentityGraphViewTypeEnum.USAGE)
										}}
										className={controlButtonClassName(viewType === IdentityGraphViewTypeEnum.USAGE)}
									>
										{isPendingGroupedUsageLogs ? (
											<LoadingOutlined />
										) : (
											<UsageIcon className="!max-w-5 !max-h-5 !fill-transparent" />
										)}
									</ControlButton>
								</span>
							</Tooltip>
						),
					},
					{
						value: IdentityGraphViewTypeEnum.STATIC,
						label: (
							<Tooltip title="Static view" placement="right">
								<span>
									<ControlButton
										onClick={() => onViewTypeClicked(IdentityGraphViewTypeEnum.STATIC)}
										className={controlButtonClassName(
											viewType === IdentityGraphViewTypeEnum.STATIC,
										)}
									>
										<ExclamationCircleOutlined className="[&_svg]:!max-w-5 [&_svg]:!max-h-5" />
									</ControlButton>
								</span>
							</Tooltip>
						),
					},
				]}
			/>
		</>
	)
}
