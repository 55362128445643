import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { ServerSalesforcePermissionSet } from '../../../../schemas/identities/salesforce/salesforcePermissionSetSchema.ts'
import { ServerTag } from '../../../../schemas/tags.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type SalesforcePermissionSetsNodeType = Node<
	{ permissionSets: ServerSalesforcePermissionSet[] },
	'salesforcePermissionSets'
>
type SalesforcePermissionSetsNodeProps = NodeProps<SalesforcePermissionSetsNodeType>

export const SalesforcePermissionSetsNode = ({ data: { permissionSets } }: SalesforcePermissionSetsNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = permissionSets.some((permissionSet) =>
		highlightedNodes.some((node) => node.type === 'salesforcePermissionSet' && node.id === permissionSet.id),
	)

	const badgeTooltipContent = permissionSets.map((permissionSet) => (
		<span key={permissionSet.id}>{permissionSet.name}</span>
	))

	const uniqueTags = permissionSets.reduce((tags, permissionSet) => {
		permissionSet.tags?.forEach((tag) => {
			if (!tags.find((t) => t.name === tag.name)) {
				tags.push(tag)
			}
		})
		return tags
	}, [] as ServerTag[])

	return (
		<BasePermissionNode
			label="Permission Sets"
			isHighlighted={isHighlighted}
			risks={uniqueTags}
			groupProps={{ count: permissionSets.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
