import { Button, Modal } from 'antd'
import { AgGridReact } from 'ag-grid-react'
import React, { ComponentType, FunctionComponent, useCallback, useMemo, useRef, useState } from 'react'
import { formatDate, getCsvFileName } from '../../../../../utils.ts'
import ExportIcon from '../../../../../assets/export_icon_20.svg?react'
import { useEnvironments } from '../../../../../api/environments.ts'
import { ColDef } from 'ag-grid-enterprise'
import { EnvironmentType } from '../../../../../schemas/envType.ts'

const FirstLogDate: FunctionComponent<{ firstLogDate?: string | Date | null }> = ({ firstLogDate }) => {
	return (
		<span className="mt-4 text-textIcon-secondary">
			Earliest event collected: {firstLogDate ? formatDate(firstLogDate, false) : 'N/A'}
		</span>
	)
}

type BaseCloudtrailLogsContentProps<TEntry> = {
	environmentId: string
	subEnvironmentId: string
	identityArn: string
	entries: TEntry[]
	entriesColumnDefs: ColDef<TEntry>[]
	ContentTable: ComponentType<{ entries: TEntry[] }>
	title: string
	modalTitle: string
	onExportCsv?: () => void
}

export const BaseCloudtrailLogsContent = <TEntry,>({
	environmentId,
	subEnvironmentId,
	identityArn, // TODO: Remove this prop when we deprecate the identity drawer
	entries,
	entriesColumnDefs,
	ContentTable,
	title,
	modalTitle,
	onExportCsv,
}: BaseCloudtrailLogsContentProps<TEntry>) => {
	const { data } = useEnvironments()
	const gridRef: React.RefObject<AgGridReact> = useRef<AgGridReact>(null)

	// // TODO: use this when we deprecate the identity drawer
	// const { identityId } = identityRoute.useParams()
	// const { data: identity } = useIdentity(identityId)
	// const identityArn = identity.literal

	const cloudtrailIntegration = useMemo(() => {
		const awsEnvironment = data?.[EnvironmentType.AWS].find((env) => env.id === environmentId)
		const awsAccount = awsEnvironment?.aws_accounts.find((account) => account.id === subEnvironmentId)
		return awsAccount?.account_cloudtrails[0]
	}, [data, environmentId, subEnvironmentId])

	const [defaultColDef] = useState({
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	})

	const [isModalOpen, setIsModalOpen] = useState(false)

	const exportToCsv = useCallback(() => {
		onExportCsv?.()
		gridRef?.current!.api.exportDataAsCsv({
			fileName: getCsvFileName(`${title.toLowerCase()}-${identityArn}`, false),
		})
	}, [onExportCsv])

	return (
		<>
			{entries.length > 0 ? (
				<>
					<div className="h-64 overflow-auto">
						<ContentTable entries={entries}></ContentTable>
					</div>
					<div className="flex justify-between">
						<Button size="small" type="link" className="mt-4" onClick={() => setIsModalOpen(true)}>
							See all events
						</Button>
						<FirstLogDate firstLogDate={cloudtrailIntegration?.first_log_date} />
					</div>
				</>
			) : cloudtrailIntegration ? (
				<div className="text-center">
					{`${title} information is not available.`}
					<br />
					{cloudtrailIntegration.first_log_date && (
						<FirstLogDate firstLogDate={cloudtrailIntegration.first_log_date} />
					)}
				</div>
			) : (
				<div className="text-center">
					{`${title} information is not available due to missing Cloudtrail integration`}
					<br />
					<a
						className="text-textIcon-link hover:text-textIcon-linkHover !transition-none"
						href="mailto:support@token.security?subject=Cloudtrail Integration&body=Hey team Token, I would like to add a Cloudtrail integration, can you help me with that?"
						target="_blank"
					>
						Let's integrate Cloudtrail
					</a>
				</div>
			)}
			<Modal
				centered={true}
				width={1024}
				title={modalTitle}
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="first-log-date" className="flex justify-between items-center">
						<FirstLogDate firstLogDate={cloudtrailIntegration?.first_log_date} />
						<div className="flex gap-2">
							<Button icon={<ExportIcon />} onClick={exportToCsv}>
								Export CSV
							</Button>
							<Button type="primary" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className={'ag-theme-alpine w-full'}
						rowData={entries}
						rowHeight={54}
						columnDefs={entriesColumnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
						ref={gridRef}
					/>
				</div>
			</Modal>
		</>
	)
}
