import React from 'react'
import { Layout } from 'antd'
import { SideBarMenu } from '../menus/SideBarMenu'
import { useAuth } from '../../services/auth/auth.ts'

const { Content, Sider } = Layout
export const MainLayout: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const { user } = useAuth()
	if (!user) {
		return null
	}
	return (
		<Layout className="h-screen bg-surface-primaryDark">
			<Sider
				collapsedWidth={60}
				className="bg-transparent"
				trigger={null}
				defaultCollapsed={true}
				collapsed={true}
			>
				<SideBarMenu />
			</Sider>
			<Content>{children}</Content>
		</Layout>
	)
}
