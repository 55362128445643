import { CSSProperties, forwardRef, HTMLAttributes } from 'react'
import cx from 'classnames'
import { nodeColorClassNames, NodeColorTypes } from '../graphUtils/nodeColors.ts'

type NodeBadgeProps = HTMLAttributes<HTMLDivElement> & {
	positionOnLeft?: boolean
	breathingAnimation?: boolean
	nodeColorType: NodeColorTypes
}

export const NodeBadge = forwardRef<HTMLDivElement, NodeBadgeProps>(
	({ className, style, children, positionOnLeft, breathingAnimation, nodeColorType, ...rest }, ref) => {
		const badgeNodeClassName = cx(
			'absolute h-[25px] min-w-[25px] w-fit top-[-10px] flex items-center justify-center rounded-full border border-border-white z-10',
			className,
			nodeColorClassNames[nodeColorType].badgeBg,
			nodeColorClassNames[nodeColorType].badgeText,
			{
				'animate-pulse-scale': breathingAnimation,
			},
		)

		const badgeStyle: CSSProperties = {
			left: positionOnLeft ? -10 : 30,
			...style,
		}

		return (
			<div data-badge="true" className={badgeNodeClassName} style={badgeStyle} ref={ref} {...rest}>
				{children}
			</div>
		)
	},
)
