import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { ServerSalesforceConnectedAppMetadata } from '../../../../schemas/identities/salesforce/salesforceConnectedApplicationSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type SalesforceConnectedAppMetadataNodeType = Node<
	{
		metadata: ServerSalesforceConnectedAppMetadata
	},
	'salesforceConnectedAppMetadata'
>
type SalesforceConnectedAppOAuthMetadataNodeProps = NodeProps<SalesforceConnectedAppMetadataNodeType>

export const SalesforceConnectedAppMetadataNode = ({
	data: { metadata },
}: SalesforceConnectedAppOAuthMetadataNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) =>
			highlightedNode.type === 'salesforceConnectedAppMetadata' && highlightedNode.id === metadata.label,
	)

	return (
		<BasePermissionNode
			label="Connected Application Metadata"
			name={{ text: metadata.label }}
			isHighlighted={isHighlighted}
		/>
	)
}
