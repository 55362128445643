import { Tag, Tooltip } from 'antd'
import {
	IssueStatusesWithIgnoreReason,
	IssueStatusMap,
	ServerIssue,
	UserChangeableIssueStatuses,
} from '../../schemas/issue'
import React from 'react'
import { EditOutlined } from '@ant-design/icons'

export const IssueStatusTag: React.FunctionComponent<{
	issue?: Pick<ServerIssue, 'status' | 'ignore_reason'> | null
	onEdit?: () => void
}> = ({ issue, onEdit }) => {
	if (!issue) {
		return null
	}

	const isEditable = !!onEdit && UserChangeableIssueStatuses.includes(issue.status)
	const statusNode = <div className="text font-medium">{IssueStatusMap[issue.status]}</div>

	return (
		<Tag onClick={isEditable ? onEdit : undefined} className={isEditable ? 'cursor-pointer' : undefined}>
			<div className="flex items-center">
				{IssueStatusesWithIgnoreReason.includes(issue.status) && issue.ignore_reason ? (
					<Tooltip title={issue.ignore_reason}>{statusNode}</Tooltip>
				) : (
					statusNode
				)}
				{isEditable && (
					<Tooltip title="Update issue status">
						<EditOutlined className="ml-1" />
					</Tooltip>
				)}
			</div>
		</Tag>
	)
}
