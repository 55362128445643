import { Tooltip } from 'antd'
import { RoutePaths } from '../RoutePaths.tsx'
import { TokenBreadcrumbs } from '../../components/common/TokenBreadcrumbs.tsx'
import { useIdentity } from '../../api/identities.ts'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'

export const IdentityHeader = ({ identityId }: { identityId: string }) => {
	const { data: identity, isPending: isIdentityPending, isError: isIdentityError } = useIdentity(identityId)
	const currentTitle = isIdentityPending ? (
		<div className="flex items-center gap-2">
			<LoadingOutlined />
			{'Loading...'}
		</div>
	) : isIdentityError ? (
		<div className="flex items-center gap-2">
			<ExclamationCircleOutlined className="text-status-critical" />
			{'Error'}
		</div>
	) : (
		<Tooltip title={identity.literal}>{identity.literal_friendly_name || identity.literal}</Tooltip>
	)

	return (
		<TokenBreadcrumbs
			previousEntry={{
				linkProps: {
					from: RoutePaths.Identity,
					to: RoutePaths.Identities,
					search: ({ identitiesPage }) => ({ ...identitiesPage }),
				},
				title: 'Inventory',
			}}
			currentTitle={currentTitle}
		/>
	)
}
