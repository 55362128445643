import { FronteggProvider, useAuth as FrontEggAuth, useLoginWithRedirect } from '@frontegg/react'
import React, { useEffect, useState } from 'react'
import { initSentry } from '../sentry/sentry'
import { FullScreenLoader } from '../../components/common/FullScreenLoader.tsx'
import { InitLogRocket } from '../logrocket/logrocket'
import { updateAccessToken } from '../../api/api'
import { AuthContext, AuthContextState } from './AuthContext.ts'

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [authContextState, setAuthContextState] = useState<AuthContextState | null>(null)
	const { isAuthenticated, user, tenantsState } = FrontEggAuth()
	const loginWithRedirect = useLoginWithRedirect()

	useEffect(() => {
		if (!isAuthenticated) {
			void loginWithRedirect()
		} else {
			if (!user || !tenantsState || !tenantsState.activeTenant) {
				return
			}

			// NOTE: this is for debugging purposes. Do not remove.
			window.$user = user
			const isImpersonating = user.act !== undefined
			const isAdmin = !!user.roles.find((role) => role.name === 'Admin')
			updateAccessToken(user.accessToken)
			InitLogRocket(user, tenantsState.activeTenant, isImpersonating)
			initSentry(tenantsState.activeTenant, user)
			setAuthContextState({
				user,
				tenantsState,
				activeTenant: tenantsState.activeTenant,
				isImpersonating,
				isAdmin,
			})
		}
	}, [isAuthenticated, loginWithRedirect, user, tenantsState, tenantsState?.activeTenant, user?.accessToken])

	if (!authContextState) {
		return <FullScreenLoader />
	}

	return <AuthContext.Provider value={authContextState}>{children}</AuthContext.Provider>
}

interface AuthProviderProps {
	baseUrl: string
	clientId: string
	children?: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, baseUrl, clientId }) => {
	const [isFronteggLoading, setIsFronteggLoading] = useState(true)
	return (
		<>
			<FronteggProvider
				contextOptions={{
					baseUrl,
					clientId,
				}}
				entitlementsOptions={{ enabled: true }}
				hostedLoginBox={true}
				customLoader={setIsFronteggLoading}
				authOptions={{
					hostedLoginOptions: {
						loadUserOnFirstLoad: true,
					},
					keepSessionAlive: true,
				}}
			>
				<AuthContextProvider>{children}</AuthContextProvider>
			</FronteggProvider>
			{isFronteggLoading && <FullScreenLoader />}
		</>
	)
}
