import { IssueName, IssueNameMap, ServerIssue } from '../../../schemas/issue.ts'
import React from 'react'
import { IssueIcon } from '../../common/IssueIcon.tsx'
import { SelectAssignee } from '../../../routes/Identity/IssuesView/SelectAssignee.tsx'
import { IssueTitleStatus } from '../../../routes/Identity/IssuesView/IssueTitleStatus.tsx'
import { JiraIntegrationWrapper } from '../../../jiraIntegration/components/JiraIntegrationWrapper.tsx'

export const IssueDrawerTitle: React.FunctionComponent<{
	issue?: ServerIssue
}> = ({ issue }) => {
	const displayIssueName: string = IssueNameMap[issue?.issue_name as IssueName]
	return issue ? (
		<div className="flex flex-row items-center gap-2 mr-2">
			<IssueIcon name={issue.issue_name} />
			<span className="font-medium">{displayIssueName}</span>
			<IssueTitleStatus issueId={issue.id} />
			<span className="text-textIcon-secondary font-extralight">|</span>
			<div className="ml-2 font-medium items-center text-sm flex gap-2">
				<span>Assignee:</span>
				<SelectAssignee issueId={issue.id} />
			</div>
			<JiraIntegrationWrapper issueId={issue.id} />
		</div>
	) : null
}
