import { useCallback, useEffect, useState } from 'react'
import { ExpandedChangedEvent } from 'ag-grid-enterprise'
import { CustomCellRendererProps } from 'ag-grid-react'
import { ServerIssue, IssueStatus } from '../../schemas/issue'
import { IssueNameTag } from '../../components/common/IssueNameTag.tsx'
import ChevronDownIcon from '../../assets/chevron_down_icon_20.svg?react'
import { Tooltip } from 'antd'
import { IssueStatusTag } from '../../components/common/IssueStatusTag.tsx'
import { IdentityTypeWithIcon } from '../../components/common/IdentityTypeWithIcon.tsx'
import { FronteggUserAvatar } from '../../components/common/FronteggUserAvatar.tsx'

import { useFronteggUsers } from '../../api/fronteggApi.ts'

export const FindingsGroupCellRender = (props: CustomCellRendererProps<ServerIssue, string>) => {
	const { node, value } = props
	const { data: fronteggUsers } = useFronteggUsers()
	const [expanded, setExpanded] = useState(node.expanded)

	useEffect(() => {
		const expandListener = (event: ExpandedChangedEvent<ServerIssue>) => setExpanded(event.node.expanded)

		node.addEventListener('expandedChanged', expandListener)

		return () => {
			node.removeEventListener('expandedChanged', expandListener)
		}
	}, [])

	const onClick = useCallback(() => node.setExpanded(!node.expanded), [node])
	const renderValue = () => {
		switch (node.field) {
			case 'issue_name':
				return <IssueNameTag name={value as string} />
			case 'identity.literal':
				return (
					<Tooltip placement="bottomLeft" title={value as string}>
						{value}
					</Tooltip>
				)
			case 'status':
				return <IssueStatusTag issue={{ status: value as IssueStatus }} />
			case 'identity.account_literal':
				return (
					<>
						<Tooltip placement="bottomLeft" title={value as string}>
							<div className="ml-2 truncate">{value}</div>
						</Tooltip>
					</>
				)
			case 'identity.type':
				return <IdentityTypeWithIcon type={value as string} />
			case 'priority':
				return value
			case 'assignee':
				if (!value) {
					return 'Unassigned'
				}
				return <FronteggUserAvatar user={fronteggUsers![value]} />
		}
	}
	return (
		<div
			className="flex items-center"
			style={{
				paddingLeft: `${node.level * 15}px`,
			}}
		>
			{node.group && (
				<div
					style={{
						cursor: 'pointer',
						transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
						display: 'inline-block',
					}}
					onClick={onClick}
				>
					<ChevronDownIcon />
				</div>
			)}
			&nbsp;
			{renderValue()}
		</div>
	)
}
