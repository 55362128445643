import { AgGridReact } from 'ag-grid-react'
import React, { useMemo } from 'react'
import { ColDef } from 'ag-grid-enterprise'
import { Tooltip } from 'antd'
import {
	aggregateGCPUserChangeLogs,
	ServerGCPUserChangeLogs,
	GCPUserChangeLogsAggregatedSchema,
} from '../../../../../schemas/identities/gcp/gcpUserChangeLogs.ts'
import { formatDate, formatRelativeDateText } from '../../../../../utils.ts'

const defaultColDef: ColDef = {
	sortable: true,
	resizable: true,
	filter: true,
	cellStyle: () => ({
		display: 'flex',
		alignItems: 'center',
	}),
	flex: 1,
}

export const GCPAggregatedOwnershipTable: React.FunctionComponent<{
	changeLogs: ServerGCPUserChangeLogs[]
}> = ({ changeLogs }) => {
	const columnDefs: ColDef<GCPUserChangeLogsAggregatedSchema>[] = [
		{
			field: 'actor_principal_email',
			headerName: 'Actor',
		},
		{
			field: 'event_names',
			headerName: 'Actions',
			valueFormatter: ({ value }: { value: string[] }) => value.join(', '),
		},
		{
			field: 'first_time',
			headerName: 'First Time',
			cellRenderer: (params: { value: string }) => (
				<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
					<div className="text-textIcon-secondary">{formatRelativeDateText(params.value, true)}</div>
				</Tooltip>
			),
		},
		{
			field: 'last_time',
			headerName: 'Last Time',
			sort: 'desc',
			cellRenderer: (params: { value: string }) => (
				<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
					<div className="text-textIcon-secondary">{formatRelativeDateText(params.value, true)}</div>
				</Tooltip>
			),
		},
	]

	const aggregatedEntries = useMemo(() => aggregateGCPUserChangeLogs(changeLogs), [changeLogs])

	return (
		<AgGridReact
			className="ag-theme-alpine h-full w-full overflow-x-auto"
			rowHeight={54}
			rowData={aggregatedEntries}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
			suppressHorizontalScroll={false}
		/>
	)
}
