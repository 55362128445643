import { Typography } from 'antd'
import { EnvironmentConfig } from '../environmentIntegrationsTypes.ts'

type IntegrationModalHeaderProps = {
	integration: EnvironmentConfig
}

export const IntegrationModalTitle = ({ integration }: IntegrationModalHeaderProps) => (
	<div className="flex items-center gap-3">
		{integration.logo}
		<Typography.Title level={4} className="!m-0">
			Login to your {integration.name} account
		</Typography.Title>
	</div>
)
