import { Button, Dropdown, Tooltip } from 'antd'
import { MailTwoTone } from '@ant-design/icons'
import { ServerIssue, IssueNameMap } from '../../../schemas/issue'
import { ServerIdentity } from '../../../schemas/identity'
import { useIdentityOwners } from '../../../api/identities'
import { Link } from '@tanstack/react-router'

type MailToIssueButtonProps = {
	currentIssue: ServerIssue
	identity: ServerIdentity
}

const constructMailtoLink = (email: string, issue: ServerIssue, identity: ServerIdentity) => {
	const identityFriendlyName = identity?.literal_friendly_name || 'Unknown Identity'
	const identityLiteralName = identity?.literal || 'Unknown Identity'
	const identitySource = identity.source || 'Unknown Source'
	const identitySourceEnv = identity.env_type || 'Unknown Environment'
	const issueName = issue.issue_name ? IssueNameMap[issue.issue_name] : 'Unknown Issue'
	const issueDescription = issue.description || 'Unknown Description'
	const subject = encodeURIComponent(`${identityFriendlyName} - ${issueName}`)
	const body = encodeURIComponent(
		`Hello,\n\nI am reaching out regarding an issue with the ${identitySource} identity "${identityLiteralName}" in our ${identitySourceEnv} environment.\n\nIssue Type: ${issueName}\n\nIssue Description: ${issueDescription}\n\nIssue Link:\n${window.location.href}\n\nBest regards,`,
	)
	return `mailto:${email}?subject=${subject}&body=${body}`
}

export const MailToIssueButton = ({ currentIssue, identity }: MailToIssueButtonProps) => {
	const { data: owners, isError, isPending } = useIdentityOwners(identity.id)

	if (isError || isPending || !owners) {
		return null
	}

	const emails = [
		...new Set(owners.map((owner) => owner.email?.toLowerCase()).filter((email): email is string => !!email)),
	]

	if (emails.length === 0) {
		return null
	}

	const items = emails.map((email) => ({
		key: email,
		label: <Link to={constructMailtoLink(email, currentIssue, identity)}>{email}</Link>,
	}))

	return (
		<Tooltip title="Email the owner of this identity about this issue" placement="top">
			<Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
				<Button classNames={{ icon: 'flex' }} icon={<MailTwoTone />} />
			</Dropdown>
		</Tooltip>
	)
}
