import { KeyOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { BaseNode, BaseNodeProps } from './BaseNode.tsx'
import { ReactNode } from 'react'
import { NodeBadge } from '../NodeBadge.tsx'
import { BaseServerIssue } from '../../../../../schemas/issue.ts'
import { IssueIcon } from '../../../../../components/common/IssueIcon.tsx'
import { getColorFromTailwindColorClassName } from '../../../../../utils/colorUtils.ts'

type BaseCredentialsNodeProps = {
	label: string
	isHighlighted?: boolean
	name?: BaseNodeProps['name']
	issueAttached?: BaseServerIssue
	isActive?: boolean
	icon?: ReactNode
	children?: ReactNode
}

export const BaseCredentialsNode = ({
	label,
	isHighlighted,
	name,
	issueAttached,
	isActive = true,
	icon: Icon,
	children,
}: BaseCredentialsNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.credentials.bgHover} ${nodeColorClassNames.credentials.text} w-[50px] h-[50px] rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.credentials.bg]: !isHighlighted,
			[nodeColorClassNames.credentials.bgHighlighted]: isHighlighted,
			[nodeColorClassNames.credentials.bgDisabled]: !isActive,
			[nodeColorClassNames.credentials.bgHoverDisabled]: !isActive,
		},
	)

	return (
		<BaseNode labelProps={{ label }} name={name}>
			{issueAttached && (
				<NodeBadge nodeColorType="credentials" className="text-lg">
					<IssueIcon
						color={getColorFromTailwindColorClassName(nodeColorClassNames.credentials.text)}
						size={14}
						name={issueAttached?.issue_name}
					/>
				</NodeBadge>
			)}
			{children}
			<div className={iconWrapperClassName}>{Icon ? Icon : <KeyOutlined />}</div>
		</BaseNode>
	)
}
