import { ClientIssueSearch, ServerIssue, ServerIssuesStatsSchema, ServerIssueStats } from '../../schemas/issue.ts'
import { CloudProviders, IdentityProviders } from '../schemas.ts'
import { IdentityType, ServerIdentityRelatedIssues } from '../../schemas/identity.ts'
import countBy from 'lodash/countBy'
import { mostWantedIdentitiesData } from '../data/metricsData.ts'

export function filterIssues(issues: ServerIssue[], postParams: ClientIssueSearch): ServerIssue[] {
	if (issues.length === 0) return []
	let filteredIssues = issues
	if (postParams.issueName) {
		filteredIssues = filteredIssues.filter((issue) => {
			return issue?.issue_name === postParams.issueName
		})
	}
	if (postParams.issueStatuses) {
		filteredIssues = filteredIssues.filter((issue) => {
			return postParams.issueStatuses?.includes(issue?.status)
		})
	}
	if (postParams.cloudProvider) {
		filteredIssues = filteredIssues.filter((issue) => {
			return issue?.issue_source === postParams.cloudProvider
		})
	}
	if (postParams.identityProvider) {
		filteredIssues = filteredIssues.filter((issue) => {
			return issue?.identity?.env_type === postParams.identityProvider
		})
	}
	if (postParams.nonHuman) {
		filteredIssues = filteredIssues.filter((issue) => {
			return issue.identity?.type !== IdentityType.Human
		})
	}
	if (postParams.human) {
		filteredIssues = filteredIssues.filter((issue) => {
			return issue.identity?.type === IdentityType.Human
		})
	}
	if (postParams.issueTag) {
		const issueTag = postParams.issueTag
		filteredIssues = filteredIssues.filter((issue) => {
			const tags: string[] = []
			issue.identity?.tags?.forEach((tag) => {
				tags.push(tag.name)
			})
			return tags.includes(issueTag)
		})
	}
	if (postParams.mostWanted) {
		const mostWantedIdentitiesIds = mostWantedIdentitiesData.map((identity) => identity.id)
		filteredIssues = filteredIssues.filter((issue) => {
			if (!issue.identity?.id) return false
			return mostWantedIdentitiesIds.includes(issue.identity.id)
		})
	}
	return filteredIssues
}

export function compareIssues(a: ServerIssue, b: ServerIssue): number {
	const aPriority = a.priority
	const bPriority = b.priority
	if (aPriority === undefined || aPriority === null) {
		return 1
	}
	if (bPriority === undefined || bPriority === null) {
		return -1
	}
	if (aPriority === bPriority) {
		return 0
	}
	return aPriority < bPriority ? 1 : -1
}

function generateServerIssueStats(rawIssuesStats: Record<string, number>, type: string): ServerIssueStats[] {
	const serverIssueStats: ServerIssueStats[] = []
	for (const issueName in rawIssuesStats) {
		serverIssueStats.push(
			ServerIssuesStatsSchema.parse({
				group: type,
				count: rawIssuesStats[issueName],
				issue_name: issueName,
			}),
		)
	}
	if (serverIssueStats.length === 0) {
		serverIssueStats.push(
			ServerIssuesStatsSchema.parse({
				group: type,
				count: 0,
				issue_name: 'All',
			}),
		)
	}
	return serverIssueStats
}

export function countIssueStats(issues: ServerIssue[]): ServerIssueStats[] {
	return [
		...generateServerIssueStats({ All: issues.length }, 'AllCount'),
		...generateServerIssueStats(countBy(issues, 'issue_name'), 'Findings'),
		...generateServerIssueStats(
			countBy(
				issues.filter((issue) => CloudProviders.safeParse(issue.issue_source).success),
				'issue_source',
			),
			'cloudProvider',
		),
		...generateServerIssueStats(
			countBy(
				issues.filter((issue) => IdentityProviders.safeParse(issue.identity?.env_type).success),
				'identity.env_type',
			),
			'identityProvider',
		),
		...generateServerIssueStats(
			countBy(
				issues.filter((issue) => issue.identity?.type !== IdentityType.Human),
				'issue_name',
			),
			'nonHuman',
		),
		...generateServerIssueStats(
			countBy(
				issues.filter((issue) => issue.identity?.type === IdentityType.Human),
				'issue_name',
			),
			'human',
		),
		...generateServerIssueStats(
			countBy(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
				issues.filter((issue) => issue?.assignee === (window.$user as any)?.id),
				'issue_name',
			),
			'MyIssues',
		),
	]
}

export function getIdentityWithIssues(issuesArray: ServerIssue[], identityID: string): ServerIdentityRelatedIssues {
	const identitiesID: string[] = []
	issuesArray.forEach((issue) => {
		const identityId = issue.identity?.id
		if (identityId && !identitiesID.includes(identityId)) {
			identitiesID.push(identityId)
		}
	})
	const identitiesWithIssues: ServerIdentityRelatedIssues[] = []
	identitiesID.forEach((identityId) => {
		const issues = issuesArray.filter((issue) => issue.identity?.id === identityId)
		identitiesWithIssues.push({
			id: identityId,
			issues: issues,
		})
	})
	return identitiesWithIssues.filter((identity) => identity.id === identityID)[0]
}
