import React from 'react'
import cx from 'classnames'

type MetricsChartContainerProps = {
	children: React.ReactNode
	header: React.ReactNode
	className?: string
	childrenAreList?: boolean
	heightSize?: 'small' | 'large' | 'full'
}

export const MetricsChartContainer: React.FunctionComponent<MetricsChartContainerProps> = ({
	children,
	header,
	className,
	childrenAreList,
	heightSize,
}) => {
	const containerClassName = cx(
		'bg-surface-secondary text-sm text-textIcon-primary border border-border-secondary rounded-lg flex flex-col gap-2 min-w-0',
		{
			'max-h-[400px] h-[400px]': heightSize === 'large' || !heightSize,
			'max-h-[200px] h-[200px]': heightSize === 'small',
			'grow min-h-0 shrink-0 basis-0': heightSize === 'full',
		},
		className,
	)

	const contentClassName = cx('flex flex-col grow', {
		'px-2 py-0 mb-2 !gap-1 overflow-y-auto': !!childrenAreList,
		'p-4 gap-2': !childrenAreList,
	})
	return (
		<div className={containerClassName}>
			<div className="border-b border-border-secondary px-4 py-2 flex gap-6 items-center min-h-12 justify-between font-medium">
				{header}
			</div>
			<div className={contentClassName}>{children}</div>
		</div>
	)
}
