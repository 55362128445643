import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { ServerAdGroup } from '../../../../schemas/identities/activeDirectory/adGroupSchema'
import { ServerTag } from '../../../../schemas/tags'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type AdGroupsNodeType = Node<{ groups: ServerAdGroup[] }, 'adGroups'>
type AdGroupsNodeProps = NodeProps<AdGroupsNodeType>

export const AdGroupsNode = ({ data: { groups } }: AdGroupsNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = groups.some((group) =>
		highlightedNodes.some((node) => node.type === 'adGroup' && node.id === group.object_sid),
	)

	const badgeTooltipContent = groups.map((group) => <span key={group.object_sid}>{group.sam_account_name}</span>)

	const uniqueTags = groups.reduce((tags, group) => {
		group.tags?.forEach((tag) => {
			if (!tags.find((t) => t.name === tag.name)) {
				tags.push(tag)
			}
		})
		return tags
	}, [] as ServerTag[])

	return (
		<BasePermissionNode
			label="AD Groups"
			groupProps={{ count: groups.length, tooltipContent: badgeTooltipContent }}
			isHighlighted={isHighlighted}
			risks={uniqueTags}
		/>
	)
}
