import OktaIcon from '../../../../assets/okta_logo_16.svg?react'
import OktaGuide from '../../../../assets/integrationGuides/okta-integration-guide.pdf'
import {
	EnvironmentCreateRequest,
	EnvironmentTestConnectionRequest,
	ServerEnvironments,
} from '../../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationCategory,
	EnvironmentIntegrationFormData,
} from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const oktaConfig: ConnectableEnvironmentConfig = {
	key: EnvironmentType.OKTA,
	name: 'Okta',
	description: 'Link Token Security to your Okta organizations to map cloud identities across your IDP.',
	logo: <IntegrationIcon leftIcon={OktaIcon} />,
	category: EnvironmentIntegrationCategory.IDENTITY_ACCESS_MANAGEMENT,
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.OKTA].filter(
			(env) =>
				env.name.toLowerCase().includes(searchText.toLowerCase()) ||
				env.env_domain.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.OKTA].map((env) => env.name),
	guideFileName: OktaGuide,
	formFields: [
		{
			fieldType: 'simple',
			name: 'name',
			label: 'Environment Name',
			type: 'text',
			placeholder: 'Ex: my-okta-environment',
			required: true,
		},
		{
			fieldType: 'simple',
			name: 'domain',
			label: 'Domain',
			type: 'url',
			placeholder: 'Ex: https://my-domain.okta.com',
			required: true,
		},
		{
			fieldType: 'simple',
			name: 'apiKey',
			label: 'API Key',
			type: 'password',
			placeholder: 'Ex: AbC12345XyZ67890!@#$%^&*()_+',
			required: true,
		},
	],
	createTestConnectionPayload: (formData: EnvironmentIntegrationFormData): EnvironmentTestConnectionRequest => ({
		environment_type: EnvironmentType.OKTA,
		config: {
			type: EnvironmentType.OKTA,
			domain: formData.domain as string,
			api_key: formData.apiKey as string,
		},
	}),
	createEnvironmentPayload: (formData: EnvironmentIntegrationFormData): EnvironmentCreateRequest => ({
		name: formData.name as string,
		environment_type: EnvironmentType.OKTA,
		config: {
			type: EnvironmentType.OKTA,
			domain: formData.domain as string,
			api_key: formData.apiKey as string,
		},
	}),
}
