import { IdentitiesPageLens } from '../../schemas/identity.ts'
import ResourcesIcon from '../../assets/resources.svg?react'
import AwsIcon from '../../assets/aws_icon.svg?react'
import GcpIcon from '../../assets/gcp_icon_20.svg?react'
import AzureIcon from '../../assets/azure_icon.svg?react'
import OktaIcon from '../../assets/okta_icon_no_color.svg?react'
import JumpcloudIcon from '../../assets/jumpcloud_icon_16.svg?react'
import EntraIdIcon from '../../assets/entra_id_icon_16.svg?react'
import GoogleWorkspaceIcon from '../../assets/google_workspace_logo_16.svg?react'
import PostgresIcon from '../../assets/postgres_icon_20.svg?react'
import SnowflakeIcon from '../../assets/snowflake_icon.svg?react'
import DatabricksIcon from '../../assets/databricks_icon.svg?react'
import KubernetesIcon from '../../assets/kubernetes_icon_20.svg?react'
import OffBoardedIcon from '../../assets/off_boarded_icon_20.svg?react'
import AccessKeysIcon from '../../assets/access_keys_icon_20.svg?react'
import UnfederatedIcon from '../../assets/unfederated_identity_16.svg?react'
import RecentlyCreatedIcon from '../../assets/recently_created.svg?react'
import MostWantedIcon from '../../assets/most_wanted.svg?react'
import OverPrivilegedIcon from '../../assets/over_privileged_identities.svg?react'
import ThirdPartyIcon from '../../assets/third_party.svg?react'
import GithubIcon from '../../assets/github_icon_20.svg?react'
import AzureDevopsIcon from '../../assets/azure_devops_logo_16.svg?react'
import SalesforceIcon from '../../assets/salesforce_icon.svg?react'
import AtlassianIcon from '../../assets/attlasianIcon_blue.svg?react'
import { LensAccountPriorityIcon } from '../../components/common/LensAccountPriorityIcon.tsx'
import { AccountPriorityEnum } from '../../schemas/environments/accountPriorityEnum.ts'

export interface IdentitiesLensConfig {
	label: string
	icon: React.ComponentType<{ className?: string }>
	isDemoOnly?: boolean
}

export const DEFAULT_IDENTITIES_LENS = IdentitiesPageLens.ALL

export const IDENTITIES_LENS_MAPPING: Record<IdentitiesPageLens, IdentitiesLensConfig> = {
	[IdentitiesPageLens.AWS]: { label: 'AWS', icon: AwsIcon },
	[IdentitiesPageLens.GCP]: { label: 'GCP', icon: GcpIcon },
	[IdentitiesPageLens.AZURE]: { label: 'Azure', icon: AzureIcon },
	[IdentitiesPageLens.OKTA]: { label: 'Okta', icon: OktaIcon },
	[IdentitiesPageLens.JUMPCLOUD]: { label: 'Jumpcloud', icon: JumpcloudIcon },
	[IdentitiesPageLens.ENTRA_ID]: { label: 'Entra ID', icon: EntraIdIcon },
	[IdentitiesPageLens.GOOGLE_WORKSPACE]: { label: 'Google Workspace', icon: GoogleWorkspaceIcon },
	[IdentitiesPageLens.POSTGRES]: { label: 'Postgres', icon: PostgresIcon },
	[IdentitiesPageLens.SNOWFLAKE]: { label: 'Snowflake', icon: SnowflakeIcon },
	[IdentitiesPageLens.DEMO_DATABRICKS]: { label: 'Databricks', icon: DatabricksIcon, isDemoOnly: true },
	[IdentitiesPageLens.KUBERNETES]: { label: 'Kubernetes', icon: KubernetesIcon },
	[IdentitiesPageLens.AZURE_DEVOPS]: { label: 'Azure Devops', icon: AzureDevopsIcon },
	[IdentitiesPageLens.GITHUB]: { label: 'Github', icon: GithubIcon },
	[IdentitiesPageLens.SALESFORCE]: { label: 'Salesforce', icon: SalesforceIcon },
	[IdentitiesPageLens.DEMO_ATLASSIAN]: { label: 'Atlassian', icon: AtlassianIcon, isDemoOnly: true },
	[IdentitiesPageLens.OFF_BOARDED_EMPLOYEES]: { label: 'Off-Boarded Employees', icon: OffBoardedIcon },
	[IdentitiesPageLens.MULTIPLE_ACCESS_KEYS]: { label: 'Multiple Access Keys', icon: AccessKeysIcon },
	[IdentitiesPageLens.UNFEDERATED_IDENTITIES]: { label: 'Unfederated Identities', icon: UnfederatedIcon },
	[IdentitiesPageLens.RECENTLY_CREATED]: { label: 'Recently Created Identities', icon: RecentlyCreatedIcon },
	[IdentitiesPageLens.MOST_WANTED]: { label: 'Most Wanted Identities', icon: MostWantedIcon },
	[IdentitiesPageLens.DEMO_OVER_PRIVILEGED_IDENTITY]: {
		label: 'Over-Privileged Identities',
		icon: OverPrivilegedIcon,
		isDemoOnly: true,
	},
	[IdentitiesPageLens.DEMO_IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR]: {
		label: '3rd Party Identities',
		icon: ThirdPartyIcon,
		isDemoOnly: true,
	},
	[IdentitiesPageLens.ALL]: { label: 'All', icon: ResourcesIcon },
	[IdentitiesPageLens.CRITICAL_PRIORITY]: {
		label: 'Critical Priority Accounts',
		icon: ({ className }: { className?: string }) => (
			<LensAccountPriorityIcon className={className} priority={AccountPriorityEnum.CRITICAL} />
		),
	},
	[IdentitiesPageLens.HIGH_PRIORITY]: {
		label: 'High Priority Accounts',
		icon: ({ className }: { className?: string }) => (
			<LensAccountPriorityIcon className={className} priority={AccountPriorityEnum.HIGH} />
		),
	},
	[IdentitiesPageLens.MEDIUM_PRIORITY]: {
		label: 'Medium Priority Accounts',
		icon: ({ className }: { className?: string }) => (
			<LensAccountPriorityIcon className={className} priority={AccountPriorityEnum.MEDIUM} />
		),
	},
	[IdentitiesPageLens.LOW_PRIORITY]: {
		label: 'Low Priority Accounts',
		icon: ({ className }: { className?: string }) => (
			<LensAccountPriorityIcon className={className} priority={AccountPriorityEnum.LOW} />
		),
	},
	[IdentitiesPageLens.UNKNOWN_PRIORITY]: {
		label: 'Unknown Priority Accounts',
		icon: ({ className }: { className?: string }) => (
			<LensAccountPriorityIcon className={className} priority={AccountPriorityEnum.UNKNOWN} />
		),
	},
}
