import { useCallback, useEffect, useMemo, useState } from 'react'
import { RoutePaths } from '../../RoutePaths.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { FindingsStatsItem } from './FindingsStatsItem.tsx'
import { fetchIssuesIdentityAccounts, usePaginatedIssuesStats } from '../../../api/issues.ts'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { Badge } from 'antd'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { EnvWithLiteral, IssueType, PaginatedFindingsPageSearch, PaginatedIssueFilter } from '../../../schemas/issue.ts'
import { TableFilterEntry, TableSetFilterEntry } from '../../../schemas/tableFilter.ts'
import { getQueryFilterFromTableFilterModel } from '../findingsTableUtils.ts'
import { themeColors } from '../../../utils/colorUtils.ts'

function getAccountLiteralByEnvTypes(envsWithLiterals: EnvWithLiteral[], envs: EnvironmentType[]): string[] {
	return envsWithLiterals
		.filter((item) => envs.includes(item.identity_env_type))
		.map((item) => item.identity_account_literal)
}

function getEnvTypeByAccountLiteral(envsWithLiterals: EnvWithLiteral[], envs: string[]): EnvironmentType[] {
	return Array.from(
		new Set(
			envsWithLiterals
				.filter((item) => envs.includes(item.identity_account_literal))
				.map((item) => item.identity_env_type),
		),
	)
}

export const FindingsStatsPaginatedEnvsDistribution = ({ issueType = IssueType.FINDING }: { issueType: IssueType }) => {
	// Determine the route path based on issue type
	const routePath = issueType === IssueType.ITDR ? RoutePaths.Itdr : RoutePaths.Findings

	// Use the dynamic route path for navigation and search
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { tab, lens, issueFilter } = useSearch({ from: routePath }) as PaginatedFindingsPageSearch
	const navigate = useNavigate({ from: routePath })
	const [allEnvironments, setAllEnvironments] = useState<EnvWithLiteral[]>([])

	// We want our stats to be calculated without the source environments filter
	const queryWithoutSourceEnvs = useMemo(() => {
		const newIssueFilter = { ...issueFilter } as PaginatedIssueFilter
		delete newIssueFilter.identity_account_literal
		return {
			filter: getQueryFilterFromTableFilterModel((newIssueFilter || {}) as Record<string, TableFilterEntry>),
		}
	}, [issueFilter])

	// Pass issue type to the stats query
	const statsQuery = usePaginatedIssuesStats(queryWithoutSourceEnvs, tab, lens, issueType)
	const { data: issuesStats } = statsQuery

	// Fetch environments data with issue type
	useEffect(() => {
		const fetchEnvironments = async () => {
			const environments = await fetchIssuesIdentityAccounts(queryWithoutSourceEnvs, tab, lens, issueType)
			const envWithLiterals = environments
				.filter((env) => env.identity_env_type !== null)
				.map((env) => ({
					identity_env_type: env.identity_env_type as EnvironmentType,
					identity_account_literal: env.identity_account_literal,
					identity_account_literal_friendly_name: env.identity_account_literal_friendly_name,
				}))
			setAllEnvironments(envWithLiterals)
		}
		void fetchEnvironments()
	}, [queryWithoutSourceEnvs, tab, lens, issueType])

	// Get the current selected environments from the filter
	const currentEnvs = useMemo(() => {
		if (!issueFilter || !allEnvironments) {
			return []
		}
		const newIssueFilter = { ...issueFilter }
		const literals =
			((newIssueFilter?.identity_account_literal as TableSetFilterEntry | undefined)?.values as string[]) ?? []
		return getEnvTypeByAccountLiteral(allEnvironments, literals)
	}, [issueFilter, allEnvironments])

	// Generate the list of environments with their issue count
	const envsWithCount = useMemo(() => {
		if (!issuesStats || !allEnvironments) {
			return []
		}
		// Get envs that currently have issues
		const envsWithCount = Object.entries(issuesStats.count_by_env).map(([env, count]) => ({
			env: env as EnvironmentType,
			count,
		}))
		// Add envs that don't have issues in the current filter
		const uniqueIdentityEnvTypes = Array.from(new Set(allEnvironments.map((item) => item.identity_env_type)))
		for (const env of uniqueIdentityEnvTypes) {
			if (!envsWithCount.find((e) => e.env === env)) {
				envsWithCount.push({ env: env, count: 0 })
			}
		}
		return envsWithCount.sort((a, b) => a.env.localeCompare(b.env))
	}, [issuesStats, allEnvironments])

	const handleEnvironmentClick = useCallback(
		(env: EnvironmentType) => {
			// Badge with 0 is not clickable
			const envCount = envsWithCount.find((e) => e.env === env)?.count
			if (envCount === 0) {
				return
			}
			const newEnvs: EnvironmentType[] = currentEnvs.includes(env)
				? currentEnvs.filter((e) => e !== env)
				: [...currentEnvs, env]
			void navigate({
				to: routePath,
				search: (prev) => {
					const newSearch = { ...prev } as PaginatedFindingsPageSearch
					const newIssueFilter = { ...newSearch.issueFilter }

					if (newEnvs.length === 0) {
						delete newIssueFilter.identity_account_literal
					} else {
						newIssueFilter.identity_account_literal = {
							filterType: 'set',
							values: getAccountLiteralByEnvTypes(allEnvironments, newEnvs),
						}
					}

					newSearch.issueFilter = newIssueFilter
					// When filters change we need to reset the page
					delete newSearch.page
					return newSearch
				},
			})
		},
		[currentEnvs, envsWithCount, allEnvironments, navigate, routePath],
	)

	return (
		<FindingsStatsItem title="Environments" statsQuery={statsQuery}>
			{allEnvironments && issuesStats ? (
				<div className="h-18 items-start flex flex-wrap gap-1">
					{envsWithCount.map((envWithCount) => (
						<div
							className="cursor-pointer"
							key={envWithCount.env}
							onClick={() => handleEnvironmentClick(envWithCount.env)}
						>
							<Badge
								className={envWithCount.count === 0 ? 'opacity-35' : ''}
								offset={[-10, 10]}
								style={{
									color: themeColors.textIcon.secondary,
									backgroundColor: themeColors.surface.primary,
									border: `1px solid ${themeColors.border.secondary}`,
								}}
								showZero={true}
								count={envWithCount.count}
								key={envWithCount.env}
							>
								<div
									className="rounded-full cursor-pointer"
									style={{
										border: `2px solid ${currentEnvs.includes(envWithCount.env) ? themeColors.status.active : 'transparent'}`,
									}}
								>
									<IssueSourceIcon source={envWithCount.env} size={24} />
								</div>
							</Badge>
						</div>
					))}
				</div>
			) : (
				<div className="m-4 text-base text-textIcon-secondary">N/A</div>
			)}
		</FindingsStatsItem>
	)
}
