import { type CustomCellRendererProps } from 'ag-grid-react'
import { IdentitiesTableContext, ServerIdentitiesTableRow } from '../../../schemas/identity.ts'
import { highlightSearchText } from '../../../utils/highlightUtils.tsx'
import { Tooltip } from 'antd'
import { useIdentitiesQuickSearchText } from '../useIdentitiesQuickSearchText.ts'

type IdentityNameCellRendererProps = CustomCellRendererProps<
	ServerIdentitiesTableRow,
	ServerIdentitiesTableRow['literal_friendly_name'],
	IdentitiesTableContext
>

export const IdentityNameCellRenderer = ({ data }: IdentityNameCellRendererProps) => {
	const searchText = useIdentitiesQuickSearchText()
	const displayText = data?.literal_friendly_name ?? data?.literal
	return (
		<Tooltip placement="bottomLeft" title={data?.literal}>
			{highlightSearchText(displayText, searchText)}
		</Tooltip>
	)
}
