import { Edge, EdgeTypes, SmoothStepEdgeProps } from '@xyflow/react'
import { IdentitySource, ServerIdentity } from '../../../../schemas/identity.ts'
import { createAccessKeyNodes, getAwsNodesAndEdges } from '../aws/awsGraphUtils.ts'
import { getKubernetesNodesAndEdges } from '../kubernetes/kubernetesGraphUtils.ts'
import { createSaAccessKeyNodes, getGcpNodesAndEdges } from '../gcp/gcpGraphUtils.ts'
import { getGithubNodesAndEdges } from '../github/githubGraphUtils.ts'
import { createSpAuthCredentialNodes, getEntraIdNodesAndEdges } from '../entraId/entraIdGraphUtils.ts'
import { getDatabricksNodesAndEdges } from '../databricks/databricksGraphUtils.ts'
import { getAzureKVNodesAndEdges } from '../azure/azureKV/azureKVGraphUtils.ts'
import { getOktaNodesAndEdges } from '../okta/oktaGraphUtils.ts'
import { getSnowflakeNodesAndEdges, createLoginNodes } from '../snowflake/snowflakeGraphUtils.ts'
import { BareNodesColumnsType, IdentityGraphNodeType, IdentityGraphViewTypeEnum } from '../identityGraphTypes.ts'
import { groupNodes } from './nodeGroups.ts'
import { uniqWith } from 'lodash'
import { getJumpcloudNodesAndEdges } from '../jumpcloud/jumpcloudGraphUtils.ts'
import { getSalesforceNodesAndEdges } from '../salesforce/salesforceGraphUtils.ts'
import { getGoogleWorkspaceNodesAndEdges } from '../googleWorkspace/googleWorkspaceGraphUtils.ts'
import { getPostgresRoleNodesAndEdges } from '../postgresRole/postgresGraphUtils.ts'
import { getAzureDevOpsNodesAndEdges } from '../azure/azureDevops/azureDevopsGraphUtils.ts'
import { getActiveDirectoryNodesAndEdges } from '../activeDirectory/activeDirectoryGraphUtils.ts'
import { getUsageNodesAndEdges } from './usageGraph.ts'
import { ServerUsageLogGrouped } from '../../../../schemas/identities/groupedUsageLogsSchema.ts'
import { demoCreateAtlassianKeyNodes, getDemoAtlassianNodesAndEdges } from '../demoAtlassian/demoAtlassianGraphUtils.ts'
import { themeColors } from '../../../../utils/colorUtils.ts'
import { IssueType, OpenIssueStatuses } from '../../../../schemas/issue.ts'
import { CSSProperties } from 'react'

const GRAPH_GAPS = {
	usage: { x: 300, y: 180 },
	static: { x: 200, y: 200 },
}

type GetEdgeArgs = {
	source: string
	target: string
	sourceHandle?: string
	targetHandle?: string
	animated?: boolean
	type?: keyof EdgeTypes
	label?: string
	labelStyle?: Record<string, string | number>
	labelBgStyle?: Record<string, string | number>
	labelBgPadding?: [number, number]
	labelBgBorderRadius?: number
	labelShowBg?: boolean
	pathOptions?: Record<string, string | number>
	style?: CSSProperties
}

export type EdgeType = Edge & Partial<SmoothStepEdgeProps>

export const getEdge = ({
	source,
	target,
	sourceHandle,
	targetHandle,
	animated,
	type,
	label,
	labelStyle,
	labelBgStyle,
	labelBgPadding,
	labelBgBorderRadius,
	labelShowBg,
	pathOptions,
	style,
}: GetEdgeArgs): EdgeType => ({
	id: `edge_${source}_${target}`,
	source,
	sourceHandle: sourceHandle || 'right',
	targetHandle: targetHandle || 'left',
	target,
	animated,
	type,
	label,
	labelStyle,
	labelBgStyle,
	labelBgPadding,
	labelBgBorderRadius,
	labelShowBg,
	pathOptions,
	style,
})

export const processItdrEdges = (edges: EdgeType[], nodes: IdentityGraphNodeType[]): EdgeType[] => {
	return edges.map((edge) => {
		// Find source nodes
		const sourceNode = nodes.find((node) => node.id === edge.source)

		const isSourceItdrNode = sourceNode?.type === 'issue' && sourceNode?.data?.issue?.issue_type === IssueType.ITDR

		if (isSourceItdrNode) {
			return {
				...edge,
				animated: true,
				style: {
					...edge.style,
					stroke: OpenIssueStatuses.includes(sourceNode?.data?.issue?.status) ? themeColors.status.high : '',
				},
			}
		}
		return edge
	})
}

export const getNodesAndEdges = (
	graphViewType: IdentityGraphViewTypeEnum,
	identity: ServerIdentity,
	isCompact: boolean,
	groupedUsageLogs?: ServerUsageLogGrouped[],
): [IdentityGraphNodeType[], Array<EdgeType>] => {
	let [bareNodesColumns, edges]: [BareNodesColumnsType[], Array<EdgeType>] = [[], []]
	switch (identity?.source) {
		case IdentitySource.KUBERNETES_RESOURCE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getKubernetesNodesAndEdges(identity)
			break
		case IdentitySource.AWS_IAM_USER:
			if (graphViewType === IdentityGraphViewTypeEnum.STATIC) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getAwsNodesAndEdges(identity)
			} else if (graphViewType === IdentityGraphViewTypeEnum.USAGE) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getUsageNodesAndEdges({
					identity,
					groupedUsageLogs,
					getCredentialsNodes: createAccessKeyNodes,
				})
			}
			break
		case IdentitySource.AWS_IAM_ROLE:
		case IdentitySource.AWS_KEY_PAIR:
		case IdentitySource.AWS_EC2_INSTANCE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAwsNodesAndEdges(identity)
			break
		case IdentitySource.GCP_SERVICE_ACCOUNT:
			if (graphViewType === IdentityGraphViewTypeEnum.STATIC) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getGcpNodesAndEdges(identity)
			} else if (graphViewType === IdentityGraphViewTypeEnum.USAGE) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getUsageNodesAndEdges({
					identity,
					groupedUsageLogs,
					getCredentialsNodes: createSaAccessKeyNodes,
				})
			}
			break
		case IdentitySource.GITHUB_APP_INSTALLATION:
		case IdentitySource.GITHUB_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getGithubNodesAndEdges(identity)
			break
		case IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL:
			if (graphViewType === IdentityGraphViewTypeEnum.STATIC) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getEntraIdNodesAndEdges(identity)
			} else if (graphViewType === IdentityGraphViewTypeEnum.USAGE) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getUsageNodesAndEdges({
					identity,
					groupedUsageLogs,
					getCredentialsNodes: createSpAuthCredentialNodes,
				})
			}
			break
		case IdentitySource.ENTRA_ID_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getEntraIdNodesAndEdges(identity)
			break
		case IdentitySource.SNOWFLAKE_USER:
			if (graphViewType === IdentityGraphViewTypeEnum.STATIC) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getSnowflakeNodesAndEdges(identity)
			} else if (graphViewType === IdentityGraphViewTypeEnum.USAGE) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getUsageNodesAndEdges({
					identity,
					groupedUsageLogs,
					getCredentialsNodes: createLoginNodes,
				})
			}
			break
		case IdentitySource.OKTA:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getOktaNodesAndEdges(identity)
			break
		case IdentitySource.DATABRICKS_USER:
		case IdentitySource.DATABRICKS_SERVICE_PRINCIPAL:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getDatabricksNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_KV_CERTIFICATE:
		case IdentitySource.AZURE_KV_KEY:
		case IdentitySource.AZURE_KV_SECRET:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureKVNodesAndEdges(identity)
			break
		case IdentitySource.JUMPCLOUD_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getJumpcloudNodesAndEdges(identity)
			break
		case IdentitySource.SALESFORCE_USER:
		case IdentitySource.SALESFORCE_CONNECTED_APPLICATION:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getSalesforceNodesAndEdges(identity)
			break
		case IdentitySource.GOOGLE_WORKSPACE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getGoogleWorkspaceNodesAndEdges(identity)
			break
		case IdentitySource.POSTGRES_ROLE:
		case IdentitySource.DEMO_AZURE_POSTGRES_ROLE:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getPostgresRoleNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_DEVOPS_USER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureDevOpsNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_DEVOPS_SERVICE_PRINCIPAL:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureDevOpsNodesAndEdges(identity)
			break
		case IdentitySource.AZURE_DEVOPS_SECRET:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getAzureDevOpsNodesAndEdges(identity)
			break
		case IdentitySource.ACTIVE_DIRECTORY_USER:
		case IdentitySource.ACTIVE_DIRECTORY_COMPUTER:
			// eslint-disable-next-line no-extra-semi
			;[bareNodesColumns, edges] = getActiveDirectoryNodesAndEdges(identity)
			break
		case IdentitySource.DEMO_ATLASSIAN_USER:
			if (graphViewType === IdentityGraphViewTypeEnum.STATIC) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getDemoAtlassianNodesAndEdges(identity)
			} else if (graphViewType === IdentityGraphViewTypeEnum.USAGE) {
				// eslint-disable-next-line no-extra-semi
				;[bareNodesColumns, edges] = getUsageNodesAndEdges({
					identity,
					groupedUsageLogs,
					getCredentialsNodes: demoCreateAtlassianKeyNodes,
				})
			}
			break
	}

	if (isCompact) {
		// eslint-disable-next-line no-extra-semi
		;[bareNodesColumns, edges] = groupNodes(bareNodesColumns, edges)
	}

	edges = uniqWith(edges, (edgeA, edgeB) => edgeA.source === edgeB.source && edgeA.target === edgeB.target)

	const nodes: IdentityGraphNodeType[] = bareNodesColumns
		.filter((bareNodeColumn) => !!bareNodeColumn.nodes.length)
		.map((bareNodeColumn, columnIndex) => {
			const middleYPoint = (bareNodeColumn.nodes.length - 1) / 2
			const x = columnIndex * GRAPH_GAPS[graphViewType].x
			return bareNodeColumn.nodes.toReversed().map((bareNode, rowIndex) => {
				const y =
					bareNodeColumn.yPosition === 'top'
						? (0 - (rowIndex + 1)) * GRAPH_GAPS[graphViewType].y
						: (middleYPoint - rowIndex) * GRAPH_GAPS[graphViewType].y

				const position =
					graphViewType === IdentityGraphViewTypeEnum.USAGE && columnIndex !== 1 //middle column
						? { x: x + 1, y: y + 1 } // a little offset to make the gradient edge visible
						: { x, y }

				return { ...bareNode, position, selectable: true }
			})
		})
		.flat()

	// Check for ITDR issues and only style edges that are specifically related to ITDR issues
	edges = processItdrEdges(edges, nodes)

	return [nodes, edges]
}

export const getConnectedNodes = (
	nodeId: string,
	nodes: IdentityGraphNodeType[],
	edges: Edge[],
	relevantTypes: IdentityGraphNodeType['type'][] | null = null,
) => {
	const connectedEdges = edges.filter((edge) => edge.source === nodeId || edge.target === nodeId)

	const neighborIds = new Set<string>()
	connectedEdges.forEach((edge) => {
		if (edge.source !== nodeId) {
			neighborIds.add(edge.source)
		}
		if (edge.target !== nodeId) {
			neighborIds.add(edge.target)
		}
	})

	return nodes.filter((n) => neighborIds.has(n.id) && (relevantTypes ? relevantTypes.includes(n.type) : true))
}
