import { HTMLAttributes } from 'react'
import cx from 'classnames'

export const TokenBadge = ({ className, children }: Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'children'>) => {
	const badgeClassName = cx(
		'absolute left-4 flex items-center justify-center z-10 -top-1 min-w-0 min-h-0 w-5 h-4 rounded-full border-border-white border-2',
		className,
	)

	return <div className={badgeClassName}> {children}</div>
}
