import { IdentitySource, ServerIdentity } from '../../../schemas/identity.ts'
import { AwsUsageLogsContent } from './tables/aws/AwsUsageLogsContent.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { isDemo } from '../../../utils/demoUtils.ts'
import { AzureUsageLogsContent } from './tables/azure/AzureUsageLogsContent.tsx'
import { useIdentityUsageLogs, useIdentityUsageLogsGrouped } from '../../../api/identities.ts'
import { Skeleton } from 'antd'
import { SalesforceSetupAuditTrailLogsTable } from './tables/salesforce/SalesforceSetupAuditTrailLogsTable.tsx'
import { SalesforceApiTotalUsageLogsTable } from './tables/salesforce/SalesforceApiTotalUsageLogsTable.tsx'
import { GithubUsageLogsContent } from './tables/github/GithubUsageLogsContent.tsx'
import { SnowflakeUsageLogsTable } from './tables/snowflake/SnowflakeUsageLogsTable.tsx'
import { AssociationTypeEnum } from '../../../schemas/identities/usageAssociationItemSchema.ts'
import { GCPUsageLogsTable } from './tables/gcp/GcpUsageLogsTable.tsx'
import { UsageLogsGroupedContent } from './UsageLogsGroupedContent.tsx'
import { DemoAtlassianUsageTable } from './tables/demoAtlassian/DemoAtlassianUsageTable.tsx'
import { LogRocketTrackEvent, trackEvent } from '../../../services/logrocket/logrocket.ts'
import { AdUsageTable } from './tables/activeDirectory/AdUsageTable.tsx'

const onUsageCsvExport = () => {
	trackEvent(LogRocketTrackEvent.UsageCsvDataExported)
}

export const SidePanelUsage = ({
	identity,
	activeUsageLogsGroupedKeys,
	setActiveUsageLogsGroupedKeys,
}: {
	identity: ServerIdentity
	activeUsageLogsGroupedKeys: (keyof typeof AssociationTypeEnum)[]
	setActiveUsageLogsGroupedKeys: (keys: (keyof typeof AssociationTypeEnum)[]) => void
}) => {
	const {
		data: usageLogs,
		isPending: isPendingUsageLogs,
		isError: isErrorUsageLogs,
	} = useIdentityUsageLogs(identity.id)

	const {
		data: groupedUsageLogs,
		isPending: isPendingGroupedUsageLogs,
		isError: isErrorGroupedUsageLogs,
	} = useIdentityUsageLogsGrouped(identity.id)

	if (isPendingUsageLogs || isPendingGroupedUsageLogs) {
		return <Skeleton active />
	}

	if ((isErrorUsageLogs || isErrorGroupedUsageLogs) && !isDemo) {
		return 'Could not fetch usage data'
	}

	if (identity.source === IdentitySource.AWS_IAM_ROLE) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={identity.aws_iam_role?.aws_account?.id ?? ''}
				identityArn={identity.aws_iam_role?.arn ?? ''}
				usageLogs={
					usageLogs?.aws_iam_role_usage_logs?.length
						? usageLogs.aws_iam_role_usage_logs
						: identity.aws_iam_role?.usage_logs?.length
							? identity.aws_iam_role.usage_logs
							: []
				}
				onExportCsv={onUsageCsvExport}
			/>
		)
	}
	if (identity.env_type === EnvironmentType.AWS) {
		return groupedUsageLogs?.usage_logs_grouped.length ? (
			<UsageLogsGroupedContent
				activeUsageLogsGroupedKeys={activeUsageLogsGroupedKeys}
				setActiveUsageLogsGroupedKeys={setActiveUsageLogsGroupedKeys}
				usageLogs={
					isDemo ? (identity?.aws_iam_user?.usage_logs ?? []) : (usageLogs?.aws_iam_user_usage_logs ?? [])
				}
				usageLogsGrouped={groupedUsageLogs?.usage_logs_grouped || []}
			>
				{(usageLogs) => (
					<AwsUsageLogsContent
						environmentId={identity.environment_id ?? ''}
						subEnvironmentId={identity.aws_iam_user?.aws_account?.id ?? ''}
						identityArn={identity.aws_iam_user?.arn ?? ''}
						usageLogs={usageLogs}
						onExportCsv={onUsageCsvExport}
					/>
				)}
			</UsageLogsGroupedContent>
		) : (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={identity.aws_iam_user?.aws_account?.id ?? ''}
				identityArn={identity.aws_iam_user?.arn ?? ''}
				usageLogs={
					usageLogs?.aws_iam_user_usage_logs?.length
						? usageLogs.aws_iam_user_usage_logs
						: identity.aws_iam_user?.usage_logs?.length
							? identity.aws_iam_user.usage_logs
							: []
				}
				onExportCsv={onUsageCsvExport}
			/>
		)
	}

	if (identity.env_type === EnvironmentType.GITHUB && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.github_user?.usage_logs ?? []}
				onExportCsv={onUsageCsvExport}
			/>
		)
	}

	if (identity.env_type === EnvironmentType.GITHUB) {
		return (
			<GithubUsageLogsContent
				auditLogs={usageLogs?.github_user_audit_logs || []}
				identity={identity}
				onExportCsv={onUsageCsvExport}
			/>
		)
	}

	if (identity.source === IdentitySource.GCP_SERVICE_ACCOUNT) {
		return groupedUsageLogs?.usage_logs_grouped.length ? (
			<UsageLogsGroupedContent
				activeUsageLogsGroupedKeys={activeUsageLogsGroupedKeys}
				setActiveUsageLogsGroupedKeys={setActiveUsageLogsGroupedKeys}
				usageLogs={
					isDemo
						? (identity.gcp_service_account?.usage_logs ?? [])
						: (usageLogs?.gcp_service_account_usage_logs ?? [])
				}
				usageLogsGrouped={groupedUsageLogs?.usage_logs_grouped || []}
			>
				{(usageLogs) => (
					<GCPUsageLogsTable
						usageLogs={usageLogs}
						identityName={identity.literal_friendly_name ?? identity.literal}
						onExportCsv={onUsageCsvExport}
					/>
				)}
			</UsageLogsGroupedContent>
		) : (
			<GCPUsageLogsTable
				usageLogs={identity.gcp_service_account?.usage_logs ?? []}
				identityName={identity.literal_friendly_name ?? identity.literal}
				onExportCsv={onUsageCsvExport}
			/>
		)
	}

	if (identity.source === IdentitySource.ACTIVE_DIRECTORY_USER) {
		return (
			<AdUsageTable
				data={usageLogs?.active_directory_user_usage_logs || []}
				identityName={identity.literal_friendly_name || identity.literal || 'Unknown'}
			/>
		)
	}

	if (identity.source === IdentitySource.ENTRA_ID_USER) {
		return (
			<AzureUsageLogsContent
				identity={identity}
				usageLogs={
					usageLogs?.entra_id_user_usage_logs?.length
						? usageLogs.entra_id_user_usage_logs
						: identity.entra_id_user?.usage_logs?.length
							? identity.entra_id_user.usage_logs
							: []
				}
				onExportCsv={onUsageCsvExport}
			></AzureUsageLogsContent>
		)
	}

	if (identity.source === IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL) {
		return groupedUsageLogs?.usage_logs_grouped.length ? (
			<UsageLogsGroupedContent
				activeUsageLogsGroupedKeys={activeUsageLogsGroupedKeys}
				setActiveUsageLogsGroupedKeys={setActiveUsageLogsGroupedKeys}
				usageLogs={
					isDemo
						? (identity.entra_id_service_principal?.usage_logs ?? [])
						: (usageLogs?.entra_id_service_principal_usage_logs ?? [])
				}
				usageLogsGrouped={groupedUsageLogs?.usage_logs_grouped || []}
			>
				{(usageLogs) => (
					<AzureUsageLogsContent identity={identity} usageLogs={usageLogs} onExportCsv={onUsageCsvExport} />
				)}
			</UsageLogsGroupedContent>
		) : (
			<AzureUsageLogsContent
				identity={identity}
				usageLogs={
					usageLogs?.entra_id_service_principal_usage_logs?.length
						? usageLogs.entra_id_service_principal_usage_logs
						: identity.entra_id_service_principal?.usage_logs?.length
							? identity.entra_id_service_principal.usage_logs
							: []
				}
				onExportCsv={onUsageCsvExport}
			/>
		)
	}

	if (identity.source === IdentitySource.OKTA && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.okta_user?.demo_usage_logs ?? []}
				onExportCsv={onUsageCsvExport}
			/>
		)
	}

	if (identity.source === IdentitySource.SALESFORCE_USER) {
		return <SalesforceSetupAuditTrailLogsTable identity={identity} onExportCsv={onUsageCsvExport} />
	}

	if (identity.source === IdentitySource.SALESFORCE_CONNECTED_APPLICATION) {
		return <SalesforceApiTotalUsageLogsTable identity={identity} onExportCsv={onUsageCsvExport} />
	}

	if (identity.source === IdentitySource.SNOWFLAKE_USER) {
		return groupedUsageLogs?.usage_logs_grouped.length || isDemo ? (
			<UsageLogsGroupedContent
				activeUsageLogsGroupedKeys={activeUsageLogsGroupedKeys}
				setActiveUsageLogsGroupedKeys={setActiveUsageLogsGroupedKeys}
				usageLogs={usageLogs?.snowflake_user_usage_logs || []}
				usageLogsGrouped={groupedUsageLogs?.usage_logs_grouped || []}
			>
				{(usageLogs) => (
					<SnowflakeUsageLogsTable
						username={identity.literal_friendly_name ?? identity.literal ?? ''}
						usageLogs={usageLogs}
						onExportCsv={onUsageCsvExport}
					/>
				)}
			</UsageLogsGroupedContent>
		) : (
			<SnowflakeUsageLogsTable
				usageLogs={usageLogs?.snowflake_user_usage_logs ?? []}
				username={identity.literal_friendly_name ?? identity.literal ?? ''}
				onExportCsv={onUsageCsvExport}
			/>
		)
	}
	if (identity.source === IdentitySource.DEMO_ATLASSIAN_USER) {
		return (
			<UsageLogsGroupedContent
				activeUsageLogsGroupedKeys={activeUsageLogsGroupedKeys}
				setActiveUsageLogsGroupedKeys={setActiveUsageLogsGroupedKeys}
				usageLogs={identity.demo_atlassian_user?.demo_usage_logs || []}
				usageLogsGrouped={groupedUsageLogs?.usage_logs_grouped || []}
			>
				{(usageLogs) => <DemoAtlassianUsageTable entries={usageLogs} />}
			</UsageLogsGroupedContent>
		)
	}

	return 'No usage data'
}
