import { z } from 'zod'

export const UsageAssociationToActionsSchema = z.record(z.array(z.string()))

export type UsageAssociationToActionsItem = z.infer<typeof UsageAssociationToActionsSchema>

export const UsageActionSchema = z.object({
	name: z.string(),
})

export type UsageActionItem = z.infer<typeof UsageActionSchema>

export enum ActionTypeEnum {
	AccessAnalyzer = 'access-analyzer',
	Account = 'account',
	ACM = 'acm',
	ACMPCA = 'acm-pca',
	AgreementMarketplace = 'agreement-marketplace',
	Airflow = 'airflow',
	AmazonMQ = 'amazonmq',
	Amplify = 'amplify',
	APIGateway = 'apigateway',
	AppFlow = 'appflow',
	ApplicationCostProfiler = 'application-cost-profiler',
	ApplicationInsights = 'application-insights',
	AppMesh = 'appmesh',
	AppRunner = 'apprunner',
	AppStream = 'appstream',
	AppSync = 'appsync',
	APS = 'aps',
	ArcZonalShift = 'arc-zonal-shift',
	Athena = 'athena',
	AutoScaling = 'autoscaling',
	AWSPaymentEncryption = 'aws-payment-encryption',
	Backup = 'backup',
	BackupGateway = 'backup-gateway',
	Batch = 'batch',
	BCMDataExports = 'bcm-data-exports',
	Bedrock = 'bedrock',
	BillingConductor = 'billingconductor',
	BillingConsole = 'billingconsole',
	Budgets = 'budgets',
	CE = 'ce',
	Chime = 'chime',
	Cleanrooms = 'cleanrooms',
	CloudControlAPI = 'cloudcontrolapi',
	CloudDirectory = 'clouddirectory',
	CloudFormation = 'cloudformation',
	CloudFront = 'cloudfront',
	CloudHSM = 'cloudhsm',
	CloudSearch = 'cloudsearch',
	CloudShell = 'cloudshell',
	CloudTrail = 'cloudtrail',
	CodeArtifact = 'codeartifact',
	CodeBuild = 'codebuild',
	CodeCommit = 'codecommit',
	CodeDeploy = 'codedeploy',
	CodePipeline = 'codepipeline',
	CodeStar = 'codestar',
	CodeWhisperer = 'codewhisperer',
	CognitoIdentity = 'cognito-identity',
	CognitoIDP = 'cognito-idp',
	ComputeOptimizer = 'compute-optimizer',
	Config = 'config',
	ControlTower = 'controltower',
	CostOptimizationHub = 'cost-optimization-hub',
	CUR = 'cur',
	DAX = 'dax',
	DataPipeline = 'datapipeline',
	DataZone = 'datazone',
	DevOpsGuru = 'devops-guru',
	DirectConnect = 'directconnect',
	DiscoveryMarketplace = 'discovery-marketplace',
	DLM = 'dlm',
	DMS = 'dms',
	DS = 'ds',
	DynamoDB = 'dynamodb',
	EC2 = 'ec2',
	ECR = 'ecr',
	ECRPublic = 'ecr-public',
	ECS = 'ecs',
	EKS = 'eks',
	ElastiCache = 'elasticache',
	ElasticBeanstalk = 'elasticbeanstalk',
	ElasticFileSystem = 'elasticfilesystem',
	ElasticLoadBalancing = 'elasticloadbalancing',
	ElasticMapReduce = 'elasticmapreduce',
	ElasticTranscoder = 'elastictranscoder',
	ES = 'es',
	Events = 'events',
	Firehose = 'firehose',
	FraudDetector = 'frauddetector',
	FreeTier = 'freetier',
	FSx = 'fsx',
	GameLift = 'gamelift',
	Geo = 'geo',
	GetApplicationInstance = 'GetApplicationInstance',
	GetApplicationTemplate = 'GetApplicationTemplate',
	GetProfile = 'GetProfile',
	Glacier = 'glacier',
	GlobalAccelerator = 'globalaccelerator',
	Glue = 'glue',
	Greengrass = 'greengrass',
	GuardDuty = 'guardduty',
	Health = 'health',
	IAM = 'iam',
	IdentityStore = 'identitystore',
	IdentityStoreAuth = 'identitystore-auth',
	ImageBuilder = 'imagebuilder',
	Inspector = 'inspector',
	IoT = 'iot',
	IoTWireless = 'iotwireless',
	Kafka = 'kafka',
	Kendra = 'kendra',
	Kinesis = 'kinesis',
	KinesisAnalytics = 'kinesisanalytics',
	KinesisVideo = 'kinesisvideo',
	KMS = 'kms',
	Lambda = 'lambda',
	LicenseManager = 'license-manager',
	LicenseManagerLinuxSubscriptions = 'license-manager-linux-subscriptions',
	Lightsail = 'lightsail',
	Logs = 'logs',
	ManagedBlockchain = 'managedblockchain',
	MarketplaceCatalog = 'marketplacecatalog',
	MediaPackage = 'mediapackage',
	Monitoring = 'monitoring',
	NetworkFirewall = 'network-firewall',
	Notifications = 'notifications',
	OAM = 'oam',
	Organizations = 'organizations',
	OpsWorks = 'opsworks',
	OpsWorksCM = 'opsworks-cm',
	OSIS = 'osis',
	Outposts = 'outposts',
	Payments = 'payments',
	PI = 'pi',
	Pinpoint = 'pinpoint',
	Polly = 'polly',
	PriceList = 'pricelist',
	Q = 'q',
	QBusiness = 'qbusiness',
	QLDB = 'qldb',
	QuickSight = 'quicksight',
	RAM = 'ram',
	RDS = 'rds',
	Redshift = 'redshift',
	RedshiftServerless = 'redshift-serverless',
	Rekognition = 'rekognition',
	ResilienceHub = 'resiliencehub',
	ResourceExplorer2 = 'resource-explorer-2',
	ResourceGroups = 'resource-groups',
	RolesAnywhere = 'rolesanywhere',
	Route53 = 'route53',
	Route53Domains = 'route53domains',
	Route53Profiles = 'route53profiles',
	Route53RecoveryControlConfig = 'route53-recovery-control-config',
	Route53Resolver = 'route53resolver',
	S3 = 's3',
	SageMaker = 'sagemaker',
	SavingsPlans = 'savingsplans',
	Scheduler = 'scheduler',
	SecurityHub = 'securityhub',
	SecretManager = 'secretsmanager',
	ServiceCatalog = 'servicecatalog',
	ServiceCatalogAppRegistry = 'servicecatalog-appregistry',
	ServiceDiscovery = 'servicediscovery',
	ServiceQuotas = 'servicequotas',
	SES = 'ses',
	Shield = 'shield',
	SignIn = 'signin',
	Signer = 'signer',
	SimplifiedService = 'Simplified_Service',
	SNS = 'sns',
	SQS = 'sqs',
	SSM = 'ssm',
	SSO = 'sso',
	SSODirectory = 'sso-directory',
	States = 'states',
	StorageGateway = 'storagegateway',
	STS = 'sts',
	Support = 'support',
	SupportPlans = 'supportplans',
	SupportSubscription = 'support-subscription',
	Sustainability = 'sustainability',
	Tagging = 'tagging',
	Tax = 'tax',
	TaxConsole = 'taxconsole',
	TimeStream = 'timestream',
	TranscribeStreaming = 'transcribestreaming',
	Transfer = 'transfer',
	TrustedAdvisor = 'trustedadvisor',
	UserSubscriptions = 'user-subscriptions',
	WAF = 'waf',
	WAFRegional = 'waf-regional',
	WAFv2 = 'wafv2',
	WorkMail = 'workmail',
	WorkSpaces = 'workspaces',
	XRay = 'xray',
	Zocalo = 'zocalo',
	Select = 'SELECT',
	Show = 'SHOW',
	Describe = 'DESCRIBE',
	CreateTable = 'CREATE_TABLE',
	Copy = 'COPY',
	Insert = 'INSERT',
	Merge = 'MERGE',
	Rollback = 'ROLLBACK',
	Delete = 'DELETE',
	Commit = 'COMMIT',
	Update = 'UPDATE',
	// GCP Resource Types
	IamRole = 'iam_role',
	CloudSqlDatabase = 'cloudsql_database',
	ClientAuthConfigClient = 'client_auth_config_client',
	IdentityToolkitProject = 'identitytoolkit_project',
	CloudFunction = 'cloud_function',
	PubsubSubscription = 'pubsub_subscription',
	GcsBucket = 'gcs_bucket',
	Deployment = 'deployment',
	LoggingSink = 'logging_sink',
	CloudRunJob = 'cloud_run_job',
	ServiceAccount = 'service_account',
	GceInstanceGroupManager = 'gce_instance_group_manager',
	CloudComposerEnvironment = 'cloud_composer_environment',
	DnsManagedZone = 'dns_managed_zone',
	BigQueryResource = 'bigquery_resource',
	BigQueryDataset = 'bigquery_dataset',
	DatastoreDatabase = 'datastore_database',
	IdentityToolkitTenant = 'identitytoolkit_tenant',
	K8sCluster = 'k8s_cluster',
	GceInstance = 'gce_instance',
	GceInstanceGroup = 'gce_instance_group',
	GceUrlMap = 'gce_url_map',
	GceInstanceTemplate = 'gce_instance_template',
	CloudRunRevision = 'cloud_run_revision',
	PubsubTopic = 'pubsub_topic',
	AuditedResource = 'audited_resource',
	GceReservedAddress = 'gce_reserved_address',
	GaeApp = 'gae_app',
	Project = 'project',
	Build = 'build',
	DemoJiraCreateIssue = 'Jira Create Issue',
	DemoJiraUpdateIssue = 'Jira Update Issue',
}
