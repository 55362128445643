import React from 'react'
import { ActionTypeEnum } from '../../schemas/identities/usageActionItemSchema'
import ActivityLogoIcon from '../../assets/actionNodesIcons/action_activity_logo.svg?react'
import Ec2LogoIcon from '../../assets/actionNodesIcons/action_ec2_logo.svg?react'
import S3LogoIcon from '../../assets/actionNodesIcons/action_s3_logo.svg?react'
import SqsLogoIcon from '../../assets/actionNodesIcons/action_sqs_logo.svg?react'
import StsLogoIcon from '../../assets/actionNodesIcons/action_sts_logo.svg?react'
import KmsLogoIcon from '../../assets/actionNodesIcons/action_kms_logo.svg?react'
import SelectLogoIcon from '../../assets/actionNodesIcons/action_select_logo.svg?react'
import ShowLogoIcon from '../../assets/actionNodesIcons/action_show_logo.svg?react'
import DescribeLogoIcon from '../../assets/actionNodesIcons/action_describe_logo.svg?react'
import CreateTableLogoIcon from '../../assets/actionNodesIcons/action_create_table_logo.svg?react'
import CopyLogoIcon from '../../assets/actionNodesIcons/action_copy_logo.svg?react'
import InsertLogoIcon from '../../assets/actionNodesIcons/action_insert_logo.svg?react'
import MergeLogoIcon from '../../assets/actionNodesIcons/action_merge_logo.svg?react'
import RollbackLogoIcon from '../../assets/actionNodesIcons/action_rollback_logo.svg?react'
import DeleteLogoIcon from '../../assets/actionNodesIcons/action_delete_logo.svg?react'
import CommitLogoIcon from '../../assets/actionNodesIcons/action_commit_logo.svg?react'
import UpdateLogoIcon from '../../assets/actionNodesIcons/action_update_logo.svg?react'
import GaeAppLogoIcon from '../../assets/actionNodesIcons/action_gaeApp_logo_gcp.svg?react'
import AuditLogoIcon from '../../assets/actionNodesIcons/action_audit_logo_gcp.svg?react'
import K8sLogoIcon from '../../assets/actionNodesIcons/action_k8s_logo_gcp.svg?react'
import DatastoreLogoIcon from '../../assets/actionNodesIcons/action_datastore_logo_gcp.svg?react'
import BigQueryLogoIcon from '../../assets/actionNodesIcons/action_bigquery_logo_gcp.svg?react'
import DnsLogoIcon from '../../assets/actionNodesIcons/action_dns_logo_gcp.svg?react'
import ComposerLogoIcon from '../../assets/actionNodesIcons/action_composer_logo_gcp.svg?react'
import GceLogoIcon from '../../assets/actionNodesIcons/action_gce_logo_gcp.svg?react'
import CloudRunLogoIcon from '../../assets/actionNodesIcons/action_cloudRun_logo_gcp.svg?react'
import LoggingLogoIcon from '../../assets/actionNodesIcons/action_logging_logo_gcp.svg?react'
import CloudBuildLogoIcon from '../../assets/actionNodesIcons/action_cloudBuild_logo_gcp.svg?react'
import CloudDeployLogoIcon from '../../assets/actionNodesIcons/action_cloudDeploy_logo_gcp.svg?react'
import CloudStorageLogoIcon from '../../assets/actionNodesIcons/action_cloudStorage_logo_gcp.svg?react'
import PubsubLogoIcon from '../../assets/actionNodesIcons/action_pubsub_logo_gcp.svg?react'
import CloudFunctionLogoIcon from '../../assets/actionNodesIcons/action_cloudFunction_logo_gcp.svg?react'
import ClientAuthConfigLogoIcon from '../../assets/actionNodesIcons/action_clientAuthConfig_logo_gcp.svg?react'
import CloudSqlLogoIcon from '../../assets/actionNodesIcons/action_cloudSql_logo_gcp.svg?react'
import IamLogoIcon from '../../assets/actionNodesIcons/action_iam_logo_gcp.svg?react'
import ProjectLogoIcon from '../../assets/actionNodesIcons/action_project_logo_gcp.svg?react'
import JiraIcon from '../../assets/jira_icon.svg?react'

export const UsageActionIcon: React.FunctionComponent<{
	name?: string | null
	color?: string
	size?: number
	style?: React.CSSProperties
}> = ({ name, color = '#E4750D', size = 28, style }) => {
	let IconComponent
	switch (name) {
		case ActionTypeEnum.EC2:
			IconComponent = Ec2LogoIcon
			break
		case ActionTypeEnum.KMS:
			IconComponent = KmsLogoIcon
			break
		case ActionTypeEnum.STS:
			IconComponent = StsLogoIcon
			break
		case ActionTypeEnum.S3:
			IconComponent = S3LogoIcon
			break
		case ActionTypeEnum.SQS:
			IconComponent = SqsLogoIcon
			break
		case ActionTypeEnum.Select:
			IconComponent = SelectLogoIcon
			break
		case ActionTypeEnum.Show:
			IconComponent = ShowLogoIcon
			break
		case ActionTypeEnum.Describe:
			IconComponent = DescribeLogoIcon
			break
		case ActionTypeEnum.CreateTable:
			IconComponent = CreateTableLogoIcon
			break
		case ActionTypeEnum.Copy:
			IconComponent = CopyLogoIcon
			break
		case ActionTypeEnum.Insert:
			IconComponent = InsertLogoIcon
			break
		case ActionTypeEnum.Merge:
			IconComponent = MergeLogoIcon
			break
		case ActionTypeEnum.Rollback:
			IconComponent = RollbackLogoIcon
			break
		case ActionTypeEnum.Delete:
			IconComponent = DeleteLogoIcon
			break
		case ActionTypeEnum.Commit:
			IconComponent = CommitLogoIcon
			break
		case ActionTypeEnum.Update:
			IconComponent = UpdateLogoIcon
			break
		case ActionTypeEnum.IamRole:
		case ActionTypeEnum.IdentityToolkitProject:
		case ActionTypeEnum.ServiceAccount:
		case ActionTypeEnum.IdentityToolkitTenant:
			IconComponent = IamLogoIcon
			break

		case ActionTypeEnum.CloudSqlDatabase:
			IconComponent = CloudSqlLogoIcon
			break

		case ActionTypeEnum.ClientAuthConfigClient:
			IconComponent = ClientAuthConfigLogoIcon
			break

		case ActionTypeEnum.CloudFunction:
			IconComponent = CloudFunctionLogoIcon
			break

		case ActionTypeEnum.PubsubSubscription:
		case ActionTypeEnum.PubsubTopic:
			IconComponent = PubsubLogoIcon
			break

		case ActionTypeEnum.GcsBucket:
			IconComponent = CloudStorageLogoIcon
			break

		case ActionTypeEnum.Deployment:
			IconComponent = CloudDeployLogoIcon
			break

		case ActionTypeEnum.Build:
			IconComponent = CloudBuildLogoIcon
			break

		case ActionTypeEnum.LoggingSink:
			IconComponent = LoggingLogoIcon
			break

		case ActionTypeEnum.CloudRunJob:
		case ActionTypeEnum.CloudRunRevision:
			IconComponent = CloudRunLogoIcon
			break

		case ActionTypeEnum.GceInstanceGroupManager:
		case ActionTypeEnum.GceInstance:
		case ActionTypeEnum.GceInstanceGroup:
		case ActionTypeEnum.GceUrlMap:
		case ActionTypeEnum.GceInstanceTemplate:
		case ActionTypeEnum.GceReservedAddress:
			IconComponent = GceLogoIcon
			break

		case ActionTypeEnum.CloudComposerEnvironment:
			IconComponent = ComposerLogoIcon
			break

		case ActionTypeEnum.DnsManagedZone:
			IconComponent = DnsLogoIcon
			break

		case ActionTypeEnum.BigQueryResource:
			IconComponent = BigQueryLogoIcon
			break

		case ActionTypeEnum.BigQueryDataset:
			IconComponent = BigQueryLogoIcon
			break

		case ActionTypeEnum.DatastoreDatabase:
			IconComponent = DatastoreLogoIcon
			break

		case ActionTypeEnum.K8sCluster:
			IconComponent = K8sLogoIcon
			break

		case ActionTypeEnum.AuditedResource:
			IconComponent = AuditLogoIcon
			break

		case ActionTypeEnum.GaeApp:
			IconComponent = GaeAppLogoIcon
			break

		case ActionTypeEnum.Project:
			IconComponent = ProjectLogoIcon
			break

		case ActionTypeEnum.DemoJiraCreateIssue:
		case ActionTypeEnum.DemoJiraUpdateIssue:
			IconComponent = JiraIcon
			break

		case ActionTypeEnum.IAM:
		case ActionTypeEnum.SageMaker:
		case ActionTypeEnum.WAF:
		case ActionTypeEnum.AWSPaymentEncryption:
		case ActionTypeEnum.AppMesh:
		case ActionTypeEnum.CodeDeploy:
		case ActionTypeEnum.QBusiness:
		case ActionTypeEnum.Organizations:
		case ActionTypeEnum.SavingsPlans:
		case ActionTypeEnum.NetworkFirewall:
		case ActionTypeEnum.GlobalAccelerator:
		case ActionTypeEnum.IdentityStoreAuth:
		case ActionTypeEnum.Greengrass:
		case ActionTypeEnum.CodeArtifact:
		case ActionTypeEnum.Support:
		case ActionTypeEnum.ManagedBlockchain:
		case ActionTypeEnum.CloudHSM:
		case ActionTypeEnum.ApplicationCostProfiler:
		case ActionTypeEnum.RolesAnywhere:
		case ActionTypeEnum.SSO:
		case ActionTypeEnum.ServiceDiscovery:
		case ActionTypeEnum.Budgets:
		case ActionTypeEnum.DataPipeline:
		case ActionTypeEnum.GuardDuty:
		case ActionTypeEnum.AppRunner:
		case ActionTypeEnum.SNS:
		case ActionTypeEnum.ECS:
		case ActionTypeEnum.Kafka:
		case ActionTypeEnum.TaxConsole:
		case ActionTypeEnum.Route53:
		case ActionTypeEnum.ResourceExplorer2:
		case ActionTypeEnum.ServiceCatalogAppRegistry:
		case ActionTypeEnum.CUR:
		case ActionTypeEnum.Firehose:
		case ActionTypeEnum.ElastiCache:
		case ActionTypeEnum.CE:
		case ActionTypeEnum.GetProfile:
		case ActionTypeEnum.Shield:
		case ActionTypeEnum.WorkSpaces:
		case ActionTypeEnum.WorkMail:
		case ActionTypeEnum.AppStream:
		case ActionTypeEnum.TrustedAdvisor:
		case ActionTypeEnum.SSODirectory:
		case ActionTypeEnum.LicenseManager:
		case ActionTypeEnum.Q:
		case ActionTypeEnum.CloudSearch:
		case ActionTypeEnum.MarketplaceCatalog:
		case ActionTypeEnum.CloudFront:
		case ActionTypeEnum.ServiceQuotas:
		case ActionTypeEnum.AutoScaling:
		case ActionTypeEnum.APIGateway:
		case ActionTypeEnum.Redshift:
		case ActionTypeEnum.Transfer:
		case ActionTypeEnum.FreeTier:
		case ActionTypeEnum.Glacier:
		case ActionTypeEnum.ControlTower:
		case ActionTypeEnum.Tagging:
		case ActionTypeEnum.DLM:
		case ActionTypeEnum.DevOpsGuru:
		case ActionTypeEnum.Geo:
		case ActionTypeEnum.RAM:
		case ActionTypeEnum.CloudFormation:
		case ActionTypeEnum.Kinesis:
		case ActionTypeEnum.Notifications:
		case ActionTypeEnum.WAFv2:
		case ActionTypeEnum.ES:
		case ActionTypeEnum.Route53RecoveryControlConfig:
		case ActionTypeEnum.CognitoIdentity:
		case ActionTypeEnum.Payments:
		case ActionTypeEnum.Account:
		case ActionTypeEnum.Sustainability:
		case ActionTypeEnum.APS:
		case ActionTypeEnum.Polly:
		case ActionTypeEnum.MediaPackage:
		case ActionTypeEnum.Zocalo:
		case ActionTypeEnum.ElasticFileSystem:
		case ActionTypeEnum.Lambda:
		case ActionTypeEnum.DiscoveryMarketplace:
		case ActionTypeEnum.IdentityStore:
		case ActionTypeEnum.WAFRegional:
		case ActionTypeEnum.SecurityHub:
		case ActionTypeEnum.SES:
		case ActionTypeEnum.CodeCommit:
		case ActionTypeEnum.Route53Resolver:
		case ActionTypeEnum.CodeWhisperer:
		case ActionTypeEnum.DataZone:
		case ActionTypeEnum.GameLift:
		case ActionTypeEnum.KinesisVideo:
		case ActionTypeEnum.AmazonMQ:
		case ActionTypeEnum.BackupGateway:
		case ActionTypeEnum.SupportPlans:
		case ActionTypeEnum.DMS:
		case ActionTypeEnum.Amplify:
		case ActionTypeEnum.OAM:
		case ActionTypeEnum.ResilienceHub:
		case ActionTypeEnum.IoTWireless:
		case ActionTypeEnum.StorageGateway:
		case ActionTypeEnum.Kendra:
		case ActionTypeEnum.Lightsail:
		case ActionTypeEnum.KinesisAnalytics:
		case ActionTypeEnum.SSM:
		case ActionTypeEnum.CloudTrail:
		case ActionTypeEnum.SecretManager:
		case ActionTypeEnum.ACM:
		case ActionTypeEnum.GetApplicationTemplate:
		case ActionTypeEnum.ACMPCA:
		case ActionTypeEnum.SignIn:
		case ActionTypeEnum.FSx:
		case ActionTypeEnum.ElasticLoadBalancing:
		case ActionTypeEnum.CostOptimizationHub:
		case ActionTypeEnum.ECRPublic:
		case ActionTypeEnum.CodeStar:
		case ActionTypeEnum.GetApplicationInstance:
		case ActionTypeEnum.CognitoIDP:
		case ActionTypeEnum.DirectConnect:
		case ActionTypeEnum.ElasticMapReduce:
		case ActionTypeEnum.QLDB:
		case ActionTypeEnum.OpsWorks:
		case ActionTypeEnum.RedshiftServerless:
		case ActionTypeEnum.Config:
		case ActionTypeEnum.AppSync:
		case ActionTypeEnum.OpsWorksCM:
		case ActionTypeEnum.Events:
		case ActionTypeEnum.CodePipeline:
		case ActionTypeEnum.AppFlow:
		case ActionTypeEnum.BillingConductor:
		case ActionTypeEnum.QuickSight:
		case ActionTypeEnum.Rekognition:
		case ActionTypeEnum.DynamoDB:
		case ActionTypeEnum.EKS:
		case ActionTypeEnum.ResourceGroups:
		case ActionTypeEnum.ECR:
		case ActionTypeEnum.BCMDataExports:
		case ActionTypeEnum.Bedrock:
		case ActionTypeEnum.FraudDetector:
		case ActionTypeEnum.States:
		case ActionTypeEnum.ElasticBeanstalk:
		case ActionTypeEnum.Athena:
		case ActionTypeEnum.Route53Profiles:
		case ActionTypeEnum.CloudControlAPI:
		case ActionTypeEnum.AgreementMarketplace:
		case ActionTypeEnum.PriceList:
		case ActionTypeEnum.SimplifiedService:
		case ActionTypeEnum.Batch:
		case ActionTypeEnum.ImageBuilder:
		case ActionTypeEnum.Airflow:
		case ActionTypeEnum.Route53Domains:
		case ActionTypeEnum.Glue:
		case ActionTypeEnum.LicenseManagerLinuxSubscriptions:
		case ActionTypeEnum.ApplicationInsights:
		case ActionTypeEnum.AccessAnalyzer:
		case ActionTypeEnum.Cleanrooms:
		case ActionTypeEnum.RDS:
		case ActionTypeEnum.Logs:
		case ActionTypeEnum.IoT:
		case ActionTypeEnum.Outposts:
		case ActionTypeEnum.SupportSubscription:
		case ActionTypeEnum.Tax:
		case ActionTypeEnum.DAX:
		case ActionTypeEnum.DS:
		case ActionTypeEnum.Chime:
		case ActionTypeEnum.ServiceCatalog:
		case ActionTypeEnum.CloudDirectory:
		case ActionTypeEnum.ComputeOptimizer:
		case ActionTypeEnum.Scheduler:
		case ActionTypeEnum.BillingConsole:
		case ActionTypeEnum.CodeBuild:
		case ActionTypeEnum.XRay:
		case ActionTypeEnum.ArcZonalShift:
		case ActionTypeEnum.Pinpoint:
		case ActionTypeEnum.Signer:
		case ActionTypeEnum.Monitoring:
		case ActionTypeEnum.Health:
		case ActionTypeEnum.OSIS:
		case ActionTypeEnum.TranscribeStreaming:
		case ActionTypeEnum.CloudShell:
		case ActionTypeEnum.ElasticTranscoder:
		case ActionTypeEnum.PI:
		default:
			IconComponent = ActivityLogoIcon
			break
	}

	return <IconComponent style={{ color, width: size, height: size, ...style }} />
}
