import { Button } from 'antd'
import TokenLogo from '../assets/token_logo.svg?react'

export const ErrorBoundaryContent = () => {
	const handleReset = () => {
		window.location.reload()
	}

	return (
		<div className="flex flex-col items-center justify-center h-screen bg-background-primary">
			<div className="w-48 h-48 mb-8 p-6 bg-black rounded-lg flex items-center justify-center">
				<TokenLogo className="w-full h-full" />
			</div>
			<h1 className="text-2xl font-semibold mb-4 text-text-primary">Whoops! Something went wrong!</h1>
			<p className="text-lg mb-8 text-text-secondary">
				An unexpected error occurred, if the error persists please contact the Token team
			</p>
			<Button type="primary" size="large" onClick={handleReset}>
				Reload The Page
			</Button>
		</div>
	)
}
