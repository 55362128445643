import LogRocket from 'logrocket'
import { settings } from '../../env-settings'
import { type User } from '@frontegg/redux-store/auth/interfaces'
import { type ITenantsResponseV2 } from '@frontegg/rest-api/tenants/interfaces'
import { isUserFromTokenDomain } from '../../utils.ts'
import { isDemo } from '../../utils/demoUtils.ts'

export const trackEvent = (eventName: LogRocketTrackEvent, eventProperties?: Parameters<typeof LogRocket.track>[1]) => {
	LogRocket.track(eventName, eventProperties)
}

export const InitLogRocket = (user: User, activeTenant: ITenantsResponseV2, isImpersonating: boolean) => {
	if (isUserFromTokenDomain(user) || !settings.viteLogRocketAppId || isDemo || isImpersonating) {
		return
	}
	LogRocket.init(settings.viteLogRocketAppId, {
		network: {
			requestSanitizer: (request) => {
				if (request.url.includes('/api/environments') && request.method === 'POST') {
					return {
						...request,
						body: '',
					}
				}
				return request
			},
		},
	})
	LogRocket.identify(user.id, {
		name: user.name,
		email: user.email,
		env: settings.mode,
		activeCustomerId: activeTenant.tenantId,
		activeCustomerName: activeTenant.name,
	})
}

export enum LogRocketTrackEvent {
	IssueNameInSideBarClicked = 'Issue Name In SideBar Clicked',
	IssueDrawerOpenedFromFindingsTable = 'Issue Drawer Opened From Findings Table',
	IssueDrawerOpenedFromRelatedFindingsTable = 'Issue Drawer Opened From Related Findings Table',
	IssueDrawerClosed = 'Issue Drawer Closed',
	DescriptionShowMoreClicked = 'Description Show More Clicked',
	DescriptionShowLessClicked = 'Description Show Less Clicked',
	OpenJsonClicked = 'Open Json Clicked',
	OpenJsonClosed = 'Open Json Closed',
	IssuesTextSearchChanged = 'Issues Text Search Changed',
	MostWantedIdentityFromMetricsClicked = 'Most Wanted Identity From Metrics Clicked',
	SideBarMenuClicked = 'Side Bar Menu Clicked',

	// Issue Settings
	IssueSettingsDaysThresholdChanged = 'Issue Settings Days Threshold Changed',
	IssueSettingsPriorityChanged = 'Issue Settings Priority Changed',
	IssueSettingsEditClicked = 'Issue Settings Edit Clicked',
	IssueSettingsSaveClicked = 'Issue Settings Save Clicked',
	IssueSettingsExcludeFromReportClicked = 'Issue Settings Exclude From Report Clicked',

	UsageGraphButtonClicked = 'Usage Graph Button Clicked',
	UsageAssociationNodeClicked = 'Usage Graph Association Node Clicked',
	UsageSidePanelExpanded = 'Usage Side Panel Expanded',
	UsageCsvDataExported = 'Usage CSV Data Exported',

	// New Feature Announcement
	NewFeatureAnnouncementModalButtonClicked = 'New Feature Announcement Modal Button Clicked',
	NewFeatureAnnouncementModalDismissed = 'New Feature Announcement Modal Dismissed',

	// ITDR
	ITDRSideBarMenuClicked = 'ITDR Side Bar Menu Clicked',
	ITDRNodeClicked = 'ITDR Node Clicked',
	ITDRCsvDataExported = 'ITDR CSV Data Exported',
	ITDRTotalThreatsClicked = 'ITDR Total Threats Clicked',
}
