import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import {
	ColDef,
	FilterChangedEvent,
	GridReadyEvent,
	IRowNode,
	RowClickedEvent,
	SideBarDef,
	StatusPanelDef,
} from 'ag-grid-enterprise'
import { Tag, Tooltip } from 'antd'
import {
	IssuesTabName,
	IssueStatus,
	IssueStatusMap,
	PriorityType,
	PriorityTypeMap,
	ServerIssue,
} from '../../schemas/issue'
import { IssueNameTag } from '../../components/common/IssueNameTag'
import { formatDate, formatRelativeDateText } from '../../utils'
import { IdentityTypeTag } from '../../components/common/IdentityTypeTag'
import { PriorityNameTag } from '../../components/common/PriorityNameTag'
import { IssueStatusTag } from '../../components/common/IssueStatusTag'
import { IssueSourceFilterIcon } from '../../components/common/IssueSourceIconFilter'
import { IssueNameTagFilter } from '../../components/common/IssueNameTagFilter'
import { IdentityTypeWithIcon } from '../../components/common/IdentityTypeWithIcon.tsx'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket'
import { IdentitySourceMap } from '../../schemas/identity.ts'
import { FronteggUserAvatar } from '../../components/common/FronteggUserAvatar.tsx'
import { FronteggUser } from '../../api/fronteggApi.ts'
import { useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../routes/RoutePaths.tsx'
import { FindingsGroupCellRender } from '../../tables/findings/FindingsGroupCellRenderer.tsx'
import { getIssuesTableRowStyle } from '../../utils/styleUtils.ts'
import { IdentitySourceWithEnvIcon } from '../../routes/Identities/IdentitiesTable/IdentitySourceWithEnvIcon.tsx'

export const DemoItderFindingsTable: React.FC<{
	setGridReady: (ready: boolean) => void
	gridReady: boolean
	setFilterChanged: (ready: { value: boolean }) => void
	issuesTableData: ServerIssue[] | undefined
	onIssueClicked: (row: RowClickedEvent<ServerIssue>) => void
	searchText: string
	gridRef: React.RefObject<AgGridReact | null>
	onIssueTableFilterChanged: (event: FilterChangedEvent<ServerIssue>) => void
	searchMostWanted?: boolean
	fronteggUsers: Record<string, FronteggUser>
}> = ({
	setGridReady,
	gridReady,
	setFilterChanged,
	issuesTableData,
	onIssueClicked,
	searchText,
	gridRef,
	onIssueTableFilterChanged,
	searchMostWanted,
	fronteggUsers,
}) => {
	const {
		issueName: searchIssueName,
		issueId: searchIssueId,
		tabName: searchTabName,
		environment: searchEnvironment,
		issueTag: searchIssueTag,
		cloudProvider: searchCloudProvider,
		identityProvider: searchIdentityProvider,
		issueFilter: searchIssueFilter,
		myIssues: searchMyIssues,
	} = useSearch({ from: RoutePaths.Itdr })

	const columnDefs: ColDef<ServerIssue>[] = useMemo(() => {
		const hideStatusColumn = searchTabName === IssuesTabName.RESOLVED
		const groupByMostWanted = searchMostWanted ?? false
		return [
			{
				headerName: 'Issue Name',
				enableRowGroup: true,
				valueGetter: (params: { data?: ServerIssue }) => params.data?.issue_name || '',
				field: 'issue_name',
				hide: false,
				filter: 'agSetColumnFilter',
				minWidth: 265,
				filterParams: {
					cellRenderer: (params: { value: string }) => {
						return <IssueNameTagFilter name={params.value} />
					},
				},
				flex: 1.9,
				cellRenderer: (params: { data?: ServerIssue; node: IRowNode }) => {
					if (params.node.group) return
					return <IssueNameTag name={params.data?.issue_name} />
				},
			},
			{
				headerName: 'Identity Name',
				enableRowGroup: true,
				field: 'identity.literal', // groupDefaultExpanded: groupByMostWanted ? 1 : 0,
				rowGroup: groupByMostWanted,
				hide: false,
				filter: 'agTextColumnFilter',
				colId: 'identity.literal',
				filterValueGetter: (params: { data?: ServerIssue }) =>
					params.data?.identity?.literal_friendly_name ?? params.data?.identity?.literal ?? '',
				valueGetter: (params: { data?: ServerIssue }) =>
					params.data?.identity?.literal_friendly_name ?? params.data?.identity?.literal ?? '',
				filterParams: {},
				minWidth: 260,
				flex: 2,
				cellRenderer: (params: { data?: ServerIssue; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={params.data?.identity?.literal}>
							{params.data?.identity?.literal_friendly_name ?? params.data?.identity?.literal}
						</Tooltip>
					)
				},

				comparator: (
					_valueA: string,
					_valueB: string,
					nodeA: IRowNode<ServerIssue>,
					nodeB: IRowNode<ServerIssue>,
				) => {
					const valA = nodeA?.data?.identity?.literal_friendly_name ?? nodeA?.data?.identity?.literal ?? ''
					const valB = nodeB?.data?.identity?.literal_friendly_name ?? nodeB?.data?.identity?.literal ?? ''
					if (typeof valA === 'string' && typeof valB === 'string') {
						return valA.localeCompare(valB)
					}
					return 0
				},
			},
			{
				headerName: 'Status',
				enableRowGroup: true,
				field: 'status',
				hide: hideStatusColumn,
				flex: 1,
				minWidth: 110,
				filter: 'agSetColumnFilter',
				valueGetter: (params: { data?: ServerIssue }) => params.data?.status,
				cellRenderer: (params: { data?: ServerIssue; node: IRowNode }) => {
					if (params.node.group) return
					return <IssueStatusTag issue={params.data} />
				},
				filterParams: {
					cellRenderer: (params: { value: string }) => {
						return IssueStatusMap[params.value as IssueStatus] ?? params.value
					},
				},
			},
			{
				headerName: 'Account',
				enableRowGroup: true,
				field: 'identity.account_literal',
				hide: false,
				filter: 'agSetColumnFilter',
				flex: 1.2,
				minWidth: 200,
				filterParams: {
					treeList: true,
					treeListPathGetter: (params: string) => {
						const [key, value] = params.split(' - ')
						return [key, value]
					},
					cellRenderer: (params: { value: string }) => {
						return <IssueSourceFilterIcon source={params.value} />
					},
				},
				valueGetter: (params: { data?: ServerIssue }) => {
					if (params.data?.identity?.account_literal_friendly_name) {
						return `${params.data?.identity?.env_type} - ${params.data?.identity?.account_literal_friendly_name} (${params.data?.identity?.account_literal})`
					} else {
						// Concatenate the issueSource and accountLiteral to get the full value
						return `${params.data?.identity?.env_type} - ${params.data?.identity?.account_literal}`
					}
				},

				cellRenderer: (params: { data?: ServerIssue; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<>
							<IdentitySourceWithEnvIcon
								envType={params.data?.identity?.env_type}
								origin={params.data?.identity?.source}
								issueName={params.data?.issue_name}
							/>
							<Tooltip
								placement="bottomLeft"
								title={
									params.data?.identity?.account_literal_friendly_name
										? `${params.data?.identity?.account_literal_friendly_name} - (${params.data?.identity?.account_literal})`
										: params.data?.identity?.account_literal
								}
							>
								<div className="ml-2 truncate">
									{params.data?.identity?.account_literal_friendly_name ??
										params.data?.identity?.account_literal}
								</div>
							</Tooltip>
						</>
					)
				},
			},
			{
				headerName: 'Identity Type',
				enableRowGroup: true,
				field: 'identity.type',
				hide: false,
				minWidth: 150,
				flex: 1,
				valueGetter: (params: { data?: ServerIssue }) => params.data?.identity?.type,
				cellRenderer: (params: { data?: ServerIssue; node: IRowNode }) => {
					if (params.node.group) return
					return <IdentityTypeTag type={params.data?.identity?.type} />
				},
				filterParams: {
					cellRenderer: (params: { value: string }) => {
						return <IdentityTypeWithIcon type={params.value} />
					},
				},
			},
			{
				headerName: 'Priority',
				enableRowGroup: true,
				field: 'priority',
				hide: false,
				filter: 'agSetColumnFilter',
				flex: 0.9,
				minWidth: 150,
				sort: 'asc',
				sortIndex: 0,
				filterParams: {
					comparator: (a: string, b: string) => {
						const numA = Object.entries(PriorityTypeMap).find((p) => p[1] === a)![0]
						const numB = Object.entries(PriorityTypeMap)?.find((p) => p[1] === b)![0]
						const valA = parseInt(numA)
						const valB = parseInt(numB)
						if (valA === valB) return 0
						return valA < valB ? 1 : -1
					},
				},
				cellRenderer: (params: { data?: ServerIssue; node: IRowNode }) => {
					if (params.node.group) return
					return <PriorityNameTag priority={params.data?.priority} />
				},
				valueGetter: (params: { data?: ServerIssue }) => {
					return PriorityTypeMap[params.data?.priority ?? PriorityType.NOT_CALCULATED]
				},
				comparator: (valueA: string, valueB: string) => {
					if (typeof valueA === 'string' && typeof valueB === 'string') {
						const priorityA = PriorityType[valueA.toUpperCase() as keyof typeof PriorityType]
						const priorityB = PriorityType[valueB.toUpperCase() as keyof typeof PriorityType]
						if (priorityA !== undefined && priorityB !== undefined) {
							return priorityB - priorityA
						}
					}
					return 0
				},
			},
			{
				headerName: 'Assignee',
				minWidth: 160,
				field: 'assignee',
				valueGetter: (params: { data?: ServerIssue }) => params.data?.assignee,
				enableRowGroup: true,
				cellRenderer: (params: { value: string; node: IRowNode }) => {
					if (params.node.group || !params.value) return
					return <FronteggUserAvatar user={fronteggUsers[params.value]} />
				},
				filterParams: {
					suppressMiniFilter: true,
					cellRenderer: (params: { value: string }) => {
						if (!params.value) {
							return 'Unassigned'
						}

						if (params.value === '(Select All)') {
							return params.value
						}
						return <FronteggUserAvatar user={fronteggUsers[params.value]} compact />
					},
				},
			},
			{
				headerName: 'Issue Source',
				field: 'identity.source',
				hide: true,
				flex: 1,
				filter: 'agSetColumnFilter',
				minWidth: 180,
				valueGetter: (params: { data?: ServerIssue }) => {
					return params.data?.identity?.source ? IdentitySourceMap[params.data?.identity?.source] : 'N/A'
				},
				cellRenderer: (params: { value: string; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tag>
							<div className="flex items-center">
								<div className="pl-1 text text-sm">{params.value}</div>
							</div>
						</Tag>
					)
				},
				filterParams: {
					cellRenderer: (params: { value: string }) => {
						return params.value
					},
				},
			},
			{
				headerName: 'Identity Last Activity',
				field: 'identity.last_activity',
				hide: false,
				filter: 'agDateColumnFilter',
				flex: 1,
				minWidth: 180,
				sort: 'desc',
				sortIndex: 1,
				cellRenderer: (params: { value?: Date | null; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
							<div className="text-textIcon-secondary">{formatRelativeDateText(params.value, true)}</div>
						</Tooltip>
					)
				},
			},
			{
				headerName: 'Identity Created At',
				field: 'identity.created_at',
				hide: false,
				filter: 'agDateColumnFilter',
				flex: 1,
				minWidth: 180,
				cellRenderer: (params: { value?: Date | null; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-textIcon-secondary">{formatDate(params.value)}</div>
				},
			},
			{
				headerName: 'Issue Last Seen',
				field: 'last_seen',
				hide: false,
				minWidth: 150,
				filter: 'agDateColumnFilter',
				flex: 1,
				cellRenderer: (params: { value?: ServerIssue['last_seen']; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
							<div className="text-textIcon-secondary">{formatRelativeDateText(params.value, true)}</div>
						</Tooltip>
					)
				},
			},
			{
				headerName: 'Issue Created At',
				field: 'created_at',
				hide: false,
				minWidth: 150,
				filter: 'agDateColumnFilter',
				flex: 1,
				cellRenderer: (params: { value?: ServerIssue['created_at']; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-textIcon-secondary">{formatDate(params.value)}</div>
				},
			},
			{
				headerName: 'Link',
				suppressColumnsToolPanel: true,
				suppressNavigable: true,
				suppressMovable: true,
				sortable: false,
				resizable: false,
				filter: false,
				width: 1,
				valueGetter: (params: { data?: ServerIssue }) =>
					`${window.location.toString()}&issueId=${params.data?.id}`,
				cellRenderer: () => null,
			},
		]
	}, [searchTabName, searchMostWanted])
	const defaultColDef = useMemo<ColDef<ServerIssue>>(
		() => ({
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}),
		[],
	)

	const onRowClicked = (row: RowClickedEvent<ServerIssue>) => {
		const selection = window.getSelection()?.toString()
		if (selection?.length && selection.length > 0) {
			return
		}
		const issueName: string | undefined = row.data?.issue_name ?? undefined
		trackEvent(LogRocketTrackEvent.IssueDrawerOpenedFromFindingsTable, { issueName })
		onIssueClicked(row)
	}

	const onGridReady = useCallback(
		(_params: GridReadyEvent<ServerIssue>) => {
			if (searchIssueId) {
				gridRef?.current?.api?.forEachNode((node: IRowNode<ServerIssue>) => {
					if (node.data?.id === searchIssueId) {
						node.setSelected(true)
						gridRef?.current?.api?.ensureNodeVisible(node)
					}
				})
			}
			gridRef?.current?.api?.sizeColumnsToFit()
			setGridReady(true)
			if (searchIssueFilter) {
				gridRef?.current?.api?.setFilterModel(searchIssueFilter)
			}
		},
		[searchIssueId],
	)

	const statusBar = useMemo<{
		statusPanels: StatusPanelDef[]
	}>(() => {
		return {
			statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }],
		}
	}, [])

	const autoGroupColumnDef: ColDef = {
		cellRenderer: FindingsGroupCellRender,
		width: 200,
		minWidth: 150,
	}

	const sideBar = useMemo<SideBarDef>(
		() => ({
			position: 'left',
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					minWidth: 225,
					maxWidth: 225,
					width: 225,
					toolPanelParams: {
						suppressPivotMode: true,
					},
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					minWidth: 300,
					maxWidth: 400,
					width: 300,
				},
			],
		}),
		[],
	)

	useEffect(() => {
		// this useEffect is to notify all the components that they need to update their data
		if (!gridReady) return // Early return if grid is not ready
		setFilterChanged({ value: true })
	}, [
		searchTabName,
		searchIssueName,
		searchEnvironment,
		searchIssueTag,
		searchCloudProvider,
		searchIdentityProvider,
		searchMyIssues,
		gridReady,
	])

	return (
		<AgGridReact
			onFilterChanged={onIssueTableFilterChanged}
			autoGroupColumnDef={autoGroupColumnDef}
			getRowStyle={getIssuesTableRowStyle}
			ref={gridRef as MutableRefObject<AgGridReact | null>}
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			onGridReady={onGridReady}
			rowData={issuesTableData}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			groupDefaultExpanded={1}
			onRowClicked={onRowClicked}
			overlayLoadingTemplate={'Loading...'}
			suppressDragLeaveHidesColumns={true}
			overlayNoRowsTemplate={'No data'}
			rowSelection={{
				mode: 'singleRow',
				checkboxes: false,
			}}
			quickFilterText={searchText}
			statusBar={statusBar}
			suppressHorizontalScroll={false}
			sideBar={sideBar}
			rowGroupPanelShow="always"
			enableCellTextSelection
			debounceVerticalScrollbar
			rowBuffer={50}
		/>
	)
}
