import { Node, NodeProps } from '@xyflow/react'
import { CombinedAwsIamRolePolicy } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { ServerTag } from '../../../../schemas/tags.ts'
import { getPolicyUniqueKey } from '../../../../utils/awsIdentityUtils.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type AwsPoliciesNodeType = Node<{ policies: CombinedAwsIamRolePolicy[] }, 'awsPolicies'>
type PoliciesNodeProps = NodeProps<AwsPoliciesNodeType>

export const AwsPoliciesNode = ({ data: { policies } }: PoliciesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'awsPolicy')

	const badgeTooltipContent = policies.map((policy) => (
		<span key={getPolicyUniqueKey(policy)}>{policy.PolicyName || 'Unknown policy'}</span>
	))

	const uniqueRiskTags = policies.reduce((tags, policy) => {
		policy.RiskTags?.forEach((riskTag) => {
			if (tags.find((tag) => tag.name === riskTag.name)) {
				return
			}

			tags.push(riskTag)
		})

		return tags
	}, [] as ServerTag[])

	return (
		<BasePermissionNode
			label="AWS Policies"
			risks={uniqueRiskTags}
			isHighlighted={isHighlighted}
			groupProps={{ count: policies.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
