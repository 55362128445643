import { Node, NodeProps } from '@xyflow/react'
import { ServerGcpPrincipalInfoXc } from '../../../../schemas/identities/gcp/gcpProjectXcSchema.ts'
import GcpIcon from '../../../../assets/gcp_project_icon.svg?react'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode.tsx'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type ConnectedGcpProjectsNodeType = Node<
	{ principalInfoXcs: ServerGcpPrincipalInfoXc[] },
	'connectedGcpProjects'
>
type ConnectedGcpProjectsNodeProps = NodeProps<ConnectedGcpProjectsNodeType>

export const ConnectedGcpProjectsNode = ({ data: { principalInfoXcs } }: ConnectedGcpProjectsNodeProps) => {
	const badgeTooltipContent = principalInfoXcs.map((principalInfoXc) => (
		<span key={principalInfoXc.project.projectId}>{principalInfoXc.project.displayName || 'Unknown project'}</span>
	))

	return (
		<BaseEnvironmentNode label="GCP Projects" icon={<GcpIcon />}>
			<NodeGroupBadge
				count={principalInfoXcs.length}
				nodeColorType="environment"
				tooltipContent={badgeTooltipContent}
			/>
		</BaseEnvironmentNode>
	)
}
