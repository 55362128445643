type QueryPagination = {
	offset: number
	limit: number
}

type QuerySortCondition = {
	field: string
	direction: 'asc' | 'desc'
}

export enum QueryFilterOperator {
	EQ = 'eq',
	NEQ = 'neq',
	GT = 'gt',
	GTE = 'gte',
	LT = 'lt',
	LTE = 'lte',
	IN = 'in',
	NIN = 'nin',
	ICONTAINS = 'icontains',
	CONTAINS = 'contains',
	NOTCONTAINS = 'notcontains',
	INOTCONTAINS = 'inotcontains',
	STARTSWITH = 'startswith',
	ENDSWITH = 'endswith',
	ANY = 'any',
	NANY = 'nany',
	EMPTY = 'empty',
	OVERLAP = 'overlap',
}

enum QueryFilterLogicalOperator {
	AND = 'AND',
	OR = 'OR',
	NOT = 'NOT',
}

export type QueryFilterCondition = {
	field: string
	op: QueryFilterOperator
	value: unknown
}

export type QueryFilterExpressionGroup = {
	[key in QueryFilterLogicalOperator]?: Array<QueryFilterExpression>
}

export type QueryFilterExpression = QueryFilterCondition | QueryFilterExpressionGroup

export type QueryParameters = {
	pagination?: QueryPagination
	sort?: Array<QuerySortCondition>
	filter?: QueryFilterExpression
}
