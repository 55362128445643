import AzureDevopsIcon from '../../../../assets/azure_devops_logo_16.svg?react'
import AzureGuide from '../../../../assets/integrationGuides/azure-devops-integration-guide.pdf'
import {
	EnvironmentCreateRequest,
	EnvironmentTestConnectionRequest,
	ServerEnvironments,
} from '../../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationCategory,
	EnvironmentIntegrationFormData,
} from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const azureDevopsConfig: ConnectableEnvironmentConfig = {
	key: EnvironmentType.AZURE_DEVOPS,
	name: 'Azure DevOps',
	description: 'Link Token Security to your Azure DevOps account to map cloud identities within the Azure framework.',
	logo: <IntegrationIcon leftIcon={AzureDevopsIcon} />,
	category: EnvironmentIntegrationCategory.DEVELOPER_TOOLS,
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.AZURE_DEVOPS].filter(
			(env) =>
				env.name.toLowerCase().includes(searchText.toLowerCase()) ||
				env.organization_name.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.AZURE_DEVOPS].map((env) => env.name),
	guideFileName: AzureGuide,
	formFields: [
		{
			fieldType: 'simple',
			name: 'name',
			label: 'Environment Name',
			type: 'text',
			required: true,
			placeholder: 'Ex: my-azure-devops-environment',
		},
		{
			fieldType: 'simple',
			name: 'organizationName',
			label: 'Organization Name',
			type: 'text',
			required: true,
			placeholder: 'Ex: myorganization',
		},
		{
			fieldType: 'simple',
			name: 'personalAccessToken',
			label: 'Personal Access Token',
			type: 'password',
			required: true,
			placeholder: 'Enter your PAT token',
		},
		{
			fieldType: 'simple',
			name: 'logAnalyticsWorkspaceId',
			label: 'Log Analytics Workspace ID',
			type: 'text',
			required: true,
			placeholder: 'Ex: abcdef12-3456-7890-abcd-ef1234567890',
		},
	],
	createTestConnectionPayload: (formData: EnvironmentIntegrationFormData): EnvironmentTestConnectionRequest => ({
		environment_type: EnvironmentType.AZURE_DEVOPS,
		config: {
			type: EnvironmentType.AZURE_DEVOPS,
			organization_name: formData.organizationName as string,
			log_analytics_workspace_id: formData.logAnalyticsWorkspaceId as string,
			personal_access_token: formData.personalAccessToken as string,
		},
	}),
	createEnvironmentPayload: (formData: EnvironmentIntegrationFormData): EnvironmentCreateRequest => ({
		name: formData.name as string,
		environment_type: EnvironmentType.AZURE_DEVOPS,
		config: {
			type: EnvironmentType.AZURE_DEVOPS,
			organization_name: formData.organizationName as string,
			log_analytics_workspace_id: formData.logAnalyticsWorkspaceId as string,
			personal_access_token: formData.personalAccessToken as string,
		},
	}),
}
