import { Tag, Tooltip } from 'antd'
import React from 'react'
import { formatDate } from '../../../../../utils.ts'
import { EntraIdCommonAuthenticationCredential } from '../../../../../schemas/identities/entraId/entraIdApplicationXc.ts'

export const EntraIdSpAuthenticationCredential: React.FunctionComponent<{
	credential?: EntraIdCommonAuthenticationCredential
	onOpenChange?: (visible: boolean) => void
}> = ({ credential, onOpenChange }) => {
	const now = new Date()
	const isActive = credential?.endDateTime && new Date(credential.endDateTime) > now

	return (
		<div className="flex items-end truncate">
			<Tooltip
				onOpenChange={onOpenChange}
				classNames={{ body: 'w-fit' }}
				title={
					<div className="flex flex-col w-full">
						<span className="flex text-sm">Display Name: {credential?.displayName}</span>
						<span className="flex text-sm">Key ID: {credential?.keyId}</span>
						<span className="flex text-sm">Start Date: {formatDate(credential?.startDateTime)}</span>
						<span className="flex text-sm">End Date: {formatDate(credential?.endDateTime)}</span>
						<span className="flex text-sm">Last Used: {formatDate(credential?.lastSignIn)}</span>
						<span className="flex text-sm">Type: {credential?.type}</span>
					</div>
				}
			>
				<div className="flex items-center">
					<Tag className={`pl-1 text text-sm ${isActive ? 'text-status-active' : 'text-status-critical'}`}>
						{credential?.keyId}
					</Tag>
				</div>
			</Tooltip>
		</div>
	)
}
