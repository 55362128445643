import { Modal } from 'antd'
import { ResourceTypeMap } from '../../../schemas/issue.ts'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { keyBy } from 'lodash'
import { Account } from '../../../schemas/environments/accounts.ts'
import { AccountPrioritySelectOption } from './AccountPrioritySelectOption.tsx'

const AccountConfigChangeRow = ({ account, serverAccount }: { account: Account; serverAccount: Account }) => {
	const priorityChanged = account.priority !== serverAccount.priority
	return (
		<div className="flex flex-col gap-2 py-3">
			<div className="flex gap-3 items-center">
				<div className="flex gap-1 items-center">
					<IssueSourceIcon source={account.environment_type} size={20} />
					<span>{ResourceTypeMap[account.environment_type]}</span>
				</div>
				<span>{account.name}</span>
			</div>
			{priorityChanged && (
				<div className={'flex items-center gap-1'}>
					<span className="semibold">Priority:</span>
					<AccountPrioritySelectOption priority={serverAccount.priority} />
					<ArrowRightOutlined />
					<AccountPrioritySelectOption priority={account.priority} />
				</div>
			)}
		</div>
	)
}

type AccountConfigConfirmationModalProps = {
	isOpen: boolean
	accounts: Account[]
	changedAccountIds: Set<string>
	onCancel: () => void
	onConfirm: () => void
	isUpdating?: boolean
	serverAccounts?: Account[]
}

export const AccountConfigConfirmationModal = ({
	isOpen,
	accounts,
	changedAccountIds,
	onConfirm,
	onCancel,
	isUpdating,
	serverAccounts,
}: AccountConfigConfirmationModalProps) => {
	const [serverAccountsById, setServerAccountsById] = useState<Record<string, Account>>(
		serverAccounts ? keyBy(serverAccounts, 'id') : {},
	)
	useEffect(() => {
		if (serverAccounts) {
			setServerAccountsById(keyBy(serverAccounts, 'id'))
		}
	}, [serverAccounts])
	return (
		<Modal
			width="42%"
			okButtonProps={{ loading: isUpdating }}
			open={isOpen}
			title="Save Changes"
			okType="primary"
			okText="Save"
			onCancel={onCancel}
			onOk={onConfirm}
		>
			<div>Are you sure you want to save the following changes?</div>
			<div className="overflow-y-auto max-h-[50vh] divide-y pr-2">
				{accounts
					.filter((account) => changedAccountIds.has(account.id))
					.map((account) => (
						<AccountConfigChangeRow
							account={account}
							key={account.id}
							serverAccount={serverAccountsById[account.id]}
						/>
					))}
			</div>
		</Modal>
	)
}
