import GcpIcon from '../../../../assets/gcp_icon_20.svg?react'
import RefreshIcon from '../../../../assets/refresh_icon.svg?react'
import GcpGuide from '../../../../assets/integrationGuides/gcp-integration-guide.pdf'
import GcpLogsGuide from '../../../../assets/integrationGuides/gcp-logs-integration-guide.pdf'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import {
	EnvironmentCreateRequest,
	EnvironmentTestConnectionRequest,
	ServerEnvironments,
} from '../../../../schemas/environments/environments.ts'
import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationCategory,
	EnvironmentIntegrationFormData,
} from '../environmentIntegrationsTypes.ts'
import { message, FormInstance } from 'antd'
import { environmentsApi } from '../../../../api/environments.ts'

export const gcpConfig: ConnectableEnvironmentConfig = {
	key: EnvironmentType.GCP,
	name: 'Google Cloud Platform',
	description: 'Link Token Security to your GCP projects to map cloud identities across the Google Cloud platform.',
	logo: <IntegrationIcon leftIcon={GcpIcon} />,
	category: EnvironmentIntegrationCategory.CLOUD_PROVIDERS,
	formFields: [
		{
			fieldType: 'simple',
			name: 'name',
			label: 'Environment Name',
			type: 'text',
			required: true,
			placeholder: 'Enter environment name',
		},
		{
			fieldType: 'simple',
			name: 'org_id',
			label: 'Organization ID',
			type: 'text',
			required: true,
			placeholder: 'Enter your GCP organization ID',
		},
		{
			fieldType: 'groupedTabs',
			name: 'service_account_key_tabs',
			label: 'Service Account Key',
			required: true,
			defaultActiveKey: 'generate',
			rules: [
				{
					validator: (_: string, form: FormInstance) => {
						const generatedKey = form.getFieldValue('generated_key') as string | undefined
						const apiToken = form.getFieldValue('api_token') as Record<string, unknown> | undefined

						if (!generatedKey && !apiToken) {
							throw new Error('Please generate a service account key or upload a JSON file')
						}
					},
					message: 'Please generate a service account key or upload a JSON file',
				},
			],
			tabs: [
				{
					key: 'generate',
					title: 'Create Service Account Key',
					fields: [
						{
							fieldType: 'generated',
							name: 'generated_key',
							icon: <RefreshIcon />,
							tooltip:
								'Click to generate a Service Account for the Token Security integration with your environment',
							label: '',
							required: false,
							inputClassName: 'mb-2',
							generateAction: async (form?: FormInstance) => {
								if (!form) {
									message.error('Form instance is required')
									return ''
								}

								// Get the organization ID from the form
								const orgId = form.getFieldValue('org_id') as string

								if (!orgId) {
									message.error('Please enter an Organization ID first')
									return ''
								}

								// Create the service account and return the key
								try {
									return await environmentsApi.generateGcpServiceAccount(orgId)
								} catch (error) {
									message.error('Error generating GCP service account:')
									return ''
								}
							},
							copyable: true,
						},
					],
				},
				{
					key: 'upload',
					title: 'Upload Json File',
					fields: [
						{
							fieldType: 'fileUpload',
							name: 'api_token',
							label: '',
							required: false,
							accept: '.json',
							buttonText: 'Upload Json File',
							buttonClassName: 'w-[29rem]',
							className: '!mb-2 mt-1',
						},
					],
				},
			],
		},
		{
			fieldType: 'group',
			name: 'logs',
			label: 'Logs',
			required: true,
			fields: [
				{
					fieldType: 'simple',
					name: 'bucketName',
					label: 'Bucket name',
					type: 'text',
					required: false,
					placeholder: 'token-example-bucket',
				},
				{
					fieldType: 'simple',
					name: 'pubSubSubscriptionName',
					label: "PubSub topic's subscription name",
					type: 'text',
					required: false,
					placeholder: 'projects/example-project/topics/example-token-topic',
				},
			],
			guideFile: GcpLogsGuide,
			guideFileName: 'Logs Integration Guide',
		},
	],
	guideFileName: GcpGuide,
	createEnvironmentPayload: (formData: EnvironmentIntegrationFormData): EnvironmentCreateRequest => {
		// Use either the uploaded api_token or the generated key
		const apiToken = formData.api_token || { sa_email: formData.generated_key }

		return {
			name: formData.name as string,
			environment_type: EnvironmentType.GCP,
			config: {
				type: EnvironmentType.GCP,
				org_id: formData.org_id as string,
				api_token: apiToken as Record<string, string>,
				bucket_name: formData.bucketName as string | null,
				pub_sub_subscription_name: formData.pubSubSubscriptionName as string | null,
			},
		}
	},
	createTestConnectionPayload: (formData: EnvironmentIntegrationFormData): EnvironmentTestConnectionRequest => {
		// Use either the uploaded api_token or the generated key
		const apiToken = formData.api_token || { sa_email: formData.generated_key }

		return {
			environment_type: EnvironmentType.GCP,
			config: {
				type: EnvironmentType.GCP,
				org_id: formData.org_id as string,
				api_token: apiToken as Record<string, string>,
			},
		}
	},
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.GCP]
			.map((env) => {
				const searchTextLower = searchText.toLowerCase()
				const envNameMatch = env.name.toLowerCase().includes(searchTextLower)

				if (envNameMatch) {
					return env
				}

				const matchingProjects = env.gcp_projects.filter(
					(gcpProject) =>
						gcpProject.name.toLowerCase().includes(searchTextLower) ||
						gcpProject.project_id.toLowerCase().includes(searchTextLower),
				)

				if (matchingProjects.length > 0) {
					return {
						...env,
						gcp_projects: matchingProjects,
					}
				}

				return null
			})
			.filter((env): env is NonNullable<typeof env> => env !== null)
		return filtered
	},
	environmentNamesResolver: (environments) => {
		const projectsNames = environments[EnvironmentType.GCP].flatMap((gcpEnv) =>
			gcpEnv.gcp_projects.map((gcpProject) => gcpProject.project_id),
		)
		const envNames = environments[EnvironmentType.GCP].map((gcpEnv) => gcpEnv.name)

		return projectsNames.length > 0 ? projectsNames : envNames
	},
	onFormClose: (formData: EnvironmentIntegrationFormData | undefined) => {
		const saEmail = formData?.generated_key

		if (saEmail) {
			void environmentsApi.deleteGcpServiceAccount(saEmail as string)
		}
	},
}
