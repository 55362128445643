import { ServerIdentity } from '../../../schemas/identity.ts'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { Collapse, CollapseProps } from 'antd'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import OktaIcon from '../../../assets/okta_border_icon.svg?react'
import EntraIdIcon from '../../../assets/entra_id_icon_16.svg?react'
import AwsIcon from '../../../assets/aws_icon_16.svg?react'
import GcpIcon from '../../../assets/gcp_icon_20.svg?react'
import GithubIcon from '../../../assets/github_icon_20.svg?react'
import AzureIcon from '../../../assets/azure_icon.svg?react'
import AzureDevopsIcon from '../../../assets/azure_devops_logo_16.svg?react'
import ActiveDirectoryIcon from '../../../assets/active_directory_icon_16.svg?react'
import JumpcloudIcon from '../../../assets/jumpcloud_icon_16.svg?react'
import GoogleWorkspaceIcon from '../../../assets/google_workspace_logo_16.svg?react'
import { OktaUserXcTable } from './tables/okta/OktaUserXcTable.tsx'
import { IamRolesTable } from './tables/aws/IamRolesTable.tsx'
import { IamTrustRelationshipTable } from './tables/aws/iamTrustRelationshipTable.tsx'
import { GithubTeamsTable } from './tables/github/GithubTeamsTable.tsx'
import { GithubReposTable } from './tables/github/GithubReposTable.tsx'
import { EntraIdUserXcTable } from './tables/entraId/EntraIdUserXcTable.tsx'
import { AzureRoleAssignmentsTable } from './tables/azure/AzureRoleAssignmentsTable.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel.tsx'
import { ConnectedGcpProjectsTable } from './tables/gcp/ConnectedGcpProjectsTable.tsx'
import { ServerDetailedAzureRoleAssignment } from '../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { CellMouseOverEvent } from 'ag-grid-enterprise'
import { JumpcloudUserXcTable } from './tables/jumpcloud/JumpcloudUserXcTable.tsx'
import { GithubTokensTable } from './tables/github/GithubTokensTable.tsx'
import { AzureDevopsPatsTable } from './tables/azureDevops/AzureDevopsPatsTable.tsx'
import { AzureDevopsUsersTable } from './tables/azureDevops/AzureDevopsUsersTable.tsx'
import { EntraIdServicePrincipalTable } from './tables/entraId/EntraIdServicePrincipalTable.tsx'
import { AzureDevopsServicePrincipalsTable } from './tables/azureDevops/AzureDevopsServicePrincipalsTable.tsx'
import { ADUsersTable } from './tables/activeDirectory/AdUsersTable.tsx'
import { GoogleWorkspaceDelegationTable } from './tables/gcp/GoogleWorkspaceDelegationTable.tsx'
import { ServerOktaUserXc } from '../../../schemas/identities/oktaUserXcSchema.ts'
import { IamUsersTable } from './tables/aws/IamUsersTable.tsx'
import { CombinedAwsIamRolePolicy } from '../../../schemas/identities/awsIamRoleXcSchema.ts'
import { ServerAwsIamUserPermission } from '../../../schemas/identities/awsIamUserXcSchema.ts'

export type CrossContextCollapseItemKeys =
	| 'okta'
	| 'trust-relationships'
	| 'trusted-principals'
	| 'github-teams'
	| 'github-repos'
	| 'github-tokens'
	| 'azure-devops-pats'
	| 'azure-devops-user'
	| 'azure-devops-service-principal'
	| 'entraIDUser'
	| 'entraIDServicePrincipal'
	| 'jumpcloudUser'
	| 'aws-roles'
	| 'aws-users'
	| 'gcp-projects'
	| 'azure-roles'
	| 'active-directory-user'
	| 'domain-wide-delegation'

type CrossContextCollapseItem = Required<CollapseProps>['items'][number] & {
	key: CrossContextCollapseItemKeys
}

type SidePanelCrossContextProps = {
	identity: ServerIdentity
	activeCrossContextKeys: CrossContextCollapseItemKeys[]
	setActiveCrossContextKeys: Dispatch<SetStateAction<CrossContextCollapseItemKeys[]>>
	lastSelectedNode?: LastSelectedNode | null
}

export const SidePanelCrossContext = ({
	identity,
	activeCrossContextKeys,
	setActiveCrossContextKeys,
	lastSelectedNode,
}: SidePanelCrossContextProps) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()

	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList =
				typeof key === 'string'
					? [key as CrossContextCollapseItemKeys]
					: (key as CrossContextCollapseItemKeys[])
			setActiveCrossContextKeys(keyList)
		},
		[setActiveCrossContextKeys],
	)

	const oktaXc: Array<ServerOktaUserXc> = []
	if (identity.aws_iam_user?.okta_user_xc?.length) {
		oktaXc.push(...identity.aws_iam_user.okta_user_xc)
	} else if (identity.snowflake_user?.okta_user_xc) {
		oktaXc.push(identity.snowflake_user.okta_user_xc)
	} else if (identity.salesforce_user?.okta_user_xc) {
		oktaXc.push(identity.salesforce_user.okta_user_xc)
	} else if (identity.demo_atlassian_user?.okta_user_xc) {
		oktaXc.push(identity.demo_atlassian_user.okta_user_xc)
	}
	const entraIdUserXc =
		identity.snowflake_user?.entra_id_user_xc ||
		identity.github_user?.entra_id_user_xc ||
		identity.salesforce_user?.entra_id_user_xc ||
		identity.azure_devops_user?.entra_id_user ||
		identity.active_directory_user?.entra_id_user
	const entraIdServicePrincipalXc = identity.azure_devops_service_principal?.entra_id_service_principal
	const azureDevopsUserXc = identity.entra_id_user?.azure_devops_user
	const azureDevopsServicePrincipalXc = identity.entra_id_service_principal?.azure_devops_service_principal
	const activeDirectoryUserXc = identity.entra_id_user?.active_directory_user

	const trustRelationshipsXc = identity.aws_iam_role?.aws_iam_role_details_xc?.AssumeRolePolicyDocument
	const trustRelationshipsXcStatements = trustRelationshipsXc?.Statement
	const crossAccountRolesXc = identity.aws_iam_role?.aws_iam_cross_account_roles_xc
	const githubReposXc = identity.github_user?.github_repos_xc
	const githubTeamsXc = identity.github_user?.github_teams_xc
	const githubAppRepos = identity.github_app_installation?.repositories
	const githubTokens = identity.github_user?.tokens
	const azureDevopsPats = identity.azure_devops_user?.personal_access_tokens
	const awsIamRolesXc =
		identity.entra_id_user?.aws_iam_roles_xc ||
		identity.jumpcloud_user?.aws_iam_roles_xc ||
		identity.okta_user?.aws_iam_roles_xc ||
		identity.aws_key_pair?.aws_iam_role_details_xc ||
		(identity.aws_ec2_instance?.aws_iam_role_details_xc
			? [identity.aws_ec2_instance?.aws_iam_role_details_xc]
			: undefined) ||
		identity.kubernetes_resource?.aws_iam_role_details_xc
	const gcpProjectsXc = identity.entra_id_user?.gcp_projects_xc || identity.jumpcloud_user?.gcp_projects_xc
	const azureRoleAssignmentsXc =
		identity.entra_id_user?.azure_role_assignments_xc ||
		identity.entra_id_service_principal?.azure_role_assignments_xc
	const jumpcloudUserXc = identity.snowflake_user?.jumpcloud_user_xc
	const googleWorkspaceDelegation = identity.google_workspace_user?.demo_domain_wide_delegation
	const kubernetesAwsUsersXc = identity.kubernetes_resource?.aws_iam_users_xc || []

	const items: CrossContextCollapseItem[] = useMemo(() => {
		const ret: CrossContextCollapseItem[] = []
		if (jumpcloudUserXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Jumpcloud User'} icon={JumpcloudIcon} />,
				key: 'jumpcloudUser',
				children: <JumpcloudUserXcTable data={[jumpcloudUserXc]} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'jumpcloudUser', id: '' }])
				},
			})
		}
		if (oktaXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Okta Users'} icon={OktaIcon} />,
				key: 'okta',
				children: <OktaUserXcTable data={oktaXc} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'oktaUser', id: '' }])
				},
			})
		}

		if (trustRelationshipsXc && trustRelationshipsXcStatements?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Trust Relationships'} icon={AwsIcon} />,
				key: 'trust-relationships',
				children: <IamTrustRelationshipTable data={trustRelationshipsXc} />,
			})
		}

		if (crossAccountRolesXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Trusted Principals'} icon={AwsIcon} />,
				key: 'trusted-principals',
				children: (
					<IamRolesTable
						awsIamRolesXc={crossAccountRolesXc}
						onCellMouseOver={(event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => {
							setHighlightedNodes([{ type: 'awsPolicy', id: event.node.id! }])
						}}
						onCellMouseOut={() => setHighlightedNodes([])}
					/>
				),
			})
		}

		if (githubTeamsXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'GitHub Teams'} icon={GithubIcon} />,
				key: 'github-teams',
				children: <GithubTeamsTable githubTeams={githubTeamsXc} />,
			})
		}

		if (githubReposXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'GitHub Repositories'} icon={GithubIcon} />,
				key: 'github-repos',
				children: <GithubReposTable githubRepos={githubReposXc} />,
			})
		}

		if (githubAppRepos?.length) {
			const githubRepositories = githubAppRepos.map((repoName) => ({
				name: repoName,
			}))

			ret.push({
				label: <CollapsibleItemLabel label={'GitHub Repositories'} icon={GithubIcon} />,
				key: 'github-repos',
				children: <GithubReposTable githubRepos={githubRepositories} />,
			})
		}

		if (githubTokens?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'GitHub Tokens'} icon={GithubIcon} />,
				key: 'github-tokens',
				children: <GithubTokensTable githubTokens={githubTokens} />,
			})
		}

		if (azureDevopsPats?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Azure DevOps PATs'} icon={AzureDevopsIcon} />,
				key: 'azure-devops-pats',
				children: (
					<AzureDevopsPatsTable
						pats={azureDevopsPats}
						lastSelectedPat={lastSelectedNode?.type === 'azureDevopsPat' ? lastSelectedNode.id : undefined}
					/>
				),
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'azureDevopsPat', id: '' }])
				},
			})
		}

		if (azureDevopsUserXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Azure Devops User'} icon={AzureDevopsIcon} />,
				key: 'azure-devops-user',
				children: <AzureDevopsUsersTable data={[azureDevopsUserXc]} />,
			})
		}

		if (azureDevopsServicePrincipalXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Azure Devops Service Principal'} icon={AzureDevopsIcon} />,
				key: 'azure-devops-service-principal',
				children: <AzureDevopsServicePrincipalsTable data={[azureDevopsServicePrincipalXc]} />,
			})
		}

		if (entraIdUserXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Entra ID User'} icon={EntraIdIcon} />,
				key: 'entraIDUser',
				children: <EntraIdUserXcTable data={[entraIdUserXc]} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'entraIDUser', id: '' }])
				},
			})
		}

		if (entraIdServicePrincipalXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Entra ID Service Principal'} icon={EntraIdIcon} />,
				key: 'entraIDServicePrincipal',
				children: <EntraIdServicePrincipalTable data={[entraIdServicePrincipalXc]} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'entraIDServicePrincipal', id: '' }])
				},
			})
		}

		if (awsIamRolesXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'AWS IAM Roles'} icon={AwsIcon} />,
				key: 'aws-roles',
				children: (
					<IamRolesTable
						awsIamRolesXc={awsIamRolesXc}
						onCellMouseOver={(event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => {
							setHighlightedNodes([{ type: 'awsPolicy', id: event.node.id! }])
						}}
						onCellMouseOut={() => setHighlightedNodes([])}
					/>
				),
			})
		}

		if (gcpProjectsXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'GCP Projects'} icon={GcpIcon} />,
				key: 'gcp-projects',
				children: <ConnectedGcpProjectsTable gcpProjectsXc={gcpProjectsXc} />,
			})
		}

		if (azureRoleAssignmentsXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Azure Role Assignments'} icon={AzureIcon} />,
				key: 'azure-roles',
				children: (
					<AzureRoleAssignmentsTable
						data={azureRoleAssignmentsXc}
						lastSelectedRole={
							lastSelectedNode?.type === 'enrichedAzureRole' ? lastSelectedNode.id : undefined
						}
						onCellMouseOver={(event: CellMouseOverEvent<ServerDetailedAzureRoleAssignment>) => {
							setHighlightedNodes([{ type: 'enrichedAzureRole', id: event.node.id! }])
						}}
						onCellMouseOut={() => setHighlightedNodes([])}
					/>
				),
			})
		}

		// Add Active Directory User relationship
		if (activeDirectoryUserXc) {
			ret.push({
				label: <CollapsibleItemLabel label={'Active Directory User'} icon={ActiveDirectoryIcon} />,
				key: 'active-directory-user',
				children: <ADUsersTable data={[activeDirectoryUserXc]} />,
			})
		}

		if (googleWorkspaceDelegation?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Domain Wide Delegation'} icon={GoogleWorkspaceIcon} />,
				key: 'domain-wide-delegation',
				children: <GoogleWorkspaceDelegationTable data={googleWorkspaceDelegation} />,
			})
		}
		if (kubernetesAwsUsersXc?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'AWS IAM Users'} icon={AwsIcon} />,
				key: 'aws-users',
				children: (
					<IamUsersTable
						awsIamUsersXc={kubernetesAwsUsersXc}
						onCellMouseOver={(event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => {
							setHighlightedNodes([{ type: 'awsPolicy', id: event.node.id! }])
						}}
						onCellMouseOut={() => setHighlightedNodes([])}
						onHeaderMouseOver={(event: CellMouseOverEvent<ServerAwsIamUserPermission>) => {
							setHighlightedNodes([{ type: 'awsIamUser', id: event.node.id! }])
						}}
					/>
				),
			})
		}

		return ret
	}, [identity, lastSelectedNode])

	if (
		!oktaXc?.length &&
		!trustRelationshipsXcStatements?.length &&
		!crossAccountRolesXc?.length &&
		!githubTeamsXc?.length &&
		!githubReposXc?.length &&
		!githubAppRepos?.length &&
		!githubTokens?.length &&
		!entraIdUserXc &&
		!entraIdServicePrincipalXc &&
		!awsIamRolesXc?.length &&
		!gcpProjectsXc?.length &&
		!azureRoleAssignmentsXc?.length &&
		!jumpcloudUserXc &&
		!azureDevopsPats?.length &&
		!azureDevopsUserXc &&
		!azureDevopsServicePrincipalXc &&
		!activeDirectoryUserXc &&
		!googleWorkspaceDelegation?.length &&
		!kubernetesAwsUsersXc?.length
	) {
		return 'No cross-context data'
	}

	return (
		<div
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			<Collapse
				onChange={onCollapseChange}
				activeKey={activeCrossContextKeys}
				className="bg-surface-primary"
				expandIconPosition={'end'}
				items={items}
			/>
		</div>
	)
}
