import { useMemo } from 'react'
import { AgChartsReact } from 'ag-charts-react'
import { ServerResolvedVsUnresolved } from '../schemas/metrics/resolvedVsUnresolved.ts'
import { noDataOverlay } from './helpers.tsx'
import { formatDate, formatDuration } from '../utils.ts'
import { AgChartOptions } from 'ag-charts-enterprise'
import { useMetricsSummary, useResolvedVsUnresolvedMetrics } from '../api/metrics.ts'
import { Skeleton } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'
import { MetricsChartContainer } from '../routes/Metrics/MetricsChartContainer.tsx'

const resolvedColor = themeColors.dsBlue[300]
const unresolvedColor = themeColors.dsPink[300]
const resolvedColorWithOpacity = `${resolvedColor}4D`
const unresolvedColorWithOpacity = `${unresolvedColor}4D`

export const ResolvedVsUnresolvedFindingsChartV2 = () => {
	const { data: metricsSummary } = useMetricsSummary()
	const { data: resolvedVsUnresolvedMetrics, isPending: isPendingResolvedVsUnresolvedMetrics } =
		useResolvedVsUnresolvedMetrics()
	const normalizedData = useMemo(() => {
		return resolvedVsUnresolvedMetrics?.toSorted((a, b) => a.date.getTime() - b.date.getTime())
	}, [resolvedVsUnresolvedMetrics])

	const options: AgChartOptions = {
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
		data: normalizedData,
		background: {
			fill: themeColors.surface.secondary,
		},
		autoSize: true,
		axes: [
			{
				type: 'time',
				position: 'bottom',
				label: {
					enabled: true,
					format: '%d-%m-%Y',
				},
				nice: false,
			},
			{
				type: 'number',
				position: 'left',
			},
		],
		theme: {
			palette: {
				fills: [resolvedColorWithOpacity, unresolvedColorWithOpacity],
			},
		},
		series: [
			{
				type: 'area',
				stacked: true,
				xKey: 'date',
				yKey: 'resolved',
				yName: 'Resolved',
				stroke: resolvedColor,
				marker: {
					fill: resolvedColor,
					size: 0,
				},
				strokeWidth: 2,
				tooltip: {
					renderer: ({ datum }: { datum: ServerResolvedVsUnresolved }) => {
						return {
							title: formatDate(datum.date, false),
							content: `Resolved Findings: ${datum.resolved}`,
							backgroundColor: resolvedColor,
						}
					},
				},
			},
			{
				type: 'area',
				stacked: true,
				xKey: 'date',
				yKey: 'unresolved',
				yName: 'Unresolved',
				stroke: unresolvedColor,
				marker: {
					fill: unresolvedColor,
					size: 0,
				},
				strokeWidth: 2,
				tooltip: {
					renderer: ({ datum }: { datum: ServerResolvedVsUnresolved }) => {
						return {
							title: formatDate(datum.date, false),
							content: `Unresolved Findings: ${datum.unresolved}`,
							backgroundColor: unresolvedColor,
						}
					},
				},
			},
		],
	}
	return (
		<MetricsChartContainer className="col-span-6" header="Resolved vs Unresolved Findings">
			<div className="flex gap-1">
				<span className="text-textIcon-secondary">Average time to resolution:</span>
				<span className="font-semibold text-textIcon-primary">
					{metricsSummary?.time_to_resolve ? formatDuration(metricsSummary.time_to_resolve) : 'Loading ...'}
				</span>
			</div>
			{isPendingResolvedVsUnresolvedMetrics ? <Skeleton active /> : <AgChartsReact options={options} />}
		</MetricsChartContainer>
	)
}
