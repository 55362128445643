import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config.ts'

const fullConfig = resolveConfig(tailwindConfig)
export const { colors: themeColors } = fullConfig.theme

export function getColorFromTailwindColorClassName(tailwindBgClassName: string): string {
	const classParts = tailwindBgClassName.split('-')
	const intensity = classParts[2]
	const colorFamily = classParts[1]
	if (!colorFamily) {
		throw new Error(`Badly formatted tailwind class name ${tailwindBgClassName}`)
	}

	const color = themeColors[colorFamily as keyof typeof themeColors]
	if (!color || (!intensity && typeof color !== 'string')) {
		throw new Error(`Color ${colorFamily} from class name ${tailwindBgClassName} does not exist in theme`)
	}

	if (intensity) {
		const colorWithIntensity = color[intensity as keyof typeof color]
		if (!colorWithIntensity || typeof colorWithIntensity !== 'string') {
			throw new Error(
				`Color ${colorFamily} with intensity ${intensity} from class name ${tailwindBgClassName} does not exist in theme`,
			)
		}

		return colorWithIntensity
	}

	return color as string
}
