import { settings } from '../env-settings.ts'
import { ModuleRegistry, AllEnterpriseModule, provideGlobalGridOptions, LicenseManager } from 'ag-grid-enterprise'

export const initAgGrid = () => {
	LicenseManager.setLicenseKey(settings.viteAgGridLicense!)
	ModuleRegistry.registerModules([AllEnterpriseModule])

	provideGlobalGridOptions(
		{
			theme: 'legacy',
			defaultColDef: { suppressHeaderFilterButton: true },
			tooltipShowDelay: 200,
		},
		'deep',
	)
}
