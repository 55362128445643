import ActiveDirectoryIcon from '../../../../assets/active_directory_icon_16.svg?react'
import { ServerEnvironments } from '../../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { EnvironmentConfig, EnvironmentIntegrationCategory } from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const activeDirectoryConfig: EnvironmentConfig = {
	key: EnvironmentType.ACTIVE_DIRECTORY,
	name: 'Active Directory',
	description: 'Link Token Security to your Active Directory to map identities within your organization directory.',
	logo: <IntegrationIcon leftIcon={ActiveDirectoryIcon} />,
	category: EnvironmentIntegrationCategory.IDENTITY_ACCESS_MANAGEMENT,
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.ACTIVE_DIRECTORY].filter(
			(env) =>
				env.name.toLowerCase().includes(searchText.toLowerCase()) ||
				env.domain.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.ACTIVE_DIRECTORY].map((env) => env.name),
}
