import { z } from 'zod'

export const ServerDatabricksWorkspaceSchema = z
	.object({
		name: z.string(),
		status: z.string(),
		bucket_name: z.string(),
		credential_name: z.string(),
		metastore: z.string(),
	})
	.nullable()

export type ServerDatabricksWorkspaceInput = z.input<typeof ServerDatabricksWorkspaceSchema>
export type ServerDatabricksWorkspace = z.infer<typeof ServerDatabricksWorkspaceSchema>
