import { Tag, Tooltip } from 'antd'
import { DemoAtlassianOAuthToken } from '../../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'

type DemoAtlassianOAuthTokenPropertyProps = {
	oAuthToken: DemoAtlassianOAuthToken
}
export const DemoAtlassianOAuthTokenProperty = ({ oAuthToken }: DemoAtlassianOAuthTokenPropertyProps) => {
	const tooltipText = (
		<div className="flex flex-col">
			<span className="font-medium mb-1">OAuth Scopes:</span>
			{oAuthToken.scopes.map((scope) => (
				<span key={scope} className="font-mono">
					{scope}
				</span>
			))}
		</div>
	)
	return (
		<div className="flex items-end truncate">
			<Tooltip title={tooltipText}>
				<div className="flex items-center">
					<Tag>{oAuthToken.name}</Tag>
				</div>
			</Tooltip>
		</div>
	)
}
