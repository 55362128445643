import { JiraFieldComponent } from '../schemas/jiraSchemas.ts'
import { JiraDateField } from './JiraDateField.tsx'
import { JiraNumberField } from './JiraNumberField.tsx'
import { JiraAttachmentField } from './JiraAttachmentField.tsx'
import { JiraSelectField } from './JiraSelectField.tsx'
import { JiraStringField } from './JiraStringField.tsx'
import { JiraArrayField } from './JiraArrayField.tsx'

export const jiraFieldTypeToComponent: Partial<Record<string, JiraFieldComponent>> = {
	datetime: JiraDateField,
	number: JiraNumberField,
	attachment: JiraAttachmentField,
	securitylevel: JiraSelectField,
	string: JiraStringField,
	array: JiraArrayField,
	priority: JiraSelectField,
}
