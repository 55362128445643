import { z } from 'zod'

export const ServerAzureUsageLogSchema = z.object({
	actor: z.string(), // Actor field (previously caller)
	association: z.string().nullable(), // Association, nullable
	ip_address: z.string(), // IP Address field (renamed from ip)
	day: z.string(), // Date in string format
	operations: z.array(z.string()), // Array of operations performed
	operations_count: z.number(), // Count of operations performed
	credential_id: z.string().nullish(), // Optional credential ID
	user_agent: z.string().nullish(), // Optional user agent
	association_type: z
		.string()
		.nullish()
		.transform((value) => (value === null ? 'UNKNOWN' : value)),
})

export type ServerAzureUsageLogInput = z.input<typeof ServerAzureUsageLogSchema>
export type ServerAzureUsageLog = z.infer<typeof ServerAzureUsageLogSchema>
