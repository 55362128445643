import { ServerTag, Tag } from '../../../../schemas/tags.ts'
import { tagsIconsMap } from '../../../../components/common/IdentityTag/common.tsx'
import { Tooltip } from 'antd'
import { NodeBadge } from './NodeBadge.tsx'
import { NodeColorTypes } from '../graphUtils/nodeColors.ts'
import { useActiveSidePanelKeysContext } from '../../ActiveSidePanelKeysContext.tsx'

export type RiskNodeTagIconsProps = {
	tags?: ServerTag[] | null
	nodeColorType?: NodeColorTypes
}

export const RiskNodeTagIcons = ({ tags, nodeColorType = 'permission' }: RiskNodeTagIconsProps) => {
	const { setActiveSidePanelKeys } = useActiveSidePanelKeysContext()
	if (!tags?.length) {
		return null
	}

	const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		setActiveSidePanelKeys(['Risks'])
	}

	const initialLeftPosition = 30
	return tags
		.sort((r1, r2) => r1.name.localeCompare(r2.name))
		.map((tag, index) => {
			const TagIcon = tagsIconsMap[tag.name as Tag]?.icon
			const tooltipTitle = tagsIconsMap[tag.name as Tag]?.title
			const currentLeftPosition = initialLeftPosition + index * 20
			return (
				<Tooltip title={tooltipTitle} key={tag.id}>
					<NodeBadge nodeColorType={nodeColorType} style={{ left: currentLeftPosition }} onClick={onClick}>
						{TagIcon && <TagIcon style={{ height: 18, width: 18 }} />}
					</NodeBadge>
				</Tooltip>
			)
		})
}
