import {
	DemoJiraRole,
	DemoJiraRoleWithProject,
} from '../../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellMouseOutEvent, CellMouseOverEvent, ColDef, GetRowIdParams } from 'ag-grid-enterprise'
import { TagIcons } from '../../../../../components/common/TagIcons.tsx'
import { ServerTag, Tag, TagsDisplayNameMap } from '../../../../../schemas/tags.ts'

type DemoJiraRolesTableProps = {
	projectRoles: Record<string, Array<DemoJiraRole>>
	lastSelectedRole?: string
	onCellMouseOver: (event: CellMouseOverEvent<DemoJiraRoleWithProject>) => void
	onCellMouseOut: (event: CellMouseOutEvent<DemoJiraRoleWithProject>) => void
}

export const DemoJiraRolesTable = ({
	projectRoles,
	lastSelectedRole,
	onCellMouseOver,
	onCellMouseOut,
}: DemoJiraRolesTableProps) => {
	const gridRef = useRef<AgGridReact<DemoJiraRoleWithProject>>(null)
	useEffect(() => {
		highlightRow(lastSelectedRole)
	}, [lastSelectedRole])

	const rolesWithProject = useMemo<Array<DemoJiraRoleWithProject>>(
		() => Object.entries(projectRoles).flatMap(([project, roles]) => roles.map((role) => ({ ...role, project }))),
		[projectRoles],
	)

	const highlightRow = useCallback((rowId?: string | null) => {
		if (!rowId) {
			return
		}

		const rowNode = gridRef.current?.api?.getRowNode(rowId)
		if (!rowNode) {
			return
		}

		gridRef.current?.api?.ensureNodeVisible(rowNode, 'middle')
		gridRef.current?.api?.flashCells({ rowNodes: [rowNode] })
	}, [])

	const onGridReady = useCallback(() => {
		highlightRow(lastSelectedRole)
	}, [])

	const columnDefs = useMemo<ColDef<DemoJiraRoleWithProject>[]>(() => {
		return [
			{ field: 'project', headerName: 'Project name' },
			{ field: 'name', headerName: 'Role name' },
			{
				headerName: 'Risk',
				cellRenderer: (params: { data?: DemoJiraRoleWithProject }) => {
					return <TagIcons tags={params.data?.tags?.map(({ name }) => ({ name: name as Tag }))} />
				},
				valueGetter: (params: { data?: DemoJiraRoleWithProject }) => {
					return params.data?.tags?.map((tag: ServerTag): string => TagsDisplayNameMap[tag.name as Tag])
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			flex: 1,
		}
	}, [])

	return (
		<div className="h-64 overflow-auto">
			<AgGridReact
				className={'ag-theme-alpine w-full'}
				rowData={rolesWithProject}
				rowHeight={54}
				getRowId={(rowData: GetRowIdParams<DemoJiraRoleWithProject>) => rowData.data.name}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				ref={gridRef}
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
