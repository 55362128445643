import { z } from 'zod'
import { DateSchema } from '../../common'

export const ServerGCPUserChangeLogsSchema = z.object({
	event_name: z.string(),
	event_time: DateSchema,
	actor_principal_email: z.string(),
	affected_principal_email: z.string(),
	request_parameters: z.string(),
	log_name: z.string(),
	ip: z.string().nullable(),
	user_agent: z.string().nullable(),
})

export type ServerGCPUserChangeLogsInput = z.input<typeof ServerGCPUserChangeLogsSchema>
export type ServerGCPUserChangeLogs = z.infer<typeof ServerGCPUserChangeLogsSchema>

export interface GCPUserChangeLogsAggregatedSchema {
	actor_principal_email: string
	event_names: string[]
	first_time: Date
	last_time: Date
}

export function aggregateGCPUserChangeLogs(logs: ServerGCPUserChangeLogs[]): GCPUserChangeLogsAggregatedSchema[] {
	const aggregationMap: Map<string, GCPUserChangeLogsAggregatedSchema> = new Map()

	for (const log of logs) {
		const { actor_principal_email, event_name, event_time } = log

		if (!aggregationMap.has(actor_principal_email)) {
			// Initialize the entry if it doesn't exist
			aggregationMap.set(actor_principal_email, {
				actor_principal_email,
				event_names: [event_name],
				first_time: event_time,
				last_time: event_time,
			})
		} else {
			// Update the existing entry
			const aggregated = aggregationMap.get(actor_principal_email)!
			if (!aggregated.event_names.includes(event_name)) {
				aggregated.event_names.push(event_name)
			}
			aggregated.first_time = event_time < aggregated.first_time ? event_time : aggregated.first_time
			aggregated.last_time = event_time > aggregated.last_time ? event_time : aggregated.last_time
		}
	}

	return Array.from(aggregationMap.values()).sort((a, b) => b.last_time.getTime() - a.last_time.getTime())
}
