import { type Config } from 'tailwindcss'

// TODO: Rename `ds<colorName> to `<colorName>` when removing default Tailwind color after the design system migration
const primitiveColors = {
	white: '#fff',
	black: '#000',
	transparent: 'transparent',
	current: 'currentColor',
	dsNaturals: {
		50: '#FFFFFF',
		100: '#FAFAFC',
		200: '#F5F5F7',
		300: '#EBEBF0',
		400: '#DADAE3',
		500: '#C1C1C9',
		600: '#9A9AA6',
		700: '#7E7E8C',
		800: '#676773',
		900: '#4E4E59',
		950: '#383840',
		1000: '#212126',
	},
	dsPrimary: {
		50: '#EDFCF3',
		100: '#D3F8E0',
		200: '#ACEEC7',
		300: '#75E0A6',
		400: '#3CC982',
		500: '#1AAF69',
		600: '#0E8D54',
		700: '#0B7146',
		800: '#0B5A39',
		900: '#05291C',
	},
	dsRed: {
		50: '#FFF0F1',
		100: '#FFE2E5',
		200: '#FFCAD2',
		300: '#FF9FAC',
		400: '#FF6981',
		500: '#FF496A',
		600: '#ED1143',
		700: '#C80839',
		800: '#A80937',
		900: '#8F0C35',
	},
	dsPink: {
		50: '#FFF2F7',
		100: '#F6CFDE',
		200: '#F5A6C3',
		300: '#F488B0',
		400: '#E15689',
		500: '#D92768',
		600: '#BF225C',
		700: '#9D1C4B',
		800: '#842D4D',
		900: '#722944',
	},
	dsOrange: {
		50: '#FFF5ED',
		100: '#FFE7D4',
		200: '#FFC8A8',
		300: '#FFA771',
		400: '#FF7738',
		500: '#FE5211',
		600: '#EF3807',
		700: '#C62608',
		800: '#9D200F',
		900: '#7E1D10',
		950: '#440B06',
	},
	dsBlue: {
		50: '#F7F9FF',
		100: '#EDF1FF',
		200: '#CCD7FF',
		300: '#ACBDFB',
		400: '#809CFF',
		500: '#4C73FF',
		600: '#3D5CCC',
		700: '#2F479D',
		800: '#2D4084',
		900: '#222277',
	},
	dsGreen: {
		50: '#EFFAF4',
		100: '#D9F2E3',
		200: '#A2DDBC',
		300: '#86CFAB',
		400: '#53B487',
		500: '#31986C',
		600: '#217A55',
		700: '#1B6146',
		800: '#174E39',
		900: '#144030',
	},
	dsSky: {
		100: '#CCE2FF',
		200: '#B2D1FF',
		300: '#80B3FF',
		400: '#4C94FF',
	},
	dsViolet: {
		50: '#F8F9FD',
		100: '#F0F3FD',
		200: '#E4E9FB',
		300: '#CED5F7',
		400: '#B0BAF1',
		500: '#9096E9',
		600: '#7575DF',
		700: '#645BD1',
		800: '#554BB7',
		900: '#463F94',
	},
	dsYellow: {
		100: '#FDFFE8',
		200: '#F5FAC0',
		300: '#EDF591',
		400: '#C5D04C',
	},
} as const

const semanticColors = {
	surface: {
		primary: primitiveColors['dsNaturals'][50],
		secondary: primitiveColors['dsNaturals'][100],
		tertiary: primitiveColors['dsNaturals'][200],
		fourthy: primitiveColors['dsNaturals'][400],
		primaryDark: primitiveColors['dsNaturals'][1000],
		secondaryDark: primitiveColors['dsNaturals'][950],
		brand: primitiveColors['dsBlue'][100],
	},
	border: {
		primary: primitiveColors['dsNaturals'][200],
		secondary: primitiveColors['dsNaturals'][300],
		tertiary: primitiveColors['dsNaturals'][400],
		white: primitiveColors['dsNaturals'][50],
		primaryDark: primitiveColors['dsNaturals'][700],
		secondaryDark: primitiveColors['dsNaturals'][900],
		brand: primitiveColors['dsBlue'][500],
	},
	textIcon: {
		primary: primitiveColors['dsNaturals'][950],
		secondary: primitiveColors['dsNaturals'][700],
		tertiary: primitiveColors['dsNaturals'][600],
		disable: primitiveColors['dsNaturals'][500],
		dark: primitiveColors['dsNaturals'][50],
		secondaryDark: primitiveColors['dsNaturals'][300],
		darkDisable: primitiveColors['dsNaturals'][800],
		brand: primitiveColors['dsBlue'][500],
		link: primitiveColors['dsBlue'][500],
		linkHover: primitiveColors['dsBlue'][400],
	},
	status: {
		critical: primitiveColors['dsPink'][600],
		criticalLowContrast: primitiveColors['dsPink'][50],
		high: primitiveColors['dsRed'][500],
		highLowContrast: primitiveColors['dsRed'][50],
		medium: primitiveColors['dsOrange'][500],
		mediumLowContrast: primitiveColors['dsOrange'][50],
		active: primitiveColors['dsGreen'][500],
		activeLowContrast: primitiveColors['dsGreen'][50],
		neutralLowContrast: primitiveColors['dsNaturals'][200],
		neutral: primitiveColors['dsNaturals'][700],
		info: primitiveColors['dsBlue'][500],
		infoLowContrast: primitiveColors['dsBlue'][50],
	},
} as const

const graphColors = {
	graphCredentials: {
		text: semanticColors.textIcon.dark,
		bg: '#ED73C3',
		bgHover: '#F046B5',
		bgDisabled: primitiveColors['dsNaturals'][500],
		bgHoverDisabled: primitiveColors['dsNaturals'][600],
	},
	graphDependencies: {
		bg: '#DB7BE9',
		bgHover: '#BD58CC',
		text: semanticColors.textIcon.dark,
	},
	graphPermission: {
		text: semanticColors.textIcon.dark,
		bg: '#564D78',
		bgHover: '#312B4B',
	},
	graphEnvironment: {
		bg: primitiveColors.dsBlue[300],
		bgHover: primitiveColors.dsBlue[400],
		text: semanticColors.textIcon.dark,
	},
	graphIdentity: {
		bg: '#645BD1',
		bgHover: '#453E9E',
		text: semanticColors.textIcon.dark,
	},
	graphIssueCritical: {
		bg: semanticColors.status.critical,
		bgHover: primitiveColors.dsPink[800],
		text: semanticColors.textIcon.dark,
	},
	graphIssueHigh: {
		bg: semanticColors.status.high,
		bgHover: primitiveColors.dsRed[700],
		text: semanticColors.textIcon.dark,
	},
	graphIssueMedium: {
		bg: semanticColors.status.medium,
		bgHover: primitiveColors.dsOrange[700],
		text: semanticColors.textIcon.dark,
	},
	graphIssueLow: {
		bg: semanticColors.status.info,
		bgHover: primitiveColors.dsBlue[700],
		text: semanticColors.textIcon.dark,
	},
	graphIssueResolved: {
		bg: semanticColors.status.active,
		bgHover: primitiveColors.dsGreen[700],
		text: semanticColors.textIcon.dark,
	},
	graphIssueNeutral: {
		bg: semanticColors.status.neutral,
		bgHover: primitiveColors.dsNaturals[900],
		text: semanticColors.textIcon.dark,
	},
	graphAssociation: {
		bg: '#E4750D',
		bgHover: '#CC6E17',
		text: semanticColors.textIcon.dark,
	},
	graphAction: {
		bg: primitiveColors.dsBlue[100],
		bgHover: primitiveColors.dsBlue[200],
		text: semanticColors.textIcon.brand,
	},
} as const

export default {
	content: ['./index.html', './src/**/*.{js,jsx,mjs,cjs,ts,tsx,mts,cts}'],
	theme: {
		colors: { ...primitiveColors, ...semanticColors, ...graphColors },
		extend: {
			keyframes: {
				pulseScale: {
					'0%': {
						transform: 'scale(1)',
						boxShadow: '0 0 0 0 rgba(76, 115, 255, 0.4)',
					},
					'50%': {
						transform: 'scale(1.2)',
						boxShadow: '0 0 0 5px rgba(76, 115, 255, 0.2)',
					},
					'100%': {
						transform: 'scale(1)',
						boxShadow: '0 0 0 0 rgba(76, 115, 255, 0)',
					},
				},
			},
			animation: {
				'pulse-scale': 'pulseScale 2s infinite ease-in-out',
			},
		},
	},
	plugins: [],
} satisfies Config
