import { BareIdentityGraphNodeType, BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { EdgeType, getEdge } from '../graphUtils/nodesAndEdges.ts'
import { IdentitySource, ServerIdentity } from '../../../../schemas/identity.ts'
import { IssueName, ResourceType } from '../../../../schemas/issue.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import OktaIcon from '../../../../assets/okta_logo_16.svg?react'
import { FederationNodeType } from '../common/DemoFederationNode.tsx'
import { DemoAtlassianApiTokenNodeType } from './DemoAtlassianApiTokenNode.tsx'
import { DemoJiraProjectNodeType } from './DemoJiraProjectNode.tsx'
import { DemoJiraRoleNodeType } from './DemoJiraRoleNode.tsx'
import { DemoAtlassianOAuthTokenNodeType } from './DemoAtlassianOAuthTokenNode.tsx'
import { ServerUsageLogGrouped } from '../../../../schemas/identities/groupedUsageLogsSchema.ts'
import { usageNodeLogicalTypeToColumnId } from '../graphUtils/usageGraph.ts'
import { DemoAtlassianAdminApiKeyNodeType } from './DemoAtlassianAdminApiKeyNode.tsx'

const nodeLogicalTypeToColumnId = {
	issue: 0,
	credentialIssue: 1,
	credential: 2,
	identity: 3,
	project: 4,
	role: 5,
}

const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const federationNodeRowIndex = 2
const federationNodeId = `${nodeLogicalTypeToColumnId.identity}-${federationNodeRowIndex}`

export const credentialIssueNames: IssueName[] = [
	IssueName.DemoOverExtendedAdminApiKey,
	IssueName.DemoAdminApiKeyCreatedByOffboardedEmployee,
]

export const getDemoAtlassianNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const identityNodes: Array<
		BareIdentityGraphNodeType | BareNodeType<OwnershipNodeType> | BareNodeType<FederationNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	if (identity.demo_atlassian_user?.demo_change_logs) {
		const uniqueOwners = new Set(
			identity.demo_atlassian_user.demo_change_logs.map((changeLog) => changeLog.actor_name),
		)
		const owners = Array.from(uniqueOwners).map((owner) => ({ id: owner, name: owner }))
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners,
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.demo_atlassian_user?.okta_user) {
		const oktaUser = identity.demo_atlassian_user.okta_user
		identityNodes.push({
			type: 'federation',
			data: {
				identityId: oktaUser.id,
				name: oktaUser.name,
				Icon: OktaIcon,
				resourceType: ResourceType.OKTA,
				identitySource: IdentitySource.OKTA,
			},
			id: federationNodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: federationNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const credentialsNodes: Array<
		| BareNodeType<DemoAtlassianApiTokenNodeType>
		| BareNodeType<DemoAtlassianOAuthTokenNodeType>
		| BareNodeType<DemoAtlassianAdminApiKeyNodeType>
	> = []

	identity.demo_atlassian_user?.admin_api_keys?.forEach((apiKey) => {
		credentialsNodes.push({
			type: 'atlassianAdminApiKey',
			data: apiKey,
			id: `${nodeLogicalTypeToColumnId.credential}-${credentialsNodes.length}`,
		})
	})

	identity.demo_atlassian_user?.api_tokens?.forEach((apiToken) => {
		credentialsNodes.push({
			type: 'atlassianApiToken',
			data: apiToken,
			id: `${nodeLogicalTypeToColumnId.credential}-${credentialsNodes.length}`,
		})
	})

	identity.demo_atlassian_user?.oauth_tokens?.forEach((oAuthToken) => {
		credentialsNodes.push({
			type: 'atlassianOAuthToken',
			data: oAuthToken,
			id: `${nodeLogicalTypeToColumnId.credential}-${credentialsNodes.length}`,
		})
	})

	credentialsNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.credential}-${index}`
		edges.push(getEdge({ source: source, target: identityNodeId }))
	})

	const credentialsIssueNodes: BareNodeType<IssueNodeType>[] = []
	const issueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issue_name && credentialIssueNames.includes(issue.issue_name)) {
			credentialsIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.credentialIssue}-${credentialsIssueNodes.length}`,
			})
		} else {
			issueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.issue}-${issueNodes.length}`,
			})
		}
	})

	issueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.issue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	// Connect every credentials issue node to either:
	//  - A single credentials node, if the issue is for a specific key.
	//  - The identity node if there is no relevant access key node.
	credentialsIssueNodes.forEach((issueNode, index) => {
		const source = `${nodeLogicalTypeToColumnId.credentialIssue}-${index}`
		// There are no credentials nodes - connect the issue node to the identity node.
		if (!credentialsNodes.length) {
			edges.push(getEdge({ source: source, target: identityNodeId }))
			return
		}

		// There are credentials and the issue node should connect to one of them - try and find the relevant
		//  credentials node. If it is not found (e.g. the credential was already deleted) - connect the issue node
		//  to the identity node.
		const credentialsNodeIndices: number[] = []

		credentialsNodes.forEach((credentialsNode, credentialsNodeIndex) => {
			if (issueNode.data.issue.description?.includes(credentialsNode.data.name)) {
				credentialsNodeIndices.push(credentialsNodeIndex)
			}
		})

		// If we found relevant credentials nodes - connect the issue to each of them.
		if (credentialsNodeIndices.length) {
			credentialsNodeIndices.forEach((credentialsNodeIndex) => {
				const target = `${nodeLogicalTypeToColumnId.credential}-${credentialsNodeIndex}`
				edges.push(getEdge({ source, target }))
			})
		} else {
			// If no relevant credentials are found for this issue - connect it to the identity node.
			edges.push(getEdge({ source, target: identityNodeId }))
		}
	})

	const projectNodes: Array<BareNodeType<DemoJiraProjectNodeType>> = []
	const roleNodes: Array<BareNodeType<DemoJiraRoleNodeType>> = []
	Object.entries(identity.demo_atlassian_user?.jira_project_roles || {}).forEach(([projectName, roles]) => {
		const projectNodeId = `${nodeLogicalTypeToColumnId.project}-${projectNodes.length}`
		projectNodes.push({
			type: 'jiraProject',
			data: { projectName },
			id: projectNodeId,
		})

		edges.push(getEdge({ source: identityNodeId, target: projectNodeId }))

		roles.forEach((role) => {
			const roleNodeId = `${nodeLogicalTypeToColumnId.role}-${roleNodes.length}`
			roleNodes.push({
				type: 'jiraRole',
				data: { role },
				id: roleNodeId,
			})

			edges.push(getEdge({ source: projectNodeId, target: roleNodeId }))
		})
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'center', nodes: [...credentialsIssueNodes, ...issueNodes] },
		{ yPosition: 'top', nodes: credentialsNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: projectNodes },
		{ yPosition: 'center', nodes: roleNodes },
	]

	return [nodes, edges]
}

export const demoCreateAtlassianKeyNodes = (
	groupedLogs: ServerUsageLogGrouped[],
	identity: ServerIdentity,
): Array<
	| BareNodeType<DemoAtlassianOAuthTokenNodeType>
	| BareNodeType<DemoAtlassianAdminApiKeyNodeType>
	| BareNodeType<DemoAtlassianApiTokenNodeType>
> => {
	const oauthNodes: BareNodeType<DemoAtlassianOAuthTokenNodeType>[] = []
	const adminApiKeyNodes: BareNodeType<DemoAtlassianAdminApiKeyNodeType>[] = []
	const apiTokenNodes: BareNodeType<DemoAtlassianApiTokenNodeType>[] = []
	const oauthKeyNames = identity.demo_atlassian_user?.oauth_tokens?.map((oAuthToken) => oAuthToken.name) ?? []
	const adminApiKeyNames = identity.demo_atlassian_user?.admin_api_keys?.map((adminApiKey) => adminApiKey.name) ?? []
	const apiTokenNames = identity.demo_atlassian_user?.api_tokens?.map((apiToken) => apiToken.name) ?? []
	groupedLogs?.forEach(({ key }, index) => {
		if (oauthKeyNames.includes(key)) {
			oauthNodes.push({
				type: 'atlassianOAuthToken',
				data: {
					name: key,
					scopes: [],
				},
				id: `${usageNodeLogicalTypeToColumnId.accessKey}-${index + 1}`,
			})

			return
		}

		if (adminApiKeyNames.includes(key)) {
			adminApiKeyNodes.push({
				type: 'atlassianAdminApiKey',
				data: {
					name: key,
					expires_on: identity.demo_atlassian_user!.admin_api_keys!.find(
						(adminApiKey) => adminApiKey.name === key,
					)!.expires_on,
				},
				id: `${usageNodeLogicalTypeToColumnId.accessKey}-${index + 1}`,
			})

			return
		}

		if (apiTokenNames.includes(key)) {
			apiTokenNodes.push({
				type: 'atlassianApiToken',
				data: {
					name: key,
					expires_on: identity.demo_atlassian_user!.api_tokens!.find((apiToken) => apiToken.name === key)!
						.expires_on,
				},
				id: `${usageNodeLogicalTypeToColumnId.accessKey}-${index + 1}`,
			})

			return
		}
	})

	return [...oauthNodes, ...adminApiKeyNodes, ...apiTokenNodes]
}
