import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent, ICellRendererParams, CellMouseOverEvent, CellMouseOutEvent } from 'ag-grid-enterprise'
import { CombinedAwsIamRolePolicy } from '../../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { combinedAwsIamRolePolicyColumnDefs } from './combinedAwsIamRolePolicyDefs.tsx'
import {
	ServerAwsIamGroupDetails,
	ServerAwsIamUserPermission,
} from '../../../../../schemas/identities/awsIamUserXcSchema.ts'
import { GetRowIdParams } from 'ag-grid-community'
import { getPolicyUniqueKey } from '../../../../../utils/awsIdentityUtils.ts'

type IamUsersPoliciesTableProps = ICellRendererParams<ServerAwsIamUserPermission> & {
	lastSelectedPolicyId?: string | null
	onCellMouseOver?: (event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => void
	onCellMouseOut?: (event: CellMouseOutEvent<CombinedAwsIamRolePolicy>) => void
}
export const IamUsersPoliciesTable: React.FunctionComponent<IamUsersPoliciesTableProps> = ({
	data,
	node: _node,
	api: _api,
	onCellMouseOver,
	onCellMouseOut,
}) => {
	const gridRef = useRef<AgGridReact<CombinedAwsIamRolePolicy>>(null)
	const [rowData, setRowData] = useState<CombinedAwsIamRolePolicy[]>()

	const onGridReady = useCallback(
		(_params: GridReadyEvent<CombinedAwsIamRolePolicy>) => {
			const combinedPolicies: CombinedAwsIamRolePolicy[] = []
			;(data as ServerAwsIamUserPermission)?.AttachedManagedPolicies?.forEach((policy) => {
				combinedPolicies.push({ ...policy, IsAttached: true })
			})
			;(data as ServerAwsIamUserPermission)?.UserPolicyList?.forEach((policy) => {
				combinedPolicies.push({ ...policy, IsAttached: false })
			})
			;(data as ServerAwsIamUserPermission)?.GroupListPermissions?.forEach(
				(groupPermission: ServerAwsIamGroupDetails) => {
					groupPermission?.GroupPolicyList.forEach((policy) => {
						combinedPolicies.push({ ...policy, IsAttached: false, GroupName: groupPermission.GroupName })
					})
					groupPermission?.AttachedManagedPolicies.forEach((policy) => {
						combinedPolicies.push({ ...policy, IsAttached: true, GroupName: groupPermission.GroupName })
					})
				},
			)

			setRowData(combinedPolicies)
			gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
		},
		[data],
	)

	const columnDefs = useMemo<ColDef<CombinedAwsIamRolePolicy>[]>(() => {
		return combinedAwsIamRolePolicyColumnDefs
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={rowData}
				getRowId={(rowData: GetRowIdParams<CombinedAwsIamRolePolicy>) => getPolicyUniqueKey(rowData.data)}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
