import React from 'react'
import { EnvironmentType } from '../../../../schemas/envType'
import { ServerEntraIdEnvironment } from '../../../../schemas/environments/entraIdEnvironment'
import { ServerAwsEnvironment } from '../../../../schemas/environments/awsEnvironment'
import { EntraIdSinglePageIntegrationBody } from './EntraIdSinglePageIntegrationBody'
import { AwsSinglePageIntegrationBody } from './AwsSinglePageIntegrationBody'
import { ServerEnvironments } from '../../../../schemas/environments/environments'
import { SinglePageIntegrationEnabledEnvironments } from '../environmentIntegrationsTypes'
import { AzureDevopsSinglePageIntegrationBody } from './AzureDevopsSinglePageIntegrationBody.tsx'
import { ServerAzureDevopsEnvironment } from '../../../../schemas/environments/azureDevopsEnvironment.ts'
import { GithubSinglePageIntegrationBody } from './GithubSinglePageIntegrationBody.tsx'
import { ServerGithubEnvironment } from '../../../../schemas/environments/githubEnvironment.ts'
import { SalesforceSinglePageIntegrationBody } from './SalesforceSinglePageIntegrationBody.tsx'
import { ServerSalesforceEnvironment } from '../../../../schemas/environments/salesforceEnvironment.ts'
import { SnowflakeSinglePageIntegrationBody } from './SnowflakeSinglePageIntegrationBody.tsx'
import { ServerSnowflakeEnvironment } from '../../../../schemas/environments/snowflakeEnvironment.ts'
import { GoogleWorkspaceSinglePageIntegrationBody } from './GoogleWorkspaceSinglePageIntegrationBody.tsx'
import { ServerGoogleWorkspaceEnvironment } from '../../../../schemas/environments/googleWorkspaceEnvironment.ts'
import { JumpcloudSinglePageIntegrationBody } from './JumpcloudSinglePageIntegrationBody.tsx'
import { ServerJumpcloudEnvironment } from '../../../../schemas/environments/jumpcloudEnvironment.ts'
import { ActiveDirectorySinglePageIntegrationBody } from './ActiveDirectorySinglePageIntegrationBody.tsx'
import { ServerActiveDirectoryEnvironment } from '../../../../schemas/environments/activeDirectoryEnvironment.ts'
import { OktaSinglePageIntegrationBody } from './OktaSinglePageIntegrationBody.tsx'
import { ServerOktaEnvironment } from '../../../../schemas/environments/oktaEnvironment.ts'
import { GcpSinglePageIntegrationBody } from './GcpSinglePageIntegrationBody.tsx'
import { ServerGcpEnvironment } from '../../../../schemas/environments/gcpEnvironment.ts'

interface IntegrationBodyRendererProps {
	integrationEnvironmentType: EnvironmentType
	singlePageFilteredEnvironments: ServerEnvironments[keyof ServerEnvironments]
	singlePageEnvironments: ServerEnvironments[keyof ServerEnvironments]
}

export const SinglePageIntegrationBody: React.FC<IntegrationBodyRendererProps> = ({
	integrationEnvironmentType,
	singlePageFilteredEnvironments: filteredEnvironments,
	singlePageEnvironments: environments,
}) => {
	if (!SinglePageIntegrationEnabledEnvironments.includes(integrationEnvironmentType)) {
		return <div>Integration type not supported.</div>
	}
	if (environments.length !== 0 && filteredEnvironments.length === 0) {
		return <div>No accounts found for this search.</div>
	} else if (environments.length === 0 && filteredEnvironments.length === 0) {
		return <div>No accounts connected, connect an account to get started.</div>
	}
	switch (integrationEnvironmentType) {
		case EnvironmentType.ENTRA_ID:
			return (
				<EntraIdSinglePageIntegrationBody
					entraIdEnvironments={filteredEnvironments as ServerEntraIdEnvironment[]}
				/>
			)
		case EnvironmentType.AZURE_DEVOPS:
			return (
				<AzureDevopsSinglePageIntegrationBody
					azureDevopsEnvironments={filteredEnvironments as ServerAzureDevopsEnvironment[]}
				/>
			)
		case EnvironmentType.GITHUB:
			return (
				<GithubSinglePageIntegrationBody
					githubEnvironments={filteredEnvironments as ServerGithubEnvironment[]}
				/>
			)
		case EnvironmentType.SALESFORCE:
			return (
				<SalesforceSinglePageIntegrationBody
					salesforceEnvironments={filteredEnvironments as ServerSalesforceEnvironment[]}
				/>
			)
		case EnvironmentType.AWS:
			return <AwsSinglePageIntegrationBody awsEnvironments={filteredEnvironments as ServerAwsEnvironment[]} />
		case EnvironmentType.SNOWFLAKE:
			return (
				<SnowflakeSinglePageIntegrationBody
					snowflakeEnvironments={filteredEnvironments as ServerSnowflakeEnvironment[]}
				/>
			)
		case EnvironmentType.GOOGLE_WORKSPACE:
			return (
				<GoogleWorkspaceSinglePageIntegrationBody
					googleWorkspaceEnvironments={filteredEnvironments as ServerGoogleWorkspaceEnvironment[]}
				/>
			)
		case EnvironmentType.JUMPCLOUD:
			return (
				<JumpcloudSinglePageIntegrationBody
					jumpcloudEnvironments={filteredEnvironments as ServerJumpcloudEnvironment[]}
				/>
			)
		case EnvironmentType.ACTIVE_DIRECTORY:
			return (
				<ActiveDirectorySinglePageIntegrationBody
					activeDirectoryEnvironments={filteredEnvironments as ServerActiveDirectoryEnvironment[]}
				/>
			)
		case EnvironmentType.OKTA:
			return <OktaSinglePageIntegrationBody oktaEnvironments={filteredEnvironments as ServerOktaEnvironment[]} />
		case EnvironmentType.GCP:
			return <GcpSinglePageIntegrationBody gcpEnvironments={filteredEnvironments as ServerGcpEnvironment[]} />
	}
}
