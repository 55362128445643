import { useState } from 'react'
import { FindingsDistributionHeader } from './FindingsDistributionHeader.tsx'
import { FindingsDistributionSegment } from './helpers.tsx'
import { FindingsDistributionTreeMapChart } from '../../charts/FindingsDistributionTreeMapChart.tsx'
import { FindingsDistributionByEnvironmentTreeMapChart } from '../../charts/FindingsDistributionByEnvironmentTreeMapChart.tsx'
import { MetricsChartContainer } from './MetricsChartContainer.tsx'

export const FindingsDistribution = () => {
	const [currentDistribution, setCurrentDistribution] = useState<FindingsDistributionSegment>(
		FindingsDistributionSegment.IssueType,
	)

	return (
		<MetricsChartContainer
			className="col-span-6"
			header={
				<FindingsDistributionHeader
					onDistributionSegmentChange={setCurrentDistribution}
					currentDistribution={currentDistribution}
				/>
			}
		>
			{currentDistribution === FindingsDistributionSegment.IssueType && <FindingsDistributionTreeMapChart />}
			{currentDistribution === FindingsDistributionSegment.Environment && (
				<FindingsDistributionByEnvironmentTreeMapChart />
			)}
		</MetricsChartContainer>
	)
}
