import { Select } from 'antd'
import { useState } from 'react'
import { DaysThresholdSelectOption } from './DaysThresholdSelectOption'
import { DaysThresholdModal } from './DaysThresholdModal'

const PREDEFINED_DAYS = [30, 60, 90, 120, 180, 365] as number[]
const CUSTOM_OPTION_VALUE = 'custom'

type SelectDaysThresholdProps = {
	daysThreshold: number
	onChange: (newDaysThreshold: number | null) => void
	disabled: boolean
	loading: boolean
}

export const SelectDaysThreshold = ({ daysThreshold, onChange, disabled, loading }: SelectDaysThresholdProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	if (!daysThreshold) return null

	const options = PREDEFINED_DAYS.includes(daysThreshold) ? PREDEFINED_DAYS : [...PREDEFINED_DAYS, daysThreshold]

	return (
		<div className="flex items-center h-full">
			{disabled ? (
				<DaysThresholdSelectOption daysThreshold={daysThreshold} withBorder />
			) : (
				<>
					<Select
						placeholder="Select days"
						className="w-[200px]"
						loading={loading}
						onChange={(value: string | number) => {
							if (value === CUSTOM_OPTION_VALUE) {
								setIsModalOpen(true)
							} else {
								onChange(value as number)
							}
						}}
						value={daysThreshold}
					>
						{options.map((days) => (
							<Select.Option key={days} value={days}>
								<DaysThresholdSelectOption daysThreshold={days} />
							</Select.Option>
						))}
						<Select.Option key={CUSTOM_OPTION_VALUE} value={CUSTOM_OPTION_VALUE}>
							<div className="text-sm">Select Custom</div>
						</Select.Option>
					</Select>

					<DaysThresholdModal
						isOpen={isModalOpen}
						onClose={() => setIsModalOpen(false)}
						onSave={onChange}
						initialDays={daysThreshold}
					/>
				</>
			)}
		</div>
	)
}
