import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { ServerIssue } from '../../../../schemas/issue.ts'
import { AIRemediationTag } from '../../../../components/common/AIRemediationTag.tsx'

type IssueRemediationHeaderProps = {
	issue: ServerIssue
}

export const IssueRemediationHeader = ({ issue }: IssueRemediationHeaderProps) => (
	<div className="flex items-center gap-3">
		<div className="flex items-center gap-1">
			<span className="font-medium">Remediation Steps</span>
			<Tooltip title="Follow the remediation steps to resolve this issue">
				<InfoCircleOutlined className="text-textIcon-secondary" />
			</Tooltip>
		</div>
		<AIRemediationTag issue={issue} />
	</div>
)
