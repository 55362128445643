import { DateSchema } from '../../common.ts'
import { z } from 'zod'

export const ServerSalesforceApiTotalUsageLogSchema = z.object({
	timestamp: DateSchema,
	api_resource: z.string(),
	client_name: z.string(),
	http_method: z.string().nullish(),
	client_ip: z.string(),
	entity_name: z.string(),
	status_code: z.number().nullish(),
})

export type ServerSalesforceApiTotalUsageLogInput = z.input<typeof ServerSalesforceApiTotalUsageLogSchema>
export type ServerSalesforceApiTotalUsageLog = z.infer<typeof ServerSalesforceApiTotalUsageLogSchema>

export const ServerSalesforceApiTotalUsageAggregatedLogReadModelSchema = z.object({
	day: DateSchema,
	client_ip: z.string(),
	status_codes: z.array(z.number()),
	total_requests: z.number(),
})

export type ServerSalesforceApiTotalUsageAggregatedLogReadModelInput = z.input<
	typeof ServerSalesforceApiTotalUsageAggregatedLogReadModelSchema
>
export type ServerSalesforceApiTotalUsageAggregatedLogReadModel = z.infer<
	typeof ServerSalesforceApiTotalUsageAggregatedLogReadModelSchema
>
