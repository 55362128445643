import { Button, Tag } from 'antd'
import {
	AlertOutlined,
	ApartmentOutlined,
	CheckCircleOutlined,
	CiCircleOutlined,
	CloudOutlined,
	CloudServerOutlined,
	CloudSyncOutlined,
	DatabaseOutlined,
	KeyOutlined,
	LockOutlined,
	PlusOutlined,
	UserOutlined,
} from '@ant-design/icons'
import React, { SVGProps } from 'react'
import AwsIcon from '../../../assets/aws_icon_16.svg?react'
import SnowflakeIcon from '../../../assets/snowflake_icon.svg?react'
import AzureIcon from '../../../assets/azure_icon.svg?react'
import GcpIcon from '../../../assets/gcp_icon_20.svg?react'
import OciIcon from '../../../assets/oci_icon.svg?react'
import DatabricksIcon from '../../../assets/databricks_icon.svg?react'
import PostgresIcon from '../../../assets/postgres_icon_20.svg?react'
import RedisIcon from '../../../assets/redis_icon.svg?react'
import ElasticIcon from '../../../assets/elastic_icon.svg?react'
import MongoIcon from '../../../assets/mongo_icon.svg?react'
import MssqlIcon from '../../../assets/mssql_icon.svg?react'
import AzureVmIcon from '../../../assets/azure_virtual_machine.svg?react'
import K8sIcon from '../../../assets/kubernetes_icon_20.svg?react'
import VaultIcon from '../../../assets/vault_icon.svg?react'
import EntraIdIcon from '../../../assets/entra_id_icon_16.svg?react'
import ActiveDirectoryIcon from '../../../assets/active_directory_icon_16.svg?react'
import OktaIcon from '../../../assets/okta_logo_16.svg?react'
import JumpCloudIcon from '../../../assets/jumpcloud_icon_16.svg?react'
import GoogleWorkspaceIcon from '../../../assets/google_workspace_logo_16.svg?react'
import GitHubIcon from '../../../assets/github_icon_20.svg?react'
import GitLabIcon from '../../../assets/gitlab_icon.svg?react'
import ArgoCdIcon from '../../../assets/argocd_icon.svg?react'
import SalesforceIcon from '../../../assets/salesforce_icon.svg?react'
import NetsuiteIcon from '../../../assets/netsuite_icon.svg?react'
import OneLoginIcon from '../../../assets/onelogin_icon.svg?react'
import TorqIcon from '../../../assets/torq_icon.svg?react'
import TinesIcon from '../../../assets/tines_icon.svg?react'
import SplunkIcon from '../../../assets/splunk_icon.svg?react'
import DatadogIcon from '../../../assets/datadog_icon.svg?react'
import WizIcon from '../../../assets/wiz_icon.svg?react'
import OrcaIcon from '../../../assets/orca_icon.svg?react'
import { isDemo } from '../../../utils/demoUtils.ts'
import { NavigateToBase } from '../../NavigateToBase.tsx'

enum Integrations {
	AWS = 'AWS',
	AZURE = 'Azure',
	GCP = 'Google Cloud Platform',
	OCI = 'Oracle Cloud Infrastructure',
	SNOWFLAKE = 'Snowflake',
	DATABRICKS = 'Databricks',
	POSTGRES = 'PostgreSQL',
	REDIS = 'Redis',
	ELASTIC = 'Elastic',
	MONGO = 'MongoDB',
	MSSQL = 'Microsoft SQL Server',
	AZURE_VMS = 'Azure Virtual Machines',
	AWS_EC2 = 'AWS EC2',
	K8S = 'Kubernetes',
	GCE = 'Google Compute Engine',
	AWS_ECS = 'AWS ECS',
	AWS_LAMBDA = 'AWS Lambda',
	VAULT = 'HashiCorp Vault',
	ENTRA_ID = 'Entra ID',
	OKTA = 'Okta',
	JUMPCLOUD = 'JumpCloud',
	GOOGLE_WORKSPACE = 'Google Workspace',
	GITHUB = 'GitHub',
	GITLAB = 'GitLab',
	ARGO_CD = 'Argo CD',
	SALESFORCE = 'Salesforce',
	NETSUITE = 'NetSuite',
	ACTIVE_DIRECTORY = 'Active Directory',
	TORQ = 'Torq',
	TINES = 'Tines',
	SPLUNK = 'Splunk',
	DATADOG = 'Datadog',
	ONELOGIN = 'OneLogin',
	WIZ = 'Wiz',
	ORCA = 'Orca Security',
}

enum IntegrationCategories {
	CLOUD = 'Cloud Providers',
	DATABASE = 'Databases',
	WORKLOAD = 'Workloads',
	IDP = 'Identity Providers',
	CI_CD = 'CI/CD Toolchains',
	VAULT = 'Vaults',
	SAAS = 'Corporate SaaS',
	SOAR = 'SOAR',
	SIEM = 'SIEM',
	SECURITY = 'Security',
}

const categoryToTag: Record<IntegrationCategories, string> = {
	[IntegrationCategories.CLOUD]: 'Cloud',
	[IntegrationCategories.DATABASE]: 'Database',
	[IntegrationCategories.WORKLOAD]: 'Workload',
	[IntegrationCategories.IDP]: 'IDP',
	[IntegrationCategories.CI_CD]: 'CI/CD',
	[IntegrationCategories.VAULT]: 'Vault',
	[IntegrationCategories.SAAS]: 'SaaS',
	[IntegrationCategories.SOAR]: 'SOAR',
	[IntegrationCategories.SIEM]: 'SIEM',
	[IntegrationCategories.SECURITY]: 'Security',
}

type Integration = {
	type: Integrations
	isActive: boolean
	connectedEnvs: number
	Icon: React.ComponentType<SVGProps<SVGSVGElement>>
}

const categoryToIcon: Record<IntegrationCategories, React.ReactNode> = {
	[IntegrationCategories.CLOUD]: <CloudOutlined />,
	[IntegrationCategories.DATABASE]: <DatabaseOutlined />,
	[IntegrationCategories.WORKLOAD]: <CloudServerOutlined />,
	[IntegrationCategories.IDP]: <UserOutlined />,
	[IntegrationCategories.CI_CD]: <CiCircleOutlined />,
	[IntegrationCategories.VAULT]: <KeyOutlined />,
	[IntegrationCategories.SAAS]: <CloudSyncOutlined />,
	[IntegrationCategories.SOAR]: <ApartmentOutlined />,
	[IntegrationCategories.SIEM]: <AlertOutlined />,
	[IntegrationCategories.SECURITY]: <LockOutlined />,
}

const integrations: Record<IntegrationCategories, Integration[]> = {
	[IntegrationCategories.CLOUD]: [
		{ type: Integrations.AWS, isActive: true, connectedEnvs: 9, Icon: AwsIcon },
		{ type: Integrations.AZURE, isActive: true, connectedEnvs: 2, Icon: AzureIcon },
		{ type: Integrations.GCP, isActive: true, connectedEnvs: 5, Icon: GcpIcon },
		{ type: Integrations.OCI, isActive: false, connectedEnvs: 0, Icon: OciIcon },
	],
	[IntegrationCategories.DATABASE]: [
		{ type: Integrations.SNOWFLAKE, isActive: true, connectedEnvs: 1, Icon: SnowflakeIcon },
		{ type: Integrations.DATABRICKS, isActive: true, connectedEnvs: 1, Icon: DatabricksIcon },
		{ type: Integrations.POSTGRES, isActive: true, connectedEnvs: 1, Icon: PostgresIcon },
		{ type: Integrations.REDIS, isActive: false, connectedEnvs: 0, Icon: RedisIcon },
		{ type: Integrations.ELASTIC, isActive: false, connectedEnvs: 0, Icon: ElasticIcon },
		{ type: Integrations.MONGO, isActive: false, connectedEnvs: 0, Icon: MongoIcon },
		{ type: Integrations.MSSQL, isActive: false, connectedEnvs: 0, Icon: MssqlIcon },
	],
	[IntegrationCategories.WORKLOAD]: [
		{ type: Integrations.AZURE_VMS, isActive: true, connectedEnvs: 1, Icon: AzureVmIcon },
		{ type: Integrations.AWS_EC2, isActive: true, connectedEnvs: 3, Icon: AwsIcon },
		{ type: Integrations.K8S, isActive: true, connectedEnvs: 4, Icon: K8sIcon },
		{ type: Integrations.GCE, isActive: false, connectedEnvs: 0, Icon: GcpIcon },
		{ type: Integrations.AWS_ECS, isActive: false, connectedEnvs: 0, Icon: AwsIcon },
		{ type: Integrations.AWS_LAMBDA, isActive: false, connectedEnvs: 0, Icon: AwsIcon },
	],
	[IntegrationCategories.IDP]: [
		{ type: Integrations.ENTRA_ID, isActive: true, connectedEnvs: 1, Icon: EntraIdIcon },
		{ type: Integrations.OKTA, isActive: true, connectedEnvs: 1, Icon: OktaIcon },
		{ type: Integrations.JUMPCLOUD, isActive: true, connectedEnvs: 1, Icon: JumpCloudIcon },
		{ type: Integrations.GOOGLE_WORKSPACE, isActive: true, connectedEnvs: 1, Icon: GoogleWorkspaceIcon },
		{ type: Integrations.ACTIVE_DIRECTORY, isActive: true, connectedEnvs: 1, Icon: ActiveDirectoryIcon },
		{ type: Integrations.ONELOGIN, isActive: false, connectedEnvs: 0, Icon: OneLoginIcon },
	],
	[IntegrationCategories.SOAR]: [
		{ type: Integrations.TORQ, isActive: false, connectedEnvs: 0, Icon: TorqIcon },
		{ type: Integrations.TINES, isActive: false, connectedEnvs: 0, Icon: TinesIcon },
	],
	[IntegrationCategories.SIEM]: [
		{ type: Integrations.SPLUNK, isActive: false, connectedEnvs: 0, Icon: SplunkIcon },
		{ type: Integrations.DATADOG, isActive: false, connectedEnvs: 0, Icon: DatadogIcon },
	],
	[IntegrationCategories.SECURITY]: [
		{ type: Integrations.WIZ, isActive: false, connectedEnvs: 0, Icon: WizIcon },
		{ type: Integrations.ORCA, isActive: false, connectedEnvs: 0, Icon: OrcaIcon },
	],
	[IntegrationCategories.CI_CD]: [
		{ type: Integrations.GITHUB, isActive: true, connectedEnvs: 2, Icon: GitHubIcon },
		{ type: Integrations.GITLAB, isActive: false, connectedEnvs: 0, Icon: GitLabIcon },
		{ type: Integrations.ARGO_CD, isActive: false, connectedEnvs: 0, Icon: ArgoCdIcon },
	],
	[IntegrationCategories.VAULT]: [{ type: Integrations.VAULT, isActive: false, connectedEnvs: 0, Icon: VaultIcon }],
	[IntegrationCategories.SAAS]: [
		{ type: Integrations.SALESFORCE, isActive: false, connectedEnvs: 0, Icon: SalesforceIcon },
		{ type: Integrations.NETSUITE, isActive: false, connectedEnvs: 0, Icon: NetsuiteIcon },
	],
}

const IntegrationCategoryTag = ({ category }: { category: IntegrationCategories }) => {
	return (
		<div className="px-2 py-1 rounded border border-border-secondary flex gap-2">
			{categoryToIcon[category]}
			{categoryToTag[category]}
		</div>
	)
}

const IntegrationCard = ({ integration, category }: { integration: Integration; category: IntegrationCategories }) => {
	const { Icon } = integration
	return (
		<div className="flex flex-col justify-between h-[200px] w-[300px] rounded border border-border-secondary p-4">
			<div className="flex gap-2 items-center text-base">
				<Icon style={{ width: 20, height: 20 }} />
				{integration.type}
			</div>
			<div className="flex gap-4 items-center">
				<div className="flex flex-col gap-2">
					<span className="text-textIcon-secondary">Status</span>
					<Tag color={integration.isActive ? 'success' : undefined}>
						{integration.isActive ? 'Active' : 'Inactive'}
					</Tag>
				</div>
				{integration.isActive && (
					<div className="flex flex-col gap-2">
						<span className="text-textIcon-secondary">Environments</span>
						<span className="semibold">{integration.connectedEnvs}</span>
					</div>
				)}
			</div>
			<div className="flex justify-between border-t border-border-secondary pt-4 items-center">
				<IntegrationCategoryTag category={category} />
				<Button
					className="bg-surface-primary"
					icon={
						integration.isActive ? <CheckCircleOutlined className="text-status-active" /> : <PlusOutlined />
					}
				>
					{integration.isActive ? 'Connected' : 'Connect'}
				</Button>
			</div>
		</div>
	)
}

export const DemoIntegrationsConfig = () => {
	if (!isDemo) {
		return <NavigateToBase />
	}

	return (
		<div className="h-full flex flex-col gap-2 divide-y border rounded border-border-secondary bg-surface-primary p-4 overflow-y-auto">
			{Object.entries(integrations).map(([category, integrations]) => (
				<div key={category} className="flex flex-col gap-4 py-4 first:pt-0 last:pb-0 border-border-tertiary">
					<span className="text-base font-medium">{category}</span>
					<div className="flex gap-6 flex-wrap">
						{integrations.map((integration) => (
							<IntegrationCard
								key={integration.type}
								integration={integration}
								category={category as IntegrationCategories}
							/>
						))}
					</div>
				</div>
			))}
		</div>
	)
}
