import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent } from 'ag-grid-enterprise'
import { ServerDatabricksWorkspace } from '../../../../../schemas/identities/databricks/ServerDatabricksWorkspaceSchema.ts'

export const DemoDatabricksWorkspaceTable: React.FunctionComponent<{ data: ServerDatabricksWorkspace[] }> = ({
	data,
}) => {
	const gridRef = useRef<AgGridReact>(null)
	const onGridReady = useCallback((_params: GridReadyEvent<ServerDatabricksWorkspace>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])
	const columnDefs = useMemo<ColDef<ServerDatabricksWorkspace>[]>(() => {
		return [
			{ field: 'name', headerName: 'Name' },
			{ field: 'status', headerName: 'Status' },
			{ field: 'bucket_name', headerName: 'Bucket Name' },
			{ field: 'credential_name', headerName: 'Credential Name' },
			{ field: 'metastore', headerName: 'Metastore' },
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
