import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent, IRowNode } from 'ag-grid-enterprise'
import { ServerEntraIdUserXc } from '../../../../../schemas/identities/entraId/entraIdUserXcSchema.ts'
import { BaseEntraIdUserSchema } from '../../../../../schemas/identities/entraId/entraIdUserSchema.ts'
import { type z } from 'zod'

type BaseEntraIdUser = z.infer<typeof BaseEntraIdUserSchema>
type EntraIdUserData = ServerEntraIdUserXc | BaseEntraIdUser

export const EntraIdUserXcTable: React.FunctionComponent<{ data: EntraIdUserData[] }> = ({ data }) => {
	const gridRef = useRef<AgGridReact<EntraIdUserData>>(null)

	const onGridReady = useCallback((_params: GridReadyEvent<EntraIdUserData>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<EntraIdUserData>[]>(
		() => [
			{ field: 'user_principal_name', headerName: 'User Principal Name' },
			{ field: 'mail' },
			{ field: 'last_activity', headerName: 'Last Activity' },
			{
				field: 'account_enabled',
				headerName: 'Account Enabled',
				cellRenderer: (params: { data?: EntraIdUserData; node: IRowNode }) => {
					return <div>{params.data?.account_enabled ? 'Enabled' : 'Disabled'}</div>
				},
			},
			{ field: 'job_title', headerName: 'Job Title' },
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
