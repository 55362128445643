import { IssueName } from '../../schemas/issue.ts'

export const itdrFindingsNames: Array<IssueName> = [
	IssueName.HighRiskSignInForCloudAdmin,
	IssueName.ServiceAccountSuspiciousBehavior,
	IssueName.ClientSecretUsedFromEndpoint,
	IssueName.PersonalAccessKeyUsedFromEndpoint,
	IssueName.GcpServiceAccountUsedFromEndpoint,
	IssueName.CloudtrailIamUserUsedFromEndpoint,
]
