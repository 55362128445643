// AD Event IDs
import {
	AdUsageLogsAggregatedSchema,
	EventSpecificUsageLog,
	ServerAdUsageLogs,
} from '../../../../../schemas/identities/activeDirectory/adUsageSchema'
import {
	AdOwnershipLogsAggregatedSchema,
	EventSpecificOwnershipLog,
	ServerAdOwnershipLogs,
} from '../../../../../schemas/identities/activeDirectory/adOwnershipSchema'

export enum AdEventTypes {
	// Ownership events
	USER_CREATION = 4720,
	ACCOUNT_ENABLED = 4722,
	PASSWORD_CHANGE = 4723,
	PASSWORD_RESET = 4724,

	// Usage events
	LOGIN_SUCCESS = 4624,
	TGT_REQUEST = 4768,
	TGS_REQUEST = 4769,
	TGS_RENEWAL = 4770,
}

export function aggregateAdUsageLogs(logs: ServerAdUsageLogs[]): AdUsageLogsAggregatedSchema[] {
	// Early return for empty logs
	if (!logs.length) return []

	// Use Map for better performance with complex keys
	const groupedLogsMap = new Map<string, ServerAdUsageLogs[]>()

	// First pass: group logs by the composite key
	for (const log of logs) {
		// Create a composite key from account_name, service_name, network_address, and event_id
		const key = `${log.account_name}|${log.service_name || ''}|${log.network_address || 'unknown'}|${log.event_id}`

		const existingLogs = groupedLogsMap.get(key)
		if (existingLogs) {
			existingLogs.push(log)
		} else {
			groupedLogsMap.set(key, [log])
		}
	}

	// Second pass: create aggregated data
	const result: AdUsageLogsAggregatedSchema[] = []

	groupedLogsMap.forEach((logs, key) => {
		// Extract components from key
		const [accountName, serviceName, networkAddress, eventId] = key.split('|')
		const eventIdNum = Number(eventId)

		// Initialize with first log's timestamp to avoid unnecessary calculations later
		const firstLog = logs[0]
		let firstTime = new Date(firstLog.time_created)
		let lastTime = new Date(firstLog.time_created)
		let totalCount = 0

		const eventIds: number[] = [eventIdNum]
		const countsByEventId: Record<string, number> = {}

		// For SID frequency analysis
		const serviceSidFrequency = new Map<string, number>()
		const userSidFrequency = new Map<string, number>()

		// Process all logs in this group
		for (let i = 0; i < logs.length; i++) {
			const log = logs[i]
			const count = log.event_count || 1
			const logTime = new Date(log.time_created)

			// Track SIDs
			if (log.service_sid) {
				serviceSidFrequency.set(log.service_sid, (serviceSidFrequency.get(log.service_sid) || 0) + 1)
			}

			if (log.user_sid) {
				userSidFrequency.set(log.user_sid, (userSidFrequency.get(log.user_sid) || 0) + 1)
			}

			// Update counts for this event ID
			countsByEventId[eventId] = (countsByEventId[eventId] || 0) + count
			totalCount += count

			// Update time ranges
			if (logTime < firstTime) firstTime = logTime
			if (logTime > lastTime) lastTime = logTime
		}

		// Find most common SIDs
		const getMostCommonSid = (sidMap: Map<string, number>): string | null => {
			if (!sidMap.size) return null

			let mostCommonSid = null
			let highestCount = 0

			sidMap.forEach((count, sid) => {
				if (count > highestCount) {
					highestCount = count
					mostCommonSid = sid
				}
			})

			return mostCommonSid
		}

		// Add aggregated entry to results
		result.push({
			account_name: accountName,
			service_name: serviceName === '' ? null : serviceName,
			network_address: networkAddress === 'unknown' ? null : networkAddress,
			event_ids: eventIds,
			first_time: firstTime,
			last_time: lastTime,
			service_sid: getMostCommonSid(serviceSidFrequency),
			user_sid: getMostCommonSid(userSidFrequency),
			count: totalCount,
			countsByEventId,
		})
	})

	// Sort by last activity time, most recent first
	return result.sort((a, b) => b.last_time.getTime() - a.last_time.getTime())
}

export function getEventSpecificUsageLogs(data: AdUsageLogsAggregatedSchema[]): EventSpecificUsageLog[] {
	const result: EventSpecificUsageLog[] = []

	data.forEach((item) => {
		item.event_ids.forEach((eventId) => {
			// Get the count for this specific event ID
			const eventIdKey = eventId.toString()
			const eventCount = item.countsByEventId[eventIdKey] || 0

			if (eventCount > 0) {
				result.push({
					account_name: item.account_name,
					network_address: item.network_address,
					event_id: eventId,
					first_time: item.first_time,
					last_time: item.last_time,
					count: eventCount,
				})
			}
		})
	})

	return result.sort((a, b) => {
		if (a.account_name !== b.account_name) {
			return a.account_name.localeCompare(b.account_name)
		}
		if ((a.network_address || '') !== (b.network_address || '')) {
			return (a.network_address || '').localeCompare(b.network_address || '')
		}
		return a.event_id - b.event_id
	})
}

export function getEventSpecificOwnershipLogs(data: AdOwnershipLogsAggregatedSchema[]): EventSpecificOwnershipLog[] {
	// Early return for empty data
	if (!data.length) return []

	const result: EventSpecificOwnershipLog[] = []

	// Process each item in the dataset
	for (let i = 0; i < data.length; i++) {
		const item = data[i]

		// Process each event ID for this item
		for (let j = 0; j < item.event_ids.length; j++) {
			const eventId = item.event_ids[j]
			const eventIdKey = eventId.toString()
			const eventCount = item.countsByEventId[eventIdKey] || 0

			// Only add entries with positive counts
			if (eventCount > 0) {
				result.push({
					actor_name: item.actor_name,
					event_id: eventId,
					first_time: item.first_time,
					last_time: item.last_time,
					count: eventCount,
				})
			}
		}
	}

	// Sort the results
	return result.sort((a, b) => {
		// First sort by actor name
		const nameCompare = a.actor_name.localeCompare(b.actor_name)
		if (nameCompare !== 0) return nameCompare

		// Then by event ID if actors are the same
		return a.event_id - b.event_id
	})
}

export function aggregateAdOwnershipLogs(logs: ServerAdOwnershipLogs[]): AdOwnershipLogsAggregatedSchema[] {
	// Early return for empty logs
	if (!logs.length) return []

	// Use Map for better performance with complex keys
	const logsByActor = new Map<string, ServerAdOwnershipLogs[]>()

	// First pass: group logs by actor name
	for (const log of logs) {
		const actorName = log.actor_name
		const existingLogs = logsByActor.get(actorName)

		if (existingLogs) {
			existingLogs.push(log)
		} else {
			logsByActor.set(actorName, [log])
		}
	}

	// Second pass: create aggregated data
	const result: AdOwnershipLogsAggregatedSchema[] = []

	logsByActor.forEach((actorLogs, actorName) => {
		// Initialize with first log data
		const firstLog = actorLogs[0]
		let firstTime = new Date(firstLog.time_created)
		let lastTime = new Date(firstLog.time_created)
		let totalCount = 0
		const eventIds: number[] = []
		const countsByEventId: Record<string, number> = {}

		// Process all logs for this actor
		for (let i = 0; i < actorLogs.length; i++) {
			const log = actorLogs[i]
			const eventId = Number(log.event_id)
			const count = log.event_count || 1
			const logTime = new Date(log.time_created)

			// Update event IDs list (only add if not already present)
			if (!eventIds.includes(eventId)) {
				eventIds.push(eventId)
			}

			// Update event counts
			const eventIdKey = eventId.toString()
			countsByEventId[eventIdKey] = (countsByEventId[eventIdKey] || 0) + count

			// Update total count
			totalCount += count

			// Update time ranges
			if (logTime < firstTime) firstTime = logTime
			if (logTime > lastTime) lastTime = logTime
		}

		// Add aggregated entry to results
		result.push({
			actor_name: actorName,
			event_ids: eventIds,
			first_time: firstTime,
			last_time: lastTime,
			count: totalCount,
			countsByEventId,
		})
	})

	// Sort by last activity time, most recent first
	return result.sort((a, b) => b.last_time.getTime() - a.last_time.getTime())
}
