import GoogleWorkspaceIcon from '../../../../assets/google_workspace_logo_16.svg?react'
import { ServerEnvironments } from '../../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { EnvironmentConfig, EnvironmentIntegrationCategory } from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const defaultGoogleWorkspaceCustomerId = 'Unknown'

export const googleWorkspaceConfig: EnvironmentConfig = {
	key: EnvironmentType.GOOGLE_WORKSPACE,
	name: 'Google Workspace',
	description: 'Link Token Security to your Google Workspace organizations to map cloud identities across your IDP.',
	logo: <IntegrationIcon leftIcon={GoogleWorkspaceIcon} />,
	category: EnvironmentIntegrationCategory.IDENTITY_ACCESS_MANAGEMENT,
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.GOOGLE_WORKSPACE].filter(
			(env) =>
				env.name.toLowerCase().includes(searchText.toLowerCase()) ||
				(env.gw_customer_id?.toLowerCase() ?? defaultGoogleWorkspaceCustomerId).includes(
					searchText.toLowerCase(),
				) ||
				env.subject.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.GOOGLE_WORKSPACE].map((env) => env.name),
}
