import React from 'react'

interface IntegrationStepProps {
	image: React.ReactNode
	title: string
	description: React.ReactNode | string
	stepNumber: number
}

export const IntegrationStep = ({ image, title, description, stepNumber }: IntegrationStepProps) => {
	return (
		<div className="flex flex-col items-center p-4 bg-surface-tertiary rounded-lg border border-border-secondary">
			<div className="mb-3 w-full flex items-center justify-center">{image}</div>
			<div className="space-y-1.5 w-full">
				<div className="font-medium max-w-full">
					{stepNumber}. {title}
				</div>
				<p className="text-textIcon-secondary">{description}</p>
			</div>
		</div>
	)
}
