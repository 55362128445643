import AwsIcon from '../../../../assets/aws_icon_16.svg?react'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { ServerEnvironments, EnvironmentCreateRequest } from '../../../../schemas/environments/environments.ts'
import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationCategory,
	EnvironmentIntegrationFormData,
} from '../environmentIntegrationsTypes.ts'
import AwsGuide from '../../../../assets/integrationGuides/aws-integration-guide.pdf'
import { FormInstance } from 'antd'

export const awsConfig: ConnectableEnvironmentConfig = {
	key: EnvironmentType.AWS,
	name: 'AWS',
	description: 'Link Token Security to your AWS accounts to map cloud identities within the AWS ecosystem.',
	logo: <IntegrationIcon leftIcon={AwsIcon} />,
	category: EnvironmentIntegrationCategory.CLOUD_PROVIDERS,
	guideFileName: AwsGuide,
	formFields: [
		{
			fieldType: 'generated',
			name: 'externalId',
			label: 'Generate External ID',
			required: true,
			generateAction: () => crypto.randomUUID(),
			copyable: true,
			getExistingId: (environments) => environments?.AWS?.[0]?.external_id,
		},
		{
			fieldType: 'select',
			name: 'environmentName',
			label: 'Environment Name',
			required: true,
			placeholder: 'Select Environment',
			inputPlaceholder: 'Enter new environment name',
			allowCreate: true,
			options: (environments?: ServerEnvironments) =>
				environments?.[EnvironmentType.AWS]?.map((env) => ({
					label: env.name,
					value: env.name,
				})) || [],
		},
		{
			fieldType: 'simple',
			name: 'roleArn',
			label: 'Role ARN',
			type: 'text',
			required: true,
			placeholder: 'arn:aws:iam::123456789012:role/TokenSecurityReadOnlyAccessRole',
			rules: [
				{
					validator: (value: string) => {
						const segments = value.split(':')
						if (segments.length !== 6) {
							throw new Error('Invalid Role ARN format')
						}
						const accountId = segments[4]
						if (!/^\d{12}$/.test(accountId)) {
							throw new Error('Role ARN must contain a valid 12-digit AWS account ID')
						}
					},
					message: 'Please enter a valid Role ARN with a 12-digit AWS account ID',
				},
			],
		},
		{
			fieldType: 'group',
			name: 'cloudtrail',
			label: 'CloudTrail',
			required: false,
			fields: [
				{
					fieldType: 'simple',
					name: 's3RoleArn',
					label: 'S3 Role ARN',
					type: 'text',
					required: false,
					placeholder: 'Ex: arn:aws:iam::123456789012:role/CloudTrailReadOnlyRole',
					dependencies: ['trailArn'],
					rules: [
						{
							validator: (value: string, form: FormInstance) => {
								const trailArn = form.getFieldValue('trailArn') as string | undefined
								if (value && !trailArn) {
									throw new Error('Trail ARN is required when S3 Role ARN is provided')
								}
								if (value) {
									const segments = value.split(':')
									if (segments.length !== 6) {
										throw new Error('Invalid S3 Role ARN format')
									}
									const accountId = segments[4]
									if (!/^\d{12}$/.test(accountId)) {
										throw new Error('S3 Role ARN must contain a valid 12-digit AWS account ID')
									}
								}
							},
						},
					],
				},
				{
					fieldType: 'simple',
					name: 'trailArn',
					label: 'Trail ARN',
					type: 'text',
					required: false,
					placeholder: 'Ex: arn:aws:cloudtrail:region:123456789012:trail/trail-name',
					dependencies: ['s3RoleArn'],
					rules: [
						{
							validator: (value: string, form: FormInstance) => {
								const s3RoleArn = form.getFieldValue('s3RoleArn') as string | undefined
								if (value && !s3RoleArn) {
									throw new Error('S3 Role ARN is required when Trail ARN is provided')
								}
								if (value) {
									const segments = value.split(':')
									if (segments.length !== 6 || !segments[5].startsWith('trail/')) {
										throw new Error(
											'Invalid Trail ARN format. Should be like: arn:aws:cloudtrail:region:123456789012:trail/trail-name',
										)
									}
									const accountId = segments[4]
									if (!/^\d{12}$/.test(accountId)) {
										throw new Error('Trail ARN must contain a valid 12-digit AWS account ID')
									}
								}
							},
						},
					],
				},
			],
		},
	],
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.AWS]
			.map((env) => {
				const searchTextLower = searchText.toLowerCase()
				const envNameMatch = env.name.toLowerCase().includes(searchTextLower)

				if (envNameMatch) {
					return env
				}

				const matchingAccounts = env.aws_accounts.filter(
					(awsAccount) =>
						awsAccount.name.toLowerCase().includes(searchTextLower) ||
						awsAccount.account_id.toLowerCase().includes(searchTextLower),
				)

				if (matchingAccounts.length > 0) {
					return {
						...env,
						aws_accounts: matchingAccounts,
					}
				}

				return null
			})
			// Make sure we wont return any null values
			.filter((env): env is NonNullable<typeof env> => env !== null)
		return filtered
	},
	environmentNamesResolver: (environments) =>
		environments[EnvironmentType.AWS].flatMap((awsEnv) => awsEnv.aws_accounts.map((awsAccount) => awsAccount.name)),
	logEnvironmentsCounter: (environments) =>
		environments[EnvironmentType.AWS]
			.flatMap((awsEnv) => awsEnv.aws_accounts)
			.filter((awsAccount) => awsAccount.account_cloudtrails.length > 0).length,
	createEnvironmentPayload: (formData: EnvironmentIntegrationFormData): EnvironmentCreateRequest => ({
		name: formData.environmentName as string,
		environment_type: EnvironmentType.AWS,
		config: {
			type: EnvironmentType.AWS,
			role_arn: formData.roleArn as string,
			external_id: formData.externalId as string,
			environment_row_id: (formData.environmentRowId as string) || null,
			trail_arn: (formData.trailArn as string) || null,
			s3_role_arn: (formData.s3RoleArn as string) || null,
		},
	}),
}
