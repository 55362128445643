import React from 'react'
import { AssociationTypeEnum } from '../../schemas/identities/usageAssociationItemSchema'
import EC2Icon from '../../assets/ec2.svg?react'
import UnknownIcon from '../../assets/unknown.svg?react'
import NATIcon from '../../assets/nat_GW.svg?react'
import HumanIcon from '../../assets/user.svg?react'
import GlobalIcon from '../../assets/service_account_icon_20.svg?react'
import AtlassianIcon from '../../assets/attlasianIcon_white.svg?react'
import AwsIcon from '../../assets/awsIcon_white.svg?react'
import AzureIcon from '../../assets/azureIcon_white.svg?react'
import DefaultIcon from '../../assets/defaultIcon_white.svg?react'
import GithubIcon from '../../assets/githubIcon_white.svg?react'
import GoogleIcon from '../../assets/googleIcon_white.svg?react'
import OktaIcon from '../../assets/oktaIcon_white.svg?react'
import ZscalerIcon from '../../assets/zscalerIcon_white.svg?react'
import GcpIcon from '../../assets/gcpIcon_white.svg?react'
import InternalIcon from '../../assets/internalIcon_white.svg?react'
import NetskopeIcon from '../../assets/netskopeIcon_white.svg?react'

export const UsageAssociationIcon: React.FunctionComponent<{
	name?: string | null
	color?: string
	size?: number
	style?: React.CSSProperties
}> = ({ name, color = '#E4750D', size = 28, style }) => {
	let IconComponent
	switch (name) {
		case AssociationTypeEnum.EC2:
			IconComponent = EC2Icon
			break
		case AssociationTypeEnum.NAT_GW:
			IconComponent = NATIcon
			break
		case AssociationTypeEnum.OKTA:
			IconComponent = OktaIcon
			break
		case AssociationTypeEnum.GITHUB:
			IconComponent = GithubIcon
			break
		case AssociationTypeEnum.AWS:
			IconComponent = AwsIcon
			break
		case AssociationTypeEnum.ZSCALER:
			IconComponent = ZscalerIcon
			break
		case AssociationTypeEnum.ATLASSIAN:
			IconComponent = AtlassianIcon
			break
		case AssociationTypeEnum.AZURE:
			IconComponent = AzureIcon
			break
		case AssociationTypeEnum.GCP:
			IconComponent = GcpIcon
			break
		case AssociationTypeEnum.GOOGLE:
			IconComponent = GoogleIcon
			break
		case AssociationTypeEnum.INTERNAL:
			IconComponent = InternalIcon
			break
		case AssociationTypeEnum.NETSKOPE:
			IconComponent = NetskopeIcon
			break
		case AssociationTypeEnum.GLOBAL:
			IconComponent = GlobalIcon
			break
		case AssociationTypeEnum.HUMAN:
			IconComponent = HumanIcon
			break
		case AssociationTypeEnum.UNKNOWN:
			IconComponent = UnknownIcon
			break

		default:
			IconComponent = DefaultIcon
	}

	return <IconComponent style={{ color, width: size, height: size, ...style }} />
}
