import axios from 'axios'
import { settings } from '../env-settings.ts'
import { useAuth } from '../services/auth/auth.ts'
import { useQuery } from '@tanstack/react-query'
import { keyBy } from 'lodash'

const fronteggApi = axios.create({
	baseURL: settings.viteAuthServerBaseUrl,
})

export interface FronteggUser {
	id: string
	email: string
	name?: string
	profilePictureUrl?: string
}

interface GetFronteggUsersResponseData {
	items: FronteggUser[]
	_links: {
		next?: string
	}
}

const getFronteggUsers = async (bearer: string, tenantId?: string): Promise<FronteggUser[]> => {
	const headers: Record<string, string> = { Authorization: `Bearer ${bearer}` }
	if (tenantId) {
		headers['frontegg-tenant-id'] = tenantId
	}

	// Max limit for queries to this endpoint
	const limit = 200
	let offset = 0
	let hasMore = true
	const results: FronteggUser[] = []
	while (hasMore) {
		const response = await fronteggApi.get<GetFronteggUsersResponseData>(
			`/identity/resources/users/v3?_includeSubTenants=false&_limit=${limit}&_offset=${offset}&_sortBy=email`,
			{ headers },
		)

		results.push(...response.data.items)
		hasMore = !!response.data._links.next
		// Pagination is weird in this endpoint, it expects offset jumps by 1 (they use offset as "page")
		offset += 1
	}

	return results
}

export const useFronteggUsers = () => {
	const { user, activeTenant } = useAuth()
	const tenantId = activeTenant?.tenantId
	const queryKey = ['FronteggUsers', user.id, tenantId]
	const queryFn = () => {
		return getFronteggUsers(user.accessToken, tenantId)
	}

	return useQuery({ queryKey, queryFn, select: (data) => keyBy(data, 'id'), staleTime: Infinity })
}
