import { themeColors } from '../../../../utils/colorUtils.ts'

export const EdgeGradients = () => (
	<svg style={{ position: 'absolute', width: 0, height: 0 }}>
		<defs>
			<linearGradient id="associationGradient" x1="0" y1="0" x2="1" y2="0">
				<stop offset="0%" stopColor={themeColors.graphAssociation.bg} />
				<stop offset="100%" stopColor={themeColors.graphCredentials.bg} />
			</linearGradient>
			<linearGradient id="actionGradient" x1="0" y1="0" x2="1" y2="0">
				<stop offset="0%" stopColor={themeColors.graphCredentials.bg} />
				<stop offset="100%" stopColor={themeColors.graphAction.text} />
			</linearGradient>
		</defs>
	</svg>
)
