import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { ServerSalesforceProfile } from '../../../../schemas/identities/salesforce/salesforceProfileSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type SalesforceProfileNodeType = Node<{ profile: ServerSalesforceProfile }, 'salesforceProfile'>
type SalesforceProfileNodeProps = NodeProps<SalesforceProfileNodeType>

export const SalesforceProfileNode = ({ data: { profile } }: SalesforceProfileNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'salesforceProfile' && highlightedNode.id === profile.id,
	)

	return (
		<BasePermissionNode
			label="Salesforce Profile"
			name={{ text: profile.name }}
			risks={profile.tags}
			isHighlighted={isHighlighted}
			icon={<TeamOutlined />}
		/>
	)
}
