import { Node, NodeProps } from '@xyflow/react'
import { ServerDatabricksRole } from '../../../../schemas/identities/databricks/ServerDatabricksRoleSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type DatabricksRoleNodeType = Node<{ databricksRole: ServerDatabricksRole }, 'databricksRole'>
type DatabricksRoleNodeProps = NodeProps<DatabricksRoleNodeType>

export const DatabricksRoleNode = ({ data: { databricksRole } }: DatabricksRoleNodeProps) => (
	<BasePermissionNode
		label="Role"
		name={databricksRole?.role_name ? { text: databricksRole.role_name } : null}
		risks={databricksRole?.tags}
	/>
)
