import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GetRowIdParams, GridReadyEvent } from 'ag-grid-enterprise'

import { Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../../../../utils.ts'
import { ServerAzureDevopsPat } from '../../../../../schemas/identities/azure/azureDevops/azureDevopsPatSchema.ts'
import { JsonViewer } from '../../../../../components/common/JsonViewer.tsx'

interface AzureDevopsPatsTableProps {
	pats: ServerAzureDevopsPat[]
	lastSelectedPat?: string
}

export const AzureDevopsPatsTable: React.FC<AzureDevopsPatsTableProps> = ({ pats, lastSelectedPat }) => {
	const gridRef = useRef<AgGridReact<ServerAzureDevopsPat>>(null)

	const highlightRow = useCallback((rowId?: string | null) => {
		if (!rowId) {
			return
		}

		const rowNode = gridRef.current?.api?.getRowNode(rowId)
		if (!rowNode) {
			return
		}

		gridRef.current?.api?.ensureNodeVisible(rowNode, 'middle')
		gridRef.current?.api?.flashCells({ rowNodes: [rowNode] })
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<ServerAzureDevopsPat>) => {
			highlightRow(lastSelectedPat)
		},
		[highlightRow, lastSelectedPat],
	)

	const columnDefs = useMemo<ColDef<ServerAzureDevopsPat>[]>(() => {
		return [
			{
				field: 'access_id',
				headerName: 'Access ID',
				flex: 2,
			},
			{
				field: 'display_name',
				headerName: 'Name',
				flex: 2,
			},
			{
				field: 'valid_from',
				headerName: 'Valid From',
				flex: 2,
				cellRenderer: (params: { value: string }) => (
					<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
						<div className="text-textIcon-secondary">{formatRelativeDateText(params.value, true)}</div>
					</Tooltip>
				),
			},
			{
				field: 'valid_to',
				headerName: 'Valid Until',
				flex: 2,
				cellRenderer: (params: { value: string }) => (
					<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
						<div className="text-textIcon-secondary">{formatRelativeDateText(params.value, true)}</div>
					</Tooltip>
				),
			},
			{
				headerName: 'Details',
				flex: 2,
				cellRenderer: (params: { data?: ServerAzureDevopsPat }) => {
					if (params.data) {
						return <JsonViewer data={params.data} title="PAT Details" />
					}
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	useEffect(() => {
		highlightRow(lastSelectedPat)
	}, [lastSelectedPat, highlightRow])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				ref={gridRef}
				rowData={pats}
				getRowId={(params: GetRowIdParams<ServerAzureDevopsPat>) => params.data.access_id}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				rowHeight={54}
			/>
		</div>
	)
}
