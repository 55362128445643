import { Node, NodeProps } from '@xyflow/react'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type ConnectedGcpRoleNodeType = Node<{ role: string }, 'connectedGcpRole'>
type ConnectedGcpRoleNodeProps = NodeProps<ConnectedGcpRoleNodeType>

export const ConnectedGcpRoleNode = ({ data: { role } }: ConnectedGcpRoleNodeProps) => {
	const segments = role.split('/')
	const lastSegment = segments.pop()

	return <BasePermissionNode label="GCP Role" name={lastSegment ? { text: lastSegment } : null} />
}
