import { useState } from 'react'
import { ServerAwsIamChangeLog } from '../../../../../schemas/identities/awsIamChangeLogSchema.ts'
import { formatDate } from '../../../../../utils.ts'
import { BaseCloudtrailLogsContent } from './BaseCloudtrailLogsContent.tsx'
import { AwsIamUserChangeLogsAggregationsTable } from './AwsIamUserChangeLogsAggregationsTable.tsx'
import { ColDef, IRowNode } from 'ag-grid-enterprise'
import { JsonViewer } from '../../../../../components/common/JsonViewer.tsx'
import { ActorArnCell } from './ActorArnCell.tsx'
import { getActorArnFriendlyName } from '../../../../../utils/awsIdentityUtils.ts'

type AwsIamUserChangeLogsContentProps = {
	environmentId: string
	subEnvironmentId: string
	identityArn: string
	changeLogs: ServerAwsIamChangeLog[]
}

export const AwsIamUserChangeLogsContent = ({
	environmentId,
	subEnvironmentId,
	identityArn,
	changeLogs,
}: AwsIamUserChangeLogsContentProps) => {
	const [changeLogColumnDefs] = useState<ColDef<ServerAwsIamChangeLog>[]>([
		{
			field: 'actor_arn',
			headerName: 'Actor ID',
			valueGetter: (params: { data?: ServerAwsIamChangeLog }) => {
				return getActorArnFriendlyName(params.data?.actor_arn)
			},
			cellRenderer: (params: { data?: ServerAwsIamChangeLog; node: IRowNode; value: string }) => {
				return <ActorArnCell actorArn={params.data?.actor_arn} value={params.value} />
			},
		},
		{ field: 'event_name', headerName: 'Event Name' },
		{
			field: 'event_time',
			headerName: 'Event Time',
			valueGetter: (params: { data?: ServerAwsIamChangeLog }) => formatDate(params.data?.event_time),
		},
		{ field: 'source_ip_address', headerName: 'IP' },
		{ field: 'user_agent', headerName: 'User Agent' },
		{
			field: 'request_parameters',
			headerName: 'Request Parameters',
			valueFormatter: (params: { data?: ServerAwsIamChangeLog }) =>
				JSON.stringify(params.data?.request_parameters),
			cellRenderer: (params: { data: ServerAwsIamChangeLog }) => {
				return Object.keys(params.data.request_parameters).length ? (
					<JsonViewer data={params.data?.request_parameters} title="Request Parameters" />
				) : (
					'Empty'
				)
			},
		},
	])

	return (
		<BaseCloudtrailLogsContent
			environmentId={environmentId}
			subEnvironmentId={subEnvironmentId}
			identityArn={identityArn}
			entries={changeLogs}
			entriesColumnDefs={changeLogColumnDefs}
			ContentTable={AwsIamUserChangeLogsAggregationsTable}
			title={'Ownership'}
			modalTitle={'Ownership - Creator and Maintainers'}
		/>
	)
}
