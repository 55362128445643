import { ReactNode, type FC } from 'react'
import { Tooltip } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { highlightSearchText } from '../../utils/highlightUtils'

interface OverlappingCredentialsTagProps {
	credentials: string[]
	searchText?: string
}

const getDisplayText = (credential: string, searchText?: string): ReactNode => {
	const slicedText = credential.slice(0, 14) + (credential.length > 14 ? '...' : '')

	if (!searchText || !credential.toLowerCase().includes(searchText.toLowerCase())) {
		return slicedText
	}

	// If the sliced portion contains the search text, highlight it
	if (slicedText.toLowerCase().includes(searchText.toLowerCase())) {
		return highlightSearchText(slicedText, searchText)
	}

	// If the search text is found in the original but not in the slice,
	// show the slice with the highlight background
	return <span style={{ backgroundColor: '#FA46DC40' }}>{slicedText}</span>
}

const hasHiddenCredentialMatch = (credentials: string[], searchText?: string): boolean => {
	if (!searchText) return false
	return credentials.slice(1).some((cred) => cred.toLowerCase().includes(searchText.toLowerCase()))
}

export const OverlappingCredentialsTag: FC<OverlappingCredentialsTagProps> = ({ credentials = [], searchText }) => {
	if (!credentials.length) return null

	const [firstCredential] = credentials
	const remainingCount = Math.max(0, credentials.length - 1)

	const tagClasses = cx(
		'flex items-center gap-1',
		'px-2 py-1',
		'border shadow rounded',
		'bg-surface-secondary border-border-primary',
		'text-xs text-textIcon-primary',
		'transition-transform hover:scale-105 hover:z-10',
	)

	const tooltipContent = (
		<div className="flex flex-col gap-1">
			{credentials.map((credential, index) => (
				<span key={index}>{highlightSearchText(credential, searchText)}</span>
			))}
		</div>
	)

	return (
		<div className="flex items-start px-1">
			<Tooltip title={tooltipContent}>
				<div className={tagClasses}>
					<LockOutlined className="text-textIcon-secondary" />
					<span>{getDisplayText(firstCredential, searchText)}</span>
					{remainingCount > 0 && (
						<span
							className={cx('text-textIcon-secondary text-sm', {
								'bg-dsPink-400/20': hasHiddenCredentialMatch(credentials, searchText),
							})}
						>
							+{remainingCount}
						</span>
					)}
				</div>
			</Tooltip>
		</div>
	)
}
