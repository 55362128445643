import { Node, NodeProps } from '@xyflow/react'
import { NodeGroupBadge } from '../common/NodeGroupBadge'
import GcpIcon from '../../../../assets/gcp_project_icon.svg?react'
import { ServerGcpProject } from '../../../../schemas/environments/gcpProject'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'

export type GcpProjectsNodeType = Node<{ projects: ServerGcpProject[] }, 'gcpProjects'>
type GcpProjectsNodeProps = NodeProps<GcpProjectsNodeType>

export const GcpProjectsNode = ({ data: { projects } }: GcpProjectsNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'gcpProject')
	const badgeTooltipContent = projects.map((project) => (
		<span key={project.name}>{project.project_id || 'Unknown project'}</span>
	))

	return (
		<BaseEnvironmentNode
			label="GCP Projects"
			icon={<GcpIcon />}
			isHighlighted={isHighlighted}
			tooltipTitle="GCP Projects"
		>
			<NodeGroupBadge count={projects.length} nodeColorType="environment" tooltipContent={badgeTooltipContent} />
		</BaseEnvironmentNode>
	)
}
