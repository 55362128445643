import React, { useCallback, useRef, useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { formatDate, getCsvFileName } from '../../../../../utils.ts'
import { ColDef } from 'ag-grid-enterprise'
import { JsonViewer } from '../../../../../components/common/JsonViewer.tsx'
import ExportIcon from '../../../../../assets/export_icon_20.svg?react'
import { ServerEntraIdChangeLog } from '../../../../../schemas/identities/entraId/entraIdChangeLogSchema.ts'
import { AgGridReact } from 'ag-grid-react'
import { ServerIdentity } from '../../../../../schemas/identity.ts'
import { EntraIdChangeLogsAggregationsTable } from './EntraIdServicePrincipalChangeLogsAggregationTable.tsx'

export const EntraIdChangeLogsContent = ({
	changeLogs,
	identity,
}: {
	changeLogs: ServerEntraIdChangeLog[]
	identity: ServerIdentity
}) => {
	const gridRef: React.RefObject<AgGridReact> = useRef<AgGridReact>(null)
	const [defaultColDef] = useState({
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	})
	const [isModalOpen, setIsModalOpen] = useState(false)
	const exportToCsv = useCallback(() => {
		gridRef?.current!.api.exportDataAsCsv({
			fileName: getCsvFileName(`ownership-${identity.entra_id_service_principal?.display_name}`, false),
		})
	}, [])

	const [changeLogColumnDefs] = useState<ColDef<ServerEntraIdChangeLog>[]>([
		{
			field: 'event_time',
			headerName: 'Event Time',
			filter: 'agDateColumnFilter',
			valueGetter: (params: { data?: ServerEntraIdChangeLog }) => formatDate(params.data?.event_time),
			sort: 'desc',
		},
		{
			field: 'actor_principal_name',
			headerName: 'Actor',
			cellRenderer: (params: { data: ServerEntraIdChangeLog }) => {
				return <Tooltip title={params.data.actor_principal_id}>{params.data.actor_principal_name}</Tooltip>
			},
		},
		{
			field: 'operation_name',
			headerName: 'Operation Name',
		},
		{
			field: 'target_resources',
			filter: false,
			headerName: 'Target Resources',
			valueFormatter: ({ value }) => JSON.stringify(value),
			cellRenderer: (params: { data: ServerEntraIdChangeLog }) => {
				return params.data.target_resources.length ? (
					<JsonViewer data={params.data?.target_resources} title="Target Resources" />
				) : (
					'Empty'
				)
			},
		},
		{
			field: 'source_ip_address',
			headerName: 'Source IP Address',
		},
	])

	if (!changeLogs.length) {
		return <div className={'text-center'}>No Entra ID ownership data available</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<EntraIdChangeLogsAggregationsTable changeLogs={changeLogs} />
			</div>

			<div className="flex justify-between">
				<Button type="link" size="small" className="mt-4" onClick={() => setIsModalOpen(true)}>
					See all events
				</Button>
			</div>

			<Modal
				centered={true}
				width={1024}
				title={'Ownership'}
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex gap-2">
							<Button icon={<ExportIcon />} onClick={exportToCsv}>
								Export CSV
							</Button>
							<Button type="primary" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className={'ag-theme-alpine w-full'}
						rowData={changeLogs}
						rowHeight={54}
						columnDefs={changeLogColumnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
					/>
				</div>
			</Modal>
		</>
	)
}
