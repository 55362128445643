import { ConfigProvider, Tag } from 'antd'
import React from 'react'
import { AccountPriorityEnum, AccountPriorityTypeMap } from '../../schemas/environments/accountPriorityEnum.ts'
import { getAccountPriorityColor, getAccountPriorityColorLight } from '../../utils/styleUtils.ts'

export const AccountPriorityNameTag: React.FunctionComponent<{
	priority?: AccountPriorityEnum | null
}> = ({ priority }) => {
	if (!priority) {
		priority = AccountPriorityEnum.UNKNOWN
	}

	const tagColor: string = getAccountPriorityColor(priority)
	const tagColorLight: string = getAccountPriorityColorLight(priority)

	return (
		<ConfigProvider
			theme={{
				components: {
					Tag: {
						colorBorder: tagColor,
						colorText: tagColor,
						defaultBg: tagColorLight,
						marginXS: 0,
					},
				},
			}}
		>
			<Tag>
				<div className="flex items-center">
					<div className="text text-sm">{AccountPriorityTypeMap[priority]}</div>
				</div>
			</Tag>
		</ConfigProvider>
	)
}
