import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import AwsIcon from '../../../../assets/aws_icon_16.svg?react'
import { ServerAwsEnvironment } from '../../../../schemas/environments/awsEnvironment'
import { IntegrationCardLogsContent } from '../../common/components/IntegrationCardLogsContent'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'
import { IntegrationStatus } from '../../common/integrationTypes.ts'

type AwsSinglePageIntegrationBodyProps = {
	awsEnvironments: ServerAwsEnvironment[]
}

export const AwsSinglePageIntegrationBody: React.FC<AwsSinglePageIntegrationBodyProps> = ({ awsEnvironments }) => {
	return (
		<div className="flex flex-col gap-6">
			{awsEnvironments?.map((environment) => (
				<IntegrationsSection title={environment.name} key={environment.id}>
					{environment.aws_accounts.map((account) => {
						const cardConfig = {
							title: account.name,
							logo: <IntegrationIcon leftIcon={AwsIcon} />,
							fields: [
								...(account.name !== account.account_id
									? [
											{
												label: 'ID',
												value: account.account_id,
											},
										]
									: []),
								{
									label: 'Cloudtrail',
									disableTooltip: true,
									value: (
										<IntegrationCardLogsContent
											logsConnected={account.account_cloudtrails.length > 0}
											environmentCount={1}
										/>
									),
								},
							],
						}
						return (
							<SingleIntegrationGenericCard
								key={account.id}
								title={cardConfig.title}
								logo={cardConfig.logo}
								fields={cardConfig.fields}
								status={
									account.token_has_access === false
										? IntegrationStatus.NO_ACCESS
										: IntegrationStatus.ACTIVE
								}
							/>
						)
					})}
				</IntegrationsSection>
			))}
		</div>
	)
}
