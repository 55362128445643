export enum IntegrationStatus {
	INACTIVE = 'inactive',
	ACTIVE = 'active',
	NO_ACCESS = 'no_access',
}

export interface IntegrationState {
	status: IntegrationStatus
	environmentCount: number
}

export type IntegrationsState = Record<string, IntegrationState>
