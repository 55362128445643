import SlackIcon from '../../../../assets/slack_icon.svg?react'
import {
	CollaborationIntegrationCreateRequest,
	CollaborationIntegrationTestConnectionRequest,
} from '../../../../schemas/integrations/integrations.ts'
import { IntegrationType } from '../../../../jiraIntegration/schemas/common.ts'
import { ConnectableCollaborationIntegrationConfig } from '../collaborationIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const slackConfig: ConnectableCollaborationIntegrationConfig = {
	key: IntegrationType.SLACK,
	name: 'Slack',
	description: 'Connect Token Security to your Slack workspace for real-time notifications and alerts.',
	logo: <IntegrationIcon leftIcon={SlackIcon} />,
	createIntegrationPayload: (): CollaborationIntegrationCreateRequest => ({
		integration_type: IntegrationType.SLACK,
		name: 'Slack',
		config: {
			type: IntegrationType.SLACK,
		},
	}),
	createTestConnectionPayload: (): CollaborationIntegrationTestConnectionRequest => ({
		integration_type: IntegrationType.SLACK,
		config: {
			type: IntegrationType.SLACK,
		},
	}),
}
