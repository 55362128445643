import { ServerIdentity } from '../../../schemas/identity'
import { AwsIamUserChangeLogsContent } from './tables/aws/AwsIamUserChangeLogsContent.tsx'
import { EntraIdChangeLogsContent } from './tables/entraId/EntraIdChangeLogsContent.tsx'
import { useHighlightedNodesContext } from '../HighlightedNodesContext'
import { SalesforceOwnershipTable } from './tables/salesforce/SalesforceOwnershipTable.tsx'
import { GithubOwnershipTable } from './tables/github/GithubOwnershipTable.tsx'
import { AdOwnershipTable } from './tables/activeDirectory/AdOwnershipTable.tsx'
import { SnowflakeOwnershipTable } from './tables/snowflake/SnowflakeOwnershipTable.tsx'
import { GCPOwnershipTable } from './tables/gcp/GcpOwnershipTable.tsx'
import { DemoAtlassianOwnershipTable } from './tables/demoAtlassian/DemoAtlassianOwnershipTable.tsx'
import { IacOwnershipTable } from './tables/aws/IacOwnershipTable.tsx'
import { Collapse, CollapseProps } from 'antd'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel'
import { useCallback, useMemo } from 'react'
import UserIcon from '../../../assets/user_icon_20.svg?react'
import CodeIcon from '../../../assets/ownership_iac_code_icon.svg?react'

export type OwnershipCollapseItemKeys = 'direct_ownership' | 'iac_ownership'

type OwnershipCollapseItem = Required<CollapseProps>['items'][number] & {
	key: OwnershipCollapseItemKeys
}

type SidePanelOwnershipProps = {
	identity: ServerIdentity
	activeOwnershipKeys: OwnershipCollapseItemKeys[]
	setActiveOwnershipKeys: (keys: OwnershipCollapseItemKeys[]) => void
}

const defaultCollapseItemLabel = <CollapsibleItemLabel label="Direct Ownership" icon={UserIcon} />

export const SidePanelOwnership = ({
	identity,
	activeOwnershipKeys,
	setActiveOwnershipKeys,
}: SidePanelOwnershipProps) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()

	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList =
				typeof key === 'string' ? [key as OwnershipCollapseItemKeys] : (key as OwnershipCollapseItemKeys[])
			setActiveOwnershipKeys(keyList)
		},
		[setActiveOwnershipKeys],
	)

	const handleDirectOwnershipMouseEnter = useCallback(() => {
		setHighlightedNodes([{ type: 'ownership', id: '' }])
	}, [setHighlightedNodes])

	const items: OwnershipCollapseItem[] = useMemo(() => {
		const ret: OwnershipCollapseItem[] = []

		if (identity.aws_iam_user || identity.aws_iam_role) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: (
					<AwsIamUserChangeLogsContent
						environmentId={identity.environment_id ?? ''}
						subEnvironmentId={
							(identity.aws_iam_user?.aws_account?.id || identity.aws_iam_role?.aws_account?.id) ?? ''
						}
						identityArn={(identity.aws_iam_user?.arn || identity.aws_iam_role?.arn) ?? ''}
						changeLogs={(identity.aws_iam_user?.change_logs || identity.aws_iam_role?.change_logs) ?? []}
					/>
				),
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.databricks_service_principal?.change_logs?.length) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: (
					<AwsIamUserChangeLogsContent
						environmentId={''}
						subEnvironmentId={''}
						identityArn={''}
						changeLogs={identity.databricks_service_principal.change_logs}
					/>
				),
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.gcp_service_account?.change_logs) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: (
					<GCPOwnershipTable
						changeLogs={identity.gcp_service_account.change_logs}
						identityName={identity.gcp_service_account.name ?? ''}
					/>
				),
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.entra_id_service_principal?.change_logs) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: (
					<EntraIdChangeLogsContent
						changeLogs={identity.entra_id_service_principal.change_logs}
						identity={identity}
					/>
				),
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		const azureKvChangeLogs = [
			identity.azure_kv_certificate?.change_logs,
			identity.azure_kv_key?.change_logs,
			identity.azure_kv_secret?.change_logs,
		].filter(Boolean)

		if (azureKvChangeLogs.length) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: (
					<>
						{azureKvChangeLogs.map((changeLogs, index) => (
							<EntraIdChangeLogsContent key={index} changeLogs={changeLogs!} identity={identity} />
						))}
					</>
				),
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.active_directory_user?.ownership_logs?.length) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: <AdOwnershipTable data={identity.active_directory_user.ownership_logs} />,
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (
			identity.salesforce_user?.ownership_records ||
			identity.salesforce_connected_application?.ownership_records
		) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: (
					<SalesforceOwnershipTable
						data={
							identity.salesforce_user?.ownership_records ||
							identity.salesforce_connected_application?.ownership_records ||
							[]
						}
					/>
				),
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.github_app_installation?.owner_id) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: <GithubOwnershipTable data={[identity.github_app_installation]} />,
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.snowflake_user?.change_logs) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: (
					<SnowflakeOwnershipTable
						changeLogs={identity.snowflake_user.change_logs}
						identityName={identity.snowflake_user.name}
					/>
				),
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.postgres_role?.change_logs) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: (
					<AwsIamUserChangeLogsContent
						environmentId={''}
						subEnvironmentId={''}
						identityArn={''}
						changeLogs={identity.postgres_role.change_logs}
					/>
				),
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.demo_atlassian_user?.demo_change_logs?.length) {
			ret.push({
				label: defaultCollapseItemLabel,
				key: 'direct_ownership',
				children: <DemoAtlassianOwnershipTable data={identity.demo_atlassian_user.demo_change_logs} />,
				onMouseEnter: handleDirectOwnershipMouseEnter,
			})
		}

		if (identity.aws_iam_user?.demo_iac_data) {
			ret.push({
				label: <CollapsibleItemLabel label="IaC" icon={CodeIcon} />,
				key: 'iac_ownership',
				children: <IacOwnershipTable data={identity.aws_iam_user.demo_iac_data} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'iacOwnership', id: '' }])
				},
			})
		}

		return ret
	}, [identity, setHighlightedNodes, handleDirectOwnershipMouseEnter])

	if (items.length === 0) {
		return 'No ownership data'
	}

	return (
		<div
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			<Collapse
				onChange={onCollapseChange}
				activeKey={activeOwnershipKeys}
				className="bg-surface-primary"
				expandIconPosition={'end'}
				items={items}
			/>
		</div>
	)
}
