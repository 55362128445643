import React, { useCallback, useMemo } from 'react'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { Collapse, CollapseProps, Space } from 'antd'
import DefaultIcon from '../../../assets/defaultIcon_blue.svg?react'
import UnknownIcon from '../../../assets/unknown_sidepanel.svg?react'
import GlobalIcon from '../../../assets/global_sidepanel.svg?react'
import HumanIcon from '../../../assets/human_sidepanel.svg?react'
import EC2Icon from '../../../assets/ec2_sidepanel.svg?react'
import NatGWIcon from '../../../assets/natgw_sidepanel.svg?react'
import AtlassianIcon from '../../../assets/attlasianIcon_blue.svg?react'
import AwsIcon from '../../../assets/awsIcon_blue.svg?react'
import AzureIcon from '../../../assets/azureIcon_blue.svg?react'
import GithubIcon from '../../../assets/githubIcon_blue.svg?react'
import GoogleIcon from '../../../assets/googleIcon_blue.svg?react'
import OktaIcon from '../../../assets/oktaIcon_blue.svg?react'
import ZscalerIcon from '../../../assets/zscalerIcon_blue.svg?react'
import GcpIcon from '../../../assets/gcpIcon_blue.svg?react'
import InternalIcon from '../../../assets/internalIcon_blue.svg?react'
import NetskopeIcon from '../../../assets/netskopeIcon_blue.svg?react'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel.tsx'
import { AssociationTypeEnum, UsageAssociationItem } from '../../../schemas/identities/usageAssociationItemSchema.ts'
import { formatBigNumber } from '../../../utils/numberUtils.ts'
import { capitalizeFirstLetter } from '../../../utils/textUtils.ts'
import { GROUP_THRESHOLD } from '../IdentityGraph/graphUtils/usageGraph.ts'
import { ServerUsageLogGrouped } from '../../../schemas/identities/groupedUsageLogsSchema.ts'
import { ServerSnowflakeUserUsageLog } from '../../../schemas/identities/snowflake/snowflakeUserSchema.ts'
import { ServerAwsUsageLog } from '../../../schemas/identities/awsIamUserSchema.ts'
import { ServerGCPUserUsageLog } from '../../../schemas/identities/gcp/GcpUserUsageLogsSchema.ts'
import { ServerAzureUsageLog } from '../../../schemas/identities/entraId/azureUsageLogSchema.ts'
import { DemoAtlassianAggregatedUsageLog } from '../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'

type UsageLogsGroupedCollapseItem = Required<CollapseProps>['items'][number] & {
	key: keyof typeof AssociationTypeEnum
}

type SidePanelUsageLogsGroupedProps<TLog extends UsageLogsType> = {
	activeUsageLogsGroupedKeys: (keyof typeof AssociationTypeEnum)[]
	setActiveUsageLogsGroupedKeys: (keys: (keyof typeof AssociationTypeEnum)[]) => void
	usageLogs: Array<TLog>
	usageLogsGrouped: ServerUsageLogGrouped[]
	children: (usageLogs: Array<TLog>) => React.ReactNode
}

type AssociationStats = { instances_count: number; total_events_count: number }
type AssociationAccumulator = Record<string, AssociationStats>
type UsageLogsType =
	| ServerAwsUsageLog
	| ServerSnowflakeUserUsageLog
	| ServerGCPUserUsageLog
	| ServerAzureUsageLog
	| DemoAtlassianAggregatedUsageLog

const IconsMap: Partial<Record<AssociationTypeEnum, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>> = {
	[AssociationTypeEnum.NAT_GW]: NatGWIcon,
	[AssociationTypeEnum.EC2]: EC2Icon,
	[AssociationTypeEnum.HUMAN]: HumanIcon,
	[AssociationTypeEnum.GLOBAL]: GlobalIcon,
	[AssociationTypeEnum.UNKNOWN]: UnknownIcon,
	[AssociationTypeEnum.OKTA]: OktaIcon,
	[AssociationTypeEnum.GITHUB]: GithubIcon,
	[AssociationTypeEnum.AWS]: AwsIcon,
	[AssociationTypeEnum.ZSCALER]: ZscalerIcon,
	[AssociationTypeEnum.ATLASSIAN]: AtlassianIcon,
	[AssociationTypeEnum.AZURE]: AzureIcon,
	[AssociationTypeEnum.GCP]: GcpIcon,
	[AssociationTypeEnum.INTERNAL]: InternalIcon,
	[AssociationTypeEnum.NETSKOPE]: NetskopeIcon,
	[AssociationTypeEnum.GOOGLE]: GoogleIcon,
} as const

export const UsageLogsGroupedContent = <TLog extends UsageLogsType>({
	activeUsageLogsGroupedKeys,
	setActiveUsageLogsGroupedKeys,
	usageLogs,
	usageLogsGrouped,
	children: UsageLogsComponent,
}: SidePanelUsageLogsGroupedProps<TLog>) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()

	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList =
				typeof key === 'string'
					? [key as keyof typeof AssociationTypeEnum]
					: (key as (keyof typeof AssociationTypeEnum)[])
			setActiveUsageLogsGroupedKeys(keyList)
		},
		[activeUsageLogsGroupedKeys],
	)

	const sumByType = useMemo(
		() =>
			usageLogsGrouped.reduce<AssociationAccumulator>((acc, item: { associations: UsageAssociationItem[] }) => {
				let tempAcc: AssociationAccumulator = {}

				item.associations.forEach((assoc: UsageAssociationItem) => {
					const key = assoc.name
					if (!tempAcc[key]) {
						tempAcc[key] = {
							instances_count: 0,
							total_events_count: 0,
						}
					}

					tempAcc[key].instances_count += assoc.instances_count
					tempAcc[key].total_events_count += assoc.total_events_count
				})

				const globalKeys = Object.keys(tempAcc).filter((key) => key.startsWith('GLOBAL:'))

				if (globalKeys.length > GROUP_THRESHOLD) {
					const globalStats = globalKeys.reduce(
						(stats, key) => ({
							instances_count: stats.instances_count + tempAcc[key].instances_count,
							total_events_count: stats.total_events_count + tempAcc[key].total_events_count,
						}),
						{ instances_count: 0, total_events_count: 0 },
					)

					// Create new object without GLOBAL: entries and add combined GLOBAL entry
					tempAcc = Object.fromEntries([
						...Object.entries(tempAcc).filter(([key]) => !key.startsWith('GLOBAL:')),
						['GLOBAL', globalStats],
					])
				} else {
					// Create new object with renamed GLOBAL: keys
					tempAcc = Object.fromEntries(
						Object.entries(tempAcc).map(([key, value]) => [
							key.startsWith('GLOBAL:') ? key.replace('GLOBAL:', '') : key,
							value,
						]),
					)
				}

				// Merge tempAcc into the main accumulator
				Object.entries(tempAcc).forEach(([key, stats]) => {
					if (!acc[key]) {
						acc[key] = { instances_count: 0, total_events_count: 0 }
					}
					acc[key].instances_count += stats.instances_count
					acc[key].total_events_count += stats.total_events_count
				})

				return acc
			}, {}),
		[usageLogsGrouped],
	)

	const items: UsageLogsGroupedCollapseItem[] = useMemo(() => {
		const collapseItems: UsageLogsGroupedCollapseItem[] = []
		Object.keys(sumByType).forEach((key) => {
			const typedKey = key as keyof typeof AssociationTypeEnum
			const associationType = AssociationTypeEnum[typedKey] ?? typedKey
			const instancesCount = sumByType[associationType]?.instances_count
			const label = `${key} ${instancesCount ? `(${instancesCount})` : ''}`

			collapseItems.push({
				label: (
					<CollapsibleItemLabel
						icon={IconsMap[associationType] ?? DefaultIcon}
						label={capitalizeFirstLetter(label)}
						secondaryLabel={`${formatBigNumber(sumByType[`${associationType}`]?.total_events_count ?? 0)} Events`}
					/>
				),
				headerClass: '!items-center',
				key: typedKey,
				children: UsageLogsComponent(
					usageLogs.filter(({ association_type }) => association_type?.includes(typedKey)),
				),
			})
		})

		return collapseItems
	}, [usageLogsGrouped, setHighlightedNodes])

	if (!usageLogsGrouped.length) {
		return 'No Usage Data Available'
	}

	return (
		<Space direction="vertical" className="w-full">
			{items.map((item, index) => (
				<Collapse
					key={`${item.key}-${index}`}
					onChange={onCollapseChange}
					activeKey={activeUsageLogsGroupedKeys}
					className="bg-surface-primary"
					expandIconPosition={'end'}
					items={[item]}
				/>
			))}
		</Space>
	)
}
