import { getQueryFilterFromTableFilterModel } from '../findingsTableUtils.ts'
import { useSearch } from '@tanstack/react-router'
import { useMemo } from 'react'
import { TableFilterEntry } from '../../../schemas/tableFilter.ts'
import { PaginatedFindingsPageSearch, IssueType } from '../../../schemas/issue.ts'
import { usePaginatedIssuesStats } from '../../../api/issues.ts'
import { FindingsStatsPosture } from './FindingsStatsPaginatedPosture.tsx'
import { FindingsStatsPaginatedEnvsDistribution } from './FindingsStatsPaginatedEnvsDistribution.tsx'
import { FindingsStatsItem } from './FindingsStatsItem.tsx'
import { FindingsStatsCount } from '../FindingsStatsCount.tsx'
import { useFindings } from '../FindingsContext.tsx'

interface FindingsStatsPaginatedProps {
	issueType: IssueType
}

export const FindingsStatsPaginated = ({ issueType }: FindingsStatsPaginatedProps) => {
	const { routePath } = useFindings()

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { issueFilter, lens, tab } = useSearch({ from: routePath }) as PaginatedFindingsPageSearch

	const query = useMemo(
		() => ({
			filter: getQueryFilterFromTableFilterModel((issueFilter || {}) as Record<string, TableFilterEntry>),
		}),
		[issueFilter],
	)

	const statsQuery = usePaginatedIssuesStats(query, tab, lens, issueType)
	const { data: issuesStats } = statsQuery

	const totalCount = useMemo(() => {
		if (!issuesStats) {
			return 0
		}

		return issuesStats.total_count
	}, [issuesStats])

	return (
		<div className="py-4 px-6 flex gap-2 justify-between h-fit rounded-lg border border-border-secondary bg-surface-primary">
			<FindingsStatsItem
				title={issueType === IssueType.ITDR ? 'ITDR Alerts' : 'Findings'}
				statsQuery={statsQuery}
			>
				<FindingsStatsCount count={totalCount} />
			</FindingsStatsItem>
			<FindingsStatsPaginatedEnvsDistribution issueType={issueType} />
			<FindingsStatsPosture statsQuery={statsQuery} issueType={issueType} />
		</div>
	)
}
