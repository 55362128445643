import { z } from 'zod'
import React from 'react'

const JiraAvatarUrlsSchema = z.object({
	'16x16': z.string().optional(),
	'24x24': z.string().optional(),
	'32x32': z.string().optional(),
	'48x48': z.string().optional(),
})
export const JiraProjectSchema = z
	.object({
		expand: z.string(),
		self: z.string(),
		id: z.string(),
		key: z.string(),
		name: z.string(),
		avatarUrls: JiraAvatarUrlsSchema,
		projectTypeKey: z.string(),
		simplified: z.boolean(),
		style: z.string(),
		isPrivate: z.boolean(),
		properties: z.object({}),
	})
	.passthrough()
export type JiraProject = z.infer<typeof JiraProjectSchema>

export const JiraIssueTypeSchema = z
	.object({
		self: z.string(),
		id: z.string(),
		description: z.string(),
		iconUrl: z.string(),
		name: z.string(),
		untranslatedName: z.string(),
		subtask: z.boolean(),
		hierarchyLevel: z.number(),
	})
	.passthrough()
export type JiraIssueType = z.infer<typeof JiraIssueTypeSchema>

export const JiraUserSchema = z.object({
	self: z.string(),
	accountId: z.string(),
	accountType: z.string(),
	emailAddress: z.string(),
	avatarUrls: JiraAvatarUrlsSchema,
	displayName: z.string(),
	active: z.boolean(),
	timeZone: z.string(),
	locale: z.string(),
})
export type JiraUser = z.infer<typeof JiraUserSchema>

export const JiraFieldSchema = z
	.object({
		required: z.boolean(),
		schema: z
			.object({
				type: z.string(),
				items: z.string().optional(),
				system: z.string().optional(),
				custom: z.string().optional(),
				customId: z.number().optional(),
				configuration: z.any().optional(),
			})
			.passthrough(),
		name: z.string(),
		key: z.string(),
		autoCompleteUrl: z.string().optional(),
		hasDefaultValue: z.boolean(),
		operations: z.array(z.string()),
		fieldId: z.string(),
		allowedValues: z
			.array(
				z
					.object({
						self: z.string(),
						value: z.string().optional(),
						id: z.string(),
						description: z.string().optional(),
						iconUrl: z.string().optional(),
						name: z.string().optional(),
						subtask: z.boolean().optional(),
						avatarId: z.number().optional(),
						hierarchyLevel: z.number().optional(),
						projectTypeKey: z.string().optional(),
						key: z.string().optional(),
						simplified: z.boolean().optional(),
						avatarUrls: JiraAvatarUrlsSchema.optional(),
					})
					.passthrough(),
			)
			.optional(),
		defaultValue: z.union([
			z
				.object({
					self: z.string(),
					name: z.string(),
					id: z.string(),
					iconUrl: z.string().optional(),
				})
				.passthrough()
				.optional(),
			z.number().optional(),
			z.any(),
		]),
	})
	.passthrough()
export type JiraField = z.infer<typeof JiraFieldSchema>

export const JiraPaginationSchema = z.object({
	startAt: z.number(),
	maxResults: z.number(),
	total: z.number(),
	self: z.string().optional(),
	isLast: z.boolean().optional(),
})

export const JiraErrorResponseSchema = z.object({
	errorMessages: z.array(z.string()),
	errors: z.record(z.string()),
})

export type JiraErrorResponse = z.infer<typeof JiraErrorResponseSchema>

export const FastApiJiraHttpExceptionSchema = z.object({
	detail: JiraErrorResponseSchema,
})

export type FastApiJiraHttpException = z.infer<typeof FastApiJiraHttpExceptionSchema>

export type JiraFieldComponent = React.ComponentType<{ field?: JiraField }>

export const JiraTicketSchema = z.object({
	id: z.string(),
	key: z.string(),
	self: z.string(),
	watchers: z.any(),
})
export type JiraTicket = z.infer<typeof JiraTicketSchema>

const JiraIssuePickerIssueSchema = z.object({
	id: z.number(),
	key: z.string(),
	img: z.string(),
	keyHtml: z.string(),
	summary: z.string(),
	summaryText: z.string(),
})

export type JiraIssuePickerIssue = z.infer<typeof JiraIssuePickerIssueSchema>

export const JiraIssuePickerSchema = z.object({
	sections: z.array(
		z.object({
			id: z.string(),
			label: z.string(),
			sub: z.string().optional(),
			msg: z.string().optional(),
			issues: z.array(JiraIssuePickerIssueSchema),
		}),
	),
})

export type JiraIssuePicker = z.infer<typeof JiraIssuePickerSchema>

export const JiraCreateTicketFieldsBaseSchema = z
	.object({
		project: z.object({ key: z.string() }),
		issuetype: z.object({ id: z.string() }),
		summary: z.string(),
		description: z.any(),
		assignee: z.object({ accountId: z.string() }).optional(),
		labels: z.array(z.string()).optional(),
	})
	.catchall(z.any())
export type JiraCreateTicketFieldsBase = z.infer<typeof JiraCreateTicketFieldsBaseSchema>

export const JiraCreateTicketSchema = z.object({
	fields: JiraCreateTicketFieldsBaseSchema,
	watchers: z.array(z.string()).optional(),
})
export type JiraCreateTicket = z.infer<typeof JiraCreateTicketSchema>
