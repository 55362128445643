import { MutableRefObject, useCallback, useRef, useState } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { Button, Tooltip } from 'antd'
import { FindingsTablePaginated } from './FindingsTablePaginated.tsx'
import FindingsIcon from '../../assets/findings_icon.svg?react'
import ItdrIcon from '../../assets/itdr_icon.svg?react'
import { IssueDrawer } from '../../components/drawers/issueDrawer/IssueDrawer.tsx'
import { MenuInfo } from 'rc-menu/lib/interface'
import {
	IssueName,
	IssuesPageLens,
	IssuesTabName,
	IssueTablePaginatedRow,
	PaginatedFindingsPageSearch,
	PaginatedIssueFilter,
	IssueType,
} from '../../schemas/issue.ts'
import { AgGridReact } from 'ag-grid-react'
import { FindingsSideBarPaginatedMenu } from './FindingsSideBarPaginated.tsx'
import { FindingsStatsPaginated } from './stats/FindingsStatsPaginated.tsx'
import { FindingsQuickSearch } from './FindingsQuickSearch.tsx'
import ClearFilterIcon from '../../assets/clear_filter_icon_20.svg?react'
import { FindingsExport } from './FindingsExport.tsx'
import { isEmpty } from 'lodash'
import { PageHeader } from '../../components/common/PageHeader.tsx'
import { Page } from '../../components/common/Page.tsx'
import { OnboardingStateRenderer } from '../../components/OnboardingStateRenderer.tsx'
import { OnboardingStateRendererSidebar } from '../../components/OnboardingStateRendererSidebar.tsx'
import { useFindings } from './FindingsContext.tsx'
import { FindingsPaginatedPageTitle } from './FindingsPaginatedPageTitle.tsx'

const TabToTooltip: Partial<Record<IssuesTabName, string>> = {
	[IssuesTabName.OPEN]: 'Open and in-progress issues',
	[IssuesTabName.RESOLVED]: 'Resolved issues',
	[IssuesTabName.SUPPRESSED]: 'Ignored and false-positive issues',
	[IssuesTabName.ALL]: 'All issues',
}

interface FindingsPaginatedProps {
	issueType: IssueType
}

export const FindingsPaginated = ({ issueType }: FindingsPaginatedProps) => {
	const { routePath } = useFindings()
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { tab, issueFilter } = useSearch({ from: routePath }) as PaginatedFindingsPageSearch
	const navigate = useNavigate({ from: routePath })
	const gridRef: MutableRefObject<AgGridReact<IssueTablePaginatedRow> | null> = useRef(null)

	const [tabItems] = useState(() =>
		Object.values(IssuesTabName).map((tabName) => ({
			key: tabName,
			label: TabToTooltip[tabName] ? <Tooltip title={TabToTooltip[tabName]}>{tabName}</Tooltip> : tabName,
			children: null,
		})),
	)

	const onTabChange = (activeKey: string) => {
		void navigate({
			to: routePath,
			search: (prev) => {
				const newSearch = { ...prev } as PaginatedFindingsPageSearch
				delete newSearch.issueFilter
				// When changing tabs we need to reset the page
				delete newSearch.page
				newSearch.tab = activeKey as IssuesTabName
				return newSearch
			},
		})
	}

	const onFindingsSideBarMenuClick = (e: MenuInfo) => {
		if (Object.values(IssuesPageLens).includes(e.key as IssuesPageLens)) {
			const newLens = e.key as IssuesPageLens
			void navigate({
				to: routePath,
				search: () => {
					return { lens: newLens, tab: IssuesTabName.OPEN }
				},
			})
		} else if (Object.values(IssueName).includes(e.key as IssueName)) {
			const issueName = e.key as IssueName
			void navigate({
				to: routePath,
				search: (prev) => {
					const newIssueFilter = { ...(prev.issueFilter as PaginatedIssueFilter) }
					newIssueFilter.issue_name = {
						filterType: 'set',
						values: [issueName],
					}
					return { issueFilter: newIssueFilter, tab: IssuesTabName.OPEN }
				},
			})
		} else {
			void navigate({
				to: routePath,
				search: () => {
					return {}
				},
			})
		}
	}

	const clearFilters = useCallback(() => {
		if (isEmpty(issueFilter)) {
			return
		}
		void navigate({
			to: routePath,
			search: (prev) => {
				const newSearch = { ...prev } as PaginatedFindingsPageSearch
				delete newSearch.issueFilter
				// When clearing a filter we need to reset the page
				delete newSearch.page
				return newSearch
			},
		})
	}, [issueFilter, routePath])

	return (
		<Page
			sidebar={
				<OnboardingStateRendererSidebar>
					<FindingsSideBarPaginatedMenu
						onFindingsSideBarMenuClick={onFindingsSideBarMenuClick}
						gridRef={gridRef}
						issueType={issueType}
					/>
				</OnboardingStateRendererSidebar>
			}
			contentClassName="relative"
		>
			<PageHeader
				Icon={issueType === IssueType.ITDR ? ItdrIcon : FindingsIcon}
				tabsProps={{
					activeKey: tab ?? 'Open',
					onChange: onTabChange,
					items: tabItems,
				}}
			>
				<FindingsPaginatedPageTitle />
			</PageHeader>
			<OnboardingStateRenderer>
				<FindingsStatsPaginated issueType={issueType} />
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-2">
						<FindingsQuickSearch routePath={routePath} />
						<Tooltip placement="topLeft" title="Clear filters">
							<Button
								icon={<ClearFilterIcon />}
								onClick={clearFilters}
								className="border-border-tertiary"
							/>
						</Tooltip>
					</div>
					<FindingsExport gridRef={gridRef} issueType={issueType} />
				</div>
				<div className="h-full w-full">
					<FindingsTablePaginated gridRef={gridRef} issueType={issueType} />
				</div>
				<IssueDrawer route={routePath} />
			</OnboardingStateRenderer>
		</Page>
	)
}
