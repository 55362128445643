import React from 'react'
import { Tag } from 'antd'

import { IdentityTypeWithIcon } from './IdentityTypeWithIcon.tsx'

export const IdentityTypeTag: React.FunctionComponent<{
	type?: string | null
}> = ({ type }) => (
	<Tag className="flex items-center content-center font-normal">
		<IdentityTypeWithIcon type={type} />
	</Tag>
)
