import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import OktaIcon from '../../../../assets/okta_logo_16.svg?react'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'
import { ServerOktaEnvironment } from '../../../../schemas/environments/oktaEnvironment.ts'

type OktaSinglePageIntegrationBodyProps = {
	oktaEnvironments: ServerOktaEnvironment[]
}

export const OktaSinglePageIntegrationBody: React.FC<OktaSinglePageIntegrationBodyProps> = ({ oktaEnvironments }) => {
	return (
		<IntegrationsSection>
			{oktaEnvironments?.map((environment) => {
				const cardConfig = {
					title: environment.name,
					logo: <IntegrationIcon leftIcon={OktaIcon} />,
					fields: [{ label: 'Organization', value: environment.env_domain }],
				}
				return (
					<SingleIntegrationGenericCard
						key={environment.id}
						title={cardConfig.title}
						logo={cardConfig.logo}
						fields={cardConfig.fields}
					/>
				)
			})}
		</IntegrationsSection>
	)
}
