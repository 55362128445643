import { z } from 'zod'
import { camelizeSchema } from './schemas/utils'

export const EnvSettingsRawSchema = z.object({
	NODE_ENV: z.enum(['development', 'staging', 'production']).default('development'),
	MODE: z.enum(['development', 'staging', 'production', 'demo', 'devdemo']).default('development'),
	VITE_AUTH_SERVER_BASE_URL: z.string().url(),
	VITE_AUTH_CLIENT_ID: z.string().uuid(),
	VITE_BACKEND_URL: z.string().url(),
	VITE_SENTRY_DSN: z.string().url().optional(),
	VITE_AG_GRID_LICENSE: z.string().optional(),
	VITE_AG_CHART_LICENSE: z.string().optional(),
	VITE_LOG_ROCKET_APP_ID: z.string().optional(),
	VITE_IS_DEMO: z.string().optional().default('false'),
})
export type EnvSettingsRaw = z.infer<typeof EnvSettingsRawSchema>

export const EnvSettingsSchema = camelizeSchema(EnvSettingsRawSchema.transform((val) => val))
export type EnvSettings = z.infer<typeof EnvSettingsSchema>

export const settings = EnvSettingsSchema.parse(import.meta.env)
