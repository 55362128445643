import { ServerIssue, IssueNameMap } from '../../../schemas/issue.ts'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { IssueIcon } from '../../../components/common/IssueIcon.tsx'
import { PriorityNameTag } from '../../../components/common/PriorityNameTag.tsx'
import { Button, ConfigProvider } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { JiraIntegrationWrapper } from '../../../jiraIntegration/components/JiraIntegrationWrapper.tsx'
import { IssueTitleStatus } from './IssueTitleStatus.tsx'
import remarkGfm from 'remark-gfm'
import rehypeExternalLinks from 'rehype-external-links'
import Markdown from 'react-markdown'
import { ServerIdentity } from '../../../schemas/identity.ts'
import { SelectAssignee } from './SelectAssignee.tsx'
import { RoutePaths } from '../../RoutePaths.tsx'
import { themeColors } from '../../../utils/colorUtils.ts'
import { MailToIssueButton } from './MailToIssueButton.tsx'
import { IssueRemediation } from './IssueRemediation/IssueRemediation.tsx'
import { IssueDate } from './IssueDate.tsx'
import { useDeferredValue } from 'react'

const IssuesListNavigation = ({ issues, currentIssueIndex }: { issues: ServerIssue[]; currentIssueIndex: number }) => {
	const navigate = useNavigate({ from: RoutePaths.Identity })
	const previousIssueId = currentIssueIndex === 0 ? undefined : issues[currentIssueIndex - 1].id
	const nextIssueId = currentIssueIndex === issues.length - 1 ? undefined : issues[currentIssueIndex + 1].id
	return (
		<ConfigProvider
			theme={{
				token: { colorLink: themeColors.textIcon.primary, colorLinkHover: themeColors.textIcon.secondary },
			}}
		>
			<div className="flex items-center">
				<Button
					type="link"
					disabled={!previousIssueId}
					icon={<LeftOutlined />}
					onClick={() => {
						void navigate({ search: (prev) => ({ ...prev, issueId: previousIssueId }) })
					}}
				/>
				<div className="text-textIcon-secondary">
					<span className="text-textIcon-primary semibold">{currentIssueIndex + 1}</span>
					<span>{`/${issues.length}`}</span>
				</div>
				<Button
					disabled={!nextIssueId}
					type="link"
					icon={<RightOutlined />}
					onClick={() => {
						void navigate({ search: (prev) => ({ ...prev, issueId: nextIssueId }) })
					}}
				/>
			</div>
		</ConfigProvider>
	)
}

type IssuesViewProps = {
	issues: ServerIssue[]
	identity: ServerIdentity
}

export const IssuesView = ({ issues, identity }: IssuesViewProps) => {
	const { issueId: searchIssueId } = useSearch({ from: RoutePaths.Identity })
	// Deferring the issue ID from the URL to ensure that this is rendered only after the tab change is completed when
	//  switching between closed/open issue tabs
	const issueId = useDeferredValue(searchIssueId)

	if (!issueId) {
		return null
	}

	const currentIssueIndex = issues.findIndex((issue) => issue.id === issueId)
	if (currentIssueIndex === -1) {
		return `Could not find issue ${issueId}`
	}

	const currentIssue = issues[currentIssueIndex]

	return (
		<div className="flex flex-col h-full">
			<div className="flex gap-2 items-center border-b border-border-secondary p-3">
				{issues.length > 1 && <IssuesListNavigation issues={issues} currentIssueIndex={currentIssueIndex} />}
				<IssueIcon name={currentIssue.issue_name} />
				<span className="font-semibold text-base">
					{currentIssue.issue_name ? IssueNameMap[currentIssue.issue_name] : 'Unknown Issue'}
				</span>
				<PriorityNameTag priority={currentIssue.priority} />
				<IssueTitleStatus issueId={currentIssue.id} currentIssue={currentIssue} />
			</div>
			<div className="flex flex-col p-4 gap-4 overflow-y-auto grow">
				<div className="flex items-center gap-2 flex-wrap">
					<IssueDate date={currentIssue.created_at} label="Date created" />
					<IssueDate date={currentIssue.last_seen} label="Last seen" />
					<IssueDate date={currentIssue.resolved_at} label="Resolved at" />
					<MailToIssueButton currentIssue={currentIssue} identity={identity} />
					<JiraIntegrationWrapper issueId={currentIssue.id} currentIssue={currentIssue} />
					<SelectAssignee issueId={currentIssue.id} currentIssue={currentIssue} />
				</div>
				<div className="text-wrap whitespace-pre text-textIcon-secondary my-4">
					<Markdown
						remarkPlugins={[remarkGfm]}
						children={currentIssue.description || ''}
						rehypePlugins={[[rehypeExternalLinks, { target: '_blank', rel: ['noreferrer noopener'] }]]}
					/>
				</div>
				<IssueRemediation identity={identity} issue={currentIssue} />
			</div>
		</div>
	)
}
