import React from 'react'

export const CollapsibleItemLabel: React.FunctionComponent<{
	icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
	label: string
	secondaryLabel?: string
}> = ({ icon: Icon, label, secondaryLabel }) => {
	return (
		<div className="grid grid-flow-col grid-cols-[40%_30%] items-center justify-items-start">
			<div className="flex items-center gap-2">
				{Icon && <Icon className="bg-status-infoLowContrast text-textIcon-brand w-7 h-7 p-1 rounded" />}
				<div className="text-nowrap">{label}</div>
			</div>
			{secondaryLabel && <div className="text-textIcon-secondary">{secondaryLabel}</div>}
		</div>
	)
}
