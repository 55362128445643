import { Button, Form, Typography, Spin } from 'antd'
import React, { useCallback, useState } from 'react'
import InsightIcon from '../../../../assets/insight_icon_16.svg?react'
import { ConnectableEnvironmentConfig, EnvironmentIntegrationFormData } from '../environmentIntegrationsTypes.ts'
import { FormFieldRenderer } from './FormFieldRenderer.tsx'
import { useEnvironments } from '../../../../api/environments'
import {
	EnvironmentTestConnectionRequest,
	EnvironmentTestConnectionResponse,
	EnvironmentTestConnectionResponsePayload,
	EnvironmentTestConnectionResponseSchema,
} from '../../../../schemas/environments/environments.ts'

const { Link } = Typography

interface EnvironmentIntegrationFormProps {
	integration: ConnectableEnvironmentConfig
	onSubmit: (
		formData: EnvironmentIntegrationFormData,
		testResultPayload: EnvironmentTestConnectionResponsePayload,
	) => void
	onTestConnection: (request: EnvironmentTestConnectionRequest) => Promise<EnvironmentTestConnectionResponse>
	onClose: () => void
	isConnecting: boolean
	isTestingConnection: boolean
}

export const EnvironmentIntegrationForm: React.FC<EnvironmentIntegrationFormProps> = ({
	integration,
	onSubmit,
	onTestConnection,
	onClose,
	isConnecting,
	isTestingConnection,
}) => {
	const [form] = Form.useForm<EnvironmentIntegrationFormData>()
	const [testResult, setTestResult] = useState<EnvironmentTestConnectionResponse | null>(null)
	const { data: environments, isPending, isError } = useEnvironments()

	const handleTestConnection = () => {
		const createTestConnection = integration.createTestConnectionPayload
		if (!createTestConnection) return

		form.validateFields()
			.then((values) => {
				const testConnectionRequest = createTestConnection(values)

				onTestConnection(testConnectionRequest)
					.then((result) => {
						setTestResult(result)
					})
					.catch(() => {
						setTestResult(
							EnvironmentTestConnectionResponseSchema.parse({
								success: false,
								message: 'Error testing connection',
							}),
						)
					})
			})
			.catch(() => {})
	}

	const onFormValuesChange = useCallback(() => {
		setTestResult((currentTestResult) => (currentTestResult?.success ? null : currentTestResult))
	}, [setTestResult])

	const handleGuideDownload = () => {
		const link = document.createElement('a')
		link.href = integration.guideFileName
		link.download = `${integration.key}-integration-guide.pdf`
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	const onFormFinish = useCallback(
		(formData: EnvironmentIntegrationFormData) => {
			onSubmit(formData, testResult?.payload)
		},
		[onSubmit, testResult],
	)

	const handleClose = useCallback(() => {
		if (integration.onFormClose) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			integration.onFormClose(form.getFieldsValue(true))
		}
		onClose()
	}, [integration, onClose])

	return (
		<>
			<p className="text-sm text-textIcon-secondary mb-6 mt-6">
				Please visit the <Link onClick={handleGuideDownload}>Integration Guide</Link> for further information
			</p>

			<Form form={form} layout="vertical" onFinish={onFormFinish} onValuesChange={onFormValuesChange}>
				{isPending ? (
					<div className="flex justify-center items-center py-12">
						<Spin size="large" />
					</div>
				) : isError ? (
					<div className="flex flex-col items-center py-12">
						<p className="text-status-critical mb-2">Failed to load environments</p>
						<p className="text-sm text-textIcon-secondary">
							Please try again later or contact support if the issue persists
						</p>
					</div>
				) : (
					integration.formFields.map((field) => (
						<FormFieldRenderer
							key={field.name}
							field={field}
							form={form}
							disabled={isConnecting || isTestingConnection}
							environments={environments}
						/>
					))
				)}

				{integration.createTestConnectionPayload && (
					<div className="flex items-center gap-2 mb-4">
						<Button
							type="default"
							onClick={handleTestConnection}
							disabled={isConnecting || isError}
							loading={isTestingConnection}
							icon={<InsightIcon className="w-4 h-4" />}
						>
							Test Connection
						</Button>
						{testResult && (
							<span
								className={`text-sm ${testResult.success ? 'text-status-active' : 'text-status-critical'}`}
							>
								{testResult.success ? 'Connection Succeed' : 'Connection Failed'}
							</span>
						)}
					</div>
				)}

				<div className="flex justify-between pt-4 border-t border-border-secondary">
					<Button onClick={handleClose} className="px-4">
						Cancel
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						disabled={isError || (integration.createTestConnectionPayload ? !testResult?.success : false)}
						loading={isConnecting || isPending}
					>
						Connect
					</Button>
				</div>
			</Form>
		</>
	)
}
