import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { DemoJiraRole } from '../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type DemoJiraRoleNodeType = Node<{ role: DemoJiraRole }, 'jiraRole'>
type DemoJiraRoleNodeProps = NodeProps<DemoJiraRoleNodeType>

export const DemoJiraRoleNode = ({ data: { role } }: DemoJiraRoleNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'jiraRole' && highlightedNode.id === role.name,
	)

	return (
		<BasePermissionNode
			label="Jira Role"
			name={{ text: role.name }}
			risks={role.tags}
			isHighlighted={isHighlighted}
		/>
	)
}
