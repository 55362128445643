import { NodeProps, Node } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseIdentityNode } from './baseNodes/BaseIdentityNode.tsx'
import { NodeGroupBadge } from './NodeGroupBadge.tsx'

type Owner = {
	id: string
	name: string
}

export type OwnershipNodeType = Node<{ owners: Owner[] }, 'ownership'>
type OwnershipNodeProps = NodeProps<OwnershipNodeType>

export const OwnershipNode = ({ data: { owners } }: OwnershipNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'ownership')
	const lastOwner = owners[owners.length - 1]
	const tooltipText = (
		<div className="flex flex-col gap-4">
			<span>{`Last owner: ${lastOwner.name}`}</span>
			{lastOwner.name !== lastOwner.id && <span>{`Last owner ID: ${lastOwner.id}`}</span>}
		</div>
	)

	const badgeTooltipContent = (
		<>
			<span>Owners:</span>
			{owners.map((owner) => (
				<span key={owner.id}>{owner.name || 'Unknown owner'}</span>
			))}
		</>
	)

	return (
		<BaseIdentityNode
			name={{ text: lastOwner.name, tooltipText }}
			labelProps={{ label: 'Owner' }}
			isHighlighted={isHighlighted}
		>
			{owners.length > 1 && (
				<NodeGroupBadge count={owners.length} nodeColorType="identity" tooltipContent={badgeTooltipContent} />
			)}
		</BaseIdentityNode>
	)
}
