import { getColorFromTailwindColorClassName } from '../../../../utils/colorUtils.ts'
import { isDev } from '../../../../utils/devUtils.ts'
import { IdentityGraphNodeType } from '../identityGraphTypes.ts'
import { BaseServerIssue, IssueStatus, PriorityType } from '../../../../schemas/issue.ts'

type ColorClassNames = {
	text: string
	bg: string
	badgeBg: string
	badgeText: string
	bgHover: string
	bgHighlighted: string
	bgDisabled?: string
	bgHoverDisabled?: string
}

/**
 * Mapping of kinds of nodes to their respective color. Using a constant for the class names instead of constructing
 * them dynamically to allow tailwind to pick up these class names and generate them.
 */
export const nodeColorClassNames = {
	credentials: {
		text: 'text-graphCredentials-text',
		bg: 'bg-graphCredentials-bg',
		badgeBg: 'text-graphCredentials-text',
		badgeText: 'bg-graphCredentials-bg',
		bgHover: 'hover:bg-graphCredentials-bgHover',
		bgHighlighted: 'bg-graphCredentials-bgHover',
		bgDisabled: 'bg-graphCredentials-bgDisabled',
		bgHoverDisabled: 'hover:bg-graphCredentials-bgHoverDisabled',
	},
	dependencies: {
		text: 'text-graphDependencies-text',
		bg: 'bg-graphDependencies-bg',
		badgeBg: 'text-graphDependencies-text',
		badgeText: 'bg-graphDependencies-bg',
		bgHover: 'hover:bg-graphDependencies-bgHover',
		bgHighlighted: 'bg-graphDependencies-bgHover',
	},
	permission: {
		text: 'text-graphPermission-text',
		bg: 'bg-graphPermission-bg',
		badgeBg: 'text-graphPermission-text',
		badgeText: 'bg-graphPermission-bg',
		bgHover: 'hover:bg-graphPermission-bgHover',
		bgHighlighted: 'bg-graphPermission-bgHover',
	},
	environment: {
		text: 'text-graphEnvironment-text',
		bg: 'bg-graphEnvironment-bg',
		badgeBg: 'text-graphEnvironment-text',
		badgeText: 'bg-graphEnvironment-bg',
		bgHover: 'hover:bg-graphEnvironment-bgHover',
		bgHighlighted: 'bg-graphEnvironment-bgHover',
	},
	identity: {
		text: 'text-graphIdentity-text',
		bg: 'bg-graphIdentity-bg',
		badgeBg: 'text-graphIdentity-text',
		badgeText: 'bg-graphIdentity-bg',
		bgHover: 'hover:bg-graphIdentity-bgHover',
		bgHighlighted: 'bg-graphIdentity-bgHover',
	},
	issueCritical: {
		text: 'text-graphIssueCritical-text',
		bg: 'bg-graphIssueCritical-bg',
		badgeBg: 'bg-status-criticalLowContrast',
		badgeText: 'text-status-critical',
		bgHover: 'hover:bg-graphIssueCritical-bgHover',
		bgHighlighted: 'bg-graphIssueCritical-bgHover',
	},
	issueHigh: {
		text: 'text-graphIssueHigh-text',
		bg: 'bg-graphIssueHigh-bg',
		badgeBg: 'bg-status-highLowContrast',
		badgeText: 'text-status-high',
		bgHover: 'hover:bg-graphIssueHigh-bgHover',
		bgHighlighted: 'bg-graphIssueHigh-bgHover',
	},
	issueMedium: {
		text: 'text-graphIssueMedium-text',
		bg: 'bg-graphIssueMedium-bg',
		badgeBg: 'bg-status-mediumLowContrast',
		badgeText: 'text-status-medium',
		bgHover: 'hover:bg-graphIssueMedium-bgHover',
		bgHighlighted: 'bg-graphIssueMedium-bgHover',
	},
	issueLow: {
		text: 'text-graphIssueLow-text',
		bg: 'bg-graphIssueLow-bg',
		badgeBg: 'bg-status-infoLowContrast',
		badgeText: 'text-status-info',
		bgHover: 'hover:bg-graphIssueLow-bgHover',
		bgHighlighted: 'bg-graphIssueLow-bgHover',
	},
	issueResolved: {
		text: 'text-graphIssueResolved-text',
		bg: 'bg-graphIssueResolved-bg',
		badgeBg: 'bg-status-activeLowContrast',
		badgeText: 'text-status-active',
		bgHover: 'hover:bg-graphIssueResolved-bgHover',
		bgHighlighted: 'bg-graphIssueResolved-bgHover',
	},
	issueNeutral: {
		text: 'text-graphIssueNeutral-text',
		bg: 'bg-graphIssueNeutral-bg',
		badgeBg: 'bg-status-neutralLowContrast',
		badgeText: 'text-status-neutral',
		bgHover: 'hover:bg-graphIssueNeutral-bgHover',
		bgHighlighted: 'bg-graphIssueNeutral-bgHover',
	},
	association: {
		text: 'text-graphAssociation-text',
		bg: 'bg-graphAssociation-bg',
		badgeBg: 'text-graphAssociation-text',
		badgeText: 'bg-graphAssociation-bg',
		bgHover: 'hover:bg-graphAssociation-bgHover',
		bgHighlighted: 'bg-graphAssociation-bgHover',
	},
	action: {
		text: 'text-graphAction-text',
		bg: 'bg-graphAction-bg',
		badgeBg: 'text-graphAction-text',
		badgeText: 'bg-graphAction-bg',
		bgHover: 'hover:bg-graphAction-bgHover',
		bgHighlighted: 'bg-graphAction-bgHover',
	},
} satisfies Record<string, ColorClassNames>

export type NodeColorTypes = keyof typeof nodeColorClassNames

/** Turning the node colors class names into actual colors, keeping the same structure. */
const nodeColors = Object.entries(nodeColorClassNames).reduce(
	(colorsAcc, colorsValue) => {
		return {
			...colorsAcc,
			[colorsValue[0]]: Object.entries(colorsValue[1]).reduce((acc, value) => {
				return { ...acc, [value[0]]: getColorFromTailwindColorClassName(value[1]) }
			}, {} as ColorClassNames),
		}
	},
	{} as Record<NodeColorTypes, ColorClassNames>,
)

export function getIssueNodeColorType(issue: BaseServerIssue): NodeColorTypes {
	if (issue.status === IssueStatus.RESOLVED) {
		return 'issueResolved'
	}

	if ([IssueStatus.IGNORED, IssueStatus.FALSE_POSITIVE].includes(issue.status)) {
		return 'issueNeutral'
	}

	if (!issue.priority || issue.priority === PriorityType.NOT_CALCULATED) {
		return 'issueNeutral'
	}

	if (issue.priority === PriorityType.CRITICAL) {
		return 'issueCritical'
	}

	if (issue.priority === PriorityType.HIGH) {
		return 'issueHigh'
	}

	if (issue.priority === PriorityType.MEDIUM) {
		return 'issueMedium'
	}

	if (issue.priority === PriorityType.LOW) {
		return 'issueLow'
	}

	return 'issueNeutral'
}

export function getMinimapNodeColor(node: IdentityGraphNodeType): string {
	switch (node.type) {
		case 'accessKey':
			return node.data?.accessKey?.is_active ? nodeColors.credentials.bg : nodeColors.credentials.bgDisabled!
		case 'oauthToken':
		case 'pat':
		case 'entraIdCredential':
		case 'gcpAccessKey':
		case 'githubUserToken':
		case 'salesforceConsumerKey':
		case 'keyPair':
		case 'azureDevopsPat':
		case 'snowflakeLogin':
		case 'atlassianApiToken':
		case 'atlassianOAuthToken':
		case 'atlassianAdminApiKey':
			return nodeColors.credentials.bg
		case 'ec2Instances':
		case 'kubernetesResources':
		case 'azureVirtualMachines':
		case 'azureKeyVault':
		case 'gcpResource':
		case 'gwOAuthApp':
		case 'demoAdEndpoints':
		case 'demoAdServers':
			return nodeColors.dependencies.bg
		case 'awsPolicy':
		case 'awsPolicies':
		case 'azureManagementGroup':
		case 'azureManagementGroups':
		case 'enrichedAzureRole':
		case 'enrichedAzureRoles':
		case 'detailedEntraIdRole':
		case 'detailedEntraIdRoles':
		case 'databricksRole':
		case 'gcpRole':
		case 'gcpRoles':
		case 'gcpScope':
		case 'gcpScopes':
		case 'connectedGcpRole':
		case 'connectedGcpRoles':
		case 'awsIamRole':
		case 'awsIamRoles':
		case 'awsIamUser':
		case 'snowflakeRole':
		case 'snowflakeRoles':
		case 'githubAppPermission':
		case 'salesforceProfile':
		case 'salesforcePermissionSet':
		case 'salesforcePermissionSets':
		case 'salesforceConnectedAppMetadata':
		case 'jiraRole':
		case 'jiraRoles':
		case 'iacCode': // This is not accurate, and needed to have its own sometime
			return nodeColors.permission.bg
		case 'azureSubscription':
		case 'azureSubscriptions':
		case 'awsAccount':
		case 'gcpProject':
		case 'gcpProjects':
		case 'connectedGcpProject':
		case 'connectedGcpProjects':
		case 'githubRepository':
		case 'githubRepositories':
		case 'adGroup':
		case 'adGroups':
		case 'gcpWorkspaceEnv':
		case 'gcpWorkspaceEnvs':
		case 'jiraProject':
		case 'githubEnvironment':
			return nodeColors.environment.bg
		case 'identity':
		case 'oktaUser':
		case 'entraIDUser':
		case 'jumpcloudUser':
		case 'entraIDUsers':
		case 'entraIDServicePrincipal':
		case 'entraIDServicePrincipals':
		case 'ownership':
		case 'iacOwnership':
			return nodeColors.identity.bg
		case 'issue':
			return nodeColors[getIssueNodeColorType(node.data.issue)].bg
		case 'association':
			return nodeColors.association.bg
		case 'action':
		case 'githubActions':
		case 'federation':
			return nodeColors.action.text
	}

	if (isDev) {
		throw new Error(`Node type ${node.type} does not have a minimap color`)
	}

	return '#2e2e2e'
}
