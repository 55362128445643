import { DateSchema } from '../../common.ts'
import { z } from 'zod'

// Schema for individual audit trail logs
export const ServerSalesforceSetupAuditTrailLogSchema = z.object({
	timestamp: DateSchema,
	action: z.string(),
	section: z.string().nullable(),
	display: z.string().nullable(),
})

export type ServerSalesforceSetupAuditTrailLogInput = z.input<typeof ServerSalesforceSetupAuditTrailLogSchema>
export type ServerSalesforceSetupAuditTrailLog = z.infer<typeof ServerSalesforceSetupAuditTrailLogSchema>

// Schema for aggregated audit trail logs
export const ServerSalesforceSetupAuditTrailAggregatedLogSchema = z.object({
	day: DateSchema,
	created_by: z.string(),
	sections: z.array(z.string()),
	actions_count: z.number(),
})

export type ServerSalesforceSetupAuditTrailAggregatedLogInput = z.input<
	typeof ServerSalesforceSetupAuditTrailAggregatedLogSchema
>
export type ServerSalesforceSetupAuditTrailAggregatedLog = z.infer<
	typeof ServerSalesforceSetupAuditTrailAggregatedLogSchema
>
