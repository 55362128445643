import { DashboardOutlined } from '@ant-design/icons'
import { AccountPriorityEnum } from '../../schemas/environments/accountPriorityEnum.ts'
import { getAccountPriorityColor } from '../../utils/styleUtils.ts'

type LensAccountPriorityIconProps = {
	priority: AccountPriorityEnum
	className?: string
}

export const LensAccountPriorityIcon = ({ priority, className }: LensAccountPriorityIconProps) => (
	<DashboardOutlined style={{ color: getAccountPriorityColor(priority) }} className={className} />
)
