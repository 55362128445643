import { ColDef, CellMouseOverEvent } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { ServerGoogleWorkspaceUserDemoOauthAppsXc } from '../../../../../schemas/identities/googleWorkspaceUserSchema'
import { Tooltip } from 'antd'

type GoogleWorkspaceOAuthAppsTableProps = {
	data: ServerGoogleWorkspaceUserDemoOauthAppsXc[]
	lastSelectedNode?: string
	onCellMouseOver?: (event: CellMouseOverEvent<ServerGoogleWorkspaceUserDemoOauthAppsXc>) => void
	onCellMouseOut?: () => void
}

export const GoogleWorkspaceOAuthAppsTable = ({
	data,
	lastSelectedNode: lastSelectedApp,
	onCellMouseOver,
	onCellMouseOut,
}: GoogleWorkspaceOAuthAppsTableProps) => {
	const gridRef = useRef<AgGridReact<ServerGoogleWorkspaceUserDemoOauthAppsXc>>(null)

	const highlightRow = useCallback((appName?: string | null) => {
		if (!appName || !gridRef.current?.api) {
			return
		}

		const rowNode = gridRef.current.api.getRowNode(appName)
		if (!rowNode) {
			return
		}

		gridRef.current.api.ensureNodeVisible(rowNode, 'middle')
		gridRef.current.api.flashCells({ rowNodes: [rowNode] })
	}, [])

	const getRowId = useCallback((params: { data: ServerGoogleWorkspaceUserDemoOauthAppsXc }) => {
		return params.data.name
	}, [])

	useEffect(() => {
		highlightRow(lastSelectedApp)
	}, [lastSelectedApp, highlightRow])

	const columnDefs = useMemo<ColDef<ServerGoogleWorkspaceUserDemoOauthAppsXc>[]>(
		() => [
			{
				field: 'name',
				headerName: 'App Name',
				flex: 2,
			},
			{
				field: 'scopes',
				headerName: 'Scopes',
				flex: 3,
				filter: 'agTextColumnFilter',
				valueFormatter: (params: { value?: string[] }) => params.value?.join(', ') || '',
				cellRenderer: (params: { value?: string[] }) => (
					<Tooltip placement="topLeft" title={params.value?.join('\n') || ''} classNames={{ body: 'w-fit' }}>
						<div>{params.value?.join(', ') || ''}</div>
					</Tooltip>
				),
			},
			{
				field: 'is_internal',
				headerName: 'App Type',
				flex: 1,
				valueGetter: (params) => (params.data?.is_internal ? 'Internal' : 'External'),
			},
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	const onGridReady = useCallback(() => {
		highlightRow(lastSelectedApp)
	}, [highlightRow, lastSelectedApp])

	return (
		<div className="w-full h-96">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				getRowId={getRowId}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
