import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { ServerGcpServiceAccountDemoDomainWideDelegationScope } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema'
import { ServerTag } from '../../../../schemas/tags'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type GcpScopesNodeType = Node<
	{
		scopes: ServerGcpServiceAccountDemoDomainWideDelegationScope[]
	},
	'gcpScopes'
>

export const GcpScopesNode = ({ data: { scopes } }: NodeProps<GcpScopesNodeType>) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'gcpScope')

	const badgeTooltipContent = scopes.map((scope) => (
		<span key={scope.literal_name}>{scope.friendly_name || scope.literal_name}</span>
	))

	const uniqueRiskTags = scopes.reduce((tags, scope) => {
		if (tags.find((t) => t.name === scope.tag.name)) {
			return tags
		}
		tags.push(scope.tag)
		return tags
	}, [] as ServerTag[])

	return (
		<BasePermissionNode
			label="OAuth Scopes"
			isHighlighted={isHighlighted}
			risks={uniqueRiskTags}
			groupProps={{ count: scopes.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
