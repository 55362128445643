import { IdentitySource, ServerIdentity } from '../../../schemas/identity'
import { AwsIamGroupIdentity } from './aws/AwsIamGroup.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import React from 'react'

export const BaseIdentity: React.FunctionComponent<{
	identity?: ServerIdentity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	if (identity?.source === IdentitySource.AWS_IAM_GROUP) {
		return <AwsIamGroupIdentity identity={identity} envType={envType} />
	}
	return null
}
