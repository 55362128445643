import { IssueStatusTag } from '../../../components/common/IssueStatusTag.tsx'
import { useMemo, useState } from 'react'
import { UpdateIssueStatusModal } from './UpdateIssueStatusModal.tsx'
import { useIssue } from '../../../api/issues.ts'
import { Tag } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ServerIssue } from '../../../schemas/issue.ts'

type IssueTitleStatusProps = {
	issueId: string
	currentIssue?: ServerIssue
}

export const IssueTitleStatus = ({ issueId, currentIssue }: IssueTitleStatusProps) => {
	const {
		data: issue,
		isLoading: isIssueLoading,
		isError: isIssueError,
	} = useIssue(issueId, { enabled: Boolean(!currentIssue) })
	const [isModalOpen, setIsModalOpen] = useState(false)

	const displayIssue = useMemo(() => currentIssue || issue, [currentIssue, issue])

	if (isIssueError) {
		return null
	}

	if (!currentIssue && isIssueLoading) {
		return (
			<Tag className="text-sm px-6">
				<LoadingOutlined />
			</Tag>
		)
	}

	if (!displayIssue) {
		return null
	}

	return (
		<div className="flex items-center">
			<IssueStatusTag issue={displayIssue} onEdit={() => setIsModalOpen(true)} />
			<UpdateIssueStatusModal
				isOpen={isModalOpen}
				closeModal={() => setIsModalOpen(false)}
				issue={displayIssue}
			/>
		</div>
	)
}
