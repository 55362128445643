import React, { useState, useTransition, useCallback, memo } from 'react'
import ReactJson from 'searchable-react-json-view'
import { Button, Modal, Input, Spin } from 'antd'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'
import { themeColors } from '../../utils/colorUtils.ts'
import { debounce } from 'lodash'

const MemoizedJsonView = memo<{ data: object; searchTerm?: string }>(({ data, searchTerm }) => {
	return (
		<ReactJson
			src={data}
			style={{ backgroundColor: themeColors.surface.secondary }}
			enableClipboard={true}
			displayDataTypes={false}
			displayObjectSize={false}
			iconStyle="square"
			collapseStringsAfterLength={100}
			highlightSearch={searchTerm}
		/>
	)
})

export const JsonViewer: React.FunctionComponent<{
	data: object
	title: string
}> = ({ data, title }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
	const [isPending, startTransition] = useTransition()

	const debouncedSetSearch = useCallback(
		debounce((value: string) => {
			startTransition(() => {
				setDebouncedSearchTerm(value)
			})
		}, 500),
		[startTransition, setDebouncedSearchTerm],
	)

	const handleSearch = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value
			setSearchTerm(value)
			debouncedSetSearch(value)
		},
		[setSearchTerm, debouncedSetSearch],
	)

	const showModal = () => {
		trackEvent(LogRocketTrackEvent.OpenJsonClicked, {})
		setIsModalOpen(true)
	}

	const handleOk = () => {
		trackEvent(LogRocketTrackEvent.OpenJsonClosed, {})
		setIsModalOpen(false)
	}

	const handleCancel = () => {
		trackEvent(LogRocketTrackEvent.OpenJsonClosed, {})
		setIsModalOpen(false)
	}
	return (
		<>
			<Button type="link" size="small" onClick={showModal}>
				Open JSON
			</Button>
			<Modal
				width={1024}
				title={title}
				open={isModalOpen}
				onCancel={handleCancel}
				onOk={handleOk}
				footer={[
					<Button key="done-button" type="primary" onClick={handleCancel}>
						Done
					</Button>,
				]}
				classNames={{ body: 'flex flex-col gap-4' }}
			>
				<Input.Search
					placeholder="Search in JSON..."
					value={searchTerm}
					onChange={handleSearch}
					allowClear
					loading={isPending}
				/>
				<div className="relative h-[500px]">
					<div className="overflow-y-scroll h-full text-xs border-border-primary border rounded ">
						<MemoizedJsonView data={data} searchTerm={debouncedSearchTerm} />
					</div>
					{isPending && (
						<div className="absolute inset-0 flex items-center justify-center bg-black/10 pointer-events-none rounded">
							<Spin size="large" />
						</div>
					)}
				</div>
			</Modal>
		</>
	)
}
