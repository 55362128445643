import { environments } from '../data/environments.ts'
import { Account } from '../../schemas/environments/accounts.ts'
import { AppServer } from '../schemas.ts'

export function mirageGetAccounts(server: AppServer) {
	server.get('/accounts', (): Account[] => {
		return environments
	})
}

export function mirageUpdateAccounts(server: AppServer) {
	server.put('/accounts', () => null)
}
