import React from 'react'
import { Handle, Position } from '@xyflow/react'
import { Tooltip } from 'antd'
import { NodeLabel, NodeLabelProps } from '../NodeLabel.tsx'

export type BaseNodeProps = {
	name?: { text: string; tooltipText?: React.ReactNode } | null
	labelProps: NodeLabelProps | Array<NodeLabelProps>
	children: React.ReactNode
}

export const BaseNode = ({ name, labelProps, children }: BaseNodeProps) => (
	<div className="flex flex-col gap-2 overflow-visible items-center py-4 w-[150px] h-[150px]">
		<div className="flex w-[50px] h-[50px] relative">
			<Handle
				type="source"
				position={Position.Right}
				style={{ borderStyle: 'none', backgroundColor: 'transparent', right: 4, pointerEvents: 'none' }}
				id="right"
			/>
			<Handle
				type="source"
				position={Position.Bottom}
				style={{ borderStyle: 'none', backgroundColor: 'transparent', bottom: 4, pointerEvents: 'none' }}
				id="bottom"
			/>
			<Handle
				type="target"
				position={Position.Left}
				style={{ borderStyle: 'none', backgroundColor: 'transparent', left: 4, pointerEvents: 'none' }}
				id="left"
			/>
			<Handle
				type="target"
				position={Position.Top}
				style={{ borderStyle: 'none', backgroundColor: 'transparent', top: 4, pointerEvents: 'none' }}
				id="top"
			/>
			{children}
		</div>
		<div className="flex flex-col items-center gap-1 text-center max-w-[180px] w-max bg-surface-primary border border-border-primary rounded p-1 opacity-85">
			{name ? (
				<Tooltip classNames={{ body: 'w-fit' }} placement="bottom" title={name.tooltipText ?? name.text}>
					<span className="w-full px-1 text-ellipsis overflow-hidden">{name.text}</span>
				</Tooltip>
			) : null}
			{Array.isArray(labelProps) ? (
				labelProps.map((singleLabelProps) => <NodeLabel key={singleLabelProps.label} {...singleLabelProps} />)
			) : (
				<NodeLabel {...labelProps} />
			)}
		</div>
	</div>
)
