import React, { useEffect, useMemo, useState } from 'react'
import {
	useCollaborationIntegrations,
	useCollaborationTestConnection,
	useCreateCollaborationIntegration,
} from '../../../../api/integrations.ts'
import { IntegrationsState, IntegrationStatus } from '../../common/integrationTypes.ts'
import { IntegrationsError, IntegrationsSkeleton } from '../../common/components/IntegrationStates.tsx'
import { IntegrationCard } from '../../common/components/IntegrationCard.tsx'
import { collaborationIntegrations, collaborationIntegrationsByKey } from '../collaborationRegistry.ts'
import { CollaborationsIntegrationModal } from './CollaborationIntegrationModal.tsx'
import { IntegrationType } from '../../../../jiraIntegration/schemas/common.ts'
import { message, notification } from 'antd'
import {
	CollaborationIntegrationFormData,
	ConnectableCollaborationIntegrationConfig,
	isCollaborationIntegrationConnectable,
} from '../collaborationIntegrationsTypes.ts'

interface CollaborationIntegrationsProps {
	openSpecificModal?: IntegrationType
	isModalVisible: boolean
	setIsModalVisible: (visible: boolean) => void
}

export const CollaborationIntegrations: React.FC<CollaborationIntegrationsProps> = ({
	openSpecificModal,
	isModalVisible,
	setIsModalVisible,
}) => {
	const { data: integrations, isPending, error } = useCollaborationIntegrations()
	const { mutate: createIntegration, isPending: isCreatingIntegration } = useCreateCollaborationIntegration()
	const testConnection = useCollaborationTestConnection()
	const [selectedIntegration, setSelectedIntegration] = useState<ConnectableCollaborationIntegrationConfig | null>(
		null,
	)
	const [testConnectionStatus, setTestConnectionStatus] = useState<'success' | 'error' | null>(null)

	useEffect(() => {
		if (openSpecificModal) {
			const integrationToOpen = collaborationIntegrationsByKey[openSpecificModal]
			if (integrationToOpen) {
				setSelectedIntegration(integrationToOpen)
			}
		}
	}, [openSpecificModal])

	const integrationsState: IntegrationsState = useMemo(() => {
		if (!integrations) return {}

		return collaborationIntegrations.reduce((integrationsState, integration) => {
			const isActive = integrations.some(
				(i) => i.integration_type.toLowerCase() === integration.key.toLowerCase(),
			)
			integrationsState[integration.key] = {
				status: isActive ? IntegrationStatus.ACTIVE : IntegrationStatus.INACTIVE,
				environmentCount: isActive ? 1 : 0,
			}
			return integrationsState
		}, {} as IntegrationsState)
	}, [integrations])

	const handleConnect = (integration: ConnectableCollaborationIntegrationConfig) => {
		if (integration) {
			setSelectedIntegration(integration)
			setIsModalVisible(true)
		}
	}

	const handleCloseModal = () => {
		setIsModalVisible(false)
		setSelectedIntegration(null)
		setTestConnectionStatus(null)
	}

	const handleSubmit = (values: CollaborationIntegrationFormData) => {
		if (!selectedIntegration || !selectedIntegration.createIntegrationPayload) return

		const integrationData = selectedIntegration.createIntegrationPayload(values)
		if (!integrationData) {
			message.error(`Failed to create ${selectedIntegration.name} integration.`)
			return
		}
		createIntegration(integrationData, {
			onSuccess: () => {
				message.success(`${selectedIntegration.name} integration created successfully`)
				handleCloseModal()
			},
			onError: () => {
				message.error(`Failed to create ${selectedIntegration.name} integration.`)
			},
		})
	}

	const handleTestConnection = async (values?: CollaborationIntegrationFormData) => {
		if (!selectedIntegration || !selectedIntegration.createTestConnectionPayload) return
		const testConnectionPayload = selectedIntegration.createTestConnectionPayload(values ?? undefined)
		if (!testConnectionPayload) {
			message.error(`Failed to test connection for ${selectedIntegration.name} integration.`)
			return
		}
		try {
			const response = await testConnection.mutateAsync(testConnectionPayload)

			setTestConnectionStatus(response.success ? 'success' : 'error')
			if (!response.success) {
				notification.error({
					message: 'Test Connection Failed',
					description: response.message,
				})
			}
		} catch (error) {
			setTestConnectionStatus('error')
			notification.error({
				message: 'Test Connection Failed',
				description: error instanceof Error ? error.message : 'An unexpected error occurred',
			})
		}
	}

	if (isPending) {
		return <IntegrationsSkeleton />
	}

	if (error) {
		return <IntegrationsError error={error instanceof Error ? error : new Error('An unknown error occurred')} />
	}

	return (
		<>
			<div className="flex flex-wrap gap-5">
				{collaborationIntegrations.map((integration) => (
					<IntegrationCard
						key={integration.key}
						logo={integration.logo}
						name={integration.name}
						description={integration.description}
						status={integrationsState[integration.key]?.status || IntegrationStatus.INACTIVE}
						onConnect={
							isCollaborationIntegrationConnectable(integration)
								? () => handleConnect(integration)
								: undefined
						}
					/>
				))}
			</div>
			{selectedIntegration && (
				<CollaborationsIntegrationModal
					key={selectedIntegration.key}
					integration={selectedIntegration}
					integrationType={selectedIntegration.key}
					isModalVisible={isModalVisible}
					onClose={handleCloseModal}
					onTestConnection={handleTestConnection}
					isConnecting={false}
					isTestingConnection={testConnection.isPending}
					testConnectionStatus={testConnectionStatus}
					onSubmit={handleSubmit}
					isCreatingIntegration={isCreatingIntegration}
				/>
			)}
		</>
	)
}
