import { AxiosRequestHeaders, AxiosResponse, Method } from 'axios'
import { settings } from '../../env-settings.ts'
import { api } from '../../api/api.ts'
import {
	JiraFetchFieldsResponse,
	JiraFetchFieldsResponseSchema,
	JiraFetchIssueTypeResponse,
	JiraFetchIssueTypesResponseSchema,
	JiraFetchLabelsResponse,
	JiraFetchLabelsResponseSchema,
	JiraFetchProjectsResponse,
	JiraFetchProjectsResponseSchema,
} from '../schemas/jiraApi.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
	JiraCreateTicket,
	JiraIssuePicker,
	JiraIssuePickerIssue,
	JiraIssuePickerSchema,
	JiraIssueType,
	JiraIssueTypeSchema,
	JiraProject,
	JiraTicket,
	JiraUser,
	JiraUserSchema,
} from '../schemas/jiraSchemas.ts'
import { ServerIntegrationTicketInTokenDb } from '../schemas/common.ts'
import { integrationsApi } from '../../api/integrations.ts'
import { JiraIntegrationResponse } from '../../schemas/integrations/integrations.ts'

const jiraApi = {
	async proxy<T>(
		endpoint: string,
		method: Method,
		integrationId: string,
		queryParams?: unknown,
		data?: unknown,
		headers?: AxiosRequestHeaders,
	): Promise<AxiosResponse<T, unknown>> {
		try {
			const url = settings.viteBackendUrl + '/api/integrations/proxy/jira'
			const params = {
				method: method,
				data: data,
				headers: headers,
				integrationId: integrationId,
				queryParams: queryParams,
				endpoint: endpoint,
			}
			return api.post<T>(url, params)
		} catch (error) {
			console.error(error)
			throw error
		}
	},
}

const fetchProjects = async (integrationId: string): Promise<JiraProject[]> => {
	let allProjects: JiraProject[] = []
	let startAt = 0
	let isLast = false

	while (!isLast) {
		const response = await jiraApi.proxy<JiraFetchProjectsResponse>(
			'/rest/api/3/project/search',
			'GET',
			integrationId,
			{
				action: 'create',
				startAt: startAt,
			},
		)
		const fetchedProjects = JiraFetchProjectsResponseSchema.parse(response.data).values
		allProjects = allProjects.concat(fetchedProjects)
		startAt = response.data.startAt + response.data.maxResults
		isLast = response.data.isLast ?? true
	}
	return allProjects
}

export const fetchIssueStatuses = async (
	integrationId: string,
	jiraTickets: ServerIntegrationTicketInTokenDb[],
): Promise<Record<string, string>> => {
	const fetchPromises = jiraTickets.map((jiraTicket) =>
		jiraApi.proxy<{ fields: { status: { name: string } } }>(
			`/rest/api/2/issue/${jiraTicket.data.key}`,
			'GET',
			integrationId,
			{
				fields: 'status',
			},
		),
	)

	const fetchedStatuses = await Promise.all(fetchPromises)
	const ret: Record<string, string> = {}
	jiraTickets.forEach((jiraTicket, index) => {
		ret[jiraTicket.data.key] = fetchedStatuses[index].data.fields.status.name
	})

	return ret
}

const fetchLabels = async (integrationId: string) => {
	let allLabels: string[] = []
	let startAt = 0
	let isLast = false

	while (!isLast) {
		const response = await jiraApi.proxy<JiraFetchLabelsResponse>('/rest/api/3/label', 'GET', integrationId, {
			startAt: startAt,
		})
		const fetchedLabels = JiraFetchLabelsResponseSchema.parse(response.data).values
		allLabels = allLabels.concat(fetchedLabels)
		startAt = response.data.startAt + response.data.maxResults
		isLast = response.data.isLast ?? true
	}
	return allLabels
}

const fetchUsers = async (projectId: string, integrationId: string) => {
	const { data: jiraUsers } = await jiraApi.proxy<JiraUser[]>(
		'/rest/api/3/user/assignable/search',
		'GET',
		integrationId,
		{
			project: projectId,
		},
	)
	return jiraUsers.map((user) => JiraUserSchema.parse(user))
}

const fetchIssueTypes = async (integrationId: string, projectId: string) => {
	let allIssueTypes: JiraIssueType[] = []
	let startAt = 0
	let isLast = false

	while (!isLast) {
		const response = await jiraApi.proxy<JiraFetchIssueTypeResponse>(
			`/rest/api/3/issue/createmeta/${projectId}/issuetypes`,
			'GET',
			integrationId,
			{
				startAt: startAt,
			},
		)
		const fetchedIssueTypes = JiraFetchIssueTypesResponseSchema.parse(response.data).issueTypes
		allIssueTypes = allIssueTypes.concat(fetchedIssueTypes.map((issueType) => JiraIssueTypeSchema.parse(issueType)))
		startAt = response.data.startAt + response.data.maxResults
		isLast = response.data.isLast ?? true
	}
	return allIssueTypes
}

const fetchFields = async (integrationId: string, issueTypeId: string, projectId: string) => {
	const response = await jiraApi.proxy<JiraFetchFieldsResponse>(
		`/rest/api/3/issue/createmeta/${projectId}/issuetypes/${issueTypeId}`,
		'GET',
		integrationId,
	)
	return JiraFetchFieldsResponseSchema.parse(response.data).fields
}

const createTicketInJira = async (
	integrationId: string,
	issuePayload: Record<string, unknown>,
): Promise<JiraTicket> => {
	const response = await jiraApi.proxy<JiraTicket>('/rest/api/3/issue', 'POST', integrationId, issuePayload)
	return response.data
}

export const fetchJiraIssuesPerProject = async (
	integrationId: string,
	projectId: string,
	projectKey: string,
	query: string,
): Promise<JiraIssuePickerIssue[]> => {
	const queryParams = {
		currentProjectId: projectId,
		currentJQL: `project=${projectKey}`,
		query: query,
	}
	const response = await jiraApi.proxy<JiraIssuePicker>('/rest/api/3/issue/picker', 'GET', integrationId, queryParams)
	const jiraIssuesPerProject = JiraIssuePickerSchema.parse(response.data)
	const currentIssues = jiraIssuesPerProject.sections.find((issueSection) => issueSection.label === 'Current Search')
	if (currentIssues && currentIssues.issues.length > 0) {
		return currentIssues.issues
	}
	const historyIssues = jiraIssuesPerProject.sections.find((issueSection) => issueSection.label === 'History Search')
	if (historyIssues && historyIssues.issues.length > 0) {
		return historyIssues.issues
	}
	return []
}

export function useJiraProxyFetchProjects(integration: JiraIntegrationResponse) {
	return useQuery({
		queryKey: ['jira', 'projects', integration.id],
		queryFn: () => fetchProjects(integration.id),
	})
}

export function useJiraProxyFetchLabels(integration: JiraIntegrationResponse) {
	return useQuery({
		queryKey: ['jira', 'labels', integration.id],
		queryFn: () => fetchLabels(integration.id),
	})
}

export function useJiraProxyFetchUsers(integration: JiraIntegrationResponse, projectId?: string) {
	return useQuery({
		queryKey: ['jira', 'users', projectId, integration.id],
		queryFn: () => fetchUsers(projectId!, integration.id),
		enabled: !!projectId,
	})
}

export function useJiraProxyFetchIssueTypes(integration: JiraIntegrationResponse, projectId?: string) {
	return useQuery({
		queryKey: ['jira', 'issueTypes', projectId, integration.id],
		queryFn: () => fetchIssueTypes(integration.id, projectId!),
		enabled: !!projectId,
	})
}

export function useJiraProxyFetchFields(
	integration: JiraIntegrationResponse,
	projectId?: string,
	issueTypeId?: string,
) {
	return useQuery({
		queryKey: ['jira', 'fieldsMetadata', projectId, issueTypeId, integration.id],
		queryFn: () => fetchFields(integration.id, issueTypeId!, projectId!),
		enabled: !!projectId && !!issueTypeId,
	})
}

export function useJiraProxyCreateTicket(integrationId: string, issueId: string) {
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: ['jira', 'createTicket'],
		mutationFn: async ({ issuePayload }: { issuePayload: JiraCreateTicket }) => {
			const response = await createTicketInJira(integrationId, issuePayload)
			return integrationsApi.createIntegrationTicketInTokenDb(issueId, integrationId, response)
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: ['integrations', 'jira', 'tickets', integrationId, issueId],
			})
		},
	})
}

export function useCreateIntegrationTicketInTokenDb(integration: JiraIntegrationResponse, issueId: string) {
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: ['jira', 'linkTicket', integration.id, issueId],
		mutationFn: async ({ id, key }: { id: string; key: string }) => {
			const ticketMetadata: JiraTicket = {
				id,
				key,
				self: integration.base_url ?? '',
			}
			return integrationsApi.createIntegrationTicketInTokenDb(issueId, integration.id, ticketMetadata)
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: ['integrations', 'jira', 'tickets', integration.id, issueId],
			})
		},
	})
}

export function useGetTickets(integrationId: string, issueId: string) {
	return useQuery({
		queryKey: ['integrations', 'jira', 'tickets', integrationId, issueId],
		queryFn: () => integrationsApi.getTickets(integrationId, issueId),
	})
}

export function useFetchJiraIssuesPerProject(
	integration: JiraIntegrationResponse,
	query: string,
	project?: JiraProject,
) {
	return useQuery({
		queryKey: ['jira', 'issues', integration.id, project?.id, project?.key, query],
		queryFn: () => fetchJiraIssuesPerProject(integration.id, project!.id, project!.key, query),
		enabled: !!project && query.length !== 0,
	})
}

export function useFetchIssueStatuses(
	integration: JiraIntegrationResponse,
	jiraTickets: ServerIntegrationTicketInTokenDb[],
) {
	return useQuery({
		queryKey: ['jira', 'issueStatuses', integration.id, jiraTickets],
		queryFn: () => fetchIssueStatuses(integration.id, jiraTickets),
		enabled: !!jiraTickets?.length,
	})
}
