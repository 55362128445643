import { ServerAwsUsageLogInput } from '../../schemas/identities/awsIamUserSchema.ts'

export const generateOktaHighRiskSignInUsageLogs = (
	userName: string,
	arn: string = '',
): Array<ServerAwsUsageLogInput> => {
	const baseLog = {
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	}

	const logs = [
		{
			id: '7f9e8d7c-6b5a-4c3d-2e1f-0a9b8c7d6e5f',
			created_at: '2024-08-04T00:00:00Z',
			updated_at: '2024-08-04T00:00:00Z',
			ip: '82.132.232.45',
			association: `${userName}'s MacOS`,
			events: ['Okta Dashboard', 'Gong'],
			events_count: 8,
			day: '2024-08-04T00:00:00Z',
		},
		{
			id: '1a2b3c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p',
			created_at: '2024-08-09T00:00:00Z',
			updated_at: '2024-08-09T00:00:00Z',
			ip: '82.132.232.45',
			association: `${userName}'s MacOS`,
			events: ['Okta Dashboard', 'AWS (Prod)'],
			events_count: 5,
			day: '2024-08-09T00:00:00Z',
		},
		{
			id: 'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6',
			created_at: '2024-08-10T00:00:00Z',
			updated_at: '2024-08-10T00:00:00Z',
			ip: '82.132.232.45',
			association: `${userName}'s MacOS`,
			events: ['Tableau', 'Datadog'],
			events_count: 3,
			day: '2024-08-10T00:00:00Z',
		},
		{
			id: 'q1w2e3r4-t5y6-u7i8-o9p0-a1s2d3f4g5h6',
			created_at: '2024-08-23T00:00:00Z',
			updated_at: '2024-08-23T00:00:00Z',
			ip: '82.132.232.45',
			association: `${userName}'s MacOS`,
			events: ['Okta Dashboard', 'Datadog'],
			events_count: 4,
			day: '2024-08-23T00:00:00Z',
		},
		{
			id: 'z1x2c3v4-b5n6-m7k8-l9j0-h1g2f3d4s5a6',
			created_at: '2024-08-24T00:00:00Z',
			updated_at: '2024-08-24T00:00:00Z',
			ip: '109.76.160.39',
			association: '(unknown MacOS)',
			events: ['Okta Dashboard', 'Salesforce.com', 'AWS (Prod)'],
			events_count: 6,
			day: '2024-08-24T00:00:00Z',
		},
	]

	return logs.map((log) => ({
		...baseLog,
		...log,
		arn: arn,
	}))
}
