import { Node, NodeProps } from '@xyflow/react'
import { ServerGoogleWorkspaceUserDemoOauthAppsXc } from '../../../../schemas/identities/googleWorkspaceUserSchema.ts'
import { BaseDependencyNode } from '../common/baseNodes/BaseDependencyNode.tsx'
import { AppstoreOutlined } from '@ant-design/icons'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'

export type GwOAuthAppNodeType = Node<
	{
		oauthApp: ServerGoogleWorkspaceUserDemoOauthAppsXc
	},
	'gwOAuthApp'
>

type GwOAuthAppNodeProps = NodeProps<GwOAuthAppNodeType>

export const GwOAuthAppNode = ({ data: { oauthApp } }: GwOAuthAppNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'gwOAuthApp' && highlightedNode.id === oauthApp.name,
	)

	return (
		<BaseDependencyNode
			nodeLabel={'OAuth App'}
			name={{ text: oauthApp.name, tooltipText: oauthApp.scopes.join(',\n') }}
			Icon={AppstoreOutlined}
			isHighlighted={isHighlighted}
		/>
	)
}
