import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Layout, Menu, MenuProps, ConfigProvider, Skeleton } from 'antd'
import { DashboardOutlined, TeamOutlined } from '@ant-design/icons'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { IdentitiesPageLens } from '../../../schemas/identity.ts'
import { isDemo } from '../../../utils/demoUtils.ts'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { useIdentitiesContext } from '../context/IdentitiesProvider.tsx'
import CloudIcon from '../../../assets/cloud_icon_20.svg?react'
import DatastoresIcon from '../../../assets/hard_drives.svg?react'
import WorkloadsIcon from '../../../assets/workloads.svg?react'
import CICDIcon from '../../../assets/ci_cd_16.svg?react'
import CorporateSaasIcon from '../../../assets/corporate_saas_16.svg?react'
import { useIdentityLensStats } from '../../../api/identities.ts'
import { pageSidebarTheme } from '../../../styles/pageSidebarTheme.ts'
import { CollapsibleSiderMenuHeader } from '../../../components/common/CollapsibleSiderMenuHeader.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { DEFAULT_IDENTITIES_LENS, IDENTITIES_LENS_MAPPING, IdentitiesLensConfig } from '../identitiesLensMapping.tsx'

type MenuItem = Required<MenuProps>['items'][number]

interface MenuItemLabelProps {
	children: React.ReactNode
	count?: number
	isFolder?: boolean
}

interface MenuItemConfig extends IdentitiesLensConfig {
	key: IdentitiesPageLens
}

function getLensMenuItemConfig(lens: IdentitiesPageLens): MenuItemConfig {
	return {
		...IDENTITIES_LENS_MAPPING[lens],
		key: lens,
	}
}

interface MenuCategory {
	key: string
	label: string
	icon: React.ComponentType<{ className?: string }>
	items: MenuItemConfig[]
}

const MENU_STRUCTURE: MenuCategory[] = [
	{
		key: 'cloud-providers',
		label: 'Cloud Providers',
		icon: CloudIcon,
		items: [IdentitiesPageLens.AWS, IdentitiesPageLens.GCP, IdentitiesPageLens.AZURE].map(getLensMenuItemConfig),
	},
	{
		key: 'identity-providers',
		label: 'Identity Providers',
		icon: TeamOutlined,
		items: [
			IdentitiesPageLens.OKTA,
			IdentitiesPageLens.JUMPCLOUD,
			IdentitiesPageLens.ENTRA_ID,
			IdentitiesPageLens.GOOGLE_WORKSPACE,
		].map(getLensMenuItemConfig),
	},
	{
		key: 'databases',
		label: 'Databases',
		icon: DatastoresIcon,
		items: [IdentitiesPageLens.POSTGRES, IdentitiesPageLens.SNOWFLAKE, IdentitiesPageLens.DEMO_DATABRICKS].map(
			getLensMenuItemConfig,
		),
	},
	{
		key: 'workloads',
		label: 'Workloads',
		icon: WorkloadsIcon,
		items: [getLensMenuItemConfig(IdentitiesPageLens.KUBERNETES)],
	},
	{
		key: 'ci/cd',
		label: 'CI/CD',
		icon: CICDIcon,
		items: [IdentitiesPageLens.AZURE_DEVOPS, IdentitiesPageLens.GITHUB].map(getLensMenuItemConfig),
	},
	{
		key: 'saas',
		label: 'Corporate SaaS',
		icon: CorporateSaasIcon,
		items: [IdentitiesPageLens.SALESFORCE, IdentitiesPageLens.DEMO_ATLASSIAN].map(getLensMenuItemConfig),
	},
	{
		key: 'accountPriority',
		label: 'Account Priority',
		icon: DashboardOutlined,
		items: [
			IdentitiesPageLens.CRITICAL_PRIORITY,
			IdentitiesPageLens.HIGH_PRIORITY,
			IdentitiesPageLens.MEDIUM_PRIORITY,
			IdentitiesPageLens.LOW_PRIORITY,
			IdentitiesPageLens.UNKNOWN_PRIORITY,
		].map(getLensMenuItemConfig),
	},
]

const STANDALONE_ITEMS: MenuItemConfig[] = [
	IdentitiesPageLens.OFF_BOARDED_EMPLOYEES,
	IdentitiesPageLens.MULTIPLE_ACCESS_KEYS,
	IdentitiesPageLens.UNFEDERATED_IDENTITIES,
	IdentitiesPageLens.RECENTLY_CREATED,
	IdentitiesPageLens.MOST_WANTED,
	IdentitiesPageLens.DEMO_OVER_PRIVILEGED_IDENTITY,
	IdentitiesPageLens.DEMO_IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR,
].map(getLensMenuItemConfig)

const shouldShowMenuItem = (item: MenuItemConfig, count: number | undefined, isDemo: boolean): boolean => {
	// Don't show demo items in non-demo mode
	if (item.isDemoOnly && !isDemo) {
		return false
	}

	// Don't show items with count of 0 or undefined
	return count !== undefined && count > 0
}

const MenuItemLabel = ({ children, count, isFolder = false }: MenuItemLabelProps) => (
	<div className="flex w-full items-center">
		<span className="truncate">{children}</span>
		{!isFolder && <span className="ml-auto pl-1 text-sm shrink-0">{`(${formatNumber(count || 0)})`}</span>}
	</div>
)

const MenuItemIcon = ({ Icon, ...rest }: { Icon: React.ComponentType<{ className?: string }> }) => (
	<span {...rest}>
		<Icon className="w-5 h-5 !text-base" />
	</span>
)

export const IdentitiesSideBar = () => {
	const { lens, tab } = useSearch({ from: RoutePaths.Identities })
	const { gridRef } = useIdentitiesContext()
	const [isCollapsed, setIsCollapsed] = useState(false)
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const [openKeys, setOpenKeys] = useState<string[]>([])

	useEffect(() => {
		const columnFitTimeout = setTimeout(() => {
			gridRef.current?.api?.sizeColumnsToFit()
		}, 200)

		return () => {
			clearTimeout(columnFitTimeout)
		}
	}, [isCollapsed])

	const onClick: MenuClickEventHandler = (menuEntry) => {
		const newLens: IdentitiesPageLens | undefined = Object.values(IdentitiesPageLens).includes(
			menuEntry.key as IdentitiesPageLens,
		)
			? (menuEntry.key as IdentitiesPageLens)
			: undefined

		void navigate({
			search: {
				tab,
				...(newLens && { lens: newLens }),
			},
		})
	}
	const { data: lensStats, isPending } = useIdentityLensStats()
	const items = useMemo(() => {
		const menuItems: MenuItem[] = [
			{
				key: IdentitiesPageLens.ALL,
				label: (
					<MenuItemLabel count={lensStats?.[IdentitiesPageLens.ALL]}>
						{IDENTITIES_LENS_MAPPING[IdentitiesPageLens.ALL].label}
					</MenuItemLabel>
				),
				icon: <MenuItemIcon Icon={IDENTITIES_LENS_MAPPING[IdentitiesPageLens.ALL].icon} />,
			},
		]

		MENU_STRUCTURE.forEach((category) => {
			const validItems = category.items
				.filter((item) => shouldShowMenuItem(item, lensStats?.[item.key], isDemo))
				.map((item) => ({
					key: item.key,
					label: <MenuItemLabel count={lensStats?.[item.key]}>{item.label}</MenuItemLabel>,
					icon: <MenuItemIcon Icon={item.icon} />,
				}))

			if (validItems.length > 0) {
				menuItems.push({
					key: category.key,
					label: <MenuItemLabel isFolder>{category.label}</MenuItemLabel>,
					icon: <MenuItemIcon Icon={category.icon} />,
					children: validItems,
				})
			}
		})

		STANDALONE_ITEMS.forEach((item) => {
			if (shouldShowMenuItem(item, lensStats?.[item.key], isDemo)) {
				menuItems.push({
					key: item.key,
					label: <MenuItemLabel count={lensStats?.[item.key]}>{item.label}</MenuItemLabel>,
					icon: <MenuItemIcon Icon={item.icon} />,
				})
			}
		})

		return menuItems
	}, [lensStats])

	useEffect(() => {
		if (!lens) {
			return
		}

		const parentCategory = MENU_STRUCTURE.find((category) => category.items.some((item) => item.key === lens))

		if (parentCategory) {
			setOpenKeys((currentOpenKeys) => [...currentOpenKeys, parentCategory.key])
		}
	}, [lens])

	const toggleSiderCollapse = useCallback(() => {
		setIsCollapsed((currentIsCollapsed) => !currentIsCollapsed)
	}, [setIsCollapsed])
	return (
		<ConfigProvider theme={pageSidebarTheme}>
			<Layout.Sider collapsed={isCollapsed} collapsedWidth={64} className="overflow-y-auto" width="15%">
				<CollapsibleSiderMenuHeader
					isCollapsed={isCollapsed}
					title="Inventory view"
					toggleCollapse={toggleSiderCollapse}
				/>
				{isPending ? (
					<div className="px-4">
						<div className="py-2">
							<Skeleton active paragraph={{ rows: 12 }} />
						</div>
					</div>
				) : (
					<Menu
						onClick={onClick}
						selectedKeys={lens ? [lens] : [DEFAULT_IDENTITIES_LENS]}
						openKeys={openKeys}
						onOpenChange={setOpenKeys}
						mode="inline"
						theme={'dark'}
						items={items}
					/>
				)}
			</Layout.Sider>
		</ConfigProvider>
	)
}
