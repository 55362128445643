import {
	IdentitiesPageLens,
	IdentitiesPageSearch,
	IdentityActivityStatus,
	IdentitySource,
	IdentityTabName,
	IdentityType,
	ServerIdentitiesTableRow,
} from '../../schemas/identity.ts'
import { mostWantedIdentitiesData } from '../data/metricsData.ts'
import { MockDemoTag } from '../../schemas/tags.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { IssueName } from '../../schemas/issue.ts'
import { QueryFilterOperator, QueryFilterExpression } from '../../schemas/query.ts'

export function filterIdentities(
	identities: ServerIdentitiesTableRow[],
	postParams: IdentitiesPageSearch,
): ServerIdentitiesTableRow[] {
	if (identities.length === 0) return []
	let filteredIdentities = identities
	const { lens } = postParams

	switch (lens) {
		case IdentitiesPageLens.AWS:
		case IdentitiesPageLens.GCP:
		case IdentitiesPageLens.AZURE:
		case IdentitiesPageLens.OKTA:
		case IdentitiesPageLens.JUMPCLOUD:
		case IdentitiesPageLens.ENTRA_ID:
		case IdentitiesPageLens.GOOGLE_WORKSPACE:
		case IdentitiesPageLens.GITHUB:
		case IdentitiesPageLens.AZURE_DEVOPS:
		case IdentitiesPageLens.SALESFORCE:
		case IdentitiesPageLens.DEMO_ATLASSIAN: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.env_type === (lens as unknown as EnvironmentType)
			})
			break
		}
		case IdentitiesPageLens.DEMO_OVER_PRIVILEGED_IDENTITY:
		case IdentitiesPageLens.DEMO_IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.mock_demo_tags?.includes(lens as unknown as MockDemoTag)
			})
			break
		}
		case IdentitiesPageLens.OFF_BOARDED_EMPLOYEES: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				const validSources = [
					IdentitySource.OKTA,
					IdentitySource.GOOGLE_WORKSPACE,
					IdentitySource.JUMPCLOUD_USER,
					IdentitySource.ENTRA_ID_USER,
				]
				return (
					identity.type === IdentityType.Human &&
					identity.activity_status === IdentityActivityStatus.INACTIVE &&
					validSources.includes(identity.source)
				)
			})
			break
		}
		case IdentitiesPageLens.MULTIPLE_ACCESS_KEYS:
		case IdentitiesPageLens.UNFEDERATED_IDENTITIES: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				const matchingIssues =
					identity.issues?.filter((issue) => {
						return issue.issue_name === (lens as unknown as IssueName)
					})?.length ?? 0
				return matchingIssues > 0
			})
			break
		}
		case IdentitiesPageLens.RECENTLY_CREATED: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.created_at && new Date(identity.created_at) > new Date(Date.now() - 24 * 60 * 60 * 1000)
			})
			break
		}
		case IdentitiesPageLens.MOST_WANTED: {
			const mostWantedIdentitiesIds = mostWantedIdentitiesData.map((identity) => identity.id)

			// Filter identities that are in mostWantedIdentities
			filteredIdentities = filteredIdentities.filter((identity) => {
				return mostWantedIdentitiesIds.includes(identity.id)
			})

			// Sort filteredIdentities to match the order of mostWantedIdentities
			filteredIdentities.sort((a, b) => {
				return mostWantedIdentitiesIds.indexOf(a.id) - mostWantedIdentitiesIds.indexOf(b.id)
			})
			break
		}
		case IdentitiesPageLens.KUBERNETES:
		case IdentitiesPageLens.POSTGRES:
		case IdentitiesPageLens.SNOWFLAKE:
		case IdentitiesPageLens.DEMO_DATABRICKS: {
			filteredIdentities = filteredIdentities.filter((identity) => {
				return identity.source.toLowerCase().includes(lens.toLowerCase())
			})
		}
	}

	return filteredIdentities
}

export function applyPagination(
	data: ServerIdentitiesTableRow[],
	offset: number,
	limit: number,
): ServerIdentitiesTableRow[] {
	return data.slice(offset, offset + limit)
}

export function applySorting(
	data: ServerIdentitiesTableRow[],
	sort: Array<{ field: string; direction: 'asc' | 'desc' }>,
): ServerIdentitiesTableRow[] {
	return [...data].sort((a, b) => {
		for (const sortItem of sort) {
			const aValue = a[sortItem.field as keyof ServerIdentitiesTableRow]
			const bValue = b[sortItem.field as keyof ServerIdentitiesTableRow]

			if (aValue === bValue) continue

			if (aValue === null || aValue === undefined) return 1
			if (bValue === null || bValue === undefined) return -1

			// Handle date fields
			const dateFields = ['created_at', 'last_activity', 'last_modified']
			if (dateFields.includes(sortItem.field)) {
				const aDate = aValue ? new Date(aValue as string).getTime() : 0
				const bDate = bValue ? new Date(bValue as string).getTime() : 0
				return sortItem.direction === 'asc' ? aDate - bDate : bDate - aDate
			}

			// Handle numeric fields
			if (typeof aValue === 'number' && typeof bValue === 'number') {
				return sortItem.direction === 'asc' ? aValue - bValue : bValue - aValue
			}

			// Handle string fields
			const comparison = String(aValue).localeCompare(String(bValue))
			return sortItem.direction === 'asc' ? comparison : -comparison
		}
		return 0
	})
}

function handleDateFilter(dateValue: Date | null, filter: QueryFilterExpression): boolean {
	if (!dateValue) return false

	// Handle direct comparison filters
	if ('field' in filter && 'op' in filter && filter.value !== undefined) {
		if (filter.op === QueryFilterOperator.GT) {
			const filterDate = new Date(filter.value as string)
			return dateValue > filterDate
		} else if (filter.op === QueryFilterOperator.LT) {
			const filterDate = new Date(filter.value as string)
			return dateValue < filterDate
		}
	}

	return false
}

export function applyFiltering(
	data: ServerIdentitiesTableRow[],
	filter: QueryFilterExpression,
): ServerIdentitiesTableRow[] {
	return data.filter((row) => {
		// Handle logical operators (AND, OR, NOT) for complex filter expressions
		// Each operator recursively applies filters to the row:
		// - AND: All subfilters must match (every)
		// - OR: At least one subfilter must match (some)
		// - NOT: None of the subfilters should match (every not)
		// Returns true if no operator matches (empty filter group)
		if ('AND' in filter) {
			return filter.AND?.every((subFilter) => applyFiltering([row], subFilter).length > 0) ?? true
		} else if ('OR' in filter) {
			return filter.OR?.some((subFilter) => applyFiltering([row], subFilter).length > 0) ?? true
		} else if ('NOT' in filter) {
			return filter.NOT?.every((subFilter) => applyFiltering([row], subFilter).length === 0) ?? true
		}

		if ('field' in filter && filter.value !== undefined) {
			// Handle direct field comparison with operators like EQ, CONTAINS, etc.

			const value = row[filter.field as keyof ServerIdentitiesTableRow]

			// Handle direct field comparison with operators
			switch (filter.op) {
				case QueryFilterOperator.EQ:
					return value === filter.value
				case QueryFilterOperator.NEQ:
					return value !== filter.value
				case QueryFilterOperator.CONTAINS:
				case QueryFilterOperator.ICONTAINS: {
					// Special handling for affected_environments
					if (filter.field === 'affected_environments') {
						if (!value || typeof value !== 'object') return false

						return Object.values(
							// This can be of the type of ServerAffectedEnvironmentsSchema, if we infer it.
							value as Record<
								string,
								Array<{
									account_db_id?: string | null
									account_id?: string | null
									account_name?: string | null
								}>
							>,
						).some((envArray) =>
							envArray?.some((env) => {
								const searchValue = String(filter.value).toLowerCase()
								return (
									(env?.account_db_id && String(env.account_db_id).toLowerCase() === searchValue) ||
									(env?.account_id && String(env.account_id).toLowerCase() === searchValue) ||
									(env?.account_name && String(env.account_name).toLowerCase() === searchValue)
								)
							}),
						)
					}
					return String(value).toLowerCase().includes(String(filter.value).toLowerCase())
				}
				case QueryFilterOperator.IN:
					if (filter.field === 'max_priority') {
						// Convert both the value array and the row value to numbers for comparison
						const filterValues = (filter.value as string[]).map(Number)
						return filterValues.includes(Number(value))
					}
					return Array.isArray(filter.value) && filter.value.includes(value)
				case QueryFilterOperator.NIN:
					return Array.isArray(filter.value) && !filter.value.includes(value)
				case QueryFilterOperator.OVERLAP:
					if (filter.field === 'tags_with_env' || filter.field === 'tags_names') {
						return (
							Array.isArray(filter.value) &&
							Array.isArray(value) &&
							(filter.value as string[]).some((filterValue) => (value as string[]).includes(filterValue))
						)
					}
					return false
				case QueryFilterOperator.GT:
					if (filter.field === 'last_activity') {
						return handleDateFilter(new Date(value as string), filter)
					}
					return typeof value === 'number' && value > Number(filter.value)
				case QueryFilterOperator.GTE:
					return typeof value === 'number' && value >= Number(filter.value)
				case QueryFilterOperator.LT:
					if (filter.field === 'last_activity') {
						return handleDateFilter(new Date(value as string), filter)
					}
					return typeof value === 'number' && value < Number(filter.value)
				case QueryFilterOperator.LTE:
					return typeof value === 'number' && value <= Number(filter.value)
				default:
					return true
			}
		}

		return true
	})
}

export function applyLensAndTabFiltering(
	identities: ServerIdentitiesTableRow[],
	lens?: IdentitiesPageLens,
	tab?: IdentityTabName,
): ServerIdentitiesTableRow[] {
	let result = identities

	// Apply lens filtering
	if (lens) {
		result = filterIdentities(result, { lens })
	}

	// Apply tab filtering (MANAGED vs DELETED)
	if (tab === IdentityTabName.DELETED) {
		result = result.filter((identity) => identity.activity_status === IdentityActivityStatus.DELETED)
	} else {
		result = result.filter((identity) => identity.activity_status !== IdentityActivityStatus.DELETED)
	}

	return result
}

export function countIdentitiesByLens(identities: ServerIdentitiesTableRow[]): Record<IdentitiesPageLens, number> {
	const counts: Record<IdentitiesPageLens, number> = {} as Record<IdentitiesPageLens, number>

	// Process each lens type
	Object.values(IdentitiesPageLens).forEach((lens) => {
		if (lens === IdentitiesPageLens.ALL) {
			counts[lens] = identities.length
			return
		}

		// Use filterIdentities for all other lenses
		const filteredIdentities = filterIdentities(identities, { lens })
		counts[lens] = filteredIdentities.length
	})

	return counts
}
