import { TableSetFilterEntry } from '../../../schemas/tableFilter.ts'
import {
	IdentityFilterKeys,
	IdentitySource,
	IdentitySourceMap,
	IdentityType,
	IdentityTypeMap,
} from '../../../schemas/identity.ts'
import {
	isResourceType,
	PriorityType,
	PriorityTypeMap,
	ResourceTypeIconMap,
	ResourceTypeMap,
} from '../../../schemas/issue.ts'
import { Tag, TagsDisplayNameMap } from '../../../schemas/tags.ts'
import { useIdentityAffectedEnvironments, useIdentityEnvironments } from '../../../api/identities.ts'
import { Skeleton } from 'antd'
import { SetFilterChip } from '../../../components/common/FilterChip/SetFilterChip.tsx'
import { FilterChipValueProps } from '../../../components/common/FilterChip/FilterChipValue.tsx'
import { RoutePaths } from '../../RoutePaths.tsx'
import { useNavigate } from '@tanstack/react-router'
import { isEmpty } from 'lodash'

type SetFilterChipProps = {
	field: IdentityFilterKeys
	filter: TableSetFilterEntry
}

export const InventorySetFilterChip = ({ field, filter }: SetFilterChipProps) => {
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const { data: sourceEnvironments, isPending: isSourceEnvPending } = useIdentityEnvironments({})
	const { data: affectedEnvironments, isPending: isAffectedEnvPending } = useIdentityAffectedEnvironments({})

	const removeFilterValue = (fieldToRemove: IdentityFilterKeys, valueToRemove: string) => {
		void navigate({
			search: (prevSearch) => {
				const newIdentityFilter = { ...prevSearch.identityFilter }
				const currentFilter = newIdentityFilter[fieldToRemove] as TableSetFilterEntry
				const newFilter: TableSetFilterEntry = {
					...currentFilter,
					values: currentFilter.values.filter((value) => value !== valueToRemove),
				}

				const newSearch = { ...prevSearch }
				if (isEmpty(newFilter.values)) {
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete newIdentityFilter[fieldToRemove]
				} else {
					newIdentityFilter[fieldToRemove] = newFilter
				}

				if (isEmpty(newIdentityFilter)) {
					delete newSearch.identityFilter
				} else {
					newSearch.identityFilter = newIdentityFilter
				}

				// When filters change we need to reset the page
				delete newSearch.page

				return newSearch
			},
		})
	}

	const createValueWithIconComponent = (
		displayValue: string,
		iconType: string | null | undefined,
		value: string,
		iconTypeInTitle?: boolean,
	): FilterChipValueProps & { key: string } => {
		let SourceIcon = null
		let title: string = displayValue
		if (isResourceType(iconType)) {
			SourceIcon = ResourceTypeIconMap[iconType] ?? null
			if (iconTypeInTitle) {
				title = `${ResourceTypeMap[iconType]} ${displayValue}`
			}
		}

		const children = (
			<div className="flex items-center truncate">
				{SourceIcon && (
					<span className="shrink-0">
						<SourceIcon width="16" height="16" />
					</span>
				)}
				<span className="ml-1 shrink truncate">{displayValue}</span>
			</div>
		)

		return {
			key: `${value}_${iconType}`,
			title,
			onRemove: () => removeFilterValue(field, value),
			children,
		}
	}

	const chipValues: Array<FilterChipValueProps & { key: string }> = filter.values
		.filter((v) => v !== null)
		.map((value) => {
			switch (field) {
				case 'type':
					return {
						key: value,
						title: IdentityTypeMap[value as IdentityType],
						onRemove: () => removeFilterValue(field, value),
					}
				case 'source':
					return {
						key: value,
						title: IdentitySourceMap[value as IdentitySource],
						onRemove: () => removeFilterValue(field, value),
					}
				case 'max_priority': {
					const numericValue = Number(value)
					return {
						key: value,
						title: PriorityTypeMap[numericValue as PriorityType],
						onRemove: () => removeFilterValue(field, value),
					}
				}
				case 'account_literal': {
					if (isSourceEnvPending) {
						return {
							key: value,
							title: '',
							children: <Skeleton className="min-w-12" paragraph={false} active />,
						}
					}
					const env = sourceEnvironments?.find((e) => e.account_literal === value)
					const envDisplayName = env?.account_literal_friendly_name ?? value
					return createValueWithIconComponent(envDisplayName, env?.env_type, value)
				}
				case 'affected_environments': {
					if (isAffectedEnvPending) {
						return {
							key: value,
							title: '',
							children: <Skeleton className="min-w-12" paragraph={false} active />,
						}
					}
					const env = affectedEnvironments?.find((e) => e.account_db_id === value)
					const envDisplayName = env?.account_name ?? value
					return createValueWithIconComponent(envDisplayName, env?.env_type, value)
				}
				case 'tags_names':
					return {
						key: value,
						title: TagsDisplayNameMap[value as Tag],
						onRemove: () => removeFilterValue(field, value),
					}
				case 'tags_with_env': {
					const [envType, envTag] = value.split('__')
					return createValueWithIconComponent(TagsDisplayNameMap[envTag as Tag], envType, value, true)
				}
				case 'owners':
					return {
						key: value,
						title: value,
						onRemove: () => removeFilterValue(field, value),
					}
				default:
					console.error('Unsupported set filter field:', field)
					return { title: value, key: value }
			}
		})

	return <SetFilterChip chipValues={chipValues} />
}
