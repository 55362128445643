import { ServerIdentity } from '../../../schemas/identity.ts'
import { Card, Tooltip } from 'antd'
import { tagsIconsMap } from '../../../components/common/IdentityTag/common.tsx'
import { getTagDescription, Tag, TagsDisplayNameMap } from '../../../schemas/tags.ts'
import { getCombinedAwsIamRolePolicy, getPolicyUniqueKey } from '../../../utils/awsIdentityUtils.ts'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { isResourceType, ResourceTypeIconMap } from '../../../schemas/issue.ts'

export const SidePanelRisks = ({ identity }: { identity: ServerIdentity }) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	if (!identity.tags?.length) {
		return 'No risks data'
	}

	const policies = getCombinedAwsIamRolePolicy(
		identity.aws_iam_user?.aws_iam_user_details_xc,
		identity.aws_iam_role?.aws_iam_role_details_xc || identity.aws_key_pair?.aws_iam_role_details_xc,
	)

	return (
		<div className="flex flex-col gap-2 h-64 overflow-auto">
			{identity.tags
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((tag) => {
					const relatedPolicies = policies.filter((policy) =>
						policy.RiskTags?.find((riskTag) => riskTag.name === tag.name),
					)

					const onOpenChange = (visible: boolean) => {
						if (!visible) {
							setHighlightedNodes([])
						} else {
							setHighlightedNodes(
								relatedPolicies.map((relatedPolicy) => ({
									type: 'awsPolicy',
									id: getPolicyUniqueKey(relatedPolicy),
								})),
							)
						}
					}

					const tagName = tag.name as Tag
					const Icon = tagsIconsMap[tagName].icon
					const ResourceIcon = isResourceType(tag.related_resource_type)
						? ResourceTypeIconMap[tag.related_resource_type]
						: null
					return (
						<Card
							key={`${tag.name}-${tag.related_resource_type}`}
							title={
								<div className="flex gap-2 items-center">
									{!!Icon && <Icon style={{ width: 20, height: 20 }} />}
									{TagsDisplayNameMap[tagName]}
									{ResourceIcon && <ResourceIcon width="16" height="16" />}
								</div>
							}
						>
							<div className="flex flex-col gap-1">
								{relatedPolicies.length !== 0 && (
									<div className="flex gap-2 items-center text-textIcon-secondary">
										<span>{`Related policies (${relatedPolicies.length})`}</span>
										<Tooltip
											onOpenChange={onOpenChange}
											title={
												<div className="flex flex-col">
													{relatedPolicies.map((relatedPolicy) => (
														<span key={getPolicyUniqueKey(relatedPolicy)}>
															{relatedPolicy.PolicyName}
														</span>
													))}
												</div>
											}
										>
											<ExclamationCircleOutlined className="cursor-pointer mt-[2px] text-base" />
										</Tooltip>
									</div>
								)}
								<span>{getTagDescription(tagName, tag.related_resource_type)}</span>
							</div>
						</Card>
					)
				})}
		</div>
	)
}
