import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import GithubIcon from '../../../../assets/github_icon_20.svg?react'
import { ServerGithubEnvironment } from '../../../../schemas/environments/githubEnvironment.ts'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'

type GithubSinglePageIntegrationBodyProps = {
	githubEnvironments: ServerGithubEnvironment[]
}

export const GithubSinglePageIntegrationBody: React.FC<GithubSinglePageIntegrationBodyProps> = ({
	githubEnvironments,
}) => {
	return (
		<IntegrationsSection>
			{githubEnvironments?.map((environment) => {
				const cardConfig = {
					title: environment.name,
					logo: <IntegrationIcon leftIcon={GithubIcon} />,
					fields: [{ label: 'Organization ID', value: environment.org_id }],
				}
				return (
					<SingleIntegrationGenericCard
						key={environment.id}
						title={cardConfig.title}
						logo={cardConfig.logo}
						fields={cardConfig.fields}
					/>
				)
			})}
		</IntegrationsSection>
	)
}
