import { StandardGroupSids } from './adConstants'
import { ServerAdGroupInput } from '../../schemas/identities/activeDirectory/adGroupSchema.ts'

export const adGroupsData: Record<string, ServerAdGroupInput> = {
	DOMAIN_ADMINS: {
		object_sid: StandardGroupSids.DOMAIN_ADMINS,
		sam_account_name: 'Domain Admins',
		description: 'Designated administrators of the domain',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'ADMIN',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	DNS_ADMINS: {
		object_sid: StandardGroupSids.DNS_ADMINS,
		sam_account_name: 'DnsAdmins',
		description: 'DNS Administrators Group',
		outbound_permissions: [
			{
				right_name: 'GenericAll',
				is_inherited: false,
			},
			{
				right_name: 'GenericAll',
				is_inherited: false,
			},
			{
				right_name: 'GenericAll',
				is_inherited: true,
			},
			{
				right_name: 'GenericWrite',
				is_inherited: true,
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	SQL_WRITE_ACCESS: {
		object_sid: StandardGroupSids.SQL_WRITE_ACCESS,
		sam_account_name: 'SQL_Write_Access',
		description: 'Members have write access to SQL databases',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	PRM_LOG_ADMINS: {
		object_sid: StandardGroupSids.PRM_LOG_ADMINS,
		sam_account_name: 'PRM_LOG_ADMINS',
		description: 'Members have admin access to PRM logs',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	DOMAIN_USERS: {
		object_sid: StandardGroupSids.DOMAIN_USERS,
		sam_account_name: 'Domain Users',
		description: 'All domain users',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
		],
		tags: [],
	},

	SCHEMA_ADMINS: {
		object_sid: StandardGroupSids.SCHEMA_ADMINS,
		sam_account_name: 'Schema Admins',
		description: 'Members can modify the Active Directory schema',
		outbound_permissions: [
			{
				right_name: 'GenericAll',
				is_inherited: false,
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'ADMIN',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	ENTERPRISE_ADMINS: {
		object_sid: StandardGroupSids.ENTERPRISE_ADMINS,
		sam_account_name: 'Enterprise Admins',
		description: 'Members have full control of all domains in the forest',
		outbound_permissions: [
			{
				right_name: 'GenericAll',
				is_inherited: false,
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'ADMIN',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	BACKUP_OPERATORS: {
		object_sid: StandardGroupSids.BACKUP_OPERATORS,
		sam_account_name: 'Backup Operators',
		description: 'Members can backup and restore files regardless of permissions',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 2,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	SERVER_OPERATORS: {
		object_sid: StandardGroupSids.SERVER_OPERATORS,
		sam_account_name: 'Server Operators',
		description: 'Members can administer domain servers',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
		],
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 2,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	PERFORMANCE_MONITOR_USERS: {
		object_sid: StandardGroupSids.PERFORMANCE_MONITOR_USERS,
		sam_account_name: 'Performance Monitor Users',
		description: 'Members can monitor performance counters on domain controllers',
		outbound_permissions: [],
		tags: [],
	},

	DOMAIN_COMPUTERS: {
		object_sid: StandardGroupSids.DOMAIN_COMPUTERS,
		sam_account_name: 'Domain Computers',
		description: 'All computers in the domain',
		outbound_permissions: [],
		tags: [],
	},
}
