import { useMemo } from 'react'
import { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Switch } from 'antd'
import {
	IssueImpactProbabilityWithCount,
	IssueNameMap,
	PriorityType,
	PriorityTypeMap,
	ResourceTypeMap,
} from '../../../schemas/issue.ts'
import { IssueIcon } from '../../../components/common/IssueIcon.tsx'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { SelectPriority } from './SelectPriority.tsx'
import { SelectDaysThreshold } from './SelectDaysThreshold.tsx'
import { systemGeneratedText } from './PrioritySelectOption.tsx'

type IssueTypesTableProps = {
	isEditing: boolean
	isFetching: boolean
	issueTypes: IssueImpactProbabilityWithCount[]
	searchText: string
	onToggleIssueType: (issueType: IssueImpactProbabilityWithCount) => void
	onChangeIssueTypePriority: (issueType: IssueImpactProbabilityWithCount, newPriority: PriorityType | null) => void
	onChangeIssueTypeDaysThreshold: (
		issueType: IssueImpactProbabilityWithCount,
		newDaysThreshold: number | null,
	) => void
}

export const IssueTypesTable = ({
	isEditing,
	isFetching,
	issueTypes,
	searchText,
	onToggleIssueType,
	onChangeIssueTypePriority,
	onChangeIssueTypeDaysThreshold,
}: IssueTypesTableProps) => {
	const columns = useMemo(
		(): ColDef<IssueImpactProbabilityWithCount>[] => [
			{
				headerName: 'Issue Type',
				flex: 0.7,
				sort: 'asc',
				sortIndex: 0,
				valueGetter: ({ data }) => (data ? IssueNameMap[data.issue_name] : ''),
				cellRenderer: ({ data, value }: { value: string; data: IssueImpactProbabilityWithCount }) => (
					<div className="flex items-center gap-2 h-full">
						<IssueIcon name={data.issue_name} style={{ flexShrink: 0 }} />
						<span>{value}</span>
					</div>
				),
			},
			{
				headerName: 'Source Environment',
				flex: 0.5,
				sort: 'asc',
				sortIndex: 1,
				valueGetter: ({ data }) => (data ? ResourceTypeMap[data.issue_source] : ''),
				cellRenderer: ({ data, value }: { value: string; data: IssueImpactProbabilityWithCount }) => (
					<div className="flex items-center gap-2 h-full">
						<IssueSourceIcon source={data.issue_source} size={20} />
						<span>{value}</span>
					</div>
				),
			},
			{
				field: 'issue_count',
				headerName: 'Open Issue Count',
				flex: 0.4,
				cellRenderer: ({ value }: { value: IssueImpactProbabilityWithCount['issue_count'] }) => (
					<span>{value}</span>
				),
			},
			{
				field: 'priority',
				headerName: 'Priority',
				flex: 0.7,
				filterValueGetter: ({ data }) =>
					data?.priority ? PriorityTypeMap[data.priority] : systemGeneratedText,
				valueGetter: ({ data }) => data?.priority || 0,
				cellRenderer: ({ data }: { data: IssueImpactProbabilityWithCount }) => (
					<SelectPriority
						priority={data.priority || 0}
						onChange={(newPriority: PriorityType | null) => {
							onChangeIssueTypePriority(data, newPriority)
						}}
						loading={isFetching}
						disabled={!isEditing}
					/>
				),
			},
			{
				headerName: 'Advanced Settings',
				flex: 0.4,
				filterValueGetter: ({ data }) => data?.days_threshold || 0,
				valueGetter: ({ data }) => data?.days_threshold || 0,
				cellRenderer: ({ data }: { data: IssueImpactProbabilityWithCount }) =>
					data.days_threshold ? (
						<SelectDaysThreshold
							daysThreshold={data.days_threshold}
							onChange={(newDaysThreshold: number | null) => {
								onChangeIssueTypeDaysThreshold(data, newDaysThreshold)
							}}
							loading={isFetching}
							disabled={!isEditing}
						/>
					) : null,
			},
			{
				headerName: 'Enabled',
				type: 'rightAligned',
				flex: 0.4,
				valueGetter: ({ data }) => !data!.ignore_issue,
				cellRenderer: ({ value, data }: { value: boolean; data: IssueImpactProbabilityWithCount }) => (
					<Switch
						checked={value}
						onChange={() => {
							onToggleIssueType(data)
						}}
						loading={isFetching}
						disabled={!isEditing}
					/>
				),
			},
		],
		[isEditing, isFetching],
	)

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowData={issueTypes}
			columnDefs={columns}
			quickFilterText={searchText}
			getRowId={({ data }) => data.id}
			rowHeight={50}
		/>
	)
}
