import { z } from 'zod'

export const ServerIdentitiesDistByPriorityAndDateSchema = z.object({
	date: z.string(),
	critical: z.number(),
	high: z.number(),
	medium: z.number(),
	low: z.number(),
	not_calculated: z.number(),
})

export type ServerIdentitiesDistByPriorityAndDateInput = z.input<typeof ServerIdentitiesDistByPriorityAndDateSchema>
export type ServerIdentitiesDistByPriorityAndDate = z.infer<typeof ServerIdentitiesDistByPriorityAndDateSchema>
