import React, { useEffect, useRef, useState } from 'react'
import { Divider, Typography, message } from 'antd'
import { EnvironmentIntegrations } from './EnvironmentIntegrations/components/EnvironmentIntegrations.tsx'
import { CollaborationIntegrations } from './CollaborationIntegrations/components/CollaborationIntegrations.tsx'
import { ApiOutlined } from '@ant-design/icons'
import { useSearch, useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../RoutePaths'
import { IntegrationType } from '../../jiraIntegration/schemas/common'
import { IntegrationResultStatus } from '../../schemas/integrations/integrations'
import { PageHeader } from '../../components/common/PageHeader.tsx'
import { Page } from '../../components/common/Page.tsx'

const { Title, Paragraph } = Typography

enum ModalType {
	ENVIRONMENT = 'environment',
	COLLABORATION = 'collaboration',
}

export const Integrations: React.FC = () => {
	const search = useSearch({ from: RoutePaths.Integrations })
	const navigate = useNavigate()
	const [messageApi, antMessageContextHolder] = message.useMessage()
	const [openSpecificModal, setOpenSpecificModal] = useState<IntegrationType | undefined>(undefined)
	const processedParams = useRef(new Set<string>())
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [activeModalType, setActiveModalType] = useState<ModalType | undefined>(undefined)

	useEffect(() => {
		// Handle Slack integration status notifications
		// This effect runs when redirected back from Slack OAuth flow
		// It shows appropriate toast messages based on the integration result
		// and cleans up the URL parameters afterwards to prevent duplicate toasts
		// This can be removed once we have a more robust way to handle OAuth flows for all integrations
		const { integrationType, openModal, integrationStatus } = search

		if (integrationType === 'slack' && integrationStatus && !processedParams.current.has('slack')) {
			processedParams.current.add('slack')

			if (integrationStatus === IntegrationResultStatus.SUCCESS) {
				messageApi.success({
					content: 'Slack integration successfully configured',
					duration: 4,
				})
			} else if (integrationStatus === IntegrationResultStatus.FAIL) {
				messageApi.error({
					content: 'Could not configure Slack integration. Please try again.',
					duration: 4,
				})
			} else if (integrationStatus === IntegrationResultStatus.EXISTS) {
				messageApi.info({
					content: 'Slack integration already configured',
					duration: 4,
				})
			}
			if (openModal) {
				setOpenSpecificModal(openModal === 'slack' ? IntegrationType.SLACK : undefined)
				setActiveModalType(ModalType.COLLABORATION)
				setIsModalVisible(true)
			}

			// Clean up URL parameters
			navigate({
				to: RoutePaths.Integrations,
				search: {},
			})
		}
	}, [search, messageApi, navigate])

	return (
		<Page>
			{antMessageContextHolder}
			<PageHeader Icon={ApiOutlined}>Integrations</PageHeader>
			<div className="flex flex-col w-full bg-surface-primary border border-border-primary rounded-lg p-6 overflow-y-auto">
				<EnvironmentIntegrations
					isModalVisible={isModalVisible && activeModalType === ModalType.ENVIRONMENT}
					setIsModalVisible={(visible) => {
						setIsModalVisible(visible)
						setActiveModalType(visible ? ModalType.ENVIRONMENT : undefined)
					}}
				/>
				<Divider />
				<Title level={5}>Collaboration Tools</Title>
				<Paragraph className="text-textIcon-secondary">
					Connect your collaboration tools to your environment to enable seamless communication and
					collaboration.
				</Paragraph>
				<CollaborationIntegrations
					openSpecificModal={openSpecificModal}
					isModalVisible={isModalVisible && activeModalType === ModalType.COLLABORATION}
					setIsModalVisible={(visible) => {
						setIsModalVisible(visible)
						setActiveModalType(visible ? ModalType.COLLABORATION : undefined)
					}}
				/>
			</div>
		</Page>
	)
}
