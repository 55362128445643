import cx from 'classnames'
import { type ReactNode } from 'react'
import { Button, Tooltip } from 'antd'

type SideBarMenuIconProps = {
	children: ReactNode
	isActive: boolean
	onClick: () => void
	tooltipTitle: string
}

export const SideBarMenuIconButton = ({ children, isActive, onClick, tooltipTitle }: SideBarMenuIconProps) => {
	const className = cx('w-10 h-10 rounded-sm border flex items-center justify-center', {
		'border-border-secondaryDark bg-surface-secondaryDark text-dsBlue-400': isActive,
		'hover:border-border-secondaryDark hover:bg-surface-secondaryDark border-transparent text-textIcon-secondaryDark':
			!isActive,
	})

	return (
		<Tooltip title={tooltipTitle} placement="right">
			<Button type="link" className="w-10 h-10 p-0" onClick={onClick}>
				<div className={className}>{children}</div>
			</Button>
		</Tooltip>
	)
}
