import TeamsNewChatImage from '../../../../assets/IntegrationPage/TeamsIntegrationModalContent/TeamsIntegrationModalContentStep1.svg?react'
import TeamsAddWebhookImage from '../../../../assets/IntegrationPage/TeamsIntegrationModalContent/TeamsIntegrationModalContentStep2.svg?react'
import TeamsConfigureWebhookImage from '../../../../assets/IntegrationPage/TeamsIntegrationModalContent/TeamsIntegrationModalContentStep3.svg?react'
import TeamsFinalizeConfigImage from '../../../../assets/IntegrationPage/TeamsIntegrationModalContent/TeamsIntegrationModalContentStep4.svg?react'
import { Form, Input } from 'antd'
import { ConnectableCollaborationIntegrationConfig } from '../collaborationIntegrationsTypes.ts'
import { IntegrationStep } from './IntegrationStep.tsx'

interface TeamsIntegrationModalContentProps {
	integration: ConnectableCollaborationIntegrationConfig
}

export const TeamsIntegrationModalContent = ({ integration }: TeamsIntegrationModalContentProps) => {
	if (!integration || !integration.formFields) return <></>
	return (
		<div className="px-1 pt-2">
			<div className="mb-6">
				<h2 className="font-semibold mb-1">Enable Token Security Alerts in Your Teams Chats</h2>
				<p className="text-textIcon-secondary">
					Stay informed with notifications from Token Security directly in your Teams channel. Receive instant
					alerts about security issues, empowering your team to respond swiftly and maintain a robust security
					posture.
				</p>
			</div>

			<div className="bg-surface-tertiary rounded-lg p-2 mb-6 border border-border-tertiary">
				<ul className="list-disc pl-2 space-y-2">
					<li>
						<span className="font-medium">Teams Permissions: </span>
						<span className="text-textIcon-secondary">
							Ensure you have the necessary permissions to create Workflows in your desired Teams channel.
						</span>
					</li>
				</ul>
			</div>

			<div className="grid grid-cols-4 gap-4 mb-4">
				<IntegrationStep
					stepNumber={1}
					image={<TeamsNewChatImage className="w-full h-auto" />}
					title="Create a new chat"
					description='Open new Teams channel, and click the "manage channel" tab.'
				/>
				<IntegrationStep
					stepNumber={2}
					image={<TeamsAddWebhookImage className="w-full h-auto" />}
					title="Add Webhook Connectors"
					description='Add new Connectors - Search for "Incoming Webhook" and push-configure.'
				/>
				<IntegrationStep
					stepNumber={3}
					image={<TeamsConfigureWebhookImage className="w-full h-auto" />}
					title="Configure Webhook"
					description="Enter your new Incoming Webhook name and copy the Incoming Webhook URL."
				/>
				<IntegrationStep
					stepNumber={4}
					image={<TeamsFinalizeConfigImage className="w-full h-auto" />}
					title="Finalize Configuration 🎉"
					description="Paste here the Webhook URL. Token Security will now automatically send alerts to your Teams channel."
				/>
			</div>
			{integration.formFields.map((field) => (
				<Form.Item
					key={field.name}
					name={field.name}
					label={field.label}
					required={field.required}
					rules={[
						{
							required: field.required,
							message: `${field.label} is required`,
						},
						{
							whitespace: true,
							message: `${field.label} cannot be empty`,
						},
					]}
				>
					<Input
						placeholder={field.placeholder || 'Enter the webhook URL from step 4'}
						type={field.fieldType === 'simple' ? field.type : undefined}
					/>
				</Form.Item>
			))}
		</div>
	)
}
