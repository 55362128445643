import { formatNumber } from '../../../utils/numberUtils.ts'

type IdentitiesStatsCountProps = {
	count: number
}

export const IdentitiesStatsCount = ({ count }: IdentitiesStatsCountProps) => (
	<span className="text-3xl shrink-0 text-textIcon-primary font-medium self-center min-w-10 2xl:min-w-32">
		{formatNumber(count)}
	</span>
)
