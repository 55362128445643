import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import WarningIcon from '../../../../assets/warning_icon_20.svg?react'
import SuccessIcon from '../../../../assets/success_icon_20.svg?react'
import { ColDef, GridReadyEvent } from 'ag-grid-enterprise'
import { ServerEndpointAccessXc } from '../../../../schemas/identities/endpointAccessXcSchema.ts'
import { Tag, Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'

export const EndpointAccessContextTable: React.FunctionComponent<{
	endpointAccessXc: ServerEndpointAccessXc[] | null
}> = ({ endpointAccessXc }) => {
	const gridRef = useRef<AgGridReact<ServerEndpointAccessXc>>(null)
	const columnDefs = useMemo<ColDef<ServerEndpointAccessXc>[]>(() => {
		return [
			{
				field: 'device_name',
				headerName: 'Device Name',
			},
			{
				field: 'device_type',
				headerName: 'Device Type',
			},
			{
				field: 'endpoint_protection',
				headerName: 'Endpoint Protection',
				cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
				cellRenderer: (params: { data?: ServerEndpointAccessXc }) =>
					params.data?.endpoint_protection ? (
						<Tooltip placement="bottomLeft" title={'Endpoint protection is available'}>
							<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						</Tooltip>
					) : (
						<Tooltip placement="bottomLeft" title={'Endpoint protection is missing'}>
							<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						</Tooltip>
					),
			},
			{
				field: 'status',
				headerName: 'Status',
				cellRenderer: (params: { data?: ServerEndpointAccessXc }) => {
					return (
						<Tag>
							<div className="flex items-center">
								<div className="text font-medium">{params.data?.status}</div>
							</div>
						</Tag>
					)
				},
			},
			{
				field: 'ip_address',
				headerName: 'IP Address',
			},
			{
				field: 'first_seen',
				headerName: 'First Seen',
				cellRenderer: (params: { data?: ServerEndpointAccessXc }) => {
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.data?.first_seen)}>
							<div className="text-textIcon-secondary">
								{formatRelativeDateText(params.data?.first_seen, true)}
							</div>
						</Tooltip>
					)
				},
			},
			{
				field: 'last_seen',
				headerName: 'Last Seen',
				sort: 'desc',
				cellRenderer: (params: { data?: ServerEndpointAccessXc }) => {
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.data?.last_seen)}>
							<div className="text-textIcon-secondary">
								{formatRelativeDateText(params.data?.last_seen, true)}
							</div>
						</Tooltip>
					)
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<ServerEndpointAccessXc>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={endpointAccessXc}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
