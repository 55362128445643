import React, { useCallback, useRef, useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { ColDef } from 'ag-grid-enterprise'
import ExportIcon from '../../../../../assets/export_icon_20.svg?react'
import { AgGridReact } from 'ag-grid-react'
import { SnowflakeUserChangeLogs } from '../../../../../schemas/identities/snowflake/snowflakeUserChangeLogs.ts'
import { formatDate, formatRelativeDateText, getCsvFileName } from '../../../../../utils.ts'
import { SnowflakeAggregatedOwnershipTable } from './SnowflakeAggregatedOwnershipTable.tsx'
import { CodeViewerButton } from '../../../../../components/common/CodeViewerButton.tsx'

export const SnowflakeOwnershipTable = ({
	changeLogs,
	identityName,
}: {
	changeLogs: SnowflakeUserChangeLogs[]
	identityName: string | null
}) => {
	const gridRef: React.RefObject<AgGridReact> = useRef<AgGridReact>(null)
	const [defaultColDef] = useState({
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	})
	const [isModalOpen, setIsModalOpen] = useState(false)
	const exportToCsv = useCallback(() => {
		gridRef?.current!.api.exportDataAsCsv({
			fileName: getCsvFileName(`ownership-${identityName}`, false),
		})
	}, [])

	const [changeLogColumnDefs] = useState<ColDef<SnowflakeUserChangeLogs>[]>([
		{
			field: 'actor_user_name',
			headerName: 'Username',
		},
		{
			field: 'query_type',
			headerName: 'Action',
		},
		{
			field: 'query_time',
			headerName: 'Time',
			sort: 'desc',
			cellRenderer: (params: { value: string }) => (
				<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
					<div className="text-textIcon-secondary">{formatRelativeDateText(params.value, true)}</div>
				</Tooltip>
			),
		},
		{
			field: 'query_text',
			headerName: 'Query',
			cellRenderer: (params: { data: SnowflakeUserChangeLogs }) => (
				<CodeViewerButton code={params.data.query_text} title="Query" buttonTitle="View Query" language="sql" />
			),
		},
	])

	if (!changeLogs.length) {
		return <div className={'text-center'}>No Snowflake ownership data available</div>
	}

	return (
		<>
			{changeLogs.length > 0 ? (
				<>
					<div className="h-64 overflow-auto">
						<SnowflakeAggregatedOwnershipTable changeLogs={changeLogs} />
					</div>

					<div className="flex justify-between">
						<Button type="link" size="small" className="mt-4" onClick={() => setIsModalOpen(true)}>
							See all events
						</Button>
					</div>
				</>
			) : (
				<div className={'text-center'}>No ownership data available</div>
			)}
			<Modal
				centered={true}
				width={1024}
				title={'Ownership'}
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex gap-2">
							<Button icon={<ExportIcon />} onClick={exportToCsv}>
								Export CSV
							</Button>
							<Button type="primary" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className={'ag-theme-alpine w-full'}
						rowData={changeLogs}
						rowHeight={54}
						columnDefs={changeLogColumnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
						ref={gridRef}
					/>
				</div>
			</Modal>
		</>
	)
}
