import { api } from './api'
import {
	OnboardingState,
	OnboardingStateInput,
	OnboardingStateSchema,
	OnboardingStateType,
} from '../schemas/onboardingState'
import { useQuery } from '@tanstack/react-query'

export const fetchOnboardingState = async (): Promise<OnboardingState> => {
	const response = await api.get<OnboardingStateInput>('/api/customers/integration-status')
	return OnboardingStateSchema.parse(response.data)
}

const onboardingKeys = {
	onboardingState: () => ['customerIntegrationStatus'] as const,
}

export function useOnboarding() {
	return useQuery({
		queryKey: onboardingKeys.onboardingState(),
		queryFn: fetchOnboardingState,
		staleTime: Infinity,
		gcTime: Infinity,
		refetchInterval: (query) => {
			if (query.state.data?.status === OnboardingStateType.INTEGRATING) {
				return 2 * 60 * 1000 // Refetch every 2 minutes while integrating
			}
			if (query.state.data?.status === OnboardingStateType.FAILED) {
				return 30 * 60 * 1000 // Refetch every 30 minutes while failed
			}
			return false // Don't refetch for other states
		},
		refetchOnWindowFocus: (query) => {
			if (
				query.state.data?.status === OnboardingStateType.INTEGRATING ||
				query.state.data?.status === OnboardingStateType.FAILED
			) {
				return 'always' // Always refetch on window focus while integrating
			}
			return false // Don't refetch on window focus for other states
		},
	})
}
