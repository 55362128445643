import { AdminPortal, useLoginWithRedirect } from '@frontegg/react'
import { useAuthActions as useFronteggAuthActions } from '@frontegg/react-hooks/auth'

export const useAuthActions = () => {
	const { logout, switchTenant } = useFronteggAuthActions()
	const loginWithRedirect = useLoginWithRedirect()
	const showAdminPortal = () => AdminPortal.show()

	return { logout, loginWithRedirect, showAdminPortal, switchTenant }
}
