import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent } from 'ag-grid-enterprise'
import { ServerGcpPrincipalInfoXc } from '../../../../../schemas/identities/gcp/gcpProjectXcSchema.ts'
import { ConnectedGcpRolesTable } from './ConnectedGcpRolesTable.tsx'
import { AccountPriorityNameTag } from '../../../../../components/common/AccountPriorityNameTag.tsx'
import { AccountPriorityEnum } from '../../../../../schemas/environments/accountPriorityEnum.ts'

export const ConnectedGcpProjectsTable: React.FunctionComponent<{
	gcpProjectsXc: ServerGcpPrincipalInfoXc[]
}> = ({ gcpProjectsXc }) => {
	const gridRef = useRef<AgGridReact<ServerGcpPrincipalInfoXc>>(null)
	const showAccountPriorityDrawer = gcpProjectsXc?.every((projectInfo) => projectInfo.account_priority)
	const [rowData, setRowData] = useState<ServerGcpPrincipalInfoXc[] | null>()
	const columnDefs = useMemo<ColDef<ServerGcpPrincipalInfoXc>[]>(() => {
		return [
			{ field: 'project.displayName', headerName: 'Project', cellRenderer: 'agGroupCellRenderer' },
			{
				field: 'account_priority',
				headerName: 'Project Priority',
				hide: !showAccountPriorityDrawer,
				cellRenderer: (params: { data?: ServerGcpPrincipalInfoXc }) => {
					if (params.data?.account_priority) {
						return <AccountPriorityNameTag priority={params.data.account_priority} />
					} else {
						return <AccountPriorityNameTag priority={AccountPriorityEnum.UNKNOWN} />
					}
				},
			},
			{ field: 'project.state' },
			{ field: 'project.createTime' },
			{ field: 'project.parent' },
		]
	}, [])

	const detailCellRenderer = useMemo(() => {
		return ConnectedGcpRolesTable
	}, [])
	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<ServerGcpPrincipalInfoXc>) => {
			setRowData(gcpProjectsXc)
			gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
		},
		[gcpProjectsXc],
	)

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={rowData}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				detailCellRenderer={detailCellRenderer}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
