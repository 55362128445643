import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export const AdUsageLogSchema = z.object({
	time_created: DateSchema,
	event_id: z.string(),
	account_name: z.string(),
	service_name: z.string().nullable(),
	dc: z.string(),
	service_sid: z.string().nullable(),
	user_sid: z.string().nullable(),
	network_address: z.string().nullable(),
	event_count: z.number(),
})

export type AdUsageLog = z.infer<typeof AdUsageLogSchema>

// Schema for the active_directory_user_usage_logs array returned from the identity API
export const AdUsageLogsResponseSchema = z.object({
	active_directory_user_usage_logs: z.array(AdUsageLogSchema),
})

export type AdUsageLogsResponse = z.infer<typeof AdUsageLogsResponseSchema>

// Use the same schema as AdUsageLogSchema for consistency with the backend response
export const ServerAdUsageLogsSchema = AdUsageLogSchema

export type ServerAdUsageLogsInput = z.input<typeof ServerAdUsageLogsSchema>
export type ServerAdUsageLogs = z.infer<typeof ServerAdUsageLogsSchema>

export interface AdUsageLogsAggregatedSchema {
	account_name: string
	service_name: string | null
	network_address: string | null
	event_ids: number[]
	first_time: Date
	last_time: Date
	service_sid: string | null
	user_sid: string | null
	count: number
	countsByEventId: Record<string, number>
}

export interface EventSpecificUsageLog {
	account_name: string
	network_address: string | null
	event_id: number
	event_description?: string
	first_time: Date
	last_time: Date
	count: number
}
