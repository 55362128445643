// This file contains the mapping between Jira field types and UI types. Currently not all Jira field types are supported.
// For adding support to more jira field types - View following links for VsCode integration code: https://www.npmjs.com/package/@atlassianlabs/jira-metaui-transformer?activeTab=code, https://www.npmjs.com/package/@atlassianlabs/jira-pi-meta-models?activeTab=code

import { memo } from 'react'
import { JiraField } from '../schemas/jiraSchemas.ts'
import { JiraUnSupportedField } from './JiraUnSupportedField.tsx'
import { Form } from 'antd'
import { Rule } from 'antd/lib/form'
import { generateJiraItemRules } from '../jiraUtils.ts'
import { jiraFieldTypeToComponent } from './JiraFieldTypeToComponent.ts'

export const DynamicJiraFormItems = memo(({ jiraFields }: { jiraFields?: JiraField[] }) => {
	if (!jiraFields) return null
	return jiraFields.map((jiraField) => {
		const FieldComponent = jiraFieldTypeToComponent[jiraField.schema.type] || JiraUnSupportedField
		const rules: Rule[] = generateJiraItemRules(jiraField)
		return (
			<Form.Item key={jiraField.fieldId} label={jiraField.name} name={jiraField.key} rules={rules}>
				<FieldComponent field={jiraField} />
			</Form.Item>
		)
	})
})
