import { Node, NodeProps } from '@xyflow/react'
import { GcpServiceAccountKey } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'
import { BaseServerIssue } from '../../../../schemas/issue.ts'

export type GcpAccessKeyNodeType = Node<
	{ accessKey: Pick<GcpServiceAccountKey, 'accessKeyId' | 'disabled'>; issueAttached?: BaseServerIssue },
	'gcpAccessKey'
>
type GcpAccessKeyNodeProps = NodeProps<GcpAccessKeyNodeType>

export const GcpSaAccessKeyNode = ({ data: { accessKey, issueAttached } }: GcpAccessKeyNodeProps) => (
	<BaseCredentialsNode
		label="Access Key"
		name={{ text: accessKey.accessKeyId }}
		issueAttached={issueAttached}
		isActive={!accessKey.disabled}
	/>
)
