import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Tabs } from 'antd'
import { RightSquareOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { ServerIdentity } from '../../schemas/identity.ts'
import { IdentitySidePanel } from './IdentitySidePanel/IdentitySidePanel.tsx'
import { DependenciesCollapseItemKeys } from './IdentitySidePanel/SidePanelDependencies.tsx'
import { CrossContextCollapseItemKeys } from './IdentitySidePanel/SidePanelCrossContext.tsx'
import { PermissionsCollapseItemKeys } from './IdentitySidePanel/SidePanelPermissions.tsx'
import { IdentityGraph } from './IdentityGraph/IdentityGraph.tsx'
import { IssuesView } from './IssuesView/IssuesView.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../RoutePaths.tsx'
import { IdentityGraphNodeType } from './IdentityGraph/identityGraphTypes.ts'
import { filterClosedIssues, filterOpenIssues } from '../../utils/issueUtils.ts'
import { AssociationTypeEnum } from '../../schemas/identities/usageAssociationItemSchema.ts'
import { ReactFlowProvider } from '@xyflow/react'
import { OwnershipCollapseItemKeys } from './IdentitySidePanel/SidePanelOwnership.tsx'
import { useActiveSidePanelKeysContext } from './ActiveSidePanelKeysContext.tsx'

const defaultSidePanelWidthPercentage = 33
const maxSidePanelWidthPercentage = 75
const minSidePanelWidthPercentage = 25

export type LastSelectedNode = {
	type: IdentityGraphNodeType['type']
	id: string
}

enum IdentityPageBodyTab {
	GRAPH = 'graph',
	ISSUES = 'issues',
	CLOSED_ISSUES = 'closed_issues',
}

export const IdentityPageBody = ({ identity }: { identity: ServerIdentity }) => {
	const [sidePanelWidthPercentage, setSidePanelWidthPercentage] = useState(defaultSidePanelWidthPercentage)
	const [isSidePanelAnimated, setIsSidePanelAnimated] = useState(false)
	const isResizingRef = useRef(false)
	const containerRef = useRef<HTMLDivElement>(null)
	const [lastSelectedNode, setLastSelectedNode] = useState<LastSelectedNode | null>(null)
	const [activeDependenciesKeys, setActiveDependenciesKeys] = useState<DependenciesCollapseItemKeys[]>([])
	const [activeCrossContextKeys, setActiveCrossContextKeys] = useState<CrossContextCollapseItemKeys[]>([])
	const [activePermissionsKeys, setActivePermissionsKeys] = useState<PermissionsCollapseItemKeys[]>([])
	const [activeUsageLogsGroupedKeys, setActiveUsageLogsGroupedKeys] = useState<(keyof typeof AssociationTypeEnum)[]>(
		[],
	)
	const [activeOwnershipKeys, setActiveOwnershipKeys] = useState<OwnershipCollapseItemKeys[]>([])
	const [activeTab, setActiveTab] = useState<IdentityPageBodyTab>(IdentityPageBodyTab.GRAPH)
	const { issueId } = useSearch({ from: RoutePaths.Identity })
	const navigate = useNavigate({ from: RoutePaths.Identity })
	const { setActiveSidePanelKeys } = useActiveSidePanelKeysContext()

	const ensureIssuesSidePanelIsOpen = useCallback(() => {
		setActiveSidePanelKeys((previousSidePanelKeys) => {
			if (previousSidePanelKeys.includes('Issues')) {
				return previousSidePanelKeys
			}
			return ['Issues']
		})
	}, [setActiveSidePanelKeys])

	useEffect(() => {
		if (issueId) {
			if (
				filterOpenIssues(identity.issues)
					.map((issue) => issue.id)
					.includes(issueId)
			) {
				setActiveTab(IdentityPageBodyTab.ISSUES)
				ensureIssuesSidePanelIsOpen()
			} else {
				setActiveTab(IdentityPageBodyTab.CLOSED_ISSUES)
				ensureIssuesSidePanelIsOpen()
			}
		} else {
			setActiveTab(IdentityPageBodyTab.GRAPH)
		}
	}, [issueId, identity])

	const onTabChange = (newTab: string) => {
		switch (newTab as IdentityPageBodyTab) {
			case IdentityPageBodyTab.GRAPH:
				void navigate({
					search: (prev) => {
						const newSearch = { ...prev }
						if (newSearch.issueId) {
							delete newSearch.issueId
						}
						return newSearch
					},
				})
				break
			case IdentityPageBodyTab.ISSUES:
			case IdentityPageBodyTab.CLOSED_ISSUES: {
				const relevantIssues =
					(newTab as IdentityPageBodyTab) === IdentityPageBodyTab.ISSUES
						? filterOpenIssues(identity.issues)
						: filterClosedIssues(identity.issues)
				const firstIssueId = relevantIssues[0]?.id
				if (firstIssueId) {
					void navigate({ search: (prev) => ({ ...prev, issueId: firstIssueId }) })
				}
				break
			}
		}
	}

	const openSidePanel = useCallback(() => {
		setSidePanelWidthPercentage((currentWidthPercentage) => {
			return currentWidthPercentage || defaultSidePanelWidthPercentage
		})
	}, [setSidePanelWidthPercentage])

	useEffect(() => {
		if (!lastSelectedNode) {
			return
		}

		const resetTimeout = setTimeout(() => {
			setLastSelectedNode(null)
		}, 1000)
		return () => {
			clearTimeout(resetTimeout)
		}
	}, [lastSelectedNode])

	useEffect(() => {
		const onMouseUp = () => {
			isResizingRef.current = false
		}

		const onMouseMove = (e: MouseEvent) => {
			if (!isResizingRef.current || !containerRef.current) {
				return
			}

			e.preventDefault()

			const viewportWidth = document.body.offsetWidth
			const cursorRightOffset = viewportWidth - e.clientX
			const { right: containerRight } = containerRef.current.getBoundingClientRect()
			const containerRightOffset = viewportWidth - containerRight
			const cursorRelativeRightOffset = cursorRightOffset - containerRightOffset
			const containerWidth = containerRef.current.offsetWidth
			const cursorRelativePercentageRightOffset = (cursorRelativeRightOffset / containerWidth) * 100
			if (
				cursorRelativePercentageRightOffset <= maxSidePanelWidthPercentage &&
				cursorRelativePercentageRightOffset >= minSidePanelWidthPercentage
			) {
				setSidePanelWidthPercentage(cursorRelativePercentageRightOffset)
			}
		}

		document.addEventListener('mouseup', onMouseUp)
		document.addEventListener('mousemove', onMouseMove)

		return () => {
			document.removeEventListener('mouseup', onMouseUp)
			document.removeEventListener('mousemove', onMouseMove)
		}
	}, [])

	const iconClassName = cx('transition-transform duration-500', {
		'rotate-180': sidePanelWidthPercentage === 0,
	})

	const sidePanelContainerClassName = cx('relative shrink-0 border-border-secondary', {
		'transition-[width] duration-300': isSidePanelAnimated,
	})
	const tabItems = useMemo(() => {
		const items: ComponentProps<typeof Tabs>['items'] = [
			{
				key: IdentityPageBodyTab.GRAPH,
				label: 'Graph',
				className: 'h-full',
				children: (
					<ReactFlowProvider>
						<IdentityGraph
							identity={identity}
							setLastSelectedNode={setLastSelectedNode}
							openSidePanel={openSidePanel}
							setActiveDependenciesKeys={setActiveDependenciesKeys}
							setActiveCrossContextKeys={setActiveCrossContextKeys}
							setActivePermissionsKeys={setActivePermissionsKeys}
							setActiveUsageLogsGroupedKeys={setActiveUsageLogsGroupedKeys}
							setActiveOwnershipKeys={setActiveOwnershipKeys}
						/>
					</ReactFlowProvider>
				),
			},
		]

		const openIssues = filterOpenIssues(identity.issues)
		const closedIssues = filterClosedIssues(identity.issues)

		if (openIssues.length) {
			items.push({
				key: IdentityPageBodyTab.ISSUES,
				label: `Issues (${openIssues.length})`,
				children: <IssuesView issues={openIssues} identity={identity} />,
			})
		}

		if (closedIssues.length) {
			items.push({
				key: IdentityPageBodyTab.CLOSED_ISSUES,
				label: `Resolved & Suppressed Issues (${closedIssues.length})`,
				children: <IssuesView issues={closedIssues} identity={identity} />,
			})
		}
		return items
	}, [
		identity,
		setLastSelectedNode,
		openSidePanel,
		setActiveDependenciesKeys,
		setActiveCrossContextKeys,
		setActivePermissionsKeys,
	])

	return (
		<div
			className="h-full flex divide-x bg-surface-primary border border-border-secondary rounded min-h-0"
			ref={containerRef}
		>
			<div className="grow">
				<Tabs
					destroyInactiveTabPane
					defaultActiveKey="Open"
					className="h-full full-height-tabs"
					onChange={onTabChange}
					activeKey={activeTab}
					items={tabItems}
					tabBarStyle={{ marginBottom: 0, paddingRight: 12, paddingLeft: 24 }}
					tabBarExtraContent={{
						right: (
							<Button
								type="text"
								size="large"
								icon={<RightSquareOutlined className={iconClassName} />}
								onClick={() =>
									setSidePanelWidthPercentage((currentWidth) =>
										currentWidth === 0 ? defaultSidePanelWidthPercentage : 0,
									)
								}
								onMouseEnter={() => {
									setIsSidePanelAnimated(true)
								}}
								onMouseLeave={() => {
									setIsSidePanelAnimated(false)
								}}
							/>
						),
					}}
				/>
			</div>
			<div className={sidePanelContainerClassName} style={{ width: `${sidePanelWidthPercentage}%` }}>
				<div
					className="absolute bg-transparent cursor-col-resize w-[10px] top-0 left-0 h-full"
					onMouseDown={(e) => {
						isResizingRef.current = true
						e.preventDefault()
					}}
				/>
				<IdentitySidePanel
					identity={identity}
					lastSelectedNode={lastSelectedNode}
					activeDependenciesKeys={activeDependenciesKeys}
					setActiveDependenciesKeys={setActiveDependenciesKeys}
					activeCrossContextKeys={activeCrossContextKeys}
					setActiveCrossContextKeys={setActiveCrossContextKeys}
					activePermissionsKeys={activePermissionsKeys}
					setActivePermissionsKeys={setActivePermissionsKeys}
					activeUsageLogsGroupedKeys={activeUsageLogsGroupedKeys}
					setActiveUsageLogsGroupedKeys={setActiveUsageLogsGroupedKeys}
					activeOwnershipKeys={activeOwnershipKeys}
					setActiveOwnershipKeys={setActiveOwnershipKeys}
				/>
			</div>
		</div>
	)
}
