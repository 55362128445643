import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import cx from 'classnames'

export type CollapsibleSiderMenuHeaderProps = {
	isCollapsed: boolean
	title: string
	toggleCollapse: () => void
}

export const CollapsibleSiderMenuHeader = ({ isCollapsed, title, toggleCollapse }: CollapsibleSiderMenuHeaderProps) => {
	const siderHeaderClassName = cx(
		'h-14 px-2 mx-2 text-textIcon-dark flex items-center border-b border-border-secondaryDark mb-2',
		{
			'justify-between': !isCollapsed,
			'justify-center': isCollapsed,
		},
	)

	return (
		<div className={siderHeaderClassName}>
			{!isCollapsed && <div className="text-nowrap">{title}</div>}
			<Button
				ghost
				icon={isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
				onClick={toggleCollapse}
			/>
		</div>
	)
}
