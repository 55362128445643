import cx from 'classnames'
import { Avatar, Image } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { FronteggUser } from '../../api/fronteggApi.ts'

export const FronteggUserAvatar = ({ user, compact }: { user?: FronteggUser; compact?: boolean }) => {
	const name = user?.name || user?.email || 'Unknown user'
	const containerClassName = cx('flex items-center', { 'gap-1': compact, 'gap-2': !compact })
	return (
		<div className={containerClassName}>
			<Avatar
				size={compact ? 20 : 'default'}
				className="shrink-0"
				icon={
					user?.profilePictureUrl ? (
						<Image preview={false} src={user.profilePictureUrl} referrerPolicy="no-referrer" />
					) : (
						<UserOutlined />
					)
				}
			/>
			{name}
		</div>
	)
}
