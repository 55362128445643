import { Node, NodeProps } from '@xyflow/react'
import { ServerSnowflakeRoleXc } from '../../../../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'

export type SnowflakeRolesNodeType = Node<{ roles: ServerSnowflakeRoleXc[] }, 'snowflakeRoles'>
type SnowflakeRolesNodeProps = NodeProps<SnowflakeRolesNodeType>

export const SnowflakeRolesNode = ({ data: { roles } }: SnowflakeRolesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'snowflakeRole')

	const badgeTooltipContent = roles.map((role) => <span key={role.name}>{role.name}</span>)

	return (
		<BasePermissionNode
			label="Snowflake Roles"
			isHighlighted={isHighlighted}
			groupProps={{ count: roles.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
