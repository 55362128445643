import { Node, NodeProps } from '@xyflow/react'
import { ServerGcpServiceAccountDemoDomainWideDelegation } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema'
import GoogleWorkspaceIcon from '../../../../assets/google_workspace_logo_16.svg?react'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode'
import { NodeGroupBadge } from '../common/NodeGroupBadge'

export type GcpWorkspaceEnvsNodeType = Node<
	{
		workspaces: ServerGcpServiceAccountDemoDomainWideDelegation[]
	},
	'gcpWorkspaceEnvs'
>

export const GcpWorkspaceEnvsNode = ({ data: { workspaces } }: NodeProps<GcpWorkspaceEnvsNodeType>) => {
	const badgeTooltipContent = workspaces.map((workspace) => (
		<span key={workspace.id}>{workspace.google_workspace_env_name || 'Unknown workspace'}</span>
	))

	const maxPriority = Math.max(...workspaces.map((workspace) => workspace.priority ?? 0))

	return (
		<BaseEnvironmentNode
			label="Google Workspaces"
			icon={<GoogleWorkspaceIcon />}
			tooltipTitle="Google Workspaces"
			priority={maxPriority || undefined}
		>
			<NodeGroupBadge
				count={workspaces.length}
				nodeColorType="environment"
				tooltipContent={badgeTooltipContent}
			/>
		</BaseEnvironmentNode>
	)
}
