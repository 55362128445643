import { awsAccountsInfo } from './awsAccountsInfoData.ts'
import {
	endpointAccessXc,
	kubernetesResourcesXc1,
	kubernetesResourcesXc2,
	kubernetesResourcesXc4,
} from './crossContextData.ts'
import { MockDemoTag, ServerTagInput } from '../../schemas/tags.ts'
import { ServerAwsIdentityPolicyInput, ServerManagedPolicyInput } from '../../schemas/identities/awsPoliciesSchema.ts'
import { getRecentlyCreatedDate } from '../utils.ts'
import { ServerAwsIamChangeLogInput } from '../../schemas/identities/awsIamChangeLogSchema.ts'
import { DemoServerAwsIamIaCDataInput, ServerAwsUsageLogInput } from '../../schemas/identities/awsIamUserSchema.ts'
import { ServerUsageLogGroupedInput } from '../../schemas/identities/groupedUsageLogsSchema.ts'
import { IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { DemoServerIdentityInput } from '../schemas.ts'

export const policyTags = {
	CREDENTIAL_EXPOSURE: {
		id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
		name: 'CREDENTIAL_EXPOSURE',
		impact: 3,
		source: 'TOKEN',
		probability: null,
		related_resource_type: 'AWS',
	},
	RESOURCE_EXPOSURE: {
		id: 'e3146038-31dc-4a9f-955b-f134fdf09eb0',
		name: 'RESOURCE_EXPOSURE',
		impact: 3,
		source: 'TOKEN',
		probability: null,
		related_resource_type: 'AWS',
	},
	DATA_ACCESS: {
		id: 'a92fc363-8b88-415e-862a-8408d28d1d3a',
		name: 'DATA_ACCESS',
		impact: 3,
		source: 'TOKEN',
		probability: null,
		related_resource_type: 'AWS',
	},
	ADMIN: {
		id: '8b87eec5-a9e4-4eba-9d7e-43a974673580',
		name: 'ADMIN',
		impact: 3,
		source: 'TOKEN',
		probability: null,
		related_resource_type: 'AWS',
	},
	POSSIBLE_PRIVILEGE_ESCALATION: {
		id: '31cbaf01-ecc0-4b7b-801d-0a91f9d6fe96',
		name: 'POSSIBLE_PRIVILEGE_ESCALATION',
		impact: 4,
		source: 'TOKEN',
		probability: null,
		related_resource_type: 'AWS',
	},
} satisfies Record<string, ServerTagInput>

const awsIamIaCData = {
	'1': {
		creator: 'gabrielabeard',
		github_environment: {
			id: 'f47ac10b-58cc-4372-a567-0e02b2c3d479',
			name: 'terraform-aws-iac',
			customer_id: 'b5c67892-9f4d-4b85-8d31-f3e1a4f7b9e2',
			org_id: 'a1b2c3d4-e5f6-47a8-b9c0-1d2e3f4a5b6c',
			is_enterprise: false,
			enabled: true,
		},
		run_id: '1512345678',
		run_day: '2025-02-19',
		event_name: 'AttachUserPolicy',
		description:
			'Provisioning multiple IAM users and roles via Terraform to support Machine-to-Machine communication. Allow applications to write to S3, manage queue processing, and interact securely with other cloud resources',
		terraform_code: `resource 'aws_iam_user' 'ci_user' {
  name = 'ci-user'
}

resource 'aws_iam_policy' 'm2m_policy' {
  name        = 'M2MAccessPolicy'
  description = 'Policy to allow applications to write to S3, manage queue processing, and interact with cloud resources'

  policy = jsonencode({
    "Version": "2012-10-17",
    "Statement": [
      {
        "Action": ["s3:PutObject", "s3:GetObject", "s3:ListBucket"],
        "Effect": "Allow",
        "Resource": "arn:aws:s3:::my-app-bucket/*"
      },
      {
        "Action": ["sqs:SendMessage", "sqs:ReceiveMessage", "sqs:DeleteMessage"],
        "Effect": "Allow",
        "Resource": "arn:aws:sqs:us-east-1:123456789012:my-queue"
      }
    ]
  })
}
`,
	},
	'2': {
		creator: 'lloyd-olson',
		github_environment: {
			id: 'f47ac10b-58cc-4372-a567-0e02b2c3d479',
			name: 'terraform-aws-iac',
			customer_id: 'b5c67892-9f4d-4b85-8d31-f3e1a4f7b9e2',
			org_id: 'a1b2c3d4-e5f6-47a8-b9c0-1d2e3f4a5b6c',
			is_enterprise: false,
			enabled: true,
		},
		run_id: '1623456789',
		run_day: '2025-01-02',
		event_name: 'AttachRolePolicy',
		description:
			'Provisioning permissions to grant developers and CI/CD pipelines controlled access to production environments.',
		terraform_code: `resource 'aws_iam_policy' 'dev_ci_cd_policy' {
  name        = 'DevCICDAccessPolicy'
  description = 'Grant developers and CI/CD pipelines controlled access to production'

  policy = jsonencode({
    "Version": "2012-10-17",
    "Statement": [
      {
        "Action": ["ec2:DescribeInstances", "lambda:InvokeFunction", "s3:ListBucket"],
        "Effect": "Allow",
        "Resource": "*"
      }
    ]
  })
}

resource 'aws_iam_user_policy_attachment' 'dev_ci_cd_attachment' {
  user       = aws_iam_user.ci_user.name
  policy_arn = aws_iam_policy.dev_ci_cd_policy.arn
}`,
	},
	'3': {
		creator: 'gabrielabeard',
		github_environment: {
			id: 'f47ac10b-58cc-4372-a567-0e02b2c3d479',
			name: 'terraform-aws-iac',
			customer_id: 'b5c67892-9f4d-4b85-8d31-f3e1a4f7b9e2',
			org_id: 'a1b2c3d4-e5f6-47a8-b9c0-1d2e3f4a5b6c',
			is_enterprise: false,
			enabled: true,
		},
		run_id: '1734567890',
		run_day: '2024-12-16',
		event_name: 'UpdateUser',
		description: 'Expanding IAM identity management to improve security and compliance.',
		terraform_code: `resource 'aws_iam_group' 'compliance_team' {
  name = 'compliance-team'
}

resource 'aws_iam_group_policy_attachment' 'compliance_attachment' {
  group      = aws_iam_group.compliance_team.name
  policy_arn = 'arn:aws:iam::aws:policy/SecurityAudit'
}

resource 'aws_iam_user_group_membership' 'ci_user_compliance' {
  user   = aws_iam_user.ci_user.name
  groups = [aws_iam_group.compliance_team.name]
}`,
	},
	'4': {
		creator: 'lloyd-olson',
		github_environment: {
			id: 'f47ac10b-58cc-4372-a567-0e02b2c3d479',
			name: 'terraform-aws-iac',
			customer_id: 'b5c67892-9f4d-4b85-8d31-f3e1a4f7b9e2',
			org_id: 'a1b2c3d4-e5f6-47a8-b9c0-1d2e3f4a5b6c',
			is_enterprise: false,
			enabled: true,
		},
		run_id: '1845678901',
		run_day: '2024-10-12',
		event_name: 'DeactivateMFADevice',
		description: 'Security maintenance',
		terraform_code: `resource 'aws_iam_account_password_policy' 'strict_password_policy' {
  minimum_password_length        = 14
  require_symbols                = true
  require_numbers                = true
  require_uppercase_characters   = true
  require_lowercase_characters   = true
  allow_users_to_change_password = true
}

resource 'aws_iam_user_policy' 'mfa_enforcement' {
  name   = 'MFAEnforcementPolicy'
  user   = aws_iam_user.ci_user.name
  policy = jsonencode({
    "Version": "2012-10-17",
    "Statement": [
      {
        "Effect": "Deny",
        "Action": "*",
        "Resource": "*",
        "Condition": {
          "BoolIfExists": {
            "aws:MultiFactorAuthPresent": "false"
          }
        }
      }
    ]
  })
}`,
	},
} satisfies Record<string, DemoServerAwsIamIaCDataInput>

export const awsPolicies = {
	AdministratorAccess: {
		PolicyName: 'AdministratorAccess',
		PolicyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
		RiskTags: [
			policyTags.POSSIBLE_PRIVILEGE_ESCALATION,
			policyTags.RESOURCE_EXPOSURE,
			policyTags.DATA_ACCESS,
			policyTags.CREDENTIAL_EXPOSURE,
			policyTags.ADMIN,
		],
		PolicyInfo: null,
		CustomerManaged: false,
	},
	PowerUserAccess: {
		PolicyName: 'PowerUserAccess',
		PolicyArn: 'arn:aws:iam::871744574088:policy/PowerUserAccess',
		RiskTags: [policyTags.DATA_ACCESS, policyTags.POSSIBLE_PRIVILEGE_ESCALATION, policyTags.CREDENTIAL_EXPOSURE],
		PolicyInfo: {
			PolicyName: 'PowerUserAccess',
			PolicyId: 'ANPAV6A91DMRCNPBKAPF',
			Arn: 'arn:aws:iam::871744574088:policy/PowerUserAccess',
			Path: '/',
			DefaultVersionId: 'v10',
			AttachmentCount: 5,
			PermissionsBoundaryUsageCount: 0,
			IsAttachable: true,
			CreateDate: '2015-05-29T21:20:01Z',
			UpdateDate: '2020-08-26T10:19:45Z',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Effect: 'Allow',
								NotAction: ['iam:*', 'organizations:*', 'account:*'],
								Resource: '*',
							},
							{
								Effect: 'Allow',
								Action: [
									'iam:CreateServiceLinkedRole',
									'iam:DeleteServiceLinkedRole',
									'iam:ListRoles',
									'organizations:DescribeOrganization',
									'account:ListRegions',
									'account:GetAccountInformation',
								],
								Resource: '*',
							},
						],
					},
					VersionId: 'v10',
					IsDefaultVersion: true,
					CreateDate: '2020-08-26T10:19:45Z',
				},
			],
		},
		CustomerManaged: true,
	},
	IAMFullAccess: {
		PolicyName: 'IAMFullAccess',
		PolicyArn: 'arn:aws:iam::871744574088:policy/IAMFullAccess',
		RiskTags: [policyTags.DATA_ACCESS, policyTags.POSSIBLE_PRIVILEGE_ESCALATION],
		PolicyInfo: {
			PolicyName: 'IAMFullAccess',
			PolicyId: 'ANPAV8MPQ5FGL5RCKASF',
			Arn: 'arn:aws:iam::871744574088:policy/IAMFullAccess',
			Path: '/',
			DefaultVersionId: 'v10',
			AttachmentCount: 168,
			PermissionsBoundaryUsageCount: 0,
			IsAttachable: true,
			CreateDate: '2016-09-07T21:27:05Z',
			UpdateDate: '2022-12-21T12:18:48Z',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Effect: 'Allow',
								Action: [
									'iam:*',
									'organizations:DescribeAccount',
									'organizations:DescribeOrganization',
									'organizations:DescribeOrganizationalUnit',
									'organizations:DescribePolicy',
									'organizations:ListChildren',
									'organizations:ListParents',
									'organizations:ListPoliciesForTarget',
									'organizations:ListRoots',
									'organizations:ListPolicies',
									'organizations:ListTargetsForPolicy',
								],
								Resource: '*',
							},
						],
					},
					VersionId: 'v10',
					IsDefaultVersion: true,
					CreateDate: '2022-12-21T12:18:48Z',
				},
			],
		},
		CustomerManaged: true,
	},
	eks_service_account_policy_for_pods: {
		PolicyName: 'eks-service-account-policy-for-pods',
		PolicyArn: 'arn:aws:iam::328041475888:policy/eks-service-account-policy-for-pods',
		RiskTags: [],
		PolicyInfo: {
			PolicyName: 'eks-service-account-policy-for-pods',
			PolicyId: 'ANPAUYYGLV4YANGKGYZAY',
			Arn: 'arn:aws:iam::328041475888:policy/eks-service-account-policy-for-pods',
			Path: '/',
			DefaultVersionId: 'v1',
			AttachmentCount: 3,
			PermissionsBoundaryUsageCount: 0,
			IsAttachable: true,
			CreateDate: '2024-03-25T21:04:50Z',
			UpdateDate: '2024-03-25T21:04:50Z',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'ecr:GetDownloadUrlForLayer',
									'ecr:BatchGetImage',
									'ecr:BatchCheckLayerAvailability',
								],
								NotAction: null,
								Resource: 'arn:aws:ecr:us-east-1:508357495568:repository/prod',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									's3:*',
									's3:PutObject',
									's3:PutObjectAcl',
									's3:GetObject',
									's3:GetObjectAcl',
									's3:DeleteObject',
								],
								NotAction: null,
								Resource: ['arn:aws:s3:::prod-eks-priv-bucket', 'arn:aws:s3:::prod-eks-priv-bucket/*'],
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: 'secretsmanager:GetSecretValue',
								NotAction: null,
								Resource: 'arn:aws:secretsmanager:us-east-1:328041475888:secret:prod-eks-secret-mVS5vp',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v1',
					IsDefaultVersion: true,
					CreateDate: '2024-03-25T21:04:50Z',
				},
			],
		},
		CustomerManaged: true,
	},
	AmazonEC2FullAccess: {
		PolicyName: 'AmazonEC2FullAccess',
		PolicyArn: 'arn:aws:iam::aws:policy/AmazonEC2FullAccess',
		RiskTags: [policyTags.CREDENTIAL_EXPOSURE, policyTags.RESOURCE_EXPOSURE, policyTags.DATA_ACCESS],
		PolicyInfo: {
			PolicyName: 'AmazonEC2FullAccess',
			PolicyId: 'ANPAOI1T4380R6PUNN5I',
			Arn: 'arn:aws:iam::aws:policy/AmazonEC2FullAccess',
			Path: '/',
			DefaultVersionId: 'v5',
			AttachmentCount: 1,
			PermissionsBoundaryUsageCount: 0,
			IsAttachable: true,
			CreateDate: '2015-02-06T18:40:00Z',
			UpdateDate: '2018-11-27T02:16:30Z',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Action: 'ec2:*',
								Effect: 'Allow',
								Resource: '*',
							},
							{
								Effect: 'Allow',
								Action: 'elasticloadbalancing:*',
								Resource: '*',
							},
							{
								Effect: 'Allow',
								Action: 'cloudwatch:*',
								Resource: '*',
							},
							{
								Effect: 'Allow',
								Action: 'autoscaling:*',
								Resource: '*',
							},
							{
								Effect: 'Allow',
								Action: 'iam:CreateServiceLinkedRole',
								Resource: '*',
								Condition: {
									StringEquals: {
										'iam:AWSServiceName': [
											'autoscaling.amazonaws.com',
											'ec2scheduled.amazonaws.com',
											'elasticloadbalancing.amazonaws.com',
											'spot.amazonaws.com',
											'spotfleet.amazonaws.com',
											'transitgateway.amazonaws.com',
										],
									},
								},
							},
						],
					},
					VersionId: 'v5',
					IsDefaultVersion: true,
					CreateDate: '2023-05-24T17:19:30Z',
				},
			],
		},
		CustomerManaged: false,
	},
	AWSCodeBuildAdminAccess: {
		PolicyName: 'AWSCodeBuildAdminAccess',
		PolicyArn: 'arn:aws:iam::aws:policy/AWSCodeBuildAdminAccess',
		RiskTags: [policyTags.POSSIBLE_PRIVILEGE_ESCALATION, policyTags.CREDENTIAL_EXPOSURE],
		PolicyInfo: {
			PolicyName: 'AWSCodeBuildAdminAccess',
			PolicyId: 'ANPAJQJGIOIE3CD2TQXDS',
			Arn: 'arn:aws:iam::aws:policy/AWSCodeBuildAdminAccess',
			Path: '/',
			DefaultVersionId: 'v13',
			AttachmentCount: 1,
			PermissionsBoundaryUsageCount: 0,
			IsAttachable: true,
			CreateDate: '2016-12-01T19:04:44Z',
			UpdateDate: '2023-07-31T23:06:23Z',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: 'AWSServicesAccess',
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'elasticfilesystem:DescribeFileSystems',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									'logs:GetLogEvents',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CWLDeleteLogGroupAccess',
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SSMParameterWriteAccess',
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SSMStartSessionAccess',
								Effect: 'Allow',
								Action: ['ssm:StartSession'],
								NotAction: null,
								Resource: 'arn:aws:ecs:*:*:task/*/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarConnectionsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-connections:CreateConnection',
									'codestar-connections:DeleteConnection',
									'codestar-connections:UpdateConnectionInstallation',
									'codestar-connections:TagResource',
									'codestar-connections:UntagResource',
									'codestar-connections:ListConnections',
									'codestar-connections:ListInstallationTargets',
									'codestar-connections:ListTagsForResource',
									'codestar-connections:GetConnection',
									'codestar-connections:GetIndividualAccessToken',
									'codestar-connections:GetInstallationUrl',
									'codestar-connections:PassConnection',
									'codestar-connections:StartOAuthHandshake',
									'codestar-connections:UseConnection',
								],
								NotAction: null,
								Resource: 'arn:aws:codestar-connections:*:*:connection/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:CreateNotificationRule',
									'codestar-notifications:DescribeNotificationRule',
									'codestar-notifications:UpdateNotificationRule',
									'codestar-notifications:DeleteNotificationRule',
									'codestar-notifications:Subscribe',
									'codestar-notifications:Unsubscribe',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: {
									StringLike: {
										'codestar-notifications:NotificationsForResource': 'arn:aws:codebuild:*',
									},
								},
							},
							{
								Sid: 'CodeStarNotificationsListAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:ListNotificationRules',
									'codestar-notifications:ListEventTypes',
									'codestar-notifications:ListTargets',
									'codestar-notifications:ListTagsforResource',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsSNSTopicCreateAccess',
								Effect: 'Allow',
								Action: ['sns:CreateTopic', 'sns:SetTopicAttributes'],
								NotAction: null,
								Resource: 'arn:aws:sns:*:*:codestar-notifications*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SNSTopicListAccess',
								Effect: 'Allow',
								Action: ['sns:ListTopics', 'sns:GetTopicAttributes'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsChatbotAccess',
								Effect: 'Allow',
								Action: [
									'chatbot:DescribeSlackChannelConfigurations',
									'chatbot:ListMicrosoftTeamsChannelConfigurations',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v13',
					IsDefaultVersion: true,
					CreateDate: '2023-07-31T23:06:23Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'elasticfilesystem:DescribeFileSystems',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									'logs:GetLogEvents',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:StartSession'],
								NotAction: null,
								Resource: 'arn:aws:ecs:*:*:task/*/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarConnectionsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-connections:CreateConnection',
									'codestar-connections:DeleteConnection',
									'codestar-connections:UpdateConnectionInstallation',
									'codestar-connections:TagResource',
									'codestar-connections:UntagResource',
									'codestar-connections:ListConnections',
									'codestar-connections:ListInstallationTargets',
									'codestar-connections:ListTagsForResource',
									'codestar-connections:GetConnection',
									'codestar-connections:GetIndividualAccessToken',
									'codestar-connections:GetInstallationUrl',
									'codestar-connections:PassConnection',
									'codestar-connections:StartOAuthHandshake',
									'codestar-connections:UseConnection',
								],
								NotAction: null,
								Resource: 'arn:aws:codestar-connections:*:*:connection/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:CreateNotificationRule',
									'codestar-notifications:DescribeNotificationRule',
									'codestar-notifications:UpdateNotificationRule',
									'codestar-notifications:DeleteNotificationRule',
									'codestar-notifications:Subscribe',
									'codestar-notifications:Unsubscribe',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: {
									StringLike: {
										'codestar-notifications:NotificationsForResource': 'arn:aws:codebuild:*',
									},
								},
							},
							{
								Sid: 'CodeStarNotificationsListAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:ListNotificationRules',
									'codestar-notifications:ListEventTypes',
									'codestar-notifications:ListTargets',
									'codestar-notifications:ListTagsforResource',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsSNSTopicCreateAccess',
								Effect: 'Allow',
								Action: ['sns:CreateTopic', 'sns:SetTopicAttributes'],
								NotAction: null,
								Resource: 'arn:aws:sns:*:*:codestar-notifications*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SNSTopicListAccess',
								Effect: 'Allow',
								Action: ['sns:ListTopics', 'sns:GetTopicAttributes'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsChatbotAccess',
								Effect: 'Allow',
								Action: ['chatbot:DescribeSlackChannelConfigurations'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v12',
					IsDefaultVersion: false,
					CreateDate: '2020-09-14T16:03:39Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'elasticfilesystem:DescribeFileSystems',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									'logs:GetLogEvents',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:StartSession'],
								NotAction: null,
								Resource: 'arn:aws:ecs:*:*:task/*/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:CreateNotificationRule',
									'codestar-notifications:DescribeNotificationRule',
									'codestar-notifications:UpdateNotificationRule',
									'codestar-notifications:DeleteNotificationRule',
									'codestar-notifications:Subscribe',
									'codestar-notifications:Unsubscribe',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: {
									StringLike: {
										'codestar-notifications:NotificationsForResource': 'arn:aws:codebuild:*',
									},
								},
							},
							{
								Sid: 'CodeStarNotificationsListAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:ListNotificationRules',
									'codestar-notifications:ListEventTypes',
									'codestar-notifications:ListTargets',
									'codestar-notifications:ListTagsforResource',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsSNSTopicCreateAccess',
								Effect: 'Allow',
								Action: ['sns:CreateTopic', 'sns:SetTopicAttributes'],
								NotAction: null,
								Resource: 'arn:aws:sns:*:*:codestar-notifications*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SNSTopicListAccess',
								Effect: 'Allow',
								Action: ['sns:ListTopics', 'sns:GetTopicAttributes'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsChatbotAccess',
								Effect: 'Allow',
								Action: ['chatbot:DescribeSlackChannelConfigurations'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v11',
					IsDefaultVersion: false,
					CreateDate: '2020-07-21T04:22:37Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'elasticfilesystem:DescribeFileSystems',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									'logs:GetLogEvents',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:CreateNotificationRule',
									'codestar-notifications:DescribeNotificationRule',
									'codestar-notifications:UpdateNotificationRule',
									'codestar-notifications:DeleteNotificationRule',
									'codestar-notifications:Subscribe',
									'codestar-notifications:Unsubscribe',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: {
									StringLike: {
										'codestar-notifications:NotificationsForResource': 'arn:aws:codebuild:*',
									},
								},
							},
							{
								Sid: 'CodeStarNotificationsListAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:ListNotificationRules',
									'codestar-notifications:ListEventTypes',
									'codestar-notifications:ListTargets',
									'codestar-notifications:ListTagsforResource',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsSNSTopicCreateAccess',
								Effect: 'Allow',
								Action: ['sns:CreateTopic', 'sns:SetTopicAttributes'],
								NotAction: null,
								Resource: 'arn:aws:sns:*:*:codestar-notifications*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SNSTopicListAccess',
								Effect: 'Allow',
								Action: ['sns:ListTopics', 'sns:GetTopicAttributes'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsChatbotAccess',
								Effect: 'Allow',
								Action: ['chatbot:DescribeSlackChannelConfigurations'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v10',
					IsDefaultVersion: false,
					CreateDate: '2020-04-02T17:21:40Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'elasticfilesystem:DescribeFileSystems',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									'logs:GetLogEvents',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:CreateNotificationRule',
									'codestar-notifications:DescribeNotificationRule',
									'codestar-notifications:UpdateNotificationRule',
									'codestar-notifications:DeleteNotificationRule',
									'codestar-notifications:Subscribe',
									'codestar-notifications:Unsubscribe',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: {
									StringLike: {
										'codestar-notifications:NotificationsForResource': 'arn:aws:codebuild:*',
									},
								},
							},
							{
								Sid: 'CodeStarNotificationsListAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:ListNotificationRules',
									'codestar-notifications:ListEventTypes',
									'codestar-notifications:ListTargets',
									'codestar-notifications:ListTagsforResource',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsSNSTopicCreateAccess',
								Effect: 'Allow',
								Action: ['sns:CreateTopic', 'sns:SetTopicAttributes'],
								NotAction: null,
								Resource: 'arn:aws:sns:*:*:codestar-notifications*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SNSTopicListAccess',
								Effect: 'Allow',
								Action: ['sns:ListTopics', 'sns:GetTopicAttributes'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v9',
					IsDefaultVersion: false,
					CreateDate: '2020-02-06T20:26:30Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									'logs:GetLogEvents',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:CreateNotificationRule',
									'codestar-notifications:DescribeNotificationRule',
									'codestar-notifications:UpdateNotificationRule',
									'codestar-notifications:DeleteNotificationRule',
									'codestar-notifications:Subscribe',
									'codestar-notifications:Unsubscribe',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: {
									StringLike: {
										'codestar-notifications:NotificationsForResource': 'arn:aws:codebuild:*',
									},
								},
							},
							{
								Sid: 'CodeStarNotificationsListAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:ListNotificationRules',
									'codestar-notifications:ListEventTypes',
									'codestar-notifications:ListTargets',
									'codestar-notifications:ListTagsforResource',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsSNSTopicCreateAccess',
								Effect: 'Allow',
								Action: ['sns:CreateTopic', 'sns:SetTopicAttributes'],
								NotAction: null,
								Resource: 'arn:aws:sns:*:*:codestar-notifications*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SNSTopicListAccess',
								Effect: 'Allow',
								Action: ['sns:ListTopics', 'sns:GetTopicAttributes'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v8',
					IsDefaultVersion: false,
					CreateDate: '2019-11-05T22:12:30Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									'logs:GetLogEvents',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsReadWriteAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:CreateNotificationRule',
									'codestar-notifications:DescribeNotificationRule',
									'codestar-notifications:UpdateNotificationRule',
									'codestar-notifications:DeleteNotificationRule',
									'codestar-notifications:Subscribe',
									'codestar-notifications:Unsubscribe',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: {
									StringLike: {
										'codestar-notifications:NotificationsForResource': 'arn:aws:codebuild:*',
									},
								},
							},
							{
								Sid: 'CodeStarNotificationsListAccess',
								Effect: 'Allow',
								Action: [
									'codestar-notifications:ListNotificationRules',
									'codestar-notifications:ListTargets',
									'codestar-notifications:ListTagsforResource',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'CodeStarNotificationsSNSTopicCreateAccess',
								Effect: 'Allow',
								Action: ['sns:CreateTopic', 'sns:SetTopicAttributes'],
								NotAction: null,
								Resource: 'arn:aws:sns:*:*:codestar-notifications*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: 'SNSTopicListAccess',
								Effect: 'Allow',
								Action: ['sns:ListTopics', 'sns:GetTopicAttributes'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v7',
					IsDefaultVersion: false,
					CreateDate: '2019-11-05T20:40:27Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									'logs:GetLogEvents',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v6',
					IsDefaultVersion: false,
					CreateDate: '2018-11-15T21:21:56Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									'events:DeleteRule',
									'events:DescribeRule',
									'events:DisableRule',
									'events:EnableRule',
									'events:ListTargetsByRule',
									'events:ListRuleNamesByTarget',
									'events:PutRule',
									'events:PutTargets',
									'events:RemoveTargets',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:DeleteLogGroup', 'logs:GetLogEvents'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v5',
					IsDefaultVersion: false,
					CreateDate: '2018-10-03T18:20:00Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'cloudwatch:GetMetricStatistics',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:GetLogEvents'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v4',
					IsDefaultVersion: false,
					CreateDate: '2018-07-19T18:19:35Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'ec2:DescribeVpcs',
									'ec2:DescribeSecurityGroups',
									'ec2:DescribeSubnets',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:GetLogEvents'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v3',
					IsDefaultVersion: false,
					CreateDate: '2017-11-21T19:07:49Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:GetLogEvents'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['ssm:PutParameter'],
								NotAction: null,
								Resource: 'arn:aws:ssm:*:*:parameter/CodeBuild/*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v2',
					IsDefaultVersion: false,
					CreateDate: '2017-10-25T19:39:52Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: [
									'codebuild:*',
									'codecommit:GetBranch',
									'codecommit:GetCommit',
									'codecommit:GetRepository',
									'codecommit:ListBranches',
									'codecommit:ListRepositories',
									'ecr:DescribeRepositories',
									'ecr:ListImages',
									's3:GetBucketLocation',
									's3:ListAllMyBuckets',
								],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['logs:GetLogEvents'],
								NotAction: null,
								Resource: 'arn:aws:logs:*:*:log-group:/aws/codebuild/*:log-stream:*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v1',
					IsDefaultVersion: false,
					CreateDate: '2016-12-01T19:04:44Z',
				},
			],
		},
		CustomerManaged: false,
	},
	AmazonS3FullAccess: {
		PolicyName: 'AmazonS3FullAccess',
		PolicyArn: 'arn:aws:iam::aws:policy/AmazonS3FullAccess',
		RiskTags: [policyTags.POSSIBLE_PRIVILEGE_ESCALATION, policyTags.DATA_ACCESS],
		PolicyInfo: {
			PolicyName: 'AmazonS3FullAccess',
			PolicyId: 'ANPAIFIR6V6BVTRAHWINE',
			Arn: 'arn:aws:iam::aws:policy/AmazonS3FullAccess',
			Path: '/',
			DefaultVersionId: 'v2',
			AttachmentCount: 2,
			PermissionsBoundaryUsageCount: 0,
			IsAttachable: true,
			CreateDate: '2015-02-06T18:40:58Z',
			UpdateDate: '2021-09-27T20:16:37Z',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: ['s3:*', 's3-object-lambda:*'],
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v2',
					IsDefaultVersion: true,
					CreateDate: '2021-09-27T20:16:37Z',
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Effect: 'Allow',
								Action: 's3:*',
								NotAction: null,
								Resource: '*',
								NotResource: null,
								Condition: null,
							},
						],
					},
					VersionId: 'v1',
					IsDefaultVersion: false,
					CreateDate: '2015-02-06T18:40:58Z',
				},
			],
		},
		CustomerManaged: false,
	},
	eks_permissions: {
		PolicyName: 'eks-permissions',
		RiskTags: [policyTags.DATA_ACCESS],
		PolicyDocument: {
			Version: '2012-10-17',
			Statement: [
				{
					Sid: 'VisualEditor0',
					Effect: 'Allow',
					Action: 'eks:*',
					NotAction: null,
					Resource: '*',
					NotResource: null,
					Condition: null,
				},
			],
		},
	},
	full_access_policy_for_testing: {
		PolicyName: 'full_access_policy_for_testing',
		RiskTags: [
			policyTags.POSSIBLE_PRIVILEGE_ESCALATION,
			policyTags.RESOURCE_EXPOSURE,
			policyTags.DATA_ACCESS,
			policyTags.CREDENTIAL_EXPOSURE,
		],
		PolicyDocument: {
			Version: '2012-10-17',
			Statement: [
				{
					Sid: 'Statement1',
					Effect: 'Allow',
					Action: '*',
					NotAction: null,
					Resource: '*',
					NotResource: null,
					Condition: null,
				},
			],
		},
	},
	ec2_s3_for_ci_user: {
		PolicyName: 'ec2-s3-for-ci-user',
		RiskTags: [],
		PolicyDocument: {
			Version: '2012-10-17',
			Statement: [
				{
					Sid: null,
					Effect: 'Allow',
					Action: ['s3:ListBucket', 's3:GetObject', 's3:PutObject'],
					NotAction: null,
					Resource: ['arn:aws:s3:::specific-bucket-name', 'arn:aws:s3:::specific-bucket-name/*'],
					NotResource: null,
					Condition: null,
				},
				{
					Sid: null,
					Effect: 'Allow',
					Action: ['ec2:DescribeInstances', 'ec2:StartInstances', 'ec2:StopInstances'],
					NotAction: null,
					Resource: '*',
					NotResource: null,
					Condition: {
						StringEquals: {
							'ec2:ResourceTag/Environment': 'test',
						},
					},
				},
			],
		},
	},
	policies_for_shadow_admin_1: {
		PolicyName: 'st-notifier-deploy-us-policy',
		PolicyArn: 'arn:aws:iam::818837914182:policy/st-notifier-deploy-us-policy',
		RiskTags: [policyTags.POSSIBLE_PRIVILEGE_ESCALATION, policyTags.DATA_ACCESS, policyTags.RESOURCE_EXPOSURE],
		PolicyInfo: {
			Arn: 'arn:aws:iam::818837914182:policy/st-notifier-deploy-us-policy',
			Path: '/',
			PolicyId: 'BNPFWRK9LMTQ7X5YVZS3P',
			CreateDate: '2020-11-29 18:49:26+00:00',
			PolicyName: 'st-notifier-deploy-us-policy',
			UpdateDate: '2023-10-01 07:55:52+00:00',
			IsAttachable: true,
			AttachmentCount: 1,
			DefaultVersionId: 'v10',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Action: [
									'cloudwatch:GetMetricStatistics',
									'cloudformation:ValidateTemplate',
									'logs:DescribeLogGroups',
								],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'lambda:CreateFunction',
									'lambda:List*',
									'lambda:Get*',
									'lambda:InvokeFunction',
									'lambda:Update*',
									'lambda:AddPermission',
									'lambda:DeleteFunction',
									'lambda:RemovePermission',
									'lambda:PublishVersion',
									'lambda:CreateAlias',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:lambda:us-east-1:*:function:s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['iam:PassRole'],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'iam:GetRole',
									'iam:CreateRole',
									'iam:PutRolePolicy',
									'iam:DeleteRolePolicy',
									'iam:DeleteRole',
								],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/s3-to-sqs-*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									's3:CreateBucket',
									's3:PutObject',
									's3:GetObject',
									's3:DeleteObject',
									's3:PutAccelerateConfiguration',
									's3:ListBucketVersions',
									's3:CreateBucket',
									's3:ListBucket',
									's3:PutEncryptionConfiguration',
									's3:GetEncryptionConfiguration',
									's3:DeleteBucket',
									's3:PutBucketPolicy',
									's3:GetBucketLocation',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:s3:::s3-to-sqs*serverlessdeploy*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'cloudformation:List*',
									'cloudformation:Get*',
									'cloudformation:CreateUploadBucket',
									'cloudformation:Describe*',
									'cloudformation:CreateStack',
									'cloudformation:DeleteStack',
									'cloudformation:DeleteChangeSet',
									'cloudformation:CreateChangeSet',
									'cloudformation:ExecuteChangeSet',
									'cloudformation:UpdateStack',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:cloudformation:us-east-1:*:stack/s3-to-sqs-*/*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'logs:CreateLogGroup',
									'logs:DescribeLogStreams',
									'logs:FilterLogEvents',
									'logs:PutLogEvents',
									'logs:CreateLogStream',
									'logs:DeleteLogGroup',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:logs:us-east-1:*:*s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['events:Put*', 'events:Remove*', 'events:DescribeRule', 'events:Delete*'],
								Effect: 'Allow',
								Resource: 'arn:aws:events:us-east-1:*:rule/s3-to-sqs*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v10',
					CreateDate: '2023-10-01 07:55:52+00:00',
					IsDefaultVersion: true,
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Action: [
									'cloudwatch:GetMetricStatistics',
									'cloudformation:ValidateTemplate',
									'logs:DescribeLogGroups',
								],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'lambda:CreateFunction',
									'lambda:List*',
									'lambda:Get*',
									'lambda:InvokeFunction',
									'lambda:Update*',
									'lambda:AddPermission',
									'lambda:DeleteFunction',
									'lambda:RemovePermission',
									'lambda:PublishVersion',
									'lambda:CreateAlias',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:lambda:us-east-1:*:function:s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['iam:PassRole'],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'iam:GetRole',
									'iam:CreateRole',
									'iam:PutRolePolicy',
									'iam:DeleteRolePolicy',
									'iam:DeleteRole',
								],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/s3-to-sqs-*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									's3:CreateBucket',
									's3:PutObject',
									's3:GetObject',
									's3:DeleteObject',
									's3:PutAccelerateConfiguration',
									's3:ListBucketVersions',
									's3:CreateBucket',
									's3:ListBucket',
									's3:PutEncryptionConfiguration',
									's3:GetEncryptionConfiguration',
									's3:DeleteBucket',
									's3:PutBucketPolicy',
									's3:GetBucketLocation',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:s3:::s3-to-sqs*serverlessdeploy*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'cloudformation:List*',
									'cloudformation:Get*',
									'cloudformation:CreateUploadBucket',
									'cloudformation:Describe*',
									'cloudformation:CreateStack',
									'cloudformation:DeleteStack',
									'cloudformation:DeleteChangeSet',
									'cloudformation:CreateChangeSet',
									'cloudformation:UpdateStack',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:cloudformation:us-east-1:*:stack/s3-to-sqs-*/*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'logs:CreateLogGroup',
									'logs:DescribeLogStreams',
									'logs:FilterLogEvents',
									'logs:PutLogEvents',
									'logs:CreateLogStream',
									'logs:DeleteLogGroup',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:logs:us-east-1:*:*s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['events:Put*', 'events:Remove*', 'events:DescribeRule', 'events:Delete*'],
								Effect: 'Allow',
								Resource: 'arn:aws:events:us-east-1:*:rule/s3-to-sqs*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v9',
					CreateDate: '2023-10-01 07:51:27+00:00',
					IsDefaultVersion: false,
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Action: [
									'cloudwatch:GetMetricStatistics',
									'cloudformation:ValidateTemplate',
									'logs:DescribeLogGroups',
								],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'lambda:CreateFunction',
									'lambda:List*',
									'lambda:Get*',
									'lambda:InvokeFunction',
									'lambda:Update*',
									'lambda:AddPermission',
									'lambda:DeleteFunction',
									'lambda:RemovePermission',
									'lambda:PublishVersion',
									'lambda:CreateAlias',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:lambda:us-east-1:*:function:s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['iam:PassRole'],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'iam:GetRole',
									'iam:CreateRole',
									'iam:PutRolePolicy',
									'iam:DeleteRolePolicy',
									'iam:DeleteRole',
								],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/s3-to-sqs-*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									's3:CreateBucket',
									's3:PutObject',
									's3:GetObject',
									's3:DeleteObject',
									's3:PutAccelerateConfiguration',
									's3:ListBucketVersions',
									's3:CreateBucket',
									's3:ListBucket',
									's3:PutEncryptionConfiguration',
									's3:GetEncryptionConfiguration',
									's3:DeleteBucket',
									's3:PutBucketPolicy',
									's3:GetBucketLocation',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:s3:::s3-to-sqs*serverlessdeploy*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'cloudformation:List*',
									'cloudformation:Get*',
									'cloudformation:CreateUploadBucket',
									'cloudformation:Describe*',
									'cloudformation:CreateStack',
									'cloudformation:DeleteStack',
									'cloudformation:DeleteChangeSet',
									'cloudformation:UpdateStack',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:cloudformation:us-east-1:*:stack/s3-to-sqs-*/*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'logs:CreateLogGroup',
									'logs:DescribeLogStreams',
									'logs:FilterLogEvents',
									'logs:PutLogEvents',
									'logs:CreateLogStream',
									'logs:DeleteLogGroup',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:logs:us-east-1:*:*s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['events:Put*', 'events:Remove*', 'events:DescribeRule', 'events:Delete*'],
								Effect: 'Allow',
								Resource: 'arn:aws:events:us-east-1:*:rule/s3-to-sqs*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v8',
					CreateDate: '2023-10-01 07:47:30+00:00',
					IsDefaultVersion: false,
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Action: [
									'cloudwatch:GetMetricStatistics',
									'cloudformation:ValidateTemplate',
									'logs:DescribeLogGroups',
								],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'lambda:CreateFunction',
									'lambda:List*',
									'lambda:Get*',
									'lambda:InvokeFunction',
									'lambda:Update*',
									'lambda:AddPermission',
									'lambda:DeleteFunction',
									'lambda:RemovePermission',
									'lambda:PublishVersion',
									'lambda:CreateAlias',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:lambda:us-east-1:*:function:s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['iam:PassRole'],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'iam:GetRole',
									'iam:CreateRole',
									'iam:PutRolePolicy',
									'iam:DeleteRolePolicy',
									'iam:DeleteRole',
								],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/s3-to-sqs-*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									's3:CreateBucket',
									's3:PutObject',
									's3:GetObject',
									's3:DeleteObject',
									's3:PutAccelerateConfiguration',
									's3:ListBucketVersions',
									's3:CreateBucket',
									's3:ListBucket',
									's3:PutEncryptionConfiguration',
									's3:GetEncryptionConfiguration',
									's3:DeleteBucket',
									's3:PutBucketPolicy',
									's3:GetBucketLocation',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:s3:::s3-to-sqs*serverlessdeploy*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'cloudformation:List*',
									'cloudformation:Get*',
									'cloudformation:CreateUploadBucket',
									'cloudformation:Describe*',
									'cloudformation:CreateStack',
									'cloudformation:DeleteStack',
									'cloudformation:UpdateStack',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:cloudformation:us-east-1:*:stack/s3-to-sqs-*/*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'logs:CreateLogGroup',
									'logs:DescribeLogStreams',
									'logs:FilterLogEvents',
									'logs:PutLogEvents',
									'logs:CreateLogStream',
									'logs:DeleteLogGroup',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:logs:us-east-1:*:*s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['events:Put*', 'events:Remove*', 'events:DescribeRule', 'events:Delete*'],
								Effect: 'Allow',
								Resource: 'arn:aws:events:us-east-1:*:rule/s3-to-sqs*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v7',
					CreateDate: '2020-11-30 15:13:41+00:00',
					IsDefaultVersion: false,
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Action: [
									'cloudwatch:GetMetricStatistics',
									'cloudformation:ValidateTemplate',
									'logs:DescribeLogGroups',
								],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'lambda:CreateFunction',
									'lambda:List*',
									'lambda:Get*',
									'lambda:InvokeFunction',
									'lambda:Update*',
									'lambda:AddPermission',
									'lambda:DeleteFunction',
									'lambda:RemovePermission',
									'lambda:PublishVersion',
									'lambda:CreateAlias',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:lambda:us-east-1:*:function:s3-to-sqs-*-st_s3_to_sqs',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['iam:PassRole'],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'iam:GetRole',
									'iam:CreateRole',
									'iam:PutRolePolicy',
									'iam:DeleteRolePolicy',
									'iam:DeleteRole',
								],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/s3-to-sqs-*'],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									's3:CreateBucket',
									's3:PutObject',
									's3:GetObject',
									's3:DeleteObject',
									's3:PutAccelerateConfiguration',
									's3:ListBucketVersions',
									's3:CreateBucket',
									's3:ListBucket',
									's3:PutEncryptionConfiguration',
									's3:GetEncryptionConfiguration',
									's3:DeleteBucket',
									's3:PutBucketPolicy',
									's3:GetBucketLocation',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:s3:::s3-to-sqs*serverlessdeploy*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'cloudformation:List*',
									'cloudformation:Get*',
									'cloudformation:CreateUploadBucket',
									'cloudformation:Describe*',
									'cloudformation:CreateStack',
									'cloudformation:DeleteStack',
									'cloudformation:UpdateStack',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:cloudformation:us-east-1:*:stack/s3-to-sqs-*/*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: [
									'logs:CreateLogGroup',
									'logs:DescribeLogStreams',
									'logs:FilterLogEvents',
									'logs:PutLogEvents',
									'logs:CreateLogStream',
									'logs:DeleteLogGroup',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:logs:us-east-1:*:*s3-to-sqs-*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: null,
								Action: ['events:Put*', 'events:Remove*', 'events:DescribeRule', 'events:Delete*'],
								Effect: 'Allow',
								Resource: 'arn:aws:events:us-east-1:*:rule/s3-to-sqs*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v6',
					CreateDate: '2020-11-30 15:08:46+00:00',
					IsDefaultVersion: false,
				},
			],
			PermissionsBoundaryUsageCount: 0,
		},
		CustomerManaged: true,
	},
	generic_policy_for_shadow_admin: {
		PolicyName: 'AppInfraServicerRole',
		PolicyArn: 'arn:aws:iam::467791917130:policy/AppInfraServicerRole',
		RiskTags: [policyTags.POSSIBLE_PRIVILEGE_ESCALATION, policyTags.DATA_ACCESS, policyTags.RESOURCE_EXPOSURE],
		PolicyInfo: {
			Arn: 'arn:aws:iam::467791917130:policy/AppInfraServicerRole',
			Path: '/',
			PolicyId: 'ANPFWRK9LMTQ7X5YVZS3Q',
			CreateDate: '2024-09-02 12:00:00+00:00',
			PolicyName: 'AppInfraServicerRole',
			UpdateDate: '2024-09-02 12:00:00+00:00',
			IsAttachable: true,
			AttachmentCount: 1,
			DefaultVersionId: 'v1',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: null,
								Action: [
									'cloudwatch:GetMetricStatistics',
									'cloudformation:ValidateTemplate',
									'logs:DescribeLogGroups',
								],
								Effect: 'Allow',
								Resource: '*',
							},
							{
								Sid: null,
								Action: [
									'lambda:CreateFunction',
									'lambda:List*',
									'lambda:Get*',
									'lambda:InvokeFunction',
									'lambda:Update*',
									'lambda:AddPermission',
									'lambda:DeleteFunction',
									'lambda:RemovePermission',
									'lambda:PublishVersion',
									'lambda:CreateAlias',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:lambda:us-east-1:*:function:s3-to-sqs-*',
							},
							{
								Sid: null,
								Action: ['iam:PassRole'],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/*'],
							},
							{
								Sid: null,
								Action: [
									'iam:GetRole',
									'iam:CreateRole',
									'iam:PutRolePolicy',
									'iam:DeleteRolePolicy',
									'iam:DeleteRole',
								],
								Effect: 'Allow',
								Resource: ['arn:aws:iam::*:role/s3-to-sqs-*'],
							},
							{
								Sid: null,
								Action: [
									's3:CreateBucket',
									's3:PutObject',
									's3:GetObject',
									's3:DeleteObject',
									's3:PutAccelerateConfiguration',
									's3:ListBucketVersions',
									's3:CreateBucket',
									's3:ListBucket',
									's3:PutEncryptionConfiguration',
									's3:GetEncryptionConfiguration',
									's3:DeleteBucket',
									's3:PutBucketPolicy',
									's3:GetBucketLocation',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:s3:::s3-to-sqs*serverlessdeploy*',
							},
							{
								Sid: null,
								Action: [
									'cloudformation:List*',
									'cloudformation:Get*',
									'cloudformation:CreateUploadBucket',
									'cloudformation:Describe*',
									'cloudformation:CreateStack',
									'cloudformation:DeleteStack',
									'cloudformation:DeleteChangeSet',
									'cloudformation:CreateChangeSet',
									'cloudformation:ExecuteChangeSet',
									'cloudformation:UpdateStack',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:cloudformation:us-east-1:*:stack/s3-to-sqs-*/*',
							},
							{
								Sid: null,
								Action: [
									'logs:CreateLogGroup',
									'logs:DescribeLogStreams',
									'logs:FilterLogEvents',
									'logs:PutLogEvents',
									'logs:CreateLogStream',
									'logs:DeleteLogGroup',
								],
								Effect: 'Allow',
								Resource: 'arn:aws:logs:us-east-1:*:*s3-to-sqs-*',
							},
							{
								Sid: null,
								Action: ['events:Put*', 'events:Remove*', 'events:DescribeRule', 'events:Delete*'],
								Effect: 'Allow',
								Resource: 'arn:aws:events:us-east-1:*:rule/s3-to-sqs*',
							},
						],
					},
					VersionId: 'v1',
					CreateDate: '2024-09-02 12:00:00+00:00',
					IsDefaultVersion: true,
				},
			],
			PermissionsBoundaryUsageCount: 0,
		},
		CustomerManaged: true,
	},
	splunkUmbrellaBucketPolicy: {
		RiskTags: [policyTags.DATA_ACCESS],
		PolicyArn: 'arn:aws:iam::818837914182:policy/Splunk_umbrella_bucket',
		PolicyInfo: {
			Arn: 'arn:aws:iam::818837914182:policy/Splunk_umbrella_bucket',
			Path: '/',
			PolicyId: 'ANPAUCHLXZUU2GQ3O2X56',
			CreateDate: '2022-02-21 11:07:23+00:00',
			PolicyName: 'Splunk_umbrella_bucket',
			UpdateDate: '2022-02-21 11:07:23+00:00',
			IsAttachable: true,
			AttachmentCount: 2,
			DefaultVersionId: 'v1',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: 'VisualEditor0',
								Action: [
									's3:ListAccessPointsForObjectLambda',
									's3:GetObjectVersionTagging',
									's3:GetStorageLensConfigurationTagging',
									's3:GetObjectAcl',
									's3:GetBucketObjectLockConfiguration',
									's3:GetIntelligentTieringConfiguration',
									's3:GetObjectVersionAcl',
									's3:GetBucketPolicyStatus',
									's3:GetObjectRetention',
									's3:GetBucketWebsite',
									's3:GetJobTagging',
									's3:ListJobs',
									's3:GetMultiRegionAccessPoint',
									's3:GetObjectLegalHold',
									's3:GetBucketNotification',
									's3:DescribeMultiRegionAccessPointOperation',
									's3:GetReplicationConfiguration',
									's3:ListMultipartUploadParts',
									's3:GetObject',
									's3:DescribeJob',
									's3:GetAnalyticsConfiguration',
									's3:GetObjectVersionForReplication',
									's3:GetAccessPointForObjectLambda',
									's3:GetStorageLensDashboard',
									's3:GetLifecycleConfiguration',
									's3:GetAccessPoint',
									's3:GetInventoryConfiguration',
									's3:GetBucketTagging',
									's3:GetAccessPointPolicyForObjectLambda',
									's3:GetBucketLogging',
									's3:ListBucketVersions',
									's3:ListBucket',
									's3:GetAccelerateConfiguration',
									's3:GetBucketPolicy',
									's3:GetEncryptionConfiguration',
									's3:GetObjectVersionTorrent',
									's3:GetBucketRequestPayment',
									's3:GetAccessPointPolicyStatus',
									's3:GetObjectTagging',
									's3:GetMetricsConfiguration',
									's3:GetBucketOwnershipControls',
									's3:GetBucketPublicAccessBlock',
									's3:GetMultiRegionAccessPointPolicyStatus',
									's3:ListBucketMultipartUploads',
									's3:GetMultiRegionAccessPointPolicy',
									's3:GetAccessPointPolicyStatusForObjectLambda',
									's3:ListAccessPoints',
									's3:GetBucketVersioning',
									's3:ListMultiRegionAccessPoints',
									's3:GetBucketAcl',
									's3:GetAccessPointConfigurationForObjectLambda',
									's3:ListStorageLensConfigurations',
									's3:GetObjectTorrent',
									's3:GetStorageLensConfiguration',
									's3:GetAccountPublicAccessBlock',
									's3:ListAllMyBuckets',
									's3:GetBucketCORS',
									's3:GetBucketLocation',
									's3:GetAccessPointPolicy',
									's3:GetObjectVersion',
								],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v1',
					CreateDate: '2022-02-21 11:07:23+00:00',
					IsDefaultVersion: true,
				},
			],
			PermissionsBoundaryUsageCount: 0,
		},
		PolicyName: 'Splunk_umbrella_bucket',
		CustomerManaged: true,
	},
	tableauProdBuckupPolicy: {
		RiskTags: [policyTags.DATA_ACCESS, policyTags.RESOURCE_EXPOSURE],
		PolicyArn: 'arn:aws:iam::818837914182:policy/Prod-Tableau-backup-S3-Access',
		PolicyInfo: {
			Arn: 'arn:aws:iam::818837914182:policy/Prod-Tableau-backup-S3-Access',
			Path: '/',
			PolicyId: 'ANPAXCSZP5PYIO2FJBSB2',
			CreateDate: '2022-06-06 13:36:22+00:00',
			PolicyName: 'Prod-Tableau-backup-S3-Access',
			UpdateDate: '2022-12-21 09:47:37+00:00',
			IsAttachable: true,
			AttachmentCount: 2,
			DefaultVersionId: 'v5',
			PolicyVersionList: [
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: 'VisualEditor0',
								Action: ['s3:PutObject', 's3:GetObject', 's3:ListBucket', 's3:PutObjectAcl'],
								Effect: 'Allow',
								Resource: [
									'arn:aws:s3:::prod-synergy-tableau-backups/*',
									'arn:aws:s3:::prod-synergy-tableau-backups',
								],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: 'VisualEditor1',
								Action: ['s3:GetAccessPoint', 's3:GetAccountPublicAccessBlock', 's3:ListAllMyBuckets'],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v5',
					CreateDate: '2022-12-21 09:47:37+00:00',
					IsDefaultVersion: true,
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: 'VisualEditor0',
								Action: [
									's3:PutObject',
									's3:GetObject',
									's3:ListBucket',
									's3:DeleteObject',
									's3:PutObjectAcl',
								],
								Effect: 'Allow',
								Resource: [
									'arn:aws:s3:::prod-synergy-tableau-backups/*',
									'arn:aws:s3:::prod-synergy-tableau-backups',
								],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: 'VisualEditor1',
								Action: ['s3:GetAccessPoint', 's3:GetAccountPublicAccessBlock', 's3:ListAllMyBuckets'],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v4',
					CreateDate: '2022-12-21 08:44:31+00:00',
					IsDefaultVersion: false,
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: 'VisualEditor0',
								Action: [
									's3:GetLifecycleConfiguration',
									's3:GetBucketTagging',
									's3:GetInventoryConfiguration',
									's3:GetObjectVersionTagging',
									's3:GetBucketLogging',
									's3:ListBucket',
									's3:GetAccelerateConfiguration',
									's3:GetObjectVersionAttributes',
									's3:GetBucketPolicy',
									's3:GetObjectVersionTorrent',
									's3:GetObjectAcl',
									's3:GetEncryptionConfiguration',
									's3:GetBucketObjectLockConfiguration',
									's3:GetIntelligentTieringConfiguration',
									's3:GetBucketRequestPayment',
									's3:GetObjectVersionAcl',
									's3:GetObjectTagging',
									's3:GetMetricsConfiguration',
									's3:GetBucketOwnershipControls',
									's3:DeleteObject',
									's3:PutObjectAcl',
									's3:GetBucketPublicAccessBlock',
									's3:GetBucketPolicyStatus',
									's3:GetObjectRetention',
									's3:GetBucketWebsite',
									's3:GetObjectAttributes',
									's3:GetBucketVersioning',
									's3:GetBucketAcl',
									's3:GetObjectLegalHold',
									's3:GetBucketNotification',
									's3:GetReplicationConfiguration',
									's3:PutObject',
									's3:GetObject',
									's3:GetObjectTorrent',
									's3:GetBucketCORS',
									's3:GetAnalyticsConfiguration',
									's3:GetObjectVersionForReplication',
									's3:GetBucketLocation',
									's3:GetObjectVersion',
								],
								Effect: 'Allow',
								Resource: [
									'arn:aws:s3:::prod-synergy-tableau-backups/*',
									'arn:aws:s3:::prod-synergy-tableau-backups',
								],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: 'VisualEditor1',
								Action: ['s3:GetAccessPoint', 's3:GetAccountPublicAccessBlock', 's3:ListAllMyBuckets'],
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v3',
					CreateDate: '2022-12-21 08:43:50+00:00',
					IsDefaultVersion: false,
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: 'VisualEditor0',
								Action: ['s3:PutObject', 's3:GetObject', 's3:ListBucket', 's3:PutObjectAcl'],
								Effect: 'Allow',
								Resource: [
									'arn:aws:s3:::prod-synergy-tableau-backups',
									'arn:aws:s3:::prod-synergy-tableau-backups/*',
								],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: 'VisualEditor1',
								Action: 's3:ListAllMyBuckets',
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v2',
					CreateDate: '2022-12-21 07:17:10+00:00',
					IsDefaultVersion: false,
				},
				{
					Document: {
						Version: '2012-10-17',
						Statement: [
							{
								Sid: 'VisualEditor0',
								Action: [
									's3:PutObject',
									's3:GetObject',
									's3:ListBucket',
									's3:DeleteObject',
									's3:PutObjectAcl',
								],
								Effect: 'Allow',
								Resource: [
									'arn:aws:s3:::prod-synergy-tableau-backups',
									'arn:aws:s3:::prod-synergy-tableau-backups/*',
								],
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
							{
								Sid: 'VisualEditor1',
								Action: 's3:ListAllMyBuckets',
								Effect: 'Allow',
								Resource: '*',
								Condition: null,
								NotAction: null,
								NotResource: null,
							},
						],
					},
					VersionId: 'v1',
					CreateDate: '2022-06-06 13:36:22+00:00',
					IsDefaultVersion: false,
				},
			],
			PermissionsBoundaryUsageCount: 0,
		},
		PolicyName: 'Prod-Tableau-backup-S3-Access',
		CustomerManaged: true,
	},
} satisfies Record<string, ServerManagedPolicyInput | ServerAwsIdentityPolicyInput>

export const getTagsByPolicies = (
	policies: (ServerManagedPolicyInput | ServerAwsIdentityPolicyInput)[],
): ServerTagInput[] => {
	const riskTagsSet: Set<ServerTagInput> = new Set()

	policies.forEach((policy) => {
		policy.RiskTags?.forEach((tag) => riskTagsSet.add(tag))
	})

	return Array.from(riskTagsSet)
}

const getChangeLogs1 = (userName: string, affectedArn: string): Array<ServerAwsIamChangeLogInput> => {
	return [
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '2c4f1c35-4eb2-4473-a8a3-db169edf910b',
			event_time: '2022-02-20T07:50:51Z',
			created_at: '2022-02-20T07:50:51.546241Z',
			event_name: 'CreateUser',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '207.232.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '78972903-598d-4f46-98c0-c1191fb161af',
			event_time: '2023-10-17T11:56:57Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '207.232.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'ae07448e-900e-4389-9314-4bf69e460295',
			event_time: '2023-10-17T11:58:03Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'CreateAccessKey',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '207.232.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '04ac177d-a465-4053-a75d-928ff037652b',
			event_time: '2023-12-31T15:07:01Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'UpdateAccessKey',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.182.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				status: 'Inactive',
				userName: userName,
				accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '98bd256f-7bf3-4b61-81b3-229c8dc24916',
			event_time: '2023-12-31T15:07:09Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DeleteAccessKey',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.182.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '806b98a7-2952-493a-8225-e0884423b5d6',
			event_time: '2023-12-31T15:07:26Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'CreateAccessKey',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.182.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '3f1c890c-bd11-4d62-808e-99b14d8a0eac',
			event_time: '2023-12-31T15:09:37Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'PutUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.182.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'eks-permissions',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Sid": "VisualEditor0",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "eks:*",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'a2b937e0-fc93-4db7-a9b5-4f45efa5eb88',
			event_time: '2024-03-17T14:28:02Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.182.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'd2dd9957-133c-4c98-8dfd-d2273d7ccc30',
			event_time: '2024-03-18T11:47:00Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DetachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.182.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '964d09b4-46b1-47e8-b314-02457447727a',
			event_time: '2024-03-25T21:05:41Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '46.121.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::710369796047:policy/eks-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0da',
			event_time: '2024-03-25T21:05:53Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DetachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '46.121.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
	]
}

const getChangeLogs2 = (userName: string, affectedArn: string): Array<ServerAwsIamChangeLogInput> => {
	return [
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'f500c057-d752-45f1-8e77-f0960fec154f',
			event_time: '2023-10-11T14:57:40Z',
			created_at: '2023-10-11T14:57:40.953407Z',
			event_name: 'CreateUser',
			actor_arn: 'arn:aws:iam::862947542951:user/pvi-infra-CI',
			affected_arn: affectedArn,
			source_ip_address: '3.178.5.33',
			user_agent:
				'APN/1.0 HashiCorp/1.0 Terraform/1.3.6 (+https://www.terraform.io) terraform-provider-aws/4.55.0 (+https://registry.terraform.io/providers/hashicorp/aws) aws-sdk-go/1.44.201 (go1.19.3; linux; amd64)',
			request_parameters: {
				path: '/',
				tags: [
					{
						key: 'Project',
						value: 'prodops',
					},
					{
						key: 'Ticket',
						value: 'PRODOPS-7304',
					},
				],
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'c0900804-1c84-435c-8e4a-6446e111d315',
			event_time: '2024-01-18T06:48:42Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:iam::862947542951:user/pvi-infra-CI',
			affected_arn: affectedArn,
			source_ip_address: '3.178.5.33',
			user_agent:
				'APN/1.0 HashiCorp/1.0 Terraform/1.3.6 (+https://www.terraform.io) terraform-provider-aws/4.55.0 (+https://registry.terraform.io/providers/hashicorp/aws) aws-sdk-go/1.44.201 (go1.19.3; linux; amd64)',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{"Version":"2012-10-17","Statement":[{"Action":"s3:*","Effect":"Allow","Resource":["arn:aws:s3:::terr-state-synergy/event-management-app/*"]},{"Action":["iam:PassRole"],"Effect":"Allow","Resource":"arn:aws:iam::862947542951:role/SynergyAnalyticsProd"},{"Action":"dynamodb:*","Effect":"Allow","Resource":"arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"},{"Action":["ec2:DescribeInstances","ec2:DescribeTags","ec2:DescribeImages","ec2:DescribeKeyPairs","ec2:DescribeSecurityGroups","ec2:DescribeAvailabilityZones","ec2:RunInstances","ec2:TerminateInstances","ec2:StopInstances","ec2:StartInstances","ec2:MonitorInstances","ec2:DescribeVolumes","ec2:ModifyInstanceAttribute","ec2:UnmonitorInstances"],"Condition":{"StringEquals":{"aws:ResourceTag/Component":"event-manager-app","aws:ResourceTag/Team":"Analytics"}},"Effect":"Allow","Resource":"*"}]}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'ae62760a-0afb-466e-98c6-fd0eb732982a',
			event_time: '2024-01-18T07:00:32Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'CreateAccessKey',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'b0795601-78bf-420d-88dc-dbfe5b9b1310',
			event_time: '2024-01-18T07:00:33Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'TagUser',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				tags: [
					{
						key: 'AKIASCWPEBX2YCO73VWP',
						value: 'gitlab-ci',
					},
				],
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '6f796096-9ecc-4317-a585-898db840c32c',
			event_time: '2024-01-18T07:16:38Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'UntagUser',
			actor_arn: 'arn:aws:iam::862947542951:user/pvi-infra-CI',
			affected_arn: affectedArn,
			source_ip_address: '103.120.12.211',
			user_agent:
				'APN/1.0 HashiCorp/1.0 Terraform/1.3.6 (+https://www.terraform.io) terraform-provider-aws/4.55.0 (+https://registry.terraform.io/providers/hashicorp/aws) aws-sdk-go/1.44.201 (go1.19.3; linux; amd64)',
			request_parameters: {
				tagKeys: ['AKIASCWPEBX2YCO73VWP'],
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '183c1253-5fc1-4b90-b531-72e6c2eb59f7',
			event_time: '2024-01-18T07:36:59Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:ListAllMyBuckets",\n\t\t\t\t"s3:ListBucket"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'dcc5f628-38b4-40ee-b057-6d5354d632b7',
			event_time: '2024-01-18T08:44:20Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '7971c541-1c0b-4fa5-b968-cb1e9bcb1cbd',
			event_time: '2024-01-19T06:25:51Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'a663e095-4489-4768-b279-67dd8edf8127',
			event_time: '2024-01-19T06:31:10Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeInstanceTypes"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '0e6e93df-c42a-49a9-8b77-9ea399e55a50',
			event_time: '2024-01-19T06:51:16Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeInstanceTypes"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '679a0b74-5c81-4492-8957-e1ab2ec0f448',
			event_time: '2024-01-19T06:57:08Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '78ae144f-a166-4539-89d9-e56b94fc3503',
			event_time: '2024-01-19T07:03:10Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:iam::862947542951:user/pvi-infra-CI',
			affected_arn: affectedArn,
			source_ip_address: '103.120.12.211',
			user_agent:
				'APN/1.0 HashiCorp/1.0 Terraform/1.3.6 (+https://www.terraform.io) terraform-provider-aws/4.55.0 (+https://registry.terraform.io/providers/hashicorp/aws) aws-sdk-go/1.44.201 (go1.19.3; linux; amd64)',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{"Version":"2012-10-17","Statement":[{"Action":"s3:*","Effect":"Allow","Resource":["arn:aws:s3:::terr-state-synergy/event-management-app/*"]},{"Action":["iam:PassRole"],"Effect":"Allow","Resource":"arn:aws:iam::862947542951:role/SynergyAnalyticsProd"},{"Action":"dynamodb:*","Effect":"Allow","Resource":"arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"},{"Action":["ec2:DescribeInstances","ec2:DescribeTags","ec2:DescribeImages","ec2:DescribeKeyPairs","ec2:DescribeSecurityGroups","ec2:DescribeAvailabilityZones","ec2:RunInstances","ec2:TerminateInstances","ec2:StopInstances","ec2:StartInstances","ec2:MonitorInstances","ec2:DescribeVolumes","ec2:ModifyInstanceAttribute","ec2:UnmonitorInstances"],"Condition":{"StringEquals":{"aws:ResourceTag/Component":"event-manager-app","aws:ResourceTag/Team":"Analytics"}},"Effect":"Allow","Resource":"*"}]}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '35ec05e1-b5ef-407c-a15d-126eda311708',
			event_time: '2024-01-19T08:03:07Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:DescribeVolumes"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '1218980d-f95b-461c-a0a5-c386c08de449',
			event_time: '2024-01-19T08:48:22Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '092cb8d2-58c5-4b90-8003-19bb5720f9a8',
			event_time: '2024-01-19T08:51:15Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'b99da50e-1a7e-49c1-bfce-5a0bb22ed4a6',
			event_time: '2024-01-19T08:55:02Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '9011b4c1-7161-43e1-b311-2aa1459f35b3',
			event_time: '2024-01-19T10:26:43Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '9dd1dd2f-6fff-42ba-9261-bb599e25b708',
			event_time: '2024-01-19T10:28:14Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '429db57c-6c9e-4b03-b99e-d625974a3378',
			event_time: '2024-01-19T13:19:48Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '9f9a24aa-d92c-4dc6-acf6-84b6e69d7cab',
			event_time: '2024-01-19T13:21:39Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n    "Sid": "s3ListAllBuckets",\n    "Effect": "Allow",\n    "Action": [\n        "s3:ListAllMyBuckets",\n        "s3:ListBucket"\n    ],\n    "Resource": [\n        "*"\n    ]\n},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '284925f6-bbeb-4188-a24b-d876dc3dae2d',
			event_time: '2024-01-19T13:24:39Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t    "arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3ListAllBuckets",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:ListAllMyBuckets",\n\t\t\t\t"s3:ListBucket"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '926f25c3-4f18-4f0d-b779-de3618de110f',
			event_time: '2024-01-19T13:40:15Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3ListAllBuckets",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:ListAllMyBuckets",\n\t\t\t\t"s3:ListBucket",\n\t\t\t\t"s3:GetObject",\n\t\t\t\t"s3:GetBucketAcl",\n\t\t\t\t"s3:GetBucketVersioning"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '6b30b092-9937-409a-ade0-5f8f35729657',
			event_time: '2024-01-19T13:45:11Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3ListAllBuckets",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '46185691-cc9f-4172-b98b-c40e05eeef7d',
			event_time: '2024-01-19T13:50:04Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3BucketPolicyForTF",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t    "s3:PutObject",\n              "s3:GetObject",\n              "s3:DeleteObject",\n              "s3:ListBucket",\n              "s3:GetBucketAcl",\n              "s3:PutBucketAcl",\n              "s3:GetBucketVersioning",\n              "s3:PutBucketVersioning"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'd1825748-3503-4917-a598-60abc7e2ec4b',
			event_time: '2024-01-19T13:58:58Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3BucketPolicyForTF",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:PutObject",\n\t\t\t\t"s3:GetObject",\n\t\t\t\t"s3:DeleteObject",\n\t\t\t\t"s3:ListBucket",\n\t\t\t\t"s3:GetBucketAcl",\n\t\t\t\t"s3:PutBucketAcl",\n\t\t\t\t"s3:GetBucketVersioning",\n\t\t\t\t"s3:PutBucketVersioning",\n\t\t\t\t"s3:CreateBucket"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'b682855b-40f2-4c59-9674-d4846f773274',
			event_time: '2024-01-19T14:03:00Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '19.191.21.128',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3BucketPolicyForTF",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'e33e37de-fe73-4248-ab40-3804669da93f',
			event_time: '2024-01-22T09:37:05Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:iam::862947542951:user/pvi-infra-CI',
			affected_arn: affectedArn,
			source_ip_address: '103.120.12.211',
			user_agent:
				'APN/1.0 HashiCorp/1.0 Terraform/1.3.6 (+https://www.terraform.io) terraform-provider-aws/4.55.0 (+https://registry.terraform.io/providers/hashicorp/aws) aws-sdk-go/1.44.201 (go1.19.3; linux; amd64)',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{"Version":"2012-10-17","Statement":[{"Action":"s3:*","Effect":"Allow","Resource":["arn:aws:s3:::terr-state-synergy/event-management-app/*"]},{"Action":["iam:PassRole"],"Effect":"Allow","Resource":"arn:aws:iam::862947542951:role/SynergyAnalyticsProd"},{"Action":"dynamodb:*","Effect":"Allow","Resource":"arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"},{"Action":["ec2:DescribeInstances","ec2:DescribeTags","ec2:DescribeImages","ec2:DescribeKeyPairs","ec2:DescribeSecurityGroups","ec2:DescribeAvailabilityZones","ec2:RunInstances","ec2:TerminateInstances","ec2:StopInstances","ec2:StartInstances","ec2:MonitorInstances","ec2:DescribeVolumes","ec2:ModifyInstanceAttribute","ec2:UnmonitorInstances"],"Condition":{"StringEquals":{"aws:ResourceTag/Component":"event-manager-app","aws:ResourceTag/Team":"Analytics"}},"Effect":"Allow","Resource":"*"}]}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '0d3c2660-0aed-4e43-b877-d882d14e1530',
			event_time: '2024-01-31T06:12:49Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n      "Version": "2012-10-17",\n      "Statement": [\n        {\n          "Effect": "Allow",\n          "Action": "s3:*",\n          "Resource": [\n            "arn:aws:s3:::terr-state-synergy/event-management-app/*"\n          ]\n        },\n        {\n          "Effect": "Allow",\n          "Action": [\n            "iam:PassRole"\n          ],\n          "Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n        },\n        {\n          "Effect": "Allow",\n          "Action": "dynamodb:*",\n          "Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n        },\n        {\n            "Action": [\n                "ec2:DescribeInstances",\n                "ec2:DescribeTags",\n                "ec2:DescribeInstanceTypes",\n                "ec2:DescribeVolumes",\n                "ec2:DescribeInstanceAttribute",\n                "ec2:DescribeInstanceCreditSpecifications",\n                "ec2:CreateTags"\n            ],\n            "Effect": "Allow",\n            "Resource": "*"\n        },\n        {\n          "Effect": "Allow",\n          "Action": [\n            "ec2:*"\n          ],\n          "Resource": "*",\n          "Condition": {\n            "StringEquals": {\n              "aws:ResourceTag/Component": "event-manager-app",\n              "aws:ResourceTag/Team": "Analytics"\n            }\n          }\n        }\n      ]\n    }',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '6901b8b2-249a-4d80-8d34-7c0f15068ae6',
			event_time: '2024-01-31T06:22:36Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "s3:*",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n            "Action": [\n                "s3:Get*",\n                "s3:List*"\n            ],\n            "Effect": "Allow",\n            "Resource": "*"\n        },\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Resource": "*",\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'e7e0f367-bb7f-4e20-bb22-06be1eb59163',
			event_time: '2024-01-31T06:31:03Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '103.121.123.211',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "s3:*",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:Get*",\n\t\t\t\t"s3:List*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:RunInstances"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Resource": "*",\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: 'd9186e82-c3b1-401e-96da-e35474c031ad',
			event_time: '2024-02-01T11:19:13Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:iam::862947542951:user/pvi-infra-CI',
			affected_arn: affectedArn,
			source_ip_address: '103.120.12.211',
			user_agent:
				'APN/1.0 HashiCorp/1.0 Terraform/1.3.6 (+https://www.terraform.io) terraform-provider-aws/4.55.0 (+https://registry.terraform.io/providers/hashicorp/aws) aws-sdk-go/1.44.201 (go1.19.3; linux; amd64)',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{"Version":"2012-10-17","Statement":[{"Action":"s3:*","Effect":"Allow","Resource":["arn:aws:s3:::terr-state-synergy/event-management-app/*"]},{"Action":["iam:PassRole"],"Effect":"Allow","Resource":"arn:aws:iam::862947542951:role/SynergyAnalyticsProd"},{"Action":"dynamodb:*","Effect":"Allow","Resource":"arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"},{"Action":["ec2:DescribeInstances","ec2:DescribeTags","ec2:DescribeImages","ec2:DescribeKeyPairs","ec2:DescribeSecurityGroups","ec2:DescribeAvailabilityZones","ec2:RunInstances","ec2:TerminateInstances","ec2:StopInstances","ec2:StartInstances","ec2:MonitorInstances","ec2:DescribeVolumes","ec2:ModifyInstanceAttribute","ec2:UnmonitorInstances"],"Condition":{"StringEquals":{"aws:ResourceTag/Component":"event-manager-app","aws:ResourceTag/Team":"Analytics"}},"Effect":"Allow","Resource":"*"}]}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
			id: '0a1f1906-3055-40a3-a0ae-e3d150c665c8',
			event_time: '2024-02-02T08:39:11Z',
			created_at: '2024-05-09T05:11:51.953407Z',
			event_name: 'PutUserPolicy',
			actor_arn: 'arn:aws:iam::862947542951:user/pvi-infra-CI',
			affected_arn: affectedArn,
			source_ip_address: '103.120.12.211',
			user_agent:
				'APN/1.0 HashiCorp/1.0 Terraform/1.3.6 (+https://www.terraform.io) terraform-provider-aws/4.55.0 (+https://registry.terraform.io/providers/hashicorp/aws) aws-sdk-go/1.44.201 (go1.19.3; linux; amd64)',
			request_parameters: {
				userName: userName,
				policyName: 'vmimport',
				policyDocument:
					'{"Version":"2012-10-17","Statement":[{"Action":"s3:*","Effect":"Allow","Resource":["arn:aws:s3:::terr-state-synergy/event-management-app/*"]},{"Action":["s3:Get*","s3:List*"],"Effect":"Allow","Resource":["*"]},{"Action":["iam:PassRole"],"Effect":"Allow","Resource":"arn:aws:iam::862947542951:role/SynergyAnalyticsProd"},{"Action":"dynamodb:*","Effect":"Allow","Resource":"arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"},{"Action":["ec2:DescribeInstances","ec2:DescribeTags","ec2:DescribeInstanceTypes","ec2:DescribeVolumes","ec2:DescribeInstanceAttribute","ec2:DescribeInstanceCreditSpecifications","ec2:CreateTags","ec2:RunInstances"],"Effect":"Allow","Resource":"*"},{"Action":["ec2:*"],"Condition":{"StringEquals":{"aws:ResourceTag/Component":"event-manager-app","aws:ResourceTag/Team":"Analytics"}},"Effect":"Allow","Resource":"*"}]}',
			},
		},
	]
}

const awsUsageLogs: Array<ServerAwsUsageLogInput> = [
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		association_type: 'EC2',
		events: ['s3.GetBucketLocation', 's3.ListMultipartUploads', 's3.ListBuckets'],
		events_count: 400,
		day: '2024-07-02T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		association_type: 'EC2',
		events: ['iam.ListRoles', 's3.GetBucketLocation', 's3.ListMultipartUploads'],
		events_count: 31,
		day: '2024-07-20T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'Gabrielas MacBook Pro',
		association_type: 'EC2',
		events: ['iam.ListRoles'],
		events_count: 2,
		day: '2024-01-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'Gabrielas MacBook Pro',
		association_type: 'EC2',
		events: ['s3.ListMultipartUploads'],
		events_count: 1,
		day: '2024-04-23T11:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.75.19.123',
		association: 'Gabrielas windows laptop',
		association_type: 'EC2',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
		events: ['iam.ListRoles'],
		events_count: 1,
		day: '2024-03-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.75.19.123',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
		association: 'Gabrielas windows laptop',
		association_type: 'GLOBAL:GITHUB',
		events: ['iam.ListRoles'],
		events_count: 20,
		day: '2024-03-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.75.19.123',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
		association: 'alex-white, dean-davis, gabrielabeard',
		association_type: IdentityType.Human,
		events: ['iam.ListRoles'],
		events_count: 20,
		day: '2024-03-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
		ip: '192.168.45.101',
		association: 'cloudspark-vm1',
		association_type: 'virtual_machine',
		user_agent:
			'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.128 Safari/537.36 Edge/90.0.818.66',
		events: [
			'azure.compute.ListVirtualMachines',
			'azure.storage.ListStorageAccounts',
			'azure.network.ListVirtualNetworks',
			'azure.database.ListSqlDatabases',
			'azure.appservice.ListWebApps',
		],
		events_count: 20,
		day: '2024-03-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '203.0.113.56',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
		association: 'velocity-vm0',
		association_type: 'virtual_machine',
		user_agent:
			'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.128 Safari/537.36 Edge/90.0.818.66',
		events: [
			'azure.compute.ListVirtualMachines',
			'azure.storage.ListStorageAccounts',
			'azure.network.ListVirtualNetworks',
			'azure.database.ListSqlDatabases',
			'azure.appservice.ListWebApps',
		],
		events_count: 20,
		day: '2024-03-16T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '172.16.254.9',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
		association: 'quantumedge-vm2',
		association_type: 'virtual_machine',
		user_agent:
			'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.128 Safari/537.36 Edge/90.0.818.66',
		events: [
			'azure.compute.ListVirtualMachines',
			'azure.storage.ListStorageAccounts',
			'azure.network.ListVirtualNetworks',
			'azure.database.ListSqlDatabases',
			'azure.appservice.ListWebApps',
		],
		events_count: 20,
		day: '2024-03-17T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '198.51.100.78',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
		association: 'nova-shard01',
		association_type: 'virtual_machine',
		user_agent:
			'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.128 Safari/537.36 Edge/90.0.818.66',
		events: [
			'azure.compute.ListVirtualMachines',
			'azure.storage.ListStorageAccounts',
			'azure.network.ListVirtualNetworks',
			'azure.database.ListSqlDatabases',
			'azure.appservice.ListWebApps',
		],
		events_count: 20,
		day: '2024-03-18T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '185.23.76.150',
		aws_access_key_id: 'AKIA8664T8TT477PMKGR',
		association: 'skyforge-dev01',
		association_type: 'virtual_machine',
		user_agent:
			'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.128 Safari/537.36 Edge/90.0.818.66',
		events: [
			'azure.compute.ListVirtualMachines',
			'azure.storage.ListStorageAccounts',
			'azure.network.ListVirtualNetworks',
			'azure.database.ListSqlDatabases',
			'azure.appservice.ListWebApps',
		],
		events_count: 20,
		day: '2024-03-19T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
]

const awsUsageLogsOld: Array<ServerAwsUsageLogInput> = [
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		events: ['s3.GetBucketLocation', 's3.ListMultipartUploads', 's3.ListBuckets'],
		events_count: 400,
		day: '2023-07-02T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		events: ['iam.ListRoles', 's3.GetBucketLocation', 's3.ListMultipartUploads'],
		events_count: 31,
		day: '2023-07-20T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'Gabrielas MacBook Pro',
		events: ['iam.ListRoles'],
		events_count: 2,
		day: '2023-01-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'Gabrielas MacBook Pro',
		events: ['s3.ListMultipartUploads'],
		events_count: 1,
		day: '2023-04-23T11:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.75.19.123',
		association: 'Gabrielas windows laptop',
		events: ['iam.ListRoles'],
		events_count: 1,
		day: '2023-03-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
]

const suspiciousIamBehaviourUsageLogs: Array<ServerAwsUsageLogInput> = [
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		events: ['s3.GetBucketLocation', 's3.ListMultipartUploads', 's3.ListBuckets'],
		events_count: 400,
		day: '2024-07-02T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		events: ['eks:DescribeCluster', 's3.GetBucketLocation', 's3.ListMultipartUploads'],
		events_count: 31,
		day: '2024-07-20T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'Gabrielas MacBook Pro',
		events: ['eks:DescribeCluster'],
		events_count: 2,
		day: '2024-01-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'Gabrielas MacBook Pro',
		events: ['s3.ListMultipartUploads'],
		events_count: 1,
		day: '2024-04-23T11:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.75.19.123',
		association: 'Gabrielas windows laptop',
		events: ['eks:DescribeCluster'],
		events_count: 1,
		day: '2024-03-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '3.80.123.45',
		association: 'AWS.ec2 (unknown)',
		events: [
			'iam:ListAttachedGroupPolicies',
			'iam:ListAttachedRolePolicies',
			'iam:ListAttachedUserPolicies',
			'iam:ListGroupPolicies',
			'iam:ListGroups',
			'iam:ListGroupsForUsers',
			'iam:ListPolicies',
			'iam:ListRolePolicies',
			'iam:ListRoles',
			'iam:ListUserPolicies',
			'iam:ListUsers',
		],
		events_count: 57,
		day: '2024-08-27T00:00:00Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
]

const splunkUserUsageLogs: Array<ServerAwsUsageLogInput> = [
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '198.51.100.23',
		association: 'i-0123456789abcdef0',
		association_type: 'EC2',
		events: ['s3.putObject', 'lambda.invoke'],
		events_count: 36265784,
		day: '2023-11-01T08:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-js/2.1179.0 Node.js/v16.15.0 Linux/Ubuntu_20.04',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '203.0.113.77',
		association: 'denise-gomez',
		association_type: 'Human',
		events: ['ec2.terminateInstances', 'cloudformation.createStack'],
		events_count: 54243,
		day: '2023-10-20T17:45:10Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-java/1.11.967 Windows/11 EC2 TransferManager/SimpleUploadCommand',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '192.0.2.45',
		association: 'nat-9f8e7d6c',
		association_type: 'nat_gateway',
		events: ['lambda.invoke'],
		events_count: 100,
		day: '2023-09-15T23:59:59Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-python-boto3/1.20.19 Python/3.10.4 macOS/12.3 botocore/1.22.19',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '192.0.2.45',
		association: '9876543210987654321',
		association_type: 'GCE',
		events: ['s3.putObject'],
		events_count: 3214526,
		day: '2023-09-15T23:59:59Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-python-boto3/1.20.19 Python/3.10.4 macOS/12.3 botocore/1.22.19',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '198.51.100.11',
		association: 'i-1a2b3c4d5e6f7g8h9',
		association_type: 'EC2',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 44300,
		day: '2023-11-01T09:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-java/1.11.967 Windows/11 EC2 TransferManager/SimpleUploadCommand',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '198.51.100.13',
		association: 'i-0123456789abcdef0',
		association_type: 'EC2',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 3211,
		day: '2023-11-01T11:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-js/2.1179.0 Node.js/v16.15.0 Linux/Ubuntu_20.04',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '198.51.100.14',
		association: 'i-fedcba98765432101',
		association_type: 'EC2',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 1423,
		day: '2023-11-01T12:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-java/1.11.967 Windows/11 EC2 TransferManager/SimpleUploadCommand',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '203.0.113.10',
		association: 'joseph.rogers@synergy.com',
		association_type: 'Human',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 1,
		day: '2023-10-31T08:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-js/2.1179.0 Node.js/v16.15.0 Linux/Ubuntu_20.04',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '203.0.113.11',
		association: 'denise-gomez',
		association_type: 'Human',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 321,
		day: '2023-10-31T09:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-java/1.11.967 Windows/11 EC2 TransferManager/SimpleUploadCommand',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '203.0.113.12',
		association: 'james-taylor',
		association_type: 'Human',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 4315,
		day: '2023-10-31T10:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-python-boto3/1.20.19 Python/3.10.4 macOS/12.3 botocore/1.22.19',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '203.0.113.13',
		association: 'nat-0abc1234',
		association_type: 'nat_gateway',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 51454,
		day: '2023-10-30T08:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-js/2.1179.0 Node.js/v16.15.0 Linux/Ubuntu_20.04',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '203.0.113.14',
		association: 'nat-1a2b3c4d',
		association_type: 'nat_gateway',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 4132,
		day: '2023-10-30T09:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-java/1.11.967 Windows/11 EC2 TransferManager/SimpleUploadCommand',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '203.0.113.15',
		association: 'nat-9f8e7d6c',
		association_type: 'nat_gateway',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 431,
		day: '2023-10-30T10:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-python-boto3/1.20.19 Python/3.10.4 macOS/12.3 botocore/1.22.19',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '192.0.2.10',
		association: '1234567890123456789',
		association_type: 'GCE',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 443343,
		day: '2023-10-29T08:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-js/2.1179.0 Node.js/v16.15.0 Linux/Ubuntu_20.04',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '192.0.2.11',
		association: '9876543210987654321',
		association_type: 'GCE',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 34214,
		day: '2023-10-29T09:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-java/1.11.967 Windows/11 EC2 TransferManager/SimpleUploadCommand',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '192.0.2.12',
		association: '1122334455667788990',
		association_type: 'GCE',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 4321432,
		day: '2023-10-29T10:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-python-boto3/1.20.19 Python/3.10.4 macOS/12.3 botocore/1.22.19',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
		ip: '192.0.2.13',
		association: '9988776655443322110',
		association_type: 'GCE',
		events: [
			's3.putObject',
			'ec2.terminateInstances',
			'lambda.invoke',
			'dynamodb.batchWriteItem',
			'cloudformation.createStack',
		],
		events_count: 434432,
		day: '2023-10-29T11:12:34Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		aws_access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
		user_agent: 'aws-sdk-js/2.1179.0 Node.js/v16.15.0 Linux/Ubuntu_20.04',
	},
]

export const awsUsageLogsGroupedByIdentityId: Array<{ id: string; usage_logs_grouped: ServerUsageLogGroupedInput[] }> =
	[
		{
			id: '65',
			usage_logs_grouped: [
				{
					user_identifier: 'arn:aws:iam::862947542951:user/ci-user',
					key: 'AKIA8664T8TT477PMKGR',
					associations: [
						{
							name: 'HUMAN',
							instances_count: 3,
							total_events_count: 20,
						},
						{
							name: 'virtual_machine',
							instances_count: 5,
							total_events_count: 100,
						},
						{
							name: 'EC2',
							instances_count: 3,
							total_events_count: 435,
						},
						{
							name: 'GLOBAL:GITHUB',
							instances_count: 1,
							total_events_count: 20,
						},
					],
					events: {
						EC2: ['ec2'],
						virtual_machine: [
							'ListVirtualMachines',
							'ListStorageAccounts',
							'ListVirtualNetworks',
							'ListSqlDatabases',
							'ListWebApps',
						],
						'GLOBAL:GITHUB': ['ec2'],
						HUMAN: ['s3', 'dynamodb'],
					},
				},
			],
		},
		{
			id: '177',
			usage_logs_grouped: [
				{
					user_identifier: 'arn:aws:iam::818837914182:user/Splunk_Access',
					key: 'AKIAZTLFMVR9X3PQRSTU',
					associations: [
						{
							name: 'EC2',
							instances_count: 3,
							total_events_count: 36314718,
						},
						{
							name: 'Human',
							instances_count: 3,
							total_events_count: 58880,
						},
						{
							name: 'nat_gateway',
							instances_count: 3,
							total_events_count: 56117,
						},
						{
							name: 'GCE',
							instances_count: 4,
							total_events_count: 8447947,
						},
					],
					events: {
						EC2: ['s3', 'lambda', 'ec2', 'dynamodb', 'cloudformation'],
						Human: ['ec2', 'cloudformation', 's3', 'lambda', 'dynamodb'],
						nat_gateway: ['lambda', 's3', 'ec2', 'dynamodb', 'cloudformation'],
						GCE: ['s3', 'ec2', 'lambda', 'dynamodb', 'cloudformation'],
					},
				},
			],
		},
	]

export const awsIamUserData = {
	'1': {
		id: '1',
		literal: 'arn:aws:iam::877686312397:user/gabrielabeard',
		literal_friendly_name: 'gabrielabeard',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Hybrid,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.AdministratorAccess]),
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		account_literal: '877686312397',
		account_literal_friendly_name: 'AWS dev',
		aws_iam_user: {
			name: 'gabrielabeard',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2023-04-17 10:29:00+00:00',
				last_rotated: '2021-10-13 08:23:10+00:00',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIA9J6THLSDLW9MOP8K',
			},
			access_key_2: {
				is_active: true,
				last_used_date: '2022-01-04 06:42:00+00:00',
				last_rotated: '2022-01-04 06:19:50+00:00',
				last_used_region: 'us-west-2',
				last_used_service: 's3',
				access_key_id: 'AKIA9WO5KDIBU669YJIR',
			},
			aws_iam_access_keys_xc: [
				{
					access_key_id: 'AKIA9J6THLSDLW9MOP8K',
					is_active: true,
					last_used_date: '2024-01-30 13:16:00+00:00',
					last_rotated: '2023-12-07 12:36:09+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
				},
				{
					access_key_id: 'AKIA9WO5KDIBU669YJIR',
					is_active: true,
					last_used_date: '2024-01-30 13:16:00+00:00',
					last_rotated: '2023-12-07 12:36:09+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
				},
			],
			cert_1: {
				is_active: false,
				last_rotated: null,
			},
			cert_2: {
				is_active: false,
				last_rotated: null,
			},
			password_info: {
				enabled: true,
				last_changed: '2018-12-20 07:51:04+00:00',
				next_rotation: null,
			},
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'gabrielabeard',
				UserId: 'AIDABEBW4LXLHKETJYVA',
				Arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
				CreateDate: '2018-12-20T07:21:30Z',
				UserPolicyList: [],
				GroupList: ['ynl-dev'],
				AttachedManagedPolicies: [],
				Tags: [
					{
						Key: 'Project',
						Value: 'AWSMigration',
					},
					{
						Key: 'Department',
						Value: 'IT',
					},
				],
				GroupListPermissions: [
					{
						Path: '/',
						GroupName: 'ynl-dev',
						GroupId: 'AGPADWF6WGTK92HMOCG4',
						Arn: 'arn:aws:iam::877686312397:group/ynl-dev',
						CreateDate: '2016-12-20T05:07:16Z',
						GroupPolicyList: [],
						AttachedManagedPolicies: [awsPolicies.AdministratorAccess],
					},
				],
			},
			change_logs: [
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '2c4f1c35-4eb2-4473-a8a3-db169edf910b',
					event_time: '2022-02-20T07:50:51Z',
					created_at: '2022-02-20T07:50:51.546241Z',
					event_name: 'CreateUser',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/gabriela.beard@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '78972903-598d-4f46-98c0-c1191fb161af',
					event_time: '2023-10-17T11:56:57Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'AttachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
						policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: 'ae07448e-900e-4389-9314-4bf69e460295',
					event_time: '2023-10-17T11:58:03Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'CreateAccessKey',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '04ac177d-a465-4053-a75d-928ff037652b',
					event_time: '2023-12-31T15:07:01Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'UpdateAccessKey',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						status: 'Inactive',
						userName: 'gabrielabeard',
						accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '98bd256f-7bf3-4b61-81b3-229c8dc24916',
					event_time: '2023-12-31T15:07:09Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'DeleteAccessKey',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
						accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '806b98a7-2952-493a-8225-e0884423b5d6',
					event_time: '2023-12-31T15:07:26Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'CreateAccessKey',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '3f1c890c-bd11-4d62-808e-99b14d8a0eac',
					event_time: '2023-12-31T15:09:37Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'PutUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
						policyName: 'eks-permissions',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Sid": "VisualEditor0",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "eks:*",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: 'a2b937e0-fc93-4db7-a9b5-4f45efa5eb88',
					event_time: '2024-03-17T14:28:02Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'AttachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
						policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: 'd2dd9957-133c-4c98-8dfd-d2273d7ccc30',
					event_time: '2024-03-18T11:47:00Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'DetachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
						policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '964d09b4-46b1-47e8-b314-02457447727a',
					event_time: '2024-03-25T21:05:41Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'AttachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '46.121.39.227',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
						policyArn: 'arn:aws:iam::710369796047:policy/eks-service-account-policy-for-pods',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0da',
					event_time: '2024-03-25T21:05:53Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'DetachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '46.121.39.227',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'gabrielabeard',
						policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '4f7f835a-5820-4570-bf8e-f7938235f889',
					event_time: '2024-08-07T11:59:39Z',
					created_at: '2024-08-08T06:29:03.145963Z',
					event_name: 'TagUser',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '46.121.39.227',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								Key: 'Project',
								Value: 'AWSMigration',
							},
						],
						userName: 'gabrielabeard',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '4f7f835a-5820-4570-bf8e-f7938235f889',
					event_time: '2024-10-07T12:24:39Z',
					created_at: '2024-10-08T08:24:03.145963Z',
					event_name: 'TagUser',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::877686312397:user/gabrielabeard',
					source_ip_address: '46.121.39.227',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								Key: 'Department',
								Value: 'IT',
							},
						],
						userName: 'gabrielabeard',
					},
				},
			],
			kubernetes_resources_xc: kubernetesResourcesXc2,
			endpoint_access_xc: endpointAccessXc,
			usage_logs: awsUsageLogs,
		},
	},
	'4': {
		id: '4',
		literal: 'arn:aws:iam::467791917130:user/michael-quinn',
		literal_friendly_name: 'michael-quinn',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.generic_policy_for_shadow_admin]),
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'michael-quinn',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/michael-quinn',
			mfa_active: false,
			usage_logs: awsUsageLogsOld,
			change_logs: getChangeLogs1('michael-quinn', 'arn:aws:iam::467791917130:user/michael-quinn'),
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'michael-quinn',
				UserId: 'CYXMKFBZTQ8WL36HNDGS',
				Arn: 'arn:aws:iam::467791917130:user/michael-quinn',
				CreateDate: '2018-12-20T07:21:30Z',
				UserPolicyList: [],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.generic_policy_for_shadow_admin],
				Tags: [],
				GroupListPermissions: [],
			},
		},
	},
	'5': {
		id: '5',
		literal: 'arn:aws:iam::467791917130:user/danielle-graham',
		literal_friendly_name: 'danielle-graham',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'danielle-graham',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/danielle-graham',
			mfa_active: false,
			change_logs: getChangeLogs2('danielle-graham', 'arn:aws:iam::467791917130:user/danielle-graham'),
		},
	},
	'6': {
		id: '6',
		literal: 'arn:aws:iam::467791917130:user/thomas-pearson',
		literal_friendly_name: 'thomas-pearson',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'thomas-pearson',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/thomas-pearson',
			mfa_active: false,
			change_logs: getChangeLogs1('thomas-pearson', 'arn:aws:iam::467791917130:user/thomas-pearson'),
		},
	},
	'7': {
		id: '7',
		literal: 'arn:aws:iam::467791917130:user/alicia-nash',
		literal_friendly_name: 'alicia-nash',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'alicia-nash',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/alicia-nash',
			mfa_active: false,
			change_logs: getChangeLogs1('alicia-nash', 'arn:aws:iam::467791917130:user/alicia-nash'),
		},
	},
	'8': {
		id: '8',
		literal: 'arn:aws:iam::467791917130:user/wanda-rhodes',
		literal_friendly_name: 'wanda-rhodes',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'wanda-rhodes',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/wanda-rhodes',
			mfa_active: false,
			change_logs: getChangeLogs2('wanda-rhodes', 'arn:aws:iam::467791917130:user/wanda-rhodes'),
		},
	},
	'9': {
		id: '9',
		literal: 'arn:aws:iam::467791917130:user/kara-boyer',
		literal_friendly_name: 'kara-boyer',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'kara-boyer',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/kara-boyer',
			mfa_active: false,
			change_logs: getChangeLogs1('kara-boyer', 'arn:aws:iam::467791917130:user/kara-boyer'),
		},
	},
	'10': {
		id: '10',
		literal: 'arn:aws:iam::467791917130:user/kathleen-pena',
		literal_friendly_name: 'kathleen-pena',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'kathleen-pena',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/kathleen-pena',
			mfa_active: false,
			change_logs: getChangeLogs1('kathleen-pena', 'arn:aws:iam::467791917130:user/kathleen-pena'),
		},
	},
	'11': {
		id: '11',
		literal: 'arn:aws:iam::467791917130:user/alex-white',
		literal_friendly_name: 'alex-white',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.generic_policy_for_shadow_admin]),
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'alex-white',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/alex-white',
			mfa_active: false,
			usage_logs: awsUsageLogsOld,
			change_logs: getChangeLogs2('alex-white', 'arn:aws:iam::467791917130:user/alex-white'),
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'alex-white',
				UserId: 'LNFHVWQKXP4TD97ZBJMR',
				Arn: 'arn:aws:iam::467791917130:user/alex-white',
				CreateDate: '2018-12-20T07:21:30Z',
				UserPolicyList: [],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.generic_policy_for_shadow_admin],
				Tags: [],
				GroupListPermissions: [],
			},
		},
	},
	'12': {
		id: '12',
		literal: 'arn:aws:iam::467791917130:user/denise-gomez',
		literal_friendly_name: 'denise-gomez',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'denise-gomez',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/denise-gomez',
			mfa_active: false,
			change_logs: getChangeLogs2('denise-gomez', 'arn:aws:iam::467791917130:user/denise-gomez'),
		},
	},
	'13': {
		id: '13',
		literal: 'arn:aws:iam::467791917130:user/amy-weber',
		literal_friendly_name: 'amy-weber',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'amy-weber',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/amy-weber',
			mfa_active: false,
			change_logs: getChangeLogs2('amy-weber', 'arn:aws:iam::467791917130:user/amy-weber'),
		},
	},
	'14': {
		id: '14',
		literal: 'arn:aws:iam::467791917130:user/joseph-snyder',
		literal_friendly_name: 'joseph-snyder',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'joseph-snyder',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/joseph-snyder',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-04-07T14:38:50.127371',
				last_rotated: '2024-01-08T14:38:50.127371',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIAQXA81QB8PY792KBN',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-04-07T14:38:50.127371',
					last_rotated: '2024-01-08T14:38:50.127371',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIAQXA81QB8PY792KBN',
				},
			],
			change_logs: getChangeLogs1('joseph-snyder', 'arn:aws:iam::467791917130:user/joseph-snyder'),
		},
	},
	'15': {
		id: '15',
		literal: 'arn:aws:iam::467791917130:user/mark-foster',
		literal_friendly_name: 'mark-foster',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'mark-foster',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/mark-foster',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-03-25T14:38:50.127622',
				last_rotated: '2023-12-26T14:38:50.127622',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIAUKTIAKQT1K42675V',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-03-25T14:38:50.127622',
					last_rotated: '2023-12-26T14:38:50.127622',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIAUKTIAKQT1K42675V',
				},
			],
			change_logs: getChangeLogs2('mark-foster', 'arn:aws:iam::467791917130:user/mark-foster'),
		},
	},
	'16': {
		id: '16',
		literal: 'arn:aws:iam::467791917130:user/thomas-cordova',
		literal_friendly_name: 'thomas-cordova',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'thomas-cordova',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/thomas-cordova',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-03-26T14:38:50.127796',
				last_rotated: '2023-12-27T14:38:50.127796',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIAXECCV3MRNI5WMWW9',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-03-26T14:38:50.127796',
					last_rotated: '2023-12-27T14:38:50.127796',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIAXECCV3MRNI5WMWW9',
				},
			],
			change_logs: getChangeLogs1('thomas-cordova', 'arn:aws:iam::467791917130:user/thomas-cordova'),
		},
	},
	'17': {
		id: '17',
		literal: 'arn:aws:iam::467791917130:user/james-taylor',
		literal_friendly_name: 'james-taylor',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'james-taylor',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/james-taylor',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-04-03T14:38:50.127959',
				last_rotated: '2024-01-04T14:38:50.127959',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIAMEZZDDM2M2U922OX',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-01-30 13:16:00+00:00',
					last_rotated: '2023-12-07 12:36:09+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIAMEZZDDM2M2U922OX',
				},
			],
			change_logs: getChangeLogs2('james-taylor', 'arn:aws:iam::467791917130:user/james-taylor'),
		},
	},
	'18': {
		id: '18',
		literal: 'arn:aws:iam::467791917130:user/travis-rodriguez',
		literal_friendly_name: 'travis-rodriguez',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'travis-rodriguez',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/travis-rodriguez',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-03-29T14:38:50.128123',
				last_rotated: '2023-12-30T14:38:50.128123',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIAKZGW04V9Z9AILUHM',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-03-29T14:38:50.128123',
					last_rotated: '2023-12-30T14:38:50.128123',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIAKZGW04V9Z9AILUHM',
				},
			],
			change_logs: getChangeLogs1('travis-rodriguez', 'arn:aws:iam::467791917130:user/travis-rodriguez'),
		},
	},
	'19': {
		id: '19',
		literal: 'arn:aws:iam::467791917130:user/donna-nelson',
		literal_friendly_name: 'donna-nelson',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'donna-nelson',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/donna-nelson',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-04-09T14:38:50.128289',
				last_rotated: '2024-01-10T14:38:50.128289',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIAUBMHBM8F62ERBCRO',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-04-09T14:38:50.128289',
					last_rotated: '2024-01-10T14:38:50.128289',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIAUBMHBM8F62ERBCRO',
				},
			],
			change_logs: getChangeLogs2('donna-nelson', 'arn:aws:iam::467791917130:user/donna-nelson'),
		},
	},
	'20': {
		id: '20',
		literal: 'arn:aws:iam::467791917130:user/wendy-stewart',
		literal_friendly_name: 'wendy-stewart',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'wendy-stewart',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/wendy-stewart',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-03-23T14:38:50.128448',
				last_rotated: '2023-12-24T14:38:50.128448',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIA8PAO7DAMADG8TJY7',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-03-23T14:38:50.128448',
					last_rotated: '2023-12-24T14:38:50.128448',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIA8PAO7DAMADG8TJY7',
				},
			],
			change_logs: getChangeLogs1('wendy-stewart', 'arn:aws:iam::467791917130:user/wendy-stewart'),
		},
	},
	'21': {
		id: '21',
		literal: 'arn:aws:iam::467791917130:user/maria-kemp',
		literal_friendly_name: 'maria-kemp',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'maria-kemp',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/maria-kemp',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-04-04T14:38:50.128614',
				last_rotated: '2024-01-05T14:38:50.128614',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIAOZOFMYZI3YO697HL',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-04-04T14:38:50.128614',
					last_rotated: '2024-01-05T14:38:50.128614',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIAOZOFMYZI3YO697HL',
				},
			],
			change_logs: getChangeLogs2('maria-kemp', 'arn:aws:iam::467791917130:user/maria-kemp'),
		},
	},
	'22': {
		id: '22',
		literal: 'arn:aws:iam::467791917130:user/gina-rodriguez',
		literal_friendly_name: 'gina-rodriguez',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'gina-rodriguez',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/gina-rodriguez',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-04-12T14:38:50.128780',
				last_rotated: '2024-01-13T14:38:50.128780',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIAYT609R22VC63R658',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-04-12T14:38:50.128780',
					last_rotated: '2024-01-13T14:38:50.128780',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIAYT609R22VC63R658',
				},
			],
			change_logs: getChangeLogs1('gina-rodriguez', 'arn:aws:iam::467791917130:user/gina-rodriguez'),
		},
	},
	'23': {
		id: '23',
		literal: 'arn:aws:iam::467791917130:user/joe-hayes',
		literal_friendly_name: 'joe-hayes',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'joe-hayes',
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::467791917130:user/joe-hayes',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-04-03T14:38:50.128940',
				last_rotated: '2024-01-04T14:38:50.128940',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIANPL70647KHQOQ9WK',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-04-03T14:38:50.128940',
					last_rotated: '2024-01-04T14:38:50.128940',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIANPL70647KHQOQ9WK',
				},
			],
			change_logs: getChangeLogs2('joe-hayes', 'arn:aws:iam::467791917130:user/joe-hayes'),
		},
	},
	'34': {
		id: '34',
		literal: 'arn:aws:iam::467791917130:user/yt-it-aws',
		literal_friendly_name: 'yt-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-03-27T16:57:38.006713',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.generic_policy_for_shadow_admin]),
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'yt-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-03-27T16:57:38.006713',
			arn: 'arn:aws:iam::467791917130:user/yt-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'yt-it-aws',
				UserId: 'QNFKXPWLZS7TD83MHBGR',
				Arn: 'arn:aws:iam::467791917130:user/yt-it-aws',
				CreateDate: '2018-02-28T22:23:33Z',
				UserPolicyList: [],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.generic_policy_for_shadow_admin],
				Tags: [],
				GroupListPermissions: [],
			},
			change_logs: getChangeLogs1('yt-it-aws', 'arn:aws:iam::467791917130:user/yt-it-aws'),
			usage_logs: awsUsageLogsOld,
		},
	},
	'35': {
		id: '35',
		literal: 'arn:aws:iam::467791917130:user/uo-it-aws',
		literal_friendly_name: 'uo-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-03-23T16:57:38.007019',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'uo-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-03-23T16:57:38.007019',
			arn: 'arn:aws:iam::467791917130:user/uo-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			change_logs: getChangeLogs1('uo-it-aws', 'arn:aws:iam::467791917130:user/uo-it-aws'),
		},
	},
	'36': {
		id: '36',
		literal: 'arn:aws:iam::467791917130:user/gx-it-aws',
		literal_friendly_name: 'gx-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-04-02T16:57:38.007255',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'gx-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-04-02T16:57:38.007255',
			arn: 'arn:aws:iam::467791917130:user/gx-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			change_logs: getChangeLogs2('gx-it-aws', 'arn:aws:iam::467791917130:user/gx-it-aws'),
		},
	},
	'37': {
		id: '37',
		literal: 'arn:aws:iam::467791917130:user/zm-it-aws',
		literal_friendly_name: 'zm-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-03-28T16:57:38.007495',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'zm-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-03-28T16:57:38.007495',
			arn: 'arn:aws:iam::467791917130:user/zm-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			change_logs: getChangeLogs2('zm-it-aws', 'arn:aws:iam::467791917130:user/zm-it-aws'),
		},
	},
	'38': {
		id: '38',
		literal: 'arn:aws:iam::467791917130:user/zm-it-aws',
		literal_friendly_name: 'zm-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-03-13T16:57:38.007743',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'zm-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-03-13T16:57:38.007743',
			arn: 'arn:aws:iam::467791917130:user/zm-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			change_logs: getChangeLogs1('zm-it-aws', 'arn:aws:iam::467791917130:user/zm-it-aws'),
		},
	},
	'39': {
		id: '39',
		literal: 'arn:aws:iam::467791917130:user/mf-it-aws',
		literal_friendly_name: 'mf-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-04-03T16:57:38.007963',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'mf-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-04-03T16:57:38.007963',
			arn: 'arn:aws:iam::467791917130:user/mf-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			change_logs: getChangeLogs2('mf-it-aws', 'arn:aws:iam::467791917130:user/mf-it-aws'),
		},
	},
	'40': {
		id: '40',
		literal: 'arn:aws:iam::467791917130:user/ft-it-aws',
		literal_friendly_name: 'ft-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-03-28T16:57:38.008177',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'ft-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-03-28T16:57:38.008177',
			arn: 'arn:aws:iam::467791917130:user/ft-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			change_logs: getChangeLogs1('ft-it-aws', 'arn:aws:iam::467791917130:user/ft-it-aws'),
		},
	},
	'41': {
		id: '41',
		literal: 'arn:aws:iam::467791917130:user/ry-it-aws',
		literal_friendly_name: 'ry-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-03-12T16:57:38.008390',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'ry-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-03-12T16:57:38.008390',
			arn: 'arn:aws:iam::467791917130:user/ry-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			change_logs: getChangeLogs1('ry-it-aws', 'arn:aws:iam::467791917130:user/ry-it-aws'),
		},
	},
	'42': {
		id: '42',
		literal: 'arn:aws:iam::467791917130:user/vp-it-aws',
		literal_friendly_name: 'vp-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-04-02T16:57:38.008601',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.generic_policy_for_shadow_admin]),
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'vp-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-04-02T16:57:38.008601',
			arn: 'arn:aws:iam::467791917130:user/vp-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'vp-it-aws',
				UserId: 'RVDTPJLHZM5BG29NQFXW',
				Arn: 'arn:aws:iam::467791917130:user/vp-it-aws',
				CreateDate: '2018-02-28T22:23:33Z',
				UserPolicyList: [],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.generic_policy_for_shadow_admin],
				Tags: [],
				GroupListPermissions: [],
			},
			change_logs: getChangeLogs2('vp-it-aws', 'arn:aws:iam::467791917130:user/vp-it-aws'),
			usage_logs: awsUsageLogsOld,
		},
	},
	'43': {
		id: '43',
		literal: 'arn:aws:iam::467791917130:user/ba-it-aws',
		literal_friendly_name: 'ba-it-aws',
		created_at: '2018-02-28T22:23:33Z',
		updated_at: '2024-04-11T05:10:45.804968Z',
		last_activity: '2024-04-09T16:57:38.008811',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'ba-it-aws',
			created_at: '2018-02-28T22:23:33Z',
			last_activity: '2024-04-09T16:57:38.008811',
			arn: 'arn:aws:iam::467791917130:user/ba-it-aws',
			mfa_active: true,
			password_info: {
				enabled: true,
				last_changed: '2018-02-28 22:23:33+00:00',
				next_rotation: '2018-05-29 22:23:33+00:00',
			},
			change_logs: getChangeLogs1('ba-it-aws', 'arn:aws:iam::467791917130:user/ba-it-aws'),
		},
	},
	'65': {
		id: '65',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: 'arn:aws:iam::862947542951:user/ci-user',
		literal_friendly_name: 'ci-user',
		created_at: '2023-10-11T11:57:28Z',
		updated_at: '2024-04-17T07:01:20.243993Z',
		last_activity: '2024-01-30T13:16:00Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Hybrid,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([
			awsPolicies.AWSCodeBuildAdminAccess,
			awsPolicies.AmazonS3FullAccess,
			awsPolicies.ec2_s3_for_ci_user,
		]),
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		account_literal: '862947542951',
		account_literal_friendly_name: 'briks-prod',
		aws_iam_user: {
			kubernetes_resources_xc: kubernetesResourcesXc1,
			change_logs: [
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: 'ae62760a-0afb-466e-98c6-fd0eb732982a',
					event_time: '2024-01-18T07:00:32Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'CreateAccessKey',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: 'b0795601-78bf-420d-88dc-dbfe5b9b1310',
					event_time: '2024-01-18T07:00:33Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'TagUser',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								key: 'AKIASCWPEBX2YCO73VWP',
								value: 'gitlab-ci',
							},
						],
						userName: 'ci-user',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '183c1253-5fc1-4b90-b531-72e6c2eb59f7',
					event_time: '2024-01-18T07:36:59Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:ListAllMyBuckets",\n\t\t\t\t"s3:ListBucket"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: 'dcc5f628-38b4-40ee-b057-6d5354d632b7',
					event_time: '2024-01-18T08:44:20Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '7971c541-1c0b-4fa5-b968-cb1e9bcb1cbd',
					event_time: '2024-01-19T06:25:51Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: 'a663e095-4489-4768-b279-67dd8edf8127',
					event_time: '2024-01-19T06:31:10Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeInstanceTypes"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '0e6e93df-c42a-49a9-8b77-9ea399e55a50',
					event_time: '2024-01-19T06:51:16Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeInstanceTypes"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '679a0b74-5c81-4492-8957-e1ab2ec0f448',
					event_time: '2024-01-19T06:57:08Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '35ec05e1-b5ef-407c-a15d-126eda311708',
					event_time: '2024-01-19T08:03:07Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:DescribeVolumes"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '1218980d-f95b-461c-a0a5-c386c08de449',
					event_time: '2024-01-19T08:48:22Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '092cb8d2-58c5-4b90-8003-19bb5720f9a8',
					event_time: '2024-01-19T08:51:15Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: 'b99da50e-1a7e-49c1-bfce-5a0bb22ed4a6',
					event_time: '2024-01-19T08:55:02Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '9011b4c1-7161-43e1-b311-2aa1459f35b3',
					event_time: '2024-01-19T10:26:43Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '9dd1dd2f-6fff-42ba-9261-bb599e25b708',
					event_time: '2024-01-19T10:28:14Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeImages",\n\t\t\t\t"ec2:DescribeKeyPairs",\n\t\t\t\t"ec2:DescribeSecurityGroups",\n\t\t\t\t"ec2:DescribeAvailabilityZones",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:TerminateInstances",\n\t\t\t\t"ec2:StopInstances",\n\t\t\t\t"ec2:StartInstances",\n\t\t\t\t"ec2:MonitorInstances",\n\t\t\t\t"ec2:ModifyInstanceAttribute",\n\t\t\t\t"ec2:UnmonitorInstances",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '429db57c-6c9e-4b03-b99e-d625974a3378',
					event_time: '2024-01-19T13:19:48Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '9f9a24aa-d92c-4dc6-acf6-84b6e69d7cab',
					event_time: '2024-01-19T13:21:39Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n    "Sid": "s3ListAllBuckets",\n    "Effect": "Allow",\n    "Action": [\n        "s3:ListAllMyBuckets",\n        "s3:ListBucket"\n    ],\n    "Resource": [\n        "*"\n    ]\n},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '284925f6-bbeb-4188-a24b-d876dc3dae2d',
					event_time: '2024-01-19T13:24:39Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t    "arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3ListAllBuckets",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:ListAllMyBuckets",\n\t\t\t\t"s3:ListBucket"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '926f25c3-4f18-4f0d-b779-de3618de110f',
					event_time: '2024-01-19T13:40:15Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3ListAllBuckets",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:ListAllMyBuckets",\n\t\t\t\t"s3:ListBucket",\n\t\t\t\t"s3:GetObject",\n\t\t\t\t"s3:GetBucketAcl",\n\t\t\t\t"s3:GetBucketVersioning"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '6b30b092-9937-409a-ade0-5f8f35729657',
					event_time: '2024-01-19T13:45:11Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3ListAllBuckets",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '46185691-cc9f-4172-b98b-c40e05eeef7d',
					event_time: '2024-01-19T13:50:04Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3BucketPolicyForTF",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t    "s3:PutObject",\n              "s3:GetObject",\n              "s3:DeleteObject",\n              "s3:ListBucket",\n              "s3:GetBucketAcl",\n              "s3:PutBucketAcl",\n              "s3:GetBucketVersioning",\n              "s3:PutBucketVersioning"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: 'd1825748-3503-4917-a598-60abc7e2ec4b',
					event_time: '2024-01-19T13:58:58Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3BucketPolicyForTF",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:PutObject",\n\t\t\t\t"s3:GetObject",\n\t\t\t\t"s3:DeleteObject",\n\t\t\t\t"s3:ListBucket",\n\t\t\t\t"s3:GetBucketAcl",\n\t\t\t\t"s3:PutBucketAcl",\n\t\t\t\t"s3:GetBucketVersioning",\n\t\t\t\t"s3:PutBucketVersioning",\n\t\t\t\t"s3:CreateBucket"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: 'b682855b-40f2-4c59-9674-d4846f773274',
					event_time: '2024-01-19T14:03:00Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-br-developer/amy.ford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '19.191.21.128',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Action": "s3:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app",\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Sid": "s3BucketPolicyForTF",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"s3:*"\n\t\t\t],\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:RunInstances",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t},\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '0d3c2660-0aed-4e43-b877-d882d14e1530',
					event_time: '2024-01-31T06:12:49Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n      "Version": "2012-10-17",\n      "Statement": [\n        {\n          "Effect": "Allow",\n          "Action": "s3:*",\n          "Resource": [\n            "arn:aws:s3:::terr-state-synergy/event-management-app/*"\n          ]\n        },\n        {\n          "Effect": "Allow",\n          "Action": [\n            "iam:PassRole"\n          ],\n          "Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n        },\n        {\n          "Effect": "Allow",\n          "Action": "dynamodb:*",\n          "Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n        },\n        {\n            "Action": [\n                "ec2:DescribeInstances",\n                "ec2:DescribeTags",\n                "ec2:DescribeInstanceTypes",\n                "ec2:DescribeVolumes",\n                "ec2:DescribeInstanceAttribute",\n                "ec2:DescribeInstanceCreditSpecifications",\n                "ec2:CreateTags"\n            ],\n            "Effect": "Allow",\n            "Resource": "*"\n        },\n        {\n          "Effect": "Allow",\n          "Action": [\n            "ec2:*"\n          ],\n          "Resource": "*",\n          "Condition": {\n            "StringEquals": {\n              "aws:ResourceTag/Component": "event-manager-app",\n              "aws:ResourceTag/Team": "Analytics"\n            }\n          }\n        }\n      ]\n    }',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: '6901b8b2-249a-4d80-8d34-7c0f15068ae6',
					event_time: '2024-01-31T06:22:36Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "s3:*",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n            "Action": [\n                "s3:Get*",\n                "s3:List*"\n            ],\n            "Effect": "Allow",\n            "Resource": "*"\n        },\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:CreateTags"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Resource": "*",\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: 'b5ee15a5-21be-4e2d-b8d5-12e5395810f2',
					id: 'e7e0f367-bb7f-4e20-bb22-06be1eb59163',
					event_time: '2024-01-31T06:31:03Z',
					created_at: '2024-05-09T05:11:51.953407Z',
					event_name: 'PutUserPolicy',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.121.123.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'ci-user',
						policyName: 'vmimport',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "s3:*",\n\t\t\t"Resource": [\n\t\t\t\t"arn:aws:s3:::terr-state-synergy/event-management-app/*"\n\t\t\t]\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"s3:Get*",\n\t\t\t\t"s3:List*"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"iam:PassRole"\n\t\t\t],\n\t\t\t"Resource": "arn:aws:iam::862947542951:role/SynergyAnalyticsProd"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "dynamodb:*",\n\t\t\t"Resource": "arn:aws:dynamodb:us-east-1:862947542951:table/terraform-locks-event-manager-app"\n\t\t},\n\t\t{\n\t\t\t"Action": [\n\t\t\t\t"ec2:DescribeInstances",\n\t\t\t\t"ec2:DescribeTags",\n\t\t\t\t"ec2:DescribeInstanceTypes",\n\t\t\t\t"ec2:DescribeVolumes",\n\t\t\t\t"ec2:DescribeInstanceAttribute",\n\t\t\t\t"ec2:DescribeInstanceCreditSpecifications",\n\t\t\t\t"ec2:CreateTags",\n\t\t\t\t"ec2:RunInstances"\n\t\t\t],\n\t\t\t"Effect": "Allow",\n\t\t\t"Resource": "*"\n\t\t},\n\t\t{\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": [\n\t\t\t\t"ec2:*"\n\t\t\t],\n\t\t\t"Resource": "*",\n\t\t\t"Condition": {\n\t\t\t\t"StringEquals": {\n\t\t\t\t\t"aws:ResourceTag/Component": "event-manager-app",\n\t\t\t\t\t"aws:ResourceTag/Team": "Analytics"\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '4f7f835a-5820-4570-bf8e-f7938235f889',
					event_time: '2024-08-07T11:59:39Z',
					created_at: '2024-08-08T06:29:03.145963Z',
					event_name: 'TagUser',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.120.12.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								Key: 'Project',
								Value: 'AWSMigration',
							},
						],
						userName: 'ci-user',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '4f7f835a-5820-4570-bf8e-f7938235f889',
					event_time: '2024-10-07T12:24:39Z',
					created_at: '2024-10-08T08:24:03.145963Z',
					event_name: 'TagUser',
					actor_arn: 'arn:aws:sts::862947542951:assumed-role/jumpcloud-Ops/levi.sanford@synergy.com',
					affected_arn: 'arn:aws:iam::862947542951:user/ci-user',
					source_ip_address: '103.120.12.211',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								Key: 'Department',
								Value: 'IT',
							},
						],
						userName: 'ci-user',
					},
				},
			],
			name: 'ci-user',
			created_at: '2023-10-11T11:57:28Z',
			last_activity: '2024-01-30T13:16:00Z',
			arn: 'arn:aws:iam::862947542951:user/ci-user',
			mfa_active: true,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-01-30 13:16:00+00:00',
				last_rotated: '2023-12-07 12:36:09+00:00',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIA8664T8TT477PMKGR',
			},
			access_key_2: {
				is_active: false,
				last_used_date: null,
				last_rotated: null,
				last_used_region: null,
				last_used_service: null,
			},
			cert_1: {
				is_active: false,
				last_rotated: null,
			},
			cert_2: {
				is_active: false,
				last_rotated: null,
			},
			password_info: {
				enabled: true,
				last_changed: '2023-10-11 11:57:28+00:00',
				next_rotation: null,
			},
			okta_user_xc: [],
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'ci-user',
				UserId: 'AIDATOPRMNY7VSYGUGEV6',
				Arn: 'arn:aws:iam::862947542951:user/ci-user',
				CreateDate: '2023-10-11T11:57:28Z',
				UserPolicyList: [awsPolicies.ec2_s3_for_ci_user],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.AWSCodeBuildAdminAccess, awsPolicies.AmazonS3FullAccess],
				Tags: [
					{
						Key: 'Project',
						Value: 'AWSMigration',
					},
					{
						Key: 'Department',
						Value: 'IT',
					},
				],
				GroupListPermissions: [],
			},
			aws_iam_access_keys_xc: [
				{
					access_key_id: 'AKIA8664T8TT477PMKGR',
					is_active: true,
					last_used_date: '2024-01-30 13:16:00+00:00',
					last_rotated: '2023-12-07 12:36:09+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
				},
			],
			usage_logs: awsUsageLogs,
			demo_iac_data: [awsIamIaCData['1'], awsIamIaCData['2'], awsIamIaCData['3'], awsIamIaCData['4']],
		},
	},
	'66': {
		id: '66',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: 'arn:aws:iam::296957980053:user/terraform-prod',
		literal_friendly_name: 'terraform-prod',
		created_at: '2023-10-22T13:35:01Z',
		updated_at: '2024-04-17T07:01:20.210437Z',
		last_activity: '2023-10-23T17:27:00Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.full_access_policy_for_testing]),
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		account_literal: '296957980053',
		account_literal_friendly_name: 'Nexovate',
		aws_iam_user: {
			name: 'terraform-prod',
			created_at: '2023-10-22T13:35:01Z',
			last_activity: '2023-10-23T17:27:00Z',
			arn: 'arn:aws:iam::296957980053:user/terraform-prod',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2023-10-23 17:27:00+00:00',
				last_rotated: '2023-10-22 13:35:20+00:00',
				last_used_region: 'us-east-1',
				last_used_service: 'ec2',
				access_key_id: 'AKIANPL70647KHQOQ9WK',
			},
			access_key_2: {
				is_active: false,
				last_used_date: null,
				last_rotated: null,
				last_used_region: null,
				last_used_service: null,
			},
			cert_1: {
				is_active: false,
				last_rotated: null,
			},
			cert_2: {
				is_active: false,
				last_rotated: null,
			},
			password_info: {
				enabled: false,
				last_changed: null,
				next_rotation: null,
			},
			okta_user_xc: [],
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'terraform-prod',
				UserId: 'AIDAZBV2XIOIS732SNSN',
				Arn: 'arn:aws:iam::296957980053:user/terraform-prod',
				CreateDate: '2023-10-22T13:35:01Z',
				UserPolicyList: [awsPolicies.full_access_policy_for_testing],
				GroupList: [],
				AttachedManagedPolicies: [],
				Tags: [],
				GroupListPermissions: [],
			},
			aws_iam_access_keys_xc: [
				{
					access_key_id: 'AKIAN0EETWMVEF2V4ZHY',
					is_active: true,
					last_used_date: '2023-10-23 17:27:00+00:00',
					last_rotated: '2023-10-22 13:35:20+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'ec2',
				},
			],
			change_logs: getChangeLogs2('terraform-prod', 'arn:aws:iam::296957980053:user/terraform-prod'),
			usage_logs: awsUsageLogsOld,
		},
	},
	'83': {
		id: '83',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: 'arn:aws:iam::818837914182:user/prod-eks',
		literal_friendly_name: 'prod-eks',
		created_at: '2023-10-17T11:56:57Z',
		updated_at: '2024-08-28T07:01:19.639651Z',
		last_activity: '2024-08-28T07:01:19.639651Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.eks_service_account_policy_for_pods, awsPolicies.eks_permissions]),
		account_literal: '818837914182',
		account_literal_friendly_name: 'prod',
		aws_iam_user: {
			kubernetes_resources_xc: kubernetesResourcesXc1,
			name: 'prod-eks',
			created_at: '2023-10-17T11:56:57Z',
			last_activity: '2024-04-11T15:49:00Z',
			arn: 'arn:aws:iam::818837914182:user/prod-eks',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-04-11 15:49:00+00:00',
				last_rotated: '2023-12-31 15:07:26+00:00',
				last_used_region: 'us-east-1',
				last_used_service: 'sts',
				access_key_id: 'AKIANPL70647KHQOQ9WK',
			},
			access_key_2: {
				is_active: false,
				last_used_date: null,
				last_rotated: null,
				last_used_region: null,
				last_used_service: null,
			},
			cert_1: {
				is_active: false,
				last_rotated: null,
			},
			cert_2: {
				is_active: false,
				last_rotated: null,
			},
			password_info: {
				enabled: false,
				last_changed: null,
				next_rotation: null,
			},
			okta_user_xc: [],
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'prod-eks',
				UserId: 'AIDARMCTCXBT0CS79HJC',
				Arn: 'arn:aws:iam::818837914182:user/prod-eks',
				CreateDate: '2023-10-17T11:56:57Z',
				UserPolicyList: [awsPolicies.eks_permissions],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.eks_service_account_policy_for_pods],
				Tags: [],
				GroupListPermissions: [],
			},
			aws_iam_access_keys_xc: [
				{
					access_key_id: 'AKIAPBNIDIR2Q3XMW36D',
					is_active: true,
					last_used_date: '2024-04-11 15:49:00+00:00',
					last_rotated: '2023-12-31 15:07:26+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'sts',
				},
			],
			change_logs: getChangeLogs2('prod-eks', 'arn:aws:iam::818837914182:user/prod-eks'),
			usage_logs: suspiciousIamBehaviourUsageLogs,
		},
	},
	'84': {
		id: '84',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: 'arn:aws:iam::818837914182:user/st-notifier-us',
		literal_friendly_name: 'st-notifier-us',
		created_at: '2023-10-17T11:56:57Z',
		updated_at: '2024-04-17T07:01:19.639651Z',
		last_activity: '2024-04-11T15:49:00Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.policies_for_shadow_admin_1]),
		account_literal: '818837914182',
		account_literal_friendly_name: 'prod',
		aws_iam_user: {
			name: 'prod-eks',
			created_at: '2023-10-17T11:56:57Z',
			last_activity: '2024-04-11T15:49:00Z',
			arn: 'arn:aws:iam::818837914182:user/st-notifier-us',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-04-11 15:49:00+00:00',
				last_rotated: '2023-12-31 15:07:26+00:00',
				last_used_region: 'us-east-1',
				last_used_service: 'sts',
				access_key_id: 'AKIAZTLFMVR7H9QJGX5N',
			},
			okta_user_xc: [],
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'st-notifier-us',
				UserId: 'BFGZLNQSWYP5DV36KMRE',
				Arn: 'arn:aws:iam::818837914182:user/st-notifier-us',
				CreateDate: '2023-10-17T11:56:57Z',
				UserPolicyList: [],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.policies_for_shadow_admin_1],
				Tags: [],
				GroupListPermissions: [],
			},
			aws_iam_access_keys_xc: [
				{
					access_key_id: 'AKIAZTLFMVR7H9QJGX5N',
					is_active: true,
					last_used_date: '2024-04-11 15:49:00+00:00',
					last_rotated: '2023-12-31 15:07:26+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'sts',
				},
			],
			change_logs: getChangeLogs2('st-notifier-us', 'arn:aws:iam::818837914182:user/st-notifier-us'),
		},
	},
	'170': {
		id: '170',
		literal: 'arn:aws:iam::710369796047:user/production-eks-user',
		literal_friendly_name: 'production-eks-user',
		created_at: '2022-02-20T07:50:51Z',
		updated_at: '2024-04-21T11:46:25.926083Z',
		last_activity: null,
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		tags: getTagsByPolicies([
			awsPolicies.eks_permissions,
			awsPolicies.eks_service_account_policy_for_pods,
			awsPolicies.AmazonEC2FullAccess,
		]),
		account_literal: '710369796047',
		account_literal_friendly_name: 'production',
		aws_iam_user: {
			change_logs: [
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '2c4f1c35-4eb2-4473-a8a3-db169edf910b',
					event_time: '2022-02-20T07:50:51Z',
					created_at: '2022-02-20T07:50:51.546241Z',
					event_name: 'CreateUser',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/brian.espinoza@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '78972903-598d-4f46-98c0-c1191fb161af',
					event_time: '2023-10-17T11:56:57Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'AttachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/brian.espinoza@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
						policyArn:
							'arn:aws:iam::710369796047:policy/production-eks-user-service-account-policy-for-pods',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: 'ae07448e-900e-4389-9314-4bf69e460295',
					event_time: '2023-10-17T11:58:03Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'CreateAccessKey',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/brian.espinoza@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '04ac177d-a465-4053-a75d-928ff037652b',
					event_time: '2023-12-31T15:07:01Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'UpdateAccessKey',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						status: 'Inactive',
						userName: 'production-eks-user',
						accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '98bd256f-7bf3-4b61-81b3-229c8dc24916',
					event_time: '2023-12-31T15:07:09Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'DeleteAccessKey',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
						accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '806b98a7-2952-493a-8225-e0884423b5d6',
					event_time: '2023-12-31T15:07:26Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'CreateAccessKey',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '3f1c890c-bd11-4d62-808e-99b14d8a0eac',
					event_time: '2023-12-31T15:09:37Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'PutUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
						policyName: 'eks-permissions',
						policyDocument:
							'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Sid": "VisualEditor0",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "eks:*",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: 'a2b937e0-fc93-4db7-a9b5-4f45efa5eb88',
					event_time: '2024-03-17T14:28:02Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'AttachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
						policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: 'd2dd9957-133c-4c98-8dfd-d2273d7ccc30',
					event_time: '2024-03-18T11:47:00Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'DetachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '5.182.59.83',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
						policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '964d09b4-46b1-47e8-b314-02457447727a',
					event_time: '2024-03-25T21:05:41Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'AttachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '46.121.39.227',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
						policyArn: 'arn:aws:iam::710369796047:policy/eks-service-account-policy-for-pods',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0da',
					event_time: '2024-03-25T21:05:53Z',
					created_at: '2024-05-07T15:00:09.546241Z',
					event_name: 'DetachUserPolicy',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
					affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
					source_ip_address: '46.121.39.227',
					user_agent: 'AWS Internal',
					request_parameters: {
						userName: 'production-eks-user',
						policyArn:
							'arn:aws:iam::710369796047:policy/production-eks-user-service-account-policy-for-pods',
					},
				},
			],
			name: 'production-eks-user',
			created_at: '2024-02-20T07:50:51Z',
			arn: 'arn:aws:iam::710369796047:user/production-eks-user',
			mfa_active: false,
			access_key_1: {
				is_active: true,
				last_used_date: null,
				last_rotated: '2024-02-20 07:51:10+00:00',
				last_used_region: null,
				access_key_id: 'AKIANPL70647KHQOQ9WK',
				last_used_service: null,
			},
			access_key_2: {
				is_active: false,
				last_used_date: null,
				last_rotated: null,
				last_used_region: null,
				last_used_service: null,
			},
			cert_1: {
				is_active: false,
				last_rotated: null,
			},
			cert_2: {
				is_active: false,
				last_rotated: null,
			},
			password_info: {
				enabled: false,
				last_changed: null,
				next_rotation: null,
			},
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'production-eks-user',
				UserId: 'AIDAMN8DI06J1S0HT2PA',
				Arn: 'arn:aws:iam::710369796047:user/production-eks-user',
				CreateDate: '2024-02-20T07:50:51Z',
				UserPolicyList: [awsPolicies.eks_permissions],
				GroupList: [],
				AttachedManagedPolicies: [
					awsPolicies.eks_service_account_policy_for_pods,
					awsPolicies.AmazonEC2FullAccess,
				],
				Tags: [],
				GroupListPermissions: [],
			},
			aws_iam_access_keys_xc: [
				{
					access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
					is_active: true,
					last_used_date: null,
					last_rotated: '2024-02-20 07:51:10+00:00',
					last_used_region: null,
					last_used_service: null,
				},
			],
			aws_iam_roles_xc: null,
			kubernetes_resources_xc: kubernetesResourcesXc4,
			usage_logs: awsUsageLogsOld,
		},
	},
	'172': {
		id: '172',
		literal: 'arn:aws:iam::623356180012:user/automation_serverless',
		literal_friendly_name: 'automation_serverless',
		created_at: '2019-12-22T03:21:30Z',
		updated_at: '2024-02-18T07:03:32.834455Z',
		last_activity: '2024-05-04T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.AdministratorAccess]),
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		account_literal: '623356180012',
		account_literal_friendly_name: 'AWS Prod',
		aws_iam_user: {
			name: 'automation_serverless',
			aws_account: awsAccountsInfo['623356180012'],
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::623356180012:user/automation_serverless',
			mfa_active: true,
			access_key_1: {
				is_active: true,
				last_used_date: '2023-04-17 10:29:00+00:00',
				last_rotated: '2021-10-13 08:23:10+00:00',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2023-04-17 10:29:00+00:00',
					last_rotated: '2021-10-13 08:23:10+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				},
			],
			access_key_2: null,
			cert_1: {
				is_active: false,
				last_rotated: null,
			},
			cert_2: {
				is_active: false,
				last_rotated: null,
			},
			password_info: null,
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'automation_serverless',
				UserId: 'AIDAJ7Q7ZQ7Q7Q7Q7Q7Q7',
				Arn: 'arn:aws:iam::623356180012:user/automation_serverless',
				CreateDate: '2018-12-20T07:21:30Z',
				UserPolicyList: [],
				GroupList: ['ynl-dev'],
				AttachedManagedPolicies: [],
				Tags: [
					{
						Key: 'Project',
						Value: 'AWSMigration',
					},
					{
						Key: 'Department',
						Value: 'IT',
					},
				],
				GroupListPermissions: [
					{
						Path: '/',
						GroupName: 'ynl-dev',
						GroupId: 'AGPADWF6WGTK92HMOCG4',
						Arn: 'arn:aws:iam::623356180012:group/ynl-dev',
						CreateDate: '2016-12-20T05:07:16Z',
						GroupPolicyList: [],
						AttachedManagedPolicies: [awsPolicies.AdministratorAccess],
					},
				],
			},
			change_logs: [
				...getChangeLogs1('automation_serverless', 'arn:aws:iam::623356180012:user/automation_serverless'),
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '4f7f835a-5820-4570-bf8e-f7938235f889',
					event_time: '2024-08-07T11:59:39Z',
					created_at: '2024-08-08T06:29:03.145963Z',
					event_name: 'TagUser',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
					affected_arn: 'arn:aws:iam::623356180012:user/automation_serverless',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								Key: 'Project',
								Value: 'AWSMigration',
							},
						],
						userName: 'gabrielabeard',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '4f7f835a-5820-4570-bf8e-f7938235f889',
					event_time: '2024-10-07T12:24:39Z',
					created_at: '2024-10-08T08:24:03.145963Z',
					event_name: 'TagUser',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
					affected_arn: 'arn:aws:iam::623356180012:user/automation_serverless',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								Key: 'Department',
								Value: 'IT',
							},
						],
						userName: 'gabrielabeard',
					},
				},
			],
			usage_logs: suspiciousIamBehaviourUsageLogs,
			aws_ec2_instances_xc: [
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-08f665a7994f33b40',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:58:53Z',
					PrivateIpAddress: '172.38.45.219',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Staging',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-staging-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0cb6586b166591a28',
					InstanceType: 't4g.micro',
					LaunchTime: '2024-02-23T19:37:06Z',
					PrivateIpAddress: '172.38.71.113',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-mob1-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-00a2750030d9347d4',
					InstanceType: 'r6g.2xlarge',
					LaunchTime: '2024-01-22T04:59:43Z',
					PrivateIpAddress: '172.38.80.221',
					PublicIpAddress: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					InstanceLifecycle: null,
					Tags: [
						{
							Key: 'Exporter',
							Value: 'node',
						},
						{
							Key: 'ComponentId',
							Value: '100301',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'analytics-airflow-run1-worker-airflow-batch-2.6.2-7',
						},
						{
							Key: 'Component',
							Value: 'Pipeline',
						},
						{
							Key: 'application',
							Value: 'analytics-pipeline-controller',
						},
						{
							Key: 'Environment',
							Value: 'Prod',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'update_rollup',
							Value: 'true',
						},
						{
							Key: 'Dosa',
							Value: 'true',
						},
						{
							Key: 'Project',
							Value: 'Analytics',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'173': {
		id: '173',
		literal: 'arn:aws:iam::623356180012:user/jenkins',
		literal_friendly_name: 'jenkins',
		created_at: '2019-12-21T03:21:30Z',
		updated_at: '2024-02-14T06:03:32.834455Z',
		last_activity: '2024-04-04T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.AdministratorAccess]),
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR, MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		account_literal: '623356180012',
		account_literal_friendly_name: 'AWS Prod',
		aws_iam_user: {
			name: 'jenkins',
			aws_account: awsAccountsInfo['623356180012'],
			created_at: '2019-12-21T03:21:30Z',
			last_activity: '2024-04-04T12:26:19Z',
			arn: 'arn:aws:iam::623356180012:user/jenkins',
			change_logs: getChangeLogs1('jenkins', 'arn:aws:iam::623356180012:user/jenkins'),
			mfa_active: true,
			access_key_1: {
				is_active: true,
				last_used_date: '2023-05-04 11:12:00+00:00',
				last_rotated: '2024-05-01 08:23:10+00:00',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIA0OASQOW1MU6AF1P4',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2023-05-04 11:12:00+00:00',
					last_rotated: '2024-05-01 08:23:10+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIA0OASQOW1MU6AF1P4',
				},
			],

			access_key_2: null,
			cert_1: {
				is_active: false,
				last_rotated: null,
			},
			cert_2: {
				is_active: false,
				last_rotated: null,
			},
			password_info: null,
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'jenkins',
				UserId: 'AIDAJ7Q7ZQ7Q7Q7Q7Q7Q7',
				Arn: 'arn:aws:iam::623356180012:user/jenkins',
				CreateDate: '2018-12-20T07:21:30Z',
				UserPolicyList: [],
				GroupList: ['ynl-dev'],
				AttachedManagedPolicies: [],
				Tags: [],
				GroupListPermissions: [
					{
						Path: '/',
						GroupName: 'ynl-dev',
						GroupId: 'AGPADWF6WGTK92HMOCG4',
						Arn: 'arn:aws:iam::623356180012:group/ynl-dev',
						CreateDate: '2016-12-20T05:07:16Z',
						GroupPolicyList: [],
						AttachedManagedPolicies: [awsPolicies.AdministratorAccess],
					},
				],
			},
		},
	},
	'174': {
		id: '174',
		literal: 'arn:aws:iam::623356180012:user/terraform',
		literal_friendly_name: 'terraform',
		created_at: '2019-12-22T03:21:30Z',
		updated_at: '2024-02-18T07:03:32.834455Z',
		last_activity: '2024-05-04T12:26:19Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.AdministratorAccess]),
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR, MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		account_literal: '623356180012',
		account_literal_friendly_name: 'AWS Prod',
		aws_iam_user: {
			name: 'terraform',
			aws_account: awsAccountsInfo['623356180012'],
			created_at: '2018-12-20T07:21:30Z',
			last_activity: '2024-04-08T12:26:19Z',
			arn: 'arn:aws:iam::623356180012:user/terraform',
			change_logs: getChangeLogs1('terraform', 'arn:aws:iam::623356180012:user/terraform'),
			mfa_active: true,
			access_key_1: {
				is_active: true,
				last_used_date: '2024-05-07 10:29:00+00:00',
				last_rotated: '2024-05-01 08:23:10+00:00',
				last_used_region: 'us-east-1',
				last_used_service: 'iam',
				access_key_id: 'AKIA3ODYTOW1MU6FA1P4',
			},
			aws_iam_access_keys_xc: [
				{
					is_active: true,
					last_used_date: '2024-05-07 10:29:00+00:00',
					last_rotated: '2024-05-01 08:23:10+00:00',
					last_used_region: 'us-east-1',
					last_used_service: 'iam',
					access_key_id: 'AKIA3ODYTOW1MU6FA1P4',
				},
			],
			access_key_2: null,
			cert_1: {
				is_active: false,
				last_rotated: null,
			},
			cert_2: {
				is_active: false,
				last_rotated: null,
			},
			password_info: null,
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'terraform',
				UserId: 'AIDAJ7Q7ZQ7Q7Q7Q7Q7Q7',
				Arn: 'arn:aws:iam::623356180012:user/terraform',
				CreateDate: '2018-12-20T07:21:30Z',
				UserPolicyList: [],
				GroupList: ['ynl-dev'],
				AttachedManagedPolicies: [],
				Tags: [],
				GroupListPermissions: [
					{
						Path: '/',
						GroupName: 'ynl-dev',
						GroupId: 'AGPADWF6WGTK92HMOCG4',
						Arn: 'arn:aws:iam::623356180012:group/ynl-dev',
						CreateDate: '2016-12-20T05:07:16Z',
						GroupPolicyList: [],
						AttachedManagedPolicies: [awsPolicies.AdministratorAccess],
					},
				],
			},
		},
	},
	'175': {
		id: '175',
		literal: 'arn:aws:iam::467791917130:user/lloyd-olson',
		literal_friendly_name: 'lloyd-olson',
		created_at: getRecentlyCreatedDate(),
		updated_at: getRecentlyCreatedDate(),
		last_activity: getRecentlyCreatedDate(),
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: [],
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'lloyd-olson',
			created_at: getRecentlyCreatedDate(),
			last_activity: getRecentlyCreatedDate(),
			arn: 'arn:aws:iam::467791917130:user/lloyd-olson',
			mfa_active: false,
			change_logs: getChangeLogs1('lloyd-olson', 'arn:aws:iam::467791917130:user/lloyd-olson'),
		},
	},
	'176': {
		id: '176',
		literal: 'arn:aws:iam::467791917130:user/dean-davis',
		literal_friendly_name: 'dean-davis',
		created_at: getRecentlyCreatedDate(),
		updated_at: getRecentlyCreatedDate(),
		last_activity: getRecentlyCreatedDate(),
		env_type: EnvironmentType.AWS,
		type: IdentityType.Human,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.generic_policy_for_shadow_admin]),
		account_literal: '467791917130',
		account_literal_friendly_name: 'Innovatech Solutions Prod',
		aws_iam_user: {
			name: 'dean-davis',
			created_at: getRecentlyCreatedDate(),
			last_activity: getRecentlyCreatedDate(),
			arn: 'arn:aws:iam::467791917130:user/dean-davis',
			mfa_active: false,
			change_logs: getChangeLogs1('dean-davis', 'arn:aws:iam::467791917130:user/dean-davis'),
			usage_logs: awsUsageLogsOld,
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'dean-davis',
				UserId: 'JHTXMKRFVWC9PN42LQSZ',
				Arn: 'arn:aws:iam::467791917130:user/dean-davis',
				CreateDate: getRecentlyCreatedDate(),
				UserPolicyList: [],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.generic_policy_for_shadow_admin],
				Tags: [],
				GroupListPermissions: [],
			},
		},
	},
	'177': {
		id: '177',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: 'arn:aws:iam::818837914182:user/Splunk_Access',
		literal_friendly_name: 'Splunk_Access',
		created_at: '2022-02-02T11:56:57Z',
		updated_at: '2024-08-29T07:01:19.639651Z',
		last_activity: '2024-08-29T15:49:00Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.splunkUmbrellaBucketPolicy]),
		account_literal: '818837914182',
		account_literal_friendly_name: 'prod',
		aws_iam_user: {
			name: 'Splunk_Access',
			created_at: '2022-02-02T11:56:57Z',
			last_activity: '2024-08-29T15:49:00Z',
			arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
			mfa_active: false,
			// Un-rotated Key
			access_key_1: {
				is_active: true,
				last_used_date: '2024-08-29 08:08:00+00:00',
				last_rotated: '2023-03-02 12:51:00+00:00',
				last_used_region: 'eu-west-1',
				last_used_service: 's3',
				access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
			},
			// Inactive key (Unused)
			access_key_2: {
				is_active: true,
				last_used_date: null,
				last_rotated: '2022-02-21 13:08:00+00:00',
				last_used_region: 'eu-west-1',
				last_used_service: null,
				access_key_id: 'AKIAZTLFMVR7K4LMNOPQ',
			},
			okta_user_xc: [],
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'Splunk_Access',
				UserId: 'BFGZLNQSWYP5DV36KMRE',
				Arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
				CreateDate: '2022-02-02T11:56:57Z',
				UserPolicyList: [],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.splunkUmbrellaBucketPolicy],
				Tags: [
					{
						Key: 'Project',
						Value: 'AWSMigration',
					},
					{
						Key: 'Department',
						Value: 'IT',
					},
				],
				GroupListPermissions: [],
			},
			aws_iam_access_keys_xc: [
				{
					access_key_id: 'AKIAZTLFMVR9X3PQRSTU',
					is_active: true,
					last_used_date: '2024-08-29 08:08:00+00:00',
					last_rotated: '2023-03-02 12:51:00+00:00',
					last_used_region: 'eu-west-1',
					last_used_service: 's3',
				},
				{
					access_key_id: 'AKIAZTLFMVR7K4LMNOPQ',
					is_active: true,
					last_used_date: null,
					last_rotated: '2022-02-21 13:08:00+00:00',
					last_used_region: null,
					last_used_service: null,
				},
			],
			change_logs: [
				...getChangeLogs1('Splunk_Access', 'arn:aws:iam::818837914182:user/Splunk_Access'),
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '4f7f835a-5820-4570-bf8e-f7938235f889',
					event_time: '2024-08-07T11:59:39Z',
					created_at: '2024-08-08T06:29:03.145963Z',
					event_name: 'TagUser',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
					affected_arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								Key: 'Project',
								Value: 'AWSMigration',
							},
						],
						userName: 'gabrielabeard',
					},
				},
				{
					customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
					environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
					sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
					id: '4f7f835a-5820-4570-bf8e-f7938235f889',
					event_time: '2024-10-07T12:24:39Z',
					created_at: '2024-10-08T08:24:03.145963Z',
					event_name: 'TagUser',
					actor_arn:
						'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/joseph.rogers@synergy.com',
					affected_arn: 'arn:aws:iam::818837914182:user/Splunk_Access',
					source_ip_address: '207.232.13.65',
					user_agent: 'AWS Internal',
					request_parameters: {
						tags: [
							{
								Key: 'Department',
								Value: 'IT',
							},
						],
						userName: 'gabrielabeard',
					},
				},
			],
			usage_logs: splunkUserUsageLogs,
		},
	},
	'178': {
		id: '178',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		literal: 'arn:aws:iam::818837914182:user/tableau-bkup-rstr',
		literal_friendly_name: 'tableau-bkup-rstr',
		created_at: '2022-02-02T11:56:57Z',
		updated_at: '2024-08-29T07:01:19.639651Z',
		last_activity: '2024-08-29T15:49:00Z',
		env_type: EnvironmentType.AWS,
		type: IdentityType.Machine,
		source: IdentitySource.AWS_IAM_USER,
		issues: [],
		tags: getTagsByPolicies([awsPolicies.tableauProdBuckupPolicy]),
		account_literal: '818837914182',
		account_literal_friendly_name: 'prod',
		aws_iam_user: {
			name: 'Splunk_Access',
			created_at: '2022-02-02T11:56:57Z',
			last_activity: '2024-08-29T15:49:00Z',
			arn: 'arn:aws:iam::818837914182:user/tableau-bkup-rstr',
			mfa_active: false,
			// Un-rotated Key
			access_key_1: {
				is_active: true,
				last_used_date: '2024-08-29 08:08:00+00:00',
				last_rotated: '2023-03-02 12:51:00+00:00',
				last_used_region: 'eu-west-1',
				last_used_service: 's3',
				access_key_id: 'AKIAZTLFMVR9X3PQRSTF',
			},
			// Inactive key (Unused)
			access_key_2: {
				is_active: true,
				last_used_date: null,
				last_rotated: '2022-02-21 13:08:00+00:00',
				last_used_region: 'eu-west-1',
				last_used_service: null,
				access_key_id: 'AKIAZTLFMVR7K4LMNOPF',
			},
			okta_user_xc: [],
			aws_iam_user_details_xc: {
				Path: '/',
				UserName: 'tableau-bkup-rstr',
				UserId: 'BFGZLNQSWYP5DV36KMRF',
				Arn: 'arn:aws:iam::818837914182:user/tableau-bkup-rstr',
				CreateDate: '2022-02-02T11:56:57Z',
				UserPolicyList: [],
				GroupList: [],
				AttachedManagedPolicies: [awsPolicies.tableauProdBuckupPolicy],
				Tags: [],
				GroupListPermissions: [],
			},
			aws_iam_access_keys_xc: [
				{
					access_key_id: 'AKIAZTLFMVR9X3PQRSTF',
					is_active: true,
					last_used_date: '2024-08-29 08:08:00+00:00',
					last_rotated: '2023-03-02 12:51:00+00:00',
					last_used_region: 'eu-west-1',
					last_used_service: 's3',
				},
				{
					access_key_id: 'AKIAZTLFMVR7K4LMNOPF',
					is_active: true,
					last_used_date: null,
					last_rotated: '2022-02-21 13:08:00+00:00',
					last_used_region: null,
					last_used_service: null,
				},
			],
			change_logs: getChangeLogs1('tableau-bkup-rstr', 'arn:aws:iam::818837914182:user/tableau-bkup-rstr'),
			usage_logs: splunkUserUsageLogs,
		},
	},
} satisfies Record<string, DemoServerIdentityInput>
