import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { Prism } from 'react-syntax-highlighter'
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism'

export const CodeViewerButton: React.FunctionComponent<{
	code: string
	title: string
	buttonTitle: string
	language: string
}> = ({ code, title, buttonTitle, language }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const showModal = () => {
		setIsModalOpen(true)
	}

	const handleClose = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<Button type="link" onClick={showModal}>
				{buttonTitle}
			</Button>
			<Modal
				width={1024}
				title={title}
				open={isModalOpen}
				onOk={handleClose}
				onCancel={handleClose}
				footer={[
					<Button key="done-button" type="primary" onClick={handleClose}>
						Done
					</Button>,
				]}
			>
				<Prism language={language} style={coy}>
					{code}
				</Prism>
			</Modal>
		</>
	)
}
