import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { EdgeType, getEdge } from '../graphUtils/nodesAndEdges.ts'
import { Ec2InstancesNodeType } from '../aws/Ec2InstancesNode.tsx'
import { KubernetesResourcesNodeType } from '../common/KubernetesResourcesNode.tsx'
import { ServerKubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { DemoAzureVirtualMachinesNodeType } from '../azure/demo/DemoAzureVirtualMachinesNode.tsx'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import { aggregateAwsIamChangeLogs, getActorArnFriendlyName } from '../../../../utils/awsIdentityUtils.ts'

const nodeLogicalTypeToColumnId = {
	ec2AndK8s: 0,
	generalIssue: 1,
	identity: 2,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getPostgresRoleNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const identityNodes: Array<BareNodeType<IdentityNodeType> | BareNodeType<OwnershipNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs = identity.postgres_role?.change_logs || []
	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateAwsIamChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actorArn,
					name: getActorArnFriendlyName(aggChangeLog.actorArn),
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	const ec2AndK8sNodes: Array<
		| BareNodeType<Ec2InstancesNodeType>
		| BareNodeType<KubernetesResourcesNodeType>
		| BareNodeType<DemoAzureVirtualMachinesNodeType>
	> = []
	if (identity.postgres_role?.aws_ec2_instances_xc?.length) {
		ec2AndK8sNodes.push({
			type: 'ec2Instances',
			data: { instances: identity.postgres_role?.aws_ec2_instances_xc },
			id: `${nodeLogicalTypeToColumnId.ec2AndK8s}-${ec2AndK8sNodes.length}`,
		})
	}

	if (identity.postgres_role?.virtual_machines?.length) {
		ec2AndK8sNodes.push({
			type: 'azureVirtualMachines',
			data: { vms: identity.postgres_role?.virtual_machines },
			id: `${nodeLogicalTypeToColumnId.ec2AndK8s}-${ec2AndK8sNodes.length}`,
		})
	}

	const kubernetesResources: ServerKubernetesResourceXc[] = identity.postgres_role?.kubernetes_resources_xc || []
	if (kubernetesResources.length) {
		ec2AndK8sNodes.push({
			type: 'kubernetesResources',
			data: { resources: kubernetesResources },
			id: `${nodeLogicalTypeToColumnId.ec2AndK8s}-${ec2AndK8sNodes.length}`,
		})
	}

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	ec2AndK8sNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.ec2AndK8s}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: ec2AndK8sNodes },
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
	]
	return [nodes, edges]
}
