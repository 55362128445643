import { Node, NodeProps } from '@xyflow/react'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type ConnectedGcpRolesNodeType = Node<{ roles: string[] }, 'connectedGcpRoles'>
type ConnectedGcpRolesNodeProps = NodeProps<ConnectedGcpRolesNodeType>

export const ConnectedGcpRolesNode = ({ data: { roles } }: ConnectedGcpRolesNodeProps) => {
	const badgeTooltipContent = roles.map((role) => {
		const segments = role.split('/')
		const lastSegment = segments.pop()
		return <span key={role}>{lastSegment || 'Unknown role'}</span>
	})

	return (
		<BasePermissionNode
			label="GCP Roles"
			groupProps={{ count: roles.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
