import { Link, type LinkProps } from '@tanstack/react-router'
import { Breadcrumb } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { memo, type ReactNode } from 'react'

type TokenBreadcrumbsProps = {
	previousEntry: {
		linkProps: LinkProps
		title: string
	}
	currentTitle: ReactNode
}

export const TokenBreadcrumbs = memo(({ previousEntry, currentTitle }: TokenBreadcrumbsProps) => {
	return (
		<Breadcrumb
			items={[
				{
					title: (
						<Link
							{...previousEntry.linkProps}
							className="!flex items-center !h-full hover:!bg-surface-tertiary !text-textIcon-secondary hover:!text-textIcon-linkHover font-normal"
						>
							<LeftOutlined className="mr-2" />
							{previousEntry.title}
						</Link>
					),
				},
				{ title: currentTitle },
			]}
		/>
	)
})
