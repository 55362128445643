import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellMouseOutEvent, CellMouseOverEvent, ColDef, GetRowIdParams, GridReadyEvent } from 'ag-grid-enterprise'
import { CombinedAwsIamRolePolicy } from '../../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { combinedAwsIamRolePolicyColumnDefs } from './combinedAwsIamRolePolicyDefs.tsx'
import { getPolicyUniqueKey } from '../../../../../utils/awsIdentityUtils.ts'

type IamPermissionsTableProps = {
	data: CombinedAwsIamRolePolicy[]
	lastSelectedPolicyId?: string | null
	onCellMouseOver?: (event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => void
	onCellMouseOut?: (event: CellMouseOutEvent<CombinedAwsIamRolePolicy>) => void
}

export const IamPermissionsTable: React.FunctionComponent<IamPermissionsTableProps> = ({
	data,
	lastSelectedPolicyId,
	onCellMouseOver,
	onCellMouseOut,
}) => {
	const gridRef = useRef<AgGridReact<CombinedAwsIamRolePolicy>>(null)
	const highlightRow = (rowId?: string | null) => {
		if (!rowId) {
			return
		}

		const rowNode = gridRef?.current?.api?.getRowNode(rowId)
		if (rowNode) {
			gridRef?.current?.api?.flashCells({ rowNodes: [rowNode] })
		}
	}

	const onGridReady = useCallback((_params: GridReadyEvent<CombinedAwsIamRolePolicy>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
		highlightRow(lastSelectedPolicyId)
	}, [])

	const columnDefs = useMemo<ColDef<CombinedAwsIamRolePolicy>[]>(() => {
		return combinedAwsIamRolePolicyColumnDefs
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	useEffect(() => {
		highlightRow(lastSelectedPolicyId)
	}, [lastSelectedPolicyId])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				getRowId={(rowData: GetRowIdParams<CombinedAwsIamRolePolicy>) => getPolicyUniqueKey(rowData.data)}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
