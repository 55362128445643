import { Node, NodeProps } from '@xyflow/react'
import { BaseIdentityNode } from '../common/baseNodes/BaseIdentityNode.tsx'
import { JumpcloudUserXc } from '../../../../schemas/identities/jumpcloud/jumpcloudUserXcSchema.ts'

export type JumpcloudUserNodeType = Node<{ user: JumpcloudUserXc }, 'jumpcloudUser'>
type JumpcloudUserNodeProps = NodeProps<JumpcloudUserNodeType>

export const JumpcloudUserNode = ({ data: { user } }: JumpcloudUserNodeProps) => (
	<BaseIdentityNode
		name={{
			text: user.displayname || 'Unknown User',
			tooltipText: user.id ? `ID: ${user.id}` : undefined,
		}}
		labelProps={{ label: 'JumpCloud User' }}
	/>
)
