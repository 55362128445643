import React, { useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GetRowIdParams } from 'ag-grid-enterprise'
import { ServerAwsTag } from '../../../../../schemas/identities/awsPoliciesSchema.ts'

type AwsTagsTableProps = {
	tags: ServerAwsTag[]
}

export const AwsTagsTable: React.FC<AwsTagsTableProps> = ({ tags }) => {
	const columnDefs = useMemo<ColDef<ServerAwsTag>[]>(() => {
		return [
			{
				field: 'Key',
				headerName: 'Key',
				flex: 1,
				cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis' },
			},
			{
				field: 'Value',
				headerName: 'Value',
				flex: 1,
				cellStyle: { overflow: 'hidden', textOverflow: 'ellipsis' },
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				rowData={tags}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				getRowId={(params: GetRowIdParams<ServerAwsTag>) => params.data.Key}
				enableCellTextSelection
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				rowHeight={54}
			/>
		</div>
	)
}
