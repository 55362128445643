import { z } from 'zod'
import { TagSchema } from '../tags.ts'

export const ServerAwsTagSchema = z.object({
	Key: z.string(),
	Value: z.string(),
})

export type ServerAwsTag = z.infer<typeof ServerAwsTagSchema>

export const ServerPolicyStatement = z.object({
	Sid: z.string().optional().nullish(),
	Effect: z.string(),
	Action: z
		.union([z.array(z.string()), z.string()])
		.optional()
		.nullish(),
	NotAction: z
		.union([z.array(z.string()), z.string()])
		.optional()
		.nullish(),
	Resource: z
		.union([z.string(), z.array(z.string())])
		.optional()
		.nullish(),
	NotResource: z
		.union([z.string(), z.array(z.string())])
		.optional()
		.nullish(),
	Condition: z.record(z.any()).optional().nullish(),
})

export const ServerPolicyDocument = z.object({
	Version: z.string().optional().nullish(),
	Statement: z.union([z.array(ServerPolicyStatement), ServerPolicyStatement]),
})

// Assuming PolicyDocument is defined somewhere else
export const ServerPolicyVersion = z.object({
	Document: ServerPolicyDocument,
	VersionId: z.string(),
	IsDefaultVersion: z.boolean(),
	CreateDate: z.string(),
})

export const ServerAwsPolicy = z.object({
	PolicyName: z.string(),
	PolicyId: z.string(),
	Arn: z.string(),
	Path: z.string(),
	DefaultVersionId: z.string(),
	AttachmentCount: z.number(),
	PermissionsBoundaryUsageCount: z.number(),
	IsAttachable: z.boolean(),
	CreateDate: z.string(),
	UpdateDate: z.string(),
	PolicyVersionList: z.array(ServerPolicyVersion),
})

export const ServerManagedPolicySchema = z.object({
	PolicyName: z.string(),
	PolicyArn: z.string(),
	PolicyInfo: ServerAwsPolicy.optional().nullish(),
	CustomerManaged: z.boolean().optional().default(false),
	RiskTags: TagSchema.array().nullish(),
})

export type ServerManagedPolicyInput = z.input<typeof ServerManagedPolicySchema>
export type ServerManagedPolicy = z.infer<typeof ServerManagedPolicySchema>

export const ServerAwsIdentityPolicySchema = z.object({
	PolicyName: z.string(),
	PolicyDocument: ServerPolicyDocument,
	RiskTags: TagSchema.array().nullish(),
})

export type ServerAwsIdentityPolicyInput = z.input<typeof ServerAwsIdentityPolicySchema>
export type ServerAwsIdentityPolicy = z.infer<typeof ServerAwsIdentityPolicySchema>
