import { createRoot } from 'react-dom/client'
import './styles/index.css'
import { router } from './routes/Router.ts'
import { StrictMode } from 'react'
import { settings } from './env-settings.ts'
import { AgCharts } from 'ag-charts-enterprise'
import { makeMirageServer } from './mirage/server.ts'
import { isDemo } from './utils/demoUtils.ts'
import { RouterProvider } from '@tanstack/react-router'
import { initAgGrid } from './tables/initAgGrid.ts'

AgCharts.setLicenseKey(settings.viteAgChartLicense!)

initAgGrid()

if (isDemo) {
	makeMirageServer()
}

createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<RouterProvider router={router} />
	</StrictMode>,
)
