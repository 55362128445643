import { NodeProps, Node } from '@xyflow/react'
import { ServerGithubAppPermission } from '../../../../schemas/identities/githubAppInstallationSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type GithubAppPermissionNodeType = Node<{ permissions: ServerGithubAppPermission[] }, 'githubAppPermission'>
type GithubAppPermissionNodeProps = NodeProps<GithubAppPermissionNodeType>

export const GithubAppPermissionsNode = ({ data: { permissions } }: GithubAppPermissionNodeProps) => (
	<BasePermissionNode
		label="GitHub App Permissions"
		groupProps={
			permissions.length > 1
				? { count: permissions.length, tooltipContent: `Has permissions for ${permissions.length} scopes` }
				: undefined
		}
	/>
)
