import { tableFilterToQueryFilter } from '../../utils/tableQueryUtils.ts'
import { TableFilterEntry } from '../../schemas/tableFilter.ts'
import { FilterModel, GridApi, ISetFilter, IServerSideGetRowsParams } from 'ag-grid-enterprise'
import {
	findingsUrlQuickSearchFilterParameter,
	IssueName,
	IssuesPageLens,
	IssuesTabName,
	IssueTablePaginatedRow,
	IssueType,
} from '../../schemas/issue.ts'
import { getCsvFileName } from '../../utils.ts'
import { fetchIssuesQueryForExport } from '../../api/issues.ts'

const tableFieldToQueryFields = {
	identity_literal_friendly_name: ['identity_literal', 'identity_literal_friendly_name'],
	[findingsUrlQuickSearchFilterParameter]: [
		'identity_literal_friendly_name',
		'identity_account_literal_friendly_name',
		'identity_account_literal',
	],
}

const tableSetToTextContainsNullFields = new Set(['assignee'])

export const getQueryFilterFromTableFilterModel = (filterModel: FilterModel) =>
	tableFilterToQueryFilter(filterModel as Record<string, TableFilterEntry>, {
		tableFieldToQueryFields,
		tableSetToTextContainsNullFields,
	})

const columnsWithDynamicFilterValues = ['issue_name', 'identity_account_literal', 'assignee', 'identity_source']

export function refreshDynamicFilterValues(gridApi?: GridApi<IssueTablePaginatedRow>) {
	if (!gridApi) {
		return
	}

	columnsWithDynamicFilterValues.forEach((columnId) => {
		gridApi.getColumnFilterInstance(columnId).then((setFilter) => {
			if (setFilter?.getModel()) {
				return
			}
			// eslint-disable-next-line no-extra-semi
			;(setFilter as ISetFilter).refreshFilterValues()
		})
	})
}

export function handleExportingPaginatedDataToCsv(gridApi?: GridApi<IssueTablePaginatedRow>, issueType?: IssueType) {
	if (!gridApi || gridApi.isDestroyed()) {
		return
	}

	const originalDataSource = gridApi.getGridOption('serverSideDatasource')
	type TableContext = { tab?: IssuesTabName; lens?: IssuesPageLens | IssueName }
	const tempDataSource = {
		getRows: (params: IServerSideGetRowsParams<IssueTablePaginatedRow, TableContext>) => {
			fetchIssuesQueryForExport(
				{
					sort: params.request.sortModel.map((col) => ({
						field: col.colId,
						direction: col.sort,
					})),
					filter: getQueryFilterFromTableFilterModel(params.request.filterModel as FilterModel),
				},
				params.context.lens,
				params.context.tab,
				issueType,
			)
				.then((result) => {
					params.success({ rowData: result.issues, rowCount: result.total_count })
					gridApi.exportDataAsCsv({ fileName: getCsvFileName('issues') })
				})
				.finally(() => {
					gridApi.setGridOption('serverSideDatasource', originalDataSource)
				})
		},
	}

	gridApi.setGridOption('serverSideDatasource', tempDataSource)
}
