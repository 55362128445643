import { useCallback, useState } from 'react'
import { Button, Modal } from 'antd'
import ExportIcon from '../../assets/export_icon_20.svg?react'
import { useIdentitiesContext } from './context/IdentitiesProvider.tsx'
import { handleExportingPaginatedDataToCsv } from './paginatedInventoryUtils.ts'
import { useIdentitiesExportingLimit } from '../../api/identities.ts'
import { formatNumber } from '../../utils/numberUtils.ts'

export const IdentitiesExport = () => {
	const { gridRef, gridReady } = useIdentitiesContext()
	const { data: maxIdentitiesToExport, isPending: isPendingMaxIdentitiesToExport } = useIdentitiesExportingLimit()
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	const exportPaginatedDataToCsv = useCallback(() => {
		handleExportingPaginatedDataToCsv(gridRef?.current?.api)
		setIsModalOpen(false)
	}, [])

	const handleExportClicked = useCallback(() => {
		const totalRowCount = gridRef?.current?.api?.paginationGetRowCount() || 0
		if (totalRowCount > Number(maxIdentitiesToExport)) {
			setIsModalOpen(true)
		} else {
			exportPaginatedDataToCsv()
		}
	}, [maxIdentitiesToExport])

	const handleModalClose = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<Button
				icon={<ExportIcon />}
				className="border-border-tertiary"
				onClick={handleExportClicked}
				loading={!gridReady || isPendingMaxIdentitiesToExport}
			>
				Export CSV
			</Button>

			<Modal
				centered
				open={isModalOpen}
				width={530}
				onCancel={handleModalClose}
				onClose={handleModalClose}
				title={'Confirm Export'}
				key="identities-export-modal"
				footer={[
					<div key="identities-export-modal-footer" className="flex gap-2 justify-end">
						<Button onClick={handleModalClose}>Cancel</Button>
						<Button type="primary" onClick={exportPaginatedDataToCsv}>
							Export
						</Button>
					</div>,
				]}
			>
				<div className={'flex justify-start py-3 whitespace-pre-line'}>
					{`The export limit is ${formatNumber(Number(maxIdentitiesToExport))} items. You’re trying to export ${gridRef?.current?.api?.paginationGetRowCount()} items.
					Refine your filters or click “Export” to download the first ${formatNumber(Number(maxIdentitiesToExport))} items.`}
				</div>
			</Modal>
		</>
	)
}
