import { z } from 'zod'
import { ServerAwsEnvironmentSchema } from './awsEnvironment.ts'
import { ServerGcpEnvironmentSchema } from './gcpEnvironment.ts'
import { ServerOktaEnvironmentSchema } from './oktaEnvironment.ts'
import { ServerJumpcloudEnvironmentSchema } from './jumpcloudEnvironment.ts'
import { ServerEntraIdEnvironmentSchema } from './entraIdEnvironment.ts'
import { ServerGoogleWorkspaceEnvironmentSchema } from './googleWorkspaceEnvironment.ts'
import { ServerSalesforceEnvironmentSchema } from './salesforceEnvironment.ts'
import { EnvironmentType } from '../envType.ts'
import { ServerAzureDevopsEnvironmentSchema } from './azureDevopsEnvironment.ts'
import { ServerActiveDirectoryEnvironmentSchema } from './activeDirectoryEnvironment.ts'
import { ServerGithubEnvironmentSchema } from './githubEnvironment.ts'
import { ServerSnowflakeEnvironmentSchema } from './snowflakeEnvironment.ts'

const AzureDevOpsTestConnectionConfigSchema = z.object({
	type: z.literal(EnvironmentType.AZURE_DEVOPS),
	organization_name: z.string(),
	log_analytics_workspace_id: z.string(),
	personal_access_token: z.string(),
})
const SalesforceTestConnectionConfigSchema = z.object({
	type: z.literal(EnvironmentType.SALESFORCE),
	domain: z.string(),
	consumer_key: z.string(),
	consumer_secret: z.string(),
})

const EntraIdTestConnectionConfigSchema = z.object({
	type: z.literal(EnvironmentType.ENTRA_ID),
	entra_id_tenant_id: z.string(),
	entra_id_app_id: z.string(),
	entra_id_app_secret: z.string(),
	entra_id_logs_workspace_id: z.string(),
	entra_id_logs_workspace_collector_enabled: z.boolean(),
	entra_id_azure_collector_enabled: z.boolean(),
})

const OktaTestConnectionConfigSchema = z.object({
	type: z.literal(EnvironmentType.OKTA),
	domain: z.string(),
	api_key: z.string(),
})

const GcpTestConnectionConfigSchema = z.object({
	type: z.literal(EnvironmentType.GCP),
	org_id: z.string(),
	api_token: z.record(z.string(), z.unknown()),
})

const JumpcloudTestConnectionConfigSchema = z.object({
	type: z.literal(EnvironmentType.JUMPCLOUD),
	api_key: z.string(),
})

export const JumpCloudTestConnectionResponsePayloadSchema = z.object({
	environment_type: z.literal(EnvironmentType.JUMPCLOUD),
	org_id: z.string(),
})

export type JumpCloudTestConnectionResponsePayloadSchema = z.infer<typeof JumpCloudTestConnectionResponsePayloadSchema>

const SnowflakeTestConnectionConfigSchema = z.object({
	type: z.literal(EnvironmentType.SNOWFLAKE),
	account_identifier: z.string(),
	private_key: z.string(),
})

export type SnowflakeTestConnectionConfigSchema = z.infer<typeof SnowflakeTestConnectionConfigSchema>
export const EnvironmentTestConnectionRequestSchema = z.object({
	environment_type: z.nativeEnum(EnvironmentType),
	config: z.union([
		AzureDevOpsTestConnectionConfigSchema,
		SalesforceTestConnectionConfigSchema,
		EntraIdTestConnectionConfigSchema,
		OktaTestConnectionConfigSchema,
		GcpTestConnectionConfigSchema,
		JumpcloudTestConnectionConfigSchema,
		SnowflakeTestConnectionConfigSchema,
	]),
})

export type EnvironmentTestConnectionRequest = z.infer<typeof EnvironmentTestConnectionRequestSchema>

export const EnvironmentTestConnectionResponsePayloadSchema = JumpCloudTestConnectionResponsePayloadSchema.nullish()

export type EnvironmentTestConnectionResponsePayload = z.infer<typeof EnvironmentTestConnectionResponsePayloadSchema>

export const EnvironmentTestConnectionResponseSchema = z.object({
	success: z.boolean(),
	message: z.string(),
	payload: EnvironmentTestConnectionResponsePayloadSchema,
})

export type EnvironmentTestConnectionResponse = z.infer<typeof EnvironmentTestConnectionResponseSchema>

const AzureDevOpsEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.AZURE_DEVOPS),
	organization_name: z.string(),
	log_analytics_workspace_id: z.string(),
	personal_access_token: z.string(),
})

const SalesforceEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.SALESFORCE),
	domain: z.string(),
	consumer_key: z.string(),
	consumer_secret: z.string(),
})

const EntraIdEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.ENTRA_ID),
	entra_id_tenant_id: z.string(),
	entra_id_app_id: z.string(),
	entra_id_app_secret: z.string(),
	entra_id_logs_workspace_id: z.string().nullable(),
	entra_id_logs_workspace_collector_enabled: z.boolean(),
	entra_id_azure_collector_enabled: z.boolean(),
})

const AwsEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.AWS),
	role_arn: z.string(),
	external_id: z.string().uuid(),
	environment_row_id: z.string().nullable(),
	trail_arn: z.string().nullable(),
	s3_role_arn: z.string().nullable(),
})

const SnowflakeEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.SNOWFLAKE),
	account_identifier: z.string(),
	private_key: z.string(),
})

const OktaEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.OKTA),
	domain: z.string(),
	api_key: z.string(),
})

const GcpEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.GCP),
	org_id: z.string(),
	api_token: z.record(z.string(), z.unknown()),
	bucket_name: z.string().nullable(),
	pub_sub_subscription_name: z.string().nullable(),
})

const JumpcloudEnvironmentConfigSchema = z.object({
	type: z.literal(EnvironmentType.JUMPCLOUD),
	org_id: z.string(),
	api_key: z.string(),
})

export const ServerEnvironmentsSchema = z.object({
	[EnvironmentType.AWS]: z.array(ServerAwsEnvironmentSchema),
	[EnvironmentType.GCP]: z.array(ServerGcpEnvironmentSchema),
	[EnvironmentType.OKTA]: z.array(ServerOktaEnvironmentSchema),
	[EnvironmentType.JUMPCLOUD]: z.array(ServerJumpcloudEnvironmentSchema),
	[EnvironmentType.ENTRA_ID]: z.array(ServerEntraIdEnvironmentSchema),
	[EnvironmentType.AZURE_DEVOPS]: z.array(ServerAzureDevopsEnvironmentSchema),
	[EnvironmentType.SALESFORCE]: z.array(ServerSalesforceEnvironmentSchema),
	[EnvironmentType.GOOGLE_WORKSPACE]: z.array(ServerGoogleWorkspaceEnvironmentSchema),
	[EnvironmentType.ACTIVE_DIRECTORY]: z.array(ServerActiveDirectoryEnvironmentSchema),
	[EnvironmentType.GITHUB]: z.array(ServerGithubEnvironmentSchema),
	[EnvironmentType.SNOWFLAKE]: z.array(ServerSnowflakeEnvironmentSchema),
})

export type ServerEnvironments = z.infer<typeof ServerEnvironmentsSchema>

export const EnvironmentCreateRequestSchema = z.object({
	name: z.string(),
	environment_type: z.nativeEnum(EnvironmentType),
	config: z.union([
		AzureDevOpsEnvironmentConfigSchema,
		SalesforceEnvironmentConfigSchema,
		EntraIdEnvironmentConfigSchema,
		AwsEnvironmentConfigSchema,
		SnowflakeEnvironmentConfigSchema,
		OktaEnvironmentConfigSchema,
		GcpEnvironmentConfigSchema,
		JumpcloudEnvironmentConfigSchema,
	]),
})

export type EnvironmentCreateRequest = z.infer<typeof EnvironmentCreateRequestSchema>

export const EnvironmentCreateResponseSchema = z.object({
	message: z.string(),
	environment_id: z.string(),
})

export type EnvironmentCreateResponse = z.infer<typeof EnvironmentCreateResponseSchema>
