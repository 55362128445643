import { Input } from 'antd'
import SearchIcon from '../../assets/search_icon_20.svg?react'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../RoutePaths.tsx'
import { TableTextFilterEntry, TableTextFilterType } from '../../schemas/tableFilter.ts'
import { isEmpty } from 'lodash'
import { findingsUrlQuickSearchFilterParameter, PaginatedFindingsPageSearch } from '../../schemas/issue.ts'

interface FindingsQuickSearchProps {
	routePath?: typeof RoutePaths.Findings | typeof RoutePaths.Itdr
}

export const FindingsQuickSearch = ({ routePath = RoutePaths.Findings }: FindingsQuickSearchProps) => {
	const [searchText, setSearchText] = useState<string>('')
	const navigate = useNavigate({ from: routePath })
	const search = useSearch({ from: routePath })
	const searchIssueFilter = (search as PaginatedFindingsPageSearch).issueFilter

	const onFilterTextBoxChanged = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			trackEvent(LogRocketTrackEvent.IssuesTextSearchChanged, {})
			setSearchText(e.currentTarget.value)
		},
		[setSearchText],
	)

	useEffect(() => {
		const filterSearchText = searchIssueFilter?.[findingsUrlQuickSearchFilterParameter] as TableTextFilterEntry
		setSearchText(filterSearchText?.filter ?? '')
	}, [searchIssueFilter])

	useEffect(() => {
		const filterSetTimeout = setTimeout(() => {
			const filterSearchText = searchIssueFilter?.[findingsUrlQuickSearchFilterParameter] as TableTextFilterEntry
			// If the search text in the URL matches the new search text, do nothing
			if ((filterSearchText?.filter ?? '') === searchText) {
				return
			}

			void navigate({
				to: routePath,
				search: (prev) => {
					const newSearch = { ...prev } as PaginatedFindingsPageSearch
					const newIssueFilter = { ...newSearch.issueFilter }
					if (!searchText) {
						// This is safe since findingsUrlQuickSearchFilterParameter is a runtime constant
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete newIssueFilter[findingsUrlQuickSearchFilterParameter]
					} else {
						newIssueFilter[findingsUrlQuickSearchFilterParameter] = {
							filterType: 'text',
							type: TableTextFilterType.CONTAINS,
							filter: searchText,
						}
					}

					if (isEmpty(newIssueFilter)) {
						delete newSearch.issueFilter
					} else {
						newSearch.issueFilter = newIssueFilter
					}

					// When filters change we need to reset the page
					delete newSearch.page
					return newSearch
				},
			})
		}, 300)

		return () => {
			clearTimeout(filterSetTimeout)
		}
	}, [searchText, searchIssueFilter, routePath, navigate])

	return (
		<Input
			value={searchText}
			onChange={onFilterTextBoxChanged}
			allowClear={true}
			prefix={<SearchIcon />}
			placeholder="Search"
		/>
	)
}
