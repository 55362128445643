import { AppServer } from '../schemas.ts'
import { getAllNotifications, updateNotificationStatus } from '../mirageORM.ts'
import { ServerNotification } from '../../schemas/notifications.ts'

export function mirageNotifications(server: AppServer) {
	server.get('/notifications', (schema) => {
		return getAllNotifications(schema)
	})

	server.put('/notifications', (schema, request) => {
		const notifications = JSON.parse(request.requestBody) as ServerNotification[]

		return notifications.map((notification) => {
			return updateNotificationStatus(schema, notification.playbook_id, notification.status)
		})
	})
}
