import { AgGridReact } from 'ag-grid-react'
import React, { useMemo } from 'react'
import { ColDef } from 'ag-grid-enterprise'
import {
	AdOwnershipLogsAggregatedSchema,
	EventSpecificOwnershipLog,
} from '../../../../../schemas/identities/activeDirectory/adOwnershipSchema'
import { formatDate } from '../../../../../utils.ts'
import { eventIdDescriptions } from './adConstants'
import { getEventSpecificOwnershipLogs } from './adLogsUtils'

export const AdAggregatedOwnershipTable: React.FunctionComponent<{
	data: AdOwnershipLogsAggregatedSchema[]
}> = ({ data }) => {
	// Transform the data to show event-specific entries
	const eventSpecificData = useMemo<EventSpecificOwnershipLog[]>(() => {
		const items = getEventSpecificOwnershipLogs(data)

		// Add event descriptions
		const result = items.map((item: EventSpecificOwnershipLog) => ({
			...item,
			event_description: eventIdDescriptions[item.event_id.toString()] || 'Unknown event',
		}))
		return result
	}, [data])

	const columnDefs = useMemo<ColDef[]>(() => {
		return [
			{
				field: 'actor_name',
				headerName: 'Actor Name',
				flex: 1,
			},
			{
				field: 'event_description',
				headerName: 'Action',
				flex: 1.5,
			},
			{
				field: 'first_time',
				headerName: 'First Day',
				flex: 1,
				cellRenderer: (params: { value: Date }) => (
					<div className="text-textIcon-secondary">{formatDate(params.value.toISOString(), false)}</div>
				),
			},
			{
				field: 'last_time',
				headerName: 'Last Day',
				flex: 1,
				sort: 'desc',
				cellRenderer: (params: { value: Date }) => (
					<div className="text-textIcon-secondary">{formatDate(params.value.toISOString(), false)}</div>
				),
			},
			{
				field: 'count',
				headerName: 'Event Count',
				flex: 0.7,
			},
		]
	}, [])

	if (!data.length) {
		return <div className={'text-center'}>No Active Directory ownership data available</div>
	}

	return (
		<>
			<div className="h-72 overflow-auto">
				<AgGridReact
					className="ag-theme-alpine h-full w-full overflow-x-auto"
					rowHeight={54}
					rowData={eventSpecificData}
					columnDefs={columnDefs}
					enableCellTextSelection
					suppressHorizontalScroll={false}
				/>
			</div>
		</>
	)
}
