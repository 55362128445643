import React from 'react'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

interface IntegrationCardLogsContentProps {
	logsConnected: boolean | number
	environmentCount: number
}

export const IntegrationCardLogsContent: React.FC<IntegrationCardLogsContentProps> = ({
	logsConnected,
	environmentCount,
}) => {
	return (
		<>
			{typeof logsConnected === 'boolean' ? (
				logsConnected ? (
					<CheckCircleOutlined className="text-status-active text-base" />
				) : (
					<ExclamationCircleOutlined className="text-status-critical text-base" />
				)
			) : (
				<span className="text-textIcon-primary font-medium">
					{logsConnected}/{environmentCount}
				</span>
			)}
		</>
	)
}
