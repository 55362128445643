import { ReactNode } from 'react'
import { useEntitlementToShowOnboardingState } from '../services/auth/featureFlags'
import { isDemo } from '../utils/demoUtils'
import { useOnboarding } from '../api/onboarding'
import { OnboardingStateType } from '../schemas/onboardingState'

interface OnboardingStateRendererSidebarProps {
	children: ReactNode
}

export const OnboardingStateRendererSidebar: React.FC<OnboardingStateRendererSidebarProps> = ({ children }) => {
	const { data: onboardingState, isPending, isError } = useOnboarding()
	const { isEntitled: isEntitledToShowOnboardingState } = useEntitlementToShowOnboardingState()
	if (isDemo || !isEntitledToShowOnboardingState) {
		return children
	}

	if (isPending) {
		return null
	}

	if (isError) {
		return children
	}

	if (
		onboardingState.status === OnboardingStateType.INTEGRATING ||
		onboardingState.status === OnboardingStateType.PENDING ||
		onboardingState.status === OnboardingStateType.FAILED
	) {
		return null
	}

	return children
}
