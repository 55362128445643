import * as React from "react";
const SvgOneloginIcon = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "40px", height: "40px", viewBox: "0 0 40 40", ...props }, /* @__PURE__ */ React.createElement("g", { id: "surface1" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(12.156863%,12.156863%,12.156863%)",
  fillOpacity: 1
}, d: "M 40 20 C 40 8.953125 31.046875 0 20 0 C 8.953125 0 0 8.953125 0 20 C 0 31.046875 8.953125 40 20 40 C 31.046875 40 40 31.046875 40 20 Z M 40 20 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(100%,100%,100%)",
  fillOpacity: 1
}, d: "M 17.800781 13.691406 L 15.210938 13.691406 C 14.804688 13.691406 14.476562 14.023438 14.472656 14.429688 L 14.472656 17.746094 C 14.476562 18.152344 14.804688 18.484375 15.210938 18.484375 L 17.800781 18.484375 L 17.800781 26.84375 C 17.800781 27.25 18.128906 27.578125 18.535156 27.578125 L 21.855469 27.578125 C 22.261719 27.578125 22.589844 27.25 22.589844 26.84375 L 22.589844 14.429688 C 22.589844 14.023438 22.261719 13.691406 21.855469 13.691406 Z M 17.800781 13.691406 " })));
export default SvgOneloginIcon;
