import { AgGridReact } from 'ag-grid-react'
import React, { useMemo } from 'react'
import { ColDef } from 'ag-grid-enterprise'
import { ServerAzureUsageLog } from '../../../../../schemas/identities/entraId/azureUsageLogSchema.ts'
import { IdentitySource } from '../../../../../schemas/identity.ts'

interface AggregatedAzureUsageLog {
	actor: string
	operations: string[]
	sourceIpAddresses: string[]
	firstEventTime: Date
	lastEventTime: Date
	operationCount: number
	association: string | null
	associationType: string | null
	credentialId: string | null
	userAgent: string | null
}

const defaultColDef: ColDef = {
	sortable: true,
	resizable: true,
	filter: true,
	cellStyle: () => ({
		display: 'flex',
		alignItems: 'center',
	}),
	flex: 1,
}

export const AzureUsageLogsAggregationsTable: React.FunctionComponent<{
	usageLogs: ServerAzureUsageLog[]
	identitySource: IdentitySource
}> = ({ usageLogs, identitySource }) => {
	const columnDefs: ColDef<AggregatedAzureUsageLog>[] = [
		{
			field: 'sourceIpAddresses',
			headerName: 'IP',
			valueFormatter: (params: { value: string[] | string | null | undefined }) => {
				if (Array.isArray(params.value)) {
					return params.value?.join(', ') || ''
				}
				return params.value || ''
			},
		},
		{
			field: 'association',
			headerName: 'Association',
			valueFormatter: (params: { value: string | null | undefined }) => {
				return params.value || ''
			},
		},
		{
			field: 'operations',
			headerName: 'Operations',
			valueFormatter: (params: { value: string[] | string | null | undefined }) => {
				if (Array.isArray(params.value)) {
					return params.value?.join(', ') || ''
				}
				return params.value || ''
			},
		},
		{
			field: 'operationCount',
			headerName: 'Count',
			sort: 'desc',
			flex: 0.8,
		},
		{
			field: 'credentialId',
			headerName: 'Credential ID',
			hide: identitySource === IdentitySource.ENTRA_ID_USER,
		},
		{
			field: 'userAgent',
			headerName: 'User Agent',
		},
		{
			field: 'firstEventTime',
			headerName: 'First Event',
		},
		{
			field: 'lastEventTime',
			headerName: 'Last Event',
		},
	]

	const aggregatedEntries = useMemo(() => {
		const aggregatedMap = usageLogs.reduce(
			(acc, entry) => {
				const key = `${entry.ip_address}`
				const existing = acc[key]

				if (existing) {
					// Update firstEventTime and lastEventTime
					existing.firstEventTime =
						existing.firstEventTime < new Date(entry.day) ? existing.firstEventTime : new Date(entry.day)
					existing.lastEventTime =
						existing.lastEventTime > new Date(entry.day) ? existing.lastEventTime : new Date(entry.day)

					// Increment operation count by the operations_count field
					existing.operationCount += entry.operations_count

					// Add unique source IP addresses
					if (!existing.sourceIpAddresses.includes(entry.ip_address)) {
						existing.sourceIpAddresses.push(entry.ip_address)
					}

					// Add unique operations from the array
					entry.operations.forEach((operation) => {
						if (!existing.operations.includes(operation)) {
							existing.operations.push(operation)
						}
					})
				} else {
					// Initialize the new aggregated log entry
					acc[key] = {
						actor: entry.actor || '',
						operations: [...entry.operations],
						sourceIpAddresses: [entry.ip_address || ''],
						firstEventTime: new Date(entry.day),
						lastEventTime: new Date(entry.day),
						operationCount: entry.operations_count,
						association: entry.association || '',
						associationType: entry.association_type || '',
						credentialId: entry.credential_id || '',
						userAgent: entry.user_agent || '',
					}
				}
				return acc
			},
			{} as Record<string, AggregatedAzureUsageLog>,
		)

		return Object.values(aggregatedMap)
	}, [usageLogs])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			rowData={aggregatedEntries}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
			suppressHorizontalScroll={false}
		/>
	)
}
