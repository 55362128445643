import { Tooltip } from 'antd'
import { formatDate } from '../../../utils.ts'

type IssueDateProps = {
	date?: Date | null
	label: string
}

export const IssueDate = ({ date, label }: IssueDateProps) => {
	if (!date) {
		return null
	}

	return (
		<div className="flex items-center gap-1 rounded bg-surface-tertiary px-2 py-1">
			<span className="text-textIcon-secondary">{label}</span>
			<Tooltip title={formatDate(date)}>
				<span>{formatDate(date, false)}</span>
			</Tooltip>
		</div>
	)
}
