import { useCallback, useEffect, useState } from 'react'
import { useAppVersion } from '../api/version.ts'
import { isDev } from '../utils/devUtils.ts'
import { Modal } from 'antd'

export const NewVersionModal = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [appVersions, setAppVersions] = useState<Array<string>>([])
	const { data: newAppVersion } = useAppVersion()

	useEffect(() => {
		if (!newAppVersion) {
			return
		}

		setAppVersions((previousAppVersions) => {
			if (previousAppVersions[previousAppVersions.length - 1] === newAppVersion) {
				return previousAppVersions
			}

			return [...previousAppVersions, newAppVersion]
		})
	}, [newAppVersion, setAppVersions])

	useEffect(() => {
		if (appVersions.length <= 1) {
			return
		}

		setIsModalOpen(true)
	}, [appVersions, setIsModalOpen])

	const closeModal = useCallback(() => {
		setIsModalOpen(false)
	}, [setIsModalOpen])

	const refreshPage = useCallback(() => {
		window.location.reload()
	}, [])

	if (isDev) {
		return null
	}

	return (
		<Modal
			onCancel={closeModal}
			onClose={closeModal}
			open={isModalOpen}
			onOk={refreshPage}
			okText="Reload the page"
			title="A new version of Token Security has been released."
		>
			We recommend reloading the page to get the best experience and to avoid any issues.
		</Modal>
	)
}
