import { useMemo } from 'react'
import { Link, useSearch } from '@tanstack/react-router'
import { CloseOutlined } from '@ant-design/icons'
import { useFindings } from './FindingsContext.tsx'
import { IssueName, IssueNameMap, IssuesPageLens, PaginatedFindingsPageSearch } from '../../schemas/issue.ts'
import { DEFAULT_ISSUES_PAGE_LENS, IssuesPageLensMap } from './IssuesPageLensMap.tsx'
import { IssueIcon } from '../../components/common/IssueIcon.tsx'
import { RoutePaths } from '../RoutePaths.tsx'
import { PageHeaderChip } from '../../components/common/PageHeaderChip.tsx'

export const FindingsPaginatedPageTitle = () => {
	const { routePath } = useFindings()
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { lens } = useSearch({ from: routePath }) as PaginatedFindingsPageSearch
	const { title, iconNode } = useMemo(() => {
		if (!lens || lens in IssuesPageLensMap) {
			const { title, icon: Icon } = IssuesPageLensMap[(lens as IssuesPageLens) || DEFAULT_ISSUES_PAGE_LENS]
			return {
				title,
				iconNode: <Icon className="w-3 h-3" />,
			}
		}

		return {
			title: IssueNameMap[lens as IssueName],
			iconNode: <IssueIcon size={12} name={lens as IssueName} />,
		}
	}, [lens])

	return (
		<div className="flex items-center gap-3">
			{routePath === RoutePaths.Itdr ? 'ITDR' : 'Findings'}
			{!!lens && lens !== DEFAULT_ISSUES_PAGE_LENS && (
				<PageHeaderChip>
					{iconNode}
					<span className="font-light">{title}</span>
					<Link
						from={routePath}
						to={routePath}
						search={(prevSearch) => {
							const newSearch = { ...(prevSearch as PaginatedFindingsPageSearch) }
							delete newSearch.lens
							return newSearch
						}}
					>
						<CloseOutlined className="w-3 h-3" />
					</Link>
				</PageHeaderChip>
			)}
		</div>
	)
}
