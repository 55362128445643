import { Button, Tooltip } from 'antd'
import { useCallback, useMemo } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { IdentitiesSideBar } from './IdentitiesSideBar/IdentitiesSideBar.tsx'
import { IdentityTabName, IdentitiesPageSearch } from '../../schemas/identity.ts'
import { IssueDrawer } from '../../components/drawers/issueDrawer/IssueDrawer.tsx'
import ClearFilterIcon from '../../assets/clear_filter_icon_20.svg?react'
import IdentitiesIcon from '../../assets/identities_icon.svg?react'
import { capitalize, isEmpty, values } from 'lodash'
import { RoutePaths } from '../RoutePaths.tsx'
import { IdentitiesTable } from './IdentitiesTable/IdentitiesTable.tsx'
import { IdentitiesQuickSearch } from './IdentitiesQuickSearch.tsx'
import { IdentitiesStatsPaginated } from './stats/IdentitiesStatsPaginated.tsx'
import { IdentitiesExport } from './IdentitiesExport.tsx'
import { FilterView } from './FilterView/FilterView.tsx'
import { Page } from '../../components/common/Page.tsx'
import { PageHeader } from '../../components/common/PageHeader.tsx'
import { OnboardingStateRenderer } from '../../components/OnboardingStateRenderer.tsx'
import { OnboardingStateRendererSidebar } from '../../components/OnboardingStateRendererSidebar.tsx'
import { IdentitiesPageTitle } from './IdentitiesPageTitle.tsx'

const TabToTooltip: Partial<Record<IdentityTabName, string>> = {
	[IdentityTabName.MANAGED]: 'Managed Identities',
	[IdentityTabName.DELETED]: 'Deleted Identities',
}

export const Identities = () => {
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const search = useSearch({ from: RoutePaths.Identities })

	const tabItems = useMemo(
		() =>
			values(IdentityTabName).map((tabName) => ({
				key: tabName,
				label: <Tooltip title={TabToTooltip[tabName]}>{capitalize(tabName)}</Tooltip>,
			})),
		[],
	)

	const onTabChange = (activeKey: string) => {
		void navigate({
			search: (prev) => {
				const newSearch = { ...prev } as IdentitiesPageSearch
				delete newSearch.identityFilter
				// When tab changes we need to reset the page
				delete newSearch.page
				newSearch.tab = activeKey as IdentityTabName
				return newSearch
			},
		})
	}

	const clearFilters = useCallback(() => {
		// If the filter is already cleared, do nothing
		if (isEmpty(search.identityFilter)) {
			return
		}

		void navigate({
			search: (prev) => {
				const newSearch = { ...prev }
				delete newSearch.identityFilter
				// When clearing a filter we need to reset the page
				delete newSearch.page
				return newSearch
			},
		})
	}, [search.identityFilter])

	return (
		<Page
			sidebar={
				<OnboardingStateRendererSidebar>
					<IdentitiesSideBar />
				</OnboardingStateRendererSidebar>
			}
		>
			<PageHeader
				Icon={IdentitiesIcon}
				tabsProps={{
					activeKey: search.tab ?? IdentityTabName.MANAGED,
					onChange: onTabChange,
					items: tabItems,
				}}
			>
				<IdentitiesPageTitle />
			</PageHeader>
			<OnboardingStateRenderer>
				<div className="border border-border-secondary bg-surface-primary rounded-lg">
					<IdentitiesStatsPaginated />
				</div>
				<div className="flex justify-between items-center">
					<div className="flex items-center gap-2">
						<IdentitiesQuickSearch />
						<Tooltip placement="topLeft" title="Clear filters">
							<Button
								icon={<ClearFilterIcon />}
								className="border-border-tertiary"
								onClick={clearFilters}
							/>
						</Tooltip>
					</div>
					<div className="grow mx-2 overflow-hidden">
						<FilterView />
					</div>
					<div className="shrink-0">
						<IdentitiesExport />
					</div>
				</div>
				<div className="h-full w-full flex">
					<IdentitiesTable />
				</div>
				<IssueDrawer route={RoutePaths.Identities} />
			</OnboardingStateRenderer>
		</Page>
	)
}
