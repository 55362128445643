import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { SnowflakeRoleNodeType } from './SnowflakeRoleNode.tsx'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { EdgeType, getEdge } from '../graphUtils/nodesAndEdges.ts'
import { OktaUserNodeType } from '../okta/OktaUserNode.tsx'
import { EntraIDUserNodeType } from '../entraId/EntraIDUserNode.tsx'
import { JumpcloudUserNodeType } from '../jumpcloud/JumpcloudUserNode.tsx'
import {
	aggregateSnowflakeUserChangeLogs,
	SnowflakeUserChangeLogs,
} from '../../../../schemas/identities/snowflake/snowflakeUserChangeLogs.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import { keyBy, first, intersection } from 'lodash'
import { SnowflakeLoginNodeType } from './SnowflakeLoginNode.tsx'
import { accessKeyIssueNames } from '../../../../schemas/issue.ts'
import { usageNodeLogicalTypeToColumnId } from '../graphUtils/usageGraph.ts'
import { ServerUsageLogGrouped } from '../../../../schemas/identities/groupedUsageLogsSchema.ts'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	identity: 1,
	role: 2,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const oktaUserNodeRowIndex = 2
const entraIdUserNodeRowIndex = 3
const jumpcloudUserNodeRowIndex = 4
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getSnowflakeNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const identityNodes: Array<
		| BareNodeType<IdentityNodeType>
		| BareNodeType<OktaUserNodeType>
		| BareNodeType<EntraIDUserNodeType>
		| BareNodeType<JumpcloudUserNodeType>
		| BareNodeType<OwnershipNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs: SnowflakeUserChangeLogs[] = identity.snowflake_user?.change_logs || []
	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateSnowflakeUserChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actor_user_name,
					name: aggChangeLog.actor_user_name,
				})),
			},
			id: ownerNodeId,
		})
		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.snowflake_user?.okta_user_xc) {
		const details = identity.snowflake_user.okta_user_xc
		const displayName = details.profile?.displayName || details.profile?.email
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${oktaUserNodeRowIndex}`
		identityNodes.push({
			type: 'oktaUser',
			data: { oktaUser: { type: 'Okta User', displayName } },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.snowflake_user?.entra_id_user_xc) {
		const details = identity.snowflake_user.entra_id_user_xc
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${entraIdUserNodeRowIndex}`
		identityNodes.push({
			type: 'entraIDUser',
			data: { user: { principalName: details.user_principal_name, objectId: details.entra_user_id } },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}
	if (identity.snowflake_user?.jumpcloud_user_xc) {
		const jumpcloudUserXc = identity.snowflake_user.jumpcloud_user_xc
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${jumpcloudUserNodeRowIndex}`
		identityNodes.push({
			type: 'jumpcloudUser',
			data: { user: jumpcloudUserXc },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	const roleNodes: Array<BareNodeType<SnowflakeRoleNodeType>> = []
	const snowflakeRoles = identity.snowflake_user?.snowflake_roles_xc
	snowflakeRoles?.forEach((role, index) => {
		roleNodes.push({
			type: 'snowflakeRole',
			data: { role },
			id: `${nodeLogicalTypeToColumnId.role}-${index}`,
		})
	})

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	roleNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.role}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: roleNodes },
	]
	return [nodes, edges]
}

export const createLoginNodes = (
	groupedLogs: ServerUsageLogGrouped[],
	identity: ServerIdentity,
): BareNodeType<SnowflakeLoginNodeType>[] => {
	const issueMap = keyBy(identity.issues, 'issue_name')
	const identityIssuesNames = identity.issues?.map((issue) => issue.issue_name || '') ?? []

	const accessKeyNodes: BareNodeType<SnowflakeLoginNodeType>[] =
		groupedLogs?.map(({ key }, index) => {
			const issueAttached = first(
				intersection(accessKeyIssueNames, identityIssuesNames)
					.map((issueName) => issueMap[issueName])
					.filter(Boolean),
			)

			return {
				type: 'snowflakeLogin',
				data: {
					accessKey: key,
					issueAttached,
				},
				id: `${usageNodeLogicalTypeToColumnId.accessKey}-${index + 1}`,
			}
		}) ?? []

	return accessKeyNodes
}
