import React, { useCallback, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../routes/RoutePaths.tsx'
import { ServerFindingByPriority } from '../../schemas/metrics/findingsByPriority.ts'
import { ServerIssue, PriorityType, PriorityTypeEnum, PriorityTypeNameMap } from '../../schemas/issue.ts'
import { PriorityBar } from '../../components/common/PriorityBar.tsx'
import { DemoItdrIssueSources } from './DemoItdrIssueSources.tsx'

export const DemoItdrFindingsStats: React.FunctionComponent<{
	issuesData?: ServerIssue[]
	gridRef: React.RefObject<AgGridReact | null>
	filterChanged: { value: boolean }
	gridReady: boolean
}> = ({ issuesData, gridRef, filterChanged, gridReady }) => {
	const { issueFilter: searchFilter } = useSearch({ from: RoutePaths.Itdr })
	const navigate = useNavigate({ from: RoutePaths.Itdr })
	const issues = issuesData
	const findingsByPriority = useMemo(() => {
		if (!gridReady) return []
		if (!issues || !filterChanged.value) return []
		const acc: ServerFindingByPriority[] = []
		if (!gridRef?.current?.api) return acc
		for (let i = 0; i < gridRef?.current?.api?.getDisplayedRowCount(); i++) {
			const issue = gridRef?.current?.api?.getDisplayedRowAtIndex(i)?.data as ServerIssue
			const priority = issue?.priority
			if (priority && priority !== PriorityType.NOT_CALCULATED) {
				const index = acc.findIndex((item) => item.priorityNumber === priority)
				if (index === -1) {
					acc.push({
						priorityNumber: priority,
						priority: PriorityType[priority] as PriorityTypeEnum,
						count: 1,
					})
				} else {
					acc[index].count++
				}
			}
		}
		return acc
	}, [filterChanged])

	const filterByPriority = useCallback(
		(priority: string) => {
			if (!gridRef.current || !gridRef.current.api) return

			const priorityString = PriorityTypeNameMap[priority as PriorityTypeEnum]
			const currentFilter = searchFilter ?? {}
			const priorities = currentFilter.priority?.values || []

			if (priorities.length === 1 && priorities.includes(priorityString)) {
				// If the values array contains only this priority, delete the priority key
				delete currentFilter.priority
			} else {
				// Set the filter to just the chosen priority (including when no filter at all)
				currentFilter.priority = {
					filterType: 'set',
					values: [priorityString],
				}
			}

			gridRef.current.api.setFilterModel(currentFilter)
			navigate({
				search: (prev) => ({ ...prev, issueFilter: currentFilter }),
			})
		},
		[filterChanged], // Assuming filterChanged should be searchFilter, make sure this is updated if necessary
	)

	return (
		<div className="ml-2 grid grid-cols-4 gap-4 h-fit">
			<div className="col-span-2 min-w-[350px]">
				<div className="m-4 text-base text-textIcon-secondary-400">Finding Posture</div>
				{findingsByPriority.length > 0 ? (
					<div className="m-4">
						<PriorityBar
							findingsByPriority={findingsByPriority}
							onPriorityNameTagClick={filterByPriority}
						/>
					</div>
				) : (
					<div className="m-4 text-base text-textIcon-secondary">N/A</div>
				)}
			</div>

			<div className="col-span-2 min-w-[240px]">
				<DemoItdrIssueSources
					issuesData={issues}
					gridRef={gridRef}
					filterChanged={filterChanged}
					gridReady={gridReady}
				/>
			</div>
		</div>
	)
}
