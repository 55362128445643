import { useMemo, useState } from 'react'
import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import { JiraProject } from '../schemas/jiraSchemas.ts'
import { JiraIntegrationResponse } from '../../schemas/integrations/integrations.ts'
import { useFetchJiraIssuesPerProject } from '../api/jira.ts'

export interface JiraIssueOptionType {
	label: string
	value: string
	key: string
}

interface DebounceSelectJiraIssuesProps {
	project?: JiraProject
	integration: JiraIntegrationResponse
	value: JiraIssueOptionType | null
	onChange: (value: JiraIssueOptionType) => void
}

const debounceTimeout = 400

export function DebounceSelectJiraIssues({ integration, project, value, onChange }: DebounceSelectJiraIssuesProps) {
	const [query, setQuery] = useState<string>('')
	const { data: issues, isLoading: isIssuesLoading } = useFetchJiraIssuesPerProject(integration, query, project)
	const issueOptions: JiraIssueOptionType[] | undefined = useMemo(() => {
		if (!issues) {
			return
		}
		return issues.map((issue) => ({
			label: `${issue.key} - ${issue.summaryText}`,
			value: issue.id.toString(),
			key: issue.key,
		}))
	}, [issues])

	const onSearch = useMemo(() => debounce(setQuery, debounceTimeout), [])

	return (
		<Select
			showSearch
			placeholder="Type To Search"
			labelInValue
			filterOption={false}
			onSearch={onSearch}
			notFoundContent={isIssuesLoading ? <Spin size="small" /> : null}
			options={issueOptions}
			value={value}
			onChange={onChange}
		/>
	)
}
