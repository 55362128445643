import { ConfigProvider, Tag } from 'antd'
import React from 'react'
import { PriorityType, PriorityTypeMap } from '../../schemas/issue'
import { getPriorityColor, getPriorityColorLight } from '../../utils/styleUtils.ts'

interface PriorityNameTagProps {
	priority?: PriorityType | null
	value?: number
}

export const PriorityNameTag: React.FunctionComponent<PriorityNameTagProps> = ({ priority, value }) => {
	const effectivePriority = priority ?? PriorityType.NOT_CALCULATED // Fallback if `priority` is `null` or `undefined`.
	const tagColor: string = getPriorityColor(effectivePriority)
	const tagColorLight: string = getPriorityColorLight(effectivePriority)

	return (
		<ConfigProvider
			theme={{
				components: {
					Tag: {
						colorBorder: tagColor,
						colorText: tagColor,
						defaultBg: tagColorLight,
						marginXS: 0,
					},
				},
			}}
		>
			<Tag className="flex items-center justify-center">
				{' '}
				<div className="text-sm">{PriorityTypeMap[effectivePriority]}</div>
				{value !== undefined && <span className="ml-2 font-bold text-sm">{value > 100 ? '99+' : value}</span>}
			</Tag>
		</ConfigProvider>
	)
}
