import React from 'react'
import cx from 'classnames'
import { Modal, Button, Form, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { SlackIntegrationModalContent } from './SlackIntegrationModalContent.tsx'
import { IntegrationType } from '../../../../jiraIntegration/schemas/common.ts'
import SlackModalHeaderIcon from '../../../../assets/IntegrationPage/SlackIntegrationModalContent/SlackIntegrationModalHeaderIcon.svg?react'
import TeamsModalHeaderIcon from '../../../../assets/IntegrationPage/TeamsIntegrationModalContent/TeamsIntegrationModalHeaderIcon.svg?react'
import CollaborationIntegrationModalFooterTestConnectionIcon from '../../../../assets/IntegrationPage/CollaborationIntegrationModalFooterTestConnectionIcon.svg?react'
import {
	CollaborationIntegrationFormData,
	ConnectableCollaborationIntegrationConfig,
} from '../collaborationIntegrationsTypes.ts'
import { JiraIntegrationModalContent } from './JiraIntegrationModalContent.tsx'
import { TeamsIntegrationModalContent } from './TeamsIntegrationModalContent.tsx'

const { Title } = Typography

interface CollaborationsIntegrationModalProps {
	integration: ConnectableCollaborationIntegrationConfig | null
	integrationType: IntegrationType
	isModalVisible: boolean
	onClose: () => void
	onTestConnection: (values?: CollaborationIntegrationFormData | undefined) => Promise<void>
	onSubmit: (values: CollaborationIntegrationFormData) => void
	isCreatingIntegration: boolean
	isConnecting: boolean
	isTestingConnection: boolean
	testConnectionStatus: 'success' | 'error' | null
}

export const CollaborationsIntegrationModal: React.FC<CollaborationsIntegrationModalProps> = ({
	integration,
	integrationType,
	isModalVisible,
	onClose,
	onTestConnection,
	onSubmit,
	isConnecting,
	isTestingConnection,
	testConnectionStatus,
	isCreatingIntegration,
}) => {
	const [form] = Form.useForm<CollaborationIntegrationFormData>()
	if (!integration) return null

	const integrationConfigs = {
		[IntegrationType.SLACK]: {
			content: <SlackIntegrationModalContent />,
			needsForm: false,
		},
		[IntegrationType.TEAMS]: {
			content: <TeamsIntegrationModalContent integration={integration} />,
			needsForm: true,
		},
		[IntegrationType.JIRA]: {
			content: <JiraIntegrationModalContent integration={integration} />,
			needsForm: true,
		},
	}

	const renderModalContent = () => {
		const config = integrationConfigs[integrationType]
		if (!config) return <></>

		return config.needsForm ? (
			<Form form={form} layout="vertical" onFinish={onSubmit}>
				{config.content}
			</Form>
		) : (
			config.content
		)
	}

	const handleTestConnection = async () => {
		try {
			if (form) {
				await form.validateFields()
				const values = form.getFieldsValue()
				await onTestConnection(values)
			} else {
				await onTestConnection()
			}
		} catch (error) {
			return
		}
	}

	const handleCreate = async () => {
		try {
			if (form) {
				const values = await form.validateFields()
				onSubmit(values)
			}
		} catch (error) {
			return
		}
	}

	const modalTitleConfigs = {
		[IntegrationType.SLACK]: {
			icon: <SlackModalHeaderIcon />,
			title: 'Slack',
			className: 'flex items-center gap-2',
		},
		[IntegrationType.TEAMS]: {
			icon: <TeamsModalHeaderIcon />,
			title: 'Microsoft Teams',
			className: 'flex items-center gap-2',
		},
		[IntegrationType.JIRA]: {
			icon: integration.logo,
			title: `Login to your ${integration.name} account`,
			className: 'flex items-center gap-3 mb-3',
		},
	}

	const titleConfig = modalTitleConfigs[integrationType]
	const modalTitle = titleConfig && (
		<div className={titleConfig.className}>
			{titleConfig.icon}
			<Title level={4} className="!m-0">
				{titleConfig.title}
			</Title>
		</div>
	)

	const getTestConnectionButtonText = () => {
		if (isTestingConnection) return 'Testing Connection...'
		if (testConnectionStatus === 'success') return 'Test Successful'
		if (testConnectionStatus === 'error') return 'Test Failed'
		return 'Test Connection'
	}

	const testConnectionButtonClassName = cx({
		'text-status-active': testConnectionStatus === 'success',
		'text-status-critical': testConnectionStatus === 'error',
	})

	const modalFooter = (
		<div className="flex justify-end space-x-3 px-2 pt-4 border-t border-border-secondary">
			<Button
				type="default"
				onClick={() => void handleTestConnection()}
				disabled={isConnecting || isTestingConnection}
				loading={isTestingConnection}
				icon={<CollaborationIntegrationModalFooterTestConnectionIcon className="w-4 h-4" />}
				className={testConnectionButtonClassName}
			>
				{getTestConnectionButtonText()}
			</Button>
			{integrationType === IntegrationType.SLACK && (
				<Button type="primary" onClick={onClose}>
					Done
				</Button>
			)}
			{(integrationType === IntegrationType.JIRA || integrationType === IntegrationType.TEAMS) && (
				<Button
					type="primary"
					onClick={() => void handleCreate()}
					disabled={testConnectionStatus !== 'success'}
					loading={isCreatingIntegration || isTestingConnection}
				>
					Create
				</Button>
			)}
		</div>
	)

	return (
		<Modal
			open={isModalVisible}
			onCancel={onClose}
			footer={modalFooter}
			width={integration.modalWidth ?? 1000}
			destroyOnClose={true}
			centered
			title={modalTitle}
			closeIcon={<CloseOutlined />}
		>
			{renderModalContent()}
		</Modal>
	)
}
