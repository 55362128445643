import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { api } from './api.ts'
import {
	ServerIntegrationTicketInTokenDb,
	ServerIntegrationTicketInTokenDbSchema,
} from '../jiraIntegration/schemas/common.ts'
import {
	CollaborationIntegrationTestConnectionRequest,
	CollaborationIntegrationTestConnectionResponse,
	CollaborationIntegrationTestConnectionResponseSchema,
	CollaborationIntegrationResponse,
	CollaborationIntegrationResponseSchema,
	CollaborationIntegrationCreateRequest,
	CollaborationIntegrationCreateResponse,
	CollaborationIntegrationCreateResponseSchema,
} from '../schemas/integrations/integrations.ts'
import { JiraTicket } from '../jiraIntegration/schemas/jiraSchemas.ts'

export const integrationsApi = {
	async getCollaborationIntegrations(): Promise<CollaborationIntegrationResponse[]> {
		const { data: integrations } = await api.get<CollaborationIntegrationResponse[]>(
			'/api/integrations/get-all-collaboration-integrations',
		)
		return integrations.map((integration) => CollaborationIntegrationResponseSchema.parse(integration))
	},
	async createCollaborationIntegration(
		request: CollaborationIntegrationCreateRequest,
	): Promise<CollaborationIntegrationCreateResponse> {
		const { data } = await api.post<CollaborationIntegrationCreateResponse>('/api/integrations/create', request)
		return CollaborationIntegrationCreateResponseSchema.parse(data)
	},
	async createIntegrationTicketInTokenDb(
		issueId: string,
		integrationId: string,
		ticketMetadata: JiraTicket,
	): Promise<ServerIntegrationTicketInTokenDb> {
		const { data } = await api.post<ServerIntegrationTicketInTokenDb>(`/api/integrations/ticket`, {
			issueId,
			ticket: ticketMetadata,
			integrationId,
		})
		return ServerIntegrationTicketInTokenDbSchema.parse(data)
	},
	async getTickets(integrationId: string, issueId: string): Promise<ServerIntegrationTicketInTokenDb[]> {
		const { data } = await api.get<ServerIntegrationTicketInTokenDb[]>(
			`/api/integrations/${integrationId}/${issueId}`,
		)
		return data.map((ticket) => ServerIntegrationTicketInTokenDbSchema.parse(ticket))
	},
	async getSlackCallBackUrl(userAccessToken: string | undefined): Promise<string> {
		if (!userAccessToken) {
			throw new Error('User access token is required')
		}
		const { data } = await api.get<{ url: string }>(
			`/api/integrations/slack-url-builder?current_user_token=${userAccessToken}`,
		)
		return data.url
	},
	async collaborationIntegrationTestConnection(
		request: CollaborationIntegrationTestConnectionRequest,
	): Promise<CollaborationIntegrationTestConnectionResponse> {
		const { data } = await api.post<CollaborationIntegrationTestConnectionRequest>(
			'/api/integrations/test-collaboration-integration-connection',
			request,
		)
		return CollaborationIntegrationTestConnectionResponseSchema.parse(data)
	},
}

export function useCollaborationIntegrations() {
	return useQuery({
		queryKey: ['integrations'],
		queryFn: () => integrationsApi.getCollaborationIntegrations(),
	})
}

export function useSlackUrlBuilder(userAccessToken: string | undefined) {
	return useQuery({
		queryKey: ['integrations', 'slack-url', userAccessToken],
		queryFn: () => integrationsApi.getSlackCallBackUrl(userAccessToken),
	})
}

export function useCollaborationTestConnection() {
	return useMutation({
		mutationFn: async (request: CollaborationIntegrationTestConnectionRequest) => {
			return await integrationsApi.collaborationIntegrationTestConnection(request)
		},
	})
}

export function useCreateCollaborationIntegration() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: async (request: CollaborationIntegrationCreateRequest) => {
			return await integrationsApi.createCollaborationIntegration(request)
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: ['integrations'] })
		},
	})
}
