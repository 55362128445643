import { CustomCellRendererProps } from 'ag-grid-react'
import { OverlappingCredentialsTag } from '../../../components/common/OverlappingCredentialsTag.tsx'
import { IdentitiesTableContext, ServerIdentitiesTableRow } from '../../../schemas/identity.ts'
import { useIdentitiesQuickSearchText } from '../useIdentitiesQuickSearchText.ts'

type CredentialsCellRendererProps = CustomCellRendererProps<
	ServerIdentitiesTableRow,
	ServerIdentitiesTableRow['credentials'],
	IdentitiesTableContext
>

export const CredentialsCellRenderer = ({ value }: CredentialsCellRendererProps) => {
	const searchText = useIdentitiesQuickSearchText()
	return <OverlappingCredentialsTag credentials={value ?? []} searchText={searchText} />
}
