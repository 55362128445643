import { endpointAccessXc } from './crossContextData.ts'
import { generateUUID } from '../utils.ts'
import { IdentityActivityStatus, IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { GithubTokenType } from '../../schemas/identities/githubUserSchema.ts'
import { ServerEndpointAccessXcInput } from '../../schemas/identities/endpointAccessXcSchema.ts'
import { ServerAwsUsageLogInput } from '../../schemas/identities/awsIamUserSchema.ts'
import { DemoServerIdentityInput } from '../schemas.ts'

export const githubUsageLogs: Array<ServerAwsUsageLogInput> = [
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		events: ['dashboard.git.fetch', 'workflows.rerun_workflow_run', 'demo-repository.git.fetch'],
		events_count: 400,
		day: '2024-07-02T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		events: ['dashboard.git.fetch', 'workflows.rerun_workflow_run', 'demo-repository.git.fetch'],
		events_count: 31,
		day: '2024-07-20T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'prisma.gabrielabeard',
		events: ['dashboard.pull_request.merge', 'dashboard.git.push'],
		events_count: 2,
		day: '2024-01-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'prisma.gabrielabeard',
		events: ['pull_request.merge', 'git.push'],
		events_count: 1,
		day: '2024-04-23T11:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.75.19.123',
		association: 'prisma.gabrielabeard',
		events: ['pull_request.merge'],
		events_count: 1,
		day: '2024-03-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
	},
]

export const githubEndpointAccessXc: Array<ServerEndpointAccessXcInput> = [
	{
		device_name: "Gabriela's iPhone",
		device_type: 'Mobile - iOS',
		status: 'Active',
		endpoint_protection: false,
		ip_address: '192.75.19.123',
		first_seen: '2024-03-11 14:42:39+00:00',
		last_seen: '2024-06-29 21:04:33+00:00',
	},
	{
		device_name: "Gabriela's Dell",
		device_type: 'Desktop - Windows',
		status: 'Active',
		endpoint_protection: true,
		ip_address: '192.168.1.10',
		first_seen: '2024-02-23 08:40:08+00:00',
		last_seen: '2024-07-23 11:13:52+00:00',
	},
]

export const githubUserData = {
	'40000': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6400',
		environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
		literal: 'synergistic-sciences/gabrielabeard',
		literal_friendly_name: null,
		created_at: '2024-06-20T15:28:32.698488Z',
		updated_at: '2024-06-20T15:28:32.698488Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty123',
		account_literal_friendly_name: 'synergistic-sciences',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'Gabriela Beard',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'gabrielabeard',
			email: '',
			role: 'ADMIN',
			tokens: [
				{
					id: 1,
					name: 'gabriale-pc',
					created_at: '2024-06-20T12:04:57.328045Z',
					type: GithubTokenType.CLASSIC_PAT,
				},
			],
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
			usage_logs: githubUsageLogs,
			endpoint_access_xc: githubEndpointAccessXc,
			aws_ec2_instances_xc: [
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-08f665a7994f33b40',
					InstanceType: 't4g.small',
					LaunchTime: '2024-02-06T12:58:53Z',
					PrivateIpAddress: '172.38.71.113',
					PublicIpAddress: '18.21.139.125',
					State: {
						Code: 16,
						Name: 'running',
					},
					Tags: [
						{
							Key: 'Environment',
							Value: 'Staging',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-staging-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
				{
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0cb6586b166591a28',
					InstanceType: 't4g.micro',
					LaunchTime: '2024-02-23T19:37:06Z',
					PrivateIpAddress: '172.31.81.123',
					PublicIpAddress: '18.15.137.15',
					State: {
						Code: 16,
						Name: 'running',
					},
					Tags: [
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-mob1-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
					},
				},
			],
		},
	},
	'40001': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6401',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergistic-sciences/David-Brown',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty123',
		account_literal_friendly_name: 'synergistic-sciences',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'david brown',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'David-Brown',
			email: 'david.brown@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40002': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6402',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergistic-sciences/eliSampars',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty123',
		account_literal_friendly_name: 'synergistic-sciences',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'eli Sampars',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'eliSampars',
			email: 'eli.sampars@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40003': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6403',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/David-Brown',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'david brown',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'David-Brown',
			email: 'david.brown@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40004': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6404',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/elizabeth_coleman',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'Elizabeth Coleman',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'elizabeth_coleman',
			email: 'elizabeth.coleman@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40005': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6405',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/michaeljones',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'michaeljones',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'michaeljones',
			email: 'michael.jones@synergy.com',
			role: 'MEMBER',
			tokens: [
				{
					id: 1,
					name: 'michael-pc',
					created_at: '2024-06-20T12:04:57.328045Z',
					type: GithubTokenType.CLASSIC_PAT,
				},
			],
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'synergy-charts',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergy-charts',
							source_type: 'Repository',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
			entra_id_user_xc: {
				mail: 'michael.jones@synergy.com',
				job_title: 'DevOps',
				given_name: 'Michel',
				entra_user_id: generateUUID(),
				last_activity: '2024-07-09 11:16:04+00:00',
				account_enabled: false,
				user_principal_name: 'michael.jones@synergy.com',
				password_last_changed: '2023-10-22 18:03:03+00:00',
			},
		},
	},
	'40006': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6406',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/jane-smith',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: '',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'jane-smith',
			email: '',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40007': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6407',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/shelby',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'shelby',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'shelby',
			email: 'ms.shelby@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40008': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6408',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/wvargas',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-01-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'William Vargas',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'wvargas',
			email: 'william.vargas@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
			endpoint_access_xc: endpointAccessXc,
		},
	},
	'40009': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6409',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'AlexJohnson',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-01-20T15:28:32.698488Z',
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Human,
		source: IdentitySource.GITHUB_USER,
		issues: [],
		tags: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'Alex Johnson',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'AlexJohnson',
			email: 'johnsonalex@gmail.com',
			role: 'OUTSIDER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40010': {
		id: '85dd18ab-32ec-43f4-a4f1-6b3861b86e56',
		environment_id: '35efe7ff-95df-4f54-be2c-5e4b52ed3724',
		literal: 'synergy-ci-cd',
		literal_friendly_name: null,
		created_at: '2023-04-10T13:43:01Z',
		updated_at: '2024-08-28T09:08:43Z',
		last_activity: null,
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Machine,
		source: IdentitySource.GITHUB_APP_INSTALLATION,
		issues: [],
		tags: [],
		account_literal: 'O_kgDOAM-9uQ',
		account_literal_friendly_name: 'synergy-hq',
		github_app_installation: {
			customer_id: '052aab09-f731-4a79-9f12-99a8d8f3c0dd',
			id: 'a252be03-281c-419f-9001-ac9ab429d2ce',
			name: 'synergy-ci-cd',
			created_at: '2023-04-10T13:43:01Z',
			updated_at: '2024-08-28T09:08:43Z',
			last_activity: null,
			environment_id: '35efe7ff-95df-4f54-be2c-5e4b52ed3724',
			sub_env_id: null,
			installation_id: 36276588,
			app_id: 315796,
			is_public: false,
			owner_id: null,
			owner_name: null,
			permissions: [
				{
					scope: 'checks',
					permission: 'write',
				},
				{
					scope: 'contents',
					permission: 'write',
				},
				{
					scope: 'metadata',
					permission: 'read',
				},
				{
					scope: 'statuses',
					permission: 'write',
				},
				{
					scope: 'administration',
					permission: 'write',
				},
				{
					scope: 'repository_hooks',
					permission: 'write',
				},
			],
			repositories: ['synergy', 'ios', 'android'],
		},
	},
	'40011': {
		id: 'cef0a878-e5c4-4238-b3cd-6c48301079ce',
		environment_id: '35efe7ff-95df-4f54-be2c-5e4b52ed3724',
		literal: 'slack',
		literal_friendly_name: null,
		created_at: '2019-11-24T09:06:43Z',
		updated_at: '2024-08-27T11:19:22Z',
		last_activity: null,
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.GITHUB,
		type: IdentityType.Machine,
		source: IdentitySource.GITHUB_APP_INSTALLATION,
		issues: [],
		tags: [],
		account_literal: 'O_kgDOAM-9uQ',
		account_literal_friendly_name: 'synergy-hq',
		github_app_installation: {
			customer_id: '052aab09-f731-4a79-9f12-99a8d8f3c0dd',
			id: '80eb0664-255c-4a9c-9ab2-bf1ad5653759',
			name: 'slack',
			created_at: '2019-11-24T09:06:43Z',
			updated_at: '2024-08-27T11:19:22Z',
			last_activity: null,
			environment_id: '35efe7ff-95df-4f54-be2c-5e4b52ed3724',
			sub_env_id: null,
			installation_id: 5366888,
			app_id: 7100,
			is_public: true,
			owner_id: 9919,
			owner_name: 'github',
			permissions: [
				{
					scope: 'checks',
					permission: 'read',
				},
				{
					scope: 'issues',
					permission: 'write',
				},
				{
					scope: 'actions',
					permission: 'write',
				},
				{
					scope: 'contents',
					permission: 'read',
				},
				{
					scope: 'metadata',
					permission: 'read',
				},
				{
					scope: 'statuses',
					permission: 'read',
				},
				{
					scope: 'deployments',
					permission: 'write',
				},
				{
					scope: 'discussions',
					permission: 'read',
				},
				{
					scope: 'pull_requests',
					permission: 'write',
				},
				{
					scope: 'repository_projects',
					permission: 'read',
				},
			],
			repositories: ['synergy', 'ios', 'android'],
		},
	},
} satisfies Record<string, DemoServerIdentityInput>
