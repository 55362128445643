import { RemediationType, ServerIssue } from '../../../../schemas/issue.ts'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { useEntitlementToAIRemediation } from '../../../../services/auth/featureFlags.ts'
import { Button, Modal } from 'antd'
import { ExpandAltOutlined } from '@ant-design/icons'
import { IssueRemediationContent } from './IssueRemediationContent.tsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IssueRemediationHeader } from './IssueRemediationHeader.tsx'
import { issuesRemediationStepsMap } from '../../../../components/remediationSteps/remediationStepsData.ts'

type IssueRemediationProps = {
	issue: ServerIssue
	identity: ServerIdentity
}

export const IssueRemediation = ({ issue, identity }: IssueRemediationProps) => {
	const { isEntitled: isEntitledToAIRemediation } = useEntitlementToAIRemediation()
	const [isModalOpen, setIsModalOpen] = useState(false)
	// Holding AI remediation selected remediation type in this component to be shared between inlined remediation
	//  component and the one in the modal
	const [selectedRemediationType, setSelectedRemediationType] = useState<RemediationType | null>(null)
	const issueHasAiRemediation = isEntitledToAIRemediation && !!issue.ai_remediation_types?.length
	const remediationSteps = useMemo(
		() =>
			identity.env_type && issue.issue_name
				? issuesRemediationStepsMap[identity.env_type]?.[issue.issue_name]?.[identity.source]
				: undefined,
		[identity.env_type, issue.issue_name, identity.source],
	)

	const closeModal = useCallback(() => {
		setIsModalOpen(false)
	}, [setIsModalOpen])

	const openModal = useCallback(() => {
		setIsModalOpen(true)
	}, [setIsModalOpen])

	// When an issue changes, reset AI remediation state
	useEffect(() => {
		setSelectedRemediationType(null)
	}, [issue.id])

	return (
		<>
			<div className="grow min-h-72 flex flex-col rounded border border-border-secondary">
				<div className="bg-surface-secondary border-b border-border-secondary flex items-center justify-between p-3 shrink-0">
					<IssueRemediationHeader issue={issue} />
					{issueHasAiRemediation || remediationSteps?.length ? (
						<Button size="small" type="text" icon={<ExpandAltOutlined />} onClick={openModal} />
					) : null}
				</div>
				<IssueRemediationContent
					issue={issue}
					remediationSteps={remediationSteps}
					issueHasAiRemediation={issueHasAiRemediation}
					selectedRemediationType={selectedRemediationType}
					setSelectedRemediationType={setSelectedRemediationType}
				/>
			</div>
			<Modal
				open={isModalOpen}
				onCancel={closeModal}
				title={<IssueRemediationHeader issue={issue} />}
				width="70%"
				height="70%"
				classNames={{ body: 'h-[70vh] overflow-y-auto' }}
				footer={null}
			>
				<IssueRemediationContent
					issue={issue}
					remediationSteps={remediationSteps}
					issueHasAiRemediation={issueHasAiRemediation}
					isFullScreen
					selectedRemediationType={selectedRemediationType}
					setSelectedRemediationType={setSelectedRemediationType}
				/>
			</Modal>
		</>
	)
}
