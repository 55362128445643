import { Tooltip } from 'antd'
import { useEntitlementToAIRemediation } from '../../services/auth/featureFlags.ts'
import { type ServerIssue } from '../../schemas/issue.ts'
import InsightIcon from '../../assets/insight_icon_20.svg?react'
import { forwardRef, HTMLAttributes } from 'react'

type AIRemediationTagInternalProps = HTMLAttributes<HTMLDivElement>

const AIRemediationTagInternal = forwardRef<HTMLDivElement, AIRemediationTagInternalProps>((divProps, ref) => (
	<div
		{...divProps}
		ref={ref}
		className="flex items-center gap-1 rounded bg-dsBlue-100 text-textIcon-brand py-1 pl-1 pr-2 text-sm font-medium transition-colors text-nowrap"
	>
		<InsightIcon width={20} height={20} />
		<span>Token AI</span>
	</div>
))

type AIRemediationTagProps = {
	issue: ServerIssue
	tooltipText?: string
}

export const AIRemediationTag = ({ issue, tooltipText }: AIRemediationTagProps) => {
	const { isEntitled: isEntitledToAIRemediation } = useEntitlementToAIRemediation()

	if (!isEntitledToAIRemediation) {
		return null
	}

	if (!issue.ai_remediation_types?.length) {
		return null
	}

	if (!tooltipText) {
		return <AIRemediationTagInternal />
	}

	return (
		<Tooltip title={tooltipText}>
			<AIRemediationTagInternal />
		</Tooltip>
	)
}
