import { OverlappingOwnershipTags } from '../../../components/common/OverLappingOwnershipTags.tsx'
import { useIdentitiesQuickSearchText } from '../useIdentitiesQuickSearchText.ts'
import { IdentitiesTableContext, ServerIdentitiesTableRow } from '../../../schemas/identity.ts'
import { CustomCellRendererProps } from 'ag-grid-react'

type OwnersCellRendererProps = CustomCellRendererProps<
	ServerIdentitiesTableRow,
	ServerIdentitiesTableRow['owners'],
	IdentitiesTableContext
>

export const OwnersCellRenderer = ({ value }: OwnersCellRendererProps) => {
	const searchText = useIdentitiesQuickSearchText()
	return <OverlappingOwnershipTags owners={value ?? []} searchText={searchText} />
}
