import { Node, NodeProps } from '@xyflow/react'
import { MinusOutlined } from '@ant-design/icons'
import { CombinedAwsIamRolePolicy } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { getPolicyUniqueKey } from '../../../../utils/awsIdentityUtils.ts'
import { NodeBadge } from '../common/NodeBadge.tsx'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type AwsPolicyNodeType = Node<{ policy: CombinedAwsIamRolePolicy }, 'awsPolicy'>
type AwsPolicyNodeProps = NodeProps<AwsPolicyNodeType>

export const AwsPolicyNode = ({ data: { policy } }: AwsPolicyNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'awsPolicy' && highlightedNode.id === getPolicyUniqueKey(policy),
	)

	return (
		<BasePermissionNode
			label={policy.IsPermissionBoundary ? 'Permissions Boundary' : 'AWS Policy'}
			name={{ text: policy.PolicyName || 'Unknown policy', tooltipText: policy.PolicyArn || undefined }}
			isHighlighted={isHighlighted}
			risks={policy.IsPermissionBoundary ? null : policy.RiskTags}
		>
			{policy.IsPermissionBoundary ? (
				<NodeBadge nodeColorType="permission">
					<MinusOutlined />
				</NodeBadge>
			) : null}
		</BasePermissionNode>
	)
}
