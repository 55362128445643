import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { ControlButton, useReactFlow, Controls, FitViewOptions, useNodesInitialized } from '@xyflow/react'
import { CompactModeControlButton } from './CompactModeControlButton'
import PlusIcon from '../../../assets/pluse.svg?react'
import MinusIcon from '../../../assets/minus.svg?react'
import FitIcon from '../../../assets/CornersIn.svg?react'
import { GraphViewControlButton } from './GraphViewControlButton.tsx'
import { IdentityGraphViewTypeEnum } from './identityGraphTypes.ts'
import cx from 'classnames'
import { IdentitySource, ServerIdentity } from '../../../schemas/identity.ts'
import { Tooltip } from 'antd'

type IdentityGraphControlsProps = {
	isCompact: boolean
	setIsCompact: Dispatch<SetStateAction<boolean>>
	fitViewOptions: FitViewOptions
	viewType: IdentityGraphViewTypeEnum
	onViewTypeClicked: (viewType: IdentityGraphViewTypeEnum) => void
	identity: ServerIdentity
}

const baseControlButtonClassName =
	'!bg-surface-secondary !border !rounded-sm !border-solid !border-border-primary !p-0 !m-0'
const segmentedControlButtonClassName = (isSelected: boolean) =>
	cx(baseControlButtonClassName, '!w-6 !h-6 !text-textIcon-primary !relative overflow-hidden', {
		'!bg-surface-tertiary hover:!bg-surface-fourthy hover:!border-dsNaturals-500': !isSelected,
		'!bg-dsBlue-200 hover:!bg-dsBlue-300 !border-textIcon-brand': isSelected,
	})

const mainControlButtonClassName = cx(baseControlButtonClassName, '!w-8 !h-8 hover:!bg-surface-tertiary')

const USAGE_GRAPH_SUPPORTED_TYPES = [
	IdentitySource.AWS_IAM_USER,
	IdentitySource.SNOWFLAKE_USER,
	IdentitySource.GCP_SERVICE_ACCOUNT,
	IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
	IdentitySource.DEMO_ATLASSIAN_USER,
]

export const IdentityGraphControls = ({
	isCompact,
	setIsCompact,
	fitViewOptions,
	viewType,
	onViewTypeClicked,
	identity,
}: IdentityGraphControlsProps) => {
	const { zoomIn, zoomOut, fitView } = useReactFlow()
	const nodesInitialized = useNodesInitialized()

	useEffect(() => {
		void fitView({ duration: 1000, maxZoom: 0.8 })
	}, [nodesInitialized])

	const basicControlButtons = useMemo(
		() => [
			{
				onClick: () => {
					void fitView()
				},
				icon: FitIcon,
				tooltipText: 'Fit view',
				key: 'fit',
			},
			{
				onClick: () => {
					void zoomIn()
				},
				icon: PlusIcon,
				tooltipText: 'Zoom in',
				key: 'zoomIn',
			},
			{
				onClick: () => {
					void zoomOut()
				},
				icon: MinusIcon,
				tooltipText: 'Zoom out',
				key: 'zoomOut',
			},
		],
		[],
	)

	return (
		<Controls
			showInteractive={false}
			showFitView={false}
			showZoom={false}
			position="bottom-left"
			fitViewOptions={fitViewOptions}
			className="bg-transparent border-none !shadow-none"
		>
			<div className="grid gap-2 [&_.ant-segmented-item-label]:p-[3px]">
				{USAGE_GRAPH_SUPPORTED_TYPES.includes(identity.source) && (
					<GraphViewControlButton
						viewType={viewType}
						onViewTypeClicked={onViewTypeClicked}
						segmentedClassName={baseControlButtonClassName}
						controlButtonClassName={segmentedControlButtonClassName}
						identity={identity}
					/>
				)}
				{basicControlButtons.map(({ key, onClick, icon: ItemIcon, tooltipText }) => (
					<Tooltip title={tooltipText} placement="right" key={key}>
						<span>
							<ControlButton onClick={onClick} className={mainControlButtonClassName}>
								<ItemIcon className="!fill-transparent !max-w-7 !max-h-7" />
							</ControlButton>
						</span>
					</Tooltip>
				))}
				<CompactModeControlButton
					isCompact={isCompact}
					setIsCompact={setIsCompact}
					className={mainControlButtonClassName}
				/>
			</div>
		</Controls>
	)
}
