import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import JumpcloudIcon from '../../../../assets/jumpcloud_icon_16.svg?react'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'
import { ServerJumpcloudEnvironment } from '../../../../schemas/environments/jumpcloudEnvironment.ts'

type JumpcloudSinglePageIntegrationBodyProps = {
	jumpcloudEnvironments: ServerJumpcloudEnvironment[]
}

export const JumpcloudSinglePageIntegrationBody: React.FC<JumpcloudSinglePageIntegrationBodyProps> = ({
	jumpcloudEnvironments,
}) => {
	return (
		<IntegrationsSection>
			{jumpcloudEnvironments?.map((environment) => {
				const cardConfig = {
					title: environment.name,
					logo: <IntegrationIcon leftIcon={JumpcloudIcon} />,
					fields: [{ label: 'Organization', value: environment.org_id }],
				}
				return (
					<SingleIntegrationGenericCard
						key={environment.id}
						title={cardConfig.title}
						logo={cardConfig.logo}
						fields={cardConfig.fields}
					/>
				)
			})}
		</IntegrationsSection>
	)
}
