import { Layout } from 'antd'
import { ReactNode } from 'react'
import cx from 'classnames'

type PageProps = {
	children: ReactNode
	sidebar?: ReactNode
	contentClassName?: string
}

export const Page = ({ children, sidebar, contentClassName }: PageProps) => {
	const contentFullClassName = cx('p-3 flex flex-col h-full gap-3 overflow-x-hidden', contentClassName)
	return (
		<Layout className="h-full rounded-lg">
			{sidebar}
			<Layout.Content className={contentFullClassName}>{children}</Layout.Content>
		</Layout>
	)
}
