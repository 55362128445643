import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import ActiveDirectoryIcon from '../../../../assets/active_directory_icon_16.svg?react'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'
import { ServerActiveDirectoryEnvironment } from '../../../../schemas/environments/activeDirectoryEnvironment.ts'

type ActiveDirectorySinglePageIntegrationBodyProps = {
	activeDirectoryEnvironments: ServerActiveDirectoryEnvironment[]
}

export const ActiveDirectorySinglePageIntegrationBody: React.FC<ActiveDirectorySinglePageIntegrationBodyProps> = ({
	activeDirectoryEnvironments,
}) => {
	return (
		<IntegrationsSection>
			{activeDirectoryEnvironments?.map((environment) => {
				const cardConfig = {
					title: environment.name,
					logo: <IntegrationIcon leftIcon={ActiveDirectoryIcon} />,
					fields: [{ label: 'Domain', value: environment.domain }],
				}
				return (
					<SingleIntegrationGenericCard
						key={environment.id}
						title={cardConfig.title}
						logo={cardConfig.logo}
						fields={cardConfig.fields}
					/>
				)
			})}
		</IntegrationsSection>
	)
}
