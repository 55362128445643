import { useCallback, useState, MutableRefObject } from 'react'
import { Button, Modal } from 'antd'
import ExportIcon from '../../assets/export_icon_20.svg?react'
import { handleExportingPaginatedDataToCsv } from './findingsTableUtils.ts'
import { formatNumber } from '../../utils/numberUtils.ts'
import { IssueTablePaginatedRow, IssueType } from '../../schemas/issue.ts'
import { AgGridReact } from 'ag-grid-react'
import { useIssuesExportingLimit } from '../../api/issues.ts'
import { trackEvent, LogRocketTrackEvent } from '../../services/logrocket/logrocket.ts'

export const FindingsExport: React.FC<{
	gridRef: MutableRefObject<AgGridReact<IssueTablePaginatedRow> | null>
	issueType?: IssueType
}> = ({ gridRef, issueType }) => {
	const { data: maxIssuesToExport, isPending: isPendingMaxIssuesToExport } = useIssuesExportingLimit()
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	const exportPaginatedDataToCsv = useCallback(() => {
		handleExportingPaginatedDataToCsv(gridRef?.current?.api, issueType)
		setIsModalOpen(false)
	}, [issueType])

	const handleExportClicked = useCallback(() => {
		if (issueType === IssueType.ITDR) {
			trackEvent(LogRocketTrackEvent.ITDRCsvDataExported, {})
		}
		const totalRowCount = gridRef?.current?.api?.paginationGetRowCount() || 0
		if (totalRowCount > Number(maxIssuesToExport)) {
			setIsModalOpen(true)
		} else {
			exportPaginatedDataToCsv()
		}
	}, [maxIssuesToExport])

	const handleModalClose = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<Button
				icon={<ExportIcon />}
				onClick={handleExportClicked}
				loading={isPendingMaxIssuesToExport}
				className="border-border-tertiary"
			>
				Export CSV
			</Button>

			<Modal
				centered
				open={isModalOpen}
				width={530}
				onCancel={handleModalClose}
				onClose={handleModalClose}
				title={'Confirm Export'}
				key="identities-export-modal"
				footer={[
					<div key="identities-export-modal-footer" className="flex gap-2 justify-end">
						<Button onClick={handleModalClose}>Cancel</Button>
						<Button type="primary" onClick={exportPaginatedDataToCsv}>
							Export
						</Button>
					</div>,
				]}
			>
				<div className="flex justify-start py-3 whitespace-pre-line">
					{`The export limit is ${formatNumber(Number(maxIssuesToExport))} items. You’re trying to export ${gridRef?.current?.api?.paginationGetRowCount()} items.
					Refine your filters or click “Export” to download the first ${formatNumber(Number(maxIssuesToExport))} items.`}
				</div>
			</Modal>
		</>
	)
}
