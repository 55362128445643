import { Skeleton, Spin } from 'antd'
import { useIdentity } from '../../api/identities.ts'
import { IdentityHeader } from './IdentityHeader.tsx'
import { IdentityPageBody } from './IdentityPageBody.tsx'
import { HighlightedNodesProvider } from './HighlightedNodesContext.tsx'
import { useParams } from '@tanstack/react-router'
import { RoutePaths } from '../RoutePaths.tsx'
import { ActiveSidePanelKeysProvider } from './ActiveSidePanelKeysContext.tsx'
import { ActiveTagsKeysProvider } from './ActiveTagsKeysContext.tsx'
import { Page } from '../../components/common/Page.tsx'
import { PageHeader } from '../../components/common/PageHeader.tsx'
import { IdentityStats } from './IdentityStats.tsx'

export const IdentityPage = () => {
	const { identityId } = useParams({ from: RoutePaths.Identity })
	const {
		data: identity,
		isPending: isIdentityPending,
		isError: isIdentityError,
		isFetching: isIdentityFetching,
	} = useIdentity(identityId)

	return (
		<>
			<Page>
				<PageHeader>
					<IdentityHeader identityId={identityId} />
				</PageHeader>
				{isIdentityPending ? (
					<Skeleton className="p-4" active />
				) : isIdentityError ? (
					<div className="flex justify-center pt-8">{`Could not fetch identity ${identityId}`}</div>
				) : (
					<>
						<IdentityStats identity={identity} />
						<HighlightedNodesProvider>
							<ActiveSidePanelKeysProvider>
								<ActiveTagsKeysProvider>
									<IdentityPageBody identity={identity} />
								</ActiveTagsKeysProvider>
							</ActiveSidePanelKeysProvider>
						</HighlightedNodesProvider>
					</>
				)}
			</Page>
			<Spin
				spinning={isIdentityFetching && !isIdentityPending}
				delay={100}
				fullscreen={isIdentityFetching && !isIdentityPending}
				size="large"
			/>
		</>
	)
}
