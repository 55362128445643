import { NodeProps, Node } from '@xyflow/react'
import { CheckCircleOutlined, MinusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
	BaseServerIssue,
	IssueName,
	IssueNameMap,
	IssueStatus,
	IssueStatusMap,
	IssueType,
	OpenIssueStatuses,
	PriorityType,
	PriorityTypeMap,
} from '../../../../schemas/issue.ts'
import { IssueIcon } from '../../../../components/common/IssueIcon.tsx'
import { BaseNode } from './baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { getIssueNodeColorType, nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { type NodeLabelProps } from './NodeLabel.tsx'
import { getColorFromTailwindColorClassName, themeColors } from '../../../../utils/colorUtils.ts'
import { useMemo } from 'react'
import { getPriorityColor, getPriorityColorLight } from '../../../../utils/styleUtils.ts'
import FalsePositiveIcon from '../../../../assets/false_positive_icon.svg?react'
import { formatDate } from '../../../../utils.ts'
import { NodeBadge } from './NodeBadge.tsx'
import ItdrIcon from '../../../../assets/itdr_icon.svg?react'
import { Tooltip } from 'antd'

export type IssueNodeType = Node<{ issue: BaseServerIssue }, 'issue'>
type IssueNodeProps = NodeProps<IssueNodeType>

const getClosedIssueLabelProps = (issue: BaseServerIssue): NodeLabelProps => {
	const labelProps: NodeLabelProps = {
		label: `${IssueStatusMap[issue.status]} Issue`,
	}

	if (issue.status === IssueStatus.RESOLVED) {
		labelProps.iconNode = <CheckCircleOutlined />
		labelProps.bgColor = themeColors.status.activeLowContrast
		labelProps.textColor = themeColors.status.active
		labelProps.tooltipTitle = issue.resolved_at ? `Resolved at ${formatDate(issue.resolved_at, false)}` : undefined
	} else if (issue.status === IssueStatus.IGNORED) {
		labelProps.iconNode = <MinusCircleOutlined />
		labelProps.bgColor = themeColors.status.neutralLowContrast
		labelProps.textColor = themeColors.status.neutral
		labelProps.tooltipTitle = issue.ignore_reason ?? undefined
	} else if (issue.status === IssueStatus.FALSE_POSITIVE) {
		labelProps.iconNode = (
			<span>
				<FalsePositiveIcon />
			</span>
		)
		labelProps.bgColor = themeColors.status.neutralLowContrast
		labelProps.textColor = themeColors.status.neutral
		labelProps.tooltipTitle = issue.ignore_reason ?? undefined
	}

	return labelProps
}

const getIssueNodeLabelProps = (issue: BaseServerIssue): NodeLabelProps => {
	if (OpenIssueStatuses.includes(issue.status)) {
		return {
			label: `${PriorityTypeMap[issue.priority ?? PriorityType.NOT_CALCULATED]} Priority Issue`,
			iconNode: <ExclamationCircleOutlined />,
			bgColor: getPriorityColorLight(issue.priority),
			textColor: getPriorityColor(issue.priority),
		}
	}

	return getClosedIssueLabelProps(issue)
}

export const IssueNode = ({ data: { issue } }: IssueNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'issue' && highlightedNode.id === issue.id,
	)

	const issueNodeColorType = getIssueNodeColorType(issue)
	const iconWrapperClassName = cx(
		`${nodeColorClassNames[issueNodeColorType].bgHover} ${nodeColorClassNames[issueNodeColorType].text} transition-colors duration-300 w-[50px] h-[50px] rounded-full flex justify-center items-center`,
		{
			[nodeColorClassNames[issueNodeColorType].bg]: !isHighlighted,
			[nodeColorClassNames[issueNodeColorType].bgHighlighted]: isHighlighted,
		},
	)

	const labelProps = useMemo(() => getIssueNodeLabelProps(issue), [issue])

	return (
		<BaseNode
			labelProps={labelProps}
			name={{ text: IssueNameMap[issue.issue_name as IssueName] || 'Unknown Issue', tooltipText: '' }}
		>
			{issue.issue_type === IssueType.ITDR && (
				<Tooltip title="Identity Threat">
					<NodeBadge breathingAnimation={true} nodeColorType={issueNodeColorType}>
						<ItdrIcon />
					</NodeBadge>
				</Tooltip>
			)}
			<div className={iconWrapperClassName}>
				<IssueIcon
					color={getColorFromTailwindColorClassName(nodeColorClassNames[issueNodeColorType].text)}
					size={24}
					name={issue.issue_name}
				/>
			</div>
		</BaseNode>
	)
}
