import { NodeProps, Node } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'
import { DemoAtlassianApiToken } from '../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'

export type DemoAtlassianApiTokenNodeType = Node<DemoAtlassianApiToken, 'atlassianApiToken'>

type DemoAtlassianApiTokenNodeProps = NodeProps<DemoAtlassianApiTokenNodeType>

export const DemoAtlassianApiTokenNode = ({ data: { name, expires_on } }: DemoAtlassianApiTokenNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'atlassianApiToken' && highlightedNode.id === name,
	)

	return (
		<BaseCredentialsNode
			isHighlighted={isHighlighted}
			label="API Token"
			name={{ text: name }}
			isActive={expires_on > new Date()}
		/>
	)
}
