import { z } from 'zod'

export enum OnboardingStateType {
	PENDING = 'PENDING',
	INTEGRATING = 'INTEGRATING',
	INTEGRATED = 'INTEGRATED',
	FAILED = 'FAILED',
}

export const OnboardingStateSchema = z.object({
	customer_id: z.string(),
	status: z.nativeEnum(OnboardingStateType),
})

export type OnboardingStateInput = z.input<typeof OnboardingStateSchema>
export type OnboardingState = z.infer<typeof OnboardingStateSchema>
