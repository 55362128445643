import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent, IRowNode } from 'ag-grid-enterprise'
import { formatDate, removeNulls } from '../../../../../utils.ts'
import { capitalizeFirstLetter } from '../../../../../utils/textUtils.ts'
import { JsonViewer } from '../../../../../components/common/JsonViewer.tsx'
import { ServerGcpServiceAccountResourceData } from '../../../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'

export const GcpSaDependenciesTable: React.FunctionComponent<{
	gcpSaResourceData: ServerGcpServiceAccountResourceData[] | null
}> = ({ gcpSaResourceData }) => {
	const gridRef = useRef<AgGridReact<ServerGcpServiceAccountResourceData>>(null)
	const columnDefs = useMemo<ColDef<ServerGcpServiceAccountResourceData>[]>(() => {
		return [
			{ field: 'project_id', headerName: 'Project ID' },
			{ field: 'id', headerName: 'Resource ID' },
			{ field: 'name', headerName: 'Resource Name' },
			{
				field: 'state',
				headerName: 'State',
				flex: 0.75,
				cellRenderer: (params: { data?: ServerGcpServiceAccountResourceData; node: IRowNode }) => {
					return <div>{capitalizeFirstLetter(params?.data?.state)}</div>
				},
			},
			{ field: 'private_ip', headerName: 'Private IP' },
			{
				field: 'creation_time',
				headerName: 'Creation Time',
				cellRenderer: (params: { data?: ServerGcpServiceAccountResourceData; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-gray-400">{formatDate(params.data?.creation_time)}</div>
				},
			},
			{
				field: 'tags',
				headerName: 'Tags',
				valueFormatter: (params) => JSON.stringify(params.data?.tags),
				cellRenderer: (params: { data?: ServerGcpServiceAccountResourceData; node: IRowNode }) => {
					return <JsonViewer data={params.data?.tags ? removeNulls(params.data.tags) : {}} title="Tags" />
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<ServerGcpServiceAccountResourceData>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={gcpSaResourceData}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
