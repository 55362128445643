import { EnvironmentType } from '../../../schemas/envType'
import { IdentitySource } from '../../../schemas/identity'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { Tooltip } from 'antd'
import KubernetesIcon from '../../../assets/kubernetes_icon_20.svg?react'
import PostgresIcon from '../../../assets/postgres_icon_20.svg?react'

export const IdentitySourceWithEnvIcon = ({
	envType,
	origin,
	issueName,
}: {
	envType?: EnvironmentType | null
	origin?: IdentitySource | null
	issueName?: string | null
}) => {
	if (!envType) {
		return null
	}

	if (origin === IdentitySource.KUBERNETES_RESOURCE || issueName?.includes('EKS')) {
		return (
			<div className="flex -space-x-4 rtl:space-x-reverse">
				<IssueSourceIcon source={envType} />
				<Tooltip title="Kubernetes">
					<span>
						<KubernetesIcon />
					</span>
				</Tooltip>
			</div>
		)
	}

	if (origin === IdentitySource.POSTGRES_ROLE || origin === IdentitySource.DEMO_AZURE_POSTGRES_ROLE) {
		return (
			<div className="flex -space-x-4 rtl:space-x-reverse">
				<IssueSourceIcon source={envType} />
				<Tooltip title="Postgres">
					<span>
						<PostgresIcon />
					</span>
				</Tooltip>
			</div>
		)
	}

	return <IssueSourceIcon source={envType} />
}
