import { Tabs, type GetProps } from 'antd'
import { ElementType } from 'react'
import { NewFeatureAnnouncementButton } from './NewFeatureAnnouncementButton'

type PageHeaderProps = {
	Icon?: ElementType<{ className?: string }>
	children: React.ReactNode
	tabsProps?: GetProps<typeof Tabs>
}

export const PageHeader = ({ Icon, children, tabsProps }: PageHeaderProps) => {
	return (
		<div className="h-12 shrink-0 bg-surface-tertiary border border-border-secondary rounded-lg flex items-center px-4 relative">
			<div className="flex items-center gap-2 text-textIcon-primary">
				{Icon && <Icon className="text-base" />}
				<span className="text-base font-medium">{children}</span>
				{tabsProps && (
					<div className="absolute translate-x-[-50%] left-1/2">
						<Tabs tabBarStyle={{ marginBottom: 1 }} size="large" {...tabsProps} />
					</div>
				)}
			</div>
			<div className="ml-auto">
				<NewFeatureAnnouncementButton />
			</div>
		</div>
	)
}
