import React, { useMemo } from 'react'
import { IdentitySource, ServerIdentity } from '../../../schemas/identity.ts'
import { Tag, Tooltip } from 'antd'
import { AccountPriorityNameTag } from '../../../components/common/AccountPriorityNameTag.tsx'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import SuccessIcon from '../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../assets/warning_icon_20.svg?react'
import PostgresIcon from '../../../assets/postgres_icon_20.svg?react'
import { formatDate, formatRelativeDateText } from '../../../utils.ts'
import { AwsIamUserDetailAccessKey } from './components/aws/AwsIamUserDetailAccessKey.tsx'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { GcpSaAccessKey } from './components/gcp/GcpSaAccessKey.tsx'
import { normalizeCredentials } from '../../../schemas/identities/entraId/entraIdApplicationXc.ts'
import { EntraIdSpAuthenticationCredential } from './components/entraId/EntraIdSpAuthenticationCredential.tsx'
import { JsonViewer } from '../../../components/common/JsonViewer.tsx'
import { DemoAtlassianApiTokenProperty } from './components/demoAtlassian/DemoAtlassianApiTokenProperty.tsx'
import { DemoAtlassianOAuthTokenProperty } from './components/demoAtlassian/DemoAtlassianOAuthTokenProperty.tsx'
import { AccessKeyTooltip } from './components/kubernetes/AccessKeyTooltip.tsx'
import { IdentitySourceWithEnvIcon } from '../../Identities/IdentitiesTable/IdentitySourceWithEnvIcon.tsx'

type IdentityProperty = {
	title: string
	key: string
	shouldHide?: (identity: ServerIdentity) => boolean
	Component: React.ComponentType<{ identity: ServerIdentity }>
}

const AWS_COMMON_IDENTITY_PROPERTIES: IdentityProperty[] = [
	{
		title: 'ARN',
		key: 'arn',
		Component: ({ identity }) => identity.literal,
	},
	{
		title: 'Account',
		key: 'account',
		Component: ({ identity }) => (
			<Tooltip placement="topLeft" title={identity?.account_literal}>
				{identity?.account_literal_friendly_name ?? identity?.account_literal}
			</Tooltip>
		),
	},
	{
		title: 'Account Priority',
		key: 'account-priority',
		shouldHide: (identity) =>
			!(identity.aws_iam_user?.aws_account?.priority || identity.aws_iam_role?.aws_account?.priority),
		Component: ({ identity }) => (
			<AccountPriorityNameTag
				priority={identity.aws_iam_user?.aws_account?.priority || identity.aws_iam_role?.aws_account?.priority}
			/>
		),
	},
]

const POSTGRES_IDENTITY_COMMON_PROPERTIES: IdentityProperty[] = [
	{
		title: 'DB Name',
		key: 'db-name',
		Component: ({ identity }) => identity.postgres_role?.rds_instance?.db_name,
	},
	{
		title: 'Instance ID',
		key: 'instance-id',
		Component: ({ identity }) => identity.postgres_role?.rds_instance?.instance_id,
	},
	{
		title: 'DB Host',
		key: 'db-host',
		Component: ({ identity }) => identity.postgres_role?.rds_instance?.host,
	},
	{
		title: 'Region',
		key: 'region',
		Component: ({ identity }) => identity.postgres_role?.rds_instance?.region,
	},
	{
		title: 'Role Valid Until',
		key: 'role-valid-until',
		Component: ({ identity }) => formatDate(identity.postgres_role?.role_valid_until),
	},
	{
		title: 'Superuser',
		key: 'superuser',
		Component: ({ identity }) => (identity.postgres_role?.is_role_superuser ? 'Yes' : 'No'),
	},
	{
		title: 'Can Create Role',
		key: 'can-create-role',
		Component: ({ identity }) => (identity.postgres_role?.can_role_create_role ? 'Yes' : 'No'),
	},
	{
		title: 'Can Login',
		key: 'can-login',
		Component: ({ identity }) => (identity.postgres_role?.can_role_login ? 'Yes' : 'No'),
	},
	{
		title: 'Bypass RLS',
		key: 'by-pass-rls',
		Component: ({ identity }) => (identity.postgres_role?.can_role_bypass_rls ? 'Yes' : 'No'),
	},
	{
		title: 'Account',
		key: 'account',
		Component: ({ identity }) => (
			<div className="flex flex-row items-center space-x-2">
				<div className="flex -space-x-4 rtl:space-x-reverse">
					<IssueSourceIcon source={identity.env_type} />
					<Tooltip title="Postgres">
						<span>
							<PostgresIcon />
						</span>
					</Tooltip>
				</div>
				<Tooltip placement="topLeft" title={identity?.account_literal}>
					<div className="font-normal items-end truncate">
						{identity?.account_literal_friendly_name ?? identity?.account_literal}
					</div>
				</Tooltip>
			</div>
		),
	},
]

const IDENTITY_SOURCE_TO_PROPERTIES: Partial<Record<IdentitySource, IdentityProperty[]>> = {
	[IdentitySource.AWS_IAM_USER]: [
		...AWS_COMMON_IDENTITY_PROPERTIES,
		{
			title: 'MFA',
			key: 'mfa',
			shouldHide: (identity) => !identity.aws_iam_user?.password_info?.enabled,
			Component: ({ identity }) =>
				identity.aws_iam_user!.mfa_active ? (
					<CheckCircleOutlined className="text-status-active text-lg" />
				) : (
					<ExclamationCircleOutlined className="text-status-critical text-lg" />
				),
		},
		{
			title: 'Password Enabled',
			key: 'password-enabled',
			shouldHide: (identity) => !identity.aws_iam_user?.password_info?.enabled,
			Component: () => 'Yes',
		},
		{
			title: 'Password Last Changed',
			key: 'password-last-changed',
			shouldHide: (identity) => !identity.aws_iam_user?.password_info?.enabled,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.aws_iam_user!.password_info!.last_changed)}>
					{formatRelativeDateText(identity.aws_iam_user!.password_info!.last_changed, true)}
				</Tooltip>
			),
		},
		{
			title: 'Password Last Rotated',
			key: 'password-last-rotated',
			shouldHide: (identity) => !identity.aws_iam_user?.password_info?.enabled,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.aws_iam_user!.password_info!.last_rotated)}>
					{formatRelativeDateText(identity.aws_iam_user!.password_info!.last_rotated, true)}
				</Tooltip>
			),
		},
		{
			title: 'Password Next Rotation',
			key: 'password-next-rotation',
			shouldHide: (identity) => !identity.aws_iam_user?.password_info?.enabled,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.aws_iam_user!.password_info!.next_rotation)}>
					{formatRelativeDateText(identity.aws_iam_user!.password_info!.next_rotation, true)}
				</Tooltip>
			),
		},
		{
			title: 'Access Key',
			key: 'access-key-1',
			shouldHide: (identity) =>
				!(
					identity.aws_iam_user?.aws_iam_access_keys_xc?.[0] ||
					identity.aws_iam_user?.access_key_1?.last_rotated
				),
			Component: ({ identity }) => {
				const { setHighlightedNodes } = useHighlightedNodesContext()
				const accessKey = (identity.aws_iam_user!.aws_iam_access_keys_xc?.[0] ??
					identity.aws_iam_user!.access_key_1)!
				const onOpenChange = (visible: boolean) => {
					if (visible) {
						setHighlightedNodes([{ type: 'accessKey', id: accessKey.access_key_id! }])
					} else {
						setHighlightedNodes([])
					}
				}
				return <AwsIamUserDetailAccessKey accessKey={accessKey} onOpenChange={onOpenChange} />
			},
		},
		{
			title: 'Access Key',
			key: 'access-key-2',
			shouldHide: (identity) =>
				!(
					identity.aws_iam_user?.aws_iam_access_keys_xc?.[1] ||
					identity.aws_iam_user?.access_key_2?.last_rotated
				),
			Component: ({ identity }) => {
				const { setHighlightedNodes } = useHighlightedNodesContext()
				const accessKey = (identity.aws_iam_user!.aws_iam_access_keys_xc?.[1] ??
					identity.aws_iam_user!.access_key_2)!
				const onOpenChange = (visible: boolean) => {
					if (visible) {
						setHighlightedNodes([{ type: 'accessKey', id: accessKey.access_key_id! }])
					} else {
						setHighlightedNodes([])
					}
				}
				return <AwsIamUserDetailAccessKey accessKey={accessKey} onOpenChange={onOpenChange} />
			},
		},
	],
	[IdentitySource.AWS_IAM_ROLE]: [...AWS_COMMON_IDENTITY_PROPERTIES],
	[IdentitySource.AWS_KEY_PAIR]: [
		{
			title: 'Account',
			key: 'account',
			Component: ({ identity }) => (
				<Tooltip placement="topLeft" title={identity?.account_literal}>
					{identity?.account_literal_friendly_name ?? identity?.account_literal}
				</Tooltip>
			),
		},
		{
			title: 'Key Pair Name',
			key: 'key-pair-name',
			Component: ({ identity }) => identity.aws_key_pair?.name,
		},
		{
			title: 'Key Pair Id',
			key: 'key-pair-id',
			Component: ({ identity }) => identity?.literal,
		},
		{
			title: 'Key Pair Fingerprint',
			key: 'key-pair-fingerprint',
			Component: ({ identity }) => identity.aws_key_pair?.key_fingerprint,
		},
		{
			title: 'Account Priority',
			key: 'account-priority',
			shouldHide: (identity) => !identity.aws_key_pair?.aws_account?.priority,
			Component: ({ identity }) => (
				<AccountPriorityNameTag priority={identity.aws_key_pair?.aws_account?.priority} />
			),
		},
		{
			title: 'Region',
			key: 'region',
			Component: ({ identity }) => identity.aws_key_pair?.region,
		},
		{
			title: 'Created At',
			key: 'created-at',
			Component: ({ identity }) => formatDate(identity.created_at),
		},
	],
	[IdentitySource.GCP_SERVICE_ACCOUNT]: [
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.gcp_service_account?.email,
		},
		{
			title: 'Status',
			key: 'status',
			shouldHide: (identity) => identity?.gcp_service_account?.disabled === undefined,
			Component: ({ identity }) => (identity?.gcp_service_account?.disabled ? 'Disabled' : 'Active'),
		},
		{
			title: 'Project',
			key: 'project',
			Component: ({ identity }) => (
				<Tooltip placement="topLeft" title={identity?.account_literal}>
					{identity?.account_literal_friendly_name ?? identity?.account_literal}
				</Tooltip>
			),
		},
		{
			title: 'Project Priority',
			key: 'project-priority',
			Component: ({ identity }) => (
				<AccountPriorityNameTag priority={identity.gcp_service_account?.gcp_project?.priority} />
			),
		},
		...new Array(10).fill(null).map<IdentityProperty>((_, index) => ({
			title: `Key ${index + 1}`,
			key: `key-${index}`,
			shouldHide: (identity) => !identity.gcp_service_account?.service_account_keys_xc?.[index],
			Component: ({ identity }) =>
				identity.gcp_service_account?.service_account_keys_xc?.[index] ? (
					<GcpSaAccessKey accessKey={identity.gcp_service_account?.service_account_keys_xc[index]} />
				) : null,
		})),
	],
	[IdentitySource.GITHUB_APP_INSTALLATION]: [
		{
			title: 'ID',
			key: 'id',
			shouldHide: (identity) => !identity.github_app_installation?.app_id,
			Component: ({ identity }) => identity.github_app_installation!.app_id,
		},
		{
			title: 'Name',
			key: 'name',
			shouldHide: (identity) => !identity.github_app_installation?.name,
			Component: ({ identity }) => identity.github_app_installation!.name!,
		},
		{
			title: 'Owner',
			key: 'owner',
			shouldHide: (identity) => !identity.github_app_installation?.owner_name,
			Component: ({ identity }) => identity.github_app_installation!.owner_name!,
		},
		{
			title: 'Public',
			key: 'public',
			Component: ({ identity }) => {
				const isPublic = identity.github_app_installation?.is_public
				return isPublic === true ? 'True' : isPublic === false ? 'False' : 'Unknown'
			},
		},
	],
	[IdentitySource.GITHUB_USER]: [
		{
			title: 'Login',
			key: 'login',
			shouldHide: (identity) => !identity.github_user?.login,
			Component: ({ identity }) => identity.github_user!.login,
		},
		{
			title: 'Name',
			key: 'name',
			shouldHide: (identity) => !identity.github_user?.name,
			Component: ({ identity }) => identity.github_user!.name!,
		},
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.github_user?.email,
			Component: ({ identity }) => identity.github_user!.email!,
		},
		{
			title: 'Role',
			key: 'role',
			shouldHide: (identity) => !identity.github_user?.role,
			Component: ({ identity }) => <Tag>{identity.github_user!.role}</Tag>,
		},
		{
			title: 'Number of Tokens',
			key: 'number of tokens',
			Component: ({ identity }) => <div>{identity.github_user?.tokens?.length}</div>,
		},
	],
	[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
		{
			title: 'Service Principal ID',
			key: 'service-principal-id',
			Component: ({ identity }) => identity?.entra_id_service_principal?.service_principal_id,
		},
		{
			title: 'Service Principal Type',
			key: 'service-principal-type',
			Component: ({ identity }) => identity?.entra_id_service_principal?.service_principal_type,
		},

		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.env_type} marginBottom="-2px" marginLeft="-2px" size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Application ID',
			key: 'application-id',
			Component: ({ identity }) => identity?.entra_id_service_principal?.entra_id_application_xc?.app_id,
		},

		{
			title: 'Account Enabled',
			key: 'enabled',
			Component: ({ identity }) => (
				<span style={{ fontWeight: 'bold' }}>
					{identity.entra_id_service_principal?.account_enabled ? 'True' : 'False'}
				</span>
			),
		},

		{
			title: 'Last Sign In Date',
			key: 'last-sign-in-date',
			shouldHide: (identity) => !identity.entra_id_service_principal?.last_sign_in_date_time,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.entra_id_service_principal?.last_sign_in_date_time)}>
					{formatRelativeDateText(identity.entra_id_service_principal?.last_sign_in_date_time, true)}
				</Tooltip>
			),
		},
		...new Array(10).fill(null).map<IdentityProperty>((_, index) => ({
			title: `Authentication Credential ${index + 1}`,
			key: `credential-${index}`,
			shouldHide: (identity) => {
				const application = identity.entra_id_service_principal?.entra_id_application_xc
				const keyCredentials = application?.key_credentials || []
				const passwordCredentials = application?.password_credentials || []

				// Normalize all credentials once
				const normalizedCredentials = normalizeCredentials(keyCredentials, passwordCredentials)

				return !normalizedCredentials[index]
			},
			Component: ({ identity }) => {
				const application = identity.entra_id_service_principal?.entra_id_application_xc
				const keyCredentials = application?.key_credentials || []
				const passwordCredentials = application?.password_credentials || []

				// Normalize all credentials once
				const normalizedCredentials = useMemo(
					() => normalizeCredentials(keyCredentials, passwordCredentials),
					[keyCredentials, passwordCredentials],
				)

				const credential = normalizedCredentials[index]

				if (credential) {
					return <EntraIdSpAuthenticationCredential credential={credential} />
				} else {
					return null
				}
			},
		})),
	],
	[IdentitySource.ENTRA_ID_USER]: [
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.entra_id_user?.mail,
			Component: ({ identity }) => identity.entra_id_user?.mail,
		},
		{
			title: 'Entra User Id',
			key: 'entra-user-id',
			Component: ({ identity }) => identity.entra_id_user?.entra_user_id,
		},

		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity.env_type} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Name',
			key: 'name',
			shouldHide: (identity) => !identity.entra_id_user?.given_name,
			Component: ({ identity }) => identity.entra_id_user?.given_name,
		},
		{
			title: 'User Principal Name',
			key: 'user-principal-name',
			Component: ({ identity }) => identity.entra_id_user?.user_principal_name,
		},
		{
			title: 'Job Title',
			key: 'job-title',
			shouldHide: (identity) => !identity.entra_id_user?.job_title,
			Component: ({ identity }) => identity.entra_id_user?.job_title,
		},

		{
			title: 'Last Login',
			key: 'last-login',
			shouldHide: (identity) => !identity.entra_id_user?.last_activity,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.entra_id_user?.last_activity)}>
					{formatRelativeDateText(identity.entra_id_user?.last_activity, true)}
				</Tooltip>
			),
		},
		{
			title: 'Last Password Change',
			key: 'last-password-change',
			shouldHide: (identity) => !identity.entra_id_user?.password_last_changed,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.entra_id_user?.password_last_changed)}>
					{formatRelativeDateText(identity.entra_id_user?.password_last_changed, true)}
				</Tooltip>
			),
		},
		{
			title: 'Account Status',
			key: 'account-status',
			shouldHide: (identity) => identity.entra_id_user?.account_enabled === null,
			Component: ({ identity }) => (
				<Tag>
					<div className="flex items-center">
						<div
							className={`${
								identity.entra_id_user?.account_enabled ? 'text-status-active' : 'text-status-neutral'
							} text font-medium`}
						>
							{identity.entra_id_user?.account_enabled ? 'Enabled' : 'Disabled'}
						</div>
					</div>
				</Tag>
			),
		},
	],
	[IdentitySource.DATABRICKS_USER]: [
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.databricks_user?.email,
		},
		{
			title: 'Has Personal Access Token',
			key: 'hasPersonalAccessToken',
			Component: ({ identity }) => {
				const hasPat = !!identity.databricks_user?.personal_access_tokens?.length
				return <Tag>{hasPat ? 'Yes' : 'No'}</Tag>
			},
		},
	],
	[IdentitySource.DATABRICKS_SERVICE_PRINCIPAL]: [
		{
			title: 'ID',
			key: 'id',
			Component: ({ identity }) => identity.databricks_service_principal?.sp_id,
		},
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.databricks_service_principal?.sp_name,
		},
		{
			title: 'Has OAuth tokens',
			key: 'hasOAuthTokens',
			Component: ({ identity }) => {
				const hasTokens = !!identity.databricks_service_principal?.oauth_tokens?.length
				return <Tag>{hasTokens ? 'Yes' : 'No'}</Tag>
			},
		},
	],
	[IdentitySource.AZURE_KV_SECRET]: [
		{
			title: 'Secret Name',
			key: 'secret-name',
			Component: ({ identity }) => identity?.azure_kv_secret?.name,
		},
		{
			title: 'Secret ID',
			key: 'secret-id',
			Component: ({ identity }) => identity?.azure_kv_secret?.secret_id,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.env_type} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Vault Name',
			key: 'vault-name',
			Component: ({ identity }) => identity?.azure_kv_secret?.vault_name,
		},
		{
			title: 'Vault ID',
			key: 'vault-id',
			Component: ({ identity }) => identity?.azure_kv_secret?.vault_id,
		},
		{
			title: 'Subscription ID',
			key: 'subscription-id',
			Component: ({ identity }) => identity?.azure_kv_secret?.subscription_id,
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (
				<Tag color={identity.azure_kv_secret?.is_enabled ? 'green' : 'red'}>
					{identity.azure_kv_secret?.is_enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'Managed',
			key: 'managed',
			Component: ({ identity }) => (
				<Tag color={identity.azure_kv_secret?.is_managed ? 'blue' : 'default'}>
					{identity.azure_kv_secret?.is_managed ? 'Managed' : 'Not Managed'}
				</Tag>
			),
		},

		{
			title: 'Start Date',
			key: 'start-date',
			shouldHide: (identity) => !identity.azure_kv_secret?.start_date,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azure_kv_secret?.start_date)}>
					{formatRelativeDateText(identity.azure_kv_secret?.start_date, true)}
				</Tooltip>
			),
		},
		{
			title: 'Expiration Date',
			key: 'expiration-date',
			shouldHide: (identity) => !identity.azure_kv_secret?.expiration_date,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azure_kv_secret?.expiration_date)}>
					{formatRelativeDateText(identity.azure_kv_secret?.expiration_date, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.AZURE_KV_KEY]: [
		{
			title: 'Key Name',
			key: 'key-name',
			Component: ({ identity }) => identity?.azure_kv_key?.name,
		},
		{
			title: 'Key ID',
			key: 'key-id',
			Component: ({ identity }) => identity?.azure_kv_key?.key_id,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.env_type} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Vault Name',
			key: 'vault-name',
			Component: ({ identity }) => identity?.azure_kv_key?.vault_name,
		},
		{
			title: 'Vault ID',
			key: 'vault-id',
			Component: ({ identity }) => identity?.azure_kv_key?.vault_id,
		},
		{
			title: 'Subscription ID',
			key: 'subscription-id',
			Component: ({ identity }) => identity?.azure_kv_key?.subscription_id,
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (
				<Tag color={identity.azure_kv_key?.is_enabled ? 'green' : 'red'}>
					{identity.azure_kv_key?.is_enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'Managed',
			key: 'managed',
			Component: ({ identity }) => (
				<Tag color={identity.azure_kv_key?.is_managed ? 'blue' : 'default'}>
					{identity.azure_kv_key?.is_managed ? 'Managed' : 'Not Managed'}
				</Tag>
			),
		},

		{
			title: 'Start Date',
			key: 'start-date',
			shouldHide: (identity) => !identity.azure_kv_key?.start_date,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azure_kv_key?.start_date)}>
					{formatRelativeDateText(identity.azure_kv_key?.start_date, true)}
				</Tooltip>
			),
		},
		{
			title: 'Expiration Date',
			key: 'expiration-date',
			shouldHide: (identity) => !identity.azure_kv_key?.expiration_date,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azure_kv_key?.expiration_date)}>
					{formatRelativeDateText(identity.azure_kv_key?.expiration_date, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.AZURE_KV_CERTIFICATE]: [
		{
			title: 'Certificate Name',
			key: 'certificate-name',
			Component: ({ identity }) => identity?.azure_kv_certificate?.name,
		},
		{
			title: 'Certificate ID',
			key: 'certificate-id',
			Component: ({ identity }) => identity?.azure_kv_certificate?.certificate_id,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.env_type} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Vault Name',
			key: 'vault-name',
			Component: ({ identity }) => identity?.azure_kv_certificate?.vault_name,
		},
		{
			title: 'Vault ID',
			key: 'vault-id',
			Component: ({ identity }) => identity?.azure_kv_certificate?.vault_id,
		},
		{
			title: 'Subscription ID',
			key: 'subscription-id',
			Component: ({ identity }) => identity?.azure_kv_certificate?.subscription_id,
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (
				<Tag color={identity.azure_kv_certificate?.is_enabled ? 'green' : 'red'}>
					{identity.azure_kv_certificate?.is_enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'Created At',
			key: 'created-at',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azure_kv_certificate?.created_at)}>
					{formatRelativeDateText(identity.azure_kv_certificate?.created_at, true)}
				</Tooltip>
			),
		},
		{
			title: 'Updated At',
			key: 'updated-at',
			shouldHide: (identity) => !identity.azure_kv_certificate?.updated_at,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azure_kv_certificate?.updated_at)}>
					{formatRelativeDateText(identity.azure_kv_certificate?.updated_at, true)}
				</Tooltip>
			),
		},
		{
			title: 'Start Date',
			key: 'start-date',
			shouldHide: (identity) => !identity.azure_kv_certificate?.start_date,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azure_kv_certificate?.start_date)}>
					{formatRelativeDateText(identity.azure_kv_certificate?.start_date, true)}
				</Tooltip>
			),
		},
		{
			title: 'Expiration Date',
			key: 'expiration-date',
			shouldHide: (identity) => !identity.azure_kv_certificate?.expiration_date,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.azure_kv_certificate?.expiration_date)}>
					{formatRelativeDateText(identity.azure_kv_certificate?.expiration_date, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.OKTA]: [
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.okta_user?.email,
			Component: ({ identity }) => identity.okta_user?.email,
		},
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.okta_user?.name,
		},

		{
			title: 'Activated At',
			key: 'activated-at',
			Component: ({ identity }) => formatDate(identity.okta_user?.activated),
		},
		{
			title: 'Password Changed',
			key: 'password-changed',
			shouldHide: (identity) => !identity.okta_user?.password_changed,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.okta_user?.password_changed)}>
					{formatRelativeDateText(identity.okta_user?.password_changed, true)}
				</Tooltip>
			),
		},
		{
			title: 'Status',
			key: 'status',
			shouldHide: (identity) => !identity.okta_user?.user_status,
			Component: ({ identity }) => {
				const status = identity.okta_user?.user_status
				return <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>{status}</Tag>
			},
		},
	],
	[IdentitySource.SNOWFLAKE_USER]: [
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.snowflake_user?.name,
		},
		{
			title: 'Snowflake User ID',
			key: 'snowflake-user-id',
			Component: ({ identity }) => identity.snowflake_user?.user_id,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.env_type} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.snowflake_user?.email,
		},
		{
			title: 'Deleted On',
			key: 'deleted-on',
			shouldHide: (identity) => !identity.snowflake_user?.deleted_on,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.snowflake_user?.deleted_on)}>
					{formatRelativeDateText(identity.snowflake_user?.deleted_on, true)}
				</Tooltip>
			),
		},
		{
			title: 'Disabled',
			key: 'disabled',
			Component: ({ identity }) => (identity.snowflake_user?.disabled ? 'True' : 'False'),
		},
		{
			title: 'Password Enabled',
			key: 'password-enabled',
			Component: ({ identity }) => (identity.snowflake_user?.has_password ? 'True' : 'False'),
		},
		{
			title: 'Last Password changed',
			key: 'last-password-changed',
			shouldHide: (identity) => !identity.snowflake_user?.has_password,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.snowflake_user?.password_last_set_time)}>
					{formatRelativeDateText(identity.snowflake_user?.password_last_set_time, true)}
				</Tooltip>
			),
		},
		{
			title: 'MFA',
			key: 'mfa',
			shouldHide: (identity) => !identity.snowflake_user?.has_password,
			Component: ({ identity }) =>
				identity.snowflake_user?.ext_authn_duo ? (
					<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				) : (
					<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				),
		},
		{
			title: 'RSA Public Key',
			key: 'rsa-public-key',
			Component: ({ identity }) => (identity.snowflake_user?.has_rsa_public_key ? 'True' : 'False'),
		},
	],
	[IdentitySource.JUMPCLOUD_USER]: [
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.jumpcloud_user?.email,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity?.env_type} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.jumpcloud_user?.name,
		},
		{
			title: 'MFA',
			key: 'mfaActive',
			Component: ({ identity }) =>
				identity.jumpcloud_user?.mfa_active ? (
					<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				) : (
					<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				),
		},
		{
			title: 'Password Last Rotated',
			key: 'passwordLastRotated',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.jumpcloud_user?.password_last_rotated)}>
					{formatRelativeDateText(identity.jumpcloud_user?.password_last_rotated, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.SALESFORCE_USER]: [
		{
			title: 'User ID',
			key: 'user-id',
			Component: ({ identity }) => identity.salesforce_user?.user_id,
		},
		{
			title: 'Username',
			key: 'username',
			Component: ({ identity }) => identity.salesforce_user?.username,
		},
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.salesforce_user?.email,
		},
		{
			title: 'Name',
			key: 'name',
			shouldHide: (identity) => !identity.salesforce_user?.first_name && !identity.salesforce_user?.last_name,
			Component: ({ identity }) =>
				`${identity.salesforce_user?.first_name || ''} ${identity.salesforce_user?.last_name || ''}`.trim(),
		},
		{
			title: 'Last Login',
			key: 'last-login',
			shouldHide: (identity) => !identity.salesforce_user?.last_login_date,
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.salesforce_user?.last_login_date)}>
					{formatRelativeDateText(identity.salesforce_user?.last_login_date, true)}
				</Tooltip>
			),
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (
				<Tag color={identity.salesforce_user?.is_active ? 'green' : 'red'}>
					{identity.salesforce_user?.is_active ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'User Type',
			key: 'user-type',
			Component: ({ identity }) => identity.salesforce_user?.user_type,
		},
		{
			title: 'Environment',
			key: 'environment',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={identity.env_type} marginBottom="-2px" marginLeft="-2px" size={14} />
					<a
						href={identity.account_literal}
						target="_blank"
						rel="noreferrer"
						className="text-textIcon-link hover:text-textIcon-linkHover"
					>
						{identity.account_literal}
					</a>
				</div>
			),
		},
	],
	[IdentitySource.SALESFORCE_CONNECTED_APPLICATION]: [
		{
			title: 'Connected Application ID',
			key: 'connected-application-id',
			Component: ({ identity }) => identity.salesforce_connected_application?.connected_application_id,
		},
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.salesforce_connected_application?.name,
		},
		{
			title: 'Contact Email',
			key: 'contact-email',
			shouldHide: (identity) => !identity.salesforce_connected_application?.app_metadata?.contact_email,
			Component: ({ identity }) => identity.salesforce_connected_application?.app_metadata?.contact_email,
		},
		{
			title: 'Allow Admin Approved Users Only',
			key: 'allow-admin-approved-users-only',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforce_connected_application?.options_allow_admin_approved_users_only
							? 'green'
							: 'red'
					}
				>
					{identity.salesforce_connected_application?.options_allow_admin_approved_users_only ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Refresh Token Validity Metric',
			key: 'refresh-token-validity-metric',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforce_connected_application?.options_refresh_token_validity_metric
							? 'green'
							: 'red'
					}
				>
					{identity.salesforce_connected_application?.options_refresh_token_validity_metric ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Has Session Level Policy',
			key: 'has-session-level-policy',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforce_connected_application?.options_has_session_level_policy ? 'green' : 'red'
					}
				>
					{identity.salesforce_connected_application?.options_has_session_level_policy ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Is Internal',
			key: 'is-internal',
			Component: ({ identity }) => (
				<Tag color={identity.salesforce_connected_application?.options_is_internal ? 'green' : 'red'}>
					{identity.salesforce_connected_application?.options_is_internal ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Full Content Push Notifications',
			key: 'full-content-push-notifications',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforce_connected_application?.options_full_content_push_notifications
							? 'green'
							: 'red'
					}
				>
					{identity.salesforce_connected_application?.options_full_content_push_notifications ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Code Credential Guest Enabled',
			key: 'code-credential-guest-enabled',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforce_connected_application?.options_code_credential_guest_enabled
							? 'green'
							: 'red'
					}
				>
					{identity.salesforce_connected_application?.options_code_credential_guest_enabled ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'App Issue JWT Token Enabled',
			key: 'app-issue-jwt-token-enabled',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforce_connected_application?.options_app_issue_jwt_token_enabled ? 'green' : 'red'
					}
				>
					{identity.salesforce_connected_application?.options_app_issue_jwt_token_enabled ? 'Yes' : 'No'}
				</Tag>
			),
		},
		{
			title: 'Token Exchange Manage Bit Enabled',
			key: 'token-exchange-manage-bit-enabled',
			Component: ({ identity }) => (
				<Tag
					color={
						identity.salesforce_connected_application?.options_token_exchange_manage_bit_enabled
							? 'green'
							: 'red'
					}
				>
					{identity.salesforce_connected_application?.options_token_exchange_manage_bit_enabled
						? 'Yes'
						: 'No'}
				</Tag>
			),
		},
		{
			title: 'Start URL',
			key: 'start-url',
			shouldHide: (identity) => !identity.salesforce_connected_application?.start_url,
			Component: ({ identity }) => identity.salesforce_connected_application?.start_url,
		},
		{
			title: 'Refresh Token Validity Period',
			key: 'refresh-token-validity-period',
			shouldHide: (identity) => identity.salesforce_connected_application?.refresh_token_validity_period === null,
			Component: ({ identity }) =>
				`${identity.salesforce_connected_application?.refresh_token_validity_period} days`,
		},
		{
			title: 'Description',
			key: 'description',
			shouldHide: (identity) => !identity.salesforce_connected_application?.app_metadata?.description,
			Component: ({ identity }) => identity.salesforce_connected_application?.app_metadata?.description,
		},
	],
	[IdentitySource.GOOGLE_WORKSPACE]: [
		{
			title: 'Primary Email',
			key: 'primary-email',
			Component: ({ identity }) => identity.google_workspace_user?.primary_email,
		},
		{
			title: 'Recovery Email',
			key: 'recovery-email',
			Component: ({ identity }) => identity.google_workspace_user?.recovery_email ?? 'N/A',
		},
		{
			title: 'Status',
			key: 'status',
			Component: ({ identity }) => (identity.google_workspace_user?.suspended ? 'Suspended' : 'Active'),
		},
		{
			title: 'MFA',
			key: 'mfa',
			Component: ({ identity }) =>
				identity.google_workspace_user?.mfa_enabled ? (
					<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				) : (
					<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
				),
		},
		{
			title: 'Last Activity',
			key: 'last-activity',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.google_workspace_user?.last_activity)}>
					{formatRelativeDateText(identity.google_workspace_user?.last_activity, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.POSTGRES_ROLE]: POSTGRES_IDENTITY_COMMON_PROPERTIES,
	[IdentitySource.DEMO_AZURE_POSTGRES_ROLE]: POSTGRES_IDENTITY_COMMON_PROPERTIES,
	[IdentitySource.AZURE_DEVOPS_USER]: [
		{
			title: 'Display Name',
			key: 'display-name',
			Component: ({ identity }) => identity.azure_devops_user?.display_name,
		},
		{
			title: 'Principal Name',
			key: 'principal-name',
			Component: ({ identity }) => identity.azure_devops_user?.principal_name,
		},
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.azure_devops_user?.mail_address,
			Component: ({ identity }) => identity.azure_devops_user?.mail_address,
		},
		{
			title: 'Directory Alias',
			key: 'directory-alias',
			shouldHide: (identity) => !identity.azure_devops_user?.directory_alias,
			Component: ({ identity }) => identity.azure_devops_user?.directory_alias,
		},
		{
			title: 'Domain',
			key: 'domain',
			Component: ({ identity }) => identity.azure_devops_user?.domain,
		},
		{
			title: 'Origin',
			key: 'origin',
			Component: ({ identity }) => identity.azure_devops_user?.origin,
		},
		{
			title: 'Origin ID',
			key: 'origin-id',
			Component: ({ identity }) => identity.azure_devops_user?.origin_id,
		},
		{
			title: 'Descriptor',
			key: 'descriptor',
			Component: ({ identity }) => identity.azure_devops_user?.descriptor,
		},
	],
	[IdentitySource.AZURE_DEVOPS_SERVICE_PRINCIPAL]: [
		{
			title: 'Display Name',
			key: 'display-name',
			Component: ({ identity }) => identity.azure_devops_service_principal?.display_name,
		},
		{
			title: 'Principal Name',
			key: 'principal-name',
			Component: ({ identity }) => identity.azure_devops_service_principal?.principal_name,
		},
		{
			title: 'Email',
			key: 'email',
			shouldHide: (identity) => !identity.azure_devops_service_principal?.mail_address,
			Component: ({ identity }) => identity.azure_devops_service_principal?.mail_address,
		},
		{
			title: 'Directory Alias',
			key: 'directory-alias',
			shouldHide: (identity) => !identity.azure_devops_service_principal?.directory_alias,
			Component: ({ identity }) => identity.azure_devops_service_principal?.directory_alias,
		},
		{
			title: 'Domain',
			key: 'domain',
			Component: ({ identity }) => identity.azure_devops_service_principal?.domain,
		},
		{
			title: 'Origin',
			key: 'origin',
			Component: ({ identity }) => identity.azure_devops_service_principal?.origin,
		},
		{
			title: 'Origin ID',
			key: 'origin-id',
			Component: ({ identity }) => identity.azure_devops_service_principal?.origin_id,
		},
		{
			title: 'Application ID',
			key: 'origin-id',
			Component: ({ identity }) => identity.azure_devops_service_principal?.application_id,
		},
		{
			title: 'meta type',
			key: 'origin-id',
			Component: ({ identity }) => identity.azure_devops_service_principal?.meta_type,
		},
		{
			title: 'Descriptor',
			key: 'descriptor',
			Component: ({ identity }) => identity.azure_devops_service_principal?.descriptor,
		},
	],
	[IdentitySource.ACTIVE_DIRECTORY_USER]: [
		{
			title: 'SAM Account Name',
			key: 'sam-account-name',
			Component: ({ identity }) => identity.active_directory_user?.sam_account_name,
		},
		{
			title: 'Description',
			key: 'description',
			shouldHide: (identity) => !identity.active_directory_user?.description,
			Component: ({ identity }) => identity.active_directory_user?.description,
		},
		{
			title: 'Domain',
			key: 'domain',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source="ACTIVE_DIRECTORY" marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<div className="font-normal items-end truncate">{identity.active_directory_user?.domain}</div>
				</div>
			),
		},
		{
			title: 'SID',
			key: 'sid',
			Component: ({ identity }) => (
				<Tooltip placement="topLeft" title={identity.active_directory_user?.object_sid}>
					<span className="w-full truncate">{identity.active_directory_user?.object_sid}</span>
				</Tooltip>
			),
		},
		{
			title: 'Distinguished Name',
			key: 'distinguished-name',
			Component: ({ identity }) => (
				<Tooltip placement="topLeft" title={identity.active_directory_user?.distinguished_name}>
					<span className="w-full truncate">{identity.active_directory_user?.distinguished_name}</span>
				</Tooltip>
			),
		},
		{
			title: 'Status',
			key: 'enabled',
			Component: ({ identity }) => (
				<Tag color={identity.active_directory_user?.enabled ? 'green' : 'red'}>
					{identity.active_directory_user?.enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'Last Logon',
			key: 'last-logon',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.active_directory_user?.last_logon)}>
					{formatRelativeDateText(identity.active_directory_user?.last_logon, true)}
				</Tooltip>
			),
		},
		{
			title: 'Password Last Set',
			key: 'pwd-last-set',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.active_directory_user?.pwd_last_set)}>
					{formatRelativeDateText(identity.active_directory_user?.pwd_last_set, true)}
				</Tooltip>
			),
		},
		{
			title: 'Created',
			key: 'when-created',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.active_directory_user?.when_created)}>
					{formatRelativeDateText(identity.active_directory_user?.when_created, true)}
				</Tooltip>
			),
		},
	],

	[IdentitySource.AWS_EC2_INSTANCE]: [
		{
			title: 'Instance ID',
			key: 'instance-id',
			Component: ({ identity }) => identity.aws_ec2_instance?.instance_id,
		},
		{
			title: 'Instance Type',
			key: 'instance-type',
			Component: ({ identity }) => identity.aws_ec2_instance?.instance_type,
		},
		{
			title: 'Region',
			key: 'region',
			Component: ({ identity }) => identity.aws_ec2_instance?.region,
		},
		{
			title: 'State',
			key: 'state',
			Component: ({ identity }) => identity.aws_ec2_instance?.state,
		},
		{
			title: 'Launch Time',
			key: 'launch-time',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.aws_ec2_instance?.created_at)}>
					{formatRelativeDateText(identity.aws_ec2_instance?.updated_at, true)}
				</Tooltip>
			),
		},
		{
			title: 'Account',
			key: 'account',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-5 ">
					<div className="flex -space-x-4 rtl:space-x-reverse">
						<IssueSourceIcon source={identity.env_type} />
						<Tooltip title="AWS">
							<span>{/*<AwsIcon />*/}</span>
						</Tooltip>
					</div>
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Key Pair',
			key: 'Key Pair',
			Component: ({ identity }) =>
				identity.aws_ec2_instance?.key_pair ? (
					<JsonViewer data={identity.aws_ec2_instance.key_pair} title="View Key Pairs" />
				) : (
					<div>No Key Pair Available</div>
				),
		},
	],

	[IdentitySource.ACTIVE_DIRECTORY_COMPUTER]: [
		{
			title: 'SAM Account Name',
			key: 'sam-account-name',
			Component: ({ identity }) => identity.active_directory_computer?.sam_account_name,
		},
		{
			title: 'Description',
			key: 'description',
			shouldHide: (identity) => !identity.active_directory_computer?.description,
			Component: ({ identity }) => identity.active_directory_computer?.description,
		},
		{
			title: 'Operating System',
			key: 'operating-system',
			shouldHide: (identity) => !identity.active_directory_computer?.operating_system,
			Component: ({ identity }) => identity.active_directory_computer?.operating_system,
		},
		{
			title: 'Domain',
			key: 'domain',
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source="ACTIVE_DIRECTORY" marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<div className="font-normal items-end truncate">{identity.active_directory_computer?.domain}</div>
				</div>
			),
		},
		{
			title: 'SID',
			key: 'sid',
			Component: ({ identity }) => (
				<Tooltip placement="topLeft" title={identity.active_directory_computer?.sid}>
					<span className="w-full truncate">{identity.active_directory_computer?.sid}</span>
				</Tooltip>
			),
		},
		{
			title: 'Distinguished Name',
			key: 'distinguished-name',
			Component: ({ identity }) => (
				<Tooltip placement="topLeft" title={identity.active_directory_computer?.distinguished_name}>
					<span className="w-full truncate">{identity.active_directory_computer?.distinguished_name}</span>
				</Tooltip>
			),
		},
		{
			title: 'Status',
			key: 'enabled',
			Component: ({ identity }) => (
				<Tag color={identity.active_directory_computer?.enabled ? 'green' : 'red'}>
					{identity.active_directory_computer?.enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
		{
			title: 'Last Logon',
			key: 'last-logon',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.active_directory_computer?.last_logon)}>
					{formatRelativeDateText(identity.active_directory_computer?.last_logon, true)}
				</Tooltip>
			),
		},
		{
			title: 'Password Last Set',
			key: 'pwd-last-set',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.active_directory_computer?.pwd_last_set)}>
					{formatRelativeDateText(identity.active_directory_computer?.pwd_last_set, true)}
				</Tooltip>
			),
		},
		{
			title: 'Created',
			key: 'when-created',
			Component: ({ identity }) => (
				<Tooltip title={formatDate(identity.active_directory_computer?.when_created)}>
					{formatRelativeDateText(identity.active_directory_computer?.when_created, true)}
				</Tooltip>
			),
		},
	],
	[IdentitySource.AZURE_DEVOPS_SECRET]: [
		{
			title: 'Secret Hash',
			key: 'secret-hash',
			Component: ({ identity }) => identity.azure_devops_secret?.key_id,
		},
		{
			title: 'Secret name',
			key: 'secret-name',
			Component: ({ identity }) => identity.azure_devops_secret?.name ?? 'Unknown',
		},
		{
			title: 'Secret source',
			key: 'secret-source',
			Component: ({ identity }) => identity.azure_devops_secret?.source ?? 'Unknown',
		},
		{
			title: 'Locations',
			key: 'locations',
			Component: ({ identity }) =>
				identity.azure_devops_secret?.locations ? (
					<JsonViewer data={identity.azure_devops_secret.locations} title="View Secret Locations" />
				) : (
					<div>No Secret Locations Available</div>
				),
		},
	],
	[IdentitySource.DEMO_ATLASSIAN_USER]: [
		{
			title: 'Name',
			key: 'name',
			Component: ({ identity }) => identity.demo_atlassian_user!.name,
		},
		{
			title: 'Email',
			key: 'email',
			Component: ({ identity }) => identity.demo_atlassian_user!.email,
			shouldHide: (identity) => !identity.demo_atlassian_user!.email,
		},
		...new Array(10).fill(null).map<IdentityProperty>((_, index) => ({
			title: `API Token ${index + 1}`,
			key: `api-token-${index}`,
			shouldHide: (identity) => !identity.demo_atlassian_user?.api_tokens?.[index],
			Component: ({ identity }) => (
				<DemoAtlassianApiTokenProperty apiToken={identity.demo_atlassian_user!.api_tokens![index]} />
			),
		})),
		...new Array(10).fill(null).map<IdentityProperty>((_, index) => ({
			title: `OAuth Token ${index + 1}`,
			key: `oauth-token-${index}`,
			shouldHide: (identity) => !identity.demo_atlassian_user?.oauth_tokens?.[index],
			Component: ({ identity }) => (
				<DemoAtlassianOAuthTokenProperty oAuthToken={identity.demo_atlassian_user!.oauth_tokens![index]} />
			),
		})),
		...new Array(10).fill(null).map<IdentityProperty>((_, index) => ({
			title: `Org Admin API Key ${index + 1}`,
			key: `admin-api-key-${index}`,
			shouldHide: (identity) => !identity.demo_atlassian_user?.admin_api_keys?.[index],
			Component: ({ identity }) => (
				<DemoAtlassianApiTokenProperty apiToken={identity.demo_atlassian_user!.admin_api_keys![index]} />
			),
		})),
		{
			title: 'Has Console Access',
			key: 'has-console-access',
			Component: ({ identity }) => (identity.demo_atlassian_user!.has_console_access ? 'Yes' : 'No'),
		},
		{
			title: 'Is Federated',
			key: 'is-federated',
			Component: ({ identity }) => (identity.demo_atlassian_user!.okta_user ? 'Yes' : 'No'),
		},
	],
	[IdentitySource.KUBERNETES_RESOURCE]: [
		{
			title: 'ARN',
			key: 'arn',
			shouldHide: (identity) => !identity.kubernetes_resource?.aws_iam_users_xc?.[0]?.Arn,
			Component: ({ identity }) => identity.kubernetes_resource?.aws_iam_users_xc?.[0]?.Arn,
		},
		{
			title: 'Access Key',
			key: 'access-key',
			shouldHide: (identity) => !identity.kubernetes_resource?.access_keys?.access_key_id,
			Component: ({ identity }) => {
				const { setHighlightedNodes } = useHighlightedNodesContext()
				const accessKey = identity.kubernetes_resource?.access_keys
				const onOpenChange = (visible: boolean) => {
					if (visible) {
						setHighlightedNodes([{ type: 'accessKey', id: accessKey?.access_key_id || '' }])
					} else {
						setHighlightedNodes([])
					}
				}
				return <AccessKeyTooltip accessKey={accessKey} onOpenChange={onOpenChange} />
			},
		},
		{
			title: 'Cluster',
			key: 'cluster',
			shouldHide: (identity) => !identity.kubernetes_resource?.cluster_literal,
			Component: ({ identity }) => identity?.kubernetes_resource?.cluster_literal,
		},
		{
			title: 'Resource UID',
			key: 'resource-uid',
			shouldHide: (identity) => !identity.kubernetes_resource?.resource_uid,
			Component: ({ identity }) => identity?.kubernetes_resource?.resource_uid,
		},
		{
			title: 'Resource Name',
			key: 'resource-name',
			shouldHide: (identity) => !identity.kubernetes_resource?.name,
			Component: ({ identity }) => identity?.kubernetes_resource?.name,
		},
		{
			title: 'Kind',
			key: 'kind',
			shouldHide: (identity) => !identity.kubernetes_resource?.kind,
			Component: ({ identity }) => identity?.kubernetes_resource?.kind,
		},
		{
			title: 'Namespace',
			key: 'namespace',
			shouldHide: (identity) => !identity.kubernetes_resource?.resource_metadata?.namespace,
			Component: ({ identity }) => identity?.kubernetes_resource?.resource_metadata?.namespace as string,
		},
		{
			title: 'Environment',
			key: 'environment',
			shouldHide: (identity) => !identity?.env_type,
			Component: ({ identity }) => (
				<div className="flex flex-row items-center space-x-2">
					<IdentitySourceWithEnvIcon envType={identity?.env_type} origin={identity?.source} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Data',
			key: 'data',
			shouldHide: (identity) => !identity.kubernetes_resource?.data,
			Component: ({ identity }) =>
				identity.kubernetes_resource?.data ? (
					<JsonViewer data={identity.kubernetes_resource.data} title="Open JSON" />
				) : null,
		},
		{
			title: 'Metadata',
			key: 'metadata',
			shouldHide: (identity) => !identity.kubernetes_resource?.resource_metadata,
			Component: ({ identity }) =>
				identity.kubernetes_resource?.resource_metadata ? (
					<JsonViewer data={identity.kubernetes_resource.resource_metadata} title="Open JSON" />
				) : null,
		},
		{
			title: 'Spec',
			key: 'spec',
			shouldHide: (identity) => !identity.kubernetes_resource?.spec,
			Component: ({ identity }) =>
				identity.kubernetes_resource?.spec ? (
					<JsonViewer data={identity.kubernetes_resource.spec} title="Open JSON" />
				) : null,
		},
		{
			title: 'Rules',
			key: 'rules',
			shouldHide: (identity) => !identity.kubernetes_resource?.rules,
			Component: ({ identity }) =>
				identity.kubernetes_resource?.rules ? (
					<JsonViewer data={identity.kubernetes_resource.rules} title="Open JSON" />
				) : null,
		},
	],
}

export const SidePanelProperties = ({ identity }: { identity: ServerIdentity }) => {
	if (!Object.keys(IDENTITY_SOURCE_TO_PROPERTIES).includes(identity.source)) {
		return 'No properties data'
	}

	return (
		<div className="bg-surface-primary rounded p-6 flex gap-8 flex-wrap">
			{IDENTITY_SOURCE_TO_PROPERTIES[identity.source]!.map(({ key, title, Component, shouldHide }) => {
				if (shouldHide && shouldHide(identity)) {
					return null
				}

				return (
					<div className="flex flex-col items-start gap-1 min-w-0" key={key}>
						<span className="text-textIcon-secondary">{title}</span>
						<Component identity={identity} />
					</div>
				)
			})}
		</div>
	)
}
