import React from 'react'
import { IdentityTypeIconMap, IdentityTypeMap, isIdentityType } from '../../schemas/identity.ts'

export const IdentityTypeWithIcon: React.FunctionComponent<{
	type?: string | null
}> = ({ type }) => {
	let typeName: string = type || 'N/A'
	let typeIcon: React.ReactNode = null
	if (isIdentityType(type)) {
		typeName = IdentityTypeMap[type]
		const IconComponent = IdentityTypeIconMap[type]
		typeIcon = <IconComponent className="w-5 h-5 text-base flex justify-center text-textIcon-secondary" />
	}

	return (
		<div className="flex items-center gap-1 py-1">
			{typeIcon}
			<div className="text-textIcon-primary text text-sm">{typeName}</div>
		</div>
	)
}
