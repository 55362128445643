// Mapping of event IDs to their descriptions
export const eventIdDescriptions: Record<string, string> = {
	'4720': 'User account created',
	'4722': 'User account enabled',
	'4723': 'User password change attempt',
	'4724': 'User password reset attempt',
	'4725': 'User account disabled',
	'4726': 'User account deleted',
	'4738': 'User account changed',
	'4740': 'User account locked out',
	'4765': 'SID history added to account',
	'4767': 'User account unlocked',
	'4780': 'ACL set on account',
	'4781': 'User account name changed',
	'4741': 'Computer account created',
	'4742': 'Computer account changed',
	'4743': 'Computer account deleted',

	// Usage events
	'4624': 'Login successful',
	'4768': 'Kerberos authentication ticket (TGT) request',
	'4769': 'Kerberos service ticket (TGS) request',
	'4770': 'Kerberos service ticket (TGS) renewal',
}
