import { BareIdentityGraphNodeType, BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { AdGroupNodeType } from './AdGroupNode'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { EdgeType, getEdge } from '../graphUtils/nodesAndEdges.ts'
import { IdentitySource, ServerIdentity } from '../../../../schemas/identity.ts'
import { ResourceType } from '../../../../schemas/issue.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import EntraIdIcon from '../../../../assets/entra_id_icon_16.svg?react'
import { uniqBy } from 'lodash'
import { DemoAdEndpointsNodeType } from './DemoAdEndpointsNode.tsx'
import { DemoAdServersNodeType } from './DemoAdServersNode.tsx'
import { ServerAdOwnershipLogs } from '../../../../schemas/identities/activeDirectory/adOwnershipSchema'

const nodeLogicalTypeToColumnId = {
	endpoints: 0,
	servers: 0, // Using same column as endpoints
	generalIssue: 1,
	identity: 2,
	federation: 3,
	adGroup: 4,
}

const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const federationNodeRowIndex = 0
const federationNodeId = `${nodeLogicalTypeToColumnId.federation}-${federationNodeRowIndex}`

export const getActiveDirectoryNodesAndEdges = (
	identity: ServerIdentity,
): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const identityNodes: Array<BareIdentityGraphNodeType | BareNodeType<OwnershipNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	// Initialize nodes
	const endpointsNodes: Array<BareNodeType<DemoAdEndpointsNodeType>> = []
	const serversNodes: Array<BareNodeType<DemoAdServersNodeType>> = []
	const adGroupNodes: Array<BareNodeType<AdGroupNodeType>> = []

	if (identity.active_directory_user) {
		// Add ownership node if ownership records exist
		const ownershipRecords = identity.active_directory_user.ownership_logs || []
		if (ownershipRecords.length > 0) {
			identityNodes.unshift({
				type: 'ownership',
				data: {
					owners: uniqBy(ownershipRecords, 'actor_name').map((record: ServerAdOwnershipLogs) => ({
						id: record.actor_sid,
						name: record.actor_name,
					})),
				},
				id: ownerNodeId,
			})

			edges.push(
				getEdge({
					source: ownerNodeId,
					target: identityNodeId,
					sourceHandle: 'bottom',
					targetHandle: 'top',
					animated: true,
				}),
			)
		}

		// Add Federation relationship if Entra ID user exists
		if (identity.active_directory_user.entra_id_user) {
			const entraIdUser = identity.active_directory_user.entra_id_user
			identityNodes.push({
				type: 'federation',
				data: {
					identityId: entraIdUser.identity_id ?? '',
					name: entraIdUser.user_principal_name ?? 'Unknown User',
					Icon: EntraIdIcon,
					resourceType: ResourceType.ENTRA_ID,
					identitySource: IdentitySource.ENTRA_ID_USER,
				},
				id: federationNodeId,
			})

			edges.push(
				getEdge({
					source: identityNodeId,
					target: federationNodeId,
					sourceHandle: 'bottom',
					targetHandle: 'top',
					animated: true,
				}),
			)
		}

		// Create AD Group nodes from user group memberships
		identity.active_directory_user.groups?.forEach((group, index) => {
			adGroupNodes.push({
				type: 'adGroup',
				data: { group },
				id: `${nodeLogicalTypeToColumnId.adGroup}-${index}`,
			})
		})

		// Add edges for AD groups
		adGroupNodes.forEach((_, index) => {
			const target = `${nodeLogicalTypeToColumnId.adGroup}-${index}`
			edges.push(
				getEdge({
					source: identityNodeId,
					target,
					sourceHandle: 'right',
					targetHandle: 'left',
				}),
			)
		})

		// Create Endpoints nodes
		const endpoints = identity.active_directory_user.demo_endpoints_dependencies || []
		if (endpoints.length > 0) {
			endpointsNodes.push({
				type: 'demoAdEndpoints',
				data: { endpoints },
				id: `${nodeLogicalTypeToColumnId.endpoints}-0`,
			})
		}

		// Create Servers nodes
		const servers = identity.active_directory_user.demo_servers_dependencies || []
		if (servers.length > 0) {
			serversNodes.push({
				type: 'demoAdServers',
				data: { servers },
				id: `${nodeLogicalTypeToColumnId.servers}-1`, // Using index 1 since endpoints uses 0
			})
		}

		// Add edges for endpoints
		endpointsNodes.forEach((_, index) => {
			const source = `${nodeLogicalTypeToColumnId.endpoints}-${index}`
			edges.push(getEdge({ source, target: identityNodeId }))
		})

		// Add edges for servers
		serversNodes.forEach((_, index) => {
			const source = `${nodeLogicalTypeToColumnId.servers}-${index + 1}` // +1 to match the ID we used above
			edges.push(getEdge({ source, target: identityNodeId }))
		})
	}

	// Handle computer group memberships if it's an AD computer
	if (identity.active_directory_computer && identity.active_directory_computer.groups) {
		identity.active_directory_computer.groups.forEach((group, index) => {
			const groupNodeId = `${nodeLogicalTypeToColumnId.adGroup}-${adGroupNodes.length + index}`
			adGroupNodes.push({
				type: 'adGroup',
				data: { group },
				id: groupNodeId,
			})

			// Add edge for the group
			edges.push(
				getEdge({
					source: identityNodeId,
					target: groupNodeId,
					sourceHandle: 'right',
					targetHandle: 'left',
				}),
			)
		})
	}

	// Handle issues for both user and computer types
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	// Add edges for general issues
	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	const nodes: BareNodesColumnsType[] = [
		{
			yPosition: 'top',
			nodes: [...endpointsNodes, ...serversNodes],
		},
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{
			yPosition: 'center',
			nodes: adGroupNodes,
		},
	]

	return [nodes, edges]
}
