import cx from 'classnames'
import { BaseNode } from './BaseNode.tsx'
import { ComponentType } from 'react'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { NodeGroupBadge } from '../NodeGroupBadge.tsx'

type BaseResourcesNodeProps = {
	resources: unknown[]
	isHighlighted?: boolean
	nodeLabel: string
	tooltipTitle: string
	Icon: ComponentType
}

export const BaseDependenciesNode = ({
	resources,
	isHighlighted,
	nodeLabel,
	tooltipTitle,
	Icon,
}: BaseResourcesNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.dependencies.bgHover} ${nodeColorClassNames.dependencies.text} w-[50px] h-[50px] rounded-full flex justify-center items-center text-2xl`,
		{
			[nodeColorClassNames.dependencies.bg]: !isHighlighted,
			[nodeColorClassNames.dependencies.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode labelProps={{ label: nodeLabel }}>
			<NodeGroupBadge count={resources.length} nodeColorType="dependencies" tooltipContent={tooltipTitle} />
			<div className={iconWrapperClassName}>
				<Icon />
			</div>
		</BaseNode>
	)
}
