import { Node, NodeProps } from '@xyflow/react'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import GithubActionsNodeInnerIcon from '../../../../assets/githubActionsNodeInnerIcon.svg?react'

export type GithubActionsNodeType = Node<{ action: string }, 'githubActions'>
type GithubActionsNodeProps = NodeProps<GithubActionsNodeType>

export const GithubActionsNode = ({ data: { action } }: GithubActionsNodeProps) => {
	const iconWrapperClassName = `${nodeColorClassNames.action.bgHover} ${nodeColorClassNames.action.bg} border border-dsBlue-200 w-[50px] h-[50px] rounded-full flex justify-center items-center text-2xl transition-colors duration-300`

	return (
		<BaseNode labelProps={{ label: action }}>
			<div className={iconWrapperClassName}>
				<GithubActionsNodeInnerIcon />
			</div>
		</BaseNode>
	)
}
