import { awsIamRolesXcData } from './awsIamRolesXcData'
import { getRecentlyCreatedDate } from '../utils.ts'
import { ServerEntraIdUserInput } from '../../schemas/identities/entraId/entraIdUserSchema.ts'
import { ServerAzureUsageLogInput } from '../../schemas/identities/entraId/azureUsageLogSchema.ts'

export const highRiskEntraIdUserLogs: Array<ServerAzureUsageLogInput> = [
	{
		actor: 'Alex Sigdag',
		association: "Alex Sigdag's Windows 11",
		association_type: 'Windows Device',
		ip_address: '88.76.123.45',
		day: '2024-07-15T00:00:00Z',
		operations: ['Microsoft Teams'],
		operations_count: 2,
		credential_id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e1',
		user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36',
	},
	{
		actor: 'Alex Sigdag',
		association: "Alex Sigdag's Windows 11",
		association_type: 'Windows Device',
		ip_address: '88.76.123.45',
		day: '2024-07-18T00:00:00Z',
		operations: ['GitLab', 'Azure DevOps'],
		operations_count: 5,
		credential_id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e2',
		user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36',
	},
	{
		actor: 'Alex Sigdag',
		association: "Alex Sigdag's Windows 11",
		association_type: 'Windows Device',
		ip_address: '88.76.123.45',
		day: '2024-07-22T00:00:00Z',
		operations: ['Microsoft Teams', 'Jira'],
		operations_count: 14,
		credential_id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e3',
		user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36',
	},
	{
		actor: 'Alex Sigdag',
		association: "Alex Sigdag's Windows 11",
		association_type: 'Windows Device',
		ip_address: '88.76.123.45',
		day: '2024-07-29T00:00:00Z',
		operations: ['PagerDuty', 'Azure DevOps'],
		operations_count: 7,
		credential_id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e4',
		user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36',
	},
	{
		actor: 'Alex Sigdag',
		association: '(unknown)',
		ip_address: '192.240.106.189',
		day: '2024-08-02T00:00:00Z',
		operations: ['Microsoft Teams', 'GitLab', 'Jira', 'Zendesk'],
		operations_count: 11,
		credential_id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0e5',
		user_agent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36',
	},
]

export const commonEntraIdUserData = {
	JASON_NICKELSON: {
		identity_id: '300',
		entra_user_id: '6fa459ea-ee8a-3ca4-894e-db77e160355e',
		last_activity: getRecentlyCreatedDate(),
		given_name: 'Jason',
		mail: 'jason.nickelson@synergy.com',
		user_principal_name: 'jason.nickelson@synergy.com',
		password_last_changed: null,
		account_enabled: true,
	},
	DANTE_WALKER: {
		entra_user_id: '123e4567-e89b-12d3-a456-426614174000',
		last_activity: getRecentlyCreatedDate(),
		given_name: 'Dante',
		mail: 'dante.walker@synergy.com',
		user_principal_name: 'dante.walker@synergy.com',
		password_last_changed: null,
		account_enabled: true,
	},
	ALEX_SIGDAG: {
		identity_id: '302',
		entra_user_id: 'f8c3de3d-1f13-4a24-b7c8-93a5279c458f',
		last_activity: '2023-03-27T22:13:07.000000Z',
		given_name: 'Alex',
		mail: 'alex.sigdag@synergy.com',
		user_principal_name: 'alex.sigdag@synergy.com',
		password_last_changed: '2023-03-27T22:13:07.000000Z',
		account_enabled: true,
		aws_iam_roles_xc: [awsIamRolesXcData['3']],
		usage_logs: highRiskEntraIdUserLogs,
	},
	AMY_ALEXANDER: {
		entra_user_id: '12ce0455-6576-4802-a61b-82cad3647863',
		last_activity: '2024-04-20T23:54:27.000000Z',
		given_name: 'Amy',
		mail: 'amy.alexander@synergy.com',
		user_principal_name: 'amy.alexander@synergy.com',
		password_last_changed: '2019-05-12T17:38:58.000000Z',
		account_enabled: true,
	},
	LAURA_ZAMORA: {
		entra_user_id: '1ee1d6a2-9113-4663-82ec-b4102eebe616',
		last_activity: '2024-04-20T10:11:42.000000Z',
		given_name: 'Laura',
		mail: 'laura.zamora@synergy.com',
		user_principal_name: 'laura.zamora@synergy.com',
		password_last_changed: '2019-04-17T12:20:59.000000Z',
		account_enabled: true,
	},
	MARY_CHAPMAN: {
		entra_user_id: '8b0a406c-699f-447f-8f89-4512c2700e3d',
		last_activity: '2024-04-20T20:53:24.000000Z',
		given_name: 'Mary',
		mail: 'mary.chapman@synergy.com',
		user_principal_name: 'mary.chapman@synergy.com',
		password_last_changed: '2019-07-23T15:30:47.000000Z',
		account_enabled: true,
	},
	PRISCILLA_DAVIS: {
		entra_user_id: 'ebbc2601-1294-4052-b3ea-6de3fbdc3f4f',
		last_activity: '2024-04-20T04:19:51.000000Z',
		given_name: 'Priscilla',
		mail: 'priscilla.davis@synergy.com',
		user_principal_name: 'priscilla.davis@synergy.com',
		password_last_changed: '2019-02-14T16:05:05.000000Z',
		account_enabled: true,
	},
	ELIZABETH_TRUJILLO: {
		entra_user_id: '320aabcf-deeb-4720-b383-720ba5b48189',
		last_activity: '2024-04-20T21:47:06.000000Z',
		given_name: 'Elizabeth',
		mail: 'elizabeth.trujillo@synergy.com',
		user_principal_name: 'elizabeth.trujillo@synergy.com',
		password_last_changed: '2019-05-19T17:40:16.000000Z',
		account_enabled: true,
	},
	BRIAN_ESPINOZA: {
		entra_user_id: 'f005bb11-621b-4e84-a2ae-e3f5bfc53491',
		last_activity: '2024-04-20T18:28:43.000000Z',
		given_name: 'Brian',
		mail: 'brian.espinoza@synergy.com',
		user_principal_name: 'brian.espinoza@synergy.com',
		password_last_changed: '2019-05-20T22:48:32.000000Z',
		account_enabled: true,
	},
	MARIA_GREEN: {
		entra_user_id: 'b85a8b89-9d52-45ae-acd9-837c60f7c68f',
		last_activity: '2024-04-20T03:18:56.000000Z',
		given_name: 'Maria',
		mail: 'maria.green@synergy.com',
		user_principal_name: 'maria.green@synergy.com',
		password_last_changed: '2019-05-16T07:05:18.000000Z',
		account_enabled: true,
	},
	CHARLES_CARROLL: {
		entra_user_id: '5c5f3ae7-fac4-40c2-824c-69f62c478990',
		last_activity: '2024-04-20T07:45:34.000000Z',
		given_name: 'Charles',
		mail: 'charles.carroll@synergy.com',
		user_principal_name: 'charles.carroll@synergy.com',
		password_last_changed: '2019-02-18T03:44:03.000000Z',
		account_enabled: true,
	},
	DEVIN_VANCE: {
		entra_user_id: 'ce5233cd-0efa-4452-af94-7db18542e8d7',
		last_activity: '2024-04-20T16:11:42.000000Z',
		given_name: 'Devin',
		mail: 'devin.vance@synergy.com',
		user_principal_name: 'devin.vance@synergy.com',
		password_last_changed: '2019-05-05T19:34:46.000000Z',
		account_enabled: true,
	},
	JOHN_BEARD: {
		entra_user_id: 'aff6da82-9d3e-45c1-90df-34c4ff6d8709',
		last_activity: '2024-04-20T17:48:02.000000Z',
		given_name: 'John',
		mail: 'john.beard@synergy.com',
		user_principal_name: 'john.beard@synergy.com',
		password_last_changed: '2019-06-05T20:00:26.000000Z',
		account_enabled: true,
	},
	GEORGE_BROWN: {
		identity_id: '139',
		entra_user_id: '70765eaa-3e0e-4029-abca-5c29be895b1f',
		last_activity: '2023-03-27T22:13:07.000000Z',
		given_name: 'George',
		mail: 'george.brown@synergy.com',
		user_principal_name: 'george.brown@synergy.com',
		password_last_changed: '2023-03-27T22:13:07.000000Z',
		account_enabled: true,
		aws_iam_roles_xc: [awsIamRolesXcData['4']],
	},
	JOSEPH_VILLANUEVA: {
		identity_id: '140',
		entra_user_id: '6fc05143-9be8-4fbc-b599-5d6c48a6a7ec',
		last_activity: '2023-12-22T16:45:01.000000Z',
		given_name: 'Joseph',
		mail: 'joseph.villanueva@synergy.com',
		user_principal_name: 'joseph.villanueva@synergy.com',
		password_last_changed: '2023-12-22T16:45:01.000000Z',
		account_enabled: true,
		aws_iam_roles_xc: [awsIamRolesXcData['4']],
	},
	ELIZABETH_THOMAS: {
		entra_user_id: 'ae066ab2-7749-4d50-a74b-e557bf062e9f',
		last_activity: '2024-04-01T19:28:47.000000Z',
		given_name: 'Elizabeth',
		mail: 'elizabeth.thomas@synergy.com',
		user_principal_name: 'elizabeth.thomas@synergy.com',
		password_last_changed: '2024-04-13T11:32:44.000000Z',
		account_enabled: true,
		aws_iam_roles_xc: [awsIamRolesXcData['4']],
	},
	DERRICK_MORRIS: {
		entra_user_id: '79b0e871-6517-4640-948c-6a6c60eae9bf',
		last_activity: '2024-04-07T19:07:50.000000Z',
		given_name: 'Derrick',
		mail: 'derrick.morris@synergy.com',
		user_principal_name: 'derrick.morris@synergy.com',
		password_last_changed: '2024-04-13T09:04:16.000000Z',
		account_enabled: true,
	},
	MELISSA_NGUYEN: {
		entra_user_id: '990d3f31-10fa-467d-bc05-4b336a26529e',
		last_activity: '2024-04-07T17:24:22.000000Z',
		given_name: 'Melissa',
		mail: 'melissa.nguyen@synergy.com',
		user_principal_name: 'melissa.nguyen@synergy.com',
		password_last_changed: '2024-04-02T01:57:30.000000Z',
		account_enabled: true,
	},
	BRETT_JACKSON: {
		entra_user_id: '0a900c5a-d3d5-4579-a05f-6b056e8e7c94',
		last_activity: '2024-04-07T07:26:47.000000Z',
		given_name: 'Brett',
		mail: 'brett.jackson@synergy.com',
		user_principal_name: 'brett.jackson@synergy.com',
		password_last_changed: '2024-04-02T22:50:16.000000Z',
		account_enabled: true,
	},
	AARON_HUBBARD: {
		entra_user_id: '7ba0fe56-43b9-426c-a039-7efddd9e8878',
		last_activity: '2024-04-06T03:34:22.000000Z',
		given_name: 'Aaron',
		mail: 'aaron.hubbard@synergy.com',
		user_principal_name: 'aaron.hubbard@synergy.com',
		password_last_changed: '2024-04-08T08:07:14.000000Z',
		account_enabled: true,
	},
	RAYMOND_MARKS: {
		entra_user_id: 'fe6c586d-9b27-4ef1-9adc-f323d91124f0',
		last_activity: '2024-04-07T06:17:46.000000Z',
		given_name: 'Raymond',
		mail: 'raymond.marks@synergy.com',
		user_principal_name: 'raymond.marks@synergy.com',
		password_last_changed: '2024-04-15T03:39:22.000000Z',
		account_enabled: true,
	},
} satisfies Record<string, ServerEntraIdUserInput>

export const entraIdUserIds = {
	JASON_NICKELSON: '6fa459ea-ee8a-3ca4-894e-db77e160355e',
	DANTE_WALKER: '123e4567-e89b-12d3-a456-426614174000',
	ALEX_SIGDAG: 'f8c3de3d-1f13-4a24-b7c8-93a5279c458f',
	AMY_ALEXANDER: '12ce0455-6576-4802-a61b-82cad3647863',
	LAURA_ZAMORA: '1ee1d6a2-9113-4663-82ec-b4102eebe616',
	MARY_CHAPMAN: '8b0a406c-699f-447f-8f89-4512c2700e3d',
	PRISCILLA_DAVIS: 'ebbc2601-1294-4052-b3ea-6de3fbdc3f4f',
	ELIZABETH_TRUJILLO: '320aabcf-deeb-4720-b383-720ba5b48189',
	BRIAN_ESPINOZA: 'f005bb11-621b-4e84-a2ae-e3f5bfc53491',
	MARIA_GREEN: 'b85a8b89-9d52-45ae-acd9-837c60f7c68f',
	CHARLES_CARROLL: '5c5f3ae7-fac4-40c2-824c-69f62c478990',
	DEVIN_VANCE: 'ce5233cd-0efa-4452-af94-7db18542e8d7',
	JOHN_BEARD: 'aff6da82-9d3e-45c1-90df-34c4ff6d8709',
	GEORGE_BROWN: '70765eaa-3e0e-4029-abca-5c29be895b1f',
	JOSEPH_VILLANUEVA: '6fc05143-9be8-4fbc-b599-5d6c48a6a7ec',
	ELIZABETH_THOMAS: 'ae066ab2-7749-4d50-a74b-e557bf062e9f',
	DERRICK_MORRIS: '79b0e871-6517-4640-948c-6a6c60eae9bf',
	MELISSA_NGUYEN: '990d3f31-10fa-467d-bc05-4b336a26529e',
	BRETT_JACKSON: '0a900c5a-d3d5-4579-a05f-6b056e8e7c94',
	AARON_HUBBARD: '7ba0fe56-43b9-426c-a039-7efddd9e8878',
	RAYMOND_MARKS: 'fe6c586d-9b27-4ef1-9adc-f323d91124f0',
}
