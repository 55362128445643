import { ServerAdAceInput } from '../../schemas/identities/activeDirectory/adAceSchema.ts'

export const StandardGroupSids = {
	DOMAIN_ADMINS: 'S-1-5-21-3623811015-3361044348-30300820-512',
	DNS_ADMINS: 'S-1-5-21-3623811015-3361044348-30300820-1101',
	SQL_WRITE_ACCESS: 'S-1-5-21-3623811015-3361044348-30300820-1201',
	DOMAIN_USERS: 'S-1-5-21-3623811015-3361044348-30300820-513',
	DOMAIN_SID: 'S-1-5-21-3623811015-3361044348-30300820',
	PRM_LOG_ADMINS: 'S-1-5-21-3623811015-3361044348-30300820-1502',
	SCHEMA_ADMINS: 'S-1-5-21-3623811015-3361044348-30300820-518',
	ENTERPRISE_ADMINS: 'S-1-5-21-3623811015-3361044348-30300820-519',
	BACKUP_OPERATORS: 'S-1-5-32-551',
	SERVER_OPERATORS: 'S-1-5-32-549',
	PERFORMANCE_MONITOR_USERS: 'S-1-5-32-558',
	DOMAIN_COMPUTERS: 'S-1-5-21-3623811015-3361044348-30300820-515',
} as const

export const StandardAces: Record<string, Array<ServerAdAceInput>> = {
	DOMAIN_ADMIN_RIGHTS: [
		{
			right_name: 'GenericWrite',
			is_inherited: false,
			target_principal_sid: StandardGroupSids.DOMAIN_SID,
			target_principal_type: 'Group',
		},
		{
			right_name: 'WriteOwner',
			is_inherited: false,
			target_principal_sid: StandardGroupSids.DOMAIN_SID,
			target_principal_type: 'Group',
		},
		{
			right_name: 'AllExtendedRights',
			is_inherited: false,
			target_principal_sid: StandardGroupSids.DOMAIN_SID,
			target_principal_type: 'Group',
		},
		{
			right_name: 'WriteDacl',
			is_inherited: false,
			target_principal_sid: StandardGroupSids.DOMAIN_SID,
			target_principal_type: 'Group',
		},
	],
	FORCE_CHANGE_PASSWORD: [
		{
			right_name: 'ForceChangePassword',
			is_inherited: false,
			target_principal_sid: 'S-1-5-21-3623811015-3361044348-30300820-1112',
			target_principal_type: 'User',
			tags: [
				{
					id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
					name: 'POSSIBLE_PRIVILEGE_ESCALATION',
					impact: 3,
					probability: null,
					related_resource_type: 'ACTIVE_DIRECTORY',
				},
			],
		},
	],
	LOCAL_ADMIN_RIGHTS: [
		{
			right_name: 'GenericAll',
			is_inherited: false,
			target_principal_sid: 'S-1-5-32-544', // Built-in Administrators group
			target_principal_type: 'Group',
			tags: [
				{
					id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
					name: 'ADMIN',
					impact: 3,
					probability: null,
					related_resource_type: 'ACTIVE_DIRECTORY',
				},
			],
		},
	],
}
