import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent } from 'ag-grid-enterprise'
import { type z } from 'zod'
import { BaseAzureDevopsUserSchema } from '../../../../../schemas/identities/azure/azureDevops/azureDevopsUserSchema.ts'

type AzureDevopsUser = z.infer<typeof BaseAzureDevopsUserSchema>

export const AzureDevopsUsersTable: React.FC<{ data: AzureDevopsUser[] }> = ({ data }) => {
	const gridRef = useRef<AgGridReact<AzureDevopsUser>>(null)

	const onGridReady = useCallback((_params: GridReadyEvent<AzureDevopsUser>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<AzureDevopsUser>[]>(
		() => [
			{
				field: 'principal_name',
				headerName: 'Principal Name',
				flex: 2,
			},
			{
				field: 'mail_address',
				headerName: 'Email',
				flex: 2,
			},
			{
				field: 'descriptor',
				headerName: 'Descriptor',
				flex: 1.5,
			},
		],
		[],
	)

	const defaultColDef = useMemo<ColDef<AzureDevopsUser>>(
		() => ({
			sortable: true,
			filter: true,
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
			},
		}),
		[],
	)

	return (
		<div className="w-full">
			<AgGridReact<AzureDevopsUser>
				ref={gridRef}
				className="ag-theme-alpine"
				rowData={data}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				rowHeight={40}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
