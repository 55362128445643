import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-enterprise'
import { Button, Modal } from 'antd'
import { formatDate, getCsvFileName } from '../../../../../utils.ts'
import { AdUsageLog } from '../../../../../schemas/identities/activeDirectory/adUsageSchema'
import { AdAggregatedUsageTable } from './AdAggregatedUsageTable'
import { aggregateAdUsageLogs } from './adLogsUtils'
import { eventIdDescriptions } from './adConstants'
import ExportIcon from '../../../../../assets/export_icon_20.svg?react'

export const AdUsageTable: React.FunctionComponent<{
	data: AdUsageLog[]
	identityName: string
}> = ({ data, identityName }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const gridRef = useRef<AgGridReact>(null)

	const exportToCsv = useCallback(() => {
		gridRef?.current!.api.exportDataAsCsv({
			fileName: getCsvFileName('ad-usage-logs', false),
		})
	}, [])

	const columnDefs = useMemo<ColDef<AdUsageLog>[]>(() => {
		return [
			{
				field: 'account_name',
				headerName: 'Actor SID',
				flex: 1,
			},
			{
				field: 'service_name',
				headerName: 'Service Name',
				flex: 1,
			},
			{
				field: 'network_address',
				headerName: 'IP',
				flex: 1,
			},
			{
				field: 'dc',
				headerName: 'DC',
				flex: 1,
			},
			{
				headerName: 'Action',
				flex: 1.5,
				valueGetter: (params: { data?: AdUsageLog }) => {
					if (!params.data) return ''
					return eventIdDescriptions[params.data.event_id] || 'Unknown event'
				},
			},
			{
				field: 'service_sid',
				headerName: 'Security ID',
				flex: 1.5,
			},
			{
				field: 'user_sid',
				headerName: 'Actor SID',
				flex: 1.5,
			},
			{
				field: 'time_created',
				headerName: 'Date',
				flex: 1,
				valueFormatter: (params: { value: Date }) => formatDate(params.value, false),
			},
			{
				field: 'event_count',
				headerName: 'Event Count',
				flex: 1,
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	if (!data.length) {
		return <div className={'text-center'}>No Active Directory usage data available for {identityName}</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<AdAggregatedUsageTable data={aggregateAdUsageLogs(data)} />
			</div>

			<div className="flex justify-between">
				<Button type="link" size="small" className="mt-4" onClick={() => setIsModalOpen(true)}>
					See all events
				</Button>
			</div>

			<Modal
				centered
				width={1024}
				title="Active Directory Usage Logs"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex gap-2">
							<Button icon={<ExportIcon />} onClick={exportToCsv}>
								Export CSV
							</Button>
							<Button type="primary" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className="ag-theme-alpine w-full"
						rowData={data}
						rowHeight={54}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
						ref={gridRef}
					/>
				</div>
			</Modal>
		</>
	)
}
