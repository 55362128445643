import { Tooltip } from 'antd'
import { ReactNode } from 'react'
import { NodeColorTypes } from '../graphUtils/nodeColors.ts'
import { NodeBadge } from './NodeBadge.tsx'

type NodeGroupBadgeProps = {
	count: number
	nodeColorType: NodeColorTypes
	tooltipContent?: ReactNode
	positionOnLeft?: boolean
}

export const NodeGroupBadge = ({ count, nodeColorType, tooltipContent, positionOnLeft }: NodeGroupBadgeProps) => {
	const badgeNode = (
		<NodeBadge nodeColorType={nodeColorType} className={'px-1'} positionOnLeft={positionOnLeft}>
			{count}
		</NodeBadge>
	)

	if (!tooltipContent) {
		return badgeNode
	}

	return (
		<Tooltip
			classNames={{ body: 'w-fit' }}
			title={<div className="flex flex-col gap-1 max-h-[40vh] overflow-y-auto">{tooltipContent}</div>}
		>
			{badgeNode}
		</Tooltip>
	)
}
