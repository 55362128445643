import { z } from 'zod'
import { AnyFactories, ModelDefinition } from 'miragejs/-types'
import { Model, Registry, Server } from 'miragejs'
import { ServerIssue, ServerIssueInput, ServerIssueSchema } from '../schemas/issue.ts'
import {
	ServerIdentitiesTableRowInput,
	ServerIdentity,
	ServerIdentityInput,
	ServerIdentitySchema,
	ServerIdentityUsageLogsInput,
} from '../schemas/identity.ts'
import Schema from 'miragejs/orm/schema'
import { ServerNotification, ServerNotificationSchema } from '../schemas/notifications.ts'
import { ServerUsageLogGrouped, ServerUsageLogGroupedSchema } from '../schemas/identities/groupedUsageLogsSchema.ts'

export const proxyObject = z.object({
	method: z.string(),
	integrationId: z.string().uuid(),
	queryParams: z
		.object({
			action: z.string().optional(),
			project: z.string().optional(),
		})
		.optional(),
	endpoint: z.string(),
})

export const CloudProviders = z.enum(['AWS', 'GCP', 'AZURE'])
export const IdentityProviders = z.enum(['OKTA', 'GOOGLE_WORKSPACE', 'JUMPCLOUD', 'ENTRA_ID'])

export const issueModel: ModelDefinition<ServerIssue> = Model.extend(ServerIssueSchema)
export const identityModel: ModelDefinition<ServerIdentity> = Model.extend(ServerIdentitySchema)
export const notificationModel: ModelDefinition<ServerNotification> = Model.extend(ServerNotificationSchema)
export const usageLogGroupedSchema: ModelDefinition<ServerUsageLogGrouped> = Model.extend(ServerUsageLogGroupedSchema)

export const models = {
	issue: issueModel,
	identity: identityModel,
	notification: notificationModel,
	usageLogsGrouped: usageLogGroupedSchema,
}

type AppRegistry = Registry<typeof models, AnyFactories>
export type AppSchema = Schema<AppRegistry>
export type AppServer = Server<AppRegistry>

export type DemoServerIdentityInput = ServerIdentityInput &
	Pick<
		ServerIdentitiesTableRowInput,
		'affected_environments' | 'max_priority' | 'issue_count' | 'mock_demo_tags' | 'owners' | 'credentials'
	> & { noIssueConnectedToIdentity?: boolean; demo_usage_logs?: ServerIdentityUsageLogsInput }

export type DemoServerIssueInput = ServerIssueInput & { identity: DemoServerIdentityInput }
