import { generateUUID } from '../utils.ts'
import { policyTags } from './awsIamUserData.ts'
import {
	ACCOUNT_LITERAL_FRIENDLY_NAME,
	ADMIN_ROLE,
	DATABRICKS_ACCOUNT_1,
	DATABRICKS_WORKSPACE_1,
	DATABRICKS_WORKSPACE_2,
	READ_ONLY_ROLE,
} from './databricksUsersData.ts'
import { MockDemoTag } from '../../schemas/tags.ts'
import { kubernetesResourcesXc5 } from './crossContextData.ts'
import { ServerAwsIamChangeLogInput } from '../../schemas/identities/awsIamChangeLogSchema.ts'
import { ServerEndpointAccessXcInput } from '../../schemas/identities/endpointAccessXcSchema.ts'
import { IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { DemoServerIdentityInput } from '../schemas.ts'

const getChangeLogs1 = (userName: string, affectedArn: string): Array<ServerAwsIamChangeLogInput> => {
	return [
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '2c4f1c35-4eb2-4473-a8a3-db169edf910b',
			event_time: '2022-02-20T07:50:51Z',
			created_at: '2022-02-20T07:50:51.546241Z',
			event_name: 'CreateUser',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/alice.jones@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '207.23.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '78972903-598d-4f46-98c0-c1191fb161af',
			event_time: '2023-10-17T11:56:57Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/alice.jones@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '207.23.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'ae07448e-900e-4389-9314-4bf69e460295',
			event_time: '2023-10-17T11:58:03Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'CreateAccessKey',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/alice.jones@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '207.23.13.65',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '04ac177d-a465-4053-a75d-928ff037652b',
			event_time: '2023-12-31T15:07:01Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'UpdateAccessKey',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.132.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				status: 'Inactive',
				userName: userName,
				accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '98bd256f-7bf3-4b61-81b3-229c8dc24916',
			event_time: '2023-12-31T15:07:09Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DeleteAccessKey',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.132.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '806b98a7-2952-493a-8225-e0884423b5d6',
			event_time: '2023-12-31T15:07:26Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'CreateAccessKey',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.132.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '3f1c890c-bd11-4d62-808e-99b14d8a0eac',
			event_time: '2023-12-31T15:09:37Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'PutUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.132.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyName: 'eks-permissions',
				policyDocument:
					'{\n\t"Version": "2012-10-17",\n\t"Statement": [\n\t\t{\n\t\t\t"Sid": "VisualEditor0",\n\t\t\t"Effect": "Allow",\n\t\t\t"Action": "eks:*",\n\t\t\t"Resource": "*"\n\t\t}\n\t]\n}',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'a2b937e0-fc93-4db7-a9b5-4f45efa5eb88',
			event_time: '2024-03-17T14:28:02Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.132.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'd2dd9957-133c-4c98-8dfd-d2273d7ccc30',
			event_time: '2024-03-18T11:47:00Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DetachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '5.132.59.83',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: '964d09b4-46b1-47e8-b314-02457447727a',
			event_time: '2024-03-25T21:05:41Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'AttachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '46.131.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::710369796047:policy/eks-service-account-policy-for-pods',
			},
		},
		{
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
			id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0da',
			event_time: '2024-03-25T21:05:53Z',
			created_at: '2024-05-07T15:00:09.546241Z',
			event_name: 'DetachUserPolicy',
			actor_arn:
				'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.com',
			affected_arn: affectedArn,
			source_ip_address: '46.131.39.227',
			user_agent: 'AWS Internal',
			request_parameters: {
				userName: userName,
				policyArn: 'arn:aws:iam::710369796047:policy/gabrielabeard-service-account-policy-for-pods',
			},
		},
	]
}
export const endpointAccessXc: Array<ServerEndpointAccessXcInput> = [
	{
		device_name: 'Alice Jones Macbook Pro',
		device_type: 'Desktop - MacOS',
		status: 'Active',
		endpoint_protection: false,
		ip_address: '3.125.8.10',
		first_seen: '2024-03-11 14:42:39+00:00',
		last_seen: '2024-06-08 21:04:33+00:00',
	},
	{
		device_name: 'VirtualMachine-1',
		device_type: 'Virtual Machine - Linux',
		status: 'Active',
		endpoint_protection: true,
		ip_address: '127.125.42.145',
		first_seen: '2024-02-23 08:40:08+00:00',
		last_seen: '2024-06-09 11:13:52+00:00',
	},
	{
		device_name: 'IT-Admin-PC',
		device_type: 'Desktop - Windows',
		status: 'Inactive',
		endpoint_protection: false,
		ip_address: '127.41.14.241',
		first_seen: '2024-05-12 09:14:13+00:00',
		last_seen: '2024-05-12 10:37:22+00:00',
	},
]

const SP_ID_1 = 'cd8981a0-d31a-4ba3-8760-4d24060fed6f'
const SP_ID_2 = 'cd8981a0-d31a-4ba3-8760-4aaa06011111'
const SP_ID_3 = 'cd8981a0-d31a-4ba3-8760-4aaa06022222'
const SP_ID_4 = 'cd8981a0-d31a-4ba3-8760-4aaa06033333'
const SP_NAME = 'SecurityAudit'

export const databricksServicePrincipalData = {
	'80000': {
		id: SP_ID_1,
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: generateUUID(),
		literal_friendly_name: 'App-Prod-Azure',
		created_at: '2024-02-02T06:05:32.949000Z',
		updated_at: '2024-06-17T13:57:43.884507Z',
		last_activity: '2024-01-02T10:51:41.037000Z',
		env_type: EnvironmentType.DATABRICKS,
		type: IdentityType.Machine,
		source: IdentitySource.DATABRICKS_SERVICE_PRINCIPAL,
		issues: [],
		tags: [policyTags.ADMIN],
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		databricks_service_principal: {
			sp_id: '7ef4dd3b-63ce-4c9d-b2d0-cfd977d8d603',
			change_logs: getChangeLogs1('michael-quinn', 'arn:aws:iam::467791917130:user/michael-quinn'),
			sp_name: 'App-Prod-Azure',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [ADMIN_ROLE],
			kubernetes_resources_xc: kubernetesResourcesXc5,
			workspaces: [DATABRICKS_WORKSPACE_1],
			oauth_tokens: [
				{
					token_id: '3ba0c9118a4f1eba22be11a59b7cdc85630c5c9a451654dbfe024140c3d2a398',
					created_at: '2023-01-01T00:00:00Z',
					last_used: '2023-06-01T12:00:00Z',
				},
			],
			endpoint_access_xc: endpointAccessXc,
		},
	},
	'80001': {
		id: SP_ID_2,
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: generateUUID(),
		literal_friendly_name: SP_NAME,
		created_at: '2024-02-02T06:05:32.949000Z',
		updated_at: '2024-06-17T13:57:43.884507Z',
		last_activity: '2024-01-02T10:51:41.037000Z',
		env_type: EnvironmentType.DATABRICKS,
		type: IdentityType.Machine,
		source: IdentitySource.DATABRICKS_SERVICE_PRINCIPAL,
		issues: [],
		tags: [policyTags.ADMIN],
		mock_demo_tags: [],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		databricks_service_principal: {
			sp_id: '7ef4dd3b-63ce-4c9d-b2d0-cfd977d8d603',
			change_logs: getChangeLogs1('michael-quinn', 'arn:aws:iam::467791917130:user/michael-quinn'),
			sp_name: SP_NAME,
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [ADMIN_ROLE],
			kubernetes_resources_xc: null,
			workspaces: [DATABRICKS_WORKSPACE_1],
			oauth_tokens: [
				{
					token_id: '3ba0c9118a4f1eba22be11a59b7cdc85630c5c9a451654dbfe024140c3d2a398',
					created_at: '2023-01-01T00:00:00Z',
					last_used: '2023-06-01T12:00:00Z',
				},
			],
			endpoint_access_xc: endpointAccessXc,
		},
	},
	'80002': {
		id: SP_ID_3,
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: generateUUID(),
		literal_friendly_name: 'prod-databricks-data-processing-sp',
		created_at: '2024-02-02T06:05:32.949000Z',
		updated_at: '2024-06-17T13:57:43.884507Z',
		last_activity: '2024-01-02T10:51:41.037000Z',
		env_type: EnvironmentType.DATABRICKS,
		type: IdentityType.Machine,
		source: IdentitySource.DATABRICKS_SERVICE_PRINCIPAL,
		issues: [],
		tags: [policyTags.DATA_ACCESS],
		mock_demo_tags: [],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		databricks_service_principal: {
			sp_id: '7ef4dd3b-63ce-4c9d-b2d0-cfd977d8d603',
			change_logs: getChangeLogs1('michael-quinn', 'arn:aws:iam::467791917130:user/michael-quinn'),
			sp_name: 'prod-databricks-data-processing-sp',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [READ_ONLY_ROLE],
			kubernetes_resources_xc: kubernetesResourcesXc5,
			workspaces: [DATABRICKS_WORKSPACE_1],
			oauth_tokens: [
				{
					token_id: '3ba0c9118a4f1eba22be11a59b7cdc85630c5c9a451654dbfe024140c3d2a398',
					created_at: '2023-01-01T00:00:00Z',
					last_used: '2023-06-01T12:00:00Z',
				},
			],
			endpoint_access_xc: endpointAccessXc,
		},
	},
	'80003': {
		id: SP_ID_4,
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: generateUUID(),
		literal_friendly_name: 'dev-databricks-etl-sp',
		created_at: '2024-02-02T06:05:32.949000Z',
		updated_at: '2024-06-17T13:57:43.884507Z',
		last_activity: '2024-01-02T10:51:41.037000Z',
		env_type: EnvironmentType.DATABRICKS,
		type: IdentityType.Machine,
		source: IdentitySource.DATABRICKS_SERVICE_PRINCIPAL,
		issues: [],
		tags: [policyTags.DATA_ACCESS],
		mock_demo_tags: [],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		databricks_service_principal: {
			sp_id: '7ef4dd3b-63ce-4c9d-b2d0-cfd977d8d603',
			change_logs: getChangeLogs1('michael-quinn', 'arn:aws:iam::467791917130:user/michael-quinn'),
			sp_name: 'dev-databricks-etl-sp',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [READ_ONLY_ROLE],
			kubernetes_resources_xc: kubernetesResourcesXc5,
			workspaces: [DATABRICKS_WORKSPACE_1, DATABRICKS_WORKSPACE_2],
			oauth_tokens: [
				{
					token_id: '3ba0c9118a4f1eba22be11a59b7cdc85630c5c9a451654dbfe024140c3d2a398',
					created_at: '2023-01-01T00:00:00Z',
					last_used: '2023-06-01T12:00:00Z',
				},
			],
			endpoint_access_xc: endpointAccessXc,
		},
	},
} satisfies Record<string, DemoServerIdentityInput>
