import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellMouseOverEvent, ColDef, GridReadyEvent } from 'ag-grid-enterprise'
import { ServerGcpServiceAccountProjectRole } from '../../../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'
import { AccountPriorityNameTag } from '../../../../../components/common/AccountPriorityNameTag.tsx'
import { AccountPriorityEnum, AccountPriorityTypeMap } from '../../../../../schemas/environments/accountPriorityEnum.ts'

export const GcpProjectsTable: React.FunctionComponent<{
	gcpServiceAccountRolesMappings: ServerGcpServiceAccountProjectRole[]
	onCellMouseOver?: (event: CellMouseOverEvent<ServerGcpServiceAccountProjectRole>) => void
	onCellMouseOut?: () => void
}> = ({ gcpServiceAccountRolesMappings, onCellMouseOver, onCellMouseOut }) => {
	const gridRef = useRef<AgGridReact<ServerGcpServiceAccountProjectRole>>(null)
	const [rowData, setRowData] = useState<ServerGcpServiceAccountProjectRole[] | null>()
	const showAccountPriorityDrawer = gcpServiceAccountRolesMappings?.every(
		(projectInfo) => projectInfo.project.priority,
	)

	const getRowId = useCallback((params: { data: ServerGcpServiceAccountProjectRole }) => {
		return params.data.project.id
	}, [])

	const columnDefs = useMemo<ColDef<ServerGcpServiceAccountProjectRole>[]>(() => {
		return [
			{
				field: 'project.project_id',
				headerName: 'Project',
				flex: 2,
			},
			{
				field: 'project.priority',
				headerName: 'Project Priority',
				hide: !showAccountPriorityDrawer,
				cellRenderer: (params: { data?: ServerGcpServiceAccountProjectRole }) => {
					if (params.data?.project.priority) {
						return <AccountPriorityNameTag priority={params.data.project.priority} />
					} else {
						return <AccountPriorityNameTag priority={AccountPriorityEnum.UNKNOWN} />
					}
				},
				filterValueGetter: (params: { data?: ServerGcpServiceAccountProjectRole }) => {
					return params.data?.project.priority
						? AccountPriorityTypeMap[params.data.project.priority]
						: AccountPriorityTypeMap[AccountPriorityEnum.UNKNOWN]
				},
				flex: 1,
			},
			// TODO: add this back once we have the creation date of the project and not the orm object.
			// {
			// 	field: 'project.created_at',
			// 	headerName: 'Created At',
			// 	flex: 1,
			// 	filter: 'agDateColumnFilter',
			// 	cellRenderer: (params: { data?: ServerGcpServiceAccountProjectRole }) => {
			// 		if (params.data?.project.created_at) {
			// 			return new Date(params.data.project.created_at).toISOString().split('T')[0]
			// 		}
			// 		return ''
			// 	},
			// 	valueFormatter: (params: { value: string }) => {
			// 		if (params.value) {
			// 			return new Date(params.value).toISOString().split('T')[0]
			// 		}
			// 		return ''
			// 	},
			// },
		]
	}, [showAccountPriorityDrawer])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<ServerGcpServiceAccountProjectRole>) => {
			setRowData(gcpServiceAccountRolesMappings)
			gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
		},
		[gcpServiceAccountRolesMappings],
	)

	return (
		<div className="w-full h-full ag-theme-alpine">
			<AgGridReact
				className="ag-theme-alpine w-full h-full overflow-x-auto"
				getRowId={getRowId}
				ref={gridRef}
				rowData={rowData}
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
