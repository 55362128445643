import { z } from 'zod'
import { DateSchema } from '../../common.ts'
import { TagSchema } from '../../tags.ts'
import { AccountPriorityEnum } from '../../environments/accountPriorityEnum.ts'

// AzureRoleAssignment schema
export const ServerAzureRoleAssignmentSchema = z.object({
	id: z.string(),
	name: z.string(),
	type: z.string(),
	scope: z.string(),
	role_definition_id: z.string(),
	principal_id: z.string(),
	principal_type: z.string(),
	created_on: DateSchema,
	updated_on: DateSchema,
	created_by: z.string().nullish(),
	updated_by: z.string().nullish(),
})

// AzureRoleDefinition schema
export const ServerAzureRoleDefinitionSchema = z.object({
	id: z.string(),
	name: z.string(),
	type: z.string(),
	role_name: z.string(),
	description: z.string(),
	role_type: z.string(),
	permissions: z.array(z.record(z.unknown())),
	assignable_scopes: z.array(z.string()),
	created_on: DateSchema,
	updated_on: DateSchema,
})

export type ServerAzureRoleDefinitionInput = z.input<typeof ServerAzureRoleDefinitionSchema>

// SubscriptionPolicies schema
export const ServerSubscriptionPoliciesSchema = z.object({
	location_placement_id: z.string().nullish(),
	quota_id: z.string().nullish(),
	spending_limit: z.string().nullish(),
})

// AzureSubscription schema
export const ServerAzureSubscriptionSchema = z.object({
	id: z.string().nullish(),
	subscription_id: z.string().nullish(),
	display_name: z.string().nullish(),
	state: z.string().nullish(),
	subscription_policies: ServerSubscriptionPoliciesSchema.nullish(),
	authorization_source: z.string().nullish(),
	priority: z.nativeEnum(AccountPriorityEnum).nullish(),
})

export type ServerAzureSubscriptionInput = z.input<typeof ServerAzureSubscriptionSchema>
export type ServerAzureSubscription = z.infer<typeof ServerAzureSubscriptionSchema>

// AzureManagementGroup schema
export const ServerAzureManagementGroupSchema = z.object({
	id: z.string().nullish(),
	type: z.string().nullish(),
	name: z.string().nullish(),
	tenant_id: z.string().nullish(),
	display_name: z.string().nullish(),
	subscriptions: z.array(ServerAzureSubscriptionSchema).nullish(),
})

export type ServerAzureManagementGroup = z.infer<typeof ServerAzureManagementGroupSchema>

// DetailedAzureRoleAssignment schema
export const ServerDetailedAzureRoleAssignmentSchema = z.object({
	role_assignment: ServerAzureRoleAssignmentSchema,
	role_definition: ServerAzureRoleDefinitionSchema,
	scope_type: z.string(),
	subscription: ServerAzureSubscriptionSchema.nullish(),
	management_group: ServerAzureManagementGroupSchema.nullish(),
	tags: z.array(TagSchema).nullish(),
})

export type ServerDetailedAzureRoleAssignment = z.infer<typeof ServerDetailedAzureRoleAssignmentSchema>
