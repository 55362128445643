import { Node, NodeProps } from '@xyflow/react'
import { GithubOutlined } from '@ant-design/icons'
import { ServerGithubRepository } from '../../../../schemas/identities/githubUserSchema.ts'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode.tsx'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type GithubRepositoriesNodeType = Node<{ repositories: ServerGithubRepository[] }, 'githubRepositories'>
type GithubRepositoriesNodeProps = NodeProps<GithubRepositoriesNodeType>

export const GithubRepositoriesNode = ({ data: { repositories } }: GithubRepositoriesNodeProps) => {
	const badgeTooltipContent = repositories.map((repo) => <span key={repo.name}>{repo.name}</span>)
	return (
		<BaseEnvironmentNode label="GitHub Repositories" icon={<GithubOutlined />}>
			<NodeGroupBadge
				count={repositories.length}
				nodeColorType="environment"
				tooltipContent={badgeTooltipContent}
			/>
		</BaseEnvironmentNode>
	)
}
