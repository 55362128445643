import { ServerIdentity } from '../../../schemas/identity.ts'
import { getPriorityColor } from '../../../utils/styleUtils.ts'
import { IssueIcon } from '../../../components/common/IssueIcon.tsx'
import { IssueNameMap } from '../../../schemas/issue.ts'
import { Link, useSearch } from '@tanstack/react-router'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { filterOpenIssues, issuePrioritySorter } from '../../../utils/issueUtils.ts'
import cx from 'classnames'
import { RoutePaths } from '../../RoutePaths.tsx'
import { useMemo } from 'react'
import { AIRemediationTag } from '../../../components/common/AIRemediationTag.tsx'
import { themeColors } from '../../../utils/colorUtils.ts'

export const SidePanelIssues = ({ identity }: { identity: ServerIdentity }) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	const { issueId: currentIssueId } = useSearch({ from: RoutePaths.Identity })
	const sortedOpenIssues = useMemo(() => filterOpenIssues(identity.issues).toSorted(issuePrioritySorter), [identity])
	if (!sortedOpenIssues.length) {
		return 'Identity has no open issues.'
	}

	return (
		<div
			className="flex flex-col gap-2 bg-surface-primary p-2 rounded border border-border-primary"
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			{sortedOpenIssues.map((issue) => {
				const issueSelected = issue.id === currentIssueId
				const issueClassName = cx('p-2 rounded flex items-center gap-2 transition-colors min-h-11 border', {
					'bg-surface-secondary border-border-primary': !issueSelected,
					'bg-surface-tertiary font-medium border-border-secondary': issueSelected,
				})

				return (
					<Link
						key={issue.id}
						className={issueClassName}
						from={RoutePaths.Identity}
						search={(prev) => ({ ...prev, issueId: issue.id })}
						onMouseEnter={() => {
							setHighlightedNodes([{ type: 'issue', id: issue.id }])
						}}
					>
						<div className="w-1 h-full" style={{ backgroundColor: getPriorityColor(issue.priority) }}>
							&nbsp;
						</div>
						<IssueIcon name={issue.issue_name} color={themeColors.textIcon.primary} />
						<span className="grow">{issue.issue_name ? IssueNameMap[issue.issue_name] : 'N/A'}</span>
						<AIRemediationTag issue={issue} tooltipText="Remediate this issue with Token AI" />
					</Link>
				)
			})}
		</div>
	)
}
