import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { ServerSalesforcePermissionSet } from '../../../../schemas/identities/salesforce/salesforcePermissionSetSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type SalesforcePermissionSetNodeType = Node<
	{ permissionSet: ServerSalesforcePermissionSet },
	'salesforcePermissionSet'
>
type SalesforcePermissionSetNodeProps = NodeProps<SalesforcePermissionSetNodeType>

export const SalesforcePermissionSetNode = ({ data: { permissionSet } }: SalesforcePermissionSetNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) =>
			highlightedNode.type === 'salesforcePermissionSet' && highlightedNode.id === permissionSet?.id,
	)

	return (
		<BasePermissionNode
			label="Permission Set"
			name={{ text: permissionSet.name }}
			risks={permissionSet.tags}
			isHighlighted={isHighlighted}
		/>
	)
}
