import { Edge, getConnectedEdges, Node } from '@xyflow/react'
import {
	entries,
	flatMap,
	groupBy,
	mapValues,
	sumBy,
	List,
	isEmpty,
	find,
	has,
	uniq,
	values as lodashValues,
	filter,
	last,
} from 'lodash'
import { ServerIdentity, ServerIdentityUsageLogsGrouped } from '../../../../schemas/identity'
import {
	BareNodesColumnsType,
	BareNodeType,
	IdentityGraphNodeType,
	UsageGraphCredentialNodeType,
} from '../identityGraphTypes'
import { EdgeType, getConnectedNodes, getEdge } from './nodesAndEdges'
import { AssociationNodeType } from '../common/AssociationNode.tsx'
import { UsageAssociationItem } from '../../../../schemas/identities/usageAssociationItemSchema'
import { ServerUsageLogGrouped } from '../../../../schemas/identities/groupedUsageLogsSchema'
import { IdentityNodeType } from '../common/IdentityNode'
import { OktaUserNodeType } from '../okta/OktaUserNode'
import { OwnershipNodeType } from '../common/OwnershipNode'
import { formatBigNumber } from '../../../../utils/numberUtils'
import { ActionNodeType } from '../common/ActionNode'
import { themeColors } from '../../../../utils/colorUtils.ts'

const USAGE_HOVER_STYLES = {
	EDGE: {
		REGULAR: {
			stroke: themeColors.border.tertiary,
			transition: 'stroke 1.2s ease, stroke-width 1.2s ease',
			strokeWidth: 1,
		},
		HIGHLIGHTED: {
			strokeWidth: 2,
			transition: 'stroke 1.2s ease, stroke-width 1.2s ease',
		},
		BLURRED: {
			filter: 'blur(2px)',
			stroke: themeColors.border.tertiary,
			strokeWidth: 1,
		},
	},
	NODE: {
		BLURRED: {
			filter: 'blur(2px)',
		},
	},
}

const IdentityNodeId = '1-0'

const CredentialNodesTypes: IdentityGraphNodeType['type'][] = [
	'accessKey',
	'snowflakeLogin',
	'gcpAccessKey',
	'entraIdCredential',
	'atlassianOAuthToken',
	'atlassianAdminApiKey',
	'atlassianApiToken',
]

export const GROUP_THRESHOLD = 3

export const usageNodeLogicalTypeToColumnId = {
	association: 0,
	accessKey: 1,
	action: 2,
}

const usageIdentityNodeRowIndex = 0
export const usageIdentityNodeId = `${usageNodeLogicalTypeToColumnId.accessKey}-${usageIdentityNodeRowIndex}`
const usageAccessKeyNodeRowIndex = 1
export const usageAccessKeyNodeId = `${usageNodeLogicalTypeToColumnId.accessKey}-${usageAccessKeyNodeRowIndex}`

export const usageLabel = {
	xOffset: -23,
	scaleFactor: 30,
	keysOffset: 15,
	stepSize: 60,
}

type GetNodesAndEdgesArgs = {
	identity: ServerIdentity
	groupedUsageLogs?: ServerUsageLogGrouped[]
	getCredentialsNodes: (
		groupedLogs: ServerUsageLogGrouped[],
		identity: ServerIdentity,
	) => UsageGraphCredentialNodeType[]
}

export function getUsageNodesAndEdges({
	identity,
	groupedUsageLogs,
	getCredentialsNodes,
}: GetNodesAndEdgesArgs): [BareNodesColumnsType[], Array<EdgeType>] {
	const edges: Array<EdgeType> = []

	const identityNodes: Array<
		BareNodeType<IdentityNodeType> | BareNodeType<OktaUserNodeType> | BareNodeType<OwnershipNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: usageIdentityNodeId,
		},
	]

	const groupedLogs = groupedUsageLogs || []

	if (!groupedUsageLogs || isEmpty(groupedLogs)) {
		return [[{ yPosition: 'top', nodes: identityNodes }], edges]
	}

	const credentialsNodes: UsageGraphCredentialNodeType[] = getCredentialsNodes(groupedLogs, identity)

	edges.push(
		getEdge({
			source: usageIdentityNodeId,
			target: usageAccessKeyNodeId,
			sourceHandle: 'bottom',
			targetHandle: 'top',
			animated: true,
		}),
	)

	const associationNodes: BareNodeType<AssociationNodeType>[] = []

	const groupedAssociations = getGroupedAssociations(groupedLogs)
	const totalAssociations = Object.keys(groupedAssociations).length

	const isSingleAccessKey = credentialsNodes.length === 1

	groupedLogs.forEach((group, groupIndex) => {
		const keysOffset = isSingleAccessKey
			? 0
			: groupIndex === 0
				? usageLabel.keysOffset * 2 * (credentialsNodes.length - 1)
				: -usageLabel.keysOffset * 2 * (credentialsNodes.length - 1)

		Object.entries(groupedAssociations).forEach(([associationKey, value], associationIndex) => {
			const associationNodeData: BareNodeType<AssociationNodeType> = {
				type: 'association',
				data: value,
				id: `${usageNodeLogicalTypeToColumnId.association}-${associationIndex}`,
			}

			let existedNode = find(associationNodes, ({ data }) => data?.name === associationKey)

			if (!existedNode) {
				associationNodes.push(associationNodeData)
				existedNode = associationNodeData
			}

			if (
				has(credentialsNodes, groupIndex) &&
				find(groupedLogs, { key: group.key })?.associations.some((association) =>
					association.name.includes(associationKey),
				)
			) {
				const startPosition = -(usageLabel.scaleFactor * totalAssociations)
				const verticalPosition = keysOffset + startPosition + associationIndex * usageLabel.stepSize
				const labelTransform = `translate(${usageLabel.xOffset}%, ${verticalPosition}%)`

				edges.push(createEdgeConfig(undefined, existedNode, credentialsNodes[groupIndex], labelTransform))
			}
		})
	})

	associationNodes.forEach((node: BareNodeType<AssociationNodeType>) => {
		edges.forEach((edge) => {
			const nodeEdges = filter(edges, { source: node.id })
			const isLastEdge = edge === last(nodeEdges)

			if (isLastEdge) {
				edge.label = `${formatBigNumber(node.data.total_events_count)} Events`
			}
		})
	})

	const actionNodes: BareNodeType<ActionNodeType>[] = []
	const distinctActions = uniq(flatMap(groupedLogs, (record) => flatMap(lodashValues(record.events))))

	distinctActions?.forEach((action, actionIndex) => {
		const actionNodeData: BareNodeType<ActionNodeType> = {
			type: 'action',
			data: { name: action },
			id: `${usageNodeLogicalTypeToColumnId.action}-${actionIndex}`,
		}

		actionNodes.push(actionNodeData)
		groupedLogs?.map((group, index) => {
			if (
				find(groupedLogs, { key: group.key })?.events &&
				Object.values(find(groupedLogs, { key: group.key })?.events || {}).some((actions) =>
					actions.includes(action),
				)
			)
				edges.push(
					getEdge({
						source: credentialsNodes[index].id,
						target: actionNodeData.id,
						sourceHandle: 'right',
						targetHandle: 'left',
						type: 'smoothstep',
						pathOptions: { borderRadius: 50 },
					}),
				)
		})
	})

	return [
		[
			{ yPosition: 'center', nodes: [...associationNodes] },
			{ yPosition: 'center', nodes: [...identityNodes, ...credentialsNodes] },
			{ yPosition: 'center', nodes: [...actionNodes] },
		],
		edges,
	]
}

export const handleUsageGraphNodeMouseClick = (
	node: IdentityGraphNodeType,
	nodes: IdentityGraphNodeType[],
	edges: Edge[],
	groupedUsageLogs: ServerIdentityUsageLogsGrouped,
	updateEdge: (id: string, node: Edge) => void,
	updateNode: (id: string, node: IdentityGraphNodeType) => void,
) => {
	if (node.type === 'identity') {
		return
	}
	let relevantNodes: IdentityGraphNodeType[] = []

	//reset graph state
	handleUsageGraphNodeMouseLeave(nodes, edges, updateEdge, updateNode)

	if (node.type === 'action') {
		const associations: string[] = getRelevantAssociationsByAction(node.data.name, groupedUsageLogs)
		relevantNodes = getRelevantNodesByNamesAndType(associations, nodes, 'association')
	} else if (node.type === 'association') {
		const actions: string[] = getRelevantActionsByAssociation(node.data.name, groupedUsageLogs)
		relevantNodes = getRelevantNodesByNamesAndType(actions, nodes, 'action')
	}

	relevantNodes = [...relevantNodes, node]

	const relevantEdges = new Set(getConnectedEdges(relevantNodes, edges).map((edge) => edge.id))

	edges.forEach((edge) => {
		if (edge.source === IdentityNodeId) {
			return //ignore identity edges
		}
		const edgeStyles = createEdgeStyles(edge, relevantEdges.has(edge.id), node.id, node.type === 'accessKey')
		updateEdge(edge.id, { ...edge, ...edgeStyles })
	})

	relevantNodes = [
		...relevantNodes,
		...getConnectedNodes(node.id, nodes, edges, ['action', 'association', ...CredentialNodesTypes]),
	]

	const relevantNodeIds = new Set([...relevantNodes.map((n) => n.id), node.id])
	nodes.forEach((n) => {
		if (!relevantNodeIds.has(n.id) && n.type !== 'identity') {
			updateNode(n.id, {
				...n,
				style: { ...USAGE_HOVER_STYLES.NODE.BLURRED },
			})
		}
	})
}

export const handleUsageGraphNodeMouseLeave = (
	nodes: IdentityGraphNodeType[],
	edges: Edge[],
	updateEdge: (id: string, node: Edge) => void,
	updateNode: (id: string, node: IdentityGraphNodeType) => void,
) => {
	edges.forEach((edge) => {
		updateEdge(edge.id, {
			...edge,
			style: USAGE_HOVER_STYLES.EDGE.REGULAR,
			labelStyle: { ...edge.labelStyle, filter: 'none' },
			labelBgStyle: { ...edge.labelBgStyle, filter: 'none' },
			animated: false || edge.id.includes(IdentityNodeId),
		})
	})

	nodes.forEach((n) => {
		updateNode(n.id, {
			...n,
			style: { filter: 'none' },
		})
	})
}

export const getGroupedAssociations = (
	groupedLogs: Array<ServerUsageLogGrouped>,
): Record<string, UsageAssociationItem> => {
	const initialGrouping = mapValues(
		groupBy(flatMap(groupedLogs, 'associations'), 'name'),
		(group: List<unknown> | null | undefined, key: string) => ({
			total_events_count: sumBy(group, 'total_events_count'),
			instances_count: sumBy(group, 'instances_count'),
			name: key,
		}),
	)

	const globalKeys = Object.keys(initialGrouping).filter((key) => key.startsWith('GLOBAL:'))

	if (globalKeys.length <= GROUP_THRESHOLD) {
		return Object.fromEntries(
			Object.entries(initialGrouping).map(([key, value]) => {
				if (key.startsWith('GLOBAL:')) {
					const newKey = key.slice('GLOBAL:'.length)
					return [newKey, { ...value, name: newKey }]
				}
				return [key, value]
			}),
		)
	}

	const nonGlobalEntries = Object.entries(initialGrouping).filter(([key]) => !key.startsWith('GLOBAL:'))
	const globalEntries = Object.entries(initialGrouping).filter(([key]) => key.startsWith('GLOBAL:'))

	const combinedGlobal: UsageAssociationItem = {
		total_events_count: globalEntries.reduce((sum, [_, value]) => sum + value.total_events_count, 0),
		instances_count: globalEntries.reduce((sum, [_, value]) => sum + value.instances_count, 0),
		name: 'GLOBAL',
	}

	// Combine non-global entries with the combined global entry
	return Object.fromEntries([...nonGlobalEntries, ['GLOBAL', combinedGlobal]])
}

export const createEdgeConfig = (
	label: string | undefined,
	existedNode: BareNodeType<AssociationNodeType>,
	targetNode: BareNodeType<Node>,
	labelTransform: string,
	animated: boolean = false,
): EdgeType =>
	getEdge({
		source: existedNode.id,
		target: targetNode.id,
		sourceHandle: 'right',
		targetHandle: 'left',
		type: 'smoothstep',
		label: label,
		labelStyle: {
			fill: themeColors.textIcon.secondary,
			fontWeight: 400,
			transform: labelTransform,
		},
		labelBgStyle: {
			transform: labelTransform,
			fill: themeColors.surface.secondary,
			stroke: themeColors.border.tertiary,
			strokeWidth: '1',
		},
		labelBgPadding: [8, 4],
		labelBgBorderRadius: 10,
		labelShowBg: true,
		pathOptions: { borderRadius: 50 },
		animated,
	})

const getRelevantActionsByAssociation = (
	associationName: string,
	usageLogs: ServerIdentityUsageLogsGrouped,
): string[] => {
	const groupedLogs: Array<ServerUsageLogGrouped> =
		Object.values(usageLogs ?? {}).find((arr) => arr?.length > 0) ?? []
	return flatMap(groupedLogs, (usageLog) =>
		flatMap(entries(usageLog.events || {}), ([key, value]) =>
			key === associationName || key.includes(associationName) || associationName.startsWith(key) ? value : [],
		),
	)
}

const getRelevantAssociationsByAction = (actionName: string, usageLogs: ServerIdentityUsageLogsGrouped): string[] => {
	const groupedLogs: Array<ServerUsageLogGrouped> =
		Object.values(usageLogs ?? {}).find((arr) => arr?.length > 0) ?? []
	return flatMap(groupedLogs, (usageLog) =>
		flatMap(entries(usageLog.events || {}), ([key, value]) => (value.includes(actionName) ? key : '')),
	)
}

const getRelevantNodesByNamesAndType = (
	names: string[],
	nodes: IdentityGraphNodeType[],
	type: 'action' | 'association',
): IdentityGraphNodeType[] =>
	nodes.filter(
		(node) =>
			node.type === type &&
			(names.includes(node.data.name) || names.some((name) => name.includes(node.data.name))),
	)

const createEdgeStyles = (edge: Edge, isRelevant: boolean, sourceNodeId: string, isReverseColor: boolean = false) => {
	if (!isRelevant)
		return {
			style: USAGE_HOVER_STYLES.EDGE.BLURRED,
			labelStyle: { ...edge.labelStyle, filter: USAGE_HOVER_STYLES.EDGE.BLURRED.filter },
			labelBgStyle: { ...edge.labelBgStyle, filter: USAGE_HOVER_STYLES.EDGE.BLURRED.filter },
			animated: false,
		}

	const strokeGradient = isReverseColor
		? { source: 'url(#associationGradient)', target: 'url(#actionGradient)' }
		: { source: 'url(#actionGradient)', target: 'url(#associationGradient)' }

	return {
		style: {
			stroke: edge.source === sourceNodeId ? strokeGradient.target : strokeGradient.source,
			...USAGE_HOVER_STYLES.EDGE.HIGHLIGHTED,
		},
		animated: true,
		labelStyle: { ...edge.labelStyle, filter: 'none' },
		labelBgStyle: { ...edge.labelBgStyle, filter: 'none' },
	}
}
