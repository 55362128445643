import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, SideBarDef } from 'ag-grid-enterprise'
import { formatDate, getCsvFileName } from '../../../../../utils.ts'
import {
	ServerSalesforceApiTotalUsageAggregatedLogReadModel,
	ServerSalesforceApiTotalUsageLog,
} from '../../../../../schemas/identities/salesforce/salesforceApiTotalUsageLogSchema.ts'
import { Button, Modal, Skeleton } from 'antd'
import ExportIcon from '../../../../../assets/export_icon_20.svg?react'
import { ServerIdentity } from '../../../../../schemas/identity.ts'
import { useIdentityUsageLogs, useIdentityUsageLogsAggregated } from '../../../../../api/identities.ts'

type Props = {
	identity: ServerIdentity
	onExportCsv: () => void
}

export const SalesforceApiTotalUsageLogsTable: React.FunctionComponent<Props> = ({ identity, onExportCsv }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [hasStartedLoadingDetails, setHasStartedLoadingDetails] = useState(false)

	const aggregatedGridRef = useRef<AgGridReact<ServerSalesforceApiTotalUsageAggregatedLogReadModel>>(null)
	const detailedGridRef = useRef<AgGridReact<ServerSalesforceApiTotalUsageLog>>(null)

	const { data: aggregatedData, isPending: isPendingAggregated } = useIdentityUsageLogsAggregated(identity.id)
	const {
		data: detailedData,
		isPending: isPendingDetailed,
		isFetching: isFetchingDetailed,
	} = useIdentityUsageLogs(identity.id, {
		enabled: hasStartedLoadingDetails,
		staleTime: 5 * 60 * 1000,
	})

	const handleOpenModal = useCallback(() => {
		setHasStartedLoadingDetails(true)
		setIsModalOpen(true)
	}, [])

	const sideBar: SideBarDef = useMemo(() => {
		return {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
				},
			],
			position: 'left',
		}
	}, [])

	const aggregatedColumnDefs = useMemo<ColDef<ServerSalesforceApiTotalUsageAggregatedLogReadModel>[]>(
		() => [
			{
				field: 'day',
				headerName: 'Day',
				flex: 1,
			},
			{ field: 'client_ip', headerName: 'Client IP', flex: 1 },
			{
				field: 'status_codes',
				headerName: 'Status Codes',
				flex: 1,
				cellRenderer: (params: { value: number[] }) => params.value.join(', '),
			},
			{ field: 'total_requests', headerName: 'Total Requests', flex: 1 },
		],
		[],
	)

	const detailedColumnDefs = useMemo<ColDef<ServerSalesforceApiTotalUsageLog>[]>(
		() => [
			{
				field: 'timestamp',
				headerName: 'Timestamp',
				flex: 1,
				cellRenderer: (params: { value: string }) => formatDate(params.value),
			},
			{ field: 'api_resource', headerName: 'API Resource', flex: 1 },
			{ field: 'client_name', headerName: 'Client Name', flex: 1 },
			{ field: 'http_method', headerName: 'HTTP Method', flex: 1 },
			{ field: 'client_ip', headerName: 'Client IP', flex: 1 },
			{ field: 'entity_name', headerName: 'Entity Name', flex: 1 },
			{
				field: 'status_code',
				headerName: 'Status Code',
				flex: 1,
				cellRenderer: (params: { value: number | null }) => params.value ?? 'N/A',
			},
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	const exportToCsv = useCallback(() => {
		onExportCsv()
		if (isModalOpen) {
			detailedGridRef?.current!.api.exportDataAsCsv({
				fileName: getCsvFileName(
					`api-total-usage-logs-${identity.salesforce_connected_application?.connected_application_id}`,
					false,
				),
			})
		} else {
			aggregatedGridRef?.current!.api.exportDataAsCsv({
				fileName: getCsvFileName(
					`api-total-usage-logs-aggregated-${identity.salesforce_connected_application?.connected_application_id}`,
					false,
				),
			})
		}
	}, [identity, isModalOpen, onExportCsv])

	if (isPendingAggregated) {
		return <Skeleton active />
	}

	const usageLogsAggregated = aggregatedData?.salesforce_connected_app_api_total_usage_logs_aggregated ?? []

	if (usageLogsAggregated.length === 0 && !isModalOpen) {
		return <div className="text-center">No API Total Usage Logs available</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<AgGridReact<ServerSalesforceApiTotalUsageAggregatedLogReadModel>
					rowData={usageLogsAggregated}
					columnDefs={aggregatedColumnDefs}
					defaultColDef={defaultColDef}
					ref={aggregatedGridRef}
					enableCellTextSelection
					className="ag-theme-alpine w-full h-full overflow-x-auto"
					rowHeight={54}
				/>
			</div>
			<div className="flex justify-between">
				<Button type="link" size="small" className="mt-4" onClick={handleOpenModal}>
					See all events
				</Button>
			</div>
			<Modal
				centered={true}
				width={1024}
				title="API Total Usage Logs"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex">
							<Button
								icon={<ExportIcon />}
								onClick={exportToCsv}
								disabled={isPendingDetailed || isFetchingDetailed}
							>
								Export CSV
							</Button>
							<Button type="primary" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					{isPendingDetailed || isFetchingDetailed ? (
						<div className="w-full flex items-center justify-center">
							<Skeleton active />
						</div>
					) : !detailedData?.salesforce_connected_app_api_total_usage_logs?.length ? (
						<div className="w-full flex items-center justify-center text-textIcon-secondary">
							No detailed logs available
						</div>
					) : (
						<AgGridReact<ServerSalesforceApiTotalUsageLog>
							className="ag-theme-alpine w-full"
							rowData={detailedData.salesforce_connected_app_api_total_usage_logs}
							rowHeight={54}
							columnDefs={detailedColumnDefs}
							defaultColDef={defaultColDef}
							enableCellTextSelection
							sideBar={sideBar}
							ref={detailedGridRef}
						/>
					)}
				</div>
			</Modal>
		</>
	)
}
