import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { ServerDetailedAzureRoleAssignment } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { useHighlightedNodesContext } from '../../../HighlightedNodesContext.tsx'
import { BasePermissionNode } from '../../common/baseNodes/BasePermissionNode.tsx'

export type EnrichedAzureRolesNodeType = Node<{ roles: ServerDetailedAzureRoleAssignment[] }, 'enrichedAzureRoles'>
type AzureRolesNodeProps = NodeProps<EnrichedAzureRolesNodeType>

export const EnrichedAzureRolesNode = ({ data: { roles } }: AzureRolesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'enrichedAzureRole')

	const groupBadgeTooltipContent = roles.map((role) => (
		<span key={`${role.role_definition.id}${role.role_assignment.id}`}>
			{role.role_definition.role_name || 'Unknown role'}
		</span>
	))

	return (
		<BasePermissionNode
			label="Azure Roles"
			isHighlighted={isHighlighted}
			icon={<TeamOutlined />}
			groupProps={{ count: roles.length, tooltipContent: groupBadgeTooltipContent }}
		/>
	)
}
