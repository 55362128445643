import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { DemoJiraRole } from '../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'
import { ServerTag } from '../../../../schemas/tags.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type DemoJiraRolesNodeType = Node<{ roles: Array<DemoJiraRole> }, 'jiraRoles'>
type DemoJiraRolesNodeProps = NodeProps<DemoJiraRolesNodeType>

export const DemoJiraRolesNode = ({ data: { roles } }: DemoJiraRolesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'jiraRole')

	const badgeTooltipContent = roles.map((role) => <span key={role.name}>{role.name}</span>)

	const uniqueRiskTags = roles.reduce((tags, role) => {
		role.tags?.forEach((riskTag) => {
			if (tags.find((tag) => tag.name === riskTag.name)) {
				return
			}

			tags.push(riskTag)
		})

		return tags
	}, [] as ServerTag[])

	return (
		<BasePermissionNode
			label="Jira Roles"
			isHighlighted={isHighlighted}
			risks={uniqueRiskTags}
			groupProps={{ count: roles.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
