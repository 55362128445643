import { useEffect, useState } from 'react'

type AIRemediationStepsProps = {
	remediationSteps: string
	containerRef: React.RefObject<HTMLDivElement>
}

export const AIRemediationStepsText = ({ remediationSteps, containerRef }: AIRemediationStepsProps) => {
	const [displayedText, setDisplayedText] = useState<string>('')

	useEffect(() => {
		let textIndex = 0
		const typingInterval = setInterval(() => {
			setDisplayedText(remediationSteps.slice(0, textIndex))
			if (textIndex >= remediationSteps.length) {
				clearInterval(typingInterval)
			}

			textIndex++
		}, 10)

		return () => {
			clearInterval(typingInterval)
		}
	}, [remediationSteps])

	useEffect(() => {
		if (!containerRef.current) {
			return
		}

		containerRef.current.scrollTop = containerRef.current.scrollHeight
	}, [displayedText])

	return <div className="grow whitespace-pre-wrap">{displayedText}</div>
}
