import { Node } from '@xyflow/react'
import { BaseNode } from './baseNodes/BaseNode.tsx'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { UsageActionItem } from '../../../../schemas/identities/usageActionItemSchema.ts'
import { UsageActionIcon } from '../../../../components/common/UsageActionIcon.tsx'

export type ActionNodeType = Node<UsageActionItem, 'action'>

export type ActionNodeProps = {
	data: UsageActionItem
}

export const ActionNode = ({ data: { name } }: ActionNodeProps) => {
	const iconWrapperClassName = `${nodeColorClassNames.action.bgHover} ${nodeColorClassNames.action.bg} ${nodeColorClassNames.action.text} border border-dsBlue-200 w-[50px] h-[50px] rounded-full flex justify-center items-center text-2xl transition-colors duration-300`

	return (
		<BaseNode labelProps={{ label: name }}>
			<div className={iconWrapperClassName}>
				<UsageActionIcon name={name} size={36} />
			</div>
		</BaseNode>
	)
}
