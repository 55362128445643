import { getRecentlyCreatedDate } from '../utils.ts'
import { StandardAces } from './adConstants'
import { adGroupsData } from './adGroupsData'
import { ServerAdUserInput } from '../../schemas/identities/activeDirectory/adUserSchema.ts'

export const adUsersData: Record<string, ServerAdUserInput> = {
	JASON_NICKELSON: {
		identity_id: '312',
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1001',
		sam_account_name: 'jason.nickelson',
		distinguished_name: 'CN=JASON.NICKELSON,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Jason Nickelson',
		description: null,
		mail_address: 'jason.nickelson@synergy.com',
		enabled: true,
		last_logon: '2022-07-31T00:33:27.721732Z',
		pwd_last_set: '2022-07-31T00:33:27.721732Z',
		when_created: '2022-07-31T00:33:27.721732Z',
		inbound_permissions: [...StandardAces.DOMAIN_ADMIN_RIGHTS, ...StandardAces.FORCE_CHANGE_PASSWORD],
		outbound_permissions: [],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	DANTE_WALKER: {
		identity_id: '313',
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1002',
		sam_account_name: 'dante.walker',
		distinguished_name: 'CN=DANTE.WALKER,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Dante Walker',
		description: null,
		mail_address: 'dante.walker@synergy.com',
		enabled: true,
		// original Date.now() or getRecentlyCreatedDate() become strings in the new schema
		last_logon: String(Date.now()),
		pwd_last_set: String(Date.now()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [...StandardAces.FORCE_CHANGE_PASSWORD],
		outbound_permissions: [],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	ALEX_SIGDAG: {
		identity_id: '311',
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1003',
		sam_account_name: 'alex.sigdag',
		distinguished_name: 'CN=ALEX.SIGDAG,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Alex Sigdag',
		description: null,
		mail_address: 'alex.sigdag@synergy.com',
		enabled: true,
		last_logon: '2024-02-22T12:32:36.000000Z',
		pwd_last_set: '2024-01-21T12:32:36.000000Z',
		when_created: '2024-01-21T12:32:36.000000Z',
		inbound_permissions: [],
		outbound_permissions: [...StandardAces.FORCE_CHANGE_PASSWORD],
		groups: [
			{
				...adGroupsData.SQL_WRITE_ACCESS,
			},
			{
				...adGroupsData.DOMAIN_USERS,
			},
			{
				...adGroupsData.DOMAIN_ADMINS,
			},
			{
				...adGroupsData.DNS_ADMINS,
			},
		],
		ownership_logs: [
			{
				event_id: '4720',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1013',
				actor_name: 'george.brown',
				event_count: 1,
				time_created: '2024-01-23T12:32:36.000000Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '113',
				system_created_at: '2024-01-23T12:32:36.000000Z',
				system_updated_at: '2024-01-23T12:32:36.000000Z',
			},
			{
				event_id: '4722',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1013',
				actor_name: 'george.brown',
				event_count: 1,
				time_created: '2024-01-23T12:32:36.000000Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '114',
				system_created_at: '2024-01-23T12:32:36.000000Z',
				system_updated_at: '2024-01-23T12:32:36.000000Z',
			},
		],
		demo_endpoints_dependencies: [
			{
				host_name: 'SYNPC34',
				source_ip: '10.0.1.56',
				destination: 'SPLUNKPRD2$',
				destination_ip: '10.0.4.63',
				last_login: '2024-02-20T15:30:00.000Z',
			},
			{
				host_name: 'ElasticIT7',
				source_ip: '10.0.1.21',
				destination: 'SQLDEVTEST$',
				destination_ip: '10.0.2.10',
				last_login: '2024-02-20T15:30:00.000Z',
			},
		],
		demo_servers_dependencies: [],
	},

	GEORGE_BROWN: {
		identity_id: '316',
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1013',
		sam_account_name: 'george.brown',
		distinguished_name: 'CN=GEORGE.BROWN,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'George Brown',
		description: null,
		mail_address: 'george.brown@synergy.com',
		enabled: true,
		last_logon: '2024-02-23T12:32:36.000000Z',
		pwd_last_set: '2024-02-21T12:32:36.000000Z',
		when_created: '2024-02-21T12:32:36.000000Z',
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
			{
				...adGroupsData.DOMAIN_ADMINS,
			},
			{
				...adGroupsData.SQL_WRITE_ACCESS,
			},
		],
		ownership_logs: [
			{
				event_id: '4720',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1014',
				actor_name: 'joseph.villanueva',
				event_count: 1,
				time_created: '2024-02-22T12:32:36.000000Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '115',
				system_created_at: '2024-02-22T12:32:36.000000Z',
				system_updated_at: '2024-02-22T12:32:36.000000Z',
			},
			{
				event_id: '4722',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1014',
				actor_name: 'joseph.villanueva',
				event_count: 1,
				time_created: '2024-02-22T12:32:36.000000Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '116',
				system_created_at: '2024-02-22T12:32:36.000000Z',
				system_updated_at: '2024-02-22T12:32:36.000000Z',
			},
		],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},
	JOSEPH_VILLANUEVA: {
		identity_id: '312',
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1014',
		sam_account_name: 'joseph.villanueva',
		distinguished_name: 'CN=JOSEPH.VILLANUEVA,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Joseph Villanueva',
		description: null,
		mail_address: 'joseph.villanueva@synergy.com',
		enabled: true,
		last_logon: '2022-08-31T00:33:27.721732Z',
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: '2022-07-31T00:33:27.721732Z',
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
			{
				...adGroupsData.DNS_ADMINS,
			},
			{
				...adGroupsData.SQL_WRITE_ACCESS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	AMY_ALEXANDER: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1004',
		sam_account_name: 'amy.alexander',
		distinguished_name: 'CN=AMY.ALEXANDER,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Amy Alexander',
		description: null,
		mail_address: 'amy.alexander@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	LAURA_ZAMORA: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1005',
		sam_account_name: 'laura.zamora',
		distinguished_name: 'CN=LAURA.ZAMORA,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Laura Zamora',
		description: null,
		mail_address: 'laura.zamora@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	MARY_CHAPMAN: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1006',
		sam_account_name: 'mary.chapman',
		distinguished_name: 'CN=MARY.CHAPMAN,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Mary Chapman',
		description: null,
		mail_address: 'mary.chapman@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	PRISCILLA_DAVIS: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1007',
		sam_account_name: 'priscilla.davis',
		distinguished_name: 'CN=PRISCILLA.DAVIS,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Priscilla Davis',
		description: null,
		mail_address: 'priscilla.davis@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	ELIZABETH_TRUJILLO: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1008',
		sam_account_name: 'elizabeth.trujillo',
		distinguished_name: 'CN=ELIZABETH.TRUJILLO,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Elizabeth Trujillo',
		description: null,
		mail_address: 'elizabeth.trujillo@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	BRIAN_ESPINOZA: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1009',
		sam_account_name: 'brian.espinoza',
		distinguished_name: 'CN=BRIAN.ESPINOZA,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Brian Espinoza',
		description: null,
		mail_address: 'brian.espinoza@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	MARIA_GREEN: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1010',
		sam_account_name: 'maria.green',
		distinguished_name: 'CN=MARIA.GREEN,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Maria Green',
		description: null,
		mail_address: 'maria.green@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	CHARLES_CARROLL: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1011',
		sam_account_name: 'charles.carroll',
		distinguished_name: 'CN=CHARLES.CARROLL,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'Charles Carroll',
		description: null,
		mail_address: 'charles.carroll@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	JOHN_BEARD: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1012',
		sam_account_name: 'john.beard',
		distinguished_name: 'CN=JOHN.BEARD,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'John Beard',
		description: null,
		mail_address: 'john.beard@synergy.com',
		enabled: true,
		last_logon: String(getRecentlyCreatedDate()),
		pwd_last_set: String(getRecentlyCreatedDate()),
		when_created: String(getRecentlyCreatedDate()),
		inbound_permissions: [],
		outbound_permissions: [],
		groups: [
			{
				...adGroupsData.DOMAIN_USERS,
			},
		],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	ELASTIC_BACKUP_SVC: {
		identity_id: null,
		object_sid: 'S-1-5-21-4283586694-1852547664-606598350-1109',
		sam_account_name: 'elastic-backup-svc$',
		distinguished_name: 'CN=elastic-backup-svc,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'elastic-backup-svc$',
		description: null,
		mail_address: null,
		enabled: true,
		last_logon: '2023-10-26T00:33:27.721Z',
		pwd_last_set: '2023-10-26T00:33:27.721Z',
		when_created: '2023-10-26T00:33:27.721Z',
		outbound_permissions: [
			{
				right_name: 'Owns',
				is_inherited: false,
			},
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
			},
		],
		inbound_permissions: [],
		groups: [
			{
				...adGroupsData.PRM_LOG_ADMINS,
			},
		],
		ownership_logs: [
			{
				event_id: '4722',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1014',
				actor_name: 'joseph.villanueva',
				event_count: 1,
				time_created: '2024-01-23T12:32:36.000000Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '117',
				system_created_at: '2024-01-23T12:32:36.000000Z',
				system_updated_at: '2024-01-23T12:32:36.000000Z',
			},
		],
		demo_endpoints_dependencies: [
			{
				host_name: 'SYNPC53',
				source_ip: '10.0.1.86',
				destination: 'ElasticDEVSYN$',
				destination_ip: '10.0.4.76',
				last_login: '2024-02-20T15:30:00.000Z',
			},
		],
		demo_servers_dependencies: [
			{
				host_name: 'jenkins-linux-xc-ad',
				source_ip: '10.0.1.74',
				destination: 'SPLUNKPRD2',
				destination_ip: '10.0.4.63',
				last_login: '2024-12-15T15:30:00.000Z',
			},
			{
				host_name: 'nexus-mobile-prod',
				source_ip: '10.0.4.28',
				destination: 'mobilejenkinsdev',
				destination_ip: '10.0.6.3',
				last_login: '2024-12-17T15:30:00.000Z',
			},
			{
				host_name: 'HYPERV-dev-models',
				source_ip: '10.0.2.35',
				destination: 'mobilejenkinsdev',
				destination_ip: '10.0.6.3',
				last_login: '2024-12-17T15:30:00.000Z',
			},
		],
	},

	PROMETHEUS_EXPORTER: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1117',
		sam_account_name: 'prometheus-exporter$',
		distinguished_name: 'CN=prometheus-exporter,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'prometheus-exporter$',
		description: null,
		mail_address: null,
		enabled: true,
		last_logon: '2024-02-16T15:33:28.992Z',
		pwd_last_set: '2023-08-28T16:44:12.223Z',
		when_created: '2023-08-28T16:44:12.223Z',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				tags: [
					{
						id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
						name: 'DATA_ACCESS',
						impact: 3,
						source: 'TOKEN',
						probability: null,
						related_resource_type: 'ACTIVE_DIRECTORY',
					},
				],
			},
		],
		inbound_permissions: [],
		groups: [],
		ownership_logs: [
			{
				event_id: '4720',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1002',
				actor_name: 'dante.walker',
				event_count: 1,
				time_created: '2023-08-28T16:44:12.223Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '118',
				system_created_at: '2023-08-28T16:44:12.223Z',
				system_updated_at: '2023-08-28T16:44:12.223Z',
			},
		],
		demo_endpoints_dependencies: [
			{
				host_name: 'monitoring-srv-01',
				source_ip: '10.0.2.88',
				destination: 'prometheus-prod$',
				destination_ip: '10.0.4.92',
				last_login: '2024-02-20T15:30:00.000Z',
			},
		],
		demo_servers_dependencies: [],
	},

	SVC_MAILBOXSCANNER3: {
		identity_id: null,
		object_sid: 'S-1-5-21-4283586694-1852547664-606598350-1132',
		sam_account_name: 'svc-mailboxscanner3$',
		distinguished_name: 'CN=svc-mailboxscanner3,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'svc-mailboxscanner3$',
		description: null,
		mail_address: null,
		enabled: true,
		last_logon: '2023-11-26T00:33:27.721Z',
		pwd_last_set: '2023-11-26T00:33:27.721Z',
		when_created: '2023-11-26T00:33:27.721Z',
		outbound_permissions: [
			{
				right_name: 'Owns',
				is_inherited: false,
			},
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
			},
		],
		inbound_permissions: [],
		groups: [],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	AUDIT_LOG_UPLOAD: {
		identity_id: null,
		object_sid: 'S-1-5-21-4283586694-1852547664-606598350-1194',
		sam_account_name: 'AUDIT-LOG-UPLOAD$',
		distinguished_name: 'CN=AUDIT-LOG-UPLOAD,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'AUDIT-LOG-UPLOAD',
		description: null,
		mail_address: null,
		enabled: true,
		last_logon: '2022-11-26T00:33:27.721Z',
		pwd_last_set: '2022-11-26T00:33:27.721Z',
		when_created: '2022-11-26T00:33:27.721Z',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
			},
		],
		inbound_permissions: [],
		groups: [],
		ownership_logs: [],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [],
	},

	SPLUNK_FORWARDER_SVC: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1115',
		sam_account_name: 'splunk-forwarder-svc$',
		distinguished_name: 'CN=splunk-forwarder-svc,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'splunk-forwarder-svc$',
		description: null,
		mail_address: null,
		enabled: true,
		last_logon: '2024-02-15T08:45:22.331Z',
		pwd_last_set: '2023-09-15T14:22:45.892Z',
		when_created: '2023-09-15T14:22:45.892Z',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				tags: [
					{
						id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
						name: 'DATA_ACCESS',
						impact: 3,
						source: 'TOKEN',
						probability: null,
						related_resource_type: 'ACTIVE_DIRECTORY',
					},
				],
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
				tags: [
					{
						id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
						name: 'DATA_ACCESS',
						impact: 3,
						source: 'TOKEN',
						probability: null,
						related_resource_type: 'ACTIVE_DIRECTORY',
					},
				],
			},
		],
		inbound_permissions: [],
		groups: [],
		ownership_logs: [
			{
				event_id: '4720',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1001',
				actor_name: 'jason.nickelson',
				event_count: 1,
				time_created: '2023-09-15T14:22:45.892Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '119',
				system_created_at: '2023-09-15T14:22:45.892Z',
				system_updated_at: '2023-09-15T14:22:45.892Z',
			},
			{
				event_id: '4722',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1001',
				actor_name: 'jason.nickelson',
				event_count: 1,
				time_created: '2023-09-15T14:22:45.892Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '120',
				system_created_at: '2023-09-15T14:22:45.892Z',
				system_updated_at: '2023-09-15T14:22:45.892Z',
			},
		],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [
			{
				host_name: 'splunk-indexer-01',
				source_ip: '10.0.4.45',
				destination: 'SPLUNKPRD2',
				destination_ip: '10.0.4.63',
				last_login: '2024-12-16T09:15:00.000Z',
			},
		],
	},

	JENKINS_BUILDER_SVC: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1116',
		sam_account_name: 'jenkins-builder-svc$',
		distinguished_name: 'CN=jenkins-builder-svc,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'jenkins-builder-svc$',
		description: null,
		mail_address: null,
		enabled: true,
		last_logon: '2024-02-16T11:22:15.778Z',
		pwd_last_set: '2023-11-02T09:17:33.445Z',
		when_created: '2023-11-02T09:17:33.445Z',
		outbound_permissions: [
			{
				right_name: 'GenericAll',
				is_inherited: false,
				tags: [
					{
						id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
						name: 'ADMIN',
						impact: 3,
						source: 'TOKEN',
						probability: null,
						related_resource_type: 'ACTIVE_DIRECTORY',
					},
				],
			},
		],
		inbound_permissions: [],
		groups: [],
		ownership_logs: [
			{
				event_id: '4720',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1001',
				actor_name: 'jason.nickelson',
				event_count: 1,
				time_created: '2023-11-02T09:17:33.445Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '121',
				system_created_at: '2023-11-02T09:17:33.445Z',
				system_updated_at: '2023-11-02T09:17:33.445Z',
			},
		],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [
			{
				host_name: 'jenkins-master',
				source_ip: '10.0.3.22',
				destination: 'artifactory-prod',
				destination_ip: '10.0.3.45',
				last_login: '2024-12-17T08:30:00.000Z',
			},
		],
	},

	BACKUP_EXECUTOR_SVC: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1118',
		sam_account_name: 'backup-executor-svc$',
		distinguished_name: 'CN=backup-executor-svc,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'backup-executor-svc$',
		description: null,
		mail_address: null,
		enabled: true,
		last_logon: '2024-02-16T02:15:33.112Z',
		pwd_last_set: '2023-10-05T13:28:55.667Z',
		when_created: '2023-10-05T13:28:55.667Z',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				tags: [
					{
						id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
						name: 'DATA_ACCESS',
						impact: 3,
						source: 'TOKEN',
						probability: null,
						related_resource_type: 'ACTIVE_DIRECTORY',
					},
				],
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
				tags: [
					{
						id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
						name: 'DATA_ACCESS',
						impact: 3,
						source: 'TOKEN',
						probability: null,
						related_resource_type: 'ACTIVE_DIRECTORY',
					},
				],
			},
		],
		inbound_permissions: [],
		groups: [],
		ownership_logs: [
			{
				event_id: '4720',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1001',
				actor_name: 'jason.nickelson',
				event_count: 1,
				time_created: '2023-10-05T13:28:55.667Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '122',
				system_created_at: '2023-10-05T13:28:55.667Z',
				system_updated_at: '2023-10-05T13:28:55.667Z',
			},
			{
				event_id: '4722',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1001',
				actor_name: 'jason.nickelson',
				event_count: 1,
				time_created: '2023-10-05T13:28:55.667Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '123',
				system_created_at: '2023-10-05T13:28:55.667Z',
				system_updated_at: '2023-10-05T13:28:55.667Z',
			},
		],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [
			{
				host_name: 'backup-master-01',
				source_ip: '10.0.5.15',
				destination: 'storage-array-01',
				destination_ip: '10.0.5.20',
				last_login: '2024-12-17T02:00:00.000Z',
			},
		],
	},

	KAFKA_CONNECT_SVC: {
		identity_id: null,
		object_sid: 'S-1-5-21-3623811015-3361044348-30300820-1119',
		sam_account_name: 'kafka-connect-svc$',
		distinguished_name: 'CN=kafka-connect-svc,CN=USERS,DC=SYNERGY,DC=COM',
		domain: 'SYNERGY.COM',
		display_name: 'kafka-connect-svc$',
		description: null,
		mail_address: null,
		enabled: true,
		last_logon: '2024-02-16T18:42:55.445Z',
		pwd_last_set: '2023-12-10T10:55:18.334Z',
		when_created: '2023-12-10T10:55:18.334Z',
		outbound_permissions: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				tags: [
					{
						id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
						name: 'DATA_ACCESS',
						impact: 3,
						source: 'TOKEN',
						probability: null,
						related_resource_type: 'ACTIVE_DIRECTORY',
					},
				],
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
				tags: [
					{
						id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
						name: 'DATA_ACCESS',
						impact: 3,
						source: 'TOKEN',
						probability: null,
						related_resource_type: 'ACTIVE_DIRECTORY',
					},
				],
			},
		],
		inbound_permissions: [],
		groups: [],
		ownership_logs: [
			{
				event_id: '4720',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1003',
				actor_name: 'alex.sigdag',
				event_count: 1,
				time_created: '2023-12-10T10:55:18.334Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '124',
				system_created_at: '2023-12-10T10:55:18.334Z',
				system_updated_at: '2023-12-10T10:55:18.334Z',
			},
			{
				event_id: '4722',
				actor_sid: 'S-1-5-21-3623811015-3361044348-30300820-1002',
				actor_name: 'dante.walker',
				event_count: 1,
				time_created: '2023-12-10T10:55:18.334Z',
				customer_id: 'SYNERGY',
				environment_id: 'PROD',
				id: '125',
				system_created_at: '2023-12-10T10:55:18.334Z',
				system_updated_at: '2023-12-10T10:55:18.334Z',
			},
		],
		demo_endpoints_dependencies: [],
		demo_servers_dependencies: [
			{
				host_name: 'kafka-connect-01',
				source_ip: '10.0.3.75',
				destination: 'kafka-broker-1',
				destination_ip: '10.0.3.80',
				last_login: '2024-12-17T12:45:00.000Z',
			},
			{
				host_name: 'kafka-connect-02',
				source_ip: '10.0.3.76',
				destination: 'kafka-broker-2',
				destination_ip: '10.0.3.81',
				last_login: '2024-12-17T12:45:00.000Z',
			},
		],
	},
}
