import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { ServerAwsIamRoleXc } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'

export type AwsRoleNodesType = Node<{ awsIamRoleXcs: ServerAwsIamRoleXc[] }, 'awsIamRoles'>
type AwsRoleNodesProps = NodeProps<AwsRoleNodesType>

export const AwsRolesNode = ({ data: { awsIamRoleXcs } }: AwsRoleNodesProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'awsIamRole')

	const badgeTooltipContent = awsIamRoleXcs.map((awsIamRoleXc: ServerAwsIamRoleXc) => (
		<span key={awsIamRoleXc.RoleId}>{awsIamRoleXc.RoleName}</span>
	))

	return (
		<BasePermissionNode
			label="Roles"
			icon={<TeamOutlined />}
			isHighlighted={isHighlighted}
			groupProps={{ count: awsIamRoleXcs.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
