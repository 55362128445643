import { ReactNode } from 'react'
import { Tooltip } from 'antd'
import { themeColors } from '../../../../utils/colorUtils.ts'

export type NodeLabelProps = {
	label: string
	iconNode?: ReactNode
	tooltipTitle?: string
	bgColor?: string
	textColor?: string
}

export const NodeLabel = ({
	label,
	iconNode,
	tooltipTitle,
	bgColor = themeColors.surface.secondary,
	textColor = themeColors.textIcon.secondary,
}: NodeLabelProps) => {
	const labelNode = (
		<div
			className="flex gap-1 justify-center items-center rounded w-full px-1 font-medium"
			style={{ color: textColor, backgroundColor: bgColor }}
		>
			{iconNode}
			{label}
		</div>
	)

	return tooltipTitle ? (
		<Tooltip title={tooltipTitle} placement="bottom">
			{labelNode}
		</Tooltip>
	) : (
		labelNode
	)
}
