import cx from 'classnames'
import { BaseNode } from './BaseNode.tsx'
import { ComponentType, ReactNode } from 'react'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'

type BaseDependencyNodeProps = {
	isHighlighted?: boolean
	nodeLabel: string
	Icon: ComponentType
	name?: { text: string; tooltipText?: ReactNode }
}

export const BaseDependencyNode = ({ isHighlighted, nodeLabel, Icon, name }: BaseDependencyNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.dependencies.bgHover} ${nodeColorClassNames.dependencies.text} w-[50px] h-[50px] rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.dependencies.bg]: !isHighlighted,
			[nodeColorClassNames.dependencies.bgHighlighted]: isHighlighted,
		},
	)

	const iconNode = (
		<div className={iconWrapperClassName}>
			<Icon />
		</div>
	)

	return (
		<BaseNode labelProps={{ label: nodeLabel }} name={name}>
			{iconNode}
		</BaseNode>
	)
}
