import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellMouseOverEvent, ColDef, GridReadyEvent } from 'ag-grid-enterprise'
import {
	GCPPermissionUsage,
	RoleWithProjects,
	ServerGcpServiceAccountProjectRole,
} from '../../../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'
import { TagIcons } from '../../../../../components/common/TagIcons.tsx'
import { GcpProjectsTable } from './GcpProjectsTable.tsx'
import { ResourceType } from '../../../../../schemas/issue.ts'
import { Tag, TagsDisplayNameMap } from '../../../../../schemas/tags.ts'
import { JsonViewer } from '../../../../../components/common/JsonViewer.tsx'
import { removeNulls } from '../../../../../utils.ts'
import { useEntitlementShowGcpPermissionAnalysis } from '../../../../../services/auth/featureFlags.ts'

export const GcpRolesTable: React.FunctionComponent<{
	gcpServiceAccountRolesMappings: ServerGcpServiceAccountProjectRole[]
	permissionsUsage?: GCPPermissionUsage[]
	lastSelectedRole?: string
	onCellMouseOver?: (event: CellMouseOverEvent<RoleWithProjects>) => void
	onCellMouseOut?: () => void
	onGcpProjectMouseOver?: (event: CellMouseOverEvent<ServerGcpServiceAccountProjectRole>) => void
}> = ({
	gcpServiceAccountRolesMappings,
	permissionsUsage = [],
	lastSelectedRole,
	onCellMouseOver,
	onCellMouseOut,
	onGcpProjectMouseOver,
}) => {
	const gridRef = useRef<AgGridReact<RoleWithProjects>>(null)
	const [rowData, setRowData] = useState<RoleWithProjects[] | null>()
	const { isEntitled: isPermissionAnalysisEnabled } = useEntitlementShowGcpPermissionAnalysis()

	// Extract just the permission names from the usage data
	const usedPermissions = useMemo(() => permissionsUsage.map((usage) => usage.permission), [permissionsUsage])

	const highlightRow = useCallback((roleName?: string | null) => {
		if (!roleName || !gridRef.current?.api) {
			return
		}

		const rowNode = gridRef.current.api.getRowNode(roleName)
		if (!rowNode) {
			return
		}

		gridRef.current.api.ensureNodeVisible(rowNode, 'middle')
		gridRef.current.api.flashCells({ rowNodes: [rowNode] })
	}, [])

	const getRowId = useCallback((params: { data: RoleWithProjects }) => {
		return params.data.role.name
	}, [])

	// Group mappings by role
	const processRowData = useCallback((mappings: ServerGcpServiceAccountProjectRole[]) => {
		const roleMap = new Map<string, RoleWithProjects>()

		mappings.forEach((mapping) => {
			if (!roleMap.has(mapping.role.name)) {
				roleMap.set(mapping.role.name, {
					role: mapping.role,
					projects: [],
				})
			}
			roleMap.get(mapping.role.name)?.projects.push(mapping)
		})

		return Array.from(roleMap.values())
	}, [])

	const columnDefs = useMemo<ColDef<RoleWithProjects>[]>(() => {
		return [
			{
				field: 'role.title',
				headerName: 'Title',
				flex: 1,
				cellRenderer: 'agGroupCellRenderer',
				tooltipField: 'role.name',
			},
			{
				field: 'role.tags',
				headerName: 'Tags',
				valueFormatter: (params) => JSON.stringify(params.value),
				cellRenderer: (params: { data?: RoleWithProjects }) => {
					return params.data?.role.tags ? (
						<TagIcons
							tags={params.data.role.tags.map((tag) => ({
								name: tag.name as Tag,
								resourceType: ResourceType.GCP,
							}))}
						/>
					) : null
				},
				filterValueGetter: (params: { data?: RoleWithProjects }) => {
					return params.data?.role.tags?.map((tag) => TagsDisplayNameMap[tag.name as Tag])
				},
				flex: 1,
			},
			{
				headerName: 'Permissions',
				filter: false,
				sortable: false,
				valueFormatter: (params) => JSON.stringify(params.value),
				cellRenderer: (params: { data?: RoleWithProjects }) => {
					if (!params.data?.role.included_permissions) {
						return null
					}

					// If permission analysis is not enabled, just show regular permissions
					if (!isPermissionAnalysisEnabled) {
						return (
							<JsonViewer
								data={removeNulls({ permissions: params.data.role.included_permissions })}
								title={`Permissions for ${params.data.role.title}`}
							/>
						)
					}

					// If permission analysis is enabled, split into used and excess
					const rolePermissions = params.data.role.included_permissions
					const usedRolePermissions = rolePermissions.filter((p) => usedPermissions.includes(p))
					const excessRolePermissions = rolePermissions.filter((p) => !usedPermissions.includes(p))

					return (
						<JsonViewer
							data={removeNulls({
								used_permissions: usedRolePermissions,
								excess_permissions: excessRolePermissions,
							})}
							title={`Permissions for ${params.data.role.title}`}
						/>
					)
				},
				flex: 1,
			},
		]
	}, [isPermissionAnalysisEnabled, usedPermissions])

	const detailCellRenderer = useMemo(() => {
		return (props: { data: RoleWithProjects }) => (
			<div className="p-4 h-[200px]">
				<GcpProjectsTable
					gcpServiceAccountRolesMappings={props.data.projects}
					onCellMouseOver={onGcpProjectMouseOver}
					onCellMouseOut={onCellMouseOut}
				/>
			</div>
		)
	}, [onGcpProjectMouseOver, onCellMouseOut])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<RoleWithProjects>) => {
			const processedData = processRowData(gcpServiceAccountRolesMappings)
			setRowData(processedData)
			gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
			highlightRow(lastSelectedRole)
		},
		[gcpServiceAccountRolesMappings, processRowData, highlightRow, lastSelectedRole],
	)

	useEffect(() => {
		highlightRow(lastSelectedRole)
	}, [lastSelectedRole, highlightRow])

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={rowData}
				getRowId={getRowId}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				detailCellRenderer={detailCellRenderer}
				onGridReady={onGridReady}
				enableCellTextSelection
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
