import { NodeProps, Node } from '@xyflow/react'
import { IdentityActivityStatus, IdentitySourceMap, ServerIdentity } from '../../../../schemas/identity.ts'
import { BaseIdentityNode } from './baseNodes/BaseIdentityNode.tsx'
import { TagsNodeIndicator } from './TagsNodeIndicator'
import TrashIcon from '../../../../assets/trash_icon_16.svg?react'
import { PriorityTypeMap } from '../../../../schemas/issue.ts'
import { getPriorityColor, getPriorityColorLight } from '../../../../utils/styleUtils.ts'
import { filterOpenIssues, getIssuesMaxPriority } from '../../../../utils/issueUtils.ts'
import { useMemo } from 'react'

export type IdentityNodeType = Node<{ identity: ServerIdentity }, 'identity'>
type IdentityNodeProps = NodeProps<IdentityNodeType>

const getIdentityRiskLabelProps = (identity: ServerIdentity) => {
	const priority = getIssuesMaxPriority(filterOpenIssues(identity.issues))

	return {
		label: `${PriorityTypeMap[priority]} Risk`,
		bgColor: getPriorityColorLight(priority),
		textColor: getPriorityColor(priority),
	}
}

export const IdentityNode = ({ data: { identity } }: IdentityNodeProps) => {
	const labelProps = useMemo(() => {
		const isIdentityDeleted = identity.activity_status === IdentityActivityStatus.DELETED
		const label = isIdentityDeleted
			? `Deleted ${IdentitySourceMap[identity.source]}`
			: IdentitySourceMap[identity.source]
		const iconNode = isIdentityDeleted ? (
			<span>
				<TrashIcon className="text-textIcon-secondary" />
			</span>
		) : undefined

		return [
			{
				label,
				iconNode,
			},
			getIdentityRiskLabelProps(identity),
		]
	}, [identity])

	return (
		<BaseIdentityNode
			name={{
				text: identity.literal_friendly_name || identity.literal || 'Unknown identity',
				tooltipText: identity.literal,
			}}
			labelProps={labelProps}
			isMainIdentity
		>
			<TagsNodeIndicator identity={identity} keyId={'base-identity-tags'} />
		</BaseIdentityNode>
	)
}
