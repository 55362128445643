import { useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { DemoServerAwsIamIaCData } from '../../../../../schemas/identities/awsIamUserSchema'
import { CodeViewerButton } from '../../../../../components/common/CodeViewerButton'

export const IacOwnershipTable = ({ data }: { data: DemoServerAwsIamIaCData[] }) => {
	const [defaultColDef] = useState({
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	})
	const [isModalOpen, setIsModalOpen] = useState(false)

	const [columnDefs] = useState<ColDef<DemoServerAwsIamIaCData>[]>([
		{
			field: 'creator',
			headerName: 'Creator',
		},
		{
			field: 'run_day',
			headerName: 'Day',
			filter: 'agDateColumnFilter',
		},
		{
			field: 'run_id',
			headerName: 'Run ID',
		},
		{
			field: 'event_name',
			headerName: 'Event Name',
		},
		{
			field: 'description',
			headerName: 'Description',
			filter: 'agTextColumnFilter',
			cellRenderer: (params: { data: DemoServerAwsIamIaCData }) => (
				<div className="flex flex-col gap-2">
					<Tooltip title={params.data.description}>
						<div className="max-w-[200px] truncate">{params.data.description}</div>
					</Tooltip>
				</div>
			),
		},
		{
			field: 'terraform_code',
			headerName: 'Terraform Code',
			filter: false,
			cellRenderer: (params: { data: DemoServerAwsIamIaCData }) => (
				<CodeViewerButton
					code={params.data.terraform_code}
					title="Terraform"
					buttonTitle="View Terraform"
					language="hcl"
				/>
			),
		},
	])

	if (!data.length) {
		return <div className={'text-center'}>No IAC ownership data available</div>
	}

	return (
		<>
			<>
				<div className="h-64 overflow-auto">
					<AgGridReact
						className={'ag-theme-alpine w-full'}
						rowData={data}
						rowHeight={54}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
					/>
				</div>

				<div className="flex justify-between">
					<Button type="link" size="small" className="mt-4" onClick={() => setIsModalOpen(true)}>
						See all events
					</Button>
				</div>
			</>

			<Modal
				centered={true}
				width={1024}
				title={'IAC Ownership'}
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex gap-2">
							<Button type="primary" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className={'ag-theme-alpine w-full'}
						rowData={data}
						rowHeight={54}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
					/>
				</div>
			</Modal>
		</>
	)
}
