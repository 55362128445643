import SnowflakeIcon from '../../../../assets/snowflake_icon.svg?react'
import {
	EnvironmentCreateRequest,
	EnvironmentTestConnectionRequest,
	ServerEnvironments,
} from '../../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationCategory,
	EnvironmentIntegrationFormData,
} from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'
import SnowflakeGuide from '../../../../assets/integrationGuides/snowflake-integration-guide.pdf'

export const snowflakeConfig: ConnectableEnvironmentConfig = {
	key: EnvironmentType.SNOWFLAKE,
	name: 'Snowflake',
	description:
		'Link Token Security to your Snowflake accounts to map cloud identities across your cloud and SaaS applications within the Snowflake platform.',
	logo: <IntegrationIcon leftIcon={SnowflakeIcon} />,
	category: EnvironmentIntegrationCategory.CORPORATE_SASS,
	guideFileName: SnowflakeGuide,
	formFields: [
		{
			fieldType: 'simple',
			name: 'name',
			label: 'Environment Name',
			type: 'text',
			placeholder: 'Ex: my-snowflake-environment',
			required: true,
		},
		{
			fieldType: 'simple',
			name: 'accountIdentifier',
			label: 'Account Identifier',
			type: 'text',
			placeholder: 'Ex: abc12345.us-east-1',
			required: true,
		},
		{
			fieldType: 'textArea',
			name: 'privateKey',
			label: 'Private Key',
			placeholder: 'Ex: -----BEGIN PRIVATE KEY-----......-----END PRIVATE KEY-----',
			minHeight: 150,
			required: true,
			rules: [
				{
					validator: (value: string) => {
						if (!value.startsWith('-----BEGIN PRIVATE KEY-----')) {
							throw new Error('Private key must start with "-----BEGIN PRIVATE KEY-----"')
						}
						if (!value.endsWith('-----END PRIVATE KEY-----')) {
							throw new Error('Private key must end with "-----END PRIVATE KEY-----"')
						}
					},
				},
			],
		},
	],
	createEnvironmentPayload: (formData: EnvironmentIntegrationFormData): EnvironmentCreateRequest => ({
		name: formData.name as string,
		environment_type: EnvironmentType.SNOWFLAKE,
		config: {
			type: EnvironmentType.SNOWFLAKE,
			account_identifier: formData.accountIdentifier as string,
			private_key: formData.privateKey as string,
		},
	}),
	createTestConnectionPayload: (formData: EnvironmentIntegrationFormData): EnvironmentTestConnectionRequest => {
		return {
			environment_type: EnvironmentType.SNOWFLAKE,
			config: {
				type: EnvironmentType.SNOWFLAKE,
				account_identifier: formData.accountIdentifier as string,
				private_key: formData.privateKey as string,
			},
		}
	},
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.SNOWFLAKE].filter(
			(env) =>
				env.account_identifier.toLowerCase().includes(searchText.toLowerCase()) ||
				env.name.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.SNOWFLAKE].map((env) => env.name),
	logEnvironmentsCounter: () => true,
}
