import { Col, Divider, Row, Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'
import React, { useEffect, useState } from 'react'
import { BaseIdentityDetail } from '../BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { ServerAwsIamGroup } from '../../../../schemas/identities/awsIamGroupSchema.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { AccountPriorityNameTag } from '../../../common/AccountPriorityNameTag.tsx'

export const AwsIamGroupIdentity: React.FunctionComponent<{
	identity?: ServerIdentity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const awsIamGroup: ServerAwsIamGroup | null = identity?.aws_iam_group ?? null
	const accountPriority = awsIamGroup?.aws_account?.priority

	const [contextAmount, setContextAmount] = useState(0)
	useEffect(() => {
		calcAmountOfContext()
	}, [identity])

	const calcAmountOfContext = () => {
		setContextAmount(0)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Arn" value={identity?.literal} span={2} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Account" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.account_literal}>
							<div className="font-normal text-textIcon-secondary items-end truncate">
								{identity?.account_literal_friendly_name ?? identity?.account_literal}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{accountPriority && (
					<BaseIdentityDetail label="Account Priority" span={1}>
						<AccountPriorityNameTag priority={accountPriority} />
					</BaseIdentityDetail>
				)}
				<BaseIdentityDetail label="Name" value={awsIamGroup?.name} span={1} />
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.last_activity, true)}
					tooltipTitle={formatDate(identity?.last_activity)}
					span={1}
				/>
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider className="border-border-tertiary" />
						<Col className="gap-3 space-y-4">
							<Row>Context ({contextAmount})</Row>
						</Col>
					</div>
				)}
			</div>
		</>
	)
}
