import { NodeProps, Node } from '@xyflow/react'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'
import { BaseServerIssue } from '../../../../schemas/issue.ts'
import PasswordKeyIcon from '../../../../assets/access_key_password_logo.svg?react'

export type SnowflakeLoginNodeType = Node<
	{
		accessKey: string
		issueAttached?: BaseServerIssue
	},
	'snowflakeLogin'
>

type SnowflakeLoginNodeProps = NodeProps<SnowflakeLoginNodeType>

export const SnowflakeLoginNode = ({ data: { accessKey, issueAttached } }: SnowflakeLoginNodeProps) => {
	const isPassword = ['PASSWORD', 'ID_TOKEN'].includes(accessKey)

	return (
		<BaseCredentialsNode
			isHighlighted={false}
			label=""
			name={{ text: accessKey || 'Unknown login' }}
			issueAttached={issueAttached}
			isActive={true}
			icon={isPassword ? <PasswordKeyIcon /> : undefined}
		/>
	)
}
