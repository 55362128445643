import { z } from 'zod'
import { ServerOktaUserXcSchema } from '../oktaUserXcSchema.ts'
import { DateSchema } from '../../common.ts'
import { TagSchema } from '../../tags.ts'

const DemoAtlassianApiTokenSchema = z.object({
	name: z.string(),
	expires_on: DateSchema,
})

export type DemoAtlassianApiToken = z.infer<typeof DemoAtlassianApiTokenSchema>

const DemoAtlassianOAuthTokenSchema = z.object({ name: z.string(), scopes: z.array(z.string()) })
export type DemoAtlassianOAuthToken = z.infer<typeof DemoAtlassianOAuthTokenSchema>

const DemoJiraRoleSchema = z.object({ name: z.string(), tags: z.array(TagSchema).nullish() })
export type DemoJiraRole = z.infer<typeof DemoJiraRoleSchema>
export type DemoJiraRoleWithProject = DemoJiraRole & { project: string }

const DemoAtlassianChangeLogSchema = z.object({
	event_time: DateSchema,
	actor_name: z.string(),
	action: z.string(),
	attributes: z.string().nullish(),
})
export type DemoAtlassianChangeLog = z.infer<typeof DemoAtlassianChangeLogSchema>

const DemoAtlassianAggregatedUsageLogSchema = z.object({
	ip: z.string(),
	associations: z.string(),
	association_type: z.string(),
	events: z.string(),
	events_count: z.number(),
	key_name: z.string(),
	first_day: DateSchema,
	last_day: DateSchema,
})

export type DemoAtlassianAggregatedUsageLog = z.infer<typeof DemoAtlassianAggregatedUsageLogSchema>

export const DemoAtlassianUserSchema = z.object({
	name: z.string(),
	email: z.string().nullish(),
	api_tokens: z.array(DemoAtlassianApiTokenSchema).nullish(),
	admin_api_keys: z.array(DemoAtlassianApiTokenSchema).nullish(),
	oauth_tokens: z.array(DemoAtlassianOAuthTokenSchema).nullish(),
	has_console_access: z.boolean(),
	jira_project_roles: z.record(z.string(), z.array(DemoJiraRoleSchema)).nullish(),
	okta_user: z.object({ id: z.string(), name: z.string() }).nullish(),
	okta_user_xc: ServerOktaUserXcSchema.nullish(),
	demo_change_logs: z.array(DemoAtlassianChangeLogSchema).nullish(),
	demo_usage_logs: z.array(DemoAtlassianAggregatedUsageLogSchema).nullish(),
})
