import { Node, NodeProps } from '@xyflow/react'
import { GroupOutlined } from '@ant-design/icons'
import { ServerAzureManagementGroup } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { BasePermissionNode } from '../../common/baseNodes/BasePermissionNode.tsx'

export type AzureManagementGroupsNodeType = Node<
	{ managementGroups: ServerAzureManagementGroup[] },
	'azureManagementGroups'
>
type AzureManagementGroupsNodeProps = NodeProps<AzureManagementGroupsNodeType>

export const AzureManagementGroupsNode = ({ data: { managementGroups } }: AzureManagementGroupsNodeProps) => {
	const badgeTooltipContent = managementGroups.map((managementGroup, index) => (
		<span key={managementGroup.id || index}>{managementGroup.display_name || 'Unnamed management group'}</span>
	))

	return (
		<BasePermissionNode
			label="Azure Management Groups"
			icon={<GroupOutlined />}
			groupProps={{ count: managementGroups.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
