import React from 'react'
import { Tooltip } from 'antd'
import AwsIcon from '../../assets/aws_icon_16.svg?react'
import OktaIcon from '../../assets/okta_logo_16.svg?react'
import GoogleWorkspaceIcon from '../../assets/google_workspace_logo_16.svg?react'
import HibpIcon from '../../assets/hibp_logo.svg?react'
import LinkedinIcon from '../../assets/linkedin_logo.svg?react'
import JumpcloudIcon from '../../assets/jumpcloud_icon_16.svg?react'
import EntraIdIcon from '../../assets/entra_id_icon_16.svg?react'
import GcpIcon from '../../assets/gcp_icon_20.svg?react'
import GitHubIcon from '../../assets/github_icon_20.svg?react'
import SnowflakeIcon from '../../assets/snowflake_icon_20.svg?react'
import PostgresIcon from '../../assets/postgres_icon_20.svg?react'
import AzureIcon from '../../assets/azure_icon.svg?react'
import AzureDevopsIcon from '../../assets/azure_devops_logo_16.svg?react'
import KubernetesIcon from '../../assets/kubernetes_icon_20.svg?react'
import DatabricksIcon from '../../assets/databricks_icon.svg?react'
import SalesforceIcon from '../../assets/salesforce_icon.svg?react'
import ActiveDirectoryIcon from '../../assets/active_directory_icon_16.svg?react'
import AzureVm from '../../assets/azure_virtual_machine.svg?react'
import AtlassianIcon from '../../assets/attlasianIcon_blue.svg?react'
import { ResourceType as IssueSourceEnum, ResourceTypeMap } from '../../schemas/issue'
import { themeColors } from '../../utils/colorUtils.ts'
import OneLoginIcon from '../../assets/onelogin_icon.svg?react'

// Define a mapping of source enums to their corresponding icons and titles
const sourceMap: Record<
	IssueSourceEnum,
	{ icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null; title: string }
> = {
	[IssueSourceEnum.AWS]: { icon: AwsIcon, title: ResourceTypeMap.AWS },
	[IssueSourceEnum.OKTA]: { icon: OktaIcon, title: ResourceTypeMap.OKTA },
	[IssueSourceEnum.GOOGLE_WORKSPACE]: { icon: GoogleWorkspaceIcon, title: ResourceTypeMap.GOOGLE_WORKSPACE },
	[IssueSourceEnum.HIBP]: { icon: HibpIcon, title: ResourceTypeMap.HIBP },
	[IssueSourceEnum.LINKEDIN]: { icon: LinkedinIcon, title: ResourceTypeMap.LINKEDIN },
	[IssueSourceEnum.JUMPCLOUD]: { icon: JumpcloudIcon, title: ResourceTypeMap.JUMPCLOUD },
	[IssueSourceEnum.ENTRA_ID]: { icon: EntraIdIcon, title: ResourceTypeMap.ENTRA_ID },
	[IssueSourceEnum.GCP]: { icon: GcpIcon, title: ResourceTypeMap.GCP },
	[IssueSourceEnum.SNOWFLAKE]: { icon: SnowflakeIcon, title: ResourceTypeMap.SNOWFLAKE },
	[IssueSourceEnum.SALESFORCE]: { icon: SalesforceIcon, title: ResourceTypeMap.SALESFORCE },
	[IssueSourceEnum.GITHUB]: { icon: GitHubIcon, title: ResourceTypeMap.GITHUB },
	[IssueSourceEnum.RDS_POSTGRES]: { icon: PostgresIcon, title: ResourceTypeMap.RDS_POSTGRES },
	[IssueSourceEnum.CLOUDTRAIL]: { icon: AwsIcon, title: ResourceTypeMap.CLOUDTRAIL },
	[IssueSourceEnum.AZURE]: { icon: AzureIcon, title: ResourceTypeMap.AZURE },
	[IssueSourceEnum.KUBERNETES]: { icon: KubernetesIcon, title: ResourceTypeMap.KUBERNETES },
	[IssueSourceEnum.DATABRICKS]: { icon: DatabricksIcon, title: ResourceTypeMap.DATABRICKS },
	[IssueSourceEnum.AZURE_VM]: { icon: AzureVm, title: ResourceTypeMap.AZURE_VM },
	[IssueSourceEnum.AZURE_RDS_POSTGRES]: { icon: PostgresIcon, title: ResourceTypeMap.AZURE_RDS_POSTGRES },
	[IssueSourceEnum.AZURE_DEVOPS]: { icon: AzureDevopsIcon, title: ResourceTypeMap.AZURE_DEVOPS },
	[IssueSourceEnum.ACTIVE_DIRECTORY]: { icon: ActiveDirectoryIcon, title: ResourceTypeMap.ACTIVE_DIRECTORY },
	[IssueSourceEnum.ONELOGIN]: { icon: OneLoginIcon, title: ResourceTypeMap.ONELOGIN },
	[IssueSourceEnum.DEMO_ATLASSIAN]: { icon: AtlassianIcon, title: ResourceTypeMap.ATLASSIAN },
}

export const IssueSourceIcon: React.FunctionComponent<{
	source?: string | null
	size?: number
	marginBottom?: string | null
	marginLeft?: string | null
	border?: string
}> = ({ source, size = 18, marginBottom, marginLeft }) => {
	if (!source) {
		return null
	}

	const SourceIcon = sourceMap[source as IssueSourceEnum]?.icon
	const title = sourceMap[source as IssueSourceEnum]?.title || source

	// Render the icon or default content
	return (
		<Tooltip placement="topLeft" title={title}>
			{SourceIcon ? (
				<div>
					<div
						style={{
							backgroundColor: themeColors.surface.primary,
							border: `1px solid ${themeColors.border.secondary}`,
							borderRadius: '100%',
							width: size * 2,
							height: size * 2,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							margin: '0 5px',
						}}
					>
						<SourceIcon
							style={{
								width: size,
								height: size,
								...(marginBottom ? { marginBottom: marginBottom } : {}),
								...(marginLeft ? { marginLeft: marginLeft } : {}),
							}}
						/>
					</div>
				</div>
			) : (
				<div>{title}</div>
			)}
		</Tooltip>
	)
}
