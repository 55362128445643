import { Node, NodeProps } from '@xyflow/react'
import { ServerGcpRole } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type GcpRoleNodeType = Node<{ role: ServerGcpRole }, 'gcpRole'>
type GcpRoleNodeProps = NodeProps<GcpRoleNodeType>

export const GcpRoleNode = ({ data: { role } }: GcpRoleNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'gcpRole' && highlightedNode.id === role.name,
	)

	const segments = role.name.split('/')
	const displayName = segments[segments.length - 1]

	return (
		<BasePermissionNode
			label="GCP Role"
			name={{ text: role.title, tooltipText: displayName }}
			risks={role.tags}
			isHighlighted={isHighlighted}
		/>
	)
}
