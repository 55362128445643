import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button, Tooltip, Typography } from 'antd'
import { FormFieldValueRenderer } from '../components/FormFieldValueRenderer'
import { IntegrationCardButton } from '../../common/components/IntegrationCardButton.tsx'
import { IntegrationStatus } from '../../common/integrationTypes.ts'

interface SingleIntegrationGenericCardProps {
	title: string
	logo: React.ReactNode
	fields: { label: string; value: string | React.ReactNode; disableTooltip?: boolean }[]
	status?: IntegrationStatus
}

export const SingleIntegrationGenericCard: React.FC<SingleIntegrationGenericCardProps> = ({
	title,
	logo,
	fields,
	status = IntegrationStatus.ACTIVE,
}) => {
	return (
		<div className="w-[286px] p-4 bg-surface-primary rounded-lg border border-border-secondary flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-2">
					{logo}
					<Tooltip placement="topLeft" title={title}>
						<Typography.Text className="text-base font-medium overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px]">
							{title}
						</Typography.Text>
					</Tooltip>
				</div>
				<Tooltip title="If you wish to make changes, please contact the Token team.">
					<Button icon={<EditOutlined />} type="text" disabled={true} />
				</Tooltip>
			</div>
			<div className="flex flex-col gap-2">
				<div className="flex justify-between gap-4">
					{fields.map((field) => (
						<span key={`title-${field.label}`} className="flex-1 text-textIcon-secondary text-start">
							{field.label}
						</span>
					))}
				</div>
				<div className="flex justify-between gap-4">
					{fields.map((field) => (
						<FormFieldValueRenderer
							key={`value-${field.label}`}
							value={field.value}
							disableTooltip={field.disableTooltip}
						/>
					))}
				</div>
			</div>
			<IntegrationCardButton status={status} />
		</div>
	)
}
