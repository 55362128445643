import { DemoAtlassianChangeLog } from '../../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'
import { useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-enterprise'

type DemoAtlassianOwnershipTableProps = {
	data: Array<DemoAtlassianChangeLog>
}

export const DemoAtlassianOwnershipTable = ({ data }: DemoAtlassianOwnershipTableProps) => {
	const columnDefs = useMemo<ColDef<DemoAtlassianChangeLog>[]>(() => {
		return [
			{ field: 'event_time', headerName: 'Event Time', sortIndex: 0 },
			{ field: 'actor_name', headerName: 'Actor' },
			{ field: 'action', headerName: 'Action' },
			{ field: 'attributes', headerName: 'Attributes' },
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			flex: 1,
		}
	}, [])

	if (!data.length) {
		return <div className={'text-center'}>No Atlassian ownership data available</div>
	}

	return (
		<div className="h-64 overflow-auto">
			<AgGridReact
				className={'ag-theme-alpine w-full'}
				rowData={data}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				enableCellTextSelection
			/>
		</div>
	)
}
