import { FeatureId, NewFeatureAnnouncement } from '../schemas/newFeatureAnnouncement'

export const usageGraphAnnouncement: NewFeatureAnnouncement = {
	id: FeatureId.NEW_FEATURE_ANNOUNCEMENT_USAGE_GRAPH,
	title: 'New Feature: Identity Usage Graph',
	buttonTitle: 'New Usage Graph',
	description:
		"Visualize how machine and human identities are used—who's using them, from where, and for what. Spot anomalies, detect threats, and gain insights into identity and secret usage across your environment.",
	releaseDate: '2025-03-26', // ISO date string
}
