import React, { useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GetRowIdParams } from 'ag-grid-enterprise'
import { ServerSalesforceIdentityOwnershipRecordSchema } from '../../../../../schemas/identities/salesforce/salesforceOwnershipRecordSchema.ts'
import { Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../../../../utils.ts'

export const SalesforceOwnershipTable: React.FunctionComponent<{
	data: ServerSalesforceIdentityOwnershipRecordSchema[]
}> = ({ data }) => {
	const columnDefs = useMemo<ColDef<ServerSalesforceIdentityOwnershipRecordSchema>[]>(() => {
		return [
			{ field: 'owner.username', headerName: 'Username', flex: 1 },
			{ field: 'owner.id', headerName: 'User ID', flex: 1 },
			{ field: 'action_type', headerName: 'Action Type', flex: 1 },
			{
				field: 'last_modified_date',
				headerName: 'Last Modified Date',
				flex: 1,
				cellRenderer: (params: { value: string }) => (
					<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
						<div className="text-textIcon-secondary">{formatRelativeDateText(params.value, true)}</div>
					</Tooltip>
				),
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	if (!data.length) {
		return <div className={'text-center'}>No Salesforce ownership data available</div>
	}

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				rowData={data}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				getRowId={(params: GetRowIdParams<ServerSalesforceIdentityOwnershipRecordSchema>) =>
					params.data.owner.id
				}
				enableCellTextSelection
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				rowHeight={54}
			/>
		</div>
	)
}
