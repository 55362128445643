import { Link } from '@tanstack/react-router'
import { Skeleton } from 'antd'
import { useMetricsTopBar } from '../../../api/metrics.ts'
import { StatItem } from '../../../components/common/StatItem.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { RoutePaths } from '../../RoutePaths.tsx'
import { IdentityPosture } from './IdentityPosture.tsx'
import { TotalAdminsAtRisk } from './TotalAdminsAtRisk.tsx'
import { TotalIdentitiesAtRisk } from './TotalIdentitiesAtRisk.tsx'
import { TotalThreats } from './TotalThreats.tsx'

export const MetricsStatsHeader = () => {
	const { data: metricsTopBar } = useMetricsTopBar()
	if (!metricsTopBar) {
		return <Skeleton active />
	}

	return (
		<div className="bg-surface-primary border border-border-secondary flex justify-between min-h-[130px] gap-2 h-fit py-4 px-6 items-stretch rounded-lg">
			<StatItem title="Identity Posture">
				<IdentityPosture postureScore={metricsTopBar.identity_posture_score} />
			</StatItem>
			<StatItem title="Total Identities">
				{
					<Link from={RoutePaths.Metrics} to={RoutePaths.Identities}>
						{formatNumber(metricsTopBar.total_identities)}
					</Link>
				}
			</StatItem>
			<StatItem title="Total Threats">
				<TotalThreats metricsTopBar={metricsTopBar} />
			</StatItem>
			<StatItem title="Total Identities at Risk">
				<TotalIdentitiesAtRisk metricsTopBar={metricsTopBar} />
			</StatItem>
			<StatItem title="Total Admins at Risk">
				<TotalAdminsAtRisk metricsTopBar={metricsTopBar} />
			</StatItem>
		</div>
	)
}
