import { type ReactNode } from 'react'

type PageHeaderChipProps = {
	children: ReactNode
}

export const PageHeaderChip = ({ children }: PageHeaderChipProps) => (
	<div className="flex items-center text-xs gap-1 border rounded px-1 py-0.5 border-border-tertiary bg-surface-secondary">
		{children}
	</div>
)
