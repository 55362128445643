import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { ServerAwsIamRoleXc } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type AwsRoleNodeType = Node<{ awsIamRoleXc: ServerAwsIamRoleXc }, 'awsIamRole'>
type AwsRoleNodeProps = NodeProps<AwsRoleNodeType>

export const AwsRoleNode = ({ data: { awsIamRoleXc } }: AwsRoleNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'awsIamRole' && highlightedNode.id === awsIamRoleXc.RoleId,
	)

	return (
		<BasePermissionNode
			label="AWS Role"
			name={{ text: awsIamRoleXc.RoleName }}
			icon={<TeamOutlined />}
			isHighlighted={isHighlighted}
		/>
	)
}
