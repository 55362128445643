import { useMemo } from 'react'
import { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Account } from '../../../schemas/environments/accounts.ts'
import { AccountPriorityEnum, AccountPriorityTypeMap } from '../../../schemas/environments/accountPriorityEnum.ts'
import { ResourceTypeMap } from '../../../schemas/issue.ts'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { SelectAccountPriority } from './SelectAccountPriority.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'

type AccountConfigTableProps = {
	isEditing: boolean
	isFetching: boolean
	accountList: Account[]
	searchText: string
	onChangeAccountPriority: (accountToUpdate: Account, newPriority: AccountPriorityEnum) => void
}

export const AccountConfigTable = ({
	isEditing,
	isFetching,
	accountList,
	searchText,
	onChangeAccountPriority,
}: AccountConfigTableProps) => {
	const columns = useMemo(
		(): ColDef<Account>[] => [
			{
				headerName: 'Source Environment',
				flex: 0.6,
				sort: 'asc',
				sortIndex: 0,
				valueGetter: ({ data }) => (data ? ResourceTypeMap[data.environment_type] : ''),
				cellRenderer: ({ data, value }: { value: string; data: Account }) => (
					<div className="flex items-center gap-2 h-full">
						<IssueSourceIcon source={data.environment_type} size={20} />
						<span>{value}</span>
					</div>
				),
			},
			{
				field: 'name',
				headerName: 'Account name',
				flex: 0.5,
				sort: 'asc',
				sortIndex: 1,
				valueGetter: ({ data }) => (data ? data.name : ''),
				cellRenderer: ({ value }: { value: string }) => (
					<span className="flex items-center h-full">{value}</span>
				),
			},
			{
				field: 'identity_count',
				headerName: 'Identities Count',
				flex: 0.4,
				cellRenderer: ({ value }: { value: Account['identity_count'] }) => <span>{formatNumber(value)}</span>,
			},
			{
				field: 'priority',
				headerName: 'Priority',
				flex: 0.7,
				valueGetter: ({ data }) => (data ? data.priority : AccountPriorityEnum.UNKNOWN),
				filterValueGetter: ({ data }) =>
					data ? AccountPriorityTypeMap[data.priority] : AccountPriorityTypeMap[AccountPriorityEnum.UNKNOWN],
				cellRenderer: ({ data }: { data: Account }) => (
					<SelectAccountPriority
						priority={data.priority}
						onChange={(newPriority: AccountPriorityEnum) => {
							onChangeAccountPriority(data, newPriority)
						}}
						loading={isFetching}
						disabled={!isEditing}
					/>
				),
			},
		],
		[isEditing, isFetching],
	)

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowData={accountList}
			columnDefs={columns}
			quickFilterText={searchText}
			getRowId={({ data }) => data.id}
			rowHeight={50}
		/>
	)
}
