import cx from 'classnames'
import { Tag, Tooltip } from 'antd'
import { formatDate } from '../../../../../utils.ts'
import { DemoAtlassianApiToken } from '../../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'

type DemoAtlassianApiTokenPropertyProps = {
	apiToken: DemoAtlassianApiToken
}
export const DemoAtlassianApiTokenProperty = ({ apiToken }: DemoAtlassianApiTokenPropertyProps) => {
	const tagClassName = cx('text-sm', {
		'text-status-neutral': apiToken.expires_on < new Date(),
		'text-status-active': apiToken.expires_on >= new Date(),
	})

	return (
		<div className="flex items-end truncate">
			<Tooltip title={<span className="flex text-sm">Expires on: {formatDate(apiToken.expires_on)}</span>}>
				<div className="flex items-center">
					<Tag className={tagClassName}>{apiToken.name}</Tag>
				</div>
			</Tooltip>
		</div>
	)
}
