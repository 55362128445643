import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, IRowNode, CellMouseOverEvent, ICellRendererParams, GetRowIdFunc } from 'ag-grid-enterprise'
import { ServerAwsIamUserPermission } from '../../../../../schemas/identities/awsIamUserXcSchema.ts'
import { formatDate } from '../../../../../utils.ts'
import { IamUsersPoliciesTable } from './IamUsersPoliciesTable.tsx'
import { CombinedAwsIamRolePolicy } from '../../../../../schemas/identities/awsIamRoleXcSchema.ts'

export const IamUsersTable: React.FunctionComponent<{
	awsIamUsersXc?: ServerAwsIamUserPermission[] | null
	onCellMouseOver?: (event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => void
	onCellMouseOut?: () => void
	onHeaderMouseOver?: (event: CellMouseOverEvent<ServerAwsIamUserPermission>) => void
}> = ({ awsIamUsersXc, onCellMouseOver, onCellMouseOut, onHeaderMouseOver }) => {
	const gridRef = useRef<AgGridReact<ServerAwsIamUserPermission>>(null)
	const columnDefs = useMemo<ColDef<ServerAwsIamUserPermission>[]>(() => {
		return [
			{ field: 'UserName', cellRenderer: 'agGroupCellRenderer' },
			{ field: 'Arn' },
			{
				field: 'CreateDate',
				cellRenderer: (params: { data?: ServerAwsIamUserPermission; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-textIcon-secondary">{formatDate(params.data?.CreateDate)}</div>
				},
			},
		]
	}, [])

	const detailCellRenderer = useMemo(() => {
		return (params: ICellRendererParams<ServerAwsIamUserPermission>) => (
			<IamUsersPoliciesTable {...params} onCellMouseOver={onCellMouseOver} onCellMouseOut={onCellMouseOut} />
		)
	}, [onCellMouseOver, onCellMouseOut])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback(() => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const getRowId = useCallback<GetRowIdFunc<ServerAwsIamUserPermission>>(({ data }) => data.UserId || '0', [])

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={awsIamUsersXc}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				detailCellRenderer={detailCellRenderer}
				onGridReady={onGridReady}
				enableCellTextSelection
				getRowId={getRowId}
				onCellMouseOver={onHeaderMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
