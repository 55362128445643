import { AppSchema, DemoServerIdentityInput, DemoServerIssueInput } from './schemas.ts'
import {
	IssueImpactProbabilityWithCount,
	IssueImpactProbabilityWithCountSchema,
	IssueName,
	IssueStatus,
	ServerIssue,
	ServerIssueSchema,
} from '../schemas/issue.ts'
import {
	ServerIdentityUsageLogs,
	ServerIdentityUsageLogsSchema,
	ServerIdentitiesTableRow,
	ServerIdentitiesTableRowSchema,
	ServerIdentity,
	ServerIdentitySchema,
	IdentitySource,
	ServerIdentityUsageLogsGrouped,
	ServerIdentityUsageLogsGroupedSchema,
	ServerIdentityOwner,
} from '../schemas/identity.ts'
import { EnvironmentType } from '../schemas/envType.ts'
import { NotificationStatus, ServerNotification, ServerNotificationSchema } from '../schemas/notifications.ts'
import { getActorArnFriendlyName } from '../utils/awsIdentityUtils.ts'

export function getAllIssues(schema: AppSchema): ServerIssue[] {
	const rawIssues = schema.all('issue')
	const serverIssuesArray: ServerIssue[] = []
	rawIssues.models.forEach((issue) => {
		serverIssuesArray.push(ServerIssueSchema.parse(issue.attrs))
	})
	return serverIssuesArray
}

export function getAllIssueTypes(schema: AppSchema): IssueImpactProbabilityWithCount[] {
	const allIssues = getAllIssues(schema)
	return allIssues.reduce((issueTypes, issue, index) => {
		const currentIssueTypeIndex = issueTypes.findIndex(
			(issueType) => issueType.issue_source === issue.issue_source && issueType.issue_name === issue.issue_name,
		)

		if (currentIssueTypeIndex === -1) {
			return [
				...issueTypes,
				IssueImpactProbabilityWithCountSchema.parse({
					id: index.toString(),
					customer_id: '1',
					issue_name: issue.issue_name,
					issue_source: issue.issue_source,
					status_update_enabled: true,
					ignore_issue: false,
					issue_count: 1,
					days_threshold: issue.issue_name === IssueName.AccessKeyNotRotated ? 90 : null,
				}),
			]
		}

		issueTypes[currentIssueTypeIndex].issue_count++
		return issueTypes
	}, [] as IssueImpactProbabilityWithCount[])
}

function addAffectedEnvironmentToIdentity(identity: ServerIdentitiesTableRow) {
	if (!identity.affected_environments) {
		identity.affected_environments = {
			[identity.env_type as EnvironmentType]: [
				{
					account_db_id: identity.account_literal,
					account_id: identity.account_literal,
					account_name: identity.account_literal_friendly_name,
				},
			],
		}
	}
}

function addTagsWithEnv(identityObj: DemoServerIdentityInput, identity: ServerIdentitiesTableRow) {
	// Format: {ResourceType}__{TagName}
	const tagsWithEnv: string[] = []
	if (identityObj.tags) {
		for (const tag of identityObj.tags) {
			tagsWithEnv.push(`${tag.related_resource_type}__${tag.name}`)
		}
	}
	identity.tags_with_env = tagsWithEnv
}

export function getAllStandAloneIdentities(schema: AppSchema): ServerIdentitiesTableRow[] {
	/*
	Note:
	As we built mirage on base of Issues, we use issues to get identities.
	But we also want to have identities without issues.
	To signal that an identity has no issues, we add an attribute to it; 'noIssueConnectedToIdentity'
	Then another attribute; 'identity', that will contain the identity object
	*/
	const rawIdentities = schema.all('identity')
	const serverIdentitiesMap: Map<string, ServerIdentitiesTableRow> = new Map()

	rawIdentities.models.forEach((identity) => {
		const identityObj = { ...identity.attrs } as unknown as DemoServerIdentityInput

		if (!identityObj.noIssueConnectedToIdentity) return

		identityObj.tags_names = identityObj.tags?.map((tag) => tag.name)

		const identityParsed = ServerIdentitiesTableRowSchema.parse(identityObj)
		if (!identityParsed) return
		if (!serverIdentitiesMap.has(identityParsed.literal)) {
			identityParsed.max_priority = 1
			serverIdentitiesMap.set(identityParsed.literal, identityParsed)
			addAffectedEnvironmentToIdentity(identityParsed)
			findAndAddOwners(identityParsed, identityObj)
			// Set issue_count to 0 for standalone identities
			identityParsed.issue_count = 0
		}
	})

	return [...serverIdentitiesMap.values()]
}

export function getAllIdentitiesFromIssues(schema: AppSchema): ServerIdentitiesTableRow[] {
	const rawIssues = schema.all('issue')
	const serverIdentitiesMap: Map<string, ServerIdentitiesTableRow> = new Map()

	rawIssues.models.forEach((issue) => {
		const identityObj = { ...(issue.attrs as unknown as DemoServerIssueInput).identity }

		identityObj.issues = []
		identityObj.tags_names = identityObj.tags?.map((tag) => tag.name)
		const identity = ServerIdentitiesTableRowSchema.parse(identityObj)
		const issueParsed = ServerIssueSchema.parse(issue.attrs)
		if (issueParsed.status !== IssueStatus.OPEN && issueParsed.status !== IssueStatus.IN_PROGRESS) return
		if (!identity) return

		if (!serverIdentitiesMap.has(identity.literal)) {
			serverIdentitiesMap.set(identity.literal, identity)
			addAffectedEnvironmentToIdentity(identity)
			addTagsWithEnv(identityObj, identity)
			findAndAddOwners(identity, identityObj)
			identity.issue_count = 0
			identity.issues = []
		}

		const currentIdentity = serverIdentitiesMap.get(identity.literal)!
		if (!currentIdentity.issues) {
			currentIdentity.issues = []
		}
		currentIdentity.issues.push(issueParsed)

		// Only count OPEN or IN_PROGRESS issues
		if (issueParsed.status === IssueStatus.OPEN || issueParsed.status === IssueStatus.IN_PROGRESS) {
			currentIdentity.issue_count = (currentIdentity.issue_count || 0) + 1

			// Calculate max_priority for the identity based on its issues
			// Priority is 1-4 where 4 is highest priority (most severe)
			// For each identity, we want the highest severity (highest number) among all its issues
			currentIdentity.max_priority = Math.max(issueParsed.priority ?? 1, currentIdentity.max_priority ?? 1)
		}
	})

	return [...serverIdentitiesMap.values()]
}

export function getIssueById(schema: AppSchema, id: string): ServerIssue | undefined {
	const rawIssue = schema.findBy('issue', { id })
	if (!rawIssue) return
	return ServerIssueSchema.parse(rawIssue.attrs)
}

export function getIdentityById(schema: AppSchema, id: string): ServerIdentity {
	const rawIdentities = schema.all('identity')
	const serverIdentitiesMap: Map<string, ServerIdentity> = new Map()

	rawIdentities.models.forEach((identity) => {
		const identityObj = { ...identity.attrs } as unknown as DemoServerIdentityInput

		if (identityObj.id !== id) return
		if (!identityObj.noIssueConnectedToIdentity) return

		const identityParsed = ServerIdentitySchema.parse(identityObj)
		if (!identityParsed) return
		if (!serverIdentitiesMap.has(identityParsed.literal)) {
			serverIdentitiesMap.set(identityParsed.literal, identityParsed)
		}
	})
	return [...serverIdentitiesMap.values()][0]
}

export function getIdentityByIdFromIssues(schema: AppSchema, id: string): ServerIdentity {
	const rawIssues = schema.all('issue')
	const serverIdentitiesMap: Map<string, ServerIdentity> = new Map()

	rawIssues.models.forEach((issue) => {
		const identityObj = (issue.attrs as unknown as DemoServerIssueInput).identity
		if (identityObj.id !== id) return

		identityObj.issues = []
		const identity = ServerIdentitySchema.parse(identityObj)
		const issueParsed = ServerIssueSchema.parse(issue.attrs)
		if (issueParsed.status !== IssueStatus.OPEN && issueParsed.status !== IssueStatus.IN_PROGRESS) return
		if (!identity) return
		if (!serverIdentitiesMap.has(identity.literal)) {
			serverIdentitiesMap.set(identity.literal, identity)
		}
		serverIdentitiesMap.get(identity.literal)?.issues!.push(issueParsed)
	})
	return [...serverIdentitiesMap.values()][0]
}

export function getUsageLogsByIdentityId(schema: AppSchema, id: string): ServerIdentityUsageLogs | null {
	const rawIdentity = schema.findBy('identity', { id })
	if (!rawIdentity) {
		return {}
	}

	// Parse the data with partial schema - This allows us to create identities with missing keys (of logs)
	const partialData = ServerIdentityUsageLogsSchema.partial().parse(
		(rawIdentity.attrs as unknown as DemoServerIdentityInput).demo_usage_logs || {},
	)

	// Fill in any missing keys with empty arrays after parsing
	const usageLogs = Object.fromEntries(
		Object.keys(ServerIdentityUsageLogsSchema.shape).map((key) => [
			key,
			partialData[key as keyof ServerIdentityUsageLogs] ?? [],
		]),
	) as ServerIdentityUsageLogs

	return usageLogs
}

export function getUsageLogsGroupedByIdentityId(schema: AppSchema, id: string): ServerIdentityUsageLogsGrouped | null {
	const groupedLogs = schema.findBy('usage-logs-grouped', { id })
	if (!groupedLogs) {
		// Return an object with empty arrays for all log types
		return {
			usage_logs_grouped: [],
		}
	}

	// Parse the data with partial schema - This allows us to create identities with missing keys (of logs)
	const partialData = ServerIdentityUsageLogsGroupedSchema.partial().parse(groupedLogs.attrs)

	// Fill in any missing keys with empty arrays after parsing
	const usageLogs = Object.fromEntries(
		Object.keys(ServerIdentityUsageLogsGroupedSchema.shape).map((key) => [
			key,
			partialData[key as keyof ServerIdentityUsageLogsGrouped] ?? [],
		]),
	) as ServerIdentityUsageLogsGrouped

	return usageLogs
}

export function getAllNotifications(schema: AppSchema): ServerNotification[] {
	const rawNotifications = schema.all('notification')
	const serverNotificationsArray: ServerNotification[] = []
	rawNotifications.models.forEach((notification) => {
		serverNotificationsArray.push(ServerNotificationSchema.parse(notification.attrs))
	})
	return serverNotificationsArray
}

export function updateNotificationStatus(
	schema: AppSchema,
	id: string,
	status: NotificationStatus,
): ServerNotification {
	// @ts-expect-error: playbook_id is not a property of notification - again, this is a mirage thing
	const notification = schema.findBy('notification', { playbook_id: id })
	if (!notification) {
		throw new Error(`Notification with id ${id} not found`)
	}

	notification.update({ status })
	return ServerNotificationSchema.parse(notification.attrs)
}

function findExistingOwners(identity: DemoServerIdentityInput): ServerIdentityOwner[] {
	// This entire thing is a mess, but it's a mirage thing, so we can't do much about it
	// Its the way we structured our code, and how we relay on owners being in the identity object
	// while in reality its a separate table that we join on getting all identities
	const owners: ServerIdentityOwner[] = []

	switch (identity.source) {
		case IdentitySource.SALESFORCE_USER:
		case IdentitySource.SALESFORCE_CONNECTED_APPLICATION:
			if (identity.salesforce_user?.ownership_records) {
				identity.salesforce_user.ownership_records.forEach((record) => {
					if (record.owner) {
						owners.push({
							identity_id: identity.id,
							owner: `${record.owner.first_name} ${record.owner.last_name}`,
							email: record.owner.email,
						})
					}
				})
			}
			break

		case IdentitySource.AWS_IAM_USER:
		case IdentitySource.AWS_IAM_ROLE: {
			const awsLogs = identity.aws_iam_user?.change_logs ?? identity.aws_iam_role?.change_logs ?? []
			if (awsLogs) {
				awsLogs.forEach((log) => {
					if (log.actor_arn) {
						const ownerName = log.actor_arn.split('/').pop() || log.actor_arn
						owners.push({
							identity_id: identity.id,
							owner: ownerName,
							email: `${ownerName}@example.com`,
						})
					}
				})
			}
			break
		}

		case IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL:
		case IdentitySource.ENTRA_ID_USER: {
			const entraLogs =
				identity.entra_id_service_principal?.change_logs ?? identity.entra_id_user?.change_logs ?? []
			if (entraLogs) {
				entraLogs.forEach((log) => {
					if (log.actor_principal_name) {
						owners.push({
							identity_id: identity.id,
							owner: log.actor_principal_name,
							email: log.actor_principal_name,
						})
					}
				})
			}
			break
		}

		case IdentitySource.POSTGRES_ROLE:
		case IdentitySource.DEMO_AZURE_POSTGRES_ROLE:
			if (identity.postgres_role?.change_logs?.length) {
				identity.postgres_role.change_logs.forEach((owner) => {
					const ownerName = getActorArnFriendlyName(owner.actor_arn)
					owners.push({
						identity_id: identity.id,
						owner: owner.actor_arn,
						email: ownerName,
					})
				})
			}
			break

		case IdentitySource.DEMO_ATLASSIAN_USER:
			identity.demo_atlassian_user?.demo_change_logs?.forEach((owner) => {
				owners.push({
					identity_id: identity.id,
					owner: owner.actor_name,
					email: owner.actor_name,
				})
			})
	}

	return [...new Set(owners.map((o) => JSON.stringify(o)))].map((o) => JSON.parse(o) as ServerIdentityOwner)
}

function findAndAddOwners(identity: ServerIdentitiesTableRow, identityObj: DemoServerIdentityInput) {
	if (identity.owners && identity.owners.length > 0) {
		return
	}

	const owners = findExistingOwners(identityObj)
	if (owners.length > 0) {
		identity.owners = owners.map((o) => o.owner)
	}
}
