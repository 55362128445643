import { FileTextOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { BaseNode, BaseNodeProps } from './BaseNode.tsx'
import { ReactNode } from 'react'
import { ServerTag } from '../../../../../schemas/tags.ts'
import { RiskNodeTagIcons } from '../RiskNodeTagIcons.tsx'
import { NodeGroupBadge } from '../NodeGroupBadge.tsx'

type BasePermissionNodeProps = {
	label: string
	name?: BaseNodeProps['name']
	icon?: ReactNode
	isHighlighted?: boolean
	risks?: ServerTag[] | null
	groupProps?: { count: number; tooltipContent?: ReactNode }
	children?: ReactNode
}

export const BasePermissionNode = ({
	label,
	name,
	isHighlighted,
	icon,
	risks,
	groupProps,
	children,
}: BasePermissionNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.text} w-[50px] h-[50px] rounded-full flex justify-center items-center transition-colors duration-300 text-2xl`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode labelProps={{ label }} name={name}>
			{risks && <RiskNodeTagIcons tags={risks} />}
			{children}
			{groupProps && (
				<NodeGroupBadge
					count={groupProps.count}
					nodeColorType="permission"
					tooltipContent={groupProps.tooltipContent}
					positionOnLeft={!!risks?.length}
				/>
			)}
			<div className={iconWrapperClassName}>{icon || <FileTextOutlined />}</div>
		</BaseNode>
	)
}
