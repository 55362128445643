import { Form, Input, Typography } from 'antd'
import { ConnectableCollaborationIntegrationConfig } from '../collaborationIntegrationsTypes'

const { Link } = Typography

interface JiraIntegrationModalContentProps {
	integration: ConnectableCollaborationIntegrationConfig
}

export const JiraIntegrationModalContent = ({ integration }: JiraIntegrationModalContentProps) => {
	if (!integration || !integration.formFields) return <></>
	const handleGuideDownload = () => {
		if (!integration.guideFileName) return
		const link = document.createElement('a')
		link.href = integration.guideFileName
		link.download = `${integration.key}-integration-guide.pdf`
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	return (
		<>
			<p className="text-textIcon-secondary mb-6">
				Please visit the <Link onClick={handleGuideDownload}>Integration Guide</Link> for further information
			</p>
			{integration.formFields.map((field) => (
				<Form.Item
					key={field.name}
					name={field.name}
					label={field.label}
					required={field.required}
					rules={[
						{
							required: field.required,
							message: `${field.label} is required`,
						},
						{
							whitespace: true,
							message: `${field.label} cannot be empty`,
						},
					]}
				>
					<Input
						placeholder={field.placeholder}
						type={field.fieldType === 'simple' ? field.type : undefined}
					/>
				</Form.Item>
			))}
		</>
	)
}
