import { Tooltip } from 'antd'
import {
	ServerAwsIamChangeLog,
	AwsIamChangeLogsAggregated,
} from '../../../../../schemas/identities/awsIamChangeLogSchema.ts'
import { AgGridReact } from 'ag-grid-react'
import { useMemo, useState } from 'react'
import { ColDef, IRowNode } from 'ag-grid-enterprise'
import { formatDate, formatRelativeDateText } from '../../../../../utils.ts'
import { ActorArnCell } from './ActorArnCell.tsx'
import { aggregateAwsIamChangeLogs, getActorArnFriendlyName } from '../../../../../utils/awsIdentityUtils.ts'

export const AwsIamUserChangeLogsAggregationsTable: React.FunctionComponent<{
	entries: ServerAwsIamChangeLog[]
}> = ({ entries }) => {
	const [defaultColDef] = useState({
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	})

	// Aggregated change logs
	const changeLogsAggregations: AwsIamChangeLogsAggregated[] = useMemo(
		() => aggregateAwsIamChangeLogs(entries),
		[entries],
	)

	const [aggregationsColumnDefs] = useState<ColDef<AwsIamChangeLogsAggregated>[]>([
		{
			field: 'actorArn',
			headerName: 'Actor ID',
			valueGetter: (params: { data?: AwsIamChangeLogsAggregated }) => {
				return getActorArnFriendlyName(params.data?.actorArn)
			},
			cellRenderer: (params: { data?: AwsIamChangeLogsAggregated; node: IRowNode; value: string }) => {
				return <ActorArnCell actorArn={params.data?.actorArn} value={params.value} />
			},
		},
		{
			field: 'eventNames',
			valueGetter: (params: { data?: AwsIamChangeLogsAggregated }) => {
				return params.data?.eventNames
			},
			cellRenderer: (params: { data?: AwsIamChangeLogsAggregated; node: IRowNode }) => {
				if (params.node.group) return
				return (
					<Tooltip
						placement="topLeft"
						title={<span style={{ whiteSpace: 'pre-line' }}>{params.data?.eventNames.join('\r\n')}</span>}
					>
						<div>{params.data?.eventNames.join(', ')}</div>
					</Tooltip>
				)
			},
		},
		{
			field: 'firstEventTime',
			valueGetter: (params) => formatDate(params.data?.firstEventTime),
			cellRenderer: (params: { data?: AwsIamChangeLogsAggregated; node: IRowNode }) => {
				if (params.node.group) return
				return (
					<Tooltip placement="bottomLeft" title={formatDate(params.data?.firstEventTime)}>
						<div className="text-textIcon-secondary">
							{formatRelativeDateText(params.data?.firstEventTime, true)}
						</div>
					</Tooltip>
				)
			},
		},
		{
			field: 'lastEventTime',
			valueGetter: (params) => formatDate(params.data?.lastEventTime),
			cellRenderer: (params: { data?: AwsIamChangeLogsAggregated; node: IRowNode }) => {
				if (params.node.group) return
				return (
					<Tooltip placement="bottomLeft" title={formatDate(params.data?.lastEventTime)}>
						<div className="text-textIcon-secondary">
							{formatRelativeDateText(params.data?.lastEventTime, true)}
						</div>
					</Tooltip>
				)
			},
		},
	])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			rowData={changeLogsAggregations}
			masterDetail={true}
			columnDefs={aggregationsColumnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
		/>
	)
}
