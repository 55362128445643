import { Dispatch, SetStateAction } from 'react'
import { Tooltip } from 'antd'
import { ControlButton } from '@xyflow/react'
import cx from 'classnames'

type CompactModeControlButtonProps = {
	isCompact: boolean
	setIsCompact: Dispatch<SetStateAction<boolean>>
	className?: string
}

export const CompactModeControlButton = ({ isCompact, setIsCompact, className }: CompactModeControlButtonProps) => {
	const buttonClassName = cx(className, { '!bg-dsBlue-200 hover:!bg-dsBlue-300 !border-textIcon-brand': isCompact })

	return (
		<Tooltip title={`${isCompact ? 'Disable' : 'Enable'} compact mode`} placement="right">
			<span>
				<ControlButton
					onClick={() => {
						setIsCompact((currentIsCompact) => !currentIsCompact)
					}}
					className={buttonClassName}
				>
					<span>C</span>
				</ControlButton>
			</span>
		</Tooltip>
	)
}
