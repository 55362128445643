import { z } from 'zod'
import { DateSchema } from '../../schemas/common.ts'
import { ReactNode } from 'react'

import { JiraTicketSchema } from './jiraSchemas.ts'

export enum IntegrationType {
	JIRA = 'JIRA',
	SLACK = 'SLACK',
	TEAMS = 'TEAMS',
}

export const ServerIntegrationTicketInTokenDbSchema = z.object({
	id: z.string().uuid(),
	customer_id: z.string().uuid(),
	integration_id: z.string().uuid(),
	issue_id: z.string(),
	created_at: DateSchema,
	updated_at: DateSchema,
	integration_type: z.nativeEnum(IntegrationType),
	data: JiraTicketSchema,
})

export type ServerIntegrationTicketInTokenDb = z.infer<typeof ServerIntegrationTicketInTokenDbSchema>

export type JiraFormItem = {
	label: string
	rules: { required: boolean; message: string }[]
	component: ReactNode
	initialValue?: string | null
}
