import { Node, NodeProps } from '@xyflow/react'
import { BaseNode } from './baseNodes/BaseNode'
import cx from 'classnames'
import { IdentitySource, IdentitySourceMap } from '../../../../schemas/identity'
import { ResourceType, ResourceTypeMap } from '../../../../schemas/issue.ts'
import { FunctionComponent, SVGProps } from 'react'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'

export type FederationNodeData = {
	identityId: string
	name: string
	Icon: FunctionComponent<SVGProps<SVGSVGElement>>
	resourceType: ResourceType
	identitySource: IdentitySource
}

export type FederationNodeType = Node<FederationNodeData, 'federation'>
type FederationNodeProps = NodeProps<FederationNodeType>

export const DemoFederationNode = ({ data: { name, Icon, resourceType, identitySource } }: FederationNodeProps) => {
	const iconWrapperClassName = cx(
		nodeColorClassNames.action.bgHover,
		nodeColorClassNames.action.bg,
		nodeColorClassNames.action.text,
		'border border-dsBlue-200 w-[50px] h-[50px] rounded-full flex justify-center items-center text-2xl transition-colors duration-300',
	)

	return (
		<BaseNode
			labelProps={{ label: ResourceTypeMap[resourceType] }}
			name={{ text: name, tooltipText: `Federated with: ${IdentitySourceMap[identitySource]}` }}
		>
			<div className={iconWrapperClassName}>
				<Icon width={24} height={24} />
			</div>
		</BaseNode>
	)
}
