import { ServerKubernetesResourceAccessKeys } from '../../../../../schemas/identities/kubernetes/kubernetesResourceAccessKeysSchema'
import { Tooltip, Tag } from 'antd'

export const AccessKeyTooltip = ({
	accessKey,
	onOpenChange,
}: {
	accessKey: ServerKubernetesResourceAccessKeys
	onOpenChange?: (visible: boolean) => void
}) => {
	return (
		<div className="flex items-end">
			<Tooltip
				styles={{ root: { maxWidth: '500px' } }}
				onOpenChange={onOpenChange}
				title={
					<div className="flex flex-col">
						<span className="flex text-sm">Pod Name: {accessKey?.pod_name}</span>
						<span className="flex text-sm">Container Name: {accessKey?.container_name}</span>
						<span className="flex text-sm">Secret Key: {accessKey?.secret_key}</span>
						<span className="flex text-sm">Secret Name: {accessKey?.secret_name}</span>
					</div>
				}
			>
				<div className="flex items-center">
					<Tag className="pl-1 text-status-neutral text text-sm">
						{accessKey?.access_key_id ?? 'Inactive'}
					</Tag>
				</div>
			</Tooltip>
		</div>
	)
}
