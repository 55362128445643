import { Col, Tag as TagComponent, Tooltip } from 'antd'
import React from 'react'
import { getTagDescription, Tag, TagsDisplayNameMap } from '../../schemas/tags.ts'
import { tagsIconsMap } from './IdentityTag/common.tsx'

// TODO: Remove when removing issue drawer
export const TokenTag: React.FunctionComponent<{
	tag?: Tag
	source: string | null | undefined
}> = ({ tag, source }) => {
	if (!tag) {
		return null
	}
	const TagIcon = tagsIconsMap[tag].icon
	return (
		<Col className="pl2">
			<TagComponent>
				<div className="flex items-center">
					<Tooltip title={getTagDescription(tag, source)}>
						<div className={'flex'}>
							{TagIcon ? (
								<TagIcon
									className="text-textIcon-secondary"
									style={{
										minWidth: 18,
										minHeight: 18,
										marginRight: '4px',
										marginTop: '1px',
									}}
								/>
							) : null}
							<div className="font-medium">{TagsDisplayNameMap[tag]}</div>
						</div>
					</Tooltip>
				</div>
			</TagComponent>
		</Col>
	)
}
