import { z } from 'zod'

export const ServerAzureDevopsSecretLocationSchema = z.object({
	project: z.string().nullish(),
	repository: z.string().nullish(),
	file: z.string().nullish(),
	line: z.number().nullish(),
	author: z.string().nullish(),
	commit_id: z.string().nullish(),
	email: z.string().nullish(),
	date: z.string().nullish(),
})

export const ServerAzureDevopsSecretSchema = z.object({
	key_id: z.string(),
	name: z.string().nullish(),
	source: z.string().nullish(),
	locations: z.array(ServerAzureDevopsSecretLocationSchema).default([]),
})

export type ServerAzureDevopsSecret = z.infer<typeof ServerAzureDevopsSecretSchema>
