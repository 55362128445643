import { type ThemeConfig } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'

export const pageSidebarTheme = {
	components: {
		Tooltip: {
			colorBgSpotlight: themeColors.surface.primaryDark,
			colorTextLightSolid: themeColors.textIcon.dark,
		},
		Skeleton: {
			gradientFromColor: themeColors.dsNaturals[900],
			gradientToColor: themeColors.dsNaturals[950],
		},
	},
} satisfies ThemeConfig
