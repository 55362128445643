import cx from 'classnames'
import { MetricsStatsHeader } from './MetricsStatsHeader/MetricsStatsHeader.tsx'
import { TopInsights } from './TopInsights.tsx'
import { MostWantedIdentities } from './MostWantedIdentities.tsx'
import { FindingsDistribution } from './FindingsDistribution.tsx'
import { LifecycleDistribution } from './LifecycleDistribution.tsx'
import { MonitoredIdentitiesChartV2 } from './MontioredIdentitiesChartV2.tsx'
import { ResolvedVsUnresolvedFindingsChartV2 } from '../../charts/ResolvedVsUnresolvedFindingsChartV2.tsx'
import { IdentitiesWithIssueEnvTypeDistribution } from './IdentitiesWithIssueEnvTypeDistribution.tsx'
import { IssueName } from '../../schemas/issue.ts'
import { Page } from '../../components/common/Page.tsx'
import { PageHeader } from '../../components/common/PageHeader.tsx'
import MetricsIcon from '../../assets/metrics_icon.svg?react'
import { OnboardingStateRenderer } from '../../components/OnboardingStateRenderer.tsx'

const MetricsWidgetsContainer = ({
	className,
	children,
	layout,
}: {
	className?: string
	children: React.ReactNode
	layout: 'grid' | 'flex'
}) => {
	const containerClassName = cx(
		'border border-border-secondary bg-surface-primary p-4 rounded-lg min-h-0',
		{
			'grid grid-cols-12 gap-6 auto-rows-min overflow-y-auto': layout === 'grid',
			'flex flex-col gap-4': layout === 'flex',
		},
		className,
	)

	const style = {
		boxShadow: '0px 0px 20px 0px #8B90AC1A',
	}

	return (
		<div className={containerClassName} style={style}>
			{children}
		</div>
	)
}

export const Metrics = () => (
	<Page>
		<PageHeader Icon={MetricsIcon}>Dashboard</PageHeader>
		<OnboardingStateRenderer>
			<MetricsStatsHeader />
			<div className="min-h-0 h-full grid grid-cols-4 gap-4">
				<MetricsWidgetsContainer layout="grid" className="col-span-3">
					<IdentitiesWithIssueEnvTypeDistribution
						issueName={IssueName.InactiveIdentity}
						title="Inactive Identities"
					/>
					<IdentitiesWithIssueEnvTypeDistribution
						issueName={IssueName.UnFederatedIdentity}
						title="Unfederated Identities"
					/>
					<MonitoredIdentitiesChartV2 />
					<LifecycleDistribution />
					<FindingsDistribution />
					<ResolvedVsUnresolvedFindingsChartV2 />
				</MetricsWidgetsContainer>
				<MetricsWidgetsContainer layout="flex" className="col-span-1">
					<MostWantedIdentities />
					<TopInsights growContainer />
				</MetricsWidgetsContainer>
			</div>
		</OnboardingStateRenderer>
	</Page>
)
