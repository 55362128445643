import { useEffect, useState, useCallback } from 'react'
import { NewFeatureAnnouncement, FeatureId, featureIdSchema } from '../schemas/newFeatureAnnouncement'

import { usageGraphAnnouncement } from '../features/usageGraphAnnouncement'

const NEW_FEATURE_ANNOUNCEMENT_STATE_KEY = 'new_feature_announcement_state'
const DAYS_TO_SHOW = 14

const allFeatures: Record<FeatureId, NewFeatureAnnouncement> = {
	[FeatureId.NEW_FEATURE_ANNOUNCEMENT_USAGE_GRAPH]: usageGraphAnnouncement,
}

const getLatestDismissedFeature = (): FeatureId | null => {
	try {
		const lastDismissedId = localStorage.getItem(NEW_FEATURE_ANNOUNCEMENT_STATE_KEY)

		if (!lastDismissedId) return null

		return featureIdSchema.parse(lastDismissedId)
	} catch {
		return null
	}
}

const isFeatureActive = (
	feature: NewFeatureAnnouncement,
	lastDismissedFeature: NewFeatureAnnouncement | null,
): boolean => {
	// Convert the feature's release date string to a Date object
	const releaseDate = new Date(feature.releaseDate)
	// Get current date for comparison
	const now = new Date()

	// Check if the release date is in the future
	if (now < releaseDate) {
		return false
	}

	// Calculate time difference in milliseconds between now and release date
	const diffTime = now.getTime() - releaseDate.getTime()
	// Convert milliseconds to days, rounding up to handle partial days
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

	// If the feature is too old, don't show it
	if (diffDays > DAYS_TO_SHOW) {
		return false
	}

	// If there's a dismissed feature and this feature is older or the same, don't show it
	if (lastDismissedFeature) {
		const lastDismissedDate = new Date(lastDismissedFeature.releaseDate)
		if (releaseDate <= lastDismissedDate) {
			return false
		}
	}

	return true
}

const getActiveFeature = (): NewFeatureAnnouncement | null => {
	const lastDismissedId = getLatestDismissedFeature()
	const lastDismissedFeature = lastDismissedId ? (allFeatures[lastDismissedId] ?? null) : null

	// Sort features by release date (newest first)
	const sortedFeatures = Object.values(allFeatures).toSorted(
		(a, b) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime(),
	)

	// Return the first feature that is active and newer than the last dismissed feature
	for (const feature of sortedFeatures) {
		if (isFeatureActive(feature, lastDismissedFeature)) {
			return feature
		}
	}

	return null
}

export const useActiveFeature = () => {
	const [activeFeature, setActiveFeature] = useState<NewFeatureAnnouncement | null>(null)

	// Function to update the active feature
	const updateActiveFeature = useCallback(() => {
		setActiveFeature(getActiveFeature())
	}, [getActiveFeature])

	const dismissFeature = useCallback(
		(featureId: FeatureId) => {
			try {
				const currentId = getLatestDismissedFeature()
				if (currentId !== featureId) {
					localStorage.setItem(NEW_FEATURE_ANNOUNCEMENT_STATE_KEY, featureId)
					updateActiveFeature()
				}
			} catch (error: unknown) {
				// Silently handle localStorage errors
			}
		},
		[getLatestDismissedFeature, updateActiveFeature],
	)

	// Memoize storage event handler
	const handleStorageChange = useCallback(
		(event: StorageEvent) => {
			if (event.key === NEW_FEATURE_ANNOUNCEMENT_STATE_KEY) {
				updateActiveFeature()
			}
		},
		[updateActiveFeature],
	)

	useEffect(() => {
		// Initial update
		updateActiveFeature()

		// Storage Event Listener
		// - Fires when localStorage changes in OTHER tabs/windows
		// - Does NOT fire in the same tab that made the change
		// - Keeps feature state in sync across multiple tabs
		window.addEventListener('storage', handleStorageChange)

		return () => {
			window.removeEventListener('storage', handleStorageChange)
		}
	}, [updateActiveFeature, handleStorageChange])

	return { activeFeature, dismissFeature }
}
