import { Tabs } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { IssueConfig } from './IssueConfig/IssueConfig.tsx'
import { ComponentProps, useMemo } from 'react'
import { isDemo } from '../../utils/demoUtils.ts'
import { DemoIntegrationsConfig } from './DemoIntegrationsConfig/DemoIntegrationsConfig.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { AccountConfig } from './AccountConfig/AccountConfig.tsx'
import { NavigateToBase } from '../NavigateToBase.tsx'
import { RoutePaths } from '../RoutePaths.tsx'
import { NotificationsConfig } from './NotificationsConfig/NotificationsConfig.tsx'
import { useAuth } from '../../services/auth/auth.ts'
import { PageHeader } from '../../components/common/PageHeader.tsx'
import { SettingsTabNames } from '../../schemas/settings.ts'
import { Page } from '../../components/common/Page.tsx'
import { OnboardingStateRenderer } from '../../components/OnboardingStateRenderer.tsx'

type SettingsTab = Required<ComponentProps<typeof Tabs>>['items'][number] & { key: SettingsTabNames }

export const Settings = () => {
	const { isAdmin } = useAuth()
	const { tab } = useSearch({ from: RoutePaths.Settings })
	const navigate = useNavigate({ from: RoutePaths.Settings })
	const tabItems = useMemo(() => {
		const items: SettingsTab[] = [
			{
				key: SettingsTabNames.ISSUE_CONFIG,
				label: 'Issue Configuration',
			},
			{ key: SettingsTabNames.ACCOUNT_CONFIG, label: 'Account Configuration' },
			{ key: SettingsTabNames.NOTIFICATIONS, label: 'Notifications' },
		]

		if (isDemo) {
			items.push({ key: SettingsTabNames.INTEGRATIONS, label: 'Integrations' })
		}

		return items
	}, [])

	if (!isAdmin) {
		return <NavigateToBase />
	}

	const onTabChange = (newTab: string) => {
		void navigate({ search: (prev) => ({ ...prev, tab: newTab as SettingsTabNames }) })
	}

	return (
		<Page>
			<PageHeader Icon={SettingOutlined} tabsProps={{ onChange: onTabChange, items: tabItems, activeKey: tab }}>
				Settings
			</PageHeader>
			<OnboardingStateRenderer>
				<div className="h-full overflow-y-auto relative">
					{tab === SettingsTabNames.ISSUE_CONFIG && <IssueConfig />}
					{tab === SettingsTabNames.ACCOUNT_CONFIG && <AccountConfig />}
					{tab === SettingsTabNames.INTEGRATIONS && <DemoIntegrationsConfig />}
					{tab === SettingsTabNames.NOTIFICATIONS && <NotificationsConfig />}
				</div>
			</OnboardingStateRenderer>
		</Page>
	)
}
