import { BaseNode } from '../common/baseNodes/BaseNode'
import IacCodeNodeAsset from '../../../../assets/IacCodeNodeAsset.svg?react'
import { Node } from '@xyflow/react'
import { DemoServerAwsIamIaCData } from '../../../../schemas/identities/awsIamUserSchema'
import cx from 'classnames'

type IacCodeNodeData = {
	iacCode: DemoServerAwsIamIaCData
}

export type IacCodeNodeType = Node<IacCodeNodeData, 'iacCode'>

const iconWrapperClassName = cx(
	`transition-colors duration-300 w-[50px] h-[50px] rounded-full flex justify-center items-center text-2xl`,
)

export const IacCodeNode = () => {
	return (
		<BaseNode labelProps={{ label: 'Code' }}>
			<div className={iconWrapperClassName}>
				<IacCodeNodeAsset />
			</div>
		</BaseNode>
	)
}
