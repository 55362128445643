import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, SideBarDef } from 'ag-grid-enterprise'
import { Button, Modal } from 'antd'
import ExportIcon from '../../../../../assets/export_icon_20.svg?react'
import { ServerGCPUserUsageLog } from '../../../../../schemas/identities/gcp/GcpUserUsageLogsSchema.ts'
import { formatDate, getCsvFileName } from '../../../../../utils.ts'

type Props = {
	usageLogs: ServerGCPUserUsageLog[]
	identityName: string
	onExportCsv: () => void
}

export const GCPUsageLogsTable: React.FC<Props> = ({ usageLogs, identityName, onExportCsv }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)

	const aggregatedGridRef = useRef<AgGridReact<ServerGCPUserUsageLog>>(null)
	const detailedGridRef = useRef<AgGridReact<ServerGCPUserUsageLog>>(null)

	const handleOpenModal = useCallback(() => {
		setIsModalOpen(true)
	}, [])

	const sideBar: SideBarDef = useMemo(() => {
		return {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
				},
			],
			position: 'left',
		}
	}, [])

	const columnDefs = useMemo<ColDef<ServerGCPUserUsageLog>[]>(
		() => [
			{
				field: 'date',
				headerName: 'Time',
				flex: 0.7,
				valueFormatter: (params: { value: Date }) => formatDate(params.value, false),
			},
			{ field: 'association', headerName: 'Association', flex: 0.6 },
			{
				field: 'ip',
				headerName: 'IP',
				flex: 0.6,
			},
			{
				field: 'actions',
				headerName: 'Actions',
				filterParams: {
					valueFormatter: (params: { value: string }) => {
						return params.value
					},
					keyCreator: (params: { value: string }) => {
						return params.value
					},
					flex: 1,
				},
			},
			{
				field: 'event_count',
				headerName: 'Count',
				flex: 0.5,
			},
		],
		[],
	)

	const detailedColumnDefs = useMemo<ColDef<ServerGCPUserUsageLog>[]>(
		() => [
			{
				field: 'date',
				headerName: 'Time',
				flex: 0.7,
				valueFormatter: (params: { value: Date }) => formatDate(params.value, false),
			},
			{
				field: 'ip',
				headerName: 'IP',
				flex: 0.6,
			},
			{ field: 'association', headerName: 'Association', flex: 0.6 },
			{ field: 'resource_type', headerName: 'Resource', flex: 0.6 },
			{
				field: 'actions',
				headerName: 'Actions',
				filterParams: {
					valueFormatter: (params: { value: string }) => {
						return params.value
					},
					keyCreator: (params: { value: string }) => {
						return params.value
					},
					flex: 1,
				},
			},
			{
				field: 'event_count',
				headerName: 'Count',
				flex: 0.5,
			},
			{
				field: 'user_agent',
				headerName: 'User Agent',
				flex: 0.5,
			},
			{
				field: 'access_key',
				headerName: 'Credential',
				flex: 0.5,
			},
		],
		[],
	)

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
				padding: '0 8px',
				overflow: 'hidden',
			}),
			autoHeight: true,
		}),
		[],
	)

	const exportToCsv = useCallback(() => {
		onExportCsv()
		if (isModalOpen) {
			detailedGridRef.current?.api.exportDataAsCsv({
				fileName: getCsvFileName(`gcp-usage-logs-${identityName}`, false),
			})
		} else {
			aggregatedGridRef.current?.api.exportDataAsCsv({
				fileName: getCsvFileName(`gcp-usage-logs-aggregated-${identityName}`, false),
			})
		}
	}, [isModalOpen, identityName, onExportCsv])

	if (usageLogs.length === 0 && !isModalOpen) {
		return <div className="text-center">No GCP Usage Logs available</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<AgGridReact
					rowData={usageLogs}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					ref={aggregatedGridRef}
					enableCellTextSelection
					className="ag-theme-alpine w-full h-full overflow-x-auto"
					rowHeight={54}
				/>
			</div>
			<div className="flex justify-between">
				<Button type="link" size="small" className="mt-4" onClick={handleOpenModal}>
					See all events
				</Button>
			</div>
			<Modal
				centered
				width={1024}
				title="GCP Usage Logs"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center w-full">
						<Button icon={<ExportIcon />} onClick={exportToCsv}>
							Export CSV
						</Button>
						<Button type="primary" onClick={() => setIsModalOpen(false)}>
							Done
						</Button>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					{usageLogs.length === 0 ? (
						<div className="w-full flex items-center justify-center text-textIcon-secondary">
							No detailed logs available
						</div>
					) : (
						<AgGridReact
							className="ag-theme-alpine w-full"
							rowData={usageLogs}
							rowHeight={54}
							columnDefs={detailedColumnDefs}
							defaultColDef={defaultColDef}
							enableCellTextSelection
							sideBar={sideBar}
							ref={detailedGridRef}
						/>
					)}
				</div>
			</Modal>
		</>
	)
}
