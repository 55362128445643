import { useContext } from 'react'
import { AuthContext, AuthContextState } from './AuthContext.ts'

export const useAuth = (): AuthContextState => {
	const context = useContext(AuthContext)
	if (context === null) {
		throw new Error('useAuth must be used within an AuthProvider')
	}

	return context
}
