import { ServerAwsAccountInput } from '../../schemas/environments/awsAccountSchema.ts'
import { AccountPriorityEnum } from '../../schemas/environments/accountPriorityEnum.ts'

export const awsAccountsInfo = {
	'623356180012': {
		id: '11111111-1111-1111-1111-111111111111',
		name: 'AWS Prod',
		priority: AccountPriorityEnum.HIGH,
		account_id: '623356180012',
		created_at: '2024-02-20T18:32:58.476832Z',
		updated_at: '2024-02-20T18:32:58.476832Z',
		enabled: true,
		customer_id: '11111111-1111-1111-1111-111111111111',
		is_management: false,
	},
} satisfies Record<string, ServerAwsAccountInput>
