import { ColDef, CellMouseOverEvent } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { TagIcons } from '../../../../../components/common/TagIcons.tsx'
import { ResourceType } from '../../../../../schemas/issue.ts'
import { Tag, TagsDisplayNameMap } from '../../../../../schemas/tags.ts'

export type FlattenedDelegationRow = {
	workspace_name: string
	workspace_id: string
	scope_name: string
	scope_friendly_name: string
	scope_tag: { name: string }
}

type GcpDomainWideDelegationTableProps = {
	data: FlattenedDelegationRow[]
	lastSelectedNode?: string
	onCellMouseOver?: (event: CellMouseOverEvent<FlattenedDelegationRow>) => void
	onCellMouseOut?: () => void
}

export const GcpDomainWideDelegationTable = ({
	data,
	lastSelectedNode: lastSelectedScope,
	onCellMouseOver,
	onCellMouseOut,
}: GcpDomainWideDelegationTableProps) => {
	const gridRef = useRef<AgGridReact<FlattenedDelegationRow>>(null)

	const highlightRow = useCallback((scopeName?: string | null) => {
		if (!scopeName || !gridRef.current?.api) {
			return
		}

		const rowNode = gridRef.current.api.getRowNode(scopeName)
		if (!rowNode) {
			return
		}

		gridRef.current.api.ensureNodeVisible(rowNode, 'middle')
		gridRef.current.api.flashCells({ rowNodes: [rowNode] })
	}, [])

	const getRowId = useCallback((params: { data: FlattenedDelegationRow }) => {
		return params.data.scope_name
	}, [])

	useEffect(() => {
		highlightRow(lastSelectedScope)
	}, [lastSelectedScope, highlightRow])

	const columnDefs = useMemo<ColDef<FlattenedDelegationRow>[]>(
		() => [
			{
				field: 'workspace_name',
				headerName: 'Workspace Environment',
				flex: 2,
				filterValueGetter: (params: { data?: FlattenedDelegationRow }) => {
					return params.data?.workspace_name
				},
			},
			{
				field: 'scope_name',
				headerName: 'Scope Name',
				flex: 2,
				filterValueGetter: (params: { data?: FlattenedDelegationRow }) => {
					return params.data?.scope_friendly_name
				},
			},
			{
				field: 'scope_tag',
				headerName: 'Tags',
				flex: 1,
				cellRenderer: (params: { value?: { name: string } }) => {
					if (!params.value) return null
					return (
						<TagIcons
							tags={[
								{
									name: params.value.name as Tag,
									resourceType: ResourceType.GOOGLE_WORKSPACE,
								},
							]}
						/>
					)
				},
				filterValueGetter: (params: { data?: FlattenedDelegationRow }) => {
					return TagsDisplayNameMap[params.data?.scope_tag?.name as Tag]
				},
			},
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	const onGridReady = useCallback(() => {
		highlightRow(lastSelectedScope)
	}, [highlightRow, lastSelectedScope])

	return (
		<div className="w-full h-96">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				getRowId={getRowId}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
				onCellMouseOver={onCellMouseOver}
				onCellMouseOut={onCellMouseOut}
			/>
		</div>
	)
}
