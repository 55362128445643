import { MockDemoTag } from '../../schemas/tags.ts'
import { getRecentlyCreatedDate } from '../utils.ts'
import { DemoServerIdentityInput } from '../schemas.ts'
import { ServerGCPUserUsageLogInput } from '../../schemas/identities/gcp/GcpUserUsageLogsSchema.ts'
import { ServerEndpointAccessXcInput } from '../../schemas/identities/endpointAccessXcSchema.ts'
import { ServerGCPUserChangeLogsInput } from '../../schemas/identities/gcp/gcpUserChangeLogs.ts'
import { ServerKubernetesResourceXcInput } from '../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { GcpResourceState } from '../../schemas/identities/gcp/gcpProjectXcSchema.ts'

const gcpUsageLogs: Array<ServerGCPUserUsageLogInput> = [
	{
		principal_email: 'idotest@something.com',
		actions: ['role.create', 'serviceAccounts.create'],
		resource_type: 'GCP.gce: synergy-web-server-753',
		ip: '18.211.39.125',
		is_ip: true,
		date: '2024-07-20T21:05:53Z',
		association: 'iam',
		event_count: 31,
	},
	{
		principal_email: 'idotest@something.com',
		actions: ['container.clusters.create'],
		resource_type: 'GCP.gke: synergy-prod-app-cluster',
		ip: '18.211.39.125',
		is_ip: true,
		association: 'container',
		date: '2024-07-20T21:05:53Z',
		event_count: 31,
	},
	{
		principal_email: 'idotest@something.com',
		actions: ['io.k8s.coordination.v1.leases.update'],
		resource_type: 'coordination.k8s.io/v1/namespaces/kube-system/leases/cloud-provider-extraction-migration-pt2',
		ip: '18.211.39.125',
		is_ip: true,
		association: 'container',
		date: '2024-07-20T21:05:53Z',
		event_count: 31,
	},
]

const gcpEndpointAccessXc: Array<ServerEndpointAccessXcInput> = [
	{
		device_name: "Gabriela's Mac",
		device_type: 'Desktop - MacOS',
		status: 'Active',
		endpoint_protection: false,
		ip_address: '125.17.154.214',
		first_seen: '2024-03-11 14:42:39+00:00',
		last_seen: '2024-06-08 21:04:33+00:00',
	},
	{
		device_name: "Gabriela's Dell",
		device_type: 'Desktop - Windows',
		status: 'Active',
		endpoint_protection: true,
		ip_address: '125.25.42.145',
		first_seen: '2024-02-23 08:40:08+00:00',
		last_seen: '2024-06-09 11:13:52+00:00',
	},
	{
		device_name: 'TAU Library',
		device_type: 'Desktop - Windows',
		status: 'Inactive',
		endpoint_protection: false,
		ip_address: '125.31.14.241',
		first_seen: '2024-05-12 09:14:13+00:00',
		last_seen: '2024-05-12 10:37:22+00:00',
	},
]

const gcpChangeLogs: Array<ServerGCPUserChangeLogsInput> = [
	{
		log_name: 'projects/anecdotes-dev/logs/cloudaudit.googleapis.com%2Factivity',
		event_time: '2025-01-07T14:59:55.285304Z',
		event_name: 'io.k8s.coordination.v1.leases.update',
		actor_principal_email: 'system:pdcsi-controller',
		affected_principal_email: 'bkbkb-86@new-project-sharon.iam.gserviceaccount.com',
		ip: '172.16.20.7',
		user_agent: 'csi-attacher/v0.0.0 (linux/amd64) kubernetes/$Format',
		request_parameters: JSON.stringify({
			callerIp: '172.16.20.7',
			callerSuppliedUserAgent: 'csi-attacher/v0.0.0 (linux/amd64) kubernetes/$Format',
		}),
	},
	{
		log_name: 'projects/anecdotes-dev/logs/cloudaudit.googleapis.com%2Factivity',
		event_time: '2025-01-08T10:00:05.789456Z',
		event_name: 'google.iam.admin.v1.SetIamPolicy',
		actor_principal_email: 'serviceAccount:my-robot@my-project.iam.gserviceaccount.com',
		affected_principal_email: 'user:john.doe@example.com',
		ip: '172.16.20.200',
		user_agent: 'Google-Cloud-SDK gcloud/372.0.0',
		request_parameters: JSON.stringify({
			callerIp: '172.16.20.200',
			callerSuppliedUserAgent: 'Google-Cloud-SDK gcloud/372.0.0',
		}),
	},
	{
		log_name: 'projects/anecdotes-dev/logs/cloudaudit.googleapis.com%2Factivity',
		event_time: '2025-01-09T08:15:11.456321Z',
		event_name: 'compute.instances.start',
		actor_principal_email: 'user:alice@my-domain.com',
		affected_principal_email: 'serviceAccount:compute-engine@my-project.iam.gserviceaccount.com',
		ip: '192.168.3.45',
		user_agent: 'curl/7.78.0',
		request_parameters: JSON.stringify({
			callerIp: '192.168.3.45',
			callerSuppliedUserAgent: 'curl/7.78.0',
		}),
	},
]

export const gcpKubernetesResourcesXc: Array<ServerKubernetesResourceXcInput> = [
	{
		api_version: null,
		kind: 'DaemonSet',
		metadata: {
			uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
			name: 'my-daemonset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'deprecated.daemonset.template.generation': '1',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"DaemonSet","metadata":{"annotations":{},"name":"my-daemonset","namespace":"production"},"spec":{"selector":{"matchLabels":{"name":"my-daemonset"}},"template":{"metadata":{"labels":{"name":"my-daemonset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"gke-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"gke-secret"}}}],"image":"ubuntu","name":"my-daemonset-container","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:53:42+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-daemonset-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:revisionHistoryLimit': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:deprecated.daemonset.template.generation': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:45+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:numberReady': {},
							'f:numberAvailable': {},
							'f:observedGeneration': {},
							'f:currentNumberScheduled': {},
							'f:desiredNumberScheduled': {},
							'f:updatedNumberScheduled': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754187',
			creation_timestamp: '2024-03-11 08:53:42+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			selector: {
				match_labels: {
					name: 'my-daemonset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-daemonset-container',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-daemonset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: 0,
					max_unavailable: 1,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
		},
		status: {
			conditions: null,
			number_ready: 2,
			collision_count: null,
			number_available: 2,
			number_unavailable: null,
			number_misscheduled: 0,
			observed_generation: 1,
			current_number_scheduled: 2,
			desired_number_scheduled: 2,
			updated_number_scheduled: 2,
		},
		pod_to_iam_access_keys: [
			{
				secret_name: 'gke-secret',
				pod_name: 'my-daemonset-m2d6j',
				container_name: 'my-daemonset-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
						kind: 'DaemonSet',
						name: 'my-daemonset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'monitoring-service-cluster',
	},
	{
		api_version: null,
		kind: 'StatefulSet',
		metadata: {
			uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
			name: 'my-statefulset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"StatefulSet","metadata":{"annotations":{},"name":"my-statefulset","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"name":"my-statefulset"}},"serviceName":"my-statefulset","template":{"metadata":{"labels":{"name":"my-statefulset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"gke-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"gke-secret"}}}],"image":"ubuntu","name":"my-statefulset","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:50:09+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-statefulset"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:serviceName': {},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:partition': {},
								},
							},
							'f:podManagementPolicy': {},
							'f:revisionHistoryLimit': {},
							'f:persistentVolumeClaimRetentionPolicy': {
								'.': {},
								'f:whenScaled': {},
								'f:whenDeleted': {},
							},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 09:03:21+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:readyReplicas': {},
							'f:collisionCount': {},
							'f:updateRevision': {},
							'f:currentReplicas': {},
							'f:currentRevision': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41700785',
			creation_timestamp: '2024-03-11 08:50:09+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			ordinals: null,
			replicas: 1,
			selector: {
				match_labels: {
					name: 'my-statefulset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-statefulset',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-statefulset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			service_name: 'my-statefulset',
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					partition: 0,
					max_unavailable: null,
				},
			},
			min_ready_seconds: null,
			pod_management_policy: 'OrderedReady',
			revision_history_limit: 10,
			volume_claim_templates: null,
			persistent_volume_claim_retention_policy: {
				when_scaled: 'Retain',
				when_deleted: 'Retain',
			},
		},
		status: {
			replicas: 1,
			conditions: null,
			ready_replicas: 1,
			collision_count: 0,
			update_revision: 'my-statefulset-76b644766c',
			current_replicas: 1,
			current_revision: 'my-statefulset-76b644766c',
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
		},
		pod_to_iam_access_keys: [
			{
				secret_name: 'gke-secret',
				pod_name: 'my-statefulset-0',
				container_name: 'my-statefulset',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
						kind: 'StatefulSet',
						name: 'my-statefulset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'nayak-prometheus-migration-cluster',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			name: 'test-deployment',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 7,
			annotations: {
				'deployment.kubernetes.io/revision': '7',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"test-deployment","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"test","value":"AKIA2ODYTOW1MU6FF1P4"},{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"gke-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"gke-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"production-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-25 16:08:59+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"test"}': {
													'.': {},
													'f:name': {},
													'f:value': {},
												},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-21 03:00:40+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41862200',
			creation_timestamp: '2023-10-05 09:15:30+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'test',
									value: 'AKIA2ODYTOW1MU6FF1P4',
									value_from: null,
								},
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "test-deployment-85688f7765" has successfully progressed.',
					last_update_time: '2024-03-25 16:09:01+00:00',
					last_transition_time: '2023-10-12 12:11:57+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-21 03:00:40+00:00',
					last_transition_time: '2024-04-21 03:00:40+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 7,
			unavailable_replicas: null,
		},
		pod_to_iam_access_keys: [
			{
				secret_name: null,
				pod_name: 'test-deployment-85688f7765-hfj8f',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: null,
				pod_owner_references: [
					{
						uid: '50da299b-47f4-4e28-a810-5ceccd0cde20',
						kind: 'ReplicaSet',
						name: 'test-deployment-85688f7765',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: true,
			},
		],
		cluster_literal: 'analytics-service-cluster',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'app-deployment-helm',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'gke-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'synergy-gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'synergy-gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-gke-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'synergy-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'synergy-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "app-deployment-helm-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		cluster_literal: 'synergy-prod-app-cluster',
	},
]

export const gcpServiceAccountsData = {
	'80000': {
		id: '15e81e42-3966-4b5c-98fe-8a5de45faf16',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '214211775423123440983',
		literal_friendly_name: 'Prometheus Service Discovery (PSD) connection',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.693515Z',
		last_activity: '2024-01-03T08:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: '74905faa-c95d-4f1b-950c-165772a7481c',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		account_literal: 'projects/762385290174',
		account_literal_friendly_name: 'prod-proj-eu-west1',
		affected_environments: {
			GCP: [
				{
					account_db_id: '32397bba-12ad-4dc4-a7a3-1e9ae37572ba',
					account_id: 'prometheus-sd-integration',
					account_name: 'prometheus-sd-integration',
				},
			],
		},
		gcp_service_account: {
			name: 'Prometheus Service Discovery (PSD) connection',
			last_activity: '2024-01-03T08:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '482193764502986731574',
			email: 'prometheus-service-discovery@prod-proj-eu-west1.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 2.5,
					project: {
						name: 'projects/1098658545826',
						parent: 'organizations/1035611221239',
						projectId: 'prometheus-sd-integration',
						state: GcpResourceState.ACTIVE,
						displayName: 'prometheus-sd-integration',
						createTime: '2021-09-23T16:24:17.119000Z',
						updateTime: '2021-09-23T16:24:19.189000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/compute.viewer'],
				},
			],
			gcp_project: {
				id: '3cbc33df-b393-450d-acab-cd45b07f64e8',
				name: 'projects/762385290174',
				created_at: '2024-04-11T16:37:47.900493Z',
				updated_at: '2024-04-11T16:37:47.900493Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'prod-proj-eu-west1',
				priority: 4,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f1e2d3c4-b5a6-7890-1234-567890abcdef',
					service_account_row_id: '28e1f2da-abea-410f-8512-a0776d04dc2b', // matches the service account's id
					role_row_id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
					project_row_id: '32397bba-12ad-4dc4-a7a3-1e9ae37572ba', // matches the project's id from service_account_projects_roles_xc
					deleted: false,
					role: {
						id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/compute.viewer',
						title: 'Compute Viewer',
						description: 'Read-only access to Compute Engine resources',
						included_permissions: ['compute.instances.get', 'compute.instances.list', 'compute.zones.list'],
						stage: null,
						deleted: false,
						tags: [
							{
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'DATA_ACCESS',
								impact: 3,
							},
						],
					},
					project: {
						id: '32397bba-12ad-4dc4-a7a3-1e9ae37572ba',
						name: 'projects/1098658545826',
						created_at: '2024-04-11T16:37:47.900493Z',
						updated_at: '2024-04-11T16:37:47.900493Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'prometheus-sd-integration',
						priority: 2.5,
					},
				},
			],
		},
	},
	'80001': {
		id: 'c91b3b50-0166-40d6-a785-39d64f1ccc9d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '592837461029385762914',
		literal_friendly_name: 'Terraform SA',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.702876Z',
		last_activity: '2024-01-09T08:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: '72890858-4c9d-460d-8a02-01bd75f914c6',
				created_at: '2024-05-02T05:09:17.601705Z',
				updated_at: '2024-06-24T05:11:25.279224Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
			{
				id: '8986e646-57df-4b04-83be-d17eae0663ea',
				created_at: '2024-05-02T05:09:17.703572Z',
				updated_at: '2024-06-24T05:11:25.295686Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 4,
				probability: null,
			},
			{
				id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		account_literal: 'projects/397452691483',
		account_literal_friendly_name: 'prj-terraform-gwwsa',
		affected_environments: {
			GCP: [
				{
					account_db_id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
					account_id: 'nayak-prometheus-integration',
					account_name: 'nayak-prometheus-integration',
				},
			],
		},
		gcp_service_account: {
			name: 'Terraform SA',
			last_activity: '2024-01-09T08:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '592837461029385762914',
			email: 'ajith-nayak-terraform@prj-terraform-gfxhn.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 2.5,
					project: {
						name: 'projects/1098658545826',
						parent: 'organizations/1035611221239',
						projectId: 'nayak-prometheus-integration',
						state: GcpResourceState.ACTIVE,
						displayName: 'nayak-prometheus-integration',
						createTime: '2021-09-23T16:24:17.119000Z',
						updateTime: '2021-09-23T16:24:19.189000Z',
						labels: null,
						tags: null,
					},
					roles: [
						'roles/owner',
						'projects/prj-terraform-gfxhn/roles/customCloudKmsEncrypterDecrypterForSops',
						'roles/storage.admin',
					],
				},
			],
			gcp_project: {
				id: '516f66c4-ae4b-4fe7-a408-7ad87f181ff5',
				name: 'projects/397452691483',
				created_at: '2024-04-11T16:37:47.900493Z',
				updated_at: '2024-04-11T16:37:47.900493Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'prj-terraform-gfxhn',
				priority: 2.5,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f2e3d4c5-b6a7-8901-2345-678901bcdef0',
					service_account_row_id: '2d60dfd0-52ef-4764-a67e-227a468bc601', // matches SA id
					role_row_id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
					project_row_id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
					deleted: false,
					role: {
						id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/owner',
						title: 'Owner',
						description: 'Full access to all resources',
						included_permissions: ['resourcemanager.projects.*', 'iam.serviceAccounts.*', 'storage.*'],
						stage: null,
						deleted: false,
						tags: [
							{
								id: '72890858-4c9d-460d-8a02-01bd75f914c6',
								created_at: '2024-05-02T05:09:17.601705Z',
								updated_at: '2024-06-24T05:11:25.279224Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'CREDENTIAL_EXPOSURE',
								impact: 3,
								probability: null,
							},
							{
								id: '8986e646-57df-4b04-83be-d17eae0663ea',
								created_at: '2024-05-02T05:09:17.703572Z',
								updated_at: '2024-06-24T05:11:25.295686Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'POSSIBLE_PRIVILEGE_ESCALATION',
								impact: 4,
								probability: null,
							},
							{
								id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						],
					},
					project: {
						id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
						name: 'projects/1098658545826',
						created_at: '2024-04-11T16:37:47.900493Z',
						updated_at: '2024-04-11T16:37:47.900493Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'nayak-prometheus-integration',
						priority: 2.5,
					},
				},
				{
					id: 'f3e4d5c6-b7a8-9012-3456-789012cdef01',
					service_account_row_id: '2d60dfd0-52ef-4764-a67e-227a468bc601',
					role_row_id: 'b3c4d5e6-f7a8-9012-3456-789012cdef02',
					project_row_id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
					deleted: false,
					role: {
						id: 'b3c4d5e6-f7a8-9012-3456-789012cdef02',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'projects/prj-terraform-gfxhn/roles/customCloudKmsEncrypterDecrypterForSops',
						title: 'Custom Cloud KMS Encrypter Decrypter For Sops',
						description: 'Custom role for KMS encryption/decryption operations',
						included_permissions: [
							'cloudkms.cryptoKeyVersions.useToEncrypt',
							'cloudkms.cryptoKeyVersions.useToDecrypt',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
						name: 'projects/1098658545826',
						created_at: '2024-04-11T16:37:47.900493Z',
						updated_at: '2024-04-11T16:37:47.900493Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'nayak-prometheus-integration',
						priority: 2.5,
					},
				},
				{
					id: 'f4e5d6c7-b8a9-0123-4567-890123456012',
					service_account_row_id: '2d60dfd0-52ef-4764-a67e-227a468bc601',
					role_row_id: 'b4c5d6e7-f8a9-0123-4567-890123456013',
					project_row_id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
					deleted: false,
					role: {
						id: 'b4c5d6e7-f8a9-0123-4567-890123456013',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.admin',
						title: 'Storage Admin',
						description: 'Full control of GCS resources',
						included_permissions: ['storage.buckets.*', 'storage.objects.*'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
						name: 'projects/1098658545826',
						created_at: '2024-04-11T16:37:47.900493Z',
						updated_at: '2024-04-11T16:37:47.900493Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'nayak-prometheus-integration',
						priority: 2.5,
					},
				},
			],
		},
	},
	'80002': {
		id: 'c6d2bb6d-bf11-4f8a-b115-2647c5644271',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '748912653084736521905',
		literal_friendly_name: 'Migration_svc',
		created_at: getRecentlyCreatedDate(),
		updated_at: getRecentlyCreatedDate(),
		last_activity: getRecentlyCreatedDate(),
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: '72890858-4c9d-460d-8a02-01bd75f914c6',
				created_at: '2024-05-02T05:09:17.601705Z',
				updated_at: '2024-06-24T05:11:25.279224Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
			{
				id: '8986e646-57df-4b04-83be-d17eae0663ea',
				created_at: '2024-05-02T05:09:17.703572Z',
				updated_at: '2024-06-24T05:11:25.295686Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 4,
				probability: null,
			},
			{
				id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'projects/31723875447',
		account_literal_friendly_name: 'nayak-prometheus-migration',
		affected_environments: {
			GCP: [
				{
					account_db_id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
					account_id: 'nayak-prometheus-integration',
					account_name: 'nayak-prometheus-integration',
				},
			],
		},
		gcp_service_account: {
			name: 'Migration_svc',
			last_activity: getRecentlyCreatedDate(),
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '748912653084736521905',
			email: 'nayak-prometheus@nayak-prometheus-migration.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 2.5,
					project: {
						name: 'projects/1098658545826',
						parent: 'organizations/1035611221239',
						projectId: 'nayak-prometheus-integration',
						state: GcpResourceState.ACTIVE,
						displayName: 'nayak-prometheus-integration',
						createTime: '2021-09-23T16:24:17.119000Z',
						updateTime: '2021-09-23T16:24:19.189000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/owner'],
				},
			],
			gcp_project: {
				id: '81414a24-bdfb-489e-a161-dddbca5fc279',
				name: 'projects/31723875447',
				created_at: '2024-04-11T16:37:47.900493Z',
				updated_at: '2024-04-11T16:37:47.900493Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'nayak-prometheus-migration',
				priority: 2.5,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f5e6d7c8-b9a0-1234-5678-901234567123',
					service_account_row_id: '18d93e40-b964-4a68-ae78-baad8b608e08',
					role_row_id: 'b5c6d7e8-f9a0-1234-5678-901234567124',
					project_row_id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
					deleted: false,
					role: {
						id: 'b5c6d7e8-f9a0-1234-5678-901234567124',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/owner',
						title: 'Owner',
						description: 'Full access to all resources',
						included_permissions: ['resourcemanager.projects.*', 'iam.serviceAccounts.*', 'storage.*'],
						stage: null,
						deleted: false,
						tags: [
							{
								id: '72890858-4c9d-460d-8a02-01bd75f914c6',
								created_at: '2024-05-02T05:09:17.601705Z',
								updated_at: '2024-06-24T05:11:25.279224Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'CREDENTIAL_EXPOSURE',
								impact: 3,
								probability: null,
							},
							{
								id: '8986e646-57df-4b04-83be-d17eae0663ea',
								created_at: '2024-05-02T05:09:17.703572Z',
								updated_at: '2024-06-24T05:11:25.295686Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'POSSIBLE_PRIVILEGE_ESCALATION',
								impact: 4,
								probability: null,
							},
							{
								id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						],
					},
					project: {
						id: '91fcbc67-d7c4-44db-a780-fc285e01aea6', // Using a valid UUID
						name: 'projects/1098658545826',
						created_at: '2024-04-11T16:37:47.900493Z',
						updated_at: '2024-04-11T16:37:47.900493Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'nayak-prometheus-integration',
						priority: 2.5,
					},
				},
			],
		},
	},
	'80003': {
		id: '8ebe27cc-6ffb-455e-8862-177332eed890',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '113538849152367060134',
		literal_friendly_name: 'sheets-api-access',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2023-11-14T08:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: '72890858-4c9d-460d-8a02-01bd75f914c6',
				created_at: '2024-05-02T05:09:17.601705Z',
				updated_at: '2024-06-24T05:11:25.279224Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
			{
				id: '8986e646-57df-4b04-83be-d17eae0663ea',
				created_at: '2024-05-02T05:09:17.703572Z',
				updated_at: '2024-06-24T05:11:25.295686Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 4,
				probability: null,
			},
			{
				id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		account_literal: 'projects/257226937223',
		account_literal_friendly_name: 'organic-search-1',
		affected_environments: {
			GCP: [
				{
					account_db_id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
					account_id: 'nayak-prometheus-integration',
					account_name: 'nayak-prometheus-integration',
				},
			],
		},
		gcp_service_account: {
			name: 'sheets-api-access',
			last_activity: '2023-11-14T08:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '113538849152367060134',
			email: 'sheets-api-access@organic-search-31.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 2.5,
					project: {
						name: 'projects/1098658545826',
						parent: 'organizations/1035611221239',
						projectId: 'nayak-prometheus-integration',
						state: GcpResourceState.ACTIVE,
						displayName: 'nayak-prometheus-integration',
						createTime: '2021-09-23T16:24:17.119000Z',
						updateTime: '2021-09-23T16:24:19.189000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/viewer'],
				},
			],
			gcp_project: {
				id: 'ff248ede-34b1-4db7-8d0c-73e990801597',
				name: 'projects/257226937223',
				created_at: '2023-03-10T09:00:00.000000Z',
				updated_at: '2023-03-10T09:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'organic-search-1',
				priority: 2.5,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f6e7d8c9-b0a1-2345-6789-012345678901',
					service_account_row_id: '0a2b66e1-c687-4bdb-b156-36b664adfb89',
					role_row_id: 'b6c7d8e9-f0a1-4345-9789-012345678235',
					project_row_id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
					deleted: false,
					role: {
						id: 'b6c7d8e9-f0a1-4345-9789-012345678235',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/viewer',
						title: 'Viewer',
						description: 'Read-only access to all resources',
						included_permissions: [
							'compute.instances.get',
							'compute.instances.list',
							'storage.objects.get',
							'storage.objects.list',
						],
						stage: null,
						deleted: false,
						tags: [
							{
								id: '72890858-4c9d-460d-8a02-01bd75f914c6',
								created_at: '2024-05-02T05:09:17.601705Z',
								updated_at: '2024-06-24T05:11:25.279224Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'CREDENTIAL_EXPOSURE',
								impact: 3,
								probability: null,
							},
							{
								id: '8986e646-57df-4b04-83be-d17eae0663ea',
								created_at: '2024-05-02T05:09:17.703572Z',
								updated_at: '2024-06-24T05:11:25.295686Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'POSSIBLE_PRIVILEGE_ESCALATION',
								impact: 4,
								probability: null,
							},
							{
								id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						],
					},
					project: {
						id: '91fcbc67-d7c4-44db-a780-fc285e01aea6',
						name: 'projects/1098658545826',
						created_at: '2024-04-11T16:37:47.900493Z',
						updated_at: '2024-04-11T16:37:47.900493Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'nayak-prometheus-integration',
						priority: 2.5,
					},
				},
			],
		},
	},
	'80004': {
		id: 'd7e3f9a1-6b2c-4e8a-9f3d-1c2b3a4d5e6f',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '123456789012345678906',
		literal_friendly_name: 'synergy-app-server-001',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-03-15T10:30:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: '72890858-4c9d-460d-8a02-01bd75f914c6',
				created_at: '2024-05-02T05:09:17.601705Z',
				updated_at: '2024-06-24T05:11:25.279224Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'ADMIN',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'projects/987654321098',
		account_literal_friendly_name: 'synergy-prod-app',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
					account_id: 'synergy-prod-app',
					account_name: 'synergy-prod-app',
				},
			],
		},
		gcp_service_account: {
			name: 'synergy-app-server-001',
			last_activity: '2024-03-15T10:30:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '123456789012345678901',
			email: 'app-server@synergy-prod-app.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: [
				{
					name: 'projects/synergy-prod-app/serviceAccounts/app-server@synergy-prod-app.iam.gserviceaccount.com/keys/fcf2e23e4121cfae2133cd9feb8f9fcebef2bbf8',
					disabled: false,
					keyType: 'USER_MANAGED',
					keyOrigin: 'GOOGLE_PROVIDED',
					keyAlgorithm: 'KEY_ALG_RSA_2048',
					validAfterTime: '2024-01-11 09:45:47+00:00',
					validBeforeTime: '9999-12-31 23:59:59+00:00',
				},
				{
					name: 'projects/synergy-prod-app/serviceAccounts/app-server@synergy-prod-app.iam.gserviceaccount.com/keys/15305e77c9761d7b4a076b57234cbb7987535801',
					disabled: false,
					keyType: 'USER_MANAGED',
					keyOrigin: 'GOOGLE_PROVIDED',
					keyAlgorithm: 'KEY_ALG_RSA_2048',
					validAfterTime: '2024-01-23 11:27:34+00:00',
					validBeforeTime: '9999-12-31 23:59:59+00:00',
				},
				{
					name: 'projects/synergy-prod-app/serviceAccounts/app-server@synergy-prod-app.iam.gserviceaccount.com/keys/7dc3b7b05c01366339747d6416e67ff010905e0c',
					disabled: false,
					keyType: 'USER_MANAGED',
					keyOrigin: 'GOOGLE_PROVIDED',
					keyAlgorithm: 'KEY_ALG_RSA_2048',
					validAfterTime: '2024-02-26 07:21:04+00:00',
					validBeforeTime: '9999-12-31 23:59:59+00:00',
				},
				{
					name: 'projects/synergy-prod-app/serviceAccounts/app-server@synergy-prod-app.iam.gserviceaccount.com/keys/66d2e6de073940bd53b20e3a44622ac6e7f3fdaa',
					disabled: false,
					keyType: 'USER_MANAGED',
					keyOrigin: 'GOOGLE_PROVIDED',
					keyAlgorithm: 'KEY_ALG_RSA_2048',
					validAfterTime: '2024-02-26 07:27:08+00:00',
					validBeforeTime: '9999-12-31 23:59:59+00:00',
				},
			],
			service_account_projects_roles_xc: [
				{
					account_priority: 4,
					project: {
						name: 'projects/987654321098',
						parent: 'organizations/1035611221239',
						projectId: 'synergy-prod-app',
						state: GcpResourceState.ACTIVE,
						displayName: 'synergy-prod-app',
						createTime: '2023-01-15T09:00:00.000000Z',
						updateTime: '2023-01-15T09:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/compute.admin', 'roles/storage.objectViewer'],
				},
			],
			gcp_project: {
				id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
				name: 'projects/987654321098',
				created_at: '2023-01-15T09:00:00.000000Z',
				updated_at: '2023-01-15T09:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'synergy-prod-app',
				priority: 4,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			kubernetes_resources_xc: gcpKubernetesResourcesXc,
			role_mappings: [
				{
					id: 'f7e8d9c0-b1a2-3456-7890-123456789345',
					service_account_row_id: 'd7e3f9a1-6b2c-4e8a-9f3d-1c2b3a4d5e6f',
					role_row_id: 'b7c8d9e0-f1a2-3456-7890-123456789346',
					project_row_id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
					deleted: false,
					role: {
						id: 'b7c8d9e0-f1a2-3456-7890-123456789346',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/compute.admin',
						title: 'Compute Admin',
						description: 'Full control of all Compute Engine resources',
						included_permissions: [
							'compute.instances.*',
							'compute.disks.*',
							'compute.networks.*',
							'compute.subnetworks.*',
						],
						stage: null,
						deleted: false,
						tags: [
							{
								id: '72890858-4c9d-460d-8a02-01bd75f914c6',
								created_at: '2024-05-02T05:09:17.601705Z',
								updated_at: '2024-06-24T05:11:25.279224Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'ADMIN',
								impact: 3,
								probability: null,
							},
						],
					},
					project: {
						id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
						name: 'projects/987654321098',
						created_at: '2023-01-15T09:00:00.000000Z',
						updated_at: '2023-01-15T09:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'synergy-prod-app',
						priority: 4,
					},
				},
				{
					id: 'f8e9d0c1-b2a3-4567-8901-234567890456',
					service_account_row_id: 'd7e3f9a1-6b2c-4e8a-9f3d-1c2b3a4d5e6f',
					role_row_id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
					project_row_id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
					deleted: false,
					role: {
						id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.objectViewer',
						title: 'Storage Object Viewer',
						description: 'Read access to objects without listing',
						included_permissions: ['storage.objects.get', 'storage.objects.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
						name: 'projects/987654321098',
						created_at: '2023-01-15T09:00:00.000000Z',
						updated_at: '2023-01-15T09:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'synergy-prod-app',
						priority: 4,
					},
				},
			],
		},
	},
	'80005': {
		id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2109',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '234567890123456789012',
		literal_friendly_name: 'synergy-db-backup-002',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-03-20T14:45:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'projects/876543210987',
		account_literal_friendly_name: 'synergy-data-warehouse',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
					account_id: 'synergy-data-warehouse',
					account_name: 'synergy-data-warehouse',
				},
			],
		},
		gcp_service_account: {
			name: 'synergy-db-backup-002',
			last_activity: '2024-03-20T14:45:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '234567890123456789012',
			email: 'db-backup@synergy-data-warehouse.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 1,
					project: {
						name: 'projects/876543210987',
						parent: 'organizations/1035611221239',
						projectId: 'synergy-data-warehouse',
						state: GcpResourceState.ACTIVE,
						displayName: 'synergy-data-warehouse',
						createTime: '2023-02-20T11:30:00.000000Z',
						updateTime: '2023-02-20T11:30:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/cloudsql.editor', 'roles/storage.objectAdmin'],
				},
			],
			gcp_project: {
				id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
				name: 'projects/876543210987',
				created_at: '2023-02-20T11:30:00.000000Z',
				updated_at: '2023-02-20T11:30:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'synergy-data-warehouse',
				priority: 1,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f9e0d1c2-b3a4-5678-9012-345678901567',
					service_account_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2109',
					role_row_id: 'b9c0d1e2-f3a4-5678-9012-345678901568',
					project_row_id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
					deleted: false,
					role: {
						id: 'b9c0d1e2-f3a4-5678-9012-345678901568',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/cloudsql.editor',
						title: 'Cloud SQL Editor',
						description: 'Full control of Cloud SQL instances and databases',
						included_permissions: ['cloudsql.instances.*', 'cloudsql.databases.*', 'cloudsql.users.*'],
						stage: null,
						deleted: false,
						tags: [
							{
								id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						],
					},
					project: {
						id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
						name: 'projects/876543210987',
						created_at: '2023-02-20T11:30:00.000000Z',
						updated_at: '2023-02-20T11:30:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'synergy-data-warehouse',
						priority: 1,
					},
				},
				{
					id: 'f0e1d2c3-b4a5-6789-0123-456789abcdef',
					service_account_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2109',
					role_row_id: 'b0c1d2e3-f4a5-4789-9123-456789abcdef',
					project_row_id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
					deleted: false,
					role: {
						id: 'b0c1d2e3-f4a5-4789-9123-456789abcdef',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.objectAdmin',
						title: 'Storage Object Admin',
						description: 'Full control of GCS objects',
						included_permissions: [
							'storage.objects.create',
							'storage.objects.delete',
							'storage.objects.get',
							'storage.objects.list',
							'storage.objects.update',
						],
						stage: null,
						deleted: false,
						tags: [
							{
								id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						],
					},
					project: {
						id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
						name: 'projects/876543210987',
						created_at: '2023-02-20T11:30:00.000000Z',
						updated_at: '2023-02-20T11:30:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'synergy-data-warehouse',
						priority: 1,
					},
				},
			],
		},
	},
	'80006': {
		id: 'd7e3f9a1-6d3c-4e8h-9f3d-1c2b3a4d5e8f',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '123456789012345678921',
		literal_friendly_name: 'synergy-app-server-002',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-03-15T10:30:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: '72890858-4c9d-460d-8a02-01bd75f914c6',
				created_at: '2024-05-02T05:09:17.601705Z',
				updated_at: '2024-06-24T05:11:25.279224Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'ADMIN',
				impact: 3,
				probability: null,
			},
			{
				id: '72890858-4c9d-460d-8a02-01bd75f914c5',
				created_at: '2024-05-02T05:09:17.601705Z',
				updated_at: '2024-06-24T05:11:25.279224Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
			{
				id: '72890858-4c9d-460d-8a02-01bd75f914c4',
				created_at: '2024-05-02T05:09:17.601705Z',
				updated_at: '2024-06-24T05:11:25.279224Z',
				source: 'TOKEN',
				related_resource_type: 'GOOGLE_WORKSPACE',
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'projects/987654321098',
		account_literal_friendly_name: 'synergy-prod-app',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
					account_id: 'synergy-prod-app',
					account_name: 'synergy-prod-app',
				},
			],
			GOOGLE_WORKSPACE: [
				{
					account_db_id: '0000c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
					account_id: 'C03o59ber',
					account_name: 'C03o59ber',
				},
			],
		},
		gcp_service_account: {
			name: 'synergy-app-server-002',
			last_activity: '2024-03-15T10:30:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '123456789132345676901',
			email: 'app-server-2@synergy-prod-app.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: [
				{
					name: 'projects/synergy-prod-app/serviceAccounts/app-server-2@synergy-prod-app.iam.gserviceaccount.com/keys/fcf2e23e4121cfae2133cd9feb8f9fcebef2bbf8',
					disabled: false,
					keyType: 'USER_MANAGED',
					keyOrigin: 'GOOGLE_PROVIDED',
					keyAlgorithm: 'KEY_ALG_RSA_2048',
					validAfterTime: '2024-01-11 09:45:47+00:00',
					validBeforeTime: '9999-12-31 23:59:59+00:00',
				},
				{
					name: 'projects/synergy-prod-app/serviceAccounts/app-server-2@synergy-prod-app.iam.gserviceaccount.com/keys/7dc3b7b05c01366339747d6416e67ff010905e0c',
					disabled: false,
					keyType: 'USER_MANAGED',
					keyOrigin: 'GOOGLE_PROVIDED',
					keyAlgorithm: 'KEY_ALG_RSA_2048',
					validAfterTime: '2024-02-26 07:21:04+00:00',
					validBeforeTime: '9999-12-31 23:59:59+00:00',
				},
				{
					name: 'projects/synergy-prod-app/serviceAccounts/app-server-2@synergy-prod-app.iam.gserviceaccount.com/keys/66d2e6de073940bd53b20e3a44622ac6e7f3fdaa',
					disabled: false,
					keyType: 'USER_MANAGED',
					keyOrigin: 'GOOGLE_PROVIDED',
					keyAlgorithm: 'KEY_ALG_RSA_2048',
					validAfterTime: '2024-02-26 07:27:08+00:00',
					validBeforeTime: '9999-12-31 23:59:59+00:00',
				},
			],
			service_account_projects_roles_xc: [
				{
					account_priority: 4,
					project: {
						name: 'projects/987654321098',
						parent: 'organizations/1035611221239',
						projectId: 'synergy-prod-app',
						state: GcpResourceState.ACTIVE,
						displayName: 'synergy-prod-app',
						createTime: '2023-01-15T09:00:00.000000Z',
						updateTime: '2023-01-15T09:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/compute.admin', 'roles/storage.objectViewer'],
				},
			],
			gcp_project: {
				id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
				name: 'projects/987654321098',
				created_at: '2023-01-15T09:00:00.000000Z',
				updated_at: '2023-01-15T09:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'synergy-prod-app',
				priority: 4,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f1e2d3c4-b5a6-7890-1234-567890abcdef',
					service_account_row_id: 'd7e3f9a1-6d3c-4e8h-9f3d-1c2b3a4d5e8f',
					role_row_id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
					project_row_id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
					deleted: false,
					role: {
						id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/compute.viewer',
						title: 'Compute Viewer',
						description: 'Read-only access to Compute Engine resources',
						included_permissions: ['compute.instances.get', 'compute.instances.list', 'compute.zones.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
						name: 'projects/987654321098',
						created_at: '2023-01-15T09:00:00.000000Z',
						updated_at: '2023-01-15T09:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'synergy-prod-app',
						priority: 4,
					},
				},
				{
					id: 'f2e3d4c5-b6a7-8901-2345-678901bcdef0',
					service_account_row_id: 'd7e3f9a1-6d3c-4e8h-9f3d-1c2b3a4d5e8f',
					role_row_id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
					project_row_id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
					deleted: false,
					role: {
						id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.objectViewer',
						title: 'Storage Object Viewer',
						description: 'Read access to objects without listing',
						included_permissions: ['storage.objects.get', 'storage.objects.list'],
						stage: null,
						deleted: false,
						tags: [
							{
								id: '72890858-4c9d-460d-8a02-01bd75f914c5',
								created_at: '2024-05-02T05:09:17.601705Z',
								updated_at: '2024-06-24T05:11:25.279224Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'POSSIBLE_PRIVILEGE_ESCALATION',
								impact: 3,
								probability: null,
							},
						],
					},
					project: {
						id: 'a1b2c3d4-e5f6-4321-a1b2-c3d4e5f6a7b8',
						name: 'projects/987654321098',
						created_at: '2023-01-15T09:00:00.000000Z',
						updated_at: '2023-01-15T09:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'synergy-prod-app',
						priority: 4,
					},
				},
			],
			demo_google_workspace_domain_wide_delegation: [
				{
					id: '5432111',
					google_workspace_env_id: '1234567890',
					google_workspace_env_name: 'C03o59ber',
					priority: 1,
					scopes: [
						{
							id: '654321',
							literal_name: 'https://www.googleapis.com/auth/gmail.send',
							friendly_name: 'gmail.send',
							tag: {
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GOOGLE_WORKSPACE',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						},
						{
							id: '7654321',
							literal_name: 'https://www.googleapis.com/auth/gmail.compose',
							friendly_name: 'gmail.compose',
							tag: {
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GOOGLE_WORKSPACE',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						},
						{
							id: '87654321',
							literal_name: 'https://www.googleapis.com/auth/gmail.readonly',
							friendly_name: 'gmail.readonly',
							tag: {
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GOOGLE_WORKSPACE',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						},
					],
				},
			],
		},
	},
	'80007': {
		id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2110',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '234567890123456789065',
		literal_friendly_name: 'synergy-db-backup-003',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-03-20T14:45:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'RESOURCE_EXPOSURE',
				impact: 3,
				probability: null,
			},
			{
				id: 'c2ae112a-a40c-4435-9949-79c7a970e9f7',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'projects/876543210987',
		account_literal_friendly_name: 'synergy-data-warehouse',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
					account_id: 'synergy-data-warehouse',
					account_name: 'synergy-data-warehouse',
				},
			],
		},
		gcp_service_account: {
			name: 'synergy-db-backup-002',
			last_activity: '2024-03-20T14:45:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '234567890123456789012',
			email: 'db-backup@synergy-data-warehouse-1.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 1,
					project: {
						name: 'projects/876543210987',
						parent: 'organizations/1035611221239',
						projectId: 'synergy-data-warehouse',
						state: GcpResourceState.ACTIVE,
						displayName: 'synergy-data-warehouse',
						createTime: '2023-02-20T11:30:00.000000Z',
						updateTime: '2023-02-20T11:30:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/cloudsql.editor', 'roles/storage.objectAdmin'],
				},
			],
			gcp_project: {
				id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
				name: 'projects/876543210987',
				created_at: '2023-02-20T11:30:00.000000Z',
				updated_at: '2023-02-20T11:30:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'synergy-data-warehouse',
				priority: 1,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f1e2d3c4-b5a6-7890-1234-567890abcdef',
					service_account_row_id: 'g2f3e4d5-c6b7-5432-g2f3-e4d5c6b7g2f6',
					role_row_id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
					project_row_id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
					deleted: false,
					role: {
						id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/cloudsql.admin',
						title: 'Cloud SQL Admin',
						description: 'Full control of Cloud SQL resources',
						included_permissions: [
							'cloudsql.instances.*',
							'cloudsql.databases.*',
							'cloudsql.users.*',
							'cloudsql.backupRuns.*',
						],
						stage: null,
						deleted: false,
						tags: [
							{
								id: 'c2ae112a-a40c-4435-9949-79c7a970e9f6',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'RESOURCE_EXPOSURE',
								impact: 3,
								probability: null,
							},
							{
								id: 'c2ae112a-a40c-4435-9949-79c7a970e9f7',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'POSSIBLE_PRIVILEGE_ESCALATION',
								impact: 3,
								probability: null,
							},
						],
					},
					project: {
						id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
						name: 'projects/876543210987',
						created_at: '2023-02-20T11:30:00.000000Z',
						updated_at: '2023-02-20T11:30:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'synergy-data-warehouse',
						priority: 1,
					},
				},
				{
					id: 'f2e3d4c5-b6a7-8901-2345-678901bcdef0',
					service_account_row_id: 'g2f3e4d5-c6b7-5432-g2f3-e4d5c6b7g2f6',
					role_row_id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
					project_row_id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
					deleted: false,
					role: {
						id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.objectAdmin',
						title: 'Storage Object Admin',
						description: 'Full control of GCS objects',
						included_permissions: [
							'storage.objects.create',
							'storage.objects.delete',
							'storage.objects.get',
							'storage.objects.list',
							'storage.objects.update',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'b2c3d4e5-f6a7-5432-b2c3-d4e5f6a7b2c3',
						name: 'projects/876543210987',
						created_at: '2023-02-20T11:30:00.000000Z',
						updated_at: '2023-02-20T11:30:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'synergy-data-warehouse',
						priority: 1,
					},
				},
			],
		},
	},
	'80008': {
		id: 'b9f7c6d5-4a2b-3c4e-b9f7-c6d54a2b3c4e',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '345678901234567890124',
		literal_friendly_name: 'app-backend-service',
		created_at: getRecentlyCreatedDate(),
		updated_at: getRecentlyCreatedDate(),
		last_activity: getRecentlyCreatedDate(),
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [],
		account_literal: 'projects/123456789012',
		account_literal_friendly_name: 'app-backend-service',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'b7cf6d11-db97-46cb-8e4e-63489bf15f60',
					account_id: 'app-backend-service',
					account_name: 'app-backend-service',
				},
			],
		},
		gcp_service_account: {
			name: 'app-backend-service',
			last_activity: getRecentlyCreatedDate(),
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '345678901234567890124',
			email: 'app-backend@app-backend-service.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 3,
					project: {
						name: 'projects/123456789012',
						parent: 'organizations/1035611221239',
						projectId: 'app-backend-service',
						state: GcpResourceState.ACTIVE,
						displayName: 'app-backend-service',
						createTime: '2023-03-10T09:00:00.000000Z',
						updateTime: '2023-03-10T09:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/appengine.serviceAdmin', 'roles/logging.logWriter'],
				},
			],
			gcp_project: {
				id: 'ff248ede-34b1-4db7-8d0c-73e990801597',
				name: 'projects/123456789012',
				created_at: '2023-03-10T09:00:00.000000Z',
				updated_at: '2023-03-10T09:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'app-backend-service',
				priority: 3,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f3e4d5c6-b7a8-9012-3456-789012cdef01',
					service_account_row_id: 'h3f4e5d6-c7b8-3210-h3f4-e5d6c7b83210',
					role_row_id: 'b3c4d5e6-f7a8-9012-3456-789012cdef02',
					project_row_id: 'b7cf6d11-db97-46cb-8e4e-63489bf15f60',
					deleted: false,
					role: {
						id: 'b3c4d5e6-f7a8-9012-3456-789012cdef02',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/compute.instanceAdmin.v1',
						title: 'Compute Instance Admin (v1)',
						description: 'Full control of Compute Engine instances',
						included_permissions: [
							'compute.instances.create',
							'compute.instances.delete',
							'compute.instances.start',
							'compute.instances.stop',
							'compute.instances.update',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'b7cf6d11-db97-46cb-8e4e-63489bf15f60',
						name: 'projects/123456789012',
						created_at: '2023-03-15T14:30:00.000000Z',
						updated_at: '2023-03-15T14:30:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'app-backend-service',
						priority: 3,
					},
				},
				{
					id: 'f4e5d6c7-b8a9-0123-4567-890123456012',
					service_account_row_id: 'h3f4e5d6-c7b8-3210-h3f4-e5d6c7b83210',
					role_row_id: 'b4c5d6e7-f8a9-0123-4567-890123456013',
					project_row_id: 'b7cf6d11-db97-46cb-8e4e-63489bf15f60',
					deleted: false,
					role: {
						id: 'b4c5d6e7-f8a9-0123-4567-890123456013',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.admin',
						title: 'Storage Admin',
						description: 'Full control of GCS resources',
						included_permissions: ['storage.buckets.*', 'storage.objects.*'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'b7cf6d11-db97-46cb-8e4e-63489bf15f60',
						name: 'projects/123456789012',
						created_at: '2023-03-15T14:30:00.000000Z',
						updated_at: '2023-03-15T14:30:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'app-backend-service',
						priority: 3,
					},
				},
			],
		},
	},
	'80009': {
		id: 'f8d7c6b5-4a2b-3c4d-f8d7-c6b54a2b3c4d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '456789012345678901234',
		literal_friendly_name: 'ml-training-bot',
		created_at: getRecentlyCreatedDate(),
		updated_at: getRecentlyCreatedDate(),
		last_activity: getRecentlyCreatedDate(),
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [],
		account_literal: 'projects/234567890123',
		account_literal_friendly_name: 'ml-training-bot',
		affected_environments: {
			GCP: [
				{
					account_db_id: '362c452b-e09a-40e8-9d09-e0cb485ee9be',
					account_id: 'ml-training-bot',
					account_name: 'ml-training-bot',
				},
			],
		},
		gcp_service_account: {
			name: 'ml-training-bot',
			last_activity: getRecentlyCreatedDate(),
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '456789012345678901234',
			email: 'ml-training-bot@ml-training-bot.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 2.5,
					project: {
						name: 'projects/234567890123',
						parent: 'organizations/1035611221239',
						projectId: 'ml-training-bot',
						state: GcpResourceState.ACTIVE,
						displayName: 'ml-training-bot',
						createTime: '2023-04-05T10:00:00.000000Z',
						updateTime: '2023-04-05T10:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/ml.developer', 'roles/storage.objectViewer'],
				},
			],
			gcp_project: {
				id: 'ff248ede-34b1-4db7-8d0c-73e990801598',
				name: 'projects/234567890123',
				created_at: '2023-04-05T10:00:00.000000Z',
				updated_at: '2023-04-05T10:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'ml-training-bot',
				priority: 2.5,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: '550e8400-e29b-41d4-a716-446655440000',
					service_account_row_id: 'i5j6k7l8-c7b8-3210-i5j6-k7l8c7b83210',
					role_row_id: 'b5c6d7e8-f9a0-1234-5678-90123efgh124',
					project_row_id: '362c452b-e09a-40e8-9d09-e0cb485ee9be',
					deleted: false,
					role: {
						id: 'b5c6d7e8-f9a0-1234-5678-90123efgh124',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/ml.developer',
						title: 'ML Developer',
						description: 'Access to develop and manage ML models',
						included_permissions: [
							'ml.models.create',
							'ml.models.predict',
							'ml.models.get',
							'ml.models.list',
							'ml.versions.create',
							'ml.versions.delete',
							'ml.versions.predict',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: '362c452b-e09a-40e8-9d09-e0cb485ee9be',
						name: 'projects/234567890123',
						created_at: '2023-04-05T10:00:00.000000Z',
						updated_at: '2023-04-05T10:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'ml-training-bot',
						priority: 2.5,
					},
				},
				{
					id: '123e4567-e89b-12d3-a456-426614174000',
					service_account_row_id: 'i5j6k7l8-c7b8-3210-i5j6-k7l8c7b83210',
					role_row_id: 'b6c7d8e9-f0a1-2345-6789-01234fghi235',
					project_row_id: '362c452b-e09a-40e8-9d09-e0cb485ee9be',
					deleted: false,
					role: {
						id: 'b6c7d8e9-f0a1-2345-6789-01234fghi235',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.objectViewer',
						title: 'Storage Object Viewer',
						description: 'Read access to objects without listing',
						included_permissions: ['storage.objects.get', 'storage.objects.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: '362c452b-e09a-40e8-9d09-e0cb485ee9be',
						name: 'projects/234567890123',
						created_at: '2023-04-05T10:00:00.000000Z',
						updated_at: '2023-04-05T10:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'ml-training-bot',
						priority: 2.5,
					},
				},
			],
		},
	},
	'80010': {
		id: 'g8h7i6j5-4b2a-3c4d-g8h7-i6j54b2a3c4d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '567890123456789012345',
		literal_friendly_name: 'backup-service',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-06-01T16:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [],
		account_literal: 'projects/345678901234',
		account_literal_friendly_name: 'backup-service',
		affected_environments: {
			GCP: [
				{
					account_db_id: '5867f8ca-699c-4d52-b558-a7bc8ebf92b7',
					account_id: 'backup-service',
					account_name: 'backup-service',
				},
			],
		},
		gcp_service_account: {
			name: 'backup-service',
			last_activity: '2024-06-01T16:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '567890123456789012345',
			email: 'backup-service@backup-service.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 3,
					project: {
						name: 'projects/345678901234',
						parent: 'organizations/1035611221239',
						projectId: 'backup-service',
						state: GcpResourceState.ACTIVE,
						displayName: 'backup-service',
						createTime: '2023-05-15T12:00:00.000000Z',
						updateTime: '2023-05-15T12:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/compute.admin', 'roles/storage.admin'],
				},
			],
			gcp_project: {
				id: 'ff248ede-34b1-4db7-8d0c-73e990801599',
				name: 'projects/345678901234',
				created_at: '2023-05-15T12:00:00.000000Z',
				updated_at: '2023-05-15T12:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'backup-service',
				priority: 3,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f7e8d9c0-b1a2-3456-7890-123456789345',
					service_account_row_id: 'j5k6l7m8-c7b8-3210-j5k6-l7m8c7b83210',
					role_row_id: 'b7c8d9e0-f1a2-3456-7890-123456789346',
					project_row_id: '5867f8ca-699c-4d52-b558-a7bc8ebf92b7',
					deleted: false,
					role: {
						id: 'b7c8d9e0-f1a2-3456-7890-123456789346',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.admin',
						title: 'Storage Admin',
						description: 'Full control of GCS resources',
						included_permissions: [
							'storage.buckets.create',
							'storage.buckets.delete',
							'storage.objects.create',
							'storage.objects.delete',
							'storage.objects.list',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: '5867f8ca-699c-4d52-b558-a7bc8ebf92b7',
						name: 'projects/345678901234',
						created_at: '2023-06-01T16:00:00.000000Z',
						updated_at: '2023-06-01T16:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'backup-service',
						priority: 3,
					},
				},
				{
					id: 'f8e9d0c1-b2a3-4567-8901-234567890456',
					service_account_row_id: 'j5k6l7m8-c7b8-3210-j5k6-l7m8c7b83210',
					role_row_id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
					project_row_id: '5867f8ca-699c-4d52-b558-a7bc8ebf92b7',
					deleted: false,
					role: {
						id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/compute.instanceAdmin.v1',
						title: 'Compute Instance Admin (v1)',
						description: 'Full control of Compute Engine instances',
						included_permissions: [
							'compute.instances.create',
							'compute.instances.delete',
							'compute.instances.start',
							'compute.instances.stop',
							'compute.instances.update',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: '5867f8ca-699c-4d52-b558-a7bc8ebf92b7',
						name: 'projects/345678901234',
						created_at: '2023-06-01T16:00:00.000000Z',
						updated_at: '2023-06-01T16:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'backup-service',
						priority: 3,
					},
				},
			],
		},
	},
	'80011': {
		id: 'h8i7j6k5-4b2a-3c4d-h8i7-j6k54b2a3c4d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '678901234567890123456',
		literal_friendly_name: 'api-gateway-service',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-06-10T16:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [],
		account_literal: 'projects/456789012345',
		account_literal_friendly_name: 'api-gateway-service',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'ef391b57-e83b-452f-8d6d-bf500d072862',
					account_id: 'api-gateway-service',
					account_name: 'api-gateway-service',
				},
			],
		},
		gcp_service_account: {
			name: 'api-gateway-service',
			last_activity: '2024-06-10T16:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '678901234567890123456',
			email: 'api-gateway@api-gateway-service.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 4,
					project: {
						name: 'projects/456789012345',
						parent: 'organizations/1035611221239',
						projectId: 'api-gateway-service',
						state: GcpResourceState.ACTIVE,
						displayName: 'api-gateway-service',
						createTime: '2023-06-10T12:00:00.000000Z',
						updateTime: '2023-06-10T12:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/compute.admin', 'roles/storage.admin'],
				},
			],
			gcp_project: {
				id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2111',
				name: 'projects/456789012345',
				created_at: '2023-06-10T12:00:00.000000Z',
				updated_at: '2023-06-10T12:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'api-gateway-service',
				priority: 4,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f9e0d1c2-b3a4-5678-9012-345678901234',
					service_account_row_id: 'k6l7m8n9-c7b8-3210-k6l7-m8n9c7b83210',
					role_row_id: 'b9c0d1e2-f3a4-5678-9012-34567abcdef1',
					project_row_id: 'ef391b57-e83b-452f-8d6d-bf500d072862',
					deleted: false,
					role: {
						id: 'b9c0d1e2-f3a4-5678-9012-34567abcdef1',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/apigateway.admin',
						title: 'API Gateway Admin',
						description: 'Full control of API Gateway resources',
						included_permissions: [
							'apigateway.apis.create',
							'apigateway.apis.delete',
							'apigateway.apis.get',
							'apigateway.apis.list',
							'apigateway.apis.update',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'ef391b57-e83b-452f-8d6d-bf500d072862',
						name: 'projects/456789012345',
						created_at: '2023-07-01T16:00:00.000000Z',
						updated_at: '2023-07-01T16:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'api-gateway-service',
						priority: 3,
					},
				},
				{
					id: 'f0e1d2c3-b4a5-6789-0123-456789abcdef',
					service_account_row_id: 'k6l7m8n9-c7b8-3210-k6l7-m8n9c7b83210',
					role_row_id: 'b0c1d2e3-f4a5-4567-8901-234567890123',
					project_row_id: 'ef391b57-e83b-452f-8d6d-bf500d072862',
					deleted: false,
					role: {
						id: 'b0c1d2e3-f4a5-4567-8901-234567890123',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/monitoring.viewer',
						title: 'Monitoring Viewer',
						description: 'Read-only access to monitoring data',
						included_permissions: [
							'monitoring.timeSeries.list',
							'monitoring.groups.get',
							'monitoring.groups.list',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'ef391b57-e83b-452f-8d6d-bf500d072862',
						name: 'projects/456789012345',
						created_at: '2023-07-01T16:00:00.000000Z',
						updated_at: '2023-07-01T16:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'api-gateway-service',
						priority: 3,
					},
				},
			],
		},
	},
	'80012': {
		id: 'i9j8k7l6-4b2a-3c4d-i9j8-k7l64b2a3c4d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '789012345678901234567',
		literal_friendly_name: 'monitoring-service',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-06-20T10:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [],
		account_literal: 'projects/567890123456',
		account_literal_friendly_name: 'monitoring-service',
		affected_environments: {
			GCP: [
				{
					account_db_id: '6dafe086-6a15-4481-a0eb-0249c01bb3ff',
					account_id: 'monitoring-service',
					account_name: 'monitoring-service',
				},
			],
		},
		gcp_service_account: {
			name: 'monitoring-service',
			last_activity: '2024-06-20T10:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '789012345678901234567',
			email: 'monitoring-service@monitoring-service.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 4,
					project: {
						name: 'projects/567890123456',
						parent: 'organizations/1035611221239',
						projectId: 'monitoring-service',
						state: GcpResourceState.ACTIVE,
						displayName: 'monitoring-service',
						createTime: '2023-07-10T12:00:00.000000Z',
						updateTime: '2023-07-10T12:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/monitoring.admin', 'roles/logging.logWriter'],
				},
			],
			gcp_project: {
				id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2112',
				name: 'projects/567890123456',
				created_at: '2023-07-10T12:00:00.000000Z',
				updated_at: '2023-07-10T12:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'monitoring-service',
				priority: 4,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f1e2d3c4-b5a6-7890-1234-567890abcdef',
					service_account_row_id: 'l7m8n9o0-c7b8-3210-l7m8-n9o0c7b83210',
					role_row_id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
					project_row_id: '6dafe086-6a15-4481-a0eb-0249c01bb3ff',
					deleted: false,
					role: {
						id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/monitoring.admin',
						title: 'Monitoring Admin',
						description: 'Full access to monitoring resources',
						included_permissions: [
							'monitoring.alerts.create',
							'monitoring.alerts.delete',
							'monitoring.timeSeries.create',
							'monitoring.timeSeries.list',
							'monitoring.groups.create',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: '6dafe086-6a15-4481-a0eb-0249c01bb3ff',
						name: 'projects/567890123456',
						created_at: '2023-08-01T16:00:00.000000Z',
						updated_at: '2023-08-01T16:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring-service',
						priority: 3,
					},
				},
				{
					id: 'f2e3d4c5-b6a7-8901-2345-678901bcdef0',
					service_account_row_id: 'l7m8n9o0-c7b8-3210-l7m8-n9o0c7b83210',
					role_row_id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
					project_row_id: '6dafe086-6a15-4481-a0eb-0249c01bb3ff',
					deleted: false,
					role: {
						id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/compute.viewer',
						title: 'Compute Viewer',
						description: 'Read-only access to Compute Engine resources',
						included_permissions: ['compute.instances.get', 'compute.instances.list', 'compute.zones.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: '6dafe086-6a15-4481-a0eb-0249c01bb3ff',
						name: 'projects/567890123456',
						created_at: '2023-08-01T16:00:00.000000Z',
						updated_at: '2023-08-01T16:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring-service',
						priority: 3,
					},
				},
			],
		},
	},
	'80013': {
		id: 'j9k8l7m6-4b2a-3c4d-j9k8-l7m64b2a3c4d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '890123456789012345678',
		literal_friendly_name: 'ci-cd-service',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-06-25T14:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: 'i9j0k1l2-4c8b-5678-i9j0-k1l24c8b5678',
				created_at: '2024-05-02T05:09:17.601705Z',
				updated_at: '2024-06-24T05:11:25.279224Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'RESOURCE_EXPOSURE',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'projects/678901234567',
		account_literal_friendly_name: 'ci-cd-service',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'i9j0k1l2-1234-5678-i9j0-k1l212345678',
					account_id: 'ci-cd-service',
					account_name: 'ci-cd-service',
				},
			],
		},
		gcp_service_account: {
			name: 'ci-cd-service',
			last_activity: '2024-06-25T14:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '890123456789012345678',
			email: 'ci-cd@ci-cd-service.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 2,
					project: {
						name: 'projects/678901234567',
						parent: 'organizations/1035611221239',
						projectId: 'ci-cd-service',
						state: GcpResourceState.ACTIVE,
						displayName: 'ci-cd-service',
						createTime: '2023-08-10T12:00:00.000000Z',
						updateTime: '2023-08-10T12:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/cloudbuild.builds.builder', 'roles/storage.objectAdmin'],
				},
			],
			gcp_project: {
				id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2113',
				name: 'projects/678901234567',
				created_at: '2023-08-10T12:00:00.000000Z',
				updated_at: '2023-08-10T12:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'ci-cd-service',
				priority: 2,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f1e2d3c4-b5a6-7890-1234-567890abcdef',
					service_account_row_id: 'm8n9o0p1-c7b8-3210-m8n9-o0p1c7b83210',
					role_row_id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2113',
					deleted: false,
					role: {
						id: 'a1b2c3d4-e5f6-7890-1234-567890abcdef',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/cloudbuild.builds.builder',
						title: 'Cloud Build Builder',
						description: 'Access to create and manage build jobs',
						included_permissions: [
							'cloudbuild.builds.create',
							'cloudbuild.builds.get',
							'cloudbuild.builds.list',
							'cloudbuild.builds.update',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2113',
						name: 'projects/678901234567',
						created_at: '2023-08-10T12:00:00.000000Z',
						updated_at: '2023-08-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'ci-cd-service',
						priority: 2,
					},
				},
				{
					id: 'f2e3d4c5-b6a7-8901-2345-678901bcdef0',
					service_account_row_id: 'm8n9o0p1-c7b8-3210-m8n9-o0p1c7b83210',
					role_row_id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2113',
					deleted: false,
					role: {
						id: 'b2c3d4e5-f6a7-8901-2345-678901bcdef1',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.objectAdmin',
						title: 'Storage Object Admin',
						description: 'Full control of GCS objects',
						included_permissions: [
							'storage.objects.create',
							'storage.objects.delete',
							'storage.objects.get',
							'storage.objects.list',
							'storage.objects.update',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2113',
						name: 'projects/678901234567',
						created_at: '2023-08-10T12:00:00.000000Z',
						updated_at: '2023-08-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'ci-cd-service',
						priority: 2,
					},
				},
			],
		},
	},
	'80014': {
		id: 'k9l8m7n6-4b2a-3c4d-k9l8-m7n64b2a3c4d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '901234567890123456789',
		literal_friendly_name: 'analytics-service',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-06-30T10:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [],
		account_literal: 'projects/789012345678',
		account_literal_friendly_name: 'analytics-service',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'j0k1l2m3-1234-5678-j0k1-l2m312345678',
					account_id: 'analytics-service',
					account_name: 'analytics-service',
				},
			],
		},
		gcp_service_account: {
			name: 'analytics-service',
			last_activity: '2024-06-30T10:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '901234567890123456789',
			email: 'analytics@analytics-service.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 1,
					project: {
						name: 'projects/789012345678',
						parent: 'organizations/1035611221239',
						projectId: 'analytics-service',
						state: GcpResourceState.ACTIVE,
						displayName: 'analytics-service',
						createTime: '2023-09-10T12:00:00.000000Z',
						updateTime: '2023-09-10T12:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: ['roles/bigquery.admin', 'roles/storage.objectViewer'],
				},
			],
			gcp_project: {
				id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
				name: 'projects/789012345678',
				created_at: '2023-09-10T12:00:00.000000Z',
				updated_at: '2023-09-10T12:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'analytics-service',
				priority: 1,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				{
					id: 'f7e8d9c0-b1a2-3456-7890-123456789345',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'b7c8d9e0-f1a2-3456-7890-123456789346',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'b7c8d9e0-f1a2-3456-7890-123456789346',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/bigquery.admin',
						title: 'BigQuery Admin',
						description: 'Full control of all BigQuery resources',
						included_permissions: [
							'bigquery.datasets.create',
							'bigquery.datasets.update',
							'bigquery.datasets.delete',
							'bigquery.tables.create',
							'bigquery.tables.update',
							'bigquery.tables.delete',
							'bigquery.routines.create',
							'bigquery.routines.update',
							'bigquery.routines.delete',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'analytics-service',
						priority: 1,
					},
				},
				{
					id: 'f8e9d0c1-b2a3-4567-8901-234567890456',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/storage.objectViewer',
						title: 'Storage Object Viewer',
						description: 'Read access to objects without listing',
						included_permissions: ['storage.objects.get', 'storage.objects.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'analytics-service',
						priority: 1,
					},
				},
			],
		},
	},
	'80015': {
		id: 'aaa8m7n6-4b2a-3c4d-k9l8-m7n64b2a3c4d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '907890123456789',
		literal_friendly_name: 'monitoring-mail-assistant',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-06-30T10:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: '74905faa-c95d-4f1b-950c-165772a7481c',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GOOGLE_WORKSPACE',
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
			{
				id: '8986e646-57df-4b04-83be-d17eae0663ea',
				created_at: '2024-05-02T05:09:17.703572Z',
				updated_at: '2024-06-24T05:11:25.295686Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 4,
				probability: null,
			},
		],
		account_literal: 'projects/789012345678',
		account_literal_friendly_name: 'monitoring-mail-assistant',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'j0k1l2m3-1234-5678-j0k1-l2m312345678',
					account_id: 'monitoring-mail-assistant',
					account_name: 'monitoring-mail-assistant',
				},
			],
			GOOGLE_WORKSPACE: [
				{
					account_db_id: '0001l2m3-1234-5678-j0k1-l2m312345678',
					account_id: 'C03o59ber',
					account_name: 'C03o59ber',
				},
			],
		},
		gcp_service_account: {
			name: 'monitoring-mail-assistant',
			last_activity: '2024-06-30T10:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '907890123456789',
			email: 'monitoring-mail-assistant@monitoring-mail-assistant.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 1,
					project: {
						name: 'projects/789012345678',
						parent: 'organizations/1035611221239',
						projectId: 'monitoring',
						state: GcpResourceState.ACTIVE,
						displayName: 'monitoring',
						createTime: '2023-09-10T12:00:00.000000Z',
						updateTime: '2023-09-10T12:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: [
						'roles/monitoring.admin',
						'roles/monitoring.viewer',
						'roles/logging.viewer',
						'roles/viewer',
						'roles/iam.serviceAccountTokenCreator',
					],
				},
			],
			gcp_project: {
				id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
				name: 'projects/789012345678',
				created_at: '2023-09-10T12:00:00.000000Z',
				updated_at: '2023-09-10T12:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'monitoring',
				priority: 1,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				// Mapping for roles/monitoring.admin
				{
					id: 'f7e8d9c0-b1a2-3456-7890-123456789345',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'b7c8d9e0-f1a2-3456-7890-123456789346',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'b7c8d9e0-f1a2-3456-7890-123456789346',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/monitoring.admin',
						title: 'Monitoring Admin',
						description: 'Full control of monitoring resources',
						included_permissions: [
							'monitoring.projects.create',
							'monitoring.projects.update',
							'monitoring.projects.delete',
							'monitoring.projects.get',
							'monitoring.projects.list',
						],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
				// Mapping for roles/monitoring.viewer
				{
					id: 'f8e9d0c1-b2a3-4567-8901-234567890456',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/monitoring.viewer',
						title: 'Monitoring Viewer',
						description: 'Read access to monitoring resources',
						included_permissions: ['monitoring.projects.get', 'monitoring.projects.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
				// Mapping for roles/logging.viewer
				{
					id: 'f9e0d1c2-b3a4-5678-9012-345678901567',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'b9c0d1e2-f2a3-4567-8901-234567890458',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'b9c0d1e2-f2a3-4567-8901-234567890458',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/logging.viewer',
						title: 'Logging Viewer',
						description: 'Read access to logging resources',
						included_permissions: ['logging.entries.get', 'logging.entries.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
				// Mapping for roles/viewer
				{
					id: 'fae1d2c3-b4a5-6789-0123-456789012678',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'bac1d2e3-f3a4-5678-9012-345678901459',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'bac1d2e3-f3a4-5678-9012-345678901459',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/viewer',
						title: 'Viewer',
						description: 'Read access to all resources',
						included_permissions: ['resourcemanager.projects.get', 'resourcemanager.projects.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
				// Mapping for roles/iam.serviceAccountTokenCreator
				{
					id: 'fbe2d3c4-b5a6-7890-1234-567890123789',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'bbb2c3d4-f4a5-6789-0123-456789012460',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'bbb2c3d4-f4a5-6789-0123-456789012460',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/iam.serviceAccountTokenCreator',
						title: 'Service Account Token Creator',
						description: 'Ability to create tokens for service accounts',
						included_permissions: ['iam.serviceAccounts.get', 'iam.serviceAccounts.signBlob'],
						stage: null,
						deleted: false,
						tags: [
							{
								id: '8986e646-57df-4b04-83be-d17eae0663ea',
								created_at: '2024-05-02T05:09:17.703572Z',
								updated_at: '2024-06-24T05:11:25.295686Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'POSSIBLE_PRIVILEGE_ESCALATION',
								impact: 4,
								probability: null,
							},
						],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
			],
			demo_google_workspace_domain_wide_delegation: [
				{
					id: '54321',
					google_workspace_env_id: '1234567890',
					google_workspace_env_name: 'C03o59ber',
					priority: 1,
					scopes: [
						{
							id: '654321',
							literal_name: 'https://www.googleapis.com/auth/gmail.send',
							friendly_name: 'gmail.send',
							tag: {
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GOOGLE_WORKSPACE',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						},
						{
							id: '7654321',
							literal_name: 'https://www.googleapis.com/auth/gmail.compose',
							friendly_name: 'gmail.compose',
							tag: {
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GOOGLE_WORKSPACE',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						},
						{
							id: '87654321',
							literal_name: 'https://www.googleapis.com/auth/gmail.readonly',
							friendly_name: 'gmail.readonly',
							tag: {
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GOOGLE_WORKSPACE',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						},
					],
				},
			],
		},
	},

	'80016': {
		id: 'aab8m7n6-4b2a-3c4d-k9l8-m7n64b2a3c4d',
		environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
		literal: '07890123456789',
		literal_friendly_name: 'upload-reports-automation',
		created_at: null,
		updated_at: '2024-06-24T05:08:46.784220Z',
		last_activity: '2024-06-30T10:00:00Z',
		env_type: EnvironmentType.GCP,
		type: IdentityType.Machine,
		source: IdentitySource.GCP_SERVICE_ACCOUNT,
		issues: [],
		tags: [
			{
				id: '74905faa-c95d-4f1b-950c-165772a7481c',
				created_at: '2024-05-02T05:09:17.690807Z',
				updated_at: '2024-06-24T05:11:25.287653Z',
				source: 'TOKEN',
				related_resource_type: 'GOOGLE_WORKSPACE',
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
			{
				id: '8986e646-57df-4b04-83be-d17eae0663ea',
				created_at: '2024-05-02T05:09:17.703572Z',
				updated_at: '2024-06-24T05:11:25.295686Z',
				source: 'TOKEN',
				related_resource_type: 'GCP',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 4,
				probability: null,
			},
		],
		account_literal: 'projects/789012345678',
		account_literal_friendly_name: 'upload-reports-automation',
		affected_environments: {
			GCP: [
				{
					account_db_id: 'j0k1l2m3-1234-5678-j0k1-l2m312345678',
					account_id: 'upload-reports-automation',
					account_name: 'upload-reports-automation',
				},
			],
			GOOGLE_WORKSPACE: [
				{
					account_db_id: '0001l2m3-1234-5678-j0k1-l2m312345678',
					account_id: 'C03o59ber',
					account_name: 'C03o59ber',
				},
			],
		},
		gcp_service_account: {
			name: 'upload-reports-automation',
			last_activity: '2024-06-30T10:00:00Z',
			environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
			unique_id: '07890123456789',
			email: 'upload-reports-automation@upload-reports-automation.iam.gserviceaccount.com',
			disabled: false,
			service_account_keys_xc: null,
			service_account_projects_roles_xc: [
				{
					account_priority: 1,
					project: {
						name: 'projects/789012345678',
						parent: 'organizations/1035611221239',
						projectId: 'monitoring',
						state: GcpResourceState.ACTIVE,
						displayName: 'monitoring',
						createTime: '2023-09-10T12:00:00.000000Z',
						updateTime: '2023-09-10T12:00:05.000000Z',
						labels: null,
						tags: null,
					},
					roles: [
						'roles/monitoring.viewer',
						'roles/logging.viewer',
						'roles/viewer',
						'roles/iam.serviceAccountTokenCreator',
					],
				},
			],
			gcp_project: {
				id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
				name: 'projects/789012345678',
				created_at: '2023-09-10T12:00:00.000000Z',
				updated_at: '2023-09-10T12:00:00.000000Z',
				customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
				enabled: true,
				project_id: 'monitoring',
				priority: 1,
			},
			endpoint_access_xc: gcpEndpointAccessXc,
			usage_logs: gcpUsageLogs,
			change_logs: gcpChangeLogs,
			role_mappings: [
				// Mapping for roles/monitoring.viewer
				{
					id: 'f8e9d0c1-b2a3-4567-8901-234567890456',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'b8c9d0e1-f2a3-4567-8901-234567890457',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/monitoring.viewer',
						title: 'Monitoring Viewer',
						description: 'Read access to monitoring resources',
						included_permissions: ['monitoring.projects.get', 'monitoring.projects.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
				// Mapping for roles/logging.viewer
				{
					id: 'f9e0d1c2-b3a4-5678-9012-345678901567',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'b9c0d1e2-f2a3-4567-8901-234567890458',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'b9c0d1e2-f2a3-4567-8901-234567890458',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/logging.viewer',
						title: 'Logging Viewer',
						description: 'Read access to logging resources',
						included_permissions: ['logging.entries.get', 'logging.entries.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
				// Mapping for roles/viewer
				{
					id: 'fae1d2c3-b4a5-6789-0123-456789012678',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'bac1d2e3-f3a4-5678-9012-345678901459',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'bac1d2e3-f3a4-5678-9012-345678901459',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/viewer',
						title: 'Viewer',
						description: 'Read access to all resources',
						included_permissions: ['resourcemanager.projects.get', 'resourcemanager.projects.list'],
						stage: null,
						deleted: false,
						tags: [],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
				// Mapping for roles/iam.serviceAccountTokenCreator
				{
					id: 'fbe2d3c4-b5a6-7890-1234-567890123789',
					service_account_row_id: 'n8o9p0q1-c7b8-3210-n8o9-p0q1c7b83210',
					role_row_id: 'bbb2c3d4-f4a5-6789-0123-456789012460',
					project_row_id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
					deleted: false,
					role: {
						id: 'bbb2c3d4-f4a5-6789-0123-456789012460',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						environment_id: 'a7f6d676-3bd8-41ef-8306-e909e068491d',
						name: 'roles/iam.serviceAccountTokenCreator',
						title: 'Service Account Token Creator',
						description: 'Ability to create tokens for service accounts',
						included_permissions: ['iam.serviceAccounts.get', 'iam.serviceAccounts.signBlob'],
						stage: null,
						deleted: false,
						tags: [
							{
								id: '8986e646-57df-4b04-83be-d17eae0663ea',
								created_at: '2024-05-02T05:09:17.703572Z',
								updated_at: '2024-06-24T05:11:25.295686Z',
								source: 'TOKEN',
								related_resource_type: 'GCP',
								name: 'POSSIBLE_PRIVILEGE_ESCALATION',
								impact: 4,
								probability: null,
							},
						],
					},
					project: {
						id: 'e8f7d6c5-4b3a-2109-e8f7-d6c54b3a2114',
						name: 'projects/789012345678',
						created_at: '2023-09-10T12:00:00.000000Z',
						updated_at: '2023-09-10T12:00:00.000000Z',
						customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
						enabled: true,
						project_id: 'monitoring',
						priority: 1,
					},
				},
			],
			demo_google_workspace_domain_wide_delegation: [
				{
					id: '12345',
					google_workspace_env_id: '1234567890',
					google_workspace_env_name: 'C03o59ber',
					priority: 1,
					scopes: [
						{
							id: '123456',
							literal_name: 'https://www.googleapis.com/auth/drive.file',
							friendly_name: 'drive.file',
							tag: {
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GOOGLE_WORKSPACE',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						},
						{
							id: '1234567',
							literal_name: 'https://www.googleapis.com/auth/drive',
							friendly_name: 'drive',
							tag: {
								id: '74905faa-c95d-4f1b-950c-165772a7481c',
								created_at: '2024-05-02T05:09:17.690807Z',
								updated_at: '2024-06-24T05:11:25.287653Z',
								source: 'TOKEN',
								related_resource_type: 'GOOGLE_WORKSPACE',
								name: 'DATA_ACCESS',
								impact: 3,
								probability: null,
							},
						},
					],
				},
			],
		},
	},
} satisfies Record<string, DemoServerIdentityInput>
