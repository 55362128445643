import { z } from 'zod'
import { DateSchema } from '../common'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerOktaUserXcSchema } from './oktaUserXcSchema.ts'

export const ServerGoogleWorkspaceUserDemoDomainWideDelegationSchema = z.object({
	gcp_service_account_id: z.string(),
	gcp_service_account_name: z.string(),
	scopes: z.array(z.string()),
})

export type ServerGoogleWorkspaceUserDemoDomainWideDelegationInput = z.input<
	typeof ServerGoogleWorkspaceUserDemoDomainWideDelegationSchema
>
export type ServerGoogleWorkspaceUserDemoDomainWideDelegation = z.infer<
	typeof ServerGoogleWorkspaceUserDemoDomainWideDelegationSchema
>

export const ServerGoogleWorkspaceUserDemoOauthAppsXcSchema = z.object({
	id: z.string(),
	name: z.string(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	is_internal: z.boolean(),
	scopes: z.array(z.string()),
})

export type ServerGoogleWorkspaceUserDemoOauthAppsXcInput = z.input<
	typeof ServerGoogleWorkspaceUserDemoOauthAppsXcSchema
>
export type ServerGoogleWorkspaceUserDemoOauthAppsXc = z.infer<typeof ServerGoogleWorkspaceUserDemoOauthAppsXcSchema>

export const ServerGoogleWorkspaceUserEmailsSchema = z.object({
	address: z.string().nullish(),
	type: z.string().nullish(),
	primary: z.boolean().nullish(),
})

export const ServerGoogleWorkspaceUserSchema = z.object({
	id: z.string(),
	primary_email: z.string().nullish(),
	is_admin: z.boolean().nullish(),
	suspended: z.boolean().nullish(),
	archived: z.boolean().nullish(),
	emails: z.array(z.string()).nullish(),
	google_workspace_customer_id: z.string().nullish(),
	mfa_enabled: z.boolean().nullish(),
	recovery_email: z.string().nullish(),
	recovery_phone: z.string().nullish(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	deleted_time: DateSchema.nullish(),
	name: z.string().nullish(),
	aws_iam_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
	aws_iam_users_xc: z.any().nullish(),
	okta_user_xc: z.array(ServerOktaUserXcSchema).nullish(),
	linkedin_xc: z.any().nullish(),
	hibp_xc: z.any().nullish(),
	demo_domain_wide_delegation: z.array(ServerGoogleWorkspaceUserDemoDomainWideDelegationSchema).nullish(),
	demo_oauth_apps_xc: z.array(ServerGoogleWorkspaceUserDemoOauthAppsXcSchema).nullish(),
})

export type ServerGoogleWorkspaceUser = z.infer<typeof ServerGoogleWorkspaceUserSchema>
