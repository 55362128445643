import { azureDevopsConfig } from './configurations/AzureDevopsIntegration.tsx'
import { EnvironmentConfig, EnvironmentIntegrationCategory } from './environmentIntegrationsTypes.ts'
import { salesforceConfig } from './configurations/SalesforceIntegration.tsx'
import { entraIdConfig } from './configurations/EntraIdIntegration.tsx'
import { awsConfig } from './configurations/AwsIntegration.tsx'
import { githubConfig } from './configurations/GithubIntegration.tsx'
import { snowflakeConfig } from './configurations/SnowflakeIntegration.tsx'
import { googleWorkspaceConfig } from './configurations/GoogleWorkspaceIntegration.tsx'
import { jumpcloudConfig } from './configurations/JumpcloudIntegration.tsx'
import { activeDirectoryConfig } from './configurations/ActiveDirectoryIntegration.tsx'
import { oktaConfig } from './configurations/OktaIntegration.tsx'
import { gcpConfig } from './configurations/GcpIntegration.tsx'
import { groupBy } from 'lodash'

export const integrationConfigs: EnvironmentConfig[] = [
	azureDevopsConfig,
	salesforceConfig,
	entraIdConfig,
	awsConfig,
	githubConfig,
	snowflakeConfig,
	googleWorkspaceConfig,
	jumpcloudConfig,
	activeDirectoryConfig,
	oktaConfig,
	gcpConfig,
]

export const integrationConfigsByCategory: Partial<Record<EnvironmentIntegrationCategory, EnvironmentConfig[]>> =
	groupBy(integrationConfigs, 'category')
