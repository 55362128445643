import React, { useCallback, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-enterprise'
import { Button, Modal } from 'antd'
import { formatDate, getCsvFileName } from '../../../../../utils.ts'
import { ServerAdOwnershipLogs } from '../../../../../schemas/identities/activeDirectory/adOwnershipSchema'
import { AdAggregatedOwnershipTable } from './AdAggregatedOwnershipTable'
import ExportIcon from '../../../../../assets/export_icon_20.svg?react'
import { eventIdDescriptions } from './adConstants'
import { aggregateAdOwnershipLogs } from './adLogsUtils'

export const AdOwnershipTable: React.FunctionComponent<{
	data: ServerAdOwnershipLogs[]
}> = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const gridRef = useRef<AgGridReact>(null)

	const exportToCsv = useCallback(() => {
		gridRef?.current!.api.exportDataAsCsv({
			fileName: getCsvFileName('ad-ownership-logs', false),
		})
	}, [])

	const columnDefs = useMemo<ColDef<ServerAdOwnershipLogs>[]>(() => {
		return [
			{
				field: 'actor_name',
				headerName: 'Actor Name',
				flex: 1,
			},
			{
				field: 'actor_sid',
				headerName: 'Actor SID',
				flex: 1,
			},
			{
				headerName: 'Action',
				flex: 1.5,
				valueGetter: (params: { data?: ServerAdOwnershipLogs }) => {
					if (!params.data) return ''
					return eventIdDescriptions[params.data.event_id] || 'Unknown event'
				},
			},
			{
				field: 'time_created',
				headerName: 'Time Created',
				flex: 1,
				cellRenderer: (params: { value: string }) => (
					<div className="text-textIcon-secondary">{formatDate(params.value, false)}</div>
				),
			},
			{
				field: 'system_updated_at',
				headerName: 'Last Updated',
				flex: 1,
				cellRenderer: (params: { value: string }) => (
					<div className="text-textIcon-secondary">{formatDate(params.value, false)}</div>
				),
			},
			{
				field: 'event_count',
				headerName: 'Event Count',
				flex: 1,
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	if (!data.length) {
		return <div className={'text-center'}>No Active Directory ownership data available</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<AdAggregatedOwnershipTable data={aggregateAdOwnershipLogs(data)} />
			</div>

			<div className="flex justify-between">
				<Button type="link" size="small" className="mt-4" onClick={() => setIsModalOpen(true)}>
					See all events
				</Button>
			</div>

			<Modal
				centered
				width={1024}
				title="Active Directory Ownership Logs"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex gap-2">
							<Button icon={<ExportIcon />} onClick={exportToCsv}>
								Export CSV
							</Button>
							<Button type="primary" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className="ag-theme-alpine w-full"
						rowData={data}
						rowHeight={54}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
						ref={gridRef}
					/>
				</div>
			</Modal>
		</>
	)
}
