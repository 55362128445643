import { DemoServerIdentityInput } from '../schemas.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { IdentityActivityStatus, IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { IssueName, IssueStatus, ResourceType } from '../../schemas/issue.ts'
import {
	ServerSalesforceApiTotalUsageAggregatedLogReadModelInput,
	ServerSalesforceApiTotalUsageLogInput,
} from '../../schemas/identities/salesforce/salesforceApiTotalUsageLogSchema.ts'
import {
	ServerSalesforceSetupAuditTrailAggregatedLogInput,
	ServerSalesforceSetupAuditTrailLogInput,
} from '../../schemas/identities/salesforce/salesforceSetupAuditTrailLogSchema.ts'

const ACCOUNT_LITERAL_FRIENDLY_NAME = 'Synergy Salesforce'
export const salesforceUserUuids = {
	SALESFORCE_USER_ID_1: 'dde92cb3-e0c4-4b9e-99f9-ae4f04fe7d6e',
	SALESFORCE_USER_ID_2: '40610a6c-8763-431c-a45e-cead8f7227ca',
	SALESFORCE_USER_ID_3: 'c9bb57ae-e934-4efb-a930-ba719d489839',
	SALESFORCE_USER_ID_4: 'a07e82aa-728e-4666-af4e-59362e1012d6',
	SALESFORCE_USER_ID_5: '254758e0-b31a-4faf-b6d2-38420c4cbf46',
	SALESFORCE_USER_ID_6: '35735gdf-b31a-4faf-b6d2-59362e1012d6',
	SALESFORCE_USER_ID_7: '54170d65-3cfa-4a56-a469-621a8bcc1c14',
	SALESFORCE_USER_ID_8: 'cab8e818-0e1e-4ad4-adc6-83e758abded5',
	SALESFORCE_USER_ID_9: '09518634-b277-49ba-9aa0-4b8c3582a4f1',
	SALESFORCE_USER_ID_10: '8957a9c0-accc-42c4-a684-55d0e6c4475d',
	SALESFORCE_USER_ID_11: '2b83b7b3-4f59-4369-af55-c5ab736c4511',
	SALESFORCE_USER_ID_12: 'b7aa1929-51c1-471d-a89e-6c05ad4c1852',
} as const

const salesforceUsageLogs = {
	[salesforceUserUuids['SALESFORCE_USER_ID_5']]: [
		{
			timestamp: '2024-10-31T12:26:55Z',
			action: 'suOrgAdminLogin',
			section: 'Manage Users',
			display: 'Logged in using Login-As access Shira',
		},
		{
			timestamp: '2024-10-31T12:25:51Z',
			action: 'suOrgAdminLogin',
			section: 'Manage Users',
			display: 'Logged in using Login-As access for Sharon Levy',
		},
		{
			timestamp: '2024-10-31T12:11:25Z',
			action: 'PermSetAssign',
			section: 'Manage Users',
			display:
				'Permission set Contract object - Modify all permission: assigned to user Gil Amar (UserID: [Rz000001665lxYj])',
		},
	],
	[salesforceUserUuids['SALESFORCE_USER_ID_7']]: [
		{
			timestamp: '2024-10-01T14:20:18Z',
			action: 'suOrgAdminLogout',
			section: 'Manage Users',
			display: 'Logged out using Login-As access for Derek Lee',
		},
		{
			timestamp: '2024-10-01T14:19:32Z',
			action: 'suOrgAdminLogout',
			section: 'Manage Users',
			display: 'Logged out using Login-As access for Derek Lee',
		},
		{
			timestamp: '2024-10-01T14:17:22Z',
			action: 'suOrgAdminLogout',
			section: 'Manage Users',
			display: 'Logged out using Login-As access for Derek Lee',
		},
		{
			timestamp: '2024-10-01T14:14:09Z',
			action: 'suOrgAdminLogout',
			section: 'Manage Users',
			display: 'Logged out using Login-As access for Derek Lee',
		},
		{
			timestamp: '2024-10-01T14:13:52Z',
			action: 'suOrgAdminLogout',
			section: 'Manage Users',
			display: 'Logged out using Login-As access for Derek Lee',
		},
		{
			timestamp: '2024-10-01T14:11:51Z',
			action: 'suOrgAdminLogout',
			section: 'Manage Users',
			display: 'Logged out using Login-As access for Derek Lee',
		},
		{
			timestamp: '2024-10-01T14:11:38Z',
			action: 'suOrgAdminLogout',
			section: 'Manage Users',
			display: 'Logged out using Login-As access for Derek Lee',
		},
	],
} satisfies Record<string, Array<ServerSalesforceSetupAuditTrailLogInput>>

const salesforceApiUsageLogs = {
	[salesforceUserUuids['SALESFORCE_USER_ID_10']]: [
		{
			timestamp: '2024-11-06T20:41:43.930000Z',
			api_resource: 'queryAll',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.161',
			entity_name: 'Contact',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.256000Z',
			api_resource: 'query',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.161',
			entity_name: 'SBQQ__Subscription__c',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.235000Z',
			api_resource: 'query',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.161',
			entity_name: 'Lead',
			status_code: 200,
		},
	],
	[salesforceUserUuids['SALESFORCE_USER_ID_9']]: [
		{
			timestamp: '2024-11-06T20:41:43.930000Z',
			api_resource: '/v55.0/mobile/security/config',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: ' ',
			status_code: 403,
		},
		{
			timestamp: '2024-11-06T20:33:59.256000Z',
			api_resource: '/v61.0/query',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'SBQQ__Quote__c',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.235000Z',
			api_resource: '/v61.0/query',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'Opportunity',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.206000Z',
			api_resource: '/v61.0/query',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'Contact',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.193000Z',
			api_resource: '/v61.0/query',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'Account',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.100000Z',
			api_resource: '/v61.0/search/layout',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'SBQQ__Quote__c',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.069000Z',
			api_resource: '/v61.0/query',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'Campaign',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.054000Z',
			api_resource: '/v61.0/search/layout',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'Opportunity',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.023000Z',
			api_resource: '/v61.0/search/layout',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'Account',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:59.003000Z',
			api_resource: '/v61.0/search/layout',
			client_name: '',
			http_method: 'GET',
			client_ip: '80.1.67.1',
			entity_name: 'Contact',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:58.742000Z',
			api_resource: '/v61.0/search/layout',
			client_name: '',
			http_method: 'GET',
			client_ip: '172.56.198.237',
			entity_name: 'Campaign',
			status_code: 200,
		},
		{
			timestamp: '2024-11-06T20:33:58.726000Z',
			api_resource: '/v61.0/query',
			client_name: '',
			http_method: 'GET',
			client_ip: '172.56.198.237',
			entity_name: 'Dashboard',
			status_code: 200,
		},
	],
} satisfies Record<string, Array<ServerSalesforceApiTotalUsageLogInput>>

const salesforceUsageLogsAggregated = {
	[salesforceUserUuids['SALESFORCE_USER_ID_5']]: [
		{
			day: '2024-11-05T00:00:00Z',
			created_by: '0054I000006RTR0QAO',
			sections: ['Manage Users'],
			actions_count: 3,
		},
	],
	[salesforceUserUuids['SALESFORCE_USER_ID_7']]: [
		{
			day: '2024-11-05T00:00:00Z',
			created_by: '0054I000006RTR0QAO',
			sections: ['Manage Users'],
			actions_count: 7,
		},
	],
} satisfies Record<string, Array<ServerSalesforceSetupAuditTrailAggregatedLogInput>>

const salesforceApiUsageLogsAggregated = {
	[salesforceUserUuids['SALESFORCE_USER_ID_10']]: [
		{
			day: '2024-11-06T00:00:00Z',
			client_ip: '80.1.67.161',
			status_codes: [200],
			total_requests: 3,
		},
	],
	[salesforceUserUuids['SALESFORCE_USER_ID_9']]: [
		{
			day: '2024-11-06T00:00:00Z',
			client_ip: '80.1.67.1',
			status_codes: [200],
			total_requests: 10,
		},
		{
			day: '2024-11-06T00:00:00Z',
			client_ip: '172.56.198.237',
			status_codes: [200],
			total_requests: 2,
		},
	],
} satisfies Record<string, Array<ServerSalesforceApiTotalUsageAggregatedLogReadModelInput>>

export const salesforceUserData = {
	[salesforceUserUuids['SALESFORCE_USER_ID_1']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_1'],
		literal: '1234',
		literal_friendly_name: 'john.smith@synergy.com',
		created_at: '2024-07-28T15:39:13Z',
		updated_at: '2024-07-28T15:39:13Z',
		last_activity: null,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Hybrid,
		source: IdentitySource.SALESFORCE_USER,
		affected_environments: null,
		account_literal: 'https://synergy.salesforce.com',
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		tags: [
			{
				id: '30684cbd-a844-4f12-8921-a50088210920',
				created_at: '2024-09-26T05:59:12.831587Z',
				updated_at: '2024-11-06T05:54:52.196331Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'RESOURCE_EXPOSURE',
				impact: 2,
				probability: null,
			},
			{
				id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
				created_at: '2024-09-26T05:59:12.799448Z',
				updated_at: '2024-11-06T05:54:52.175226Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
			{
				id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
				created_at: '2024-09-26T05:59:12.856159Z',
				updated_at: '2024-11-06T05:54:52.218008Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-06T05:54:52.151406Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-06T05:54:52.151406Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'ADMIN',
				impact: 3,
				probability: null,
			},
		],
		salesforce_user: {
			user_id: '0058e000001AFKGAA4',
			username: 'john.smith@synergy.com',
			email: 'john.smith@synergy.com',
			first_name: 'John',
			last_name: 'Smith',
			is_active: true,
			user_type: 'Standard',
			last_login_date: '2024-11-05T05:09:52Z',
			created_by_id: '0054I000005rd2uQAA',
			profile: {
				id: '00e58000000pyUgAAI',
				name: 'System Administrator',
				description: null,
				user_type: 'Standard',
				permissions: {
					PermissionsEditTask: true,
					PermissionsEditEvent: true,
					PermissionsEmailMass: true,
					PermissionsManageCMS: true,
					PermissionsViewRoles: true,
					PermissionsViewSetup: true,
					PermissionsApiEnabled: true,
					PermissionsAuthorApex: true,
					PermissionsDataExport: true,
					PermissionsEditTopics: true,
				},
				created_date: '2016-03-10T10:53:40Z',
				last_modified_date: '2024-10-20T06:21:47Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '00558000000LZWjAAO',
				last_modified_by_id: '0058e000001AFKGAA4',
				tags: [
					{
						id: '30684cbd-a844-4f12-8921-a50088210920',
						created_at: '2024-09-26T05:59:12.831587Z',
						updated_at: '2024-11-05T05:53:21.513271Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'RESOURCE_EXPOSURE',
						impact: 2,
						probability: null,
					},
					{
						id: '7f590ffe-9b8f-4427-84bf-c06601230908',
						created_at: '2024-09-26T05:59:12.769217Z',
						updated_at: '2024-11-05T05:53:21.427925Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'DATA_ACCESS',
						impact: 3,
						probability: null,
					},
					{
						id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
						created_at: '2024-09-26T05:59:12.856159Z',
						updated_at: '2024-11-05T05:53:21.658417Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'POSSIBLE_PRIVILEGE_ESCALATION',
						impact: 3,
						probability: null,
					},
					{
						id: 'c07b13e2-d8bb-4083-8369-8bb5a90fa228',
						created_at: '2024-09-26T05:59:12.733472Z',
						updated_at: '2024-11-05T05:53:21.143943Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'ADMIN',
						impact: 4,
						probability: null,
					},
					{
						id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
						created_at: '2024-09-26T05:59:12.799448Z',
						updated_at: '2024-11-05T05:53:21.487585Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'CREDENTIAL_EXPOSURE',
						impact: 3,
						probability: null,
					},
				],
			},
		},
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_2']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_2'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: 'alex.jones@synergy.com',
		literal_friendly_name: 'alex.jones@synergy.com',
		created_at: '2022-09-20T06:28:13Z',
		updated_at: '2023-09-11T12:07:48Z',
		last_activity: '2024-07-22T13:01:08Z',
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Hybrid,
		source: IdentitySource.SALESFORCE_USER,
		issues: [],
		tags: [
			{
				id: '30684cbd-a844-4f12-8921-a50088210920',
				created_at: '2024-09-26T05:59:12.831587Z',
				updated_at: '2024-11-06T05:54:52.196331Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'RESOURCE_EXPOSURE',
				impact: 2,
				probability: null,
			},
			{
				id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
				created_at: '2024-09-26T05:59:12.799448Z',
				updated_at: '2024-11-06T05:54:52.175226Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
			{
				id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
				created_at: '2024-09-26T05:59:12.856159Z',
				updated_at: '2024-11-06T05:54:52.218008Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-06T05:54:52.151406Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		affected_environments: null,
		salesforce_user: {
			user_id: '0058e000000cy16AAA',
			username: 'alex.jones@synergy.com',
			email: 'alex.jones@synergy.com',
			first_name: 'Alex',
			last_name: 'Jones',
			is_active: true,
			user_type: 'Standard',
			last_login_date: '2024-07-22T13:01:08Z',
			created_by_id: '0058e000001AFKGAA4',
			profile: {
				id: '00e58000000hW9AAAU',
				name: 'Finance',
				description: null,
				user_type: 'Standard',
				permissions: {
					PermissionsEditTask: true,
					PermissionsEditEvent: true,
					PermissionsEmailMass: true,
					PermissionsViewRoles: true,
					PermissionsViewSetup: true,
					PermissionsApiEnabled: true,
					PermissionsEditTopics: true,
					PermissionsRunReports: true,
					PermissionsUseWebLink: true,
					PermissionsEmailSingle: true,
					PermissionsFreezeUsers: true,
					PermissionsManageRoles: true,
					PermissionsManageUsers: true,
					PermissionsAssignTopics: true,
					PermissionsCreateTopics: true,
					PermissionsExportReport: true,
					PermissionsViewAllUsers: true,
					PermissionsViewHelpLink: true,
					PermissionsListEmailSend: true,
					PermissionsManageSharing: true,
					PermissionsContentHubUser: true,
					PermissionsMassInlineEdit: true,
					PermissionsResetPasswords: true,
					PermissionsChatterFileLink: true,
					PermissionsFlowUFLRequired: true,
					PermissionsActivitiesAccess: true,
					PermissionsApexRestServices: true,
					PermissionsChatterOwnGroups: true,
					PermissionsManageIpAddresses: true,
					PermissionsManageTerritories: true,
					PermissionsOverrideForecasts: true,
					PermissionsChatterEditOwnPost: true,
					PermissionsDelegatedTwoFactor: true,
					PermissionsChatterInternalUser: true,
					PermissionsEmailAdministration: true,
					PermissionsEnableNotifications: true,
					PermissionsManageInternalUsers: true,
					PermissionsMonitorLoginHistory: true,
					PermissionsAssignPermissionSets: true,
					PermissionsCustomizeApplication: true,
					PermissionsCreateCustomizeFilters: true,
					PermissionsCreateCustomizeReports: true,
					PermissionsDistributeFromPersWksp: true,
					PermissionsManagePasswordPolicies: true,
					PermissionsShareFilesWithNetworks: true,
					PermissionsTransactionalEmailSend: true,
					PermissionsCreateReportInLightning: true,
					PermissionsEmailTemplateManagement: true,
					PermissionsLightningExperienceUser: true,
					PermissionsManageCustomPermissions: true,
					PermissionsEditOppLineItemUnitPrice: true,
					PermissionsPreventClassicExperience: true,
					PermissionsManageLoginAccessPolicies: true,
					PermissionsSelectFilesFromSalesforce: true,
					PermissionsShowCompanyNameAsUserBadge: true,
					PermissionsSubscribeToLightningReports: true,
					PermissionsManageProfilesPermissionsets: true,
					PermissionsLightningConsoleAllowedForUser: true,
				},
				created_date: '2016-08-08T11:15:15Z',
				last_modified_date: '2024-10-31T14:25:38Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '00558000000LZWjAAO',
				last_modified_by_id: '0058e0000018t86AAA',
				tags: [
					{
						id: '30684cbd-a844-4f12-8921-a50088210920',
						created_at: '2024-09-26T05:59:12.831587Z',
						updated_at: '2024-11-06T05:54:52.196331Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'RESOURCE_EXPOSURE',
						impact: 2,
						probability: null,
					},
					{
						id: '7f590ffe-9b8f-4427-84bf-c06601230908',
						created_at: '2024-09-26T05:59:12.769217Z',
						updated_at: '2024-11-06T05:54:52.151406Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'DATA_ACCESS',
						impact: 3,
						probability: null,
					},
					{
						id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
						created_at: '2024-09-26T05:59:12.799448Z',
						updated_at: '2024-11-06T05:54:52.175226Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'CREDENTIAL_EXPOSURE',
						impact: 3,
						probability: null,
					},
					{
						id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
						created_at: '2024-09-26T05:59:12.856159Z',
						updated_at: '2024-11-06T05:54:52.218008Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'POSSIBLE_PRIVILEGE_ESCALATION',
						impact: 3,
						probability: null,
					},
				],
			},
			permission_sets: [
				{
					id: '0PS4I000001QZWrWAO',
					name: 'AdvancedApprovalsUser',
					label: 'Advanced Approvals User',
					description: 'Standard user permissions across all Salesforce CPQ Advanced Approvals objects.',
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: null,
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'sbaa',
					has_activation_required: false,
					permissions: {},
					created_date: '2021-03-18T10:13:41Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0054I000006RZWpQAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS4I000001QZWrWAO',
						type: 'PermissionSet',
					},
					tags: null,
				},
			],
			ownership_records: [
				{
					owner: {
						id: '0058e000001AFKGAA4',
						username: 'dan.bell@synergy.com',
						email: 'dan.bell@synergy.com',
						first_name: 'Dan',
						last_name: 'Bell',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '0054I000005rd2uQAA',
						last_modified_by_id: '0058e000001AFKGAA4',
						created_at: '2022-07-03T08:23:30Z',
						last_modified_date: '2024-10-30T08:13:48Z',
						last_login_date: '2024-11-06T05:09:06Z',
					},
					action_type: 'Create',
					last_modified_date: '2022-09-20T06:28:13Z',
				},
				{
					owner: {
						id: '0058e000001AFKGAA4',
						username: 'dan.bell@synergy.com',
						email: 'dan.bell@synergy.com',
						first_name: 'Dan',
						last_name: 'Bell',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '0054I000005rd2uQAA',
						last_modified_by_id: '0058e000001AFKGAA4',
						created_at: '2022-07-03T08:23:30Z',
						last_modified_date: '2024-10-30T08:13:48Z',
						last_login_date: '2024-11-06T05:09:06Z',
					},
					action_type: 'Update',
					last_modified_date: '2023-09-11T12:07:48Z',
				},
			],
		},
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_3']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_3'],
		environment_id: '3c4568ab-2ff1-4d39-b743-f61df0b7aab7',
		literal: 'sam.green@synergy.com',
		literal_friendly_name: 'sam.green@synergy.com',
		created_at: '2023-01-15T09:21:44Z',
		updated_at: '2024-03-18T10:12:24Z',
		last_activity: '2024-08-10T11:14:02Z',
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Hybrid,
		source: IdentitySource.SALESFORCE_USER,
		issues: [],
		tags: [
			{
				id: '30684cbd-a844-4f12-8921-a50088210920',
				created_at: '2024-09-26T05:59:12.831587Z',
				updated_at: '2024-11-05T05:53:21.513271Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'RESOURCE_EXPOSURE',
				impact: 2,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-05T05:53:21.427925Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		affected_environments: null,
		salesforce_user: {
			user_id: '0058e000001GH25AAA',
			username: 'sam.green@synergy.com',
			email: 'sam.green@synergy.com',
			first_name: 'Sam',
			last_name: 'Green',
			is_active: true,
			user_type: 'Standard',
			last_login_date: '2024-08-10T11:14:02Z',
			created_by_id: '0058e000001GH67AAA',
			profile: {
				id: '00e58000000hr4AAAU',
				name: 'Operations',
				description: null,
				user_type: 'Standard',
				permissions: {
					PermissionsEditTask: true,
					PermissionsViewRoles: true,
					PermissionsApiEnabled: true,
					PermissionsManageRoles: true,
					PermissionsViewSetup: true,
					PermissionsLightningExperienceUser: true,
					PermissionsResetPasswords: true,
					PermissionsExportReport: true,
					PermissionsChatterEditOwnPost: true,
					PermissionsEnableNotifications: true,
					PermissionsCustomizeApplication: true,
					PermissionsCreateCustomizeReports: true,
					PermissionsManageIpAddresses: true,
				},
				created_date: '2017-03-02T12:05:22Z',
				last_modified_date: '2024-05-12T15:38:57Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '00558000000KDLhAAO',
				last_modified_by_id: '00558000000KGFrAAO',
				tags: [
					{
						id: '30684cbd-a844-4f12-8921-a50088210920',
						created_at: '2024-09-26T05:59:12.831587Z',
						updated_at: '2024-11-05T05:53:21.513271Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'RESOURCE_EXPOSURE',
						impact: 2,
						probability: null,
					},
					{
						id: '7f590ffe-9b8f-4427-84bf-c06601230908',
						created_at: '2024-09-26T05:59:12.769217Z',
						updated_at: '2024-11-05T05:53:21.427925Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'DATA_ACCESS',
						impact: 3,
						probability: null,
					},
				],
			},
			permission_sets: [
				{
					id: '0PS58000000JHtxEAG',
					name: 'Data_Migration_User',
					label: 'Data Migration User',
					description: null,
					is_owned_by_profile: true,
					is_custom: true,
					type: 'Regular',
					license_id: '10058000000ZJk9AAG',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {},
					created_date: '2017-04-11T09:34:01Z',
					last_modified_date: '2024-05-12T15:38:57Z',
					system_modstamp: '2024-05-12T15:38:57Z',
					created_by_id: '00558000000KDLhAAO',
					last_modified_by_id: '0058e000001GH67AAA',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS58000000JHtxEAG',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '30684cbd-a844-4f12-8921-a50088210920',
							created_at: '2024-09-26T05:59:12.831587Z',
							updated_at: '2024-11-05T05:53:21.513271Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'RESOURCE_EXPOSURE',
							impact: 2,
							probability: null,
						},
						{
							id: '7f590ffe-9b8f-4427-84bf-c06601230908',
							created_at: '2024-09-26T05:59:12.769217Z',
							updated_at: '2024-11-05T05:53:21.427925Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'DATA_ACCESS',
							impact: 3,
							probability: null,
						},
					],
				},
			],
			ownership_records: [
				{
					owner: {
						id: '0058e000001GH67AAA',
						username: 'ella.smith@synergy.com',
						email: 'ella.smith@synergy.com',
						first_name: 'Ella',
						last_name: 'Smith',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000fr8KAAI',
						created_by_id: '0054I000007XJf6QAA',
						last_modified_by_id: '0058e000001GH25AAA',
						created_at: '2023-04-15T10:42:36Z',
						last_modified_date: '2024-10-15T14:23:22Z',
						last_login_date: '2024-11-06T07:19:06Z',
					},
					action_type: 'Create',
					last_modified_date: '2023-01-15T09:21:44Z',
				},
				{
					owner: {
						id: '0058e000001GH67AAA',
						username: 'ella.smith@synergy.com',
						email: 'ella.smith@synergy.com',
						first_name: 'Ella',
						last_name: 'Smith',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000fr8KAAI',
						created_by_id: '0054I000007XJf6QAA',
						last_modified_by_id: '0058e000001GH25AAA',
						created_at: '2023-04-15T10:42:36Z',
						last_modified_date: '2024-10-15T14:23:22Z',
						last_login_date: '2024-11-06T07:19:06Z',
					},
					action_type: 'Update',
					last_modified_date: '2024-03-18T10:12:24Z',
				},
			],
		},
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_4']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_4'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: '0H4Rz0000000QAbKAM',
		literal_friendly_name: 'Retool v2',
		created_at: '2024-10-14T09:12:40Z',
		updated_at: null,
		last_activity: null,
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Machine,
		source: IdentitySource.SALESFORCE_CONNECTED_APPLICATION,
		issues: [],
		tags: [],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		affected_environments: null,
		salesforce_connected_application: {
			name: 'Retool v2',
			connected_application_id: '0H4Rz0000000QAbKAM',
			created_by_id: '0058e000001AFKGAA4',
			last_modified_by_id: '0058e000001AFKGAA4',
			options_allow_admin_approved_users_only: false,
			options_refresh_token_validity_metric: false,
			options_has_session_level_policy: false,
			options_is_internal: false,
			options_full_content_push_notifications: true,
			options_code_credential_guest_enabled: false,
			options_app_issue_jwt_token_enabled: false,
			options_token_exchange_manage_bit_enabled: false,
			start_url: null,
			refresh_token_validity_period: null,
			// bound_salesforce_user: null,
			app_metadata: {
				full_name: 'Retool_v2',
				contact_email: 'dan.bell@synergy.com',
				label: 'Retool v2',
				oauth_config: {
					access_method: null,
					callback_url: 'https://login.salesforce.com/services/oauth2/success',
					consumer_key: 'J1sqq27m0FGLI13hhvE1tdmp1U2oOnFEN0d3TLdlasldjhanskmSDFShENTrkHh5JMvTh9h247Dq',
					is_admin_approved: false,
					is_client_credential_enabled: false,
					is_code_credential_enabled: false,
					is_code_credential_post_only: false,
					is_consumer_secret_optional: false,
					is_introspect_all_tokens: false,
					is_named_user_jwt_enabled: false,
					is_pkce_required: true,
					is_refresh_token_rotation_enabled: false,
					is_secret_required_for_refresh_token: true,
					is_secret_required_for_token_exchange: false,
					is_token_exchange_enabled: false,
					oauth_client_credential_user: null,
					scopes: ['Full'],
				},
				oauth_policy: {
					ip_relaxation: 'ENFORCE',
					refresh_token_policy: 'infinite',
				},
				description: null,
				start_url: null,
			},
			ownership_records: [
				{
					owner: {
						id: '0058e000001AFKGAA4',
						username: 'dan.bell@synergy.com',
						email: 'dan.bell@synergy.com',
						first_name: 'Dan',
						last_name: 'Bell',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '0054I000005rd2uQAA',
						last_modified_by_id: '0058e000001AFKGAA4',
						created_at: '2022-07-03T08:23:30Z',
						last_modified_date: '2024-10-30T08:13:48Z',
						last_login_date: '2024-11-06T05:09:06Z',
					},
					action_type: 'Create',
					last_modified_date: '2024-10-14T09:12:40Z',
				},
				{
					owner: {
						id: '0058e000001AFKGAA4',
						username: 'dan.bell@synergy.com',
						email: 'dan.bell@synergy.com',
						first_name: 'Dan',
						last_name: 'Bell',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '0054I000005rd2uQAA',
						last_modified_by_id: '0058e000001AFKGAA4',
						created_at: '2022-07-03T08:23:30Z',
						last_modified_date: '2024-10-30T08:13:48Z',
						last_login_date: '2024-11-06T05:09:06Z',
					},
					action_type: 'Update',
					last_modified_date: '2024-10-14T09:12:40Z',
				},
			],
			api_total_usage_logs: [],
		},
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_5']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_5'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: 'synergy@synergy.force.com',
		literal_friendly_name: 'synergy@synergy.force.com',
		created_at: '2021-06-28T11:25:33Z',
		updated_at: '2021-06-28T11:25:33Z',
		last_activity: null,
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Human,
		source: IdentitySource.SALESFORCE_USER,
		issues: [
			{
				priority: 2,
				id: '66351fd1-83fe-4896-be2c-b65fcbd385b3',
				created_at: '2024-09-26T05:59:15.202677Z',
				last_seen: '2024-11-07T06:03:25.941918Z',
				status: IssueStatus.OPEN,
				issue_name: IssueName.InactiveIdentity,
				issue_source: ResourceType.SALESFORCE,
				description:
					"\nThe user has not logged in since **2021-06-28 11:25:33**.\nInactive users in Salesforce are accounts that haven't shown activity for an extended period, potentially posing security risks and consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended to maintain system security and optimize license usage.\n",
				ignore_reason: null,
			},
			{
				priority: 2,
				id: '388fc42e-bd1e-4dc1-8a7c-223e87de8e06',
				created_at: '2024-10-15T05:54:45.109502Z',
				last_seen: '2024-11-07T06:03:25.942708Z',
				status: IssueStatus.OPEN,
				issue_name: IssueName.UnFederatedIdentity,
				issue_source: ResourceType.SALESFORCE,
				description:
					"\nA non-federated Salesforce user (**synergy@synergy.force.com**) detected in the Salesforce instance, which\nrelies on traditional username and password authentication, diverging from Salesforce's security best practices.\nUsing users that are not federated with an Identity Provider (IdP) increases the risk of credential compromise and\ncomplicates access management.\nIt is recommended to implement Single Sign-On (SSO) with a federated IdP for enhanced security and streamlined user management.\n",
				ignore_reason: null,
			},
		],
		tags: [
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-07T06:03:07.410293Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: 'Synergy Salesforce',
		affected_environments: null,
		salesforce_user: {
			user_id: '0054I000005rjnIQAQ',
			username: 'synergy@synergy.force.com',
			email: 'synergy@synergy.force.com',
			first_name: 'synergy',
			last_name: 'Site Guest User',
			is_active: true,
			user_type: 'Guest',
			last_login_date: null,
			created_by_id: '0054I000006RZWpQAO',
			profile: {
				id: '00e4I00000102QqQAI',
				name: 'Synergy Site Profile',
				description: null,
				user_type: 'Guest',
				permissions: {
					PermissionsEmailAdministration: true,
					PermissionsEmailTemplateManagement: true,
				},
				created_date: '2021-06-28T11:25:33Z',
				last_modified_date: '2024-06-18T22:19:12Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '0054I000006RZWpQAO',
				last_modified_by_id: '00558000000qeXNAAY',
				tags: [
					{
						id: '7f590ffe-9b8f-4427-84bf-c06601230908',
						created_at: '2024-09-26T05:59:12.769217Z',
						updated_at: '2024-11-07T06:03:07.410293Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'DATA_ACCESS',
						impact: 3,
						probability: null,
					},
				],
			},
			permission_sets: [
				{
					id: '0PS4I000001QaOTWA0',
					name: 'X00e4I00000102QqQAI',
					label: '00e4I00000102QqQAI',
					description: null,
					is_owned_by_profile: true,
					is_custom: true,
					type: 'Profile',
					license_id: '100580000000FKhAAM',
					profile_id: '00e4I00000102QqQAI',
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsEmailAdministration: true,
						PermissionsEmailTemplateManagement: true,
					},
					created_date: '2021-06-28T11:25:33Z',
					last_modified_date: '2024-06-18T22:19:12Z',
					system_modstamp: '2024-06-18T22:19:12Z',
					created_by_id: '0054I000006RZWpQAO',
					last_modified_by_id: '00558000000qeXNAAY',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS4I000001QaOTWA0',
						type: 'PermissionSet',
					},
					tags: [],
				},
			],
			ownership_records: [
				{
					owner: {
						id: '0054I000006RZWpQAO',
						username: 'ben.tar@synergy.com',
						email: 'ben.tar@synergy.com',
						first_name: 'Ben',
						last_name: 'Tar',
						is_active: false,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '00558000000LZWjAAO',
						last_modified_by_id: '0054I000006RTR0QAO',
						created_at: '2020-07-02T17:56:25Z',
						last_modified_date: '2022-03-27T06:54:04Z',
						last_login_date: '2022-03-21T04:02:08Z',
					},
					action_type: 'Create',
					last_modified_date: '2021-06-28T11:25:33Z',
				},
				{
					owner: {
						id: '0054I000006RZWpQAO',
						username: 'ben.tar@synergy.com',
						email: 'ben.tar@synergy.com',
						first_name: 'Ben',
						last_name: 'Tar',
						is_active: false,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '00558000000LZWjAAO',
						last_modified_by_id: '0054I000006RTR0QAO',
						created_at: '2020-07-02T17:56:25Z',
						last_modified_date: '2022-03-27T06:54:04Z',
						last_login_date: '2022-03-21T04:02:08Z',
					},
					action_type: 'Update',
					last_modified_date: '2021-06-28T11:25:33Z',
				},
			],
			okta_user_xc: null,
			entra_id_user_xc: null,
			setup_audit_trail_logs: [],
		},
		salesforce_connected_application: null,
		demo_usage_logs: {
			salesforce_user_setup_audit_trail_logs: salesforceUsageLogs[salesforceUserUuids['SALESFORCE_USER_ID_5']],
			salesforce_user_setup_audit_trail_logs_aggregated:
				salesforceUsageLogsAggregated[salesforceUserUuids['SALESFORCE_USER_ID_5']],
		},
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_6']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_6'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: 'sharon.levy@synergy.com',
		literal_friendly_name: 'sharon.levy@synergy.com',
		created_at: '2023-04-17T09:20:50Z',
		updated_at: '2024-10-31T10:03:53Z',
		last_activity: '2024-10-31T07:51:34Z',
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Hybrid,
		source: IdentitySource.SALESFORCE_USER,
		issues: [
			{
				priority: 3,
				id: '1f9a3a82-2256-493f-ac32-35f79c9100a4',
				created_at: '2024-10-15T05:54:45.115215Z',
				last_seen: '2024-11-07T06:03:25.942708Z',
				status: IssueStatus.OPEN,
				issue_name: IssueName.UnFederatedIdentity,
				issue_source: ResourceType.SALESFORCE,
				description:
					"\nA non-federated Salesforce user (**sharon.levy@synergy.com**) detected in the Salesforce instance, which\nrelies on traditional username and password authentication, diverging from Salesforce's security best practices.\nUsing users that are not federated with an Identity Provider (IdP) increases the risk of credential compromise and\ncomplicates access management.\nIt is recommended to implement Single Sign-On (SSO) with a federated IdP for enhanced security and streamlined user management.\n",
				ignore_reason: null,
			},
		],
		tags: [
			{
				id: '30684cbd-a844-4f12-8921-a50088210920',
				created_at: '2024-09-26T05:59:12.831587Z',
				updated_at: '2024-11-07T06:03:07.545273Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'RESOURCE_EXPOSURE',
				impact: 2,
				probability: null,
			},
			{
				id: 'c07b13e2-d8bb-4083-8369-8bb5a90fa228',
				created_at: '2024-09-26T05:59:12.733472Z',
				updated_at: '2024-11-07T06:03:07.122721Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'ADMIN',
				impact: 4,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-07T06:03:07.410293Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
			{
				id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
				created_at: '2024-09-26T05:59:12.856159Z',
				updated_at: '2024-11-07T06:03:07.573034Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
			{
				id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
				created_at: '2024-09-26T05:59:12.799448Z',
				updated_at: '2024-11-07T06:03:07.477276Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: 'Synergy Salesforce',
		affected_environments: null,
		salesforce_user: {
			user_id: '0058e0000017WqoAAE',
			username: 'sharon.levy@synergy.com',
			email: 'sharon.levy@i-levy.com',
			first_name: 'Sharon',
			last_name: 'Levy',
			is_active: true,
			user_type: 'Standard',
			last_login_date: '2024-10-31T07:51:34Z',
			created_by_id: '0054I000006RTR0QAO',
			profile: {
				id: '00e58000000pyUgAAI',
				name: 'System Administrator',
				description: null,
				user_type: 'Standard',
				permissions: {
					PermissionsEditTask: true,
					PermissionsEditEvent: true,
					PermissionsEmailMass: true,
					PermissionsManageCMS: true,
					PermissionsViewRoles: true,
					PermissionsViewSetup: true,
					PermissionsApiEnabled: true,
					PermissionsAuthorApex: true,
					PermissionsDataExport: true,
					PermissionsEditTopics: true,
					PermissionsPackaging2: true,
					PermissionsRunReports: true,
					PermissionsUseWebLink: true,
					PermissionsCanAccessCE: true,
					PermissionsEmailSingle: true,
					PermissionsFreezeUsers: true,
					PermissionsImportLeads: true,
					PermissionsManageCases: true,
					PermissionsManageLeads: true,
					PermissionsManageRoles: true,
					PermissionsManageUsers: true,
					PermissionsMergeTopics: true,
					PermissionsScheduleJob: true,
					PermissionsViewAllData: true,
					PermissionsViewUserPII: true,
					PermissionsAssignTopics: true,
					PermissionsConvertLeads: true,
					PermissionsCreateTopics: true,
					PermissionsDeleteTopics: true,
					PermissionsExportReport: true,
					PermissionsManageMobile: true,
					PermissionsManageQuotas: true,
					PermissionsViewAllUsers: true,
					PermissionsViewHelpLink: true,
					PermissionsViewMLModels: true,
					PermissionsActivateOrder: true,
					PermissionsEditMyReports: true,
					PermissionsListEmailSend: true,
					PermissionsManageSharing: true,
					PermissionsModifyAllData: true,
					PermissionsImportPersonal: true,
					PermissionsManageNetworks: true,
					PermissionsManageSynonyms: true,
					PermissionsMassInlineEdit: true,
					PermissionsModifyMetadata: true,
					PermissionsResetPasswords: true,
					PermissionsSolutionImport: true,
					PermissionsChatterFileLink: true,
					PermissionsEditBillingInfo: true,
					PermissionsFlowUFLRequired: true,
					PermissionsManageSandboxes: true,
					PermissionsManageSolutions: true,
					PermissionsModerateChatter: true,
					PermissionsScheduleReports: true,
					PermissionsSendSitRequests: true,
					PermissionsTraceXdsQueries: true,
					PermissionsTransferAnyCase: true,
					PermissionsTransferAnyLead: true,
					PermissionsViewAllProfiles: true,
					PermissionsViewHealthCheck: true,
					PermissionsActivateContract: true,
					PermissionsActivitiesAccess: true,
					PermissionsApexRestServices: true,
					PermissionsCanVerifyComment: true,
					PermissionsChatterOwnGroups: true,
					PermissionsConsentApiUpdate: true,
					PermissionsCreateMultiforce: true,
					PermissionsCreateWorkspaces: true,
					PermissionsEditCaseComments: true,
					PermissionsEditMyDashboards: true,
					PermissionsManageCategories: true,
					PermissionsNewReportBuilder: true,
					PermissionsPackaging2Delete: true,
					PermissionsViewAllForecasts: true,
					PermissionsViewClientSecret: true,
					PermissionsBulkMacrosAllowed: true,
					PermissionsContentWorkspaces: true,
					PermissionsEditHtmlTemplates: true,
					PermissionsEditPublicFilters: true,
					PermissionsInstallMultiforce: true,
					PermissionsManageCallCenters: true,
					PermissionsManageHealthCheck: true,
					PermissionsManageInteraction: true,
					PermissionsManageIpAddresses: true,
					PermissionsManageTerritories: true,
					PermissionsOverrideForecasts: true,
					PermissionsPrivacyDataAccess: true,
					PermissionsPublishMultiforce: true,
					PermissionsTransferAnyEntity: true,
					PermissionsViewDeveloperName: true,
					PermissionsViewEventLogFiles: true,
					PermissionsViewPublicReports: true,
					PermissionsViewTrustMeasures: true,
					PermissionsChatterEditOwnPost: true,
					PermissionsDelegatedTwoFactor: true,
					PermissionsEditBrandTemplates: true,
					PermissionsEditReadonlyFields: true,
					PermissionsManageCertificates: true,
					PermissionsManageContentTypes: true,
					PermissionsManageDevSandboxes: true,
					PermissionsManagePropositions: true,
					PermissionsManageRemoteAccess: true,
					PermissionsViewDataAssessment: true,
					PermissionsViewDataCategories: true,
					PermissionsViewPlatformEvents: true,
					PermissionsChatterInternalUser: true,
					PermissionsCreateReportFolders: true,
					PermissionsEditActivatedOrders: true,
					PermissionsEditPublicDocuments: true,
					PermissionsEditPublicTemplates: true,
					PermissionsEmailAdministration: true,
					PermissionsEnableNotifications: true,
					PermissionsImportCustomObjects: true,
					PermissionsManageAuthProviders: true,
					PermissionsManageCustomDomains: true,
					PermissionsManageInternalUsers: true,
					PermissionsManageSubscriptions: true,
					PermissionsManageTrustMeasures: true,
					PermissionsMonitorLoginHistory: true,
					PermissionsSubmitMacrosAllowed: true,
					PermissionsAIViewInsightObjects: true,
					PermissionsAssignPermissionSets: true,
					PermissionsClientSecretRotation: true,
					PermissionsContentAdministrator: true,
					PermissionsCreateLtngTempFolder: true,
					PermissionsCustomizeApplication: true,
					PermissionsGiveRecognitionBadge: true,
					PermissionsManageDataCategories: true,
					PermissionsManageExchangeConfig: true,
					PermissionsManageHubConnections: true,
					PermissionsManageReleaseUpdates: true,
					PermissionsManageUnlistedGroups: true,
					PermissionsViewPublicDashboards: true,
					PermissionsChangeDashboardColors: true,
					PermissionsViewDataLeakageEvents: true,
					PermissionsViewMyTeamsDashboards: true,
					PermissionsCreateCustomizeFilters: true,
					PermissionsCreateCustomizeReports: true,
					PermissionsCreateDashboardFolders: true,
					PermissionsDistributeFromPersWksp: true,
					PermissionsExternalClientAppAdmin: true,
					PermissionsManageC360AConnections: true,
					PermissionsManageDataIntegrations: true,
					PermissionsManagePasswordPolicies: true,
					PermissionsShareFilesWithNetworks: true,
					PermissionsTransactionalEmailSend: true,
					PermissionsUseTeamReassignWizards: true,
					PermissionsAddDirectMessageMembers: true,
					PermissionsDeleteActivatedContract: true,
					PermissionsEmailTemplateManagement: true,
					PermissionsExternalClientAppViewer: true,
					PermissionsLightningExperienceUser: true,
					PermissionsManageAnalyticSnapshots: true,
					PermissionsManageContentProperties: true,
					PermissionsManageCustomPermissions: true,
					PermissionsManageCustomReportTypes: true,
					PermissionsManageDynamicDashboards: true,
					PermissionsManageEmailClientConfig: true,
					PermissionsManageMobileAppSecurity: true,
					PermissionsPvtRptsAndDashbds: true,
					PermissionsSendCustomNotifications: true,
					PermissionsChatterEditOwnRecordPost: true,
					PermissionsEditOppLineItemUnitPrice: true,
					PermissionsManageContentPermissions: true,
					PermissionsModifyDataClassification: true,
					PermissionsSubscribeReportRolesGrps: true,
					PermissionsCanInsertFeedSystemFields: true,
					PermissionsCanUseNewDashboardBuilder: true,
					PermissionsCreateCustomizeDashboards: true,
					PermissionsCreateWorkBadgeDefinition: true,
					PermissionsInboundMigrationToolsUser: true,
					PermissionsManageDashbdsInPubFolders: true,
					PermissionsManageLoginAccessPolicies: true,
					PermissionsManageReportsInPubFolders: true,
					PermissionsSelectFilesFromSalesforce: true,
					PermissionsSubscribeReportsRunAsUser: true,
					PermissionsChatterInviteExternalUsers: true,
					PermissionsConnectOrgToEnvironmentHub: true,
					PermissionsEnableCommunityAppLauncher: true,
					PermissionsExternalClientAppDeveloper: true,
					PermissionsManageBusinessHourHolidays: true,
					PermissionsOutboundMigrationToolsUser: true,
					PermissionsRemoveDirectMessageMembers: true,
					PermissionsShowCompanyNameAsUserBadge: true,
					PermissionsManageForecastingCustomData: true,
					PermissionsManageOrchInstsAndWorkItems: true,
					PermissionsSubscribeDashboardRolesGrps: true,
					PermissionsSubscribeReportToOtherUsers: true,
					PermissionsSubscribeToLightningReports: true,
					PermissionsManageProfilesPermissionsets: true,
					PermissionsLightningConsoleAllowedForUser: true,
					PermissionsManageRecommendationStrategies: true,
					PermissionsSubscribeDashboardToOtherUsers: true,
					PermissionsSubscribeToLightningDashboards: true,
				},
				created_date: '2016-03-10T10:53:40Z',
				last_modified_date: '2024-11-06T11:55:37Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '00558000000LZWjAAO',
				last_modified_by_id: '0058e000001AFKGAA4',
				tags: [
					{
						id: '30684cbd-a844-4f12-8921-a50088210920',
						created_at: '2024-09-26T05:59:12.831587Z',
						updated_at: '2024-11-07T06:03:07.545273Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'RESOURCE_EXPOSURE',
						impact: 2,
						probability: null,
					},
					{
						id: '7f590ffe-9b8f-4427-84bf-c06601230908',
						created_at: '2024-09-26T05:59:12.769217Z',
						updated_at: '2024-11-07T06:03:07.410293Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'DATA_ACCESS',
						impact: 3,
						probability: null,
					},
					{
						id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
						created_at: '2024-09-26T05:59:12.856159Z',
						updated_at: '2024-11-07T06:03:07.573034Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'POSSIBLE_PRIVILEGE_ESCALATION',
						impact: 3,
						probability: null,
					},
					{
						id: 'c07b13e2-d8bb-4083-8369-8bb5a90fa228',
						created_at: '2024-09-26T05:59:12.733472Z',
						updated_at: '2024-11-07T06:03:07.122721Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'ADMIN',
						impact: 4,
						probability: null,
					},
					{
						id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
						created_at: '2024-09-26T05:59:12.799448Z',
						updated_at: '2024-11-07T06:03:07.477276Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'CREDENTIAL_EXPOSURE',
						impact: 3,
						probability: null,
					},
				],
			},
			permission_sets: [
				{
					id: '0PS58000000eJixGAE',
					name: 'X00ex00000018ozT_128_09_43_34_1',
					label: '00ex00000018ozT_128_09_43_34_1',
					description: null,
					is_owned_by_profile: true,
					is_custom: false,
					type: 'Profile',
					license_id: '10058000000ZIk5AAG',
					profile_id: '00e58000000pyUgAAI',
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsEditTask: true,
						PermissionsEditEvent: true,
						PermissionsEmailMass: true,
						PermissionsManageCMS: true,
						PermissionsViewRoles: true,
						PermissionsViewSetup: true,
						PermissionsApiEnabled: true,
						PermissionsAuthorApex: true,
						PermissionsDataExport: true,
						PermissionsEditTopics: true,
						PermissionsPackaging2: true,
						PermissionsRunReports: true,
						PermissionsUseWebLink: true,
						PermissionsCanAccessCE: true,
						PermissionsEmailSingle: true,
						PermissionsFreezeUsers: true,
						PermissionsImportLeads: true,
						PermissionsManageCases: true,
						PermissionsManageLeads: true,
						PermissionsManageRoles: true,
						PermissionsManageUsers: true,
						PermissionsMergeTopics: true,
						PermissionsScheduleJob: true,
						PermissionsViewAllData: true,
						PermissionsViewUserPII: true,
						PermissionsAssignTopics: true,
						PermissionsConvertLeads: true,
						PermissionsCreateTopics: true,
						PermissionsDeleteTopics: true,
						PermissionsExportReport: true,
						PermissionsManageMobile: true,
						PermissionsManageQuotas: true,
						PermissionsViewAllUsers: true,
						PermissionsViewHelpLink: true,
						PermissionsViewMLModels: true,
						PermissionsActivateOrder: true,
						PermissionsEditMyReports: true,
						PermissionsListEmailSend: true,
						PermissionsManageSharing: true,
						PermissionsModifyAllData: true,
						PermissionsImportPersonal: true,
						PermissionsManageNetworks: true,
						PermissionsManageSynonyms: true,
						PermissionsMassInlineEdit: true,
						PermissionsModifyMetadata: true,
						PermissionsResetPasswords: true,
						PermissionsSolutionImport: true,
						PermissionsChatterFileLink: true,
						PermissionsCreatePackaging: true,
						PermissionsEditBillingInfo: true,
						PermissionsFlowUFLRequired: true,
						PermissionsManageSandboxes: true,
						PermissionsManageSolutions: true,
						PermissionsModerateChatter: true,
						PermissionsScheduleReports: true,
						PermissionsSendSitRequests: true,
						PermissionsTraceXdsQueries: true,
						PermissionsTransferAnyCase: true,
						PermissionsTransferAnyLead: true,
						PermissionsViewAllProfiles: true,
						PermissionsViewHealthCheck: true,
						PermissionsActivateContract: true,
						PermissionsActivitiesAccess: true,
						PermissionsApexRestServices: true,
						PermissionsCanVerifyComment: true,
						PermissionsChatterOwnGroups: true,
						PermissionsConsentApiUpdate: true,
						PermissionsCreateWorkspaces: true,
						PermissionsEditCaseComments: true,
						PermissionsEditMyDashboards: true,
						PermissionsInstallPackaging: true,
						PermissionsManageCategories: true,
						PermissionsNewReportBuilder: true,
						PermissionsPackaging2Delete: true,
						PermissionsPublishPackaging: true,
						PermissionsViewAllForecasts: true,
						PermissionsViewClientSecret: true,
						PermissionsBulkMacrosAllowed: true,
						PermissionsContentWorkspaces: true,
						PermissionsEditHtmlTemplates: true,
						PermissionsEditPublicFilters: true,
						PermissionsManageCallCenters: true,
						PermissionsManageHealthCheck: true,
						PermissionsManageInteraction: true,
						PermissionsManageIpAddresses: true,
						PermissionsManageTerritories: true,
						PermissionsOverrideForecasts: true,
						PermissionsPrivacyDataAccess: true,
						PermissionsTransferAnyEntity: true,
						PermissionsViewDeveloperName: true,
						PermissionsViewEventLogFiles: true,
						PermissionsViewPublicReports: true,
						PermissionsViewTrustMeasures: true,
						PermissionsChatterEditOwnPost: true,
						PermissionsDelegatedTwoFactor: true,
						PermissionsEditBrandTemplates: true,
						PermissionsEditReadonlyFields: true,
						PermissionsManageCertificates: true,
						PermissionsManageContentTypes: true,
						PermissionsManageDevSandboxes: true,
						PermissionsManagePropositions: true,
						PermissionsManageRemoteAccess: true,
						PermissionsViewDataAssessment: true,
						PermissionsViewDataCategories: true,
						PermissionsViewPlatformEvents: true,
						PermissionsChatterInternalUser: true,
						PermissionsCreateReportFolders: true,
						PermissionsEditActivatedOrders: true,
						PermissionsEditPublicDocuments: true,
						PermissionsEditPublicTemplates: true,
						PermissionsEmailAdministration: true,
						PermissionsEnableNotifications: true,
						PermissionsImportCustomObjects: true,
						PermissionsManageAuthProviders: true,
						PermissionsManageCustomDomains: true,
						PermissionsManageInternalUsers: true,
						PermissionsManageSubscriptions: true,
						PermissionsManageTrustMeasures: true,
						PermissionsMonitorLoginHistory: true,
						PermissionsSubmitMacrosAllowed: true,
						PermissionsAIViewInsightObjects: true,
						PermissionsAssignPermissionSets: true,
						PermissionsClientSecretRotation: true,
						PermissionsContentAdministrator: true,
						PermissionsCreateLtngTempFolder: true,
						PermissionsCustomizeApplication: true,
						PermissionsGiveRecognitionBadge: true,
						PermissionsManageDataCategories: true,
						PermissionsManageExchangeConfig: true,
						PermissionsManageHubConnections: true,
						PermissionsManageReleaseUpdates: true,
						PermissionsManageUnlistedGroups: true,
						PermissionsViewPublicDashboards: true,
						PermissionsChangeDashboardColors: true,
						PermissionsViewDataLeakageEvents: true,
						PermissionsViewMyTeamsDashboards: true,
						PermissionsCreateCustomizeFilters: true,
						PermissionsCreateCustomizeReports: true,
						PermissionsCreateDashboardFolders: true,
						PermissionsDistributeFromPersWksp: true,
						PermissionsExternalClientAppAdmin: true,
						PermissionsManageC360AConnections: true,
						PermissionsManageDataIntegrations: true,
						PermissionsManagePasswordPolicies: true,
						PermissionsShareFilesWithNetworks: true,
						PermissionsTransactionalEmailSend: true,
						PermissionsUseTeamReassignWizards: true,
						PermissionsAddDirectMessageMembers: true,
						PermissionsDeleteActivatedContract: true,
						PermissionsEmailTemplateManagement: true,
						PermissionsExternalClientAppViewer: true,
						PermissionsLightningExperienceUser: true,
						PermissionsManageAnalyticSnapshots: true,
						PermissionsManageContentProperties: true,
						PermissionsManageCustomPermissions: true,
						PermissionsManageCustomReportTypes: true,
						PermissionsManageDynamicDashboards: true,
						PermissionsManageEmailClientConfig: true,
						PermissionsManageMobileAppSecurity: true,
						PermissionsManagePvtRptsAndDashbds: true,
						PermissionsSendCustomNotifications: true,
						PermissionsChatterEditOwnRecordPost: true,
						PermissionsEditOppLineItemUnitPrice: true,
						PermissionsManageContentPermissions: true,
						PermissionsModifyDataClassification: true,
						PermissionsSubscribeReportRolesGrps: true,
						PermissionsCanInsertFeedSystemFields: true,
						PermissionsCanUseNewDashboardBuilder: true,
						PermissionsCreateCustomizeDashboards: true,
						PermissionsCreateWorkBadgeDefinition: true,
						PermissionsInboundMigrationToolsUser: true,
						PermissionsManageDashbdsInPubFolders: true,
						PermissionsManageLoginAccessPolicies: true,
						PermissionsManageReportsInPubFolders: true,
						PermissionsSelectFilesFromSalesforce: true,
						PermissionsSubscribeReportsRunAsUser: true,
						PermissionsChatterInviteExternalUsers: true,
						PermissionsConnectOrgToEnvironmentHub: true,
						PermissionsEnableCommunityAppLauncher: true,
						PermissionsExternalClientAppDeveloper: true,
						PermissionsManageBusinessHourHolidays: true,
						PermissionsOutboundMigrationToolsUser: true,
						PermissionsRemoveDirectMessageMembers: true,
						PermissionsShowCompanyNameAsUserBadge: true,
						PermissionsManageForecastingCustomData: true,
						PermissionsManageOrchInstsAndWorkItems: true,
						PermissionsSubscribeDashboardRolesGrps: true,
						PermissionsSubscribeReportToOtherUsers: true,
						PermissionsSubscribeToLightningReports: true,
						PermissionsManageProfilesPermissionsets: true,
						PermissionsLightningConsoleAllowedForUser: true,
						PermissionsManageRecommendationStrategies: true,
						PermissionsSubscribeDashboardToOtherUsers: true,
						PermissionsSubscribeToLightningDashboards: true,
					},
					created_date: '2016-03-10T10:53:40Z',
					last_modified_date: '2024-11-06T11:55:37Z',
					system_modstamp: '2024-11-06T11:55:37Z',
					created_by_id: '00558000000LZWjAAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS58000000eJixGAE',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '30684cbd-a844-4f12-8921-a50088210920',
							created_at: '2024-09-26T05:59:12.831587Z',
							updated_at: '2024-11-07T06:03:07.545273Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'RESOURCE_EXPOSURE',
							impact: 2,
							probability: null,
						},
						{
							id: '7f590ffe-9b8f-4427-84bf-c06601230908',
							created_at: '2024-09-26T05:59:12.769217Z',
							updated_at: '2024-11-07T06:03:07.410293Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'DATA_ACCESS',
							impact: 3,
							probability: null,
						},
						{
							id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
							created_at: '2024-09-26T05:59:12.799448Z',
							updated_at: '2024-11-07T06:03:07.477276Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'CREDENTIAL_EXPOSURE',
							impact: 3,
							probability: null,
						},
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-07T06:03:07.573034Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
				{
					id: '0PSRz00000031afOAA',
					name: 'Zoom_Admin',
					label: 'Zoom Admin',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: null,
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'ZVC',
					has_activation_required: false,
					permissions: {},
					created_date: '2024-05-27T10:58:44Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0058e000001AFKGAA4',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PSRz00000031afOAA',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PSRz00000031agOAA',
					name: 'Zoom_Contact_Center_Admin',
					label: 'Zoom Contact Center Admin',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: null,
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'ZVC',
					has_activation_required: false,
					permissions: {},
					created_date: '2024-05-27T10:58:47Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0058e000001AFKGAA4',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PSRz00000031agOAA',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PSRz00000031akOAA',
					name: 'Zoom_Phone_User',
					label: 'Zoom Phone User',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: null,
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'ZVC',
					has_activation_required: false,
					permissions: {},
					created_date: '2024-05-27T10:58:52Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0058e000001AFKGAA4',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PSRz00000031akOAA',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PSRz0000001YpPOAU',
					name: 'HighVelocitySalesUserIncluded',
					label: 'Sales Engagement User Included',
					description:
						'Lets users access the Sales Engagement app, add buyers to Cadences, and contact them via Work Queue.',
					is_owned_by_profile: false,
					is_custom: false,
					type: 'Standard',
					license_id: '0PLRz0000000ZE0OAM',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'force',
					has_activation_required: false,
					permissions: {
						PermissionsRunFlow: true,
						PermissionsEditTask: true,
						PermissionsEmailSingle: true,
						PermissionsLeadScoreUser: true,
						PermissionsSalesforceIQInbox: true,
						PermissionsHighVelocitySalesUser: true,
						PermissionsVideoConferenceMeetUser: true,
						PermissionsVideoConferenceZoomUser: true,
						PermissionsViewOnlyEmbeddedAppUser: true,
						PermissionsVideoConferenceTeamsUser: true,
					},
					created_date: '2024-02-11T22:04:23Z',
					last_modified_date: '2024-10-27T23:09:26Z',
					system_modstamp: '2024-10-27T23:09:26Z',
					created_by_id: '00558000000qeXOAAY',
					last_modified_by_id: '00558000000qeXOAAY',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PSRz0000001YpPOAU',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '30684cbd-a844-4f12-8921-a50088210920',
							created_at: '2024-09-26T05:59:12.831587Z',
							updated_at: '2024-11-07T06:03:07.545273Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'RESOURCE_EXPOSURE',
							impact: 2,
							probability: null,
						},
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-07T06:03:07.573034Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
				{
					id: '0PSRz0000001YpNOAU',
					name: 'HighVelocitySalesCadenceCreatorIncluded',
					label: 'Sales Engagement Cadence Creator Included',
					description:
						'Lets sales manager users access the Sales Engagement app, and create and edit Cadences.',
					is_owned_by_profile: false,
					is_custom: false,
					type: 'Standard',
					license_id: '0PLRz0000000ZE0OAM',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'force',
					has_activation_required: false,
					permissions: {
						PermissionsRunFlow: true,
						PermissionsEditTask: true,
						PermissionsEmailSingle: true,
						PermissionsLeadScoreUser: true,
						PermissionsSalesforceIQInbox: true,
						PermissionsCreateQuickCadence: true,
						PermissionsCreateLtngTempInPub: true,
						PermissionsCreateStandardCadence: true,
						PermissionsHighVelocitySalesUser: true,
						PermissionsVideoConferenceMeetUser: true,
						PermissionsVideoConferenceZoomUser: true,
						PermissionsViewOnlyEmbeddedAppUser: true,
						PermissionsVideoConferenceTeamsUser: true,
					},
					created_date: '2024-02-11T22:04:23Z',
					last_modified_date: '2024-10-27T23:09:26Z',
					system_modstamp: '2024-10-27T23:09:26Z',
					created_by_id: '00558000000qeXOAAY',
					last_modified_by_id: '00558000000qeXOAAY',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PSRz0000001YpNOAU',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '30684cbd-a844-4f12-8921-a50088210920',
							created_at: '2024-09-26T05:59:12.831587Z',
							updated_at: '2024-11-07T06:03:07.545273Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'RESOURCE_EXPOSURE',
							impact: 2,
							probability: null,
						},
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-07T06:03:07.573034Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
				{
					id: '0PSRz0000001YpOOAU',
					name: 'HighVelocitySalesQuickCadenceCreatorIncluded',
					label: 'Sales Engagement Quick Cadence Creator Included',
					description: 'Lets reps access the Sales Engagement app, and create and edit Quick Cadences.',
					is_owned_by_profile: false,
					is_custom: false,
					type: 'Standard',
					license_id: '0PLRz0000000ZE0OAM',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'force',
					has_activation_required: false,
					permissions: {
						PermissionsRunFlow: true,
						PermissionsEditTask: true,
						PermissionsEmailSingle: true,
						PermissionsLeadScoreUser: true,
						PermissionsSalesforceIQInbox: true,
						PermissionsCreateQuickCadence: true,
						PermissionsCreateLtngTempInPub: true,
						PermissionsHighVelocitySalesUser: true,
						PermissionsVideoConferenceMeetUser: true,
						PermissionsVideoConferenceZoomUser: true,
						PermissionsViewOnlyEmbeddedAppUser: true,
						PermissionsVideoConferenceTeamsUser: true,
					},
					created_date: '2024-02-11T22:04:23Z',
					last_modified_date: '2024-10-27T23:09:26Z',
					system_modstamp: '2024-10-27T23:09:26Z',
					created_by_id: '00558000000qeXOAAY',
					last_modified_by_id: '00558000000qeXOAAY',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PSRz0000001YpOOAU',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '30684cbd-a844-4f12-8921-a50088210920',
							created_at: '2024-09-26T05:59:12.831587Z',
							updated_at: '2024-11-07T06:03:07.545273Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'RESOURCE_EXPOSURE',
							impact: 2,
							probability: null,
						},
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-07T06:03:07.573034Z',
							source: 'TOKEN',
							related_resource_type: 'SALESFORFORCE',
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
				{
					id: '0PSRz0000001YpFOAU',
					name: 'AutomatedActionsUser',
					label: 'Automated Actions User',
					description: 'Lets users access Automated Actions.',
					is_owned_by_profile: false,
					is_custom: false,
					type: 'Standard',
					license_id: '0PLRz0000000ZDwOAM',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'force',
					has_activation_required: false,
					permissions: {
						PermissionsAutomatedActionsUser: true,
					},
					created_date: '2024-02-11T22:04:23Z',
					last_modified_date: '2024-10-27T23:09:26Z',
					system_modstamp: '2024-10-27T23:09:26Z',
					created_by_id: '00558000000qeXOAAY',
					last_modified_by_id: '00558000000qeXOAAY',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PSRz0000001YpFOAU',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PSRz0000001YpQOAU',
					name: 'InboxIncluded',
					label: 'Inbox Included',
					description: 'Grants access to use Inbox.',
					is_owned_by_profile: false,
					is_custom: false,
					type: 'Standard',
					license_id: '0PLRz0000000ZE0OAM',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'force',
					has_activation_required: false,
					permissions: {
						PermissionsSalesforceIQInbox: true,
						PermissionsVideoConferenceMeetUser: true,
						PermissionsVideoConferenceZoomUser: true,
						PermissionsVideoConferenceTeamsUser: true,
					},
					created_date: '2024-02-11T22:04:23Z',
					last_modified_date: '2024-10-27T23:09:26Z',
					system_modstamp: '2024-10-27T23:09:26Z',
					created_by_id: '00558000000qeXOAAY',
					last_modified_by_id: '00558000000qeXOAAY',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PSRz0000001YpQOAU',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PS8e000000kACPGA2',
					name: 'Campaign_Influence',
					label: 'Campaign Influence',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: '0PL4I000000961tWAA',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsCampaignInfluence2: true,
					},
					created_date: '2022-08-18T18:38:44Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0058e000001AFKGAA4',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000kACPGA2',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PS8e000000k9vTGAQ',
					name: 'Query_All_Files',
					label: 'Query All Files',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: '10058000000ZIk5AAG',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsViewRoles: true,
						PermissionsViewSetup: true,
						PermissionsViewAllData: true,
						PermissionsQueryAllFiles: true,
						PermissionsViewAllForecasts: true,
						PermissionsViewEventLogFiles: true,
						PermissionsViewPublicReports: true,
						PermissionsViewPlatformEvents: true,
						PermissionsViewPublicDashboards: true,
					},
					created_date: '2021-11-14T06:45:59Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0054I000006RTR0QAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000k9vTGAQ',
						type: 'PermissionSet',
					},
					tags: [],
				},
			],
			ownership_records: [
				{
					owner: {
						id: '0054I000006RTR0QAO',
						username: 'shira@synergy.com',
						email: 'shira@synergy.com',
						first_name: 'Shira',
						last_name: 'Kal',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '00558000000LZWjAAO',
						last_modified_by_id: '0054I000006RTR0QAO',
						created_at: '2020-06-07T08:23:52Z',
						last_modified_date: '2024-06-27T13:31:16Z',
						last_login_date: '2024-11-07T02:00:14Z',
					},
					action_type: 'Create',
					last_modified_date: '2023-04-17T09:20:50Z',
				},
				{
					owner: {
						id: '0058e0000017WqoAAE',
						username: 'sharon.levy@synergy.com',
						email: 'sharon.levy@synergy.com',
						first_name: 'Sharon',
						last_name: 'Levy',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '0054I000006RTR0QAO',
						last_modified_by_id: '0058e0000017WqoAAE',
						created_at: '2023-04-17T09:20:50Z',
						last_modified_date: '2024-10-31T10:03:53Z',
						last_login_date: '2024-10-31T07:51:34Z',
					},
					action_type: 'Update',
					last_modified_date: '2024-10-31T10:03:53Z',
				},
			],
			okta_user_xc: null,
			entra_id_user_xc: null,
			setup_audit_trail_logs: [],
		},
		salesforce_connected_application: null,
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_7']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_7'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: 'insights@synergy.com',
		literal_friendly_name: 'insights@synergy.com',
		created_at: '2024-03-31T09:12:39Z',
		updated_at: '2024-03-31T09:12:39Z',
		last_activity: null,
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Machine,
		source: IdentitySource.SALESFORCE_USER,
		issues: [
			{
				priority: 2,
				id: 'c1353fc1-825e-46d4-8a69-fe1d94efe514',
				created_at: '2024-09-26T05:59:15.203019Z',
				last_seen: '2024-11-07T06:03:25.941918Z',
				status: IssueStatus.OPEN,
				issue_name: IssueName.InactiveIdentity,
				issue_source: ResourceType.SALESFORCE,
				description:
					"\nThe user has not logged in since **2024-03-31 09:12:39**.\nInactive users in Salesforce are accounts that haven't shown activity for an extended period, potentially posing security risks and consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended to maintain system security and optimize license usage.\n",
				ignore_reason: null,
			},
		],
		tags: [
			{
				id: '30684cbd-a844-4f12-8921-a50088210920',
				created_at: '2024-09-26T05:59:12.831587Z',
				updated_at: '2024-11-07T06:03:07.545273Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'RESOURCE_EXPOSURE',
				impact: 2,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-07T06:03:07.410293Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
			{
				id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
				created_at: '2024-09-26T05:59:12.856159Z',
				updated_at: '2024-11-07T06:03:07.573034Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
			{
				id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
				created_at: '2024-09-26T05:59:12.799448Z',
				updated_at: '2024-11-07T06:03:07.477276Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'https://synergy.salesforce.com',
		account_literal_friendly_name: 'Synergy Salesforce',
		affected_environments: null,
		salesforce_user: {
			user_id: '005Rz000001NivpIAC',
			username: 'insights@synergy.com',
			email: 'noreply@salesforce.com',
			first_name: 'Security',
			last_name: 'User',
			is_active: true,
			user_type: 'Standard',
			last_login_date: null,
			created_by_id: '00558000000qeXNAAY',
			profile: {
				id: '00e8e0000011xdlAAA',
				name: 'Analytics Cloud Security User',
				description: null,
				user_type: 'Standard',
				permissions: {
					PermissionsViewRoles: true,
					PermissionsViewSetup: true,
					PermissionsApiEnabled: true,
					PermissionsUseWebLink: true,
					PermissionsApiUserOnly: true,
					PermissionsViewAllUsers: true,
					PermissionsInsightsAppUser: true,
					PermissionsViewAllProfiles: true,
					PermissionsApexRestServices: true,
					PermissionsInsightsAppAdmin: true,
					PermissionsViewDeveloperName: true,
					PermissionsViewEventLogFiles: true,
					PermissionsCampaignInfluence2: true,
					PermissionsViewPlatformEvents: true,
					PermissionsPasswordNeverExpires: true,
					PermissionsViewDataLeakageEvents: true,
					PermissionsAddDirectMessageMembers: true,
					PermissionsViewForecastingPredictive: true,
					PermissionsEnableCommunityAppLauncher: true,
					PermissionsRemoveDirectMessageMembers: true,
				},
				created_date: '2023-10-13T23:01:36Z',
				last_modified_date: '2024-11-06T11:55:37Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '00558000000qeXOAAY',
				last_modified_by_id: '0058e000001AFKGAA4',
				tags: [
					{
						id: '30684cbd-a844-4f12-8921-a50088210920',
						created_at: '2024-09-26T05:59:12.831587Z',
						updated_at: '2024-11-07T06:03:07.545273Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'RESOURCE_EXPOSURE',
						impact: 2,
						probability: null,
					},
					{
						id: '7f590ffe-9b8f-4427-84bf-c06601230908',
						created_at: '2024-09-26T05:59:12.769217Z',
						updated_at: '2024-11-07T06:03:07.410293Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'DATA_ACCESS',
						impact: 3,
						probability: null,
					},
					{
						id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
						created_at: '2024-09-26T05:59:12.799448Z',
						updated_at: '2024-11-07T06:03:07.477276Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'CREDENTIAL_EXPOSURE',
						impact: 3,
						probability: null,
					},
					{
						id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
						created_at: '2024-09-26T05:59:12.856159Z',
						updated_at: '2024-11-07T06:03:07.573034Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'POSSIBLE_PRIVILEGE_ESCALATION',
						impact: 3,
						probability: null,
					},
				],
			},
			permission_sets: [
				{
					id: '0PS8e000000w99VGAQ',
					name: 'X00e8e0000011xdlAAA',
					label: '00e8e0000011xdlAAA',
					description: null,
					is_owned_by_profile: true,
					is_custom: false,
					type: 'Profile',
					license_id: '1008e000000g9jDAAQ',
					profile_id: '00e8e0000011xdlAAA',
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsViewRoles: true,
						PermissionsViewSetup: true,
						PermissionsApiEnabled: true,
						PermissionsUseWebLink: true,
						PermissionsApiUserOnly: true,
						PermissionsViewAllUsers: true,
						PermissionsInsightsAppUser: true,
						PermissionsViewAllProfiles: true,
						PermissionsApexRestServices: true,
						PermissionsInsightsAppAdmin: true,
						PermissionsViewDeveloperName: true,
						PermissionsViewEventLogFiles: true,
						PermissionsCampaignInfluence2: true,
						PermissionsViewPlatformEvents: true,
						PermissionsPasswordNeverExpires: true,
						PermissionsViewDataLeakageEvents: true,
						PermissionsAddDirectMessageMembers: true,
						PermissionsViewForecastingPredictive: true,
						PermissionsEnableCommunityAppLauncher: true,
						PermissionsRemoveDirectMessageMembers: true,
					},
					created_date: '2023-10-13T23:01:36Z',
					last_modified_date: '2024-11-06T11:55:37Z',
					system_modstamp: '2024-11-06T11:55:37Z',
					created_by_id: '00558000000qeXOAAY',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000w99VGAQ',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '30684cbd-a844-4f12-8921-a50088210920',
							created_at: '2024-09-26T05:59:12.831587Z',
							updated_at: '2024-11-07T06:03:07.545273Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'RESOURCE_EXPOSURE',
							impact: 2,
							probability: null,
						},
						{
							id: '7f590ffe-9b8f-4427-84bf-c06601230908',
							created_at: '2024-09-26T05:59:12.769217Z',
							updated_at: '2024-11-07T06:03:07.410293Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'DATA_ACCESS',
							impact: 3,
							probability: null,
						},
						{
							id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
							created_at: '2024-09-26T05:59:12.799448Z',
							updated_at: '2024-11-07T06:03:07.477276Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'CREDENTIAL_EXPOSURE',
							impact: 3,
							probability: null,
						},
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-07T06:03:07.573034Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
			],
			ownership_records: [
				{
					owner: {
						id: '00558000000qeXNAAY',
						username: 'autoproc@00d0000ykeaw',
						email: 'rsandoval@00d0000ykeaw',
						first_name: 'Automated',
						last_name: 'Process',
						is_active: true,
						user_type: 'AutomatedProcess',
						profile_id: '00e58000000pyUiAAI',
						created_by_id: '00558000000qeXOAAY',
						last_modified_by_id: '00558000000qeXOAAY',
						created_at: '2016-03-10T10:53:40Z',
						last_modified_date: '2016-03-10T10:53:40Z',
						last_login_date: null,
					},
					action_type: 'Create',
					last_modified_date: '2024-03-31T09:12:39Z',
				},
				{
					owner: {
						id: '00558000000qeXNAAY',
						username: 'autoproc@00d0000ykeaw',
						email: 'rsandoval@00d0000ykeaw',
						first_name: 'Automated',
						last_name: 'Process',
						is_active: true,
						user_type: 'AutomatedProcess',
						profile_id: '00e58000000pyUiAAI',
						created_by_id: '00558000000qeXOAAY',
						last_modified_by_id: '00558000000qeXOAAY',
						created_at: '2016-03-10T10:53:40Z',
						last_modified_date: '2016-03-10T10:53:40Z',
						last_login_date: null,
					},
					action_type: 'Update',
					last_modified_date: '2024-03-31T09:12:39Z',
				},
			],
			okta_user_xc: null,
			entra_id_user_xc: null,
			setup_audit_trail_logs: [],
		},
		salesforce_connected_application: null,
		demo_usage_logs: {
			salesforce_user_setup_audit_trail_logs: salesforceUsageLogs[salesforceUserUuids['SALESFORCE_USER_ID_7']],
			salesforce_user_setup_audit_trail_logs_aggregated:
				salesforceUsageLogsAggregated[salesforceUserUuids['SALESFORCE_USER_ID_7']],
		},
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_8']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_8'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: 'derek.lee@synergy.com',
		literal_friendly_name: 'derek.lee@synergy.com',
		created_at: '2018-12-26T10:21:48Z',
		updated_at: '2024-11-06T13:32:11Z',
		last_activity: '2024-11-07T00:45:30Z',
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Hybrid,
		source: IdentitySource.SALESFORCE_USER,
		issues: [
			{
				priority: 2,
				id: 'b203c001-68d8-42e3-a21e-e75fcaacb160',
				created_at: '2024-10-15T05:54:45.114831Z',
				last_seen: '2024-11-07T06:03:25.942708Z',
				status: IssueStatus.OPEN,
				issue_name: IssueName.UnFederatedIdentity,
				issue_source: ResourceType.SALESFORCE,
				description:
					"\nA non-federated Salesforce user (**derek.lee@synergy.com**) detected in the Salesforce instance, which\nrelies on traditional username and password authentication, diverging from Salesforce's security best practices.\nUsing users that are not federated with an Identity Provider (IdP) increases the risk of credential compromise and\ncomplicates access management.\nIt is recommended to implement Single Sign-On (SSO) with a federated IdP for enhanced security and streamlined user management.\n",
				ignore_reason: null,
			},
		],
		tags: [
			{
				id: '30684cbd-a844-4f12-8921-a50088210920',
				created_at: '2024-09-26T05:59:12.831587Z',
				updated_at: '2024-11-07T06:03:07.545273Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'RESOURCE_EXPOSURE',
				impact: 2,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-07T06:03:07.410293Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
			{
				id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
				created_at: '2024-09-26T05:59:12.856159Z',
				updated_at: '2024-11-07T06:03:07.573034Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
			{
				id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
				created_at: '2024-09-26T05:59:12.799448Z',
				updated_at: '2024-11-07T06:03:07.477276Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: 'Synergy Salesforce',
		affected_environments: null,
		salesforce_user: {
			user_id: '005580000048ACJAA2',
			username: 'derek.lee@synergy.com,',
			email: 'derek.lee@synergy.com,',
			first_name: 'Derek',
			last_name: 'Lee',
			is_active: true,
			user_type: 'Standard',
			last_login_date: '2024-11-07T00:45:30Z',
			created_by_id: '00558000000LZWjAAO',
			profile: {
				id: '00e8e000000xUpyAAE',
				name: 'Sales Rep',
				description: null,
				user_type: 'Standard',
				permissions: {
					PermissionsRunFlow: true,
					PermissionsEditTask: true,
					PermissionsEditEvent: true,
					PermissionsEmailMass: true,
					PermissionsViewRoles: true,
					PermissionsApiEnabled: true,
					PermissionsEditTopics: true,
					PermissionsRunReports: true,
					PermissionsUseWebLink: true,
					PermissionsEmailSingle: true,
					PermissionsAssignTopics: true,
					PermissionsConvertLeads: true,
					PermissionsCreateTopics: true,
					PermissionsExportReport: true,
					PermissionsViewAllUsers: true,
					PermissionsViewHelpLink: true,
					PermissionsListEmailSend: true,
					PermissionsContentHubUser: true,
					PermissionsMassInlineEdit: true,
					PermissionsChatterFileLink: true,
					PermissionsFlowUFLRequired: true,
					PermissionsActivitiesAccess: true,
					PermissionsApexRestServices: true,
					PermissionsChatterOwnGroups: true,
					PermissionsEditMyDashboards: true,
					PermissionsEditHtmlTemplates: true,
					PermissionsOverrideForecasts: true,
					PermissionsChatterEditOwnPost: true,
					PermissionsChatterInternalUser: true,
					PermissionsEmailAdministration: true,
					PermissionsEnableNotifications: true,
					PermissionsViewPublicDashboards: true,
					PermissionsCreateCustomizeFilters: true,
					PermissionsCreateCustomizeReports: true,
					PermissionsCreateDashboardFolders: true,
					PermissionsDistributeFromPersWksp: true,
					PermissionsShareFilesWithNetworks: true,
					PermissionsTransactionalEmailSend: true,
					PermissionsCreateReportInLightning: true,
					PermissionsEmailTemplateManagement: true,
					PermissionsLightningExperienceUser: true,
					PermissionsEditOppLineItemUnitPrice: true,
					PermissionsPreventClassicExperience: true,
					PermissionsCreateCustomizeDashboards: true,
					PermissionsSelectFilesFromSalesforce: true,
					PermissionsShowCompanyNameAsUserBadge: true,
					PermissionsSubscribeToLightningReports: true,
					PermissionsLightningConsoleAllowedForUser: true,
				},
				created_date: '2022-02-16T19:30:58Z',
				last_modified_date: '2024-11-06T11:55:37Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '0054I000006RTR0QAO',
				last_modified_by_id: '0058e000001AFKGAA4',
				tags: [
					{
						id: '30684cbd-a844-4f12-8921-a50088210920',
						created_at: '2024-09-26T05:59:12.831587Z',
						updated_at: '2024-11-07T06:03:07.545273Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'RESOURCE_EXPOSURE',
						impact: 2,
						probability: null,
					},
				],
			},
			permission_sets: [
				{
					id: '0PS8e000000k9x5GAA',
					name: 'Enforce_SSO',
					label: 'Enforce SSO',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: '10058000000ZIk5AAG',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsIsSsoEnabled: true,
					},
					created_date: '2022-01-19T12:17:54Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0054I000006RTR0QAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000k9x5GAA',
						type: 'PermissionSet',
					},
					tags: null,
				},
			],
			ownership_records: [
				{
					owner: {
						id: '00558000000LZWjAAO',
						username: 'kelly.aviv@synergy.com',
						email: 'kelly.aviv@synergy.com',
						first_name: 'Kelly',
						last_name: 'Aviv',
						is_active: false,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '00558000000LZWjAAO',
						last_modified_by_id: '0058e000001AFKGAA4',
						created_at: '2016-03-10T10:53:40Z',
						last_modified_date: '2023-01-25T11:45:54Z',
						last_login_date: '2022-03-21T08:44:54Z',
					},
					action_type: 'Create',
					last_modified_date: '2018-12-26T10:21:48Z',
				},
			],
			okta_user_xc: null,
			entra_id_user_xc: null,
			setup_audit_trail_logs: [],
		},
		salesforce_connected_application: null,
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_9']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_9'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: '0H4580000005TdlCAE',
		literal_friendly_name: 'Salesforce for iOS',
		created_at: '2016-03-10T10:53:40Z',
		updated_at: null,
		last_activity: null,
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Machine,
		source: IdentitySource.SALESFORCE_CONNECTED_APPLICATION,
		issues: [],
		tags: [],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: 'Synergy Salesforce',
		affected_environments: null,
		salesforce_user: null,
		salesforce_connected_application: {
			name: 'Salesforce for iOS',
			connected_application_id: '0H4580000005TdlCAE',
			created_by_id: '00558000000qeXNAAY',
			last_modified_by_id: '00558000000qeXNAAY',
			options_allow_admin_approved_users_only: false,
			options_refresh_token_validity_metric: false,
			options_has_session_level_policy: false,
			options_is_internal: false,
			options_full_content_push_notifications: true,
			options_code_credential_guest_enabled: false,
			options_app_issue_jwt_token_enabled: false,
			options_token_exchange_manage_bit_enabled: false,
			start_url: null,
			refresh_token_validity_period: null,
			bound_salesforce_user: null,
			app_metadata: null,
			ownership_records: [
				{
					owner: {
						id: '00558000000qeXNAAY',
						username: 'autoproc@00d58000000yk4leaw',
						email: 'rsandoval@00d58000000yk4leaw',
						first_name: 'Automated',
						last_name: 'Process',
						is_active: true,
						user_type: 'AutomatedProcess',
						profile_id: '00e58000000pyUiAAI',
						created_by_id: '00558000000qeXOAAY',
						last_modified_by_id: '00558000000qeXOAAY',
						created_at: '2016-03-10T10:53:40Z',
						last_modified_date: '2016-03-10T10:53:40Z',
						last_login_date: null,
					},
					action_type: 'Create',
					last_modified_date: '2016-03-10T10:53:40Z',
				},
				{
					owner: {
						id: '00558000000qeXNAAY',
						username: 'autoproc@00d58000000yk4leaw',
						email: 'rsandoval@00d58000000yk4leaw',
						first_name: 'Automated',
						last_name: 'Process',
						is_active: true,
						user_type: 'AutomatedProcess',
						profile_id: '00e58000000pyUiAAI',
						created_by_id: '00558000000qeXOAAY',
						last_modified_by_id: '00558000000qeXOAAY',
						created_at: '2016-03-10T10:53:40Z',
						last_modified_date: '2016-03-10T10:53:40Z',
						last_login_date: null,
					},
					action_type: 'Update',
					last_modified_date: '2024-10-16T02:44:40Z',
				},
			],
			api_total_usage_logs: [],
		},
		demo_usage_logs: {
			salesforce_connected_app_api_total_usage_logs:
				salesforceApiUsageLogs[salesforceUserUuids['SALESFORCE_USER_ID_9']],
			salesforce_connected_app_api_total_usage_logs_aggregated:
				salesforceApiUsageLogsAggregated[salesforceUserUuids['SALESFORCE_USER_ID_9']],
		},
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_10']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_10'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: '0H458000000XZU3CAO',
		literal_friendly_name: 'HubSpot',
		created_at: '2018-08-14T08:47:52Z',
		updated_at: null,
		last_activity: null,
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Machine,
		source: IdentitySource.SALESFORCE_CONNECTED_APPLICATION,
		issues: [],
		tags: [],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: 'Synergy Salesforce',
		affected_environments: null,
		salesforce_user: null,
		salesforce_connected_application: {
			name: 'HubSpot',
			connected_application_id: '0H458000000XZU3CAO',
			created_by_id: '005580000035i7tAAA',
			last_modified_by_id: '00558000000qeXNAAY',
			options_allow_admin_approved_users_only: false,
			options_refresh_token_validity_metric: false,
			options_has_session_level_policy: false,
			options_is_internal: false,
			options_full_content_push_notifications: true,
			options_code_credential_guest_enabled: false,
			options_app_issue_jwt_token_enabled: false,
			options_token_exchange_manage_bit_enabled: false,
			start_url: null,
			refresh_token_validity_period: null,
			bound_salesforce_user: null,
			app_metadata: null,
			ownership_records: [
				{
					owner: {
						id: '005580000035i7tAAA',
						username: 'adam.raven@synergy.com',
						email: 'adam.raven@synergy.com',
						first_name: 'Adam',
						last_name: 'Raven',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e5800000156t0AAA',
						created_by_id: '00558000000LZWjAAO',
						last_modified_by_id: '0054I000005rOxvQAE',
						created_at: '2018-02-25T12:03:21Z',
						last_modified_date: '2024-11-09T05:00:12Z',
						last_login_date: '2024-11-09T12:34:16Z',
					},
					action_type: 'Create',
					last_modified_date: '2018-08-14T08:47:52Z',
				},
				{
					owner: {
						id: '00558000000qeXNAAY',
						username: 'autoproc@synergy',
						email: 'rsandoval@synergy',
						first_name: 'Automated',
						last_name: 'Process',
						is_active: true,
						user_type: 'AutomatedProcess',
						profile_id: '00e58000000pyUiAAI',
						created_by_id: '00558000000qeXOAAY',
						last_modified_by_id: '00558000000qeXOAAY',
						created_at: '2016-03-10T10:53:40Z',
						last_modified_date: '2016-03-10T10:53:40Z',
						last_login_date: null,
					},
					action_type: 'Update',
					last_modified_date: '2020-07-17T23:37:57Z',
				},
			],
			api_total_usage_logs: [],
		},
		demo_usage_logs: {
			salesforce_connected_app_api_total_usage_logs:
				salesforceApiUsageLogs[salesforceUserUuids['SALESFORCE_USER_ID_10']],
			salesforce_connected_app_api_total_usage_logs_aggregated:
				salesforceApiUsageLogsAggregated[salesforceUserUuids['SALESFORCE_USER_ID_10']],
		},
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_11']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_11'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: 'nathen.rod@synergy.com',
		literal_friendly_name: 'nathen.rod@synergy.com',
		created_at: '2022-10-31T14:54:01Z',
		updated_at: '2023-09-11T12:07:48Z',
		last_activity: '2024-10-30T15:58:23Z',
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Hybrid,
		source: IdentitySource.SALESFORCE_USER,
		issues: [
			{
				priority: 2,
				id: '1eff0f57-5472-4ace-bdb2-53bac5d0dba3',
				created_at: '2024-10-15T05:54:45.134699Z',
				last_seen: '2024-11-10T05:52:33.739863Z',
				status: IssueStatus.OPEN,
				issue_name: IssueName.UnFederatedIdentity,
				issue_source: ResourceType.SALESFORCE,
				description:
					"\nA non-federated Salesforce user (**nathen.rod@synergy.com**) detected in the Salesforce instance, which\nrelies on traditional username and password authentication, diverging from Salesforce's security best practices.\nUsing users that are not federated with an Identity Provider (IdP) increases the risk of credential compromise and\ncomplicates access management.\nIt is recommended to implement Single Sign-On (SSO) with a federated IdP for enhanced security and streamlined user management.\n",
				ignore_reason: null,
			},
		],
		tags: [
			{
				id: '30684cbd-a844-4f12-8921-a50088210920',
				created_at: '2024-09-26T05:59:12.831587Z',
				updated_at: '2024-11-10T05:52:31.399085Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'RESOURCE_EXPOSURE',
				impact: 2,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-10T05:52:31.315216Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
			{
				id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
				created_at: '2024-09-26T05:59:12.856159Z',
				updated_at: '2024-11-10T05:52:31.425763Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
			{
				id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
				created_at: '2024-09-26T05:59:12.799448Z',
				updated_at: '2024-11-10T05:52:31.365273Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'https://synergy.my.salesforce.com',
		account_literal_friendly_name: 'Synergy Salesforce',
		affected_environments: null,
		salesforce_user: {
			user_id: '0058e000000d74IAAQ',
			username: 'nathen.rod@synergy.com',
			email: 'nathen.rod@synergy.com',
			first_name: 'Nathen',
			last_name: 'Rod',
			is_active: true,
			user_type: 'Standard',
			last_login_date: '2024-10-30T15:58:23Z',
			created_by_id: '0058e000001AFKGAA4',
			profile: {
				id: '00e8e0000015tHxAAI',
				name: 'CSM',
				description: null,
				user_type: 'Standard',
				permissions: {
					PermissionsEditTask: true,
					PermissionsEditEvent: true,
					PermissionsEmailMass: true,
					PermissionsViewRoles: true,
					PermissionsApiEnabled: true,
					PermissionsEditTopics: true,
					PermissionsRunReports: true,
					PermissionsUseWebLink: true,
					PermissionsEmailSingle: true,
					PermissionsAssignTopics: true,
					PermissionsConvertLeads: true,
					PermissionsCreateTopics: true,
					PermissionsExportReport: true,
					PermissionsViewAllUsers: true,
					PermissionsViewHelpLink: true,
					PermissionsListEmailSend: true,
					PermissionsContentHubUser: true,
					PermissionsMassInlineEdit: true,
					PermissionsChatterFileLink: true,
					PermissionsFlowUFLRequired: true,
					PermissionsActivitiesAccess: true,
					PermissionsApexRestServices: true,
					PermissionsChatterOwnGroups: true,
					PermissionsEditMyDashboards: true,
					PermissionsEditHtmlTemplates: true,
					PermissionsOverrideForecasts: true,
					PermissionsChatterEditOwnPost: true,
					PermissionsChatterInternalUser: true,
					PermissionsEmailAdministration: true,
					PermissionsEnableNotifications: true,
					PermissionsViewPublicDashboards: true,
					PermissionsCreateCustomizeFilters: true,
					PermissionsCreateCustomizeReports: true,
					PermissionsCreateDashboardFolders: true,
					PermissionsDistributeFromPersWksp: true,
					PermissionsShareFilesWithNetworks: true,
					PermissionsTransactionalEmailSend: true,
					PermissionsCreateReportInLightning: true,
					PermissionsEmailTemplateManagement: true,
					PermissionsLightningExperienceUser: true,
					PermissionsEditOppLineItemUnitPrice: true,
					PermissionsPreventClassicExperience: true,
					PermissionsCreateCustomizeDashboards: true,
					PermissionsSelectFilesFromSalesforce: true,
					PermissionsShowCompanyNameAsUserBadge: true,
					PermissionsSubscribeToLightningReports: true,
					PermissionsLightningConsoleAllowedForUser: true,
				},
				created_date: '2022-12-25T08:57:57Z',
				last_modified_date: '2024-11-06T11:55:37Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '0058e000001AFKGAA4',
				last_modified_by_id: '0058e000001AFKGAA4',
				tags: [
					{
						id: '30684cbd-a844-4f12-8921-a50088210920',
						created_at: '2024-09-26T05:59:12.831587Z',
						updated_at: '2024-11-10T05:52:31.399085Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'RESOURCE_EXPOSURE',
						impact: 2,
						probability: null,
					},
					{
						id: '7f590ffe-9b8f-4427-84bf-c06601230908',
						created_at: '2024-09-26T05:59:12.769217Z',
						updated_at: '2024-11-10T05:52:31.315216Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'DATA_ACCESS',
						impact: 3,
						probability: null,
					},
					{
						id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
						created_at: '2024-09-26T05:59:12.799448Z',
						updated_at: '2024-11-10T05:52:31.365273Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'CREDENTIAL_EXPOSURE',
						impact: 3,
						probability: null,
					},
					{
						id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
						created_at: '2024-09-26T05:59:12.856159Z',
						updated_at: '2024-11-10T05:52:31.425763Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'POSSIBLE_PRIVILEGE_ESCALATION',
						impact: 3,
						probability: null,
					},
				],
			},
			permission_sets: [
				{
					id: '0PS8e000000k9x5GAA',
					name: 'Enforce_SSO',
					label: 'Enforce SSO',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: '10058000000ZIk5AAG',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsIsSsoEnabled: true,
					},
					created_date: '2022-01-19T12:17:54Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0054I000006RTR0QAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000k9x5GAA',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PS8e000000k9zBGAQ',
					name: 'Flow_User',
					label: 'Flow User',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: '10058000000ZIk5AAG',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsRunFlow: true,
					},
					created_date: '2022-03-10T18:09:12Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0054I000006RTR0QAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000k9zBGAQ',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-10T05:52:31.425763Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
				{
					id: '0PS4I000000SYeGWAW',
					name: 'SalesAIForEveryoneStandardPermSet',
					label: 'Sales Cloud Einstein For Everyone',
					description: 'All Sales Cloud users can access Sales Cloud Einstein features',
					is_owned_by_profile: false,
					is_custom: false,
					type: 'Standard',
					license_id: '10058000000ZIk5AAG',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: 'force',
					has_activation_required: false,
					permissions: {
						PermissionsOpportunityScoreUser: true,
					},
					created_date: '2016-03-10T10:53:40Z',
					last_modified_date: '2024-11-10T01:25:24Z',
					system_modstamp: '2024-11-10T01:25:24Z',
					created_by_id: '00558000000qeXOAAY',
					last_modified_by_id: '00558000000qeXOAAY',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS4I000000SYeGWAW',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PS8e000000oOWpGAM',
					name: 'X00e8e0000015tHxAAI',
					label: '00e8e0000015tHxAAI',
					description: null,
					is_owned_by_profile: true,
					is_custom: true,
					type: 'Profile',
					license_id: '10058000000ZIk5AAG',
					profile_id: '00e8e0000015tHxAAI',
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsEditTask: true,
						PermissionsEditEvent: true,
						PermissionsEmailMass: true,
						PermissionsViewRoles: true,
						PermissionsApiEnabled: true,
						PermissionsEditTopics: true,
						PermissionsRunReports: true,
						PermissionsUseWebLink: true,
						PermissionsEmailSingle: true,
						PermissionsAssignTopics: true,
						PermissionsConvertLeads: true,
						PermissionsCreateTopics: true,
						PermissionsExportReport: true,
						PermissionsViewAllUsers: true,
						PermissionsViewHelpLink: true,
						PermissionsListEmailSend: true,
						PermissionsContentHubUser: true,
						PermissionsMassInlineEdit: true,
						PermissionsChatterFileLink: true,
						PermissionsFlowUFLRequired: true,
						PermissionsActivitiesAccess: true,
						PermissionsApexRestServices: true,
						PermissionsChatterOwnGroups: true,
						PermissionsEditMyDashboards: true,
						PermissionsEditHtmlTemplates: true,
						PermissionsOverrideForecasts: true,
						PermissionsChatterEditOwnPost: true,
						PermissionsChatterInternalUser: true,
						PermissionsEmailAdministration: true,
						PermissionsEnableNotifications: true,
						PermissionsViewPublicDashboards: true,
						PermissionsCreateCustomizeFilters: true,
						PermissionsCreateCustomizeReports: true,
						PermissionsCreateDashboardFolders: true,
						PermissionsDistributeFromPersWksp: true,
						PermissionsShareFilesWithNetworks: true,
						PermissionsTransactionalEmailSend: true,
						PermissionsCreateReportInLightning: true,
						PermissionsEmailTemplateManagement: true,
						PermissionsLightningExperienceUser: true,
						PermissionsEditOppLineItemUnitPrice: true,
						PermissionsPreventClassicExperience: true,
						PermissionsCreateCustomizeDashboards: true,
						PermissionsSelectFilesFromSalesforce: true,
						PermissionsShowCompanyNameAsUserBadge: true,
						PermissionsSubscribeToLightningReports: true,
						PermissionsLightningConsoleAllowedForUser: true,
					},
					created_date: '2022-12-25T08:57:57Z',
					last_modified_date: '2024-11-06T11:55:37Z',
					system_modstamp: '2024-11-06T11:55:37Z',
					created_by_id: '0058e000001AFKGAA4',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000oOWpGAM',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '30684cbd-a844-4f12-8921-a50088210920',
							created_at: '2024-09-26T05:59:12.831587Z',
							updated_at: '2024-11-10T05:52:31.399085Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'RESOURCE_EXPOSURE',
							impact: 2,
							probability: null,
						},
						{
							id: '7f590ffe-9b8f-4427-84bf-c06601230908',
							created_at: '2024-09-26T05:59:12.769217Z',
							updated_at: '2024-11-10T05:52:31.315216Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'DATA_ACCESS',
							impact: 3,
							probability: null,
						},
						{
							id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
							created_at: '2024-09-26T05:59:12.799448Z',
							updated_at: '2024-11-10T05:52:31.365273Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'CREDENTIAL_EXPOSURE',
							impact: 3,
							probability: null,
						},
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-10T05:52:31.425763Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
			],
			ownership_records: [
				{
					owner: {
						id: '0058e000001AFKGAA4',
						username: 'ran.lev@synergy.com',
						email: 'ran.lev@synergy.com',
						first_name: 'Ran',
						last_name: 'Lev',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '0054I000005rd2uQAA',
						last_modified_by_id: '0058e000001AFKGAA4',
						created_at: '2022-07-03T08:23:30Z',
						last_modified_date: '2024-10-30T08:13:48Z',
						last_login_date: '2024-11-10T05:09:06Z',
					},
					action_type: 'Create',
					last_modified_date: '2022-10-31T14:54:01Z',
				},
				{
					owner: {
						id: '0058e000001AFKGAA4',
						username: 'ran.lev@synergy.com',
						email: 'ran.lev@synergy.com',
						first_name: 'Ran',
						last_name: 'Lev',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '0054I000005rd2uQAA',
						last_modified_by_id: '0058e000001AFKGAA4',
						created_at: '2022-07-03T08:23:30Z',
						last_modified_date: '2024-10-30T08:13:48Z',
						last_login_date: '2024-11-10T05:09:06Z',
					},
					action_type: 'Update',
					last_modified_date: '2023-09-11T12:07:48Z',
				},
			],
			okta_user_xc: null,
			entra_id_user_xc: null,
			setup_audit_trail_logs: [],
		},
		salesforce_connected_application: null,
	},
	[salesforceUserUuids['SALESFORCE_USER_ID_12']]: {
		id: salesforceUserUuids['SALESFORCE_USER_ID_12'],
		environment_id: '4b79357a-3ccc-4647-a613-7791809bcfc5',
		literal: 'benn.der@synergy.com',
		literal_friendly_name: 'benn.der@synergy.com',
		created_at: '2022-07-27T12:19:20Z',
		updated_at: '2023-09-11T12:07:49Z',
		last_activity: '2024-11-05T16:14:06Z',
		activity_status: IdentityActivityStatus.ACTIVE,
		env_type: EnvironmentType.SALESFORCE,
		type: IdentityType.Hybrid,
		source: IdentitySource.SALESFORCE_USER,
		issues: [
			{
				priority: 2,
				id: 'c8cf0657-312e-40f5-ab12-885a83cea153',
				created_at: '2024-10-15T05:54:45.145933Z',
				last_seen: '2024-11-10T05:52:33.739863Z',
				status: IssueStatus.OPEN,
				issue_name: IssueName.UnFederatedIdentity,
				issue_source: ResourceType.SALESFORCE,
				description:
					"\nA non-federated Salesforce user (**benn.der@synergy.com**) detected in the Salesforce instance, which\nrelies on traditional username and password authentication, diverging from Salesforce's security best practices.\nUsing users that are not federated with an Identity Provider (IdP) increases the risk of credential compromise and\ncomplicates access management.\nIt is recommended to implement Single Sign-On (SSO) with a federated IdP for enhanced security and streamlined user management.\n",
				ignore_reason: null,
			},
		],
		tags: [
			{
				id: '30684cbd-a844-4f12-8921-a50088210920',
				created_at: '2024-09-26T05:59:12.831587Z',
				updated_at: '2024-11-10T05:52:31.399085Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'RESOURCE_EXPOSURE',
				impact: 2,
				probability: null,
			},
			{
				id: '7f590ffe-9b8f-4427-84bf-c06601230908',
				created_at: '2024-09-26T05:59:12.769217Z',
				updated_at: '2024-11-10T05:52:31.315216Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'DATA_ACCESS',
				impact: 3,
				probability: null,
			},
			{
				id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
				created_at: '2024-09-26T05:59:12.856159Z',
				updated_at: '2024-11-10T05:52:31.425763Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				probability: null,
			},
			{
				id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
				created_at: '2024-09-26T05:59:12.799448Z',
				updated_at: '2024-11-10T05:52:31.365273Z',
				source: 'TOKEN',
				related_resource_type: ResourceType.SALESFORCE,
				name: 'CREDENTIAL_EXPOSURE',
				impact: 3,
				probability: null,
			},
		],
		account_literal: 'https://aquasec.my.salesforce.com',
		account_literal_friendly_name: 'Aqua Salesforce',
		affected_environments: null,
		salesforce_user: {
			user_id: '0058e000001AHHJAA4',
			username: 'benn.der@synergy.com',
			email: 'benn.der@synergy.com',
			first_name: 'Talib',
			last_name: 'Ali',
			is_active: true,
			user_type: 'Standard',
			last_login_date: '2024-11-05T16:14:06Z',
			created_by_id: '0054I000005rcdQQAQ',
			profile: {
				id: '00e58000000MK8zAAG',
				name: 'Customer Solutions Engineer',
				description: null,
				user_type: 'Standard',
				permissions: {
					PermissionsEditTask: true,
					PermissionsEditEvent: true,
					PermissionsEmailMass: true,
					PermissionsViewRoles: true,
					PermissionsApiEnabled: true,
					PermissionsEditTopics: true,
					PermissionsRunReports: true,
					PermissionsUseWebLink: true,
					PermissionsEmailSingle: true,
					PermissionsAssignTopics: true,
					PermissionsConvertLeads: true,
					PermissionsCreateTopics: true,
					PermissionsExportReport: true,
					PermissionsViewAllUsers: true,
					PermissionsViewHelpLink: true,
					PermissionsListEmailSend: true,
					PermissionsContentHubUser: true,
					PermissionsMassInlineEdit: true,
					PermissionsChatterFileLink: true,
					PermissionsFlowUFLRequired: true,
					PermissionsActivitiesAccess: true,
					PermissionsApexRestServices: true,
					PermissionsChatterOwnGroups: true,
					PermissionsEditMyDashboards: true,
					PermissionsOverrideForecasts: true,
					PermissionsChatterEditOwnPost: true,
					PermissionsChatterInternalUser: true,
					PermissionsEmailAdministration: true,
					PermissionsEnableNotifications: true,
					PermissionsSubmitMacrosAllowed: true,
					PermissionsViewPublicDashboards: true,
					PermissionsCreateCustomizeFilters: true,
					PermissionsCreateCustomizeReports: true,
					PermissionsDistributeFromPersWksp: true,
					PermissionsShareFilesWithNetworks: true,
					PermissionsTransactionalEmailSend: true,
					PermissionsCreateReportInLightning: true,
					PermissionsEmailTemplateManagement: true,
					PermissionsLightningExperienceUser: true,
					PermissionsEditOppLineItemUnitPrice: true,
					PermissionsPreventClassicExperience: true,
					PermissionsCreateCustomizeDashboards: true,
					PermissionsSelectFilesFromSalesforce: true,
					PermissionsShowCompanyNameAsUserBadge: true,
					PermissionsSubscribeToLightningReports: true,
					PermissionsLightningConsoleAllowedForUser: true,
				},
				created_date: '2016-06-09T05:55:07Z',
				last_modified_date: '2024-11-06T11:55:37Z',
				last_viewed_date: null,
				last_referenced_date: null,
				created_by_id: '00558000000LZWjAAO',
				last_modified_by_id: '0058e000001AFKGAA4',
				tags: [
					{
						id: '30684cbd-a844-4f12-8921-a50088210920',
						created_at: '2024-09-26T05:59:12.831587Z',
						updated_at: '2024-11-10T05:52:31.399085Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'RESOURCE_EXPOSURE',
						impact: 2,
						probability: null,
					},
					{
						id: '7f590ffe-9b8f-4427-84bf-c06601230908',
						created_at: '2024-09-26T05:59:12.769217Z',
						updated_at: '2024-11-10T05:52:31.315216Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'DATA_ACCESS',
						impact: 3,
						probability: null,
					},
					{
						id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
						created_at: '2024-09-26T05:59:12.799448Z',
						updated_at: '2024-11-10T05:52:31.365273Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'CREDENTIAL_EXPOSURE',
						impact: 3,
						probability: null,
					},
					{
						id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
						created_at: '2024-09-26T05:59:12.856159Z',
						updated_at: '2024-11-10T05:52:31.425763Z',
						source: 'TOKEN',
						related_resource_type: ResourceType.SALESFORCE,
						name: 'POSSIBLE_PRIVILEGE_ESCALATION',
						impact: 3,
						probability: null,
					},
				],
			},
			permission_sets: [
				{
					id: '0PS580000001WFJGA2',
					name: 'X00e58000000MK8zAAG',
					label: '00e58000000MK8zAAG',
					description: null,
					is_owned_by_profile: true,
					is_custom: true,
					type: 'Profile',
					license_id: '10058000000ZIk5AAG',
					profile_id: '00e58000000MK8zAAG',
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsEditTask: true,
						PermissionsEditEvent: true,
						PermissionsEmailMass: true,
						PermissionsViewRoles: true,
						PermissionsApiEnabled: true,
						PermissionsEditTopics: true,
						PermissionsRunReports: true,
						PermissionsUseWebLink: true,
						PermissionsEmailSingle: true,
						PermissionsAssignTopics: true,
						PermissionsConvertLeads: true,
						PermissionsCreateTopics: true,
						PermissionsExportReport: true,
						PermissionsViewAllUsers: true,
						PermissionsViewHelpLink: true,
						PermissionsListEmailSend: true,
						PermissionsContentHubUser: true,
						PermissionsMassInlineEdit: true,
						PermissionsChatterFileLink: true,
						PermissionsFlowUFLRequired: true,
						PermissionsActivitiesAccess: true,
						PermissionsApexRestServices: true,
						PermissionsChatterOwnGroups: true,
						PermissionsEditMyDashboards: true,
						PermissionsOverrideForecasts: true,
						PermissionsChatterEditOwnPost: true,
						PermissionsChatterInternalUser: true,
						PermissionsEmailAdministration: true,
						PermissionsEnableNotifications: true,
						PermissionsSubmitMacrosAllowed: true,
						PermissionsViewPublicDashboards: true,
						PermissionsCreateCustomizeFilters: true,
						PermissionsCreateCustomizeReports: true,
						PermissionsDistributeFromPersWksp: true,
						PermissionsShareFilesWithNetworks: true,
						PermissionsTransactionalEmailSend: true,
						PermissionsCreateReportInLightning: true,
						PermissionsEmailTemplateManagement: true,
						PermissionsLightningExperienceUser: true,
						PermissionsEditOppLineItemUnitPrice: true,
						PermissionsPreventClassicExperience: true,
						PermissionsCreateCustomizeDashboards: true,
						PermissionsSelectFilesFromSalesforce: true,
						PermissionsShowCompanyNameAsUserBadge: true,
						PermissionsSubscribeToLightningReports: true,
						PermissionsLightningConsoleAllowedForUser: true,
					},
					created_date: '2016-06-09T05:55:07Z',
					last_modified_date: '2024-11-06T11:55:37Z',
					system_modstamp: '2024-11-06T11:55:37Z',
					created_by_id: '00558000000LZWjAAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS580000001WFJGA2',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '30684cbd-a844-4f12-8921-a50088210920',
							created_at: '2024-09-26T05:59:12.831587Z',
							updated_at: '2024-11-10T05:52:31.399085Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'RESOURCE_EXPOSURE',
							impact: 2,
							probability: null,
						},
						{
							id: '7f590ffe-9b8f-4427-84bf-c06601230908',
							created_at: '2024-09-26T05:59:12.769217Z',
							updated_at: '2024-11-10T05:52:31.315216Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'DATA_ACCESS',
							impact: 3,
							probability: null,
						},
						{
							id: '4df9c630-5edd-4c97-929c-b7b30d6adc46',
							created_at: '2024-09-26T05:59:12.799448Z',
							updated_at: '2024-11-10T05:52:31.365273Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'CREDENTIAL_EXPOSURE',
							impact: 3,
							probability: null,
						},
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-10T05:52:31.425763Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
				{
					id: '0PS8e000000k9x5GAA',
					name: 'Enforce_SSO',
					label: 'Enforce SSO',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: '10058000000ZIk5AAG',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsIsSsoEnabled: true,
					},
					created_date: '2022-01-19T12:17:54Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0054I000006RTR0QAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000k9x5GAA',
						type: 'PermissionSet',
					},
					tags: null,
				},
				{
					id: '0PS8e000000k9zBGAQ',
					name: 'Flow_User',
					label: 'Flow User',
					description: null,
					is_owned_by_profile: false,
					is_custom: true,
					type: 'Regular',
					license_id: '10058000000ZIk5AAG',
					profile_id: null,
					permission_set_group_id: null,
					namespace_prefix: null,
					has_activation_required: false,
					permissions: {
						PermissionsRunFlow: true,
					},
					created_date: '2022-03-10T18:09:12Z',
					last_modified_date: '2024-05-30T09:23:15Z',
					system_modstamp: '2024-05-30T09:23:15Z',
					created_by_id: '0054I000006RTR0QAO',
					last_modified_by_id: '0058e000001AFKGAA4',
					attributes: {
						url: '/services/data/v61.0/sobjects/PermissionSet/0PS8e000000k9zBGAQ',
						type: 'PermissionSet',
					},
					tags: [
						{
							id: '791e72f3-35f7-4a0e-a544-bf1dc97c1f5c',
							created_at: '2024-09-26T05:59:12.856159Z',
							updated_at: '2024-11-10T05:52:31.425763Z',
							source: 'TOKEN',
							related_resource_type: ResourceType.SALESFORCE,
							name: 'POSSIBLE_PRIVILEGE_ESCALATION',
							impact: 3,
							probability: null,
						},
					],
				},
			],
			ownership_records: [
				{
					owner: {
						id: '0054I000005rcdQQAQ',
						username: 'rachel.rose@synergy.com',
						email: 'rachel.rose@synergy.com',
						first_name: 'Rachel',
						last_name: 'Rose',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e8e0000015tJyAAI',
						created_by_id: '00558000000LZWjAAO',
						last_modified_by_id: '0054I000005rcdQQAQ',
						created_at: '2021-06-10T16:00:13Z',
						last_modified_date: '2024-09-23T11:41:56Z',
						last_login_date: '2024-11-10T03:38:17Z',
					},
					action_type: 'Create',
					last_modified_date: '2022-07-27T12:19:20Z',
				},
				{
					owner: {
						id: '0058e000001AFKGAA4',
						username: 'kelly.aviv@synergy.com',
						email: 'kelly.aviv@aquasec.com',
						first_name: 'Kelly',
						last_name: 'Aviv',
						is_active: true,
						user_type: 'Standard',
						profile_id: '00e58000000pyUgAAI',
						created_by_id: '0054I000005rd2uQAA',
						last_modified_by_id: '0058e000001AFKGAA4',
						created_at: '2022-07-03T08:23:30Z',
						last_modified_date: '2024-10-30T08:13:48Z',
						last_login_date: '2024-11-10T05:09:06Z',
					},
					action_type: 'Update',
					last_modified_date: '2023-09-11T12:07:49Z',
				},
			],
			okta_user_xc: null,
			entra_id_user_xc: null,
			setup_audit_trail_logs: [],
		},
		salesforce_connected_application: null,
	},
} as const satisfies Record<string, DemoServerIdentityInput>
