import GithubIcon from '../../../../assets/github_icon_20.svg?react'
import { ServerEnvironments } from '../../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { EnvironmentConfig, EnvironmentIntegrationCategory } from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const githubConfig: EnvironmentConfig = {
	key: EnvironmentType.GITHUB,
	name: 'GitHub',
	description:
		'Link Token Security to your GitHub repositories to map cloud identities and enhance security across your development workflows.',
	logo: <IntegrationIcon leftIcon={GithubIcon} />,
	category: EnvironmentIntegrationCategory.DEVELOPER_TOOLS,
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.GITHUB].filter(
			(env) =>
				env.name.toLowerCase().includes(searchText.toLowerCase()) ||
				env.org_id.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.GITHUB].map((env) => env.name),
}
