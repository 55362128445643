import { PriorityTypeEnum, PriorityTypeNameToNumber } from '../../../schemas/issue.ts'
import React from 'react'
import { ServerMetricsTopBar } from '../../../schemas/metrics/metricsTopBar.ts'
import TrendUpIcon from '../../../assets/trend_up_icon.svg?react'
import { PriorityBar } from '../../../components/common/PriorityBar.tsx'
import { Link, LinkProps, useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { themeColors } from '../../../utils/colorUtils.ts'

const getIdentitiesLinkProps = (priorities: string[]): LinkProps => {
	const convertedPriorities = priorities.map((priority) => {
		return `${PriorityTypeNameToNumber[priority as PriorityTypeEnum]}`
	})

	return {
		from: RoutePaths.Metrics,
		to: RoutePaths.Identities,
		search: { identityFilter: { max_priority: { filterType: 'set', values: convertedPriorities } } },
	} satisfies LinkProps
}

export const TotalIdentitiesAtRisk: React.FunctionComponent<{
	metricsTopBar: ServerMetricsTopBar
}> = ({ metricsTopBar }) => {
	const navigate = useNavigate({ from: RoutePaths.Metrics })
	const difference =
		metricsTopBar.high_count_latest +
		metricsTopBar.critical_count_latest -
		metricsTopBar.high_count_x_days_ago -
		metricsTopBar.critical_count_x_days_ago

	const iconStyle = {
		transform: difference < 0 ? 'rotate(90deg)' : 'none',
	}

	const diffStyle = {
		backgroundColor: difference < 0 ? themeColors.status.activeLowContrast : themeColors.status.highLowContrast,
		color: difference < 0 ? themeColors.status.active : themeColors.status.high,
		padding: '2px 6px',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		gap: '2px',
		height: 'fit-content',
	}

	return (
		<div className="h-full flex gap-4">
			<div className="flex gap-2 h-min">
				<Link {...getIdentitiesLinkProps([PriorityTypeEnum.HIGH, PriorityTypeEnum.CRITICAL])}>
					{formatNumber(metricsTopBar.high_count_latest + metricsTopBar.critical_count_latest)}{' '}
				</Link>
				<div className="flex self-end">
					<div style={diffStyle}>
						<TrendUpIcon style={iconStyle} />
						<span className="text-xs">
							{difference > 0 ? '+' : ''}
							{formatNumber(difference)}
						</span>
					</div>
				</div>
			</div>
			<PriorityBar
				findingsByPriority={[
					{ priority: PriorityTypeEnum.CRITICAL, count: metricsTopBar.critical_count_latest },
					{ priority: PriorityTypeEnum.HIGH, count: metricsTopBar.high_count_latest },
				]}
				onPriorityNameTagClick={(priority) => {
					void navigate(getIdentitiesLinkProps([priority]))
				}}
			/>
		</div>
	)
}
