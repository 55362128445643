import { useEntitlements } from '@frontegg/react'

enum FeatureFlags {
	SHOW_JIRA_BUTTON = 'show-jira-button',
	SHOW_ONBOARDING_STATE = 'show-onboarding-state',
	SHOW_GCP_PERMISSION_ANALYSIS = 'show-gcp-permission-analysis',
	AI_REMEDIATION = 'ai-remediation',
}

export const useEntitlementToShowJiraButton = () => {
	return useEntitlements({ featureKey: FeatureFlags.SHOW_JIRA_BUTTON })
}

export const useEntitlementToShowOnboardingState = () => {
	return useEntitlements({ featureKey: FeatureFlags.SHOW_ONBOARDING_STATE })
}

export const useEntitlementShowGcpPermissionAnalysis = () => {
	return useEntitlements({ featureKey: FeatureFlags.SHOW_GCP_PERMISSION_ANALYSIS })
}

export const useEntitlementToAIRemediation = () => {
	return useEntitlements({ featureKey: FeatureFlags.AI_REMEDIATION })
}
