import { type ThemeConfig } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'

export const antdTheme = {
	token: {
		colorLinkHover: themeColors.textIcon.linkHover,
		colorLink: themeColors.textIcon.link,
		colorPrimary: themeColors.textIcon.primary,
		colorInfo: themeColors.textIcon.primary,
		colorSuccess: themeColors.status.active,
		colorWarning: themeColors.status.medium,
		colorError: themeColors.status.critical,
		colorTextBase: themeColors.textIcon.primary,
		colorPrimaryBg: themeColors.surface.secondary,
		colorPrimaryBgHover: themeColors.surface.primary,
		colorPrimaryBorderHover: themeColors.textIcon.brand,
		colorPrimaryBorder: themeColors.textIcon.brand,
		colorPrimaryHover: themeColors.textIcon.brand,
		colorSuccessBg: themeColors.status.activeLowContrast,
		colorSuccessBorder: themeColors.dsGreen[200],
		colorWarningBg: themeColors.status.mediumLowContrast,
		colorErrorBg: themeColors.status.criticalLowContrast,
		colorText: themeColors.textIcon.primary,
		colorTextSecondary: themeColors.textIcon.secondary,
		colorTextTertiary: themeColors.textIcon.tertiary,
		colorTextQuaternary: themeColors.textIcon.dark,
		colorBorder: themeColors.border.primary,
		colorBorderSecondary: themeColors.border.secondary,
		colorBgElevated: themeColors.surface.secondary,
		colorBgLayout: themeColors.surface.secondary,
		colorBgMask: themeColors.surface.tertiary,
		colorBgSpotlight: themeColors.surface.tertiary,
		colorFill: themeColors.surface.primary,
		colorFillSecondary: themeColors.surface.secondary,
		colorFillTertiary: themeColors.surface.tertiary,
		colorFillQuaternary: themeColors.surface.tertiary,
		borderRadius: 8,
		borderRadiusLG: 12,
		borderRadiusSM: 4,
		wireframe: false,
	},
	components: {
		Button: {
			defaultActiveColor: themeColors.textIcon.brand,
			colorPrimaryActive: themeColors.textIcon.brand,
			defaultHoverBorderColor: themeColors.textIcon.brand,
			defaultHoverColor: themeColors.textIcon.brand,
			groupBorderColor: themeColors.textIcon.brand,
			colorPrimaryHover: themeColors.textIcon.brand,
			linkHoverBg: 'inherit',
			defaultColor: themeColors.textIcon.primary,
			colorBgContainer: themeColors.surface.primary,
			colorBgContainerDisabled: themeColors.surface.secondary,
			colorBgTextActive: themeColors.textIcon.secondaryDark,
			colorTextDisabled: themeColors.textIcon.disable,
			defaultBorderColor: themeColors.border.secondary,
			defaultBg: themeColors.surface.tertiary,
			defaultHoverBg: themeColors.border.secondary,
			defaultActiveBorderColor: themeColors.textIcon.brand,
			textTextHoverColor: themeColors.textIcon.brand,
			colorBgSolid: themeColors.textIcon.brand,
			colorBgSolidHover: themeColors.textIcon.brand,
			textTextActiveColor: themeColors.textIcon.brand,
			defaultActiveBg: themeColors.textIcon.secondaryDark,
			colorBgSolidActive: themeColors.textIcon.brand,
			defaultShadow: 'none',
			primaryShadow: 'none',
		},
		Divider: {
			colorSplit: themeColors.border.primary,
			fontSize: 14,
		},
		Layout: {
			bodyBg: themeColors.surface.secondary,
			headerBg: themeColors.surface.tertiary,
			algorithm: true,
			colorText: themeColors.textIcon.primary,
			siderBg: themeColors.surface.primaryDark,
		},
		Space: {
			paddingXS: 4,
			padding: 8,
		},
		Dropdown: {
			colorTextDisabled: themeColors.textIcon.disable,
			colorSplit: themeColors.border.secondary,
			controlItemBgActiveHover: themeColors.surface.secondary,
			colorBgElevated: themeColors.surface.primary,
			boxShadowSecondary: 'none',
			borderRadiusLG: 4,
		},
		Menu: {
			colorPrimary: themeColors.textIcon.dark,
			itemColor: themeColors.textIcon.secondaryDark,
			colorText: themeColors.textIcon.dark,
			popupBg: themeColors.surface.primaryDark,
			itemBg: themeColors.surface.primaryDark,
			itemSelectedBg: themeColors.surface.secondaryDark,
			groupTitleColor: themeColors.textIcon.dark,
			colorSplit: themeColors.border.secondaryDark,
			algorithm: true,
			dangerItemSelectedBg: themeColors.surface.secondaryDark,
			dangerItemColor: themeColors.textIcon.dark,
			dangerItemHoverColor: themeColors.status.critical,
			dangerItemSelectedColor: themeColors.status.critical,
			darkDangerItemActiveBg: themeColors.status.critical,
			darkGroupTitleColor: themeColors.textIcon.dark,
			itemSelectedColor: themeColors.textIcon.dark,
			itemHoverColor: themeColors.textIcon.brand,
			itemHoverBg: themeColors.surface.secondaryDark,
			darkItemBg: themeColors.surface.primaryDark,
			darkPopupBg: themeColors.surface.primaryDark,
			darkItemHoverBg: themeColors.surface.secondaryDark,
			darkSubMenuItemBg: `${themeColors.surface.primary}09`, // 0.04 opacity
			darkItemSelectedBg: themeColors.surface.secondaryDark,
			darkItemColor: themeColors.dsNaturals[500],
			darkItemHoverColor: themeColors.dsNaturals[500],
		},
		Tooltip: {
			colorTextLightSolid: themeColors.textIcon.primary,
			colorBgSpotlight: themeColors.surface.primary,
		},
		Popover: {
			colorText: themeColors.textIcon.primary,
			colorBgElevated: themeColors.surface.primary,
		},
		Cascader: {
			colorHighlight: themeColors.textIcon.brand,
			colorTextDisabled: themeColors.textIcon.disable,
			colorSplit: themeColors.border.secondary,
			colorPrimary: themeColors.textIcon.brand,
			colorBgContainer: themeColors.surface.primary,
		},
		Switch: {
			colorPrimary: themeColors.dsGreen[400],
			colorTextQuaternary: themeColors.surface.fourthy,
			colorPrimaryHover: themeColors.dsGreen[300],
		},
		Select: {
			colorTextDisabled: themeColors.textIcon.disable,
			hoverBorderColor: themeColors.textIcon.brand,
			selectorBg: themeColors.surface.primary,
			algorithm: true,
			optionSelectedColor: themeColors.textIcon.primary,
			optionSelectedBg: themeColors.surface.fourthy,
			activeOutlineColor: `${themeColors.status.info}26`, // 0.15 opacity
			activeBorderColor: themeColors.textIcon.brand,
			multipleItemBg: themeColors.surface.tertiary,
			optionActiveBg: themeColors.surface.tertiary,
			colorTextDescription: themeColors.textIcon.primary,
			colorTextPlaceholder: themeColors.textIcon.tertiary,
			colorTextTertiary: themeColors.textIcon.tertiary,
			borderRadius: 4,
		},
		Steps: {
			colorSplit: themeColors.border.primary,
		},
		Form: {
			controlOutline: themeColors.border.secondary,
			colorBorder: themeColors.border.secondary,
			colorPrimary: themeColors.textIcon.brand,
			labelColor: themeColors.textIcon.primary,
			labelRequiredMarkColor: themeColors.textIcon.primary,
		},
		Input: {
			colorPrimaryActive: themeColors.textIcon.brand,
			colorSplit: themeColors.border.secondary,
			colorTextDisabled: themeColors.textIcon.disable,
			colorTextTertiary: themeColors.textIcon.tertiary,
			colorTextPlaceholder: themeColors.textIcon.tertiary,
			colorBorder: themeColors.border.secondary,
			borderRadius: 4,
			colorTextQuaternary: themeColors.dsNaturals[500],
		},
		InputNumber: {
			handleBorderColor: themeColors.border.secondary,
			colorBorder: themeColors.border.secondary,
			colorTextDisabled: themeColors.textIcon.disable,
		},
		Avatar: {
			colorTextLightSolid: themeColors.textIcon.brand,
			groupBorderColor: themeColors.textIcon.brand,
			colorTextPlaceholder: themeColors.status.infoLowContrast,
			colorText: themeColors.textIcon.brand,
		},
		Collapse: {
			headerBg: themeColors.surface.secondary,
			colorBorder: themeColors.border.secondary,
			colorTextDisabled: themeColors.textIcon.disable,
		},
		Descriptions: {
			colorSplit: themeColors.border.secondary,
			labelBg: themeColors.surface.secondary,
		},
		Segmented: {
			itemActiveBg: themeColors.surface.tertiary,
			itemSelectedColor: themeColors.textIcon.brand,
			itemSelectedBg: themeColors.surface.secondary,
			itemHoverColor: themeColors.textIcon.brand,
			itemHoverBg: themeColors.surface.tertiary,
			itemColor: themeColors.textIcon.primary,
			colorTextDisabled: themeColors.textIcon.disable,
			trackBg: themeColors.surface.tertiary,
		},
		Spin: {
			colorWhite: themeColors.textIcon.brand,
			colorBgMask: `${themeColors.surface.secondaryDark}80`, // 0.5 opacity
		},
		Table: {
			controlItemBgActive: themeColors.surface.tertiary,
			headerBg: themeColors.surface.tertiary,
			borderColor: themeColors.border.primary,
			colorPrimaryBorder: themeColors.border.primary,
			colorSplit: themeColors.border.primary,
			rowSelectedBg: themeColors.surface.tertiary,
			headerSortActiveBg: themeColors.surface.tertiary,
			algorithm: true,
		},
		Tabs: {
			itemActiveColor: themeColors.textIcon.brand,
			itemColor: themeColors.textIcon.primary,
			itemSelectedColor: themeColors.textIcon.brand,
			itemHoverColor: themeColors.textIcon.brand,
			inkBarColor: themeColors.textIcon.brand,
			horizontalItemPaddingLG: '12px',
		},
		Alert: {
			colorInfoBg: themeColors.status.infoLowContrast,
			colorInfoBorder: themeColors.dsBlue[300],
			colorInfo: themeColors.textIcon.brand,
			colorWarningBorder: themeColors.dsOrange[200],
		},
		Message: {
			colorInfo: themeColors.textIcon.brand,
		},
		Progress: {
			defaultColor: themeColors.textIcon.brand,
		},
		Pagination: {
			colorPrimaryHover: themeColors.textIcon.primary,
			itemActiveBg: themeColors.surface.secondary,
			itemActiveBgDisabled: themeColors.surface.secondary,
			colorBorder: themeColors.border.secondary,
			colorTextDisabled: themeColors.textIcon.secondaryDark,
			controlOutline: themeColors.border.secondary,
			colorPrimaryBorder: themeColors.border.secondary,
			colorBgTextHover: themeColors.textIcon.secondaryDark,
			colorBgTextActive: themeColors.surface.tertiary,
		},
		Checkbox: {
			colorPrimary: themeColors.textIcon.primary,
			colorPrimaryHover: themeColors.textIcon.secondaryDark,
			colorTextDisabled: themeColors.textIcon.disable,
			colorBorder: themeColors.border.tertiary,
		},
		Radio: {
			buttonSolidCheckedHoverBg: themeColors.textIcon.brand,
			buttonSolidCheckedActiveBg: themeColors.textIcon.brand,
			buttonSolidCheckedBg: themeColors.surface.secondaryDark,
			colorPrimaryBorder: themeColors.border.secondaryDark,
			colorPrimaryHover: themeColors.textIcon.dark,
			colorPrimaryActive: themeColors.textIcon.secondaryDark,
			colorBgContainer: themeColors.surface.tertiary,
			buttonColor: themeColors.textIcon.primary,
			colorText: themeColors.textIcon.primary,
			colorTextDisabled: themeColors.textIcon.secondaryDark,
			buttonSolidCheckedColor: themeColors.textIcon.dark,
			buttonCheckedBg: themeColors.surface.tertiary,
		},
		Transfer: {
			colorTextDisabled: themeColors.textIcon.disable,
		},
		Badge: {
			colorBorderBg: themeColors.border.white,
		},
		List: {
			colorSplit: themeColors.border.primary,
		},
		Tree: {
			colorTextDisabled: themeColors.textIcon.disable,
		},
		Drawer: {
			colorSplit: themeColors.border.secondary,
			colorBgMask: `${themeColors.surface.secondaryDark}1A`, // 0.1 opacity
		},
		Notification: {
			colorPrimaryBorder: themeColors.border.secondary,
		},
		Skeleton: {
			gradientFromColor: themeColors.dsNaturals[300],
			gradientToColor: themeColors.dsNaturals[400],
		},
		Tag: {
			colorBorder: themeColors.border.secondary,
		},
		Modal: {
			colorBgMask: `${themeColors.surface.secondaryDark}80`, // 0.5 opacity
			contentBg: themeColors.surface.primary,
			headerBg: themeColors.surface.primary,
		},
		Breadcrumb: {
			fontSize: 16,
		},
	},
} satisfies ThemeConfig
