import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message, Select, Tooltip, Upload, Tabs, Typography } from 'antd'
import { type UploadFile } from 'antd/es/upload/interface'
import { CopyOutlined, UploadOutlined } from '@ant-design/icons'
import { FormField, GeneratedFormField } from '../environmentIntegrationsTypes'
import { FormInstance, Rule } from 'antd/lib/form'
import { ServerEnvironments } from '../../../../schemas/environments/environments'
import cx from 'classnames'

const { Link } = Typography

interface GeneratedFieldProps {
	field: GeneratedFormField
	form: FormInstance
	existingId?: string
}

const GeneratedField: React.FC<GeneratedFieldProps> = ({ field, form, existingId }) => {
	const fieldValue = Form.useWatch<string>(field.name, form)
	const [isGenerating, setIsGenerating] = useState(false)

	const handleGenerate = async () => {
		setIsGenerating(true)
		try {
			const generatedValue = await Promise.resolve(field.generateAction(form))
			form.setFieldValue(field.name, generatedValue)
			void form.validateFields([field.name])
		} finally {
			setIsGenerating(false)
		}
	}

	const copyToClipboard = async (text: string) => {
		await navigator.clipboard.writeText(text)
		message.success('Copied to clipboard')
	}

	useEffect(() => {
		if (field.name === 'externalId' && existingId) {
			form.setFieldValue('externalId', existingId)
		}
	}, [existingId, field.name, form])

	return (
		<>
			<div className="mb-2">{field.label}</div>
			<div className="flex gap-2">
				<Tooltip
					title={
						field?.tooltip
							? field.tooltip
							: existingId
								? 'The same external ID should be used for all integrations with Token Security'
								: 'Click to create external ID for the Token Security integration with your environment'
					}
				>
					<Button onClick={() => void handleGenerate()} disabled={!!existingId || isGenerating}>
						{field?.icon}
						Generate
					</Button>
				</Tooltip>
				<Form.Item
					name={field.name}
					className={cx('grow', field?.inputClassName)}
					rules={[{ required: field.required, message: `${field.label} is required` }]}
				>
					<Input
						readOnly
						className="select-none cursor-default"
						suffix={
							field.copyable && !!fieldValue ? (
								<Button
									type="text"
									size="small"
									icon={<CopyOutlined />}
									onClick={() => {
										void copyToClipboard(fieldValue)
									}}
								/>
							) : (
								<span />
							)
						}
					/>
				</Form.Item>
			</div>
		</>
	)
}

interface FormFieldRendererProps {
	field: FormField
	form: FormInstance
	disabled?: boolean
	environments: ServerEnvironments
}

interface FileUploadFieldProps {
	field: Extract<FormField, { fieldType: 'fileUpload' }>
	form: FormInstance
	rules: Rule[]
}

type JsonContent = Record<string, unknown>

const readFileAsJson = async (file: File): Promise<JsonContent> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = (e) => {
			try {
				const content = e.target?.result as string
				const parsedJson = JSON.parse(content) as unknown

				// Validate that the parsed JSON is a single top-level object
				if (typeof parsedJson !== 'object' || parsedJson === null || Array.isArray(parsedJson)) {
					throw new Error('JSON must be a single top-level object')
				}

				resolve(parsedJson as JsonContent)
			} catch {
				reject(new Error('Failed to parse JSON file'))
			}
		}
		reader.onerror = () => reject(new Error('Failed to read file'))
		reader.readAsText(file)
	})
}

const FileUploadField: React.FC<FileUploadFieldProps> = ({ field, form, rules }) => {
	const [fileList, setFileList] = useState<UploadFile<File>[]>([])

	const handleBeforeUpload = async (file: File) => {
		if (file.type !== 'application/json') {
			message.error('You can only upload JSON files!')
			return Upload.LIST_IGNORE
		}

		try {
			const parsedJson = await readFileAsJson(file)
			form.setFieldValue(field.name, parsedJson)
			await form.validateFields([field.name])
			message.success(`${file.name} uploaded successfully`)
			return false // Prevent actual upload
		} catch {
			message.error('Failed to process file')
			return Upload.LIST_IGNORE
		}
	}

	return (
		<div className={cx('flex flex-col mb-6', field.className)}>
			<Form.Item
				name={field.name}
				rules={rules}
				className="mb-0 [&_.ant-form-item-control-input]:!min-h-0"
				label={field.label}
				required={field.required}
			>
				<Input type="hidden" />
			</Form.Item>
			<Upload
				accept=".json,application/json"
				maxCount={1}
				fileList={fileList}
				beforeUpload={handleBeforeUpload}
				onChange={({ fileList: newFileList }) => setFileList(newFileList)}
				onRemove={async () => {
					form.setFieldValue(field.name, undefined)
					try {
						await form.validateFields([field.name])
					} catch {
						// Validation error should not prevent removal
					}
					return true
				}}
			>
				<Button className={field.buttonClassName} icon={<UploadOutlined />}>
					{field.buttonText || 'Upload File'}
				</Button>
			</Upload>
		</div>
	)
}

export const FormFieldRenderer: React.FC<FormFieldRendererProps> = ({ field, form, disabled, environments }) => {
	const renderSimpleField = () => {
		if (field.fieldType !== 'simple') return null

		const rules: Rule[] = (field.rules || []).map((rule) => ({
			validator: async (_: Rule, value: unknown) => {
				try {
					await rule.validator(value as string, form)
				} catch (error) {
					throw new Error(rule.message || (error as Error).message)
				}
			},
		}))

		if (field.required) {
			rules.push({ required: true, message: `${field.label} is required` })
		}
		if (field.type === 'url') {
			rules.push({ type: 'url', message: `${field.label} should be a valid URL` })
		}

		return (
			<Form.Item
				name={field.name}
				label={field.label}
				required={field.required}
				rules={rules}
				dependencies={field.dependencies}
			>
				<Input type={field.type} placeholder={field.placeholder} disabled={disabled} />
			</Form.Item>
		)
	}

	const renderGeneratedField = () => {
		if (field.fieldType !== 'generated') return null
		const existingId = field.getExistingId?.(environments)
		return <GeneratedField field={field} form={form} existingId={existingId} />
	}

	const renderSelectField = () => {
		if (field.fieldType !== 'select') return null
		const options = field.options(environments)

		return (
			<Form.Item
				name={field.name}
				label={field.label}
				required={field.required}
				rules={[{ required: field.required, message: `${field.label} is required` }]}
			>
				<Select
					placeholder={field.placeholder}
					disabled={disabled}
					options={options}
					showSearch
					allowClear
					filterOption={(input, option) =>
						(option?.label as string).toLowerCase().includes(input.toLowerCase())
					}
					{...(field.allowCreate && {
						dropdownRender: (menu) => (
							<>
								{menu}
								<div className="px-3 py-2 mt-2 border-t">
									<Input
										placeholder={field.inputPlaceholder}
										onPressEnter={(e) => {
											const value = e.currentTarget.value
											if (value) {
												const newOption = { label: value, value }
												options.push(newOption)
												form.setFieldValue(field.name, value)
											}
										}}
									/>
								</div>
							</>
						),
					})}
				/>
			</Form.Item>
		)
	}

	const renderFieldGroup = () => {
		if (field.fieldType !== 'group') return null

		return (
			<>
				{field?.guideFile && field?.guideFileName && (
					<p className="text-sm text-textIcon-secondary mb-4 mt-6">
						Please visit the <Link onClick={handleGuideDownload}>{field?.guideFileName}</Link> for further
						information
					</p>
				)}
				<div className="mb-4 bg-surface-tertiary p-2 rounded-md border border-border-secondary">
					<div className="flex items-center gap-2 mb-3">
						<span className="text-sm font-medium">{field.label}</span>
						{!field.required && <span className="text-xs text-textIcon-secondary">(Optional)</span>}
					</div>
					<div className="[&_.ant-form-item]:mb-2 last:[&_.ant-form-item]:mb-0">
						{field.fields.map((subField) => (
							<FormFieldRenderer
								key={subField.name}
								field={subField}
								form={form}
								disabled={disabled}
								environments={environments}
							/>
						))}
					</div>
				</div>
			</>
		)
	}

	const renderTextAreaField = () => {
		if (field.fieldType !== 'textArea') return null

		const rules = [
			{ required: field.required, message: `${field.label} is required` },
			...(field.rules || []).map((rule) => ({
				validator: async (_: Rule, value: unknown) => {
					try {
						await rule.validator(value as string, form)
					} catch (error) {
						throw new Error(rule.message || (error as Error).message)
					}
				},
			})),
		]

		return (
			<Form.Item
				name={field.name}
				label={field.label}
				required={field.required}
				dependencies={field.dependencies}
				rules={rules}
			>
				<Input.TextArea
					placeholder={field.placeholder}
					disabled={disabled}
					style={{ height: field.minHeight ?? 150 }}
				/>
			</Form.Item>
		)
	}

	const renderFileUploadField = () => {
		if (field.fieldType !== 'fileUpload') return null

		const rules = [
			{ required: field.required, message: `${field.label} is required` },
			...(field.rules || []).map((rule) => ({
				validator: async (_: Rule, value: unknown) => {
					try {
						await rule.validator(value as string, form)
					} catch (error) {
						throw new Error(rule.message || (error as Error).message)
					}
				},
			})),
		]

		return <FileUploadField field={field} form={form} rules={rules} />
	}

	const renderTabsField = () => {
		if (field.fieldType !== 'groupedTabs') return null

		const rules: Rule[] = (field.rules || []).map((rule) => ({
			validator: async (_: Rule, value: unknown) => {
				try {
					await rule.validator(value as string, form)
				} catch (error) {
					message.error(rule.message)
				}
			},
		}))

		return (
			<div className="mb-4">
				<Form.Item name={field.name} rules={rules} className="hidden" required={field.required}>
					<Input type="hidden" />
				</Form.Item>
				<div className="px-2 py-1 border border-border-secondary rounded-md">
					<Tabs
						defaultActiveKey={field.defaultActiveKey || field.tabs[0].key}
						centered
						className="w-full m-0 full-width-tabs"
						items={field.tabs.map((tab) => ({
							key: tab.key,
							label: tab.title,
							children: (
								<div className="">
									{tab.fields.map((subField) => (
										<FormFieldRenderer
											key={subField.name}
											field={subField}
											form={form}
											disabled={disabled}
											environments={environments}
										/>
									))}
								</div>
							),
						}))}
					/>
				</div>
			</div>
		)
	}

	const handleGuideDownload = () => {
		if (field.fieldType === 'group' && 'guideFile' in field && 'guideFileName' in field) {
			const link = document.createElement('a')
			link.href = field.guideFile as string
			link.download = `${field.guideFileName as string}.pdf`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}

	switch (field.fieldType) {
		case 'simple':
			return renderSimpleField()
		case 'generated':
			return renderGeneratedField()
		case 'group':
			return renderFieldGroup()
		case 'select':
			return renderSelectField()
		case 'textArea':
			return renderTextAreaField()
		case 'fileUpload':
			return renderFileUploadField()
		case 'groupedTabs':
			return renderTabsField()
		default:
			return null
	}
}
