import cx from 'classnames'
import { formatNumber } from '../../utils/numberUtils.ts'

type ChartLegendItemProps = {
	color: string
	label: React.ReactNode
	labelKey: string
	count?: number
	disabled?: boolean
	onClick?: (label: string) => void
	withBorder?: boolean
}

export const ChartLegendItem = ({
	color,
	label,
	labelKey,
	count,
	disabled,
	onClick,
	withBorder,
}: ChartLegendItemProps) => {
	const className = cx('flex gap-1 items-center text-xs rounded transition w-fit h-fit text-textIcon-primary', {
		'cursor-pointer': !!onClick,
		'hover:bg-surface-fourthy': !!onClick,
		'opacity-35': !!disabled,
		'border border-border-tertiary px-2 py-1': !!withBorder,
		'px-1': !withBorder,
	})

	return (
		<div className={className} onClick={onClick ? () => onClick(labelKey) : undefined}>
			<div className="w-2 h-2 rounded-full" style={{ backgroundColor: color }} />
			<span>{label}</span>
			{typeof count === 'number' && <span className="font-semibold">{formatNumber(count)}</span>}
		</div>
	)
}
