import { useMemo } from 'react'
import { DEFAULT_IDENTITIES_LENS, IDENTITIES_LENS_MAPPING } from './identitiesLensMapping.tsx'
import { Link, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../RoutePaths.tsx'
import { CloseOutlined } from '@ant-design/icons'
import { PageHeaderChip } from '../../components/common/PageHeaderChip.tsx'

export const IdentitiesPageTitle = () => {
	const { lens } = useSearch({ from: RoutePaths.Identities })
	const { label, icon: Icon } = useMemo(() => IDENTITIES_LENS_MAPPING[lens || DEFAULT_IDENTITIES_LENS], [lens])
	return (
		<div className="flex items-center gap-3">
			{'Inventory'}
			{!!lens && lens !== DEFAULT_IDENTITIES_LENS && (
				<PageHeaderChip>
					<Icon className="w-3 h-3" />
					<span className="font-light">{label}</span>
					<Link
						from={RoutePaths.Identities}
						to={RoutePaths.Identities}
						search={(prevSearch) => {
							const newSearch = { ...prevSearch }
							delete newSearch.lens
							return newSearch
						}}
					>
						<CloseOutlined className="w-3 h-3" />
					</Link>
				</PageHeaderChip>
			)}
		</div>
	)
}
