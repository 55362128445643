import { ServerIssueUpdate, ServerIssueUpdateSchema } from '../schemas/issues/issueUpdate'
import {
	ClientIssueSearch,
	IssueImpactProbabilityWithCount,
	IssueImpactProbabilityWithCountSchema,
	IssueName,
	IssuesPageLens,
	IssuesTabName,
	IssueTablePaginatedRow,
	ServerIssue,
	ServerIssueIdentityEnvironment,
	ServerIssueSchema,
	ServerIssuesQueryResponse,
	ServerIssuesQueryResponseSchema,
	ServerIssuesStatsSchema,
	ServerIssueStats,
	ServerPaginatedIssuesStats,
	ServerPaginatedIssuesStatsSchema,
	IssueType,
	RemediationType,
} from '../schemas/issue'
import { api } from './api'
import { useMutation, useQuery, useQueryClient, keepPreviousData } from '@tanstack/react-query'
import { QueryParameters } from '../schemas/query.ts'
import { queryClient } from './queryClient.ts'
import { identityKeys } from './identities.ts'
import { IdentitySource } from '../schemas/identity.ts'

const issuesApi = {
	async search(search?: ClientIssueSearch): Promise<ServerIssue[]> {
		const { data: issues } = await api.post<ServerIssue[]>(`/api/issues/search`, search)
		return issues.map((issue) => ServerIssueSchema.parse(issue))
	},
	async stats(issueType?: IssueType | IssueType[]): Promise<ServerIssueStats[]> {
		const { data: issuesStats } = await api.get<ServerIssueStats[]>(`/api/issues/stats`, {
			params: { issue_type: issueType },
			paramsSerializer: { indexes: null },
		})
		return issuesStats.map((issue) => ServerIssuesStatsSchema.parse(issue))
	},
	async byId(issueId: string): Promise<ServerIssue> {
		const { data: issue } = await api.get<ServerIssue>(`/api/issues/${issueId}`)
		return ServerIssueSchema.parse(issue)
	},
	async update(issueId: string, issueUpdate: ServerIssueUpdate): Promise<ServerIssue> {
		const { data: issue } = await api.put<ServerIssue>(
			`/api/issues/${issueId}`,
			ServerIssueUpdateSchema.parse(issueUpdate),
		)

		return ServerIssueSchema.parse(issue)
	},
	async types(issueType?: IssueType | IssueType[]): Promise<IssueImpactProbabilityWithCount[]> {
		const { data: issueTypes } = await api.get<IssueImpactProbabilityWithCount[]>(`/api/issues/types`, {
			params: { issue_type: issueType },
			paramsSerializer: { indexes: null },
		})
		return issueTypes.map((type) => IssueImpactProbabilityWithCountSchema.parse(type))
	},
	async updateIssueTypes(issueTypes: IssueImpactProbabilityWithCount[]): Promise<void> {
		await api.put(`/api/issues/types`, issueTypes)
	},
	async getPaginatedStats(
		query: QueryParameters,
		lens?: IssuesPageLens | IssueName,
		tab?: IssuesTabName,
		issueType?: IssueType,
	): Promise<ServerPaginatedIssuesStats> {
		const { data } = await api.post<ServerPaginatedIssuesStats>(`/api/issues/query-stats`, {
			query,
			lens,
			tab,
			issue_type: issueType,
		})
		return ServerPaginatedIssuesStatsSchema.parse(data)
	},
	async getIssuesExportingLimit(): Promise<number> {
		const { data } = await api.get<number>(`/api/issues/export-limit`)
		return data
	},
	async queryIssuesForExport(
		query: QueryParameters,
		lens?: IssuesPageLens | IssueName,
		tab?: IssuesTabName,
		issueType?: IssueType,
	): Promise<ServerIssuesQueryResponse> {
		const { data } = await api.post<ServerIssuesQueryResponse>(`/api/issues/export`, {
			query,
			lens,
			tab,
			issue_type: issueType,
		})
		return ServerIssuesQueryResponseSchema.parse(data)
	},
	async getRemediationSteps(issueId: string, remediationType: RemediationType): Promise<string[]> {
		const { data } = await api.post<string[]>(
			`/api/issues/remediation-steps?issue_id=${issueId}&remediation_type=${remediationType}`,
		)
		return data
	},
}

const issueKeys = {
	issueSearches: () => ['issueSearch'] as const,
	issueSearch: (search?: ClientIssueSearch) => [...issueKeys.issueSearches(), search] as const,
	issueStats: (issueType?: IssueType | IssueType[]) => ['issueStats', issueType] as const,
	issue: (issueId: string) => ['issue', issueId] as const,
	updateIssue: () => ['updateIssue'] as const,
	issueTypes: () => ['types'] as const,
	updateIssueTypes: () => ['updateIssueTypes'] as const,
	queryIssues: (
		query: QueryParameters,
		lens?: IssuesPageLens | IssueName,
		tab?: IssuesTabName,
		issueType?: IssueType,
	) => ['queryIssues', query, lens, tab, issueType] as const,
	issuesNames: (
		query: QueryParameters,
		tab?: IssuesTabName,
		lens?: IssuesPageLens | IssueName,
		issueType?: IssueType,
	) => ['issuesNames', query, tab, lens, issueType] as const,
	issueSources: (
		query: QueryParameters,
		tab?: IssuesTabName,
		lens?: IssuesPageLens | IssueName,
		issueType?: IssueType,
	) => ['issueSources', query, tab, lens, issueType] as const,
	issuesAssignees: (
		query: QueryParameters,
		tab?: IssuesTabName,
		lens?: IssuesPageLens | IssueName,
		issueType?: IssueType,
	) => ['issuesAssignees', query, tab, lens, issueType] as const,
	issuesIdentityAccounts: (
		query: QueryParameters,
		tab?: IssuesTabName,
		lens?: IssuesPageLens | IssueName,
		issueType?: IssueType,
	) => ['issuesIdentityAccounts', query, tab, lens, issueType] as const,
	issueRemediationSteps: (issueId: string, remediationType: RemediationType) =>
		['issueRemediationSteps', issueId, remediationType] as const,
}

export async function fetchIssuesQueryForExport(
	query: QueryParameters,
	lens?: IssuesPageLens | IssueName,
	tab?: IssuesTabName,
	issueType?: IssueType,
): Promise<ServerIssuesQueryResponse> {
	return queryClient.fetchQuery({
		queryKey: ['queryIssuesForExport', query, lens, tab, issueType],
		queryFn: () => issuesApi.queryIssuesForExport(query, lens, tab, issueType),
		staleTime: 1 * 60 * 1000, // 1 minute
	})
}

export function useIssuesExportingLimit() {
	return useQuery({
		queryKey: ['queryIssuesExportingLimit'],
		queryFn: () => issuesApi.getIssuesExportingLimit(),
	})
}

export function useIssueSearch(search?: ClientIssueSearch) {
	if (['ALL'].includes(search?.issueName?.toUpperCase() as string)) {
		delete search?.issueName
	}

	return useQuery({
		queryKey: issueKeys.issueSearch(search),
		queryFn: () => issuesApi.search(search),
	})
}

export function useIssuesStats(issueType?: IssueType) {
	return useQuery({
		queryKey: issueKeys.issueStats(issueType),
		queryFn: () => issuesApi.stats(issueType),
	})
}

type UseIssueOptions = {
	enabled?: boolean
}

export function useIssue(issueId: string, options: UseIssueOptions = {}) {
	const { enabled = true } = options
	return useQuery({
		queryKey: issueKeys.issue(issueId),
		queryFn: () => issuesApi.byId(issueId),
		enabled: enabled && !!issueId,
	})
}

export function useUpdateIssue() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: issueKeys.updateIssue(),
		mutationFn: ({ issueId, issueUpdate }: { issueId: string; issueUpdate: ServerIssueUpdate }) =>
			issuesApi.update(issueId, issueUpdate),
		onSuccess: (data) => {
			queryClient.setQueryData(issueKeys.issue(data.id), data)
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueSearches(), refetchType: 'active' })
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueStats(), refetchType: 'active' })
			if (data.identity?.id) {
				void queryClient.invalidateQueries({
					queryKey: identityKeys.identityById(data.identity.id),
					refetchType: 'active',
				})
			}
		},
	})
}

export function useIssueTypes() {
	return useQuery({
		queryKey: issueKeys.issueTypes(),
		queryFn: () => issuesApi.types(),
		staleTime: Infinity,
		gcTime: Infinity,
	})
}

export function useUpdateIssueTypes() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: issueKeys.updateIssueTypes(),
		mutationFn: async (issueTypes: IssueImpactProbabilityWithCount[]) =>
			await issuesApi.updateIssueTypes(issueTypes),
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueTypes(), refetchType: 'active' })
		},
	})
}

async function queryIssues(
	query: QueryParameters,
	lens?: IssuesPageLens | IssueName,
	tab?: IssuesTabName,
	issueType?: IssueType,
): Promise<ServerIssuesQueryResponse> {
	const { data } = await api.post<IssueTablePaginatedRow>(`/api/issues/query`, {
		query: query,
		lens: lens,
		tab: tab,
		issue_type: issueType,
	})
	return ServerIssuesQueryResponseSchema.parse(data)
}

export function usePaginatedIssuesStats(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
) {
	return useQuery({
		queryKey: ['issuesPaginatedStats', query, tab, lens, issueType],
		queryFn: () => issuesApi.getPaginatedStats(query, lens, tab, issueType),
		staleTime: 5 * 60 * 1000, // 5 minutes
		placeholderData: keepPreviousData,
	})
}

export function fetchIssuesQuery(
	query: QueryParameters,
	lens?: IssuesPageLens | IssueName,
	tab?: IssuesTabName,
	issueType?: IssueType,
): Promise<ServerIssuesQueryResponse> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.queryIssues(query, lens, tab, issueType),
		queryFn: () => {
			return queryIssues(query, lens, tab, issueType)
		},
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIssueNames(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
): Promise<Array<IssueName>> {
	const { data } = await api.post<Array<IssueName>>(`/api/issues/names`, {
		query,
		tab,
		lens,
		issue_type: issueType,
	})
	return data
}

export async function fetchIssueNames(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
): Promise<Array<IssueName>> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.issuesNames(query, tab, lens, issueType),
		queryFn: () => getIssueNames(query, tab, lens, issueType),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIssueSources(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
): Promise<Array<IdentitySource>> {
	const { data } = await api.post<Array<IdentitySource>>(`/api/issues/sources`, {
		query,
		tab,
		lens,
		issue_type: issueType,
	})
	return data
}

export async function fetchIssueSources(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
): Promise<Array<IdentitySource>> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.issueSources(query, tab, lens, issueType),
		queryFn: () => getIssueSources(query, tab, lens, issueType),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIssuesIdentityAccounts(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
): Promise<Array<ServerIssueIdentityEnvironment>> {
	const { data } = await api.post<Array<ServerIssueIdentityEnvironment>>(`/api/issues/identities-accounts`, {
		query,
		tab,
		lens,
		issue_type: issueType,
	})
	return data
}

export async function fetchIssuesIdentityAccounts(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
): Promise<Array<ServerIssueIdentityEnvironment>> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.issuesIdentityAccounts(query, tab, lens, issueType),
		queryFn: () => getIssuesIdentityAccounts(query, tab, lens, issueType),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIssuesAssignees(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
): Promise<Array<string | null>> {
	const { data } = await api.post<Array<string | null>>(`/api/issues/assignees`, {
		query,
		tab,
		lens,
		issue_type: issueType,
	})
	return data
}

export async function fetchIssuesAssignees(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	issueType?: IssueType,
): Promise<Array<string | null>> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.issuesAssignees(query, tab, lens, issueType),
		queryFn: () => getIssuesAssignees(query, tab, lens, issueType),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

export function useIssueRemediation(issueId: string, remediationType: RemediationType | null) {
	return useQuery({
		queryKey: issueKeys.issueRemediationSteps(issueId, remediationType!),
		queryFn: async () => {
			const [result] = await Promise.all([
				issuesApi.getRemediationSteps(issueId, remediationType!),
				// Adding an artificial timeout of 3 seconds to make it appear like the AI is thinking
				new Promise((resolve) => setTimeout(resolve, 3000)),
			])

			return result
		},
		enabled: !!remediationType,
		select: (data) => data.join('\n\n'),
		gcTime: 0,
	})
}
