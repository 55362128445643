import { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { useMemo } from 'react'
import { ServerGoogleWorkspaceUserDemoDomainWideDelegation } from '../../../../../schemas/identities/googleWorkspaceUserSchema'
import { Tooltip } from 'antd'

type GoogleWorkspaceDelegationTableProps = {
	data: ServerGoogleWorkspaceUserDemoDomainWideDelegation[]
}

export const GoogleWorkspaceDelegationTable = ({ data }: GoogleWorkspaceDelegationTableProps) => {
	const columnDefs = useMemo<ColDef<ServerGoogleWorkspaceUserDemoDomainWideDelegation>[]>(
		() => [
			{
				field: 'gcp_service_account_name',
				headerName: 'Service Account',
				flex: 2,
			},
			{
				field: 'scopes',
				headerName: 'Scopes',
				flex: 3,
				filter: 'agTextColumnFilter',
				valueFormatter: (params: { value?: string[] }) => params.value?.join(', ') || '',
				cellRenderer: (params: { value?: string[] }) => (
					<Tooltip placement="topLeft" title={params.value?.join('\n') || ''} classNames={{ body: 'w-fit' }}>
						<div>{params.value?.join(', ') || ''}</div>
					</Tooltip>
				),
			},
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	return (
		<div className="w-full h-96">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				rowData={data}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				enableCellTextSelection
			/>
		</div>
	)
}
