import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import AzureDevopsIcon from '../../../../assets/azure_devops_logo_16.svg?react'
import { ServerAzureDevopsEnvironment } from '../../../../schemas/environments/azureDevopsEnvironment.ts'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'

type AzureDevopsSinglePageIntegrationBodyProps = {
	azureDevopsEnvironments: ServerAzureDevopsEnvironment[]
}

export const AzureDevopsSinglePageIntegrationBody: React.FC<AzureDevopsSinglePageIntegrationBodyProps> = ({
	azureDevopsEnvironments,
}) => {
	return (
		<IntegrationsSection>
			{azureDevopsEnvironments?.map((environment) => {
				const cardConfig = {
					title: environment.name,
					logo: <IntegrationIcon leftIcon={AzureDevopsIcon} />,
					fields: [{ label: 'Organization Name', value: environment.organization_name }],
				}
				return (
					<SingleIntegrationGenericCard
						key={environment.id}
						title={cardConfig.title}
						logo={cardConfig.logo}
						fields={cardConfig.fields}
					/>
				)
			})}
		</IntegrationsSection>
	)
}
