import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { DetailedEntraIdRoleAssignment } from '../../../../../schemas/identities/entraId/entraIdRoleAssignmentsSchemas.ts'
import { BasePermissionNode } from '../../common/baseNodes/BasePermissionNode.tsx'

export type DetailedEntraIdRolesNodeType = Node<{ roles: DetailedEntraIdRoleAssignment[] }, 'detailedEntraIdRoles'>
type EntraIdRolesNodeProps = NodeProps<DetailedEntraIdRolesNodeType>

export const DetailedEntraIdRolesNode = ({ data: { roles } }: EntraIdRolesNodeProps) => {
	const badgeTooltipContent = roles.map((role) => (
		<span key={`${role.roleDefinition.id}${role.roleAssignment.id}`}>
			{role.roleDefinition.displayName || 'Unknown role'}
		</span>
	))

	return (
		<BasePermissionNode
			label="Entra ID Roles"
			icon={<TeamOutlined />}
			groupProps={{ count: roles.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
