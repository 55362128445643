import { Tooltip } from 'antd'
import { useActiveSidePanelKeysContext } from '../../ActiveSidePanelKeysContext'
import { ServerIdentity, IdentitySource } from '../../../../schemas/identity'
import { NodeBadge } from './NodeBadge'
import IdentityTagIcon from '../../../../assets/identityTagIcons/identity_tag_icon_16.svg?react'
import { useActiveTagsKeysContext } from '../../ActiveTagsKeysContext.tsx'
import { TagsCollapseItemKeys } from '../../IdentitySidePanel/SidePanelTags.tsx'
import { useMemo } from 'react'

interface TagsNodeIndicatorProps {
	identity: ServerIdentity
	keyId: TagsCollapseItemKeys
}

export const TagsNodeIndicator = ({ identity, keyId }: TagsNodeIndicatorProps) => {
	const { setActiveSidePanelKeys } = useActiveSidePanelKeysContext()
	const { setActiveTagsKeys } = useActiveTagsKeysContext()

	const hasTags = useMemo(() => {
		switch (keyId) {
			case 'base-identity-tags':
				return (
					(identity.source === IdentitySource.AWS_IAM_USER &&
						!!identity.aws_iam_user?.aws_iam_user_details_xc?.Tags?.length) ||
					(identity.source === IdentitySource.AWS_IAM_ROLE &&
						!!identity.aws_iam_role?.aws_iam_role_details_xc?.Tags?.length) ||
					(identity.source === IdentitySource.AZURE_KV_SECRET &&
						!!identity.azure_kv_secret?.tags &&
						Object.keys(identity.azure_kv_secret.tags).length > 0) ||
					(identity.source === IdentitySource.AZURE_KV_CERTIFICATE &&
						!!identity.azure_kv_certificate?.tags &&
						Object.keys(identity.azure_kv_certificate.tags).length > 0) ||
					(identity.source === IdentitySource.AZURE_KV_KEY &&
						!!identity.azure_kv_key?.tags &&
						Object.keys(identity.azure_kv_key.tags).length > 0)
				)
			case 'azure-vault-tags':
				// eslint-disable-next-line no-case-declarations
				const azureVault =
					identity.azure_kv_secret?.key_vault ||
					identity.azure_kv_certificate?.key_vault ||
					identity.azure_kv_key?.key_vault

				return !!azureVault && !!azureVault.tags && Object.keys(azureVault.tags).length > 0
		}
	}, [identity, keyId])

	if (!hasTags) {
		return null
	}

	const nodeType = keyId === 'azure-vault-tags' ? 'dependencies' : 'identity'

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		setActiveSidePanelKeys(['Tags'])
		if (
			identity.source === IdentitySource.AZURE_KV_KEY ||
			identity.source === IdentitySource.AZURE_KV_SECRET ||
			identity.source === IdentitySource.AZURE_KV_CERTIFICATE
		) {
			setActiveTagsKeys([keyId])
		}
	}

	return (
		<Tooltip title="View Tags">
			<NodeBadge nodeColorType={nodeType} style={{ left: 30 }} onClick={handleClick}>
				<IdentityTagIcon style={{ height: 18, width: 18 }} />
			</NodeBadge>
		</Tooltip>
	)
}
