import { Node, NodeProps } from '@xyflow/react'
import { ServerGcpProject } from '../../../../schemas/environments/gcpProject'
import GcpIcon from '../../../../assets/gcp_project_icon.svg?react'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode.tsx'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'

export type GcpProjectNodeType = Node<{ project: ServerGcpProject }, 'gcpProject'>
type GcpProjectNodeProps = NodeProps<GcpProjectNodeType>

export const GcpProjectNode = ({ data: { project } }: GcpProjectNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'gcpProject' && highlightedNode.id === project.id,
	)

	return (
		<BaseEnvironmentNode
			label="GCP Project"
			name={project.project_id ? { text: project.project_id } : null}
			icon={<GcpIcon />}
			tooltipTitle={project.name}
			priority={project.priority ?? undefined}
			isHighlighted={isHighlighted}
		/>
	)
}
