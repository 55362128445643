import React from 'react'
import { Button, ConfigProvider, Menu, MenuProps } from 'antd'
import FindingsIcon from '../../assets/findings_icon.svg?react'
import IdentitiesIcon from '../../assets/identities_icon.svg?react'
import MetricsIcon from '../../assets/metrics_icon.svg?react'
import ItdrIcon from '../../assets/itdr_icon.svg?react'
import Lottie from 'lottie-react'
import groovyWalkAnimation from '../../assets/token_animation_lottie.json'
import { useAuth } from '../../services/auth/auth.ts'
import { UserLogo } from './UserLogo'
import { TenantMenuItem } from './TenantMenuItem.tsx'
import { useNavigate, useRouterState } from '@tanstack/react-router'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'
import { IssuesTabName } from '../../schemas/issue.ts'
import { ApiOutlined, SettingOutlined } from '@ant-design/icons'
import { isDemo } from '../../utils/demoUtils.ts'
import { RoutePaths } from '../../routes/RoutePaths.tsx'
import { SideBarMenuIconButton } from './SideBarMenuIconButton.tsx'
import { pageSidebarTheme } from '../../styles/pageSidebarTheme.ts'
import { useAuthActions } from '../../services/auth/authActions.ts'

type MenuItem = Required<MenuProps>['items'][number]

export const SideBarMenu: React.FC = () => {
	const navigate = useNavigate({ from: RoutePaths.Index })
	const {
		location: { pathname },
	} = useRouterState()
	const { user, tenantsState, isAdmin } = useAuth()
	const { logout, showAdminPortal, switchTenant } = useAuthActions()

	let menuItems: MenuItem[] = []
	if (user) {
		const extraItems = []
		if (tenantsState && user.tenantIds.length > 1) {
			const sortedTenants = [...tenantsState.tenants].sort((t1, t2) =>
				t1.name.toLowerCase().localeCompare(t2.name.toLowerCase()),
			)

			extraItems.push(
				getItem('Switch Tenant', 'switch-tenant', () => {}, null, [
					...sortedTenants.map((tenant) => {
						return getItem(
							'',
							tenant.tenantId,
							() => {
								navigate({ to: RoutePaths.Index }).then(() => {
									void switchTenant({
										tenantId: tenant.tenantId,
										callback: (_data, error) => {
											if (error) {
												console.error(error)
												return
											}
											window.location.reload()
										},
									})
								})
							},
							<TenantMenuItem tenant={tenant} />,
						)
					}),
				]),
			)
		}

		const profileItems = [
			...extraItems,
			getItem('Workspace Settings', 'settings', () => showAdminPortal()),
			getItem('API', 'api', () => void navigate({ to: '/swagger' })),
			getItem('Logout', 'logout', () => {
				void logout()
			}),
		]

		menuItems = [
			{
				key: 'profile',
				icon: <UserLogo user={user} />,
				children: profileItems,
				popupClassName:
					'[&_.ant-menu-sub]:!border [&_.ant-menu-sub]:!border-solid [&_.ant-menu-sub]:!border-border-secondaryDark',
			},
		]
	}

	function getItem(
		label: React.ReactNode,
		key?: React.Key | null,
		onClick?: React.MouseEventHandler<HTMLElement>,
		icon?: React.ReactNode,
		children?: MenuItem[],
	): MenuItem {
		return {
			key,
			icon,
			children,
			label,
			onClick,
		} as MenuItem
	}

	const changeRoute = (route: (typeof RoutePaths)[keyof typeof RoutePaths]) => {
		trackEvent(LogRocketTrackEvent.SideBarMenuClicked, { route })
		let searchParams = {}
		if ((route === RoutePaths.Findings || route === RoutePaths.Itdr) && isDemo) {
			searchParams = { issueName: 'All', tabName: IssuesTabName.OPEN }
		}
		if (route === RoutePaths.Identities) {
			searchParams = {}
		}
		void navigate({
			to: route,
			search: () => ({ ...searchParams }),
		})
	}

	return (
		<ConfigProvider theme={pageSidebarTheme}>
			<div className="flex flex-col h-full items-center w-full justify-between py-2">
				{user ? (
					<>
						<Button
							type="link"
							className="w-10 h-10 p-0"
							onClick={() => {
								changeRoute(RoutePaths.Metrics) // Metrics is currently the home page
							}}
						>
							<Lottie animationData={groovyWalkAnimation} loop={true} />
						</Button>
						<div className="flex flex-col items-center justify-center space-y-8">
							<SideBarMenuIconButton
								isActive={pathname === (RoutePaths.Metrics as string)}
								onClick={() => changeRoute(RoutePaths.Metrics)}
								tooltipTitle="Dashboard"
							>
								<MetricsIcon />
							</SideBarMenuIconButton>

							<SideBarMenuIconButton
								isActive={pathname.startsWith(RoutePaths.Identities)}
								onClick={() => changeRoute(RoutePaths.Identities)}
								tooltipTitle="Inventory"
							>
								<IdentitiesIcon />
							</SideBarMenuIconButton>
							<SideBarMenuIconButton
								isActive={pathname === (RoutePaths.Findings as string)}
								onClick={() => changeRoute(RoutePaths.Findings)}
								tooltipTitle={`${isDemo ? 'Security Posture' : 'Findings'}`}
							>
								<FindingsIcon />
							</SideBarMenuIconButton>

							<SideBarMenuIconButton
								isActive={pathname === (RoutePaths.Itdr as string)}
								onClick={() => {
									trackEvent(LogRocketTrackEvent.ITDRSideBarMenuClicked, {})
									changeRoute(RoutePaths.Itdr)
								}}
								tooltipTitle="ITDR"
							>
								<ItdrIcon />
							</SideBarMenuIconButton>
						</div>
						<div className="flex flex-col gap-2 items-center">
							{!isDemo && (
								<SideBarMenuIconButton
									isActive={pathname.startsWith(RoutePaths.Integrations as string)}
									onClick={() => changeRoute(RoutePaths.Integrations)}
									tooltipTitle="Integrations"
								>
									<ApiOutlined />
								</SideBarMenuIconButton>
							)}
							{isAdmin && (
								<SideBarMenuIconButton
									isActive={pathname === (RoutePaths.Settings as string)}
									onClick={() => changeRoute(RoutePaths.Settings)}
									tooltipTitle="Settings"
								>
									<SettingOutlined />
								</SideBarMenuIconButton>
							)}
							<Menu className="user-menu-sider !border-0" items={menuItems} />
						</div>
					</>
				) : null}
			</div>
		</ConfigProvider>
	)
}
