import { z } from 'zod'
import { TagSchema } from '../../tags.ts'

export const ServerAdAceSchema = z.object({
	right_name: z.string(),
	is_inherited: z.boolean(),
	source_principal_sid: z.string().nullish(),
	source_principal_type: z.string().nullish(),
	target_principal_sid: z.string().nullish(),
	target_principal_type: z.string().nullish(),
	target_principal_name: z.string().nullish(),
	tags: z.array(TagSchema).nullish(),
})

export type ServerAdAceInput = z.input<typeof ServerAdAceSchema>
export type ServerAdAce = z.infer<typeof ServerAdAceSchema>
