import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { ServerDetailedAzureRoleAssignment } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { useHighlightedNodesContext } from '../../../HighlightedNodesContext.tsx'
import { BasePermissionNode } from '../../common/baseNodes/BasePermissionNode.tsx'

// This is a production-ready azure-node component
export type EnrichedAzureRoleNodeType = Node<{ role: ServerDetailedAzureRoleAssignment }, 'enrichedAzureRole'>
type AzureRoleNodeProps = NodeProps<EnrichedAzureRoleNodeType>

export const EnrichedAzureRoleNode = ({ data: { role } }: AzureRoleNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) =>
			highlightedNode.type === 'enrichedAzureRole' && highlightedNode.id === role.role_assignment.id,
	)

	return (
		<BasePermissionNode
			label="Azure Role"
			name={{ text: role.role_definition.role_name || 'Unknown Role' }}
			risks={role.tags}
			icon={<TeamOutlined />}
			isHighlighted={isHighlighted}
		/>
	)
}
