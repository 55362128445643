import JumpcloudIcon from '../../../../assets/jumpcloud_icon_16.svg?react'
import JumpcloudGuide from '../../../../assets/integrationGuides/jumpcloud-integration-guide.pdf'
import {
	EnvironmentCreateRequest,
	EnvironmentTestConnectionRequest,
	EnvironmentTestConnectionResponsePayload,
	ServerEnvironments,
} from '../../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationCategory,
	EnvironmentIntegrationFormData,
} from '../environmentIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const jumpcloudConfig: ConnectableEnvironmentConfig = {
	key: EnvironmentType.JUMPCLOUD,
	name: 'JumpCloud',
	description: 'Link Token Security to your JumpCloud organizations to map cloud identities across your IDP.',
	logo: <IntegrationIcon leftIcon={JumpcloudIcon} />,
	category: EnvironmentIntegrationCategory.IDENTITY_ACCESS_MANAGEMENT,
	filterEnvironments: (environments: ServerEnvironments, searchText: string) => {
		const filtered = environments[EnvironmentType.JUMPCLOUD].filter(
			(env) =>
				env.name.toLowerCase().includes(searchText.toLowerCase()) ||
				env.org_id.toLowerCase().includes(searchText.toLowerCase()),
		)
		return filtered
	},
	environmentNamesResolver: (environments: ServerEnvironments) =>
		environments[EnvironmentType.JUMPCLOUD].map((env) => env.name),
	guideFileName: JumpcloudGuide,
	formFields: [
		{
			fieldType: 'simple',
			name: 'name',
			label: 'Environment Name',
			type: 'text',
			placeholder: 'Ex: my-jumpcloud-environment',
			required: true,
		},
		{
			fieldType: 'simple',
			name: 'apiKey',
			label: 'API Key',
			type: 'password',
			placeholder: 'Ex: AbC12345XyZ67890!@#$%^&*()_+',
			required: true,
		},
	],
	createTestConnectionPayload: (formData: EnvironmentIntegrationFormData): EnvironmentTestConnectionRequest => ({
		environment_type: EnvironmentType.JUMPCLOUD,
		config: {
			type: EnvironmentType.JUMPCLOUD,
			api_key: formData.apiKey as string,
		},
	}),
	createEnvironmentPayload: (
		formData: EnvironmentIntegrationFormData,
		testResultPayload: EnvironmentTestConnectionResponsePayload,
	): EnvironmentCreateRequest | null => {
		if (testResultPayload?.environment_type !== EnvironmentType.JUMPCLOUD) {
			return null
		}

		return {
			name: formData.name as string,
			environment_type: EnvironmentType.JUMPCLOUD,
			config: {
				type: EnvironmentType.JUMPCLOUD,
				org_id: testResultPayload.org_id,
				api_key: formData.apiKey as string,
			},
		}
	},
}
