import { Node, NodeProps } from '@xyflow/react'
import GithubIcon from '../../../../assets/githubIcon_blue.svg?react'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { ServerGithubEnvironment } from '../../../../schemas/environments/githubEnvironment.ts'

export type GithubEnvironmentNodeType = Node<{ environment: ServerGithubEnvironment }, 'githubEnvironment'>
type GithubEnvironmentNodeProps = NodeProps<GithubEnvironmentNodeType>

export const GithubEnvironmentNode = ({ data: { environment } }: GithubEnvironmentNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'githubEnvironment' && highlightedNode.id === environment.id,
	)

	return (
		<BaseEnvironmentNode
			label="GitHub"
			icon={<GithubIcon />}
			tooltipTitle={environment.name}
			isHighlighted={isHighlighted}
		/>
	)
}
