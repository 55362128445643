import { FindingsPaginated } from './FindingsPaginated.tsx'
import { FindingsDemo } from './FindingsDemo.tsx'
import { isDemo } from '../../utils/demoUtils.ts'
import { IssueType } from '../../schemas/issue.ts'
import { FindingsProvider } from './FindingsContext.tsx'

interface FindingsProps {
	issueType?: IssueType
}

export const Findings = ({ issueType = IssueType.FINDING }: FindingsProps) =>
	isDemo ? (
		<FindingsDemo />
	) : (
		<FindingsProvider issueType={issueType}>
			<FindingsPaginated issueType={issueType} />
		</FindingsProvider>
	)
