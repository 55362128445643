import { Node, NodeProps } from '@xyflow/react'
import { ServerSnowflakeRoleXc } from '../../../../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type SnowflakeRoleNodeType = Node<{ role: ServerSnowflakeRoleXc }, 'snowflakeRole'>
type SnowflakeRoleNodeProps = NodeProps<SnowflakeRoleNodeType>

export const SnowflakeRoleNode = ({ data: { role } }: SnowflakeRoleNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'snowflakeRole' && highlightedNode.id === role.name,
	)

	return <BasePermissionNode label="Snowflake Role" name={{ text: role.name }} isHighlighted={isHighlighted} />
}
