import { NodeProps, Node } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'
import { DemoAtlassianApiToken } from '../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'

export type DemoAtlassianAdminApiKeyNodeType = Node<DemoAtlassianApiToken, 'atlassianAdminApiKey'>

type DemoAtlassianAdminApiKeyNodeProps = NodeProps<DemoAtlassianAdminApiKeyNodeType>

export const DemoAtlassianAdminApiKeyNode = ({ data: { name, expires_on } }: DemoAtlassianAdminApiKeyNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'atlassianAdminApiKey' && highlightedNode.id === name,
	)

	return (
		<BaseCredentialsNode
			isHighlighted={isHighlighted}
			label="Org Admin API Key"
			name={{ text: name }}
			isActive={expires_on > new Date()}
		>
			<RiskNodeTagIcons tags={[{ name: 'ADMIN', id: '1234' }]} nodeColorType="credentials" />
		</BaseCredentialsNode>
	)
}
