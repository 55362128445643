import { Tag, Tooltip } from 'antd'
import React from 'react'
import { formatDate } from '../../../../../utils.ts'
import { GcpServiceAccountKey } from '../../../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'

export const GcpSaAccessKey: React.FunctionComponent<{
	accessKey?: GcpServiceAccountKey
	onOpenChange?: (visible: boolean) => void
}> = ({ accessKey, onOpenChange }) => {
	return (
		<div className="flex items-end truncate">
			<Tooltip
				onOpenChange={onOpenChange}
				classNames={{ body: 'w-fit' }}
				title={
					<div className="flex flex-col w-full">
						{accessKey?.disabled ? (
							<span className="flex text-sm">Status: {accessKey?.disabled ? 'Disabled' : 'Active'}</span>
						) : null}
						<span className="flex text-sm">ID: {accessKey?.accessKeyId}</span>
						<span className="flex text-sm">Creation date: {formatDate(accessKey?.validAfterTime)}</span>
						<span className="flex text-sm">Type: {accessKey?.keyType}</span>
					</div>
				}
			>
				<div className="flex items-center">
					<Tag
						className={`pl-1 ${accessKey?.disabled ? 'text-status-neutral' : 'text-status-active'} text text-sm`}
					>
						{accessKey?.accessKeyId}
					</Tag>
				</div>
			</Tooltip>
		</div>
	)
}
