import { z } from 'zod'
import { IssueName, PriorityType } from '../issue.ts'
import { ServerInsightSchema } from '../insight.ts'

export const ServerTopIssueSchema = z.object({
	issue_name: z.nativeEnum(IssueName),
	count: z.number(),
	max_priority: z.nativeEnum(PriorityType),
})

export const ServerTopIssuesAndInsightsSchema = z.object({
	issues: z.array(ServerTopIssueSchema),
	insights: z.array(ServerInsightSchema),
})

export type ServerTopIssuesAndInsightsInput = z.input<typeof ServerTopIssuesAndInsightsSchema>
export type ServerTopIssuesAndInsights = z.infer<typeof ServerTopIssuesAndInsightsSchema>
