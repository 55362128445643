import {
	EntraIdChangeLogsAggregated,
	ServerEntraIdChangeLog,
} from '../../../../../schemas/identities/entraId/entraIdChangeLogSchema.ts'
import { AgGridReact } from 'ag-grid-react'
import React, { useMemo } from 'react'
import { ColDef } from 'ag-grid-enterprise'
import { Tooltip } from 'antd'
import { aggregateEntraIdChangeLogs } from '../../../../../utils/entraIdentityUtils.ts'
import { formatDate, formatRelativeDateText } from '../../../../../utils.ts'

const defaultColDef: ColDef = {
	sortable: true,
	resizable: true,
	filter: true,
	cellStyle: () => ({
		display: 'flex',
		alignItems: 'center',
	}),
	flex: 1,
}

export const EntraIdChangeLogsAggregationsTable: React.FunctionComponent<{
	changeLogs: ServerEntraIdChangeLog[]
}> = ({ changeLogs }) => {
	const columnDefs: ColDef<EntraIdChangeLogsAggregated>[] = [
		{
			field: 'actorPrincipalName',
			headerName: 'Actor',
			cellRenderer: (params: { data: EntraIdChangeLogsAggregated }) => {
				return (
					<Tooltip placement="topLeft" title={params.data.actorPrincipalId}>
						{params.data.actorPrincipalName}
					</Tooltip>
				)
			},
		},
		{
			field: 'operations',
			headerName: 'Operations',
			filterParams: {
				valueFormatter: (params: { value: string }) => {
					return params.value
				},
				keyCreator: (params: { value: string }) => {
					return params.value
				},
			},
			valueFormatter: ({ value }: { value: string[] | string }) =>
				typeof value === 'string' ? value : value.join(','),
		},
		{
			field: 'operationCount',
			headerName: 'Count',
			filter: 'agNumberColumnFilter',
			sort: 'desc',
			flex: 0.8,
		},
		{
			field: 'sourceIpAddresses',
			headerName: 'Source IP Addresses',
			filterParams: {
				valueFormatter: (params: { value: string }) => {
					return params.value
				},
				keyCreator: (params: { value: string }) => {
					return params.value
				},
			},
			valueFormatter: ({ value }: { value: string[] | string }) => {
				return typeof value === 'string' ? value : value.join(',')
			},
		},
		{
			field: 'firstEventTime',
			headerName: 'First Event',
			filter: 'agDateColumnFilter',
			cellRenderer: ({ value }: { value: Date }) => (
				<Tooltip placement="bottomLeft" title={formatDate(value)}>
					<div className="text-textIcon-secondary">{formatRelativeDateText(value, true)}</div>
				</Tooltip>
			),
		},
		{
			field: 'lastEventTime',
			headerName: 'Last Event',
			filter: 'agDateColumnFilter',
			cellRenderer: ({ value }: { value: Date }) => (
				<Tooltip placement="bottomLeft" title={formatDate(value)}>
					<div className="text-textIcon-secondary">{formatRelativeDateText(value, true)}</div>
				</Tooltip>
			),
		},
	]

	const aggregatedEntries = useMemo(() => aggregateEntraIdChangeLogs(changeLogs), [changeLogs])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			rowData={aggregatedEntries}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
			suppressHorizontalScroll={false}
		/>
	)
}
