import cx from 'classnames'

import StatusClock from '../../../assets/status_clock.svg?react'

type DaysThresholdSelectOptionProps = {
	daysThreshold: number
	withBorder?: boolean
}

export const DaysThresholdSelectOption = ({ daysThreshold, withBorder }: DaysThresholdSelectOptionProps) => {
	const className = cx('flex items-center w-max', {
		'gap-1 text-sm': true,
		'border border-border-secondary p-1 pr-2 rounded': withBorder,
	})

	return (
		<div className={className}>
			<StatusClock />
			<span>{daysThreshold} days</span>
		</div>
	)
}
