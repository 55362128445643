import cx from 'classnames'
import { IntegrationStatus } from '../integrationTypes.ts'
import { CheckCircleOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

type IntegrationCardButtonProps = {
	onConnect?: () => void
	status: IntegrationStatus
	name?: string
}

export const IntegrationCardButton = ({ onConnect, status, name }: IntegrationCardButtonProps) => {
	const buttonClassName = cx('w-full h-8 flex items-center justify-center gap-2 text-sm font-medium', {
		'border border-status-active bg-status-activeLowContrast text-status-active rounded':
			status === IntegrationStatus.ACTIVE,
		'border border-status-critical bg-status-criticalLowContrast text-status-critical rounded':
			status === IntegrationStatus.NO_ACCESS,
	})

	if (status === IntegrationStatus.ACTIVE) {
		return (
			<div className={buttonClassName}>
				<CheckCircleOutlined /> Connected
			</div>
		)
	}

	if (status === IntegrationStatus.NO_ACCESS) {
		return (
			<Tooltip placement="bottom" title="Token has no access to this account">
				<div className={buttonClassName}>
					<ExclamationCircleOutlined /> No Access
				</div>
			</Tooltip>
		)
	}

	const buttonNode = (
		<Button
			onClick={onConnect ? onConnect : undefined}
			disabled={!onConnect}
			className={buttonClassName}
			icon={<PlusOutlined />}
		>
			Connect
		</Button>
	)

	if (!onConnect && name) {
		return (
			<Tooltip title={`To connect your ${name} environment please contact the Token team.`}>{buttonNode}</Tooltip>
		)
	}

	return buttonNode
}
