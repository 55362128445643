import { Modal, Slider, Input, ConfigProvider, Button, GetProps, ThemeConfig } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { themeColors } from '../../../utils/colorUtils.ts'

const StyledSlider = ({ ...props }: GetProps<typeof Slider>) => (
	<>
		<style>
			{`
				.custom-slider-no-handle .ant-slider-rail {
					border-radius: 4px;
				}

				.custom-slider-no-handle .ant-slider-track {
					height: 22px;
					border-radius: 2px;
					margin-top: 4px;
					margin-left: 4px;
					position: relative;
					border-radius: 4px;
					transform: scaleX(0.98);
					transform-origin: left;
				}
				.custom-slider-no-handle .ant-slider-track::after {
					content: '';
					position: absolute;
					right: 1.5px;
					top: 1px;
					width: 3px;
					height: 90%;
					background: ${themeColors.textIcon.dark};
					border-radius: 2px;
				}
				.custom-slider-no-handle .ant-slider-handle {
					display: none;
				}

			`}
		</style>
		<Slider {...props} className="custom-slider-no-handle" />
	</>
)

const customTheme = {
	components: {
		Slider: {
			railSize: 30,
			trackBg: themeColors.dsBlue[500],
			trackHoverBg: themeColors.dsBlue[400],
			railBg: themeColors.dsBlue[200],
			railHoverBg: themeColors.dsBlue[100],
		},
	},
} satisfies ThemeConfig

type DaysThresholdModalProps = {
	isOpen: boolean
	onClose: () => void
	onSave: (days: number) => void
	initialDays: number
}

export const DaysThresholdModal = ({ isOpen, onClose, onSave, initialDays }: DaysThresholdModalProps) => {
	const [customDays, setCustomDays] = useState(initialDays)

	return (
		<ConfigProvider theme={customTheme}>
			<Modal
				title={
					<div className="flex items-center gap-1 text-textIcon-secondaryDark mt-0.5">
						<EditOutlined />
						<span>Advanced Settings</span>
					</div>
				}
				open={isOpen}
				onCancel={onClose}
				onOk={() => {
					onSave(customDays)
					onClose()
				}}
				okText="Save"
				width={500}
				className="rounded"
				footer={null}
				styles={{
					header: {
						background: themeColors.surface.secondaryDark,
						padding: '12px 12px 12px',
						marginBottom: 0,
					},
					body: {
						background: themeColors.surface.secondaryDark,
						padding: '0 4px 4px',
					},
					content: {
						padding: 0,
						borderBottomLeftRadius: '4px',
						borderBottomRightRadius: '4px',
						overflow: 'hidden',
					},
				}}
			>
				<div className="rounded p-4 bg-surface-primary">
					<p className="text-sm mb-1 font-medium">Set Threshold Duration</p>
					<p className="text-textIcon-secondary text-sm -mb-4">
						Use this modal to specify the number of days you want to set for the threshold. Adjust the
						slider or input field to select the desired value. This setting will define the time frame for
						the threshold to apply. You can modify this duration at any time to better suit your needs.
					</p>
					<div className="flex items-center gap-2 -mb-4">
						<div className="flex items-center gap-2">
							<Input
								value={customDays}
								onChange={(e) => {
									const value = Number(e.target.value) || 1
									const clampedValue = Math.min(Math.max(value, 1), 999)
									setCustomDays(clampedValue)
								}}
								className="w-24"
								suffix="days"
								styles={{
									input: {
										textAlign: 'center',
									},
									suffix: {
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										paddingRight: '8px',
									},
								}}
							/>
						</div>
						<div className="flex-1">
							<StyledSlider value={customDays} onChange={setCustomDays} min={1} max={999} />
						</div>
					</div>
					<div className="flex justify-end gap-2">
						<Button onClick={onClose}>Cancel</Button>
						<Button
							onClick={() => {
								onSave(customDays)
								onClose()
							}}
							type="primary"
						>
							Save
						</Button>
					</div>
				</div>
			</Modal>
		</ConfigProvider>
	)
}
