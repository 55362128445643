import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import GcpIcon from '../../../../assets/gcp_icon_20.svg?react'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'
import { ServerGcpEnvironment } from '../../../../schemas/environments/gcpEnvironment.ts'
import { IntegrationStatus } from '../../common/integrationTypes.ts'

type GcpSinglePageIntegrationBodyProps = {
	gcpEnvironments: ServerGcpEnvironment[]
}

export const GcpSinglePageIntegrationBody: React.FC<GcpSinglePageIntegrationBodyProps> = ({ gcpEnvironments }) => {
	return (
		<div className="flex flex-col gap-6">
			{gcpEnvironments?.map((environment) => (
				<IntegrationsSection key={environment.id} title={environment.name}>
					{environment.gcp_projects.length === 0 ? (
						<div className="text-textIcon-secondary">
							No projects collected yet for environment "{environment.name}"
						</div>
					) : (
						environment.gcp_projects.map((project) => {
							const cardConfig = {
								title: project.project_id,
								logo: <IntegrationIcon leftIcon={GcpIcon} />,
								fields: [
									{
										label: 'Project Number',
										value: project.name,
									},
								],
							}
							return (
								<SingleIntegrationGenericCard
									key={project.id}
									title={cardConfig.title}
									logo={cardConfig.logo}
									fields={cardConfig.fields}
									status={
										project.token_has_access
											? IntegrationStatus.ACTIVE
											: IntegrationStatus.NO_ACCESS
									}
								/>
							)
						})
					)}
				</IntegrationsSection>
			))}
		</div>
	)
}
