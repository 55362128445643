import { Node } from '@xyflow/react'
import { BaseNode } from './baseNodes/BaseNode.tsx'
import { NodeBadge } from './NodeBadge.tsx'
import { AssociationTypeEnum, UsageAssociationItem } from '../../../../schemas/identities/usageAssociationItemSchema.ts'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { UsageAssociationIcon } from '../../../../components/common/UsageAssociationIcon.tsx'
import { capitalizeFirstLetter } from '../../../../utils/textUtils.ts'

export type AssociationNodeType = Node<UsageAssociationItem, 'association'>

export type AssociationNodeProps = {
	data: UsageAssociationItem
}

export const AssociationNode = ({ data: { name, instances_count: count } }: AssociationNodeProps) => {
	const iconWrapperClassName = `${nodeColorClassNames.association.bgHover} ${nodeColorClassNames.association.bg} ${nodeColorClassNames.association.text} w-[50px] h-[50px] rounded-full flex justify-center items-center text-2xl transition-colors duration-300`
	return (
		<BaseNode labelProps={{ label: capitalizeFirstLetter(name) }}>
			{name !== String(AssociationTypeEnum.UNKNOWN) && count > 1 && (
				<NodeBadge nodeColorType="association">{count}</NodeBadge>
			)}
			<div className={iconWrapperClassName}>
				<UsageAssociationIcon name={name} color="white" />
			</div>
		</BaseNode>
	)
}
