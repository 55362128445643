import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent, IRowNode } from 'ag-grid-enterprise'
import { ServerAwsEc2InstanceXc } from '../../../../../schemas/identities/awsEc2InstanceXcSchema.ts'
import SuccessIcon from '../../../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../../../assets/warning_icon_20.svg?react'
import { Tooltip } from 'antd'
import { formatDate, removeNulls } from '../../../../../utils.ts'
import { capitalizeFirstLetter } from '../../../../../utils/textUtils.ts'
import { JsonViewer } from '../../../../../components/common/JsonViewer.tsx'

export const Ec2InstancesTable: React.FunctionComponent<{
	awsEc2InstancesXc: ServerAwsEc2InstanceXc[] | null
}> = ({ awsEc2InstancesXc }) => {
	const gridRef = useRef<AgGridReact<ServerAwsEc2InstanceXc>>(null)
	const columnDefs = useMemo<ColDef<ServerAwsEc2InstanceXc>[]>(() => {
		return [
			{
				field: 'State.Name',
				headerName: 'State',
				flex: 0.75,
				cellRenderer: (params: { data?: ServerAwsEc2InstanceXc; node: IRowNode }) => {
					return <div>{capitalizeFirstLetter(params?.data?.State?.Name)}</div>
				},
			},
			{
				headerName: 'IMDS',
				flex: 0.75,
				cellRenderer: (params: { data?: ServerAwsEc2InstanceXc; node: IRowNode }) => {
					if (params.data?.MetadataOptions?.HttpEndpoint === 'disabled') {
						return <div>Disabled</div>
					}
					if (
						params.data?.MetadataOptions?.HttpEndpoint === 'enabled' &&
						params.data?.MetadataOptions?.HttpTokens === 'optional'
					) {
						return (
							<Tooltip title="IMDSv2 is optional">
								<span>
									<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
								</span>
							</Tooltip>
						)
					}
					return (
						<Tooltip title="IMDSv2 is required">
							<span>
								<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
							</span>
						</Tooltip>
					)
				},
			},
			{ field: 'InstanceId', headerName: 'Instance ID' },
			{ field: 'PublicIpAddress', headerName: 'Public IP' },
			{ field: 'PrivateIpAddress', headerName: 'Private IP' },
			{ field: 'InstanceLifecycle', headerName: 'Lifecycle' },
			{
				field: 'LaunchTime',
				headerName: 'Launch Time',
				cellRenderer: (params: { data?: ServerAwsEc2InstanceXc; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-textIcon-secondary">{formatDate(params.data?.LaunchTime)}</div>
				},
			},
			{
				field: 'Tags',
				headerName: 'Tags',
				valueFormatter: (params) => JSON.stringify(params.data?.Tags),
				cellRenderer: (params: { data?: ServerAwsEc2InstanceXc; node: IRowNode }) => {
					return <JsonViewer data={params.data?.Tags ? removeNulls(params.data.Tags) : {}} title="Tags" />
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<ServerAwsEc2InstanceXc>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={awsEc2InstancesXc}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
