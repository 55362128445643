import { IssueAIRemediation } from './IssueAIRemediation/IssueAIRemediation.tsx'
import { RemediationSteps } from '../../../../components/remediationSteps/RemediationSteps.tsx'
import { EmptyRemediation } from './EmptyRemediation.tsx'
import { RemediationStep } from '../../../../components/remediationSteps/remediationStepsData.ts'
import { RemediationType, ServerIssue } from '../../../../schemas/issue.ts'
import { Dispatch, SetStateAction } from 'react'

type IssueRemediationContentProps = {
	issue: ServerIssue
	remediationSteps?: RemediationStep[] | null
	issueHasAiRemediation: boolean
	isFullScreen?: boolean
	selectedRemediationType: RemediationType | null
	setSelectedRemediationType: Dispatch<SetStateAction<RemediationType | null>>
}

export const IssueRemediationContent = ({
	issue,
	remediationSteps,
	issueHasAiRemediation,
	isFullScreen,
	selectedRemediationType,
	setSelectedRemediationType,
}: IssueRemediationContentProps) => {
	return (
		<div className="grow h-full overflow-y-auto">
			{issueHasAiRemediation ? (
				<IssueAIRemediation
					issue={issue}
					isFullScreen={isFullScreen}
					selectedRemediationType={selectedRemediationType}
					setSelectedRemediationType={setSelectedRemediationType}
				/>
			) : remediationSteps?.length ? (
				<RemediationSteps remediationSteps={remediationSteps} />
			) : (
				<EmptyRemediation />
			)}
		</div>
	)
}
