import { createContext, useContext, ReactNode } from 'react'
import { RoutePaths } from '../RoutePaths'
import { IssueType } from '../../schemas/issue'

type FindingsContextType = {
	routePath: typeof RoutePaths.Findings | typeof RoutePaths.Itdr
	issueType: IssueType
}

const FindingsContext = createContext<FindingsContextType | null>(null)

export const FindingsProvider = ({ children, issueType }: { children: ReactNode; issueType: IssueType }) => {
	const routePath = issueType === IssueType.ITDR ? RoutePaths.Itdr : RoutePaths.Findings

	return <FindingsContext.Provider value={{ routePath, issueType }}>{children}</FindingsContext.Provider>
}
// eslint-disable-next-line react-refresh/only-export-components
export const useFindings = (): FindingsContextType => {
	const context = useContext(FindingsContext)
	if (!context) {
		throw new Error('useFindings must be used within a FindingsProvider')
	}
	return context
}
