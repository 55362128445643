import { Dispatch, SetStateAction, useRef } from 'react'
import cx from 'classnames'
import { RemediationType, ServerIssue } from '../../../../../schemas/issue.ts'
import { Button, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { useIssueRemediation } from '../../../../../api/issues.ts'
import { AIRemediationTargetPlatform } from './AIRemediationTargetPlatform.tsx'
import { AIRemediationStepsText } from './AIRemediationStepsText.tsx'
import { AIRemediationLoadingState } from './AIRemediationLoadingState.tsx'
import { AIRemediationEmptyErrorState } from './AIRemediationEmptyErrorState.tsx'

type IssueAIRemediationProps = {
	issue: ServerIssue
	isFullScreen?: boolean
	selectedRemediationType: RemediationType | null
	setSelectedRemediationType: Dispatch<SetStateAction<RemediationType | null>>
}

export const IssueAIRemediation = ({
	issue,
	isFullScreen,
	selectedRemediationType,
	setSelectedRemediationType,
}: IssueAIRemediationProps) => {
	const remediationContainerRef = useRef<HTMLDivElement>(null)
	const { data: remediationSteps, isLoading, isError } = useIssueRemediation(issue.id, selectedRemediationType)
	const [messageApi, antMessageContextHolder] = message.useMessage()

	const containerClassName = cx('flex h-full', {
		'border border-border-secondary rounded': isFullScreen,
	})

	const remediationContainerClassName = cx(
		'flex flex-col grow border-b border-b-border-secondary p-3 overflow-y-auto',
		{ 'rounded-tr bg-surface-primary': isFullScreen },
	)

	const onCopyToClipboardClick = () => {
		void navigator.clipboard.writeText(remediationSteps!).then(() => {
			messageApi.success('Copied remediation steps to clipboard')
		})
	}

	return (
		<div className={containerClassName}>
			{antMessageContextHolder}
			<div className="flex flex-col gap-1 border-r border-r-border-secondary p-4 overflow-y-auto items-stretch shrink-0">
				<span className="text-textIcon-secondary mb-3">Target Platform</span>
				{issue.ai_remediation_types!.map((remediationType) => (
					<AIRemediationTargetPlatform
						remediationType={remediationType}
						isSelected={selectedRemediationType === remediationType}
						onClick={() => setSelectedRemediationType(remediationType)}
						key={remediationType}
					/>
				))}
			</div>
			<div className="flex flex-col grow">
				<div className={remediationContainerClassName} ref={remediationContainerRef}>
					{isError ? (
						<AIRemediationEmptyErrorState isError />
					) : isLoading ? (
						<AIRemediationLoadingState />
					) : remediationSteps ? (
						<AIRemediationStepsText
							containerRef={remediationContainerRef}
							remediationSteps={remediationSteps}
						/>
					) : (
						<AIRemediationEmptyErrorState />
					)}
				</div>
				<div className="flex items-center justify-end shrink-0 py-2 px-3">
					<Button
						type="primary"
						disabled={!remediationSteps}
						loading={isLoading}
						onClick={onCopyToClipboardClick}
						icon={<CopyOutlined />}
					>
						Copy to clipboard
					</Button>
				</div>
			</div>
		</div>
	)
}
