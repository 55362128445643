import { z } from 'zod'
import { DateSchema } from './common.ts'
import { EnvironmentType } from './envType.ts'
import * as Sentry from '@sentry/react'
import { themeColors } from '../utils/colorUtils.ts'

export enum Tag {
	ADMIN = 'ADMIN',
	DATA_ACCESS = 'DATA_ACCESS',
	CREDENTIAL_EXPOSURE = 'CREDENTIAL_EXPOSURE',
	RESOURCE_EXPOSURE = 'RESOURCE_EXPOSURE',
	POSSIBLE_PRIVILEGE_ESCALATION = 'POSSIBLE_PRIVILEGE_ESCALATION',
}

export enum MockDemoTag {
	OVER_PRIVILEGED_IDENTITY = 'OVER_PRIVILEGED_IDENTITY',
	IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR = 'IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR',
}

export const TagsDisplayNameMap: Record<Tag, string> = {
	ADMIN: 'Admin',
	DATA_ACCESS: 'Data Access',
	CREDENTIAL_EXPOSURE: 'Credential Exposure',
	RESOURCE_EXPOSURE: 'Resource Exposure',
	POSSIBLE_PRIVILEGE_ESCALATION: 'Privilege Escalation',
}

export const TagsColorsMap: Record<Tag, string> = {
	ADMIN: themeColors.dsPink[400],
	DATA_ACCESS: themeColors.dsPink[600],
	CREDENTIAL_EXPOSURE: themeColors.dsPink[300],
	RESOURCE_EXPOSURE: themeColors.dsPink[200],
	POSSIBLE_PRIVILEGE_ESCALATION: themeColors.dsPink[800],
}

const AWSTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The user has the AdministratorAccess policy attached to their IAM user, group, or role.',
	DATA_ACCESS:
		'A managed policy or managed policy action tag that indicates the presence of an action that could return data within data stores.',
	CREDENTIAL_EXPOSURE:
		'A managed policy or managed policy action tag that indicates the presence of an action that could produce a response that contains credentials.',
	RESOURCE_EXPOSURE:
		'A managed policy or managed policy action tag that indicates the presence of an action that could expose resources to the public.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'A managed policy or managed policy action tag that indicates the presence of an action that could potentially lead to a privilege escalation.',
}

const AzureTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The identity has a role assigned to it that grants it admin access to the scope, making the identity highly privileged.',
	DATA_ACCESS:
		'A  built-in role tag that indicates the presence of an action that could return data within data stores.',
	CREDENTIAL_EXPOSURE:
		'A  built-in role tag that indicates the presence of an action that could produce a response that contains credentials.',
	RESOURCE_EXPOSURE:
		'A  built-in role tag that indicates the presence of an action that could expose resources to the public.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'A  built-in role tag that indicates the presence of an action that could potentially lead to a privilege escalation.',
}

const EntraIDTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The identity has been assigned a privileged role in Entra ID, granting it administrative access across the tenant or specific resources.',
	DATA_ACCESS:
		'A built-in role tag indicating the ability to access or manage data within Entra ID, such as user data, logs, or audit trails.',
	CREDENTIAL_EXPOSURE:
		'A built-in role tag indicating that actions under this which allows access to manage encryption keys, exposing a risk of credential leakage.',
	RESOURCE_EXPOSURE:
		'A built-in role tag indicating the ability to access or manage cloud resources connected to Entra ID.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'A built-in role tag indicating that the assigned role has permissions that could be exploited to gain higher privileges within the tenant.',
}

const SalesforceTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The user has permissions that grant extensive administrative access, including user management, role management, and system configuration capabilities.',
	DATA_ACCESS:
		'The user has permissions that allow broad access to view, edit, or export various types of data within the Salesforce organization.',
	CREDENTIAL_EXPOSURE:
		'The user has permissions that could potentially expose sensitive information or credentials, such as viewing encrypted data or accessing named credentials.',
	RESOURCE_EXPOSURE:
		'The user has permissions that allow them to access or modify resources that could potentially be exposed externally, such as public documents or reports.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'The user has permissions that could potentially be used to escalate privileges, such as assigning permission sets or managing custom permissions.',
}

const GCPTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The user has the Owner role or a similar high-privilege role assigned to their IAM user, group, or service account.',
	DATA_ACCESS:
		'A predefined role or predefined role action tag that indicates the presence of an action that could return data within Google Cloud data stores.',
	CREDENTIAL_EXPOSURE:
		'A predefined role or predefined role action tag that indicates the presence of an action that could produce a response that contains credentials.',
	RESOURCE_EXPOSURE:
		'A predefined role or permission that allows resources to be made public or accessible outside the organization, potentially exposing them to external access.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'A predefined role or predefined role action tag that indicates the presence of an action that could potentially lead to a privilege escalation.',
}

const AzureDevopsTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The identity has membership in highly privileged groups like Project Collection Administrators, Project Collection Build Administrators, or Project Administrators, granting extensive administrative control over projects and collections.',
	DATA_ACCESS:
		'The identity has permissions that allow access to sensitive data, including audit logs and Git repository content through permissions like AuditLog.Read and Git Repositories.GenericRead.',
	CREDENTIAL_EXPOSURE:
		'A permission that could allow access to or exposure of credentials within the Azure DevOps environment.',
	RESOURCE_EXPOSURE:
		'A permission that could allow resources within Azure DevOps to be exposed or made accessible beyond their intended scope.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'The identity has permissions like Identity.ManageMembership, Identity.Write, or Server.Impersonate that could be used to escalate privileges through managing identities or impersonating other users.',
}

const SnowflakeTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The user has been assigned administrative roles in Snowflake that grant extensive privileges across the organization, including user management, role management, and system configuration capabilities.',
	DATA_ACCESS:
		'The user has roles or privileges that grant access to view, modify, or manage data within Snowflake warehouses, databases, or schemas.',
	CREDENTIAL_EXPOSURE:
		'The user has permissions that could potentially expose sensitive credentials or security configurations, such as managing key pairs, network policies, or security integrations.',
	RESOURCE_EXPOSURE:
		'The user has privileges that allow them to configure external access to Snowflake resources, such as managing shares, external tables, or public access settings.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'The user has permissions related to role management or security administration that could potentially be used to escalate privileges, such as granting roles or managing account parameters.',
}

const ActiveDirectoryTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The identity has privileged access through group memberships or direct permissions like Domain Admin, Enterprise Admin, or similar built-in administrator groups that grant extensive control over the Active Directory domain.',
	DATA_ACCESS:
		'The identity has permissions to access sensitive data within Active Directory, including user attributes, group memberships, and organizational units through privileges like Read All Properties or Replicating Directory Changes.',
	CREDENTIAL_EXPOSURE:
		'The identity has permissions that could expose sensitive credentials or authentication materials, such as Reset Password rights, access to LAPS passwords, or the ability to read security descriptors.',
	RESOURCE_EXPOSURE:
		'The identity has permissions that could allow exposure of Active Directory resources beyond their intended scope, such as the ability to modify security policies, create trusts, or manage DNS settings.',
	POSSIBLE_PRIVILEGE_ESCALATION:
		'The identity has permissions that could be leveraged for privilege escalation, such as Write All Properties, Write Owner, or other delegated rights that could be used to gain additional access.',
}

const AtlassianTagsDescriptionMap: Record<Tag, string> = {
	ADMIN: 'The identity has administration permissions for a Jira project or for the whole organization.',
	DATA_ACCESS: 'The identity has permissions to access sensitive data within Jira.',
	CREDENTIAL_EXPOSURE:
		'The identity has permissions that could expose sensitive credentials or authentication materials.',
	RESOURCE_EXPOSURE:
		'The identity has permissions that could allow exposure of Jira resources beyond their intended scope.',
	POSSIBLE_PRIVILEGE_ESCALATION: 'The identity has permissions that could be leveraged for privilege escalation.',
}

const JumpCloudTagsDescriptionMap: Record<Partial<Tag>, string> = {
	ADMIN: 'The identity has been assigned a privileged role in JumpCloud, granting it administrative access across the tenant or specific resources.',
	DATA_ACCESS: 'The identity has permissions to access sensitive data within JumpCloud.',
	CREDENTIAL_EXPOSURE:
		'The identity has permissions that could expose sensitive credentials or authentication materials.',
	RESOURCE_EXPOSURE:
		'The identity has permissions that could allow exposure of JumpCloud resources beyond their intended scope.',
	POSSIBLE_PRIVILEGE_ESCALATION: 'The identity has permissions that could be leveraged for privilege escalation.',
}

export const getTagDescription = (tag: Tag, source: string | null | undefined) => {
	switch (source?.toUpperCase()) {
		case EnvironmentType.AZURE.toString():
			return AzureTagsDescriptionMap[tag]
		case EnvironmentType.SALESFORCE.toString():
			return SalesforceTagsDescriptionMap[tag]
		case EnvironmentType.AWS.toString():
			return AWSTagsDescriptionMap[tag]
		case EnvironmentType.ENTRA_ID.toString():
			return EntraIDTagsDescriptionMap[tag]
		case EnvironmentType.GCP.toString():
			return GCPTagsDescriptionMap[tag]
		case EnvironmentType.AZURE_DEVOPS.toString():
			return AzureDevopsTagsDescriptionMap[tag]
		case EnvironmentType.SNOWFLAKE.toString():
			return SnowflakeTagsDescriptionMap[tag]
		case EnvironmentType.ACTIVE_DIRECTORY.toString():
			return ActiveDirectoryTagsDescriptionMap[tag]
		case EnvironmentType.DEMO_ATLASSIAN.toString():
			return AtlassianTagsDescriptionMap[tag]
		case EnvironmentType.JUMPCLOUD.toString():
			return JumpCloudTagsDescriptionMap[tag]
		default:
			Sentry.captureMessage(`getTagDescription - Unknown source: ${source}`)
			return AWSTagsDescriptionMap[tag]
	}
}

export enum ImpactType {
	LOW = 1,
	MEDIUM = 2,
	HIGH = 3,
	CRITICAL = 4,
}

export enum ProbabilityType {
	RARE = 1,
	UNLIKELY = 2,
	POSSIBLE = 3,
	LIKELY = 4,
	ALMOST_CERTAIN = 5,
}

export const TagSchema = z.object({
	id: z.string(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	source: z.string().nullish(),
	related_resource_type: z.string().nullish(),
	name: z.string(),
	impact: z.nativeEnum(ImpactType).nullish(),
	probability: z.nativeEnum(ProbabilityType).nullish(),
})

export type ServerTagInput = z.input<typeof TagSchema>
export type ServerTag = z.infer<typeof TagSchema>
