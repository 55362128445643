import { message } from 'antd'
import { ConnectableEnvironmentConfig, EnvironmentIntegrationFormData } from './environmentIntegrationsTypes.ts'
import { useCreateEnvironment, useTestConnection } from '../../../api/environments'
import {
	EnvironmentTestConnectionRequest,
	EnvironmentTestConnectionResponsePayload,
} from '../../../schemas/environments/environments'

export const useEnvironmentIntegrationFunctions = () => {
	const { mutate: createEnvironment, isPending: isCreatingEnvironment } = useCreateEnvironment()
	const testConnection = useTestConnection()

	const handleConnectSubmit = (
		selectedIntegration: ConnectableEnvironmentConfig,
		formData: EnvironmentIntegrationFormData,
		handleCloseModal: () => void,
		testResultPayload: EnvironmentTestConnectionResponsePayload,
	) => {
		const environmentData = selectedIntegration.createEnvironmentPayload(formData, testResultPayload)
		if (!environmentData) {
			message.error(`Failed to create ${selectedIntegration.name} environment.`)
			return
		}

		createEnvironment(environmentData, {
			onSuccess: () => {
				// TODO: Lets add context to the App component? instead of passing context to the integration components
				message.success(`${selectedIntegration.name} environment created successfully`)
				handleCloseModal()
			},
			onError: () => {
				message.error(`Failed to create ${selectedIntegration.name} environment.`)
			},
		})
	}

	const handleTestConnection = async (request: EnvironmentTestConnectionRequest) => {
		return await testConnection.mutateAsync(request)
	}

	return {
		handleConnectSubmit,
		handleTestConnection,
		isCreatingEnvironment,
		testConnection,
	}
}
