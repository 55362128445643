import JiraIcon from '../../../../assets/jira_icon.svg?react'
import {
	CollaborationIntegrationCreateRequest,
	CollaborationIntegrationTestConnectionRequest,
} from '../../../../schemas/integrations/integrations.ts'
import { IntegrationType } from '../../../../jiraIntegration/schemas/common.ts'
import {
	CollaborationIntegrationFormData,
	ConnectableCollaborationIntegrationConfig,
} from '../collaborationIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'
import JiraGuide from '../../../../assets/integrationGuides/jira-integration-guide.pdf'

export const jiraConfig: ConnectableCollaborationIntegrationConfig = {
	key: IntegrationType.JIRA,
	name: 'Jira',
	description: 'Connect Token Security to your Jira workspace for issue tracking and project management.',
	logo: <IntegrationIcon leftIcon={JiraIcon} />,
	guideFileName: JiraGuide,
	modalWidth: 600,
	formFields: [
		{
			fieldType: 'simple',
			name: 'name',
			label: 'Integration Name',
			placeholder: 'Enter a name for the integration',
			required: true,
			type: 'text',
		},
		{
			fieldType: 'simple',
			name: 'base_url',
			label: 'Base URL',
			placeholder: 'https://your-jira-instance.atlassian.net',
			required: true,
			type: 'text',
		},
		{
			fieldType: 'simple',
			name: 'user_name',
			label: 'User Name',
			placeholder: 'your-jira-username',
			required: true,
			type: 'text',
		},
		{
			fieldType: 'simple',
			name: 'api_token',
			label: 'API Token',
			placeholder: 'your-jira-api-token',
			required: true,
			type: 'password',
		},
	],
	createTestConnectionPayload: (
		formData?: CollaborationIntegrationFormData,
	): CollaborationIntegrationTestConnectionRequest | null => {
		if (!formData) {
			return null
		}
		return {
			integration_type: IntegrationType.JIRA,
			config: {
				type: IntegrationType.JIRA,
				base_url: formData?.base_url as string,
				user_name: formData?.user_name as string,
				api_token: formData?.api_token as string,
			},
		}
	},
	createIntegrationPayload: (
		formData: CollaborationIntegrationFormData,
	): CollaborationIntegrationCreateRequest | null => {
		return {
			integration_type: IntegrationType.JIRA,
			name: formData?.name as string,
			config: {
				type: IntegrationType.JIRA,
				base_url: formData.base_url as string,
				user_name: formData.user_name as string,
				api_token: formData.api_token as string,
			},
		}
	},
}
