import { ServerGoogleWorkspaceUserDemoDomainWideDelegationInput } from '../../schemas/identities/googleWorkspaceUserSchema.ts'

export const gwWideDomainData = {
	'1': {
		gcp_service_account_id: '0001l2m3-1234-5678-j0k1-l2m312345678',
		gcp_service_account_name: 'monitoring-mail-assistant',
		scopes: [
			'https://www.googleapis.com/auth/gmail.send',
			'https://www.googleapis.com/auth/gmail.compose',
			'https://www.googleapis.com/auth/gmail.readonly',
		],
	},
	'2': {
		gcp_service_account_id: '0001l2m3-1234-5678-j0k1-l2m312345678',
		gcp_service_account_name: 'upload-reports-automation',
		scopes: ['https://www.googleapis.com/auth/drive', 'https://www.googleapis.com/auth/drive.file'],
	},
	'3': {
		gcp_service_account_id: 'd7e3f9a1-6d3c-4e8h-9f3d-1c2b3a4d5e8f',
		gcp_service_account_name: 'synergy-app-server-002',
		scopes: [
			'https://www.googleapis.com/auth/gmail.send',
			'https://www.googleapis.com/auth/gmail.compose',
			'https://www.googleapis.com/auth/gmail.readonly',
		],
	},
} satisfies Record<string, ServerGoogleWorkspaceUserDemoDomainWideDelegationInput>
