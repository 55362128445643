import React, { useMemo } from 'react'
import { Button, Spin, Tooltip } from 'antd'
import { JiraIntegration } from './JiraIntegration.tsx'
import { IntegrationType } from '../schemas/common.ts'
import JiraIcon from '../assets/jira_logo.svg?react'
import { useIssue } from '../../api/issues.ts'
import { useCollaborationIntegrations } from '../../api/integrations.ts'
import { useEntitlementToShowJiraButton } from '../../services/auth/featureFlags.ts'
import { ServerIssue } from '../../schemas/issue.ts'
import { JiraIntegrationResponse } from '../../schemas/integrations/integrations.ts'

type JiraIntegrationWrapperProps = {
	issueId?: string
	currentIssue?: ServerIssue
}

export const JiraIntegrationWrapper: React.FunctionComponent<{
	issueId?: string
	currentIssue?: ServerIssue
}> = ({ issueId, currentIssue }: JiraIntegrationWrapperProps) => {
	const {
		data: integrations,
		isPending: isIntegrationsPending,
		isError: isIntegrationsError,
	} = useCollaborationIntegrations()
	const {
		data: issue,
		isLoading: isIssueLoading,
		isError: isIssueError,
	} = useIssue(issueId ?? '', { enabled: Boolean(!currentIssue) })
	const { isEntitled: isEntitledToViewJiraButton } = useEntitlementToShowJiraButton()

	const displayIssue = useMemo(() => currentIssue || issue, [currentIssue, issue])

	if (!displayIssue || !issueId) {
		return null
	}

	if (isIntegrationsPending || isIssueLoading) {
		return <Spin />
	}

	if (isIntegrationsError || isIssueError) {
		return null
	}

	const jiraIntegration = integrations.find((integration) => integration.integration_type === IntegrationType.JIRA)

	if (!jiraIntegration) {
		if (isEntitledToViewJiraButton) {
			return (
				<Tooltip title="Contact support to open Jira tickets" placement="bottom">
					<Button
						classNames={{ icon: 'flex' }}
						disabled={true}
						icon={<JiraIcon className="w-[18px] h-[18px]" />}
					></Button>
				</Tooltip>
			)
		} else {
			return null
		}
	}

	return <JiraIntegration integration={jiraIntegration.integration as JiraIntegrationResponse} issue={displayIssue} />
}
