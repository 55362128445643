import { z } from 'zod'

export enum FeatureId {
	NEW_FEATURE_ANNOUNCEMENT_USAGE_GRAPH = 'new-feature-announcement-usage-graph',
}

// Zod schema for validating FeatureId
export const featureIdSchema = z.nativeEnum(FeatureId)

export type NewFeatureAnnouncement = {
	id: FeatureId
	title: string
	buttonTitle?: string
	description: string
	releaseDate: string // ISO date string
}

export type NewFeatureAnnouncementState = {
	lastDismissedId: FeatureId | null
}
