import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { ServerGcpServiceAccountDemoDomainWideDelegationScope } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

type GcpScopeNodeData = {
	scope: ServerGcpServiceAccountDemoDomainWideDelegationScope
}

export type GcpScopeNodeType = Node<GcpScopeNodeData, 'gcpScope'>
type GcpScopeNodeProps = NodeProps<GcpScopeNodeType>

export const GcpScopeNode = ({ data: { scope } }: GcpScopeNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'gcpScope' && highlightedNode.id === scope.literal_name,
	)

	return (
		<BasePermissionNode
			label="OAuth Scope"
			name={{ text: scope.friendly_name || scope.literal_name, tooltipText: scope.literal_name }}
			risks={[scope.tag]}
			isHighlighted={isHighlighted}
		/>
	)
}
