import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { ConfigProvider, Layout, Menu, Skeleton } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import {
	IssuesPageLens,
	IssueStatGroup,
	IssueTablePaginatedRow,
	IssueType,
	PaginatedFindingsPageSearch,
} from '../../schemas/issue.ts'
import { useIssuesStats } from '../../api/issues.ts'
import { RoutePaths } from '../RoutePaths.tsx'
import { useSearch } from '@tanstack/react-router'
import OpenTicketIcon from '../../assets/open_ticket_icon.svg?react'
import RisksIcon from '../../assets/risks_icon.svg?react'
import { AgGridReact } from 'ag-grid-react'
import { FindingMenuItemLabel } from './FindingMenuItemLabel.tsx'
import { IssuesPageLensMap } from './IssuesPageLensMap.tsx'
import { Tag } from '../../schemas/tags.ts'
import { pageSidebarTheme } from '../../styles/pageSidebarTheme.ts'
import { CollapsibleSiderMenuHeader } from '../../components/common/CollapsibleSiderMenuHeader.tsx'
import { formatNumber } from '../../utils/numberUtils.ts'
import { DashboardOutlined } from '@ant-design/icons'

const MenuItemLabel = ({ children, count }: { children: React.ReactNode; count?: number }) => (
	<div className="flex justify-between gap-1">
		<span>{children}</span>
		{count && <span>({formatNumber(count)})</span>}
	</div>
)

const MenuItemIcon = ({ Icon, ...rest }: { Icon: React.ComponentType<{ className?: string }> }) => (
	<span {...rest}>
		<Icon className="w-5 h-5 !text-base" />
	</span>
)

export const FindingsSideBarPaginatedMenu: React.FC<{
	onFindingsSideBarMenuClick: (e: MenuInfo) => void
	gridRef: MutableRefObject<AgGridReact<IssueTablePaginatedRow> | null>
	issueType: IssueType
}> = ({ onFindingsSideBarMenuClick, gridRef, issueType }) => {
	const { data: issuesStatsData, isPending: isIssuesStatsPending } = useIssuesStats(issueType)
	const [isCollapsed, setIsCollapsed] = useState(false)

	const routePath = issueType === IssueType.ITDR ? RoutePaths.Itdr : RoutePaths.Findings
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { lens } = useSearch({ from: routePath }) as PaginatedFindingsPageSearch

	const onClick = (e: MenuInfo) => {
		onFindingsSideBarMenuClick(e)
	}

	useEffect(() => {
		const columnFitTimeout = setTimeout(() => {
			gridRef.current?.api?.sizeColumnsToFit()
		}, 200)

		return () => {
			clearTimeout(columnFitTimeout)
		}
	}, [isCollapsed])

	const toggleSiderCollapse = useCallback(() => {
		setIsCollapsed((currentIsCollapsed) => !currentIsCollapsed)
	}, [setIsCollapsed])

	const items = useMemo(() => {
		if (!issuesStatsData) {
			return []
		}
		const totalIssues = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.AllCount)
		const allIssuesFindingsFolder = issuesStatsData.filter(
			(issueStat) => issueStat.group === IssueStatGroup.Findings,
		)

		const myIssuesSideBar = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.MyIssues)
		const relatedTickets = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.RelatedTickets)

		const allItems = []

		allItems.push({
			key: IssuesPageLens.ALL,
			label: <MenuItemLabel count={totalIssues?.[0]?.count || 0}>{IssuesPageLensMap.All.title}</MenuItemLabel>,
			icon: <MenuItemIcon Icon={IssuesPageLensMap.All.icon} />,
		})
		if (myIssuesSideBar.length > 0) {
			allItems.push({
				key: IssuesPageLens.ASSIGNED_TO_ME,
				label: (
					<MenuItemLabel count={myIssuesSideBar[0]?.count || 0}>
						{IssuesPageLensMap['My Issues'].title}
					</MenuItemLabel>
				),
				icon: <MenuItemIcon Icon={IssuesPageLensMap['My Issues'].icon} />,
			})
		}
		if (relatedTickets.length > 0) {
			allItems.push({
				key: 'sub-menu-opened-tickets',
				label: <MenuItemLabel>Open Tickets</MenuItemLabel>,
				icon: <MenuItemIcon Icon={OpenTicketIcon}></MenuItemIcon>,
				children: relatedTickets.map((relatedTicket) => ({
					key: relatedTicket.issue_name,
					label: (
						<MenuItemLabel count={relatedTicket.count}>
							{IssuesPageLensMap[relatedTicket.issue_name as IssuesPageLens].title}
						</MenuItemLabel>
					),
					icon: (
						<MenuItemIcon
							Icon={IssuesPageLensMap[relatedTicket.issue_name as IssuesPageLens].icon}
						></MenuItemIcon>
					),
				})),
			})
		}
		allItems.push({
			key: 'sub-menu-risks',
			label: <MenuItemLabel>Risks</MenuItemLabel>,
			icon: <MenuItemIcon Icon={RisksIcon}></MenuItemIcon>,
			children: Object.values(Tag).map((risk) => ({
				key: risk,
				label: <MenuItemLabel>{IssuesPageLensMap[risk].title}</MenuItemLabel>,
				icon: <MenuItemIcon Icon={IssuesPageLensMap[risk].icon}></MenuItemIcon>,
			})),
		})
		allItems.push({
			key: 'sub-menu-account-priorities',
			label: <MenuItemLabel>Account Priority</MenuItemLabel>,
			icon: <MenuItemIcon Icon={DashboardOutlined}></MenuItemIcon>,
			children: [
				IssuesPageLens.CRITICAL_PRIORITY,
				IssuesPageLens.HIGH_PRIORITY,
				IssuesPageLens.MEDIUM_PRIORITY,
				IssuesPageLens.LOW_PRIORITY,
				IssuesPageLens.UNKNOWN_PRIORITY,
			].map((priority) => ({
				key: priority,
				label: <MenuItemLabel>{IssuesPageLensMap[priority].title}</MenuItemLabel>,
				icon: <MenuItemIcon Icon={IssuesPageLensMap[priority].icon}></MenuItemIcon>,
			})),
		})
		if (issueType !== IssueType.ITDR) {
			allItems.push({
				key: IssuesPageLens.ISSUES,
				label: <MenuItemLabel>{IssuesPageLensMap.ISSUES.title}</MenuItemLabel>,
				icon: <MenuItemIcon Icon={IssuesPageLensMap.ISSUES.icon}></MenuItemIcon>,
				children: allIssuesFindingsFolder
					.filter((item) => item.count > 0)
					.sort((a, b) => a.issue_name.localeCompare(b.issue_name))
					.map((item) => ({
						key: item.issue_name,
						label: <FindingMenuItemLabel item={item} />,
					})),
			})
		}
		return allItems
	}, [issuesStatsData])

	const defaultOpenKeys = useMemo(() => {
		if (!lens) {
			return []
		}

		const openSubMenu = items.find((item) => {
			if (!item || !('children' in item)) {
				return false
			}

			return item.children!.find((subItem) => subItem?.key === lens)
		})

		if (!openSubMenu) {
			return []
		}

		return [openSubMenu.key]
	}, [lens, items])

	return (
		<ConfigProvider theme={pageSidebarTheme}>
			<Layout.Sider collapsed={isCollapsed} collapsedWidth={64} className="overflow-y-auto" width="20%">
				<CollapsibleSiderMenuHeader
					isCollapsed={isCollapsed}
					title={issueType === IssueType.ITDR ? 'ITDR view' : 'Findings view'}
					toggleCollapse={toggleSiderCollapse}
				/>
				{isIssuesStatsPending ? (
					<div className="px-4 py-2">
						<Skeleton active paragraph={{ rows: 6 }} />
					</div>
				) : (
					<Menu
						onClick={onClick}
						mode="inline"
						theme="dark"
						items={items}
						selectedKeys={lens ? [lens] : [IssuesPageLens.ALL]}
						defaultOpenKeys={defaultOpenKeys}
					/>
				)}
			</Layout.Sider>
		</ConfigProvider>
	)
}
