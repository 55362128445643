import { RowClassParams } from 'ag-grid-enterprise'
import { PriorityType, IssueTablePaginatedRow, ServerIssue } from '../schemas/issue.ts'
import { ServerIdentitiesTableRow } from '../schemas/identity.ts'
import { AccountPriorityEnum } from '../schemas/environments/accountPriorityEnum.ts'
import { getIssuesMaxPriority } from './issueUtils.ts'
import { themeColors } from './colorUtils.ts'

export const getPriorityColor = (priority?: PriorityType | null): string => {
	switch (priority) {
		case PriorityType.NOT_CALCULATED:
			return themeColors.status.neutral
		case PriorityType.LOW:
			return themeColors.status.info
		case PriorityType.MEDIUM:
			return themeColors.status.medium
		case PriorityType.HIGH:
			return themeColors.status.high
		case PriorityType.CRITICAL:
			return themeColors.status.critical
		default:
			return '#FFFFFF'
	}
}

export const getPriorityColorWithOpacity = (priority?: PriorityType | null, opacityModifier = '9F'): string => {
	return `${getPriorityColor(priority)}${opacityModifier}`
}

export const getPriorityColorLight = (priority?: PriorityType | null): string => {
	switch (priority) {
		case PriorityType.NOT_CALCULATED:
			return themeColors.status.neutralLowContrast
		case PriorityType.LOW:
			return themeColors.status.infoLowContrast
		case PriorityType.MEDIUM:
			return themeColors.status.mediumLowContrast
		case PriorityType.HIGH:
			return themeColors.status.highLowContrast
		case PriorityType.CRITICAL:
			return themeColors.status.criticalLowContrast
		default:
			return '#FFFFFF'
	}
}

export const getAccountPriorityColor = (priority?: AccountPriorityEnum | null): string => {
	switch (priority) {
		case AccountPriorityEnum.NONE:
			return themeColors.status.neutral
		case AccountPriorityEnum.UNKNOWN:
			return themeColors.status.neutral
		case AccountPriorityEnum.LOW:
			return themeColors.status.info
		case AccountPriorityEnum.MEDIUM:
			return themeColors.status.medium
		case AccountPriorityEnum.HIGH:
			return themeColors.status.high
		case AccountPriorityEnum.CRITICAL:
			return themeColors.status.critical
		default:
			return '#FFFFFF'
	}
}

export const getAccountPriorityColorLight = (priority?: AccountPriorityEnum | null): string => {
	switch (priority) {
		case AccountPriorityEnum.NONE:
			return themeColors.status.neutralLowContrast
		case AccountPriorityEnum.UNKNOWN:
			return themeColors.status.neutralLowContrast
		case AccountPriorityEnum.LOW:
			return themeColors.status.infoLowContrast
		case AccountPriorityEnum.MEDIUM:
			return themeColors.status.mediumLowContrast
		case AccountPriorityEnum.HIGH:
			return themeColors.status.highLowContrast
		case AccountPriorityEnum.CRITICAL:
			return themeColors.status.criticalLowContrast
		default:
			return '#FFFFFF'
	}
}

export const getIssueStyle = (priority?: PriorityType | null) => {
	const color = getPriorityColor(priority)
	return { boxShadow: `4px 0px 0px 0px ${color} inset`, borderRadius: '4px 0px 0px 4px' }
}

export const getIssuesTableRowStyle = (
	params: RowClassParams<ServerIssue> | RowClassParams<IssueTablePaginatedRow>,
) => {
	return getIssueStyle(params.data?.priority)
}

export const getIdentitiesTableRowStyle = (params: RowClassParams<ServerIdentitiesTableRow>) => {
	if (!params.data) {
		return
	}

	const maxPriority = params.data.max_priority ?? getIssuesMaxPriority(params.data.issues)
	return getIssueStyle(maxPriority)
}
