import { useCallback, useRef, useState } from 'react'
import { ColDef } from 'ag-grid-enterprise'
import { ServerGithubUserLog } from '../../../../../schemas/identities/githubUserSchema.ts'
import { JsonViewer } from '../../../../../components/common/JsonViewer.tsx'
import { AgGridReact } from 'ag-grid-react'
import { Button, Modal } from 'antd'
import ExportIcon from '../../../../../assets/export_icon_20.svg?react'
import { formatDate, getCsvFileName } from '../../../../../utils.ts'
import { ServerIdentity } from '../../../../../schemas/identity.ts'

type GithubUsageLogsNewContentProps = {
	auditLogs: ServerGithubUserLog[]
	identity: ServerIdentity
	onExportCsv: () => void
}

const defaultColDef: ColDef = {
	sortable: true,
	resizable: true,
	filter: true,
	cellStyle: () => ({
		display: 'flex',
		alignItems: 'center',
	}),
	flex: 1,
}

export const GithubUsageLogsContent = ({ auditLogs, identity, onExportCsv }: GithubUsageLogsNewContentProps) => {
	const gridRef = useRef<AgGridReact<ServerGithubUserLog>>(null)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const [auditLogColumnDefs] = useState<ColDef<ServerGithubUserLog>[]>([
		{
			field: 'token_id',
			headerName: 'Token ID',
			filter: 'agNumberColumnFilter',
		},
		{
			field: 'action',
			headerName: 'Action',
			cellRenderer: (params: { value: string | null | undefined }) => params.value || 'N/A',
		},
		{
			field: 'actor',
			headerName: 'Actor',
			cellRenderer: (params: { value: string | null | undefined }) => params.value || 'N/A',
		},
		{
			field: 'created_at',
			headerName: 'Timestamp',
			cellRenderer: (params: { value: string }) => formatDate(params.value),
			sort: 'desc',
		},
		{
			field: 'raw_log',
			headerName: 'Raw Log',
			cellRenderer: (params: { value: object }) => {
				return params.value ? <JsonViewer data={params.value} title="Raw Log" /> : 'Empty'
			},
			flex: 1,
		},
	])

	const exportToCsv = useCallback(() => {
		onExportCsv()
		gridRef?.current!.api.exportDataAsCsv({
			fileName: getCsvFileName(`audit-logs-${identity.github_user?.login}`, false),
		})
	}, [identity, onExportCsv])

	if (auditLogs.length === 0) {
		return <div className="text-center">No Audit Logs available</div>
	}

	return (
		<>
			<div className="h-64 overflow-auto">
				<AgGridReact
					className={'ag-theme-alpine h-full w-full overflow-x-auto'}
					rowHeight={54}
					rowData={auditLogs}
					columnDefs={auditLogColumnDefs}
					defaultColDef={defaultColDef}
					enableCellTextSelection
					suppressHorizontalScroll={false}
				/>
			</div>
			<div className="flex justify-between">
				<Button type="link" size="small" className="mt-4" onClick={() => setIsModalOpen(true)}>
					See all events
				</Button>
			</div>
			<Modal
				centered={true}
				width={1024}
				title={'Audit Logs'}
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex gap-2">
							<Button icon={<ExportIcon />} onClick={exportToCsv}>
								Export CSV
							</Button>
							<Button type="primary" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className={'ag-theme-alpine w-full'}
						rowData={auditLogs}
						columnDefs={auditLogColumnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
						enableBrowserTooltips={true}
					/>
				</div>
			</Modal>
		</>
	)
}
