import { z } from 'zod'
import { DateSchema } from '../common.ts'
import { EnvironmentType } from '../envType.ts'

export const ServerIdentitiesByEnvSchema = z.object({
	date: DateSchema,
	okta: z.number().nullish(),
	google_workspace: z.number().nullish(),
	aws: z.number().nullish(),
	jumpcloud: z.number().nullish(),
	entra_id: z.number().nullish(),
	gcp: z.number().nullish(),
	snowflake: z.number().nullish(),
	github: z.number().nullish(),
	postgres: z.number().nullish(),
	kubernetes: z.number().nullish(),
	azure: z.number().nullish(),
	databricks: z.number().nullish(),
	salesforce: z.number().nullish(),
	azure_devops: z.number().nullish(),
	active_directory: z.number().nullish(),
	atlassian: z.number().nullish(),
	onelogin: z.number().nullish(),
})

export type ServerIdentitiesByEnvInput = z.input<typeof ServerIdentitiesByEnvSchema>
export type ServerIdentitiesByEnv = z.infer<typeof ServerIdentitiesByEnvSchema>

export enum IdentityEnvSource {
	AWS = 'aws',
	OKTA = 'okta',
	GOOGLE_WORKSPACE = 'google_workspace',
	JUMPCLOUD = 'jumpcloud',
	ENTRA_ID = 'entra_id',
	GCP = 'gcp',
	GITHUB = 'github',
	RDS_POSTGRES = 'postgres',
	KUBERNETES = 'kubernetes',
	SNOWFLAKE = 'snowflake',
	AZURE = 'azure',
	DATABRICKS = 'databricks',
	SALESFORCE = 'salesforce',
	AZURE_DEVOPS = 'azure_devops',
	ACTIVE_DIRECTORY = 'active_directory',
	DEMO_ATLASSIAN = 'atlassian',
	ONELOGIN = 'onelogin',
}

export const IdentityEnvSourceName: Record<IdentityEnvSource, string> = {
	[IdentityEnvSource.JUMPCLOUD]: 'Jumpcloud',
	[IdentityEnvSource.OKTA]: 'Okta',
	[IdentityEnvSource.GOOGLE_WORKSPACE]: 'Google Workspace',
	[IdentityEnvSource.AWS]: 'AWS',
	[IdentityEnvSource.ENTRA_ID]: 'Entra ID',
	[IdentityEnvSource.GCP]: 'GCP',
	[IdentityEnvSource.SNOWFLAKE]: 'Snowflake',
	[IdentityEnvSource.GITHUB]: 'GitHub',
	[IdentityEnvSource.RDS_POSTGRES]: 'Postgres',
	[IdentityEnvSource.KUBERNETES]: 'Kubernetes',
	[IdentityEnvSource.DATABRICKS]: 'Databricks',
	[IdentityEnvSource.AZURE]: 'Azure',
	[IdentityEnvSource.SALESFORCE]: 'Salesforce',
	[IdentityEnvSource.AZURE_DEVOPS]: 'Azure DevOps',
	[IdentityEnvSource.ACTIVE_DIRECTORY]: 'Active Directory',
	[IdentityEnvSource.DEMO_ATLASSIAN]: 'Atlassian',
	[IdentityEnvSource.ONELOGIN]: 'OneLogin',
}

export const EnvironmentsColors: Record<IdentityEnvSource, string> = {
	[IdentityEnvSource.JUMPCLOUD]: '#6AC9FC', // JumpCloud light blue
	[IdentityEnvSource.OKTA]: '#007DC1', // Okta primary blue
	[IdentityEnvSource.GOOGLE_WORKSPACE]: '#EA4335', // Google Workspace red
	[IdentityEnvSource.AWS]: '#FF9900', // AWS orange
	[IdentityEnvSource.ENTRA_ID]: '#0067B8', // Microsoft Entra ID blue
	[IdentityEnvSource.GCP]: '#34A853', // GCP green
	[IdentityEnvSource.SNOWFLAKE]: '#56B9F2', // Snowflake light blue
	[IdentityEnvSource.GITHUB]: '#181717', // GitHub dark grey/black
	[IdentityEnvSource.RDS_POSTGRES]: '#527FFF', // RDS/PostgreSQL blue
	[IdentityEnvSource.KUBERNETES]: '#326CE5', // Kubernetes blue
	[IdentityEnvSource.DATABRICKS]: '#FF3621', // Databricks red/orange
	[IdentityEnvSource.AZURE]: '#0078D4', // Azure blue
	[IdentityEnvSource.SALESFORCE]: '#00A1E0', // Salesforce blue
	[IdentityEnvSource.AZURE_DEVOPS]: '#0078D7', // Azure DevOps blue
	[IdentityEnvSource.ACTIVE_DIRECTORY]: '#0078D7',
	[IdentityEnvSource.DEMO_ATLASSIAN]: '#1868DB',
	[IdentityEnvSource.ONELOGIN]: '#03A9DA', // OneLogin light blue
}

export const EnvironmentTypeColors: Record<EnvironmentType, string> = {
	[EnvironmentType.OKTA]: EnvironmentsColors[IdentityEnvSource.OKTA],
	[EnvironmentType.GOOGLE_WORKSPACE]: EnvironmentsColors[IdentityEnvSource.GOOGLE_WORKSPACE],
	[EnvironmentType.AWS]: EnvironmentsColors[IdentityEnvSource.AWS],
	[EnvironmentType.AZURE]: EnvironmentsColors[IdentityEnvSource.AZURE],
	[EnvironmentType.GCP]: EnvironmentsColors[IdentityEnvSource.GCP],
	[EnvironmentType.JUMPCLOUD]: EnvironmentsColors[IdentityEnvSource.JUMPCLOUD],
	[EnvironmentType.ENTRA_ID]: EnvironmentsColors[IdentityEnvSource.ENTRA_ID],
	[EnvironmentType.SNOWFLAKE]: EnvironmentsColors[IdentityEnvSource.SNOWFLAKE],
	[EnvironmentType.GITHUB]: EnvironmentsColors[IdentityEnvSource.GITHUB],
	[EnvironmentType.RDS_POSTGRES]: EnvironmentsColors[IdentityEnvSource.RDS_POSTGRES],
	[EnvironmentType.KUBERNETES]: EnvironmentsColors[IdentityEnvSource.KUBERNETES],
	[EnvironmentType.DATABRICKS]: EnvironmentsColors[IdentityEnvSource.DATABRICKS],
	[EnvironmentType.SALESFORCE]: EnvironmentsColors[IdentityEnvSource.SALESFORCE],
	[EnvironmentType.AZURE_DEVOPS]: EnvironmentsColors[IdentityEnvSource.AZURE_DEVOPS],
	[EnvironmentType.ACTIVE_DIRECTORY]: EnvironmentsColors[IdentityEnvSource.ACTIVE_DIRECTORY],
	[EnvironmentType.DEMO_ATLASSIAN]: EnvironmentsColors[IdentityEnvSource.DEMO_ATLASSIAN],
	[EnvironmentType.ONELOGIN]: EnvironmentsColors[IdentityEnvSource.ONELOGIN],
}
