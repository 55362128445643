import { Node, NodeProps } from '@xyflow/react'
import { ServerGcpServiceAccountDemoDomainWideDelegation } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema'
import GoogleWorkspaceIcon from '../../../../assets/google_workspace_logo_16.svg?react'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'

export type GcpWorkspaceEnvNodeType = Node<
	{ workspace: ServerGcpServiceAccountDemoDomainWideDelegation },
	'gcpWorkspaceEnv'
>
type GcpWorkspaceEnvNodeProps = NodeProps<GcpWorkspaceEnvNodeType>

export const GcpWorkspaceEnvNode = ({ data: { workspace } }: GcpWorkspaceEnvNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'gcpWorkspaceEnv' && highlightedNode.id === workspace.id,
	)

	return (
		<BaseEnvironmentNode
			label="Google Workspace"
			name={workspace.google_workspace_env_name ? { text: workspace.google_workspace_env_name } : null}
			icon={<GoogleWorkspaceIcon />}
			tooltipTitle={workspace.google_workspace_env_name}
			priority={workspace.priority ?? undefined}
			isHighlighted={isHighlighted}
		/>
	)
}
