import React from 'react'
import cx from 'classnames'
import { Button, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { IntegrationStatus } from '../integrationTypes.ts'
import { IntegrationCardLogsContent } from './IntegrationCardLogsContent.tsx'
import { IntegrationCardButton } from './IntegrationCardButton.tsx'

interface IntegrationCardProps {
	logo: React.ReactNode
	name: string
	description: string
	status: IntegrationStatus
	environmentsConfig?: {
		names: string[]
		logsConnected?: boolean | number
	}
	onConnect?: () => void
	onCardClick?: () => void
}

const IntegrationCardEnvironments = ({ environmentNames }: { environmentNames: string[] }) => (
	<div className="flex flex-col gap-1 truncate">
		<span className="text-textIcon-secondary">
			{environmentNames.length === 1 ? 'Environment' : 'Environments'}
		</span>
		<Tooltip
			placement="topLeft"
			title={
				<div className="flex flex-col max-h-48 overflow-y-auto">
					{environmentNames.map((envName, i) => (
						<span key={i}>{envName}</span>
					))}
				</div>
			}
		>
			<span className="font-medium text-textIcon-primary truncate">
				{environmentNames.length === 1 ? environmentNames[0] : environmentNames.length}
			</span>
		</Tooltip>
	</div>
)

const IntegrationCardLogs = ({
	logsConnected,
	environmentCount,
}: {
	logsConnected: boolean | number
	environmentCount: number
}) => {
	return (
		<div className="flex flex-col gap-1">
			<span className="text-textIcon-secondary">Logs</span>
			<IntegrationCardLogsContent logsConnected={logsConnected} environmentCount={environmentCount} />
		</div>
	)
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
	logo,
	name,
	description,
	status,
	environmentsConfig,
	onConnect,
	onCardClick,
}) => {
	return (
		<div
			className={cx(
				'w-[270px] p-4 bg-surface-primary rounded-lg border border-border-secondary flex flex-col gap-4',
				onCardClick &&
					'hover:border-textIcon-linkHover hover:shadow-lg transition-all duration-300 cursor-pointer',
			)}
			onClick={onCardClick}
		>
			<div className="flex justify-between items-center">
				<div className="flex items-center gap-2">
					{logo}
					<span className="text-base font-medium text-textIcon-primary">{name}</span>
				</div>
				{/* We currently want to show the tooltip for editing for integrations w/o single integration page */}
				{onCardClick === undefined && (
					<Tooltip title="If you wish to make changes, please contact the Token team.">
						<Button icon={<EditOutlined />} type="text" disabled />
					</Tooltip>
				)}
			</div>
			<div className="grow">
				{environmentsConfig?.names?.length ? (
					<div className="flex gap-4">
						<IntegrationCardEnvironments environmentNames={environmentsConfig.names} />
						{environmentsConfig.logsConnected !== undefined && (
							<IntegrationCardLogs
								logsConnected={environmentsConfig.logsConnected}
								environmentCount={environmentsConfig.names.length}
							/>
						)}
					</div>
				) : (
					<p className="text-xs text-textIcon-secondary">{description}</p>
				)}
			</div>
			<IntegrationCardButton onConnect={onConnect} status={status} name={name} />
		</div>
	)
}
