import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export const ServerAdOwnershipLogsSchema = z.object({
	id: z.string(),
	customer_id: z.string(),
	environment_id: z.string(),
	system_created_at: z.string(),
	system_updated_at: z.string(),
	time_created: DateSchema,
	event_id: z.string(),
	actor_name: z.string(),
	actor_sid: z.string(),
	event_count: z.number(),
})

export type ServerAdOwnershipLogsInput = z.input<typeof ServerAdOwnershipLogsSchema>
export type ServerAdOwnershipLogs = z.infer<typeof ServerAdOwnershipLogsSchema>

export interface AdOwnershipLogsAggregatedSchema {
	actor_name: string
	event_ids: number[]
	first_time: Date
	last_time: Date
	count: number
	countsByEventId: Record<string, number>
}

export interface EventSpecificOwnershipLog {
	actor_name: string
	event_id: number
	event_description?: string
	first_time: Date
	last_time: Date
	count: number
}
