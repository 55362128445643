import { NodeProps, Node } from '@xyflow/react'
import { ServerAwsIamUserAccessKey } from '../../../../schemas/identities/awsIamUserSchema.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'
import { BaseServerIssue } from '../../../../schemas/issue.ts'

export type AccessKeyNodeType = Node<
	{
		accessKey: ServerAwsIamUserAccessKey
		issueAttached?: BaseServerIssue
	},
	'accessKey'
>

type AccessKeyNodeProps = NodeProps<AccessKeyNodeType>

export const AccessKeyNode = ({ data: { accessKey, issueAttached } }: AccessKeyNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'accessKey' && highlightedNode.id === accessKey?.access_key_id,
	)

	return (
		<BaseCredentialsNode
			isHighlighted={isHighlighted}
			label="Access Key"
			name={{ text: accessKey?.access_key_id || 'Unknown access key' }}
			issueAttached={issueAttached}
			isActive={Boolean(accessKey?.is_active)}
		/>
	)
}
