import { Devtools } from './components/Devtools.tsx'
import { QueryClientProvider } from '@tanstack/react-query'
import { Outlet } from '@tanstack/react-router'
import { AuthProvider } from './services/auth/AuthProvider.tsx'
import { settings } from './env-settings.ts'
import { MainLayout } from './components/layout/MainLayout.tsx'
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'
import './styles/App.css'
import { ConfigProvider } from 'antd'
import { isDev } from './utils/devUtils.ts'
import { queryClient } from './api/queryClient.ts'
import { antdTheme } from './styles/antdTheme.ts'
import { ErrorBoundaryContent } from './components/ErrorBoundaryContent.tsx'
import * as Sentry from '@sentry/react'
import { NewVersionModal } from './components/NewVersionModal.tsx'

export function App() {
	return (
		<div className="app">
			<AuthProvider clientId={settings.viteAuthClientId} baseUrl={settings.viteAuthServerBaseUrl}>
				<Sentry.ErrorBoundary fallback={<ErrorBoundaryContent />}>
					<QueryClientProvider client={queryClient}>
						<ConfigProvider theme={antdTheme}>
							<MainLayout>
								<NewVersionModal />
								<main className="main-layout overflow-y-auto pt-2 pb-2 pr-2 h-full rounded-md">
									<Outlet />
								</main>
							</MainLayout>

							{isDev ? <Devtools /> : null}
						</ConfigProvider>
					</QueryClientProvider>
				</Sentry.ErrorBoundary>
			</AuthProvider>
		</div>
	)
}
