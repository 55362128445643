import { z } from 'zod'
import { DateSchema } from '../../common'

export const ServerGCPUserUsageLogSchema = z.object({
	principal_email: z.string(),
	actions: z.array(z.string()),
	resource_type: z.string(),
	ip: z.string().nullish(),
	is_ip: z.boolean(),
	user_agent: z.string().nullish(),
	access_key: z.string().nullish(),
	date: DateSchema,
	event_count: z.number(),
	association: z.string().nullish(),
	association_type: z
		.string()
		.nullish()
		.transform((value) => (value === null ? 'UNKNOWN' : value)),
})

export type ServerGCPUserUsageLogInput = z.input<typeof ServerGCPUserUsageLogSchema>
export type ServerGCPUserUsageLog = z.infer<typeof ServerGCPUserUsageLogSchema>
