import { Node, NodeProps } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { ServerAdGroup } from '../../../../schemas/identities/activeDirectory/adGroupSchema.ts'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type AdGroupNodeType = Node<{ group: ServerAdGroup }, 'adGroup'>
type AdGroupNodeProps = NodeProps<AdGroupNodeType>

export const AdGroupNode = ({ data: { group } }: AdGroupNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'adGroup' && highlightedNode.id === group.object_sid,
	)

	return (
		<BasePermissionNode
			label="AD Group"
			name={{
				text: group.sam_account_name,
				tooltipText: group.description || '',
			}}
			risks={group.tags}
			isHighlighted={isHighlighted}
		/>
	)
}
