import { ServerIdentity } from '../../../schemas/identity.ts'
import { DemoDatabricksWorkspaceTable } from './tables/demoDatabricks/DataBricksWrokspaceTable.tsx'

export const SidePanelWorkspaces = ({ identity }: { identity: ServerIdentity }) => {
	const databricksWorkspaces =
		identity?.databricks_user?.workspaces || identity?.databricks_service_principal?.workspaces || []
	if (databricksWorkspaces?.length) {
		return <DemoDatabricksWorkspaceTable data={databricksWorkspaces} />
	}

	return (
		<div className="flex gap-1">
			<span>No workspaces found for identity</span>
			<span className="font-semibold">{identity.literal_friendly_name || identity.literal}</span>
		</div>
	)
}
