import { z } from 'zod'

export const UsageAssociationItemSchema = z.object({
	name: z.string(),
	instances_count: z.number(),
	total_events_count: z.number(),
})

export type UsageAssociationItem = z.infer<typeof UsageAssociationItemSchema>

export enum AssociationTypeEnum {
	EC2 = 'EC2',
	NAT_GW = 'nat_gateway',
	HUMAN = 'HUMAN',
	OKTA = 'OKTA',
	GITHUB = 'GITHUB',
	AWS = 'AWS',
	ZSCALER = 'ZSCALER',
	ATLASSIAN = 'ATLASSIAN',
	AZURE = 'AZURE',
	GCP = 'GCP',
	GOOGLE = 'GOOGLE',
	NATIONWIDE_ASN = 'NATIONWIDE ASN',
	NAF_ASN = 'NAF_ASN',
	NETSKOPE = 'NETSKOPE',
	INTERNAL = 'INTERNAL',
	GLOBAL = 'GLOBAL',
	UNKNOWN = 'UNKNOWN',
}
