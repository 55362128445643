import TeamsIcon from '../../../../assets/teams_icon.svg?react'
import {
	CollaborationIntegrationCreateRequest,
	CollaborationIntegrationTestConnectionRequest,
} from '../../../../schemas/integrations/integrations.ts'
import { IntegrationType } from '../../../../jiraIntegration/schemas/common.ts'
import {
	CollaborationIntegrationFormData,
	ConnectableCollaborationIntegrationConfig,
} from '../collaborationIntegrationsTypes.ts'
import { IntegrationIcon } from '../../common/components/IntegrationIcon.tsx'

export const teamsConfig: ConnectableCollaborationIntegrationConfig = {
	key: IntegrationType.TEAMS,
	name: 'Microsoft Teams',
	description: 'Connect Token Security to your Microsoft Teams workspace for real-time notifications and alerts.',
	logo: <IntegrationIcon leftIcon={TeamsIcon} />,
	formFields: [
		{
			label: 'Webhook URL',
			name: 'webhook_url',
			type: 'text',
			required: true,
			fieldType: 'simple',
			placeholder: 'https://<your-team-name>.webhook.office.com/webhookb2/...',
		},
	],
	createIntegrationPayload: (formData: CollaborationIntegrationFormData): CollaborationIntegrationCreateRequest => ({
		integration_type: IntegrationType.TEAMS,
		name: 'Microsoft Teams',
		config: {
			type: IntegrationType.TEAMS,
			webhook_url: formData.webhook_url as string,
		},
	}),
	createTestConnectionPayload: (
		formData?: CollaborationIntegrationFormData,
	): CollaborationIntegrationTestConnectionRequest | null => {
		if (!formData) {
			return null
		}
		return {
			integration_type: IntegrationType.TEAMS,
			config: {
				type: IntegrationType.TEAMS,
				webhook_url: formData.webhook_url as string,
			},
		}
	},
}
