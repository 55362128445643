import cx from 'classnames'
import { ReactNode } from 'react'
import { Typography } from 'antd'

type IntegrationsSectionProps = {
	title?: string
	children: ReactNode
}

export const IntegrationsSection = ({ title, children }: IntegrationsSectionProps) => {
	const containerClassname = cx('flex bg-surface-primary border rounded-lg p-6', {
		'flex-wrap border-border-primary gap-4 p-6': !title,
		'flex-col border-border-secondary gap-2 py-6 pb-6 pt-3': !!title,
	})

	if (!title) {
		return <div className={containerClassname}>{children}</div>
	}

	return (
		<div className={containerClassname}>
			<Typography.Text className="text-base text-textIcon-secondary">{title}</Typography.Text>
			<div className="flex flex-wrap gap-4">{children}</div>
		</div>
	)
}
