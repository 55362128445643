import { Dispatch, memo, SetStateAction, useCallback, useEffect, useState, MouseEvent } from 'react'
import {
	Background,
	Edge,
	FitViewOptions,
	MiniMap,
	NodeMouseHandler,
	ReactFlow,
	useEdgesState,
	useNodesState,
	useReactFlow,
} from '@xyflow/react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { isEmpty } from 'lodash'
import { ServerIdentity, IdentitySource } from '../../../schemas/identity.ts'
import { IdentityNode } from './common/IdentityNode.tsx'
import { IssueNode } from './common/IssueNode.tsx'
import { AccessKeyNode } from './aws/AccessKeyNode.tsx'
import { AwsPolicyNode } from './aws/AwsPolicyNode.tsx'
import { getNodesAndEdges } from './graphUtils/nodesAndEdges.ts'
import { handleUsageGraphNodeMouseClick, handleUsageGraphNodeMouseLeave } from './graphUtils/usageGraph.ts'
import { Ec2InstancesNode } from './aws/Ec2InstancesNode.tsx'
import { KubernetesResourcesNode } from './common/KubernetesResourcesNode.tsx'
import { getPolicyUniqueKey } from '../../../utils/awsIdentityUtils.ts'
import { DependenciesCollapseItemKeys } from '../IdentitySidePanel/SidePanelDependencies.tsx'
import { CrossContextCollapseItemKeys } from '../IdentitySidePanel/SidePanelCrossContext.tsx'
import { GcpProjectNode } from './gcp/GcpProjectNode.tsx'
import { GcpRoleNode } from './gcp/GcpRoleNode.tsx'
import { GcpScopeNode } from './gcp/GcpScopeNode.tsx'
import { GcpWorkspaceEnvNode } from './gcp/GcpWorkspaceEnvNode.tsx'
import { GithubRepositoryNode } from './github/GithubRepositoryNode.tsx'
import { GithubUserTokenNode } from './github/GithubUserTokenNode.tsx'
import { GcpSaAccessKeyNode } from './gcp/GcpSaAccessKeyNode.tsx'
import { EntraIdSpAuthCredentialNode } from './entraId/EntraIdSpAuthCredentialNode.tsx'
import { DatabricksOAuthNode } from './databricks/DatabricksOAuthNode.tsx'
import { DatabricksPATNode } from './databricks/DatabricksPATNode.tsx'
import { DatabricksRoleNode } from './databricks/DatabricksRoleNode.tsx'
import { DemoAzureVirtualMachinesNode } from './azure/demo/DemoAzureVirtualMachinesNode.tsx'
import { getMinimapNodeColor } from './graphUtils/nodeColors.ts'
import { MiniMapNode } from './MiniMapNode.tsx'
import { GithubRepositoriesNode } from './github/GithubRepositoriesNode.tsx'
import { GcpProjectsNode } from './gcp/GcpProjectsNode.tsx'
import { EnrichedAzureRoleNode } from './azure/azureRBAC/EnrichedAzureRoleNode.tsx'
import { EnrichedAzureRolesNode } from './azure/azureRBAC/EnrichedAzureRolesNode.tsx'
import { AzureSubscriptionNode } from './azure/azureResource/AzureSubscriptionNode.tsx'
import { AzureSubscriptionsNode } from './azure/azureResource/AzureSubscriptionsNode.tsx'
import { AzureManagementGroupNode } from './azure/azureResource/AzureManagementGroupNode.tsx'
import { AzureManagementGroupsNode } from './azure/azureResource/AzureManagementGroupsNode.tsx'
import { AzureKeyVaultNode } from './azure/azureKV/AzureKeyVaultNode.tsx'
import { EntraIDUserNode } from './entraId/EntraIDUserNode.tsx'
import { EntraIDServicePrincipalNode } from './entraId/EntraIDServicePrincipalNode.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { EntraIDUsersNode } from './entraId/EntraIDUsersNode.tsx'
import { EntraIDServicePrincipalsNode } from './entraId/EntraIDServicePrincipalsNode.tsx'
import { RoutePaths } from '../../RoutePaths.tsx'
import { DetailedEntraIdRoleNode } from './entraId/entraIdRbac/DetailedEntraIdRoleNode.tsx'
import { DetailedEntraIdRolesNode } from './entraId/entraIdRbac/DetailedEntraIdRolesNode.tsx'
import { AwsRoleNode } from './aws/AwsRoleNode.tsx'
import { AwsAccountNode } from './aws/AwsAccountNode.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { SnowflakeRoleNode } from './snowflake/SnowflakeRoleNode.tsx'
import { SnowflakeRolesNode } from './snowflake/SnowflakeRolesNode.tsx'
import { IdentityGraphNodeType, IdentityGraphViewTypeEnum } from './identityGraphTypes.ts'
import { AwsRolesNode } from './aws/AwsRolesNode.tsx'
import { AwsIamUserNode } from './aws/AwsIamUserNode.tsx'
import { OwnershipNode } from './common/OwnershipNode.tsx'
import { GithubAppPermissionsNode } from './github/GithubAppPermissionNode.tsx'
import { OktaUserNode } from './okta/OktaUserNode.tsx'
import { SalesforcePermissionSetNode } from './salesforce/SalesforcePermissionSetNode.tsx'
import { SalesforceProfileNode } from './salesforce/SalesforceProfileNode.tsx'
import { PermissionsCollapseItemKeys } from '../IdentitySidePanel/SidePanelPermissions.tsx'
import { AwsPoliciesNode } from './aws/AwsPoliciesNode.tsx'
import { GcpRolesNode } from './gcp/GcpRolesNode.tsx'
import { SalesforcePermissionSetsNode } from './salesforce/SalesforcePermissionSetsNode.tsx'
import { SalesforceConnectedAppMetadataNode } from './salesforce/SalesforceConnectedAppOAuthNode.tsx'
import { JumpcloudUserNode } from './jumpcloud/JumpcloudUserNode.tsx'
import { SalesforceConsumerKeyNode } from './salesforce/SalesforceCosumerKeyNode.tsx'
import { useActiveSidePanelKeysContext } from '../ActiveSidePanelKeysContext.tsx'
import { AzureDevopsPatNode } from './azure/azureDevops/AzureDevopsPatNode.tsx'
import { DemoFederationNode } from './common/DemoFederationNode.tsx'
import { AdGroupNode } from './activeDirectory/AdGroupNode.tsx'
import { IssueType, OpenIssueStatuses } from '../../../schemas/issue.ts'
import { Ec2InstanceKeyPairNode } from './aws/Ec2InstanceKeyPairNode.tsx'
import { DemoAdEndpointsNode } from './activeDirectory/DemoAdEndpointsNode.tsx'
import { DemoAdServersNode } from './activeDirectory/DemoAdServersNode.tsx'
import { IdentityGraphControls } from './IdentityGraphControls.tsx'
import { useIdentityUsageLogsGrouped } from '../../../api/identities.ts'
import { AssociationNode } from './common/AssociationNode.tsx'
import { ActionNode } from './common/ActionNode.tsx'
import { AssociationTypeEnum } from '../../../schemas/identities/usageAssociationItemSchema.ts'
import { ConnectedGcpProjectNode } from './gcp/ConnectedGcpProjectNode.tsx'
import { ConnectedGcpProjectsNode } from './gcp/ConntectedGcpProjectsNode.tsx'
import { ConnectedGcpRoleNode } from './gcp/ConnectedGcpRoleNode.tsx'
import { ConnectedGcpRolesNode } from './gcp/ConnectedGcpRolesNode.tsx'
import { AdGroupsNode } from './activeDirectory/AgGroupsNode.tsx'
import { EdgeGradients } from './common/EdgeGradients.tsx'
import { SnowflakeLoginNode } from './snowflake/SnowflakeLoginNode.tsx'
import '@xyflow/react/dist/style.css'
import { GcpResourceNode } from './gcp/GcpResourceNode.tsx'
import { GcpWorkspaceEnvsNode } from './gcp/GcpWorkspaceEnvsNode.tsx'
import { GcpScopesNode } from './gcp/GcpScopesNode.tsx'
import { DemoAtlassianApiTokenNode } from './demoAtlassian/DemoAtlassianApiTokenNode.tsx'
import { DemoJiraProjectNode } from './demoAtlassian/DemoJiraProjectNode.tsx'
import { DemoJiraRoleNode } from './demoAtlassian/DemoJiraRoleNode.tsx'
import { DemoJiraRolesNode } from './demoAtlassian/DemoJiraRolesNode.tsx'
import { GwOAuthAppNode } from './googleWorkspace/GwOAuthAppNode.tsx'
import { DemoAtlassianOAuthTokenNode } from './demoAtlassian/DemoAtlassianOAuthTokenNode.tsx'
import { IacOwnershipNode } from './iac/IacOwnershipNode.tsx'
import { IacCodeNode } from './iac/IacCodeNode.tsx'
import { GithubEnvironmentNode } from './github/GithubEnvironmentNode.tsx'
import { GithubActionsNode } from './github/GithubActionsNode.tsx'
import { OwnershipCollapseItemKeys } from '../IdentitySidePanel/SidePanelOwnership.tsx'
import { LogRocketTrackEvent, trackEvent } from '../../../services/logrocket/logrocket.ts'
import { DemoAtlassianAdminApiKeyNode } from './demoAtlassian/DemoAtlassianAdminApiKeyNode.tsx'

const nodeTypes = {
	identity: IdentityNode,
	issue: IssueNode,
	federation: DemoFederationNode,
	accessKey: AccessKeyNode,
	awsIamRole: AwsRoleNode,
	awsIamRoles: AwsRolesNode,
	awsIamUser: AwsIamUserNode,
	awsAccount: AwsAccountNode,
	gcpAccessKey: GcpSaAccessKeyNode,
	entraIdCredential: EntraIdSpAuthCredentialNode,
	awsPolicy: AwsPolicyNode,
	awsPolicies: AwsPoliciesNode,
	ec2Instances: Ec2InstancesNode,
	kubernetesResources: KubernetesResourcesNode,
	oktaUser: OktaUserNode,
	gcpProject: GcpProjectNode,
	gcpProjects: GcpProjectsNode,
	gcpRole: GcpRoleNode,
	gcpRoles: GcpRolesNode,
	gcpScope: GcpScopeNode,
	gcpScopes: GcpScopesNode,
	gcpWorkspaceEnv: GcpWorkspaceEnvNode,
	gcpWorkspaceEnvs: GcpWorkspaceEnvsNode,
	connectedGcpProject: ConnectedGcpProjectNode,
	connectedGcpProjects: ConnectedGcpProjectsNode,
	connectedGcpRole: ConnectedGcpRoleNode,
	connectedGcpRoles: ConnectedGcpRolesNode,
	githubAppPermission: GithubAppPermissionsNode,
	githubRepository: GithubRepositoryNode,
	githubRepositories: GithubRepositoriesNode,
	githubUserToken: GithubUserTokenNode,
	oauthToken: DatabricksOAuthNode,
	pat: DatabricksPATNode,
	databricksRole: DatabricksRoleNode,
	enrichedAzureRole: EnrichedAzureRoleNode,
	enrichedAzureRoles: EnrichedAzureRolesNode,
	azureVirtualMachines: DemoAzureVirtualMachinesNode,
	azureSubscription: AzureSubscriptionNode,
	azureSubscriptions: AzureSubscriptionsNode,
	azureManagementGroup: AzureManagementGroupNode,
	azureManagementGroups: AzureManagementGroupsNode,
	azureKeyVault: AzureKeyVaultNode,
	entraIDUser: EntraIDUserNode,
	entraIDUsers: EntraIDUsersNode,
	entraIDServicePrincipal: EntraIDServicePrincipalNode,
	entraIDServicePrincipals: EntraIDServicePrincipalsNode,
	detailedEntraIdRole: DetailedEntraIdRoleNode,
	detailedEntraIdRoles: DetailedEntraIdRolesNode,
	snowflakeRole: SnowflakeRoleNode,
	snowflakeRoles: SnowflakeRolesNode,
	ownership: OwnershipNode,
	salesforceProfile: SalesforceProfileNode,
	salesforcePermissionSet: SalesforcePermissionSetNode,
	salesforcePermissionSets: SalesforcePermissionSetsNode,
	salesforceConnectedAppMetadata: SalesforceConnectedAppMetadataNode,
	salesforceConsumerKey: SalesforceConsumerKeyNode,
	jumpcloudUser: JumpcloudUserNode,
	azureDevopsPat: AzureDevopsPatNode,
	adGroup: AdGroupNode,
	adGroups: AdGroupsNode,
	demoAdEndpoints: DemoAdEndpointsNode,
	demoAdServers: DemoAdServersNode,
	keyPair: Ec2InstanceKeyPairNode,
	association: AssociationNode,
	action: ActionNode,
	snowflakeLogin: SnowflakeLoginNode,
	gcpResource: GcpResourceNode,
	atlassianApiToken: DemoAtlassianApiTokenNode,
	jiraProject: DemoJiraProjectNode,
	jiraRole: DemoJiraRoleNode,
	jiraRoles: DemoJiraRolesNode,
	gwOAuthApp: GwOAuthAppNode,
	atlassianOAuthToken: DemoAtlassianOAuthTokenNode,
	iacCode: IacCodeNode,
	iacOwnership: IacOwnershipNode,
	githubEnvironment: GithubEnvironmentNode,
	githubActions: GithubActionsNode,
	atlassianAdminApiKey: DemoAtlassianAdminApiKeyNode,
}

interface IdentityGraphProps {
	identity: ServerIdentity
	setLastSelectedNode: Dispatch<SetStateAction<LastSelectedNode | null>>
	openSidePanel: () => void
	setActiveDependenciesKeys: Dispatch<SetStateAction<DependenciesCollapseItemKeys[]>>
	setActiveCrossContextKeys: Dispatch<SetStateAction<CrossContextCollapseItemKeys[]>>
	setActivePermissionsKeys: Dispatch<SetStateAction<PermissionsCollapseItemKeys[]>>
	setActiveUsageLogsGroupedKeys: Dispatch<SetStateAction<(keyof typeof AssociationTypeEnum)[]>>
	setActiveOwnershipKeys: Dispatch<SetStateAction<OwnershipCollapseItemKeys[]>>
}

const fitViewOptions: FitViewOptions = { maxZoom: 1 }

export const IdentityGraph = memo(
	({
		identity,
		setLastSelectedNode,
		openSidePanel,
		setActiveDependenciesKeys,
		setActiveCrossContextKeys,
		setActivePermissionsKeys,
		setActiveUsageLogsGroupedKeys,
		setActiveOwnershipKeys,
	}: IdentityGraphProps) => {
		const navigate = useNavigate({ from: RoutePaths.Identity })
		const [nodes, setNodes, onNodesChange] = useNodesState<IdentityGraphNodeType>([])
		const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([])
		const { updateEdge, updateNode } = useReactFlow()
		const [isCompact, setIsCompact] = useState(true)
		const [isNodeClicked, setIsNodeClicked] = useState<boolean>(false)
		const { setActiveSidePanelKeys } = useActiveSidePanelKeysContext()
		const search = useSearch({ from: RoutePaths.Identity })
		const [graphViewType, setGraphViewType] = useState<IdentityGraphViewTypeEnum>(IdentityGraphViewTypeEnum.STATIC)
		const { data: groupedUsageLogs } = useIdentityUsageLogsGrouped(identity.id)

		useEffect(() => {
			const [newNodes, newEdges] = getNodesAndEdges(
				graphViewType,
				identity,
				isCompact,
				groupedUsageLogs?.usage_logs_grouped,
			)
			setNodes(newNodes)
			setEdges(newEdges)
		}, [identity, isCompact, graphViewType])

		const onNodeClick: NodeMouseHandler<IdentityGraphNodeType> = useCallback(
			(event, node) => {
				setIsNodeClicked(true)
				switch (node.type) {
					case 'federation':
						if (node.data.identityId) {
							void navigate({
								to: RoutePaths.Identity,
								params: { identityId: node.data.identityId },
								...(isEmpty(search) ? undefined : { search: { identitiesPage: search } }),
							})
							setActiveSidePanelKeys([]) // Unset any active side panel keys
						}
						break
					case 'issue':
						if (node.data.issue.issue_type === IssueType.ITDR) {
							trackEvent(LogRocketTrackEvent.ITDRNodeClicked, {})
						}
						if (OpenIssueStatuses.includes(node.data.issue.status)) {
							setActiveSidePanelKeys(['Issues'])
							openSidePanel()
						}
						void navigate({ search: (prev) => ({ ...prev, issueId: node.data.issue.id }) })
						break
					case 'awsPolicy':
					case 'awsPolicies':
						if (
							identity.env_type === EnvironmentType.OKTA ||
							identity.env_type === EnvironmentType.ENTRA_ID ||
							identity.env_type === EnvironmentType.JUMPCLOUD
						) {
							setActiveSidePanelKeys(['Cross-Context'])
							setActiveCrossContextKeys(['aws-roles'])
						} else if (
							(identity.env_type === EnvironmentType.AWS || identity.env_type === EnvironmentType.GCP) &&
							identity.source === IdentitySource.KUBERNETES_RESOURCE
						) {
							setActiveSidePanelKeys(['Cross-Context'])
							setActiveCrossContextKeys(['aws-users'])
						} else {
							setActiveSidePanelKeys(['Permissions'])
							setLastSelectedNode({
								type: node.type,
								id: node.type === 'awsPolicy' ? getPolicyUniqueKey(node.data.policy) : '',
							})
						}

						openSidePanel()
						break
					case 'identity':
						setActiveSidePanelKeys(['Properties'])
						openSidePanel()
						break
					case 'entraIdCredential':
						setActiveSidePanelKeys(['Properties'])
						openSidePanel()
						break
					case 'snowflakeLogin':
					case 'accessKey': {
						const data = node.data
						const target = event.target as HTMLElement
						const isBadgeTarget = target.hasAttribute('data-badge') || target.closest('[data-badge]')
						const issueStatus = data.issueAttached?.status
						const issueId = data.issueAttached?.id

						if (isBadgeTarget && issueStatus && OpenIssueStatuses.includes(issueStatus)) {
							setActiveSidePanelKeys(['Issues'])
							void navigate({ search: (prev) => ({ ...prev, issueId }) })
						} else {
							setActiveSidePanelKeys(['Properties'])
						}

						openSidePanel()

						if (graphViewType === IdentityGraphViewTypeEnum.USAGE && groupedUsageLogs) {
							handleUsageGraphNodeMouseClick(node, nodes, edges, groupedUsageLogs, updateEdge, updateNode)
						}
						break
					}
					case 'gcpAccessKey':
					case 'pat':
					case 'oauthToken':
					case 'atlassianApiToken':
					case 'atlassianOAuthToken':
					case 'atlassianAdminApiKey':
						setActiveSidePanelKeys(['Properties'])
						openSidePanel()
						break
					case 'ec2Instances':
					case 'kubernetesResources':
						setActiveSidePanelKeys(['Dependencies'])
						openSidePanel()
						setActiveDependenciesKeys([node.type === 'ec2Instances' ? 'ec2' : 'kubernetes'])
						break
					case 'oktaUser':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['okta'])
						break
					case 'gcpProject':
					case 'gcpProjects':
						if (identity.env_type === EnvironmentType.JUMPCLOUD) {
							setActiveSidePanelKeys(['Cross-Context'])
							setActiveCrossContextKeys(['gcp-projects'])
						} else {
							setActiveSidePanelKeys(['Permissions'])
						}
						openSidePanel()
						break
					case 'gcpRole':
					case 'gcpRoles':
						setActiveSidePanelKeys(['Permissions'])
						if (node.type === 'gcpRole') {
							setLastSelectedNode({ type: node.type, id: node.data.role.name })
						}
						setActivePermissionsKeys(['gcpRoles'])
						openSidePanel()
						break
					case 'gcpResource':
						setActiveSidePanelKeys(['Dependencies'])
						openSidePanel()
						break
					case 'databricksRole':
					case 'githubAppPermission':
					case 'jiraProject':
					case 'jiraRoles':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'jiraRole':
						setActiveSidePanelKeys(['Permissions'])
						setLastSelectedNode({ type: node.type, id: node.data.role.name })
						openSidePanel()
						break
					case 'githubUserToken':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['github-tokens'])
						break
					case 'githubRepository':
					case 'githubRepositories':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['github-repos'])
						break
					case 'awsIamRole':
					case 'awsAccount':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['trusted-principals'])
						setActiveCrossContextKeys(['aws-roles'])

						break
					case 'awsIamUser':
						setActiveSidePanelKeys(['Cross-Context'])
						setActiveCrossContextKeys(['aws-users'])
						openSidePanel()
						break
					case 'entraIDUser':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['entraIDUser'])
						break
					case 'entraIDServicePrincipal':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['entraIDServicePrincipal'])
						break
					case 'jumpcloudUser':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['jumpcloudUser'])
						break
					case 'snowflakeRole':
						setActiveSidePanelKeys(['Permissions'])
						setLastSelectedNode({ type: node.type, id: node.data.role.name })
						openSidePanel()
						break
					case 'snowflakeRoles':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'azureVirtualMachines':
						openSidePanel()
						setActiveSidePanelKeys(['Dependencies'])
						setActiveDependenciesKeys(['azureVms'])
						break
					case 'azureSubscription':
					case 'azureSubscriptions':
					case 'enrichedAzureRoles':
					case 'azureManagementGroup':
					case 'azureManagementGroups':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['azure-roles'])
						break
					case 'enrichedAzureRole':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['azure-roles'])
						setLastSelectedNode({ type: node.type, id: node.data.role.role_assignment.id })
						break
					case 'detailedEntraIdRole':
						setActiveSidePanelKeys(['Permissions'])
						setLastSelectedNode({ type: node.type, id: node.data.role.roleAssignment.id })
						break
					case 'detailedEntraIdRoles':
						setActiveSidePanelKeys(['Permissions'])
						break
					case 'salesforceConsumerKey':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'salesforceProfile':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						setActivePermissionsKeys(['salesforceProfile'])
						setLastSelectedNode({ type: node.type, id: node.data.profile.id })
						break
					case 'salesforcePermissionSet':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						setActivePermissionsKeys(['salesforcePermissionSet'])
						setLastSelectedNode({ type: node.type, id: node.data.permissionSet.id })
						break
					case 'salesforcePermissionSets':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						setActivePermissionsKeys(['salesforcePermissionSet'])
						break
					case 'salesforceConnectedAppMetadata':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'ownership':
						openSidePanel()
						setActiveSidePanelKeys(['Ownership'])
						setActiveOwnershipKeys(['direct_ownership'])
						break
					case 'azureDevopsPat':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['azure-devops-pats'])
						setLastSelectedNode({ type: node.type, id: node.data.pat.access_id })
						break
					case 'adGroup':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						setActivePermissionsKeys(['adGroups'])
						setLastSelectedNode({ type: node.type, id: node.data.group.object_sid })
						break
					case 'adGroups':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						break
					case 'demoAdEndpoints':
						openSidePanel()
						setActiveSidePanelKeys(['Dependencies'])
						setActiveDependenciesKeys(['demoAdEndpoints'])
						break
					case 'demoAdServers':
						openSidePanel()
						setActiveSidePanelKeys(['Dependencies'])
						setActiveDependenciesKeys(['demoAdServers'])
						break
					case 'association':
						trackEvent(LogRocketTrackEvent.UsageAssociationNodeClicked, { associationType: node.data.name })
						openSidePanel()
						setActiveSidePanelKeys(['Usage'])
						setActiveUsageLogsGroupedKeys([node.data.name as keyof typeof AssociationTypeEnum])
						if (graphViewType === IdentityGraphViewTypeEnum.USAGE && groupedUsageLogs) {
							handleUsageGraphNodeMouseClick(node, nodes, edges, groupedUsageLogs, updateEdge, updateNode)
						}
						break
					case 'action':
						if (graphViewType === IdentityGraphViewTypeEnum.USAGE && groupedUsageLogs) {
							handleUsageGraphNodeMouseClick(node, nodes, edges, groupedUsageLogs, updateEdge, updateNode)
						}
						break
					case 'connectedGcpProject':
					case 'connectedGcpProjects':
					case 'connectedGcpRole':
					case 'connectedGcpRoles':
						setActiveSidePanelKeys(['Cross-Context'])
						openSidePanel()
						setActiveCrossContextKeys(['gcp-projects'])
						break
					case 'gcpWorkspaceEnv':
					case 'gcpWorkspaceEnvs':
						setActiveSidePanelKeys(['Permissions'])
						openSidePanel()
						setActivePermissionsKeys(['gcpDomainWideDelegation'])
						break
					case 'gcpScope':
					case 'gcpScopes':
						setActiveSidePanelKeys(['Permissions'])
						if (node.type === 'gcpScope') {
							setLastSelectedNode({ type: node.type, id: node.data.scope.literal_name })
						}
						openSidePanel()
						setActivePermissionsKeys(['gcpDomainWideDelegation'])
						break
					case 'gwOAuthApp':
						setActiveSidePanelKeys(['Dependencies'])
						setActiveDependenciesKeys(['googleWorkspaceOauthApps'])
						openSidePanel()
						setLastSelectedNode({ type: node.type, id: node.data.oauthApp.name })
						break
					case 'iacOwnership':
					case 'iacCode':
					case 'githubEnvironment':
					case 'githubActions':
						openSidePanel()
						setActiveSidePanelKeys(['Ownership'])
						setActiveOwnershipKeys(['iac_ownership'])
						break
				}
			},
			[
				identity,
				navigate,
				setActiveSidePanelKeys,
				setLastSelectedNode,
				search,
				graphViewType,
				groupedUsageLogs,
				nodes,
				edges,
			],
		)

		const onPaneClick = (_: MouseEvent) => {
			setIsNodeClicked(false)
			if (graphViewType === IdentityGraphViewTypeEnum.USAGE) {
				handleUsageGraphNodeMouseLeave(nodes, edges, updateEdge, updateNode)
			}
		}

		const onMouseEnter = (_: MouseEvent, node: IdentityGraphNodeType) => {
			if (isNodeClicked) {
				return
			}
			if (graphViewType === IdentityGraphViewTypeEnum.USAGE && groupedUsageLogs) {
				handleUsageGraphNodeMouseClick(node, nodes, edges, groupedUsageLogs, updateEdge, updateNode)
			}
		}

		const onMouseLeave = (_: MouseEvent) => {
			if (isNodeClicked) {
				return
			}
			if (graphViewType === IdentityGraphViewTypeEnum.USAGE && groupedUsageLogs) {
				handleUsageGraphNodeMouseLeave(nodes, edges, updateEdge, updateNode)
			}
		}

		const onViewTypeClicked = useCallback((viewType: IdentityGraphViewTypeEnum) => {
			setIsNodeClicked(false)
			setGraphViewType(viewType)
		}, [])

		return (
			<ReactFlow
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				nodeTypes={nodeTypes}
				fitView={true}
				fitViewOptions={fitViewOptions}
				onNodeClick={onNodeClick}
				onPaneClick={onPaneClick}
				onNodeMouseEnter={onMouseEnter}
				onNodeMouseLeave={onMouseLeave}
				nodesDraggable={false}
				nodesConnectable={false}
				edgesFocusable={false}
				selectNodesOnDrag={false}
				deleteKeyCode={null}
				selectionKeyCode={null}
				multiSelectionKeyCode={null}
				zoomActivationKeyCode={null}
				panActivationKeyCode={null}
				proOptions={{ hideAttribution: true }}
			>
				<MiniMap nodeColor={getMinimapNodeColor} pannable nodeComponent={MiniMapNode} />
				<Background />
				<IdentityGraphControls
					isCompact={isCompact}
					setIsCompact={setIsCompact}
					fitViewOptions={fitViewOptions}
					viewType={graphViewType}
					onViewTypeClicked={onViewTypeClicked}
					identity={identity}
				/>
				<EdgeGradients />
			</ReactFlow>
		)
	},
)
