import { Dispatch, memo, SetStateAction, useCallback, useMemo } from 'react'
import { Collapse, CollapseProps } from 'antd'
import {
	BuildOutlined,
	DatabaseOutlined,
	ExclamationCircleOutlined,
	FileTextOutlined,
	MinusOutlined,
	PartitionOutlined,
	PlusOutlined,
	SwitcherOutlined,
	UserOutlined,
	WarningOutlined,
} from '@ant-design/icons'
import UsageIcon from '../../../assets/Usage.svg?react'
import { IdentitySource, ServerIdentity } from '../../../schemas/identity.ts'
import { SidePanelIssues } from './SidePanelIssues.tsx'
import { PermissionsCollapseItemKeys, SidePanelPermissions } from './SidePanelPermissions.tsx'
import { OwnershipCollapseItemKeys, SidePanelOwnership } from './SidePanelOwnership.tsx'
import { SidePanelProperties } from './SidePanelProperties.tsx'
import { SidePanelRisks } from './SidePanelRisks.tsx'
import { DependenciesCollapseItemKeys, SidePanelDependencies } from './SidePanelDependencies.tsx'
import { CrossContextCollapseItemKeys, SidePanelCrossContext } from './SidePanelCrossContext.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { omit } from 'lodash'
import { SidePanelUsage } from './SidePanelUsage.tsx'
import { SidePanelWorkspaces } from './SidePanelWorkspaces.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { isDemo } from '../../../utils/demoUtils.ts'
import { useActiveSidePanelKeysContext } from '../ActiveSidePanelKeysContext.tsx'
import { SidePanelTags } from './SidePanelTags.tsx'
import IdentityTagIcon from '../../../assets/identityTagIcons/identity_tag_icon_16.svg?react'
import { CollapseLabel } from './CollapseLabel.tsx'
import { filterOpenIssues } from '../../../utils/issueUtils.ts'
import { AssociationTypeEnum } from '../../../schemas/identities/usageAssociationItemSchema.ts'
import { useActiveTagsKeysContext } from '../ActiveTagsKeysContext.tsx'
import { themeColors } from '../../../utils/colorUtils.ts'
import { LogRocketTrackEvent, trackEvent } from '../../../services/logrocket/logrocket.ts'

export type SidePanelKey =
	| 'Issues'
	| 'Properties'
	| 'Permissions'
	| 'Risks'
	| 'Ownership'
	| 'Dependencies'
	| 'Usage'
	| 'Cross-Context'
	| 'Workspaces'
	| 'Tags'

type CollapseItem = Required<CollapseProps>['items'][number] & {
	key: SidePanelKey
	hide: boolean
}

type IdentitySidePanelProps = {
	identity: ServerIdentity
	lastSelectedNode: LastSelectedNode | null
	activeDependenciesKeys: DependenciesCollapseItemKeys[]
	setActiveDependenciesKeys: Dispatch<SetStateAction<DependenciesCollapseItemKeys[]>>
	activeCrossContextKeys: CrossContextCollapseItemKeys[]
	setActiveCrossContextKeys: Dispatch<SetStateAction<CrossContextCollapseItemKeys[]>>
	activePermissionsKeys: PermissionsCollapseItemKeys[]
	setActivePermissionsKeys: Dispatch<SetStateAction<PermissionsCollapseItemKeys[]>>
	activeUsageLogsGroupedKeys: (keyof typeof AssociationTypeEnum)[]
	setActiveUsageLogsGroupedKeys: Dispatch<SetStateAction<(keyof typeof AssociationTypeEnum)[]>>
	activeOwnershipKeys: OwnershipCollapseItemKeys[]
	setActiveOwnershipKeys: Dispatch<SetStateAction<OwnershipCollapseItemKeys[]>>
}

export const IdentitySidePanel = memo(
	({
		identity,
		lastSelectedNode,
		activeDependenciesKeys,
		setActiveDependenciesKeys,
		activeCrossContextKeys,
		setActiveCrossContextKeys,
		activePermissionsKeys,
		setActivePermissionsKeys,
		activeUsageLogsGroupedKeys,
		setActiveUsageLogsGroupedKeys,
		activeOwnershipKeys,
		setActiveOwnershipKeys,
	}: IdentitySidePanelProps) => {
		const { activeSidePanelKeys, setActiveSidePanelKeys } = useActiveSidePanelKeysContext()
		const { activeTagsKeys, setActiveTagsKeys } = useActiveTagsKeysContext()

		const shouldHideCrossContext = useMemo(() => {
			// Demo Google Workspace/Jira identities should not hide cross-context
			if (
				isDemo &&
				identity.env_type &&
				[EnvironmentType.DEMO_ATLASSIAN, EnvironmentType.GOOGLE_WORKSPACE].includes(identity.env_type)
			) {
				return false
			}

			// Don't hide the cross-context for GCP Kubernetes resources
			if (identity.env_type === EnvironmentType.GCP && identity.source === IdentitySource.KUBERNETES_RESOURCE) {
				return false
			}

			// Don't hide the cross-context for the following identities
			// any other environment type will hide the cross-context
			if (
				identity.env_type &&
				![
					EnvironmentType.AWS,
					EnvironmentType.GITHUB,
					EnvironmentType.ENTRA_ID,
					EnvironmentType.OKTA,
					EnvironmentType.SNOWFLAKE,
					EnvironmentType.JUMPCLOUD,
					EnvironmentType.SALESFORCE,
					EnvironmentType.AZURE_DEVOPS,
					EnvironmentType.ACTIVE_DIRECTORY,
				].includes(identity.env_type)
			) {
				return true
			}

			// Hide the cross-context if Azure devops secret is the source of the identity
			// Even if the previous condition was not met.
			return identity.source === IdentitySource.AZURE_DEVOPS_SECRET
		}, [identity.env_type, identity.source])

		const onCollapseChange = useCallback(
			(key: string | string[]) => {
				const keyList = typeof key === 'string' ? [key as SidePanelKey] : (key as SidePanelKey[])
				setActiveSidePanelKeys((currentKeyList) => {
					if (!currentKeyList.includes('Usage') && keyList.includes('Usage')) {
						trackEvent(LogRocketTrackEvent.UsageSidePanelExpanded)
					}

					return keyList
				})
			},
			[setActiveSidePanelKeys],
		)

		const collapseItemClassName = 'mb-2 !rounded bg-surface-secondary border border-border-primary'
		// Override antd styles
		const collapseItemStyle = { borderBottom: `1px solid ${themeColors.border.primary}` }
		const identityHasOpenIssues = !!filterOpenIssues(identity.issues).length
		const collapseItems: CollapseItem[] = [
			{
				key: 'Issues',
				label: (
					<CollapseLabel
						icon={<ExclamationCircleOutlined />}
						title="Open Issues"
						tooltipTitle={identityHasOpenIssues ? undefined : 'Identity has no open issues'}
					/>
				),
				children: <SidePanelIssues identity={identity} />,
				className: collapseItemClassName,
				collapsible: identityHasOpenIssues ? undefined : 'disabled',
				style: collapseItemStyle,
				hide: false,
			},
			{
				key: 'Properties',
				label: <CollapseLabel icon={<DatabaseOutlined />} title="Properties" />,
				children: <SidePanelProperties identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: false,
			},
			{
				key: 'Permissions',
				label: <CollapseLabel icon={<FileTextOutlined />} title="Permissions" />,
				children: (
					<SidePanelPermissions
						identity={identity}
						lastSelectedNode={lastSelectedNode}
						activePermissionsKeys={activePermissionsKeys}
						setActivePermissionsKeys={setActivePermissionsKeys}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.source === IdentitySource.POSTGRES_ROLE ||
					(identity.env_type !== EnvironmentType.AWS &&
						identity.env_type !== EnvironmentType.GCP &&
						identity.env_type !== EnvironmentType.AZURE &&
						identity.env_type !== EnvironmentType.ENTRA_ID &&
						identity.env_type !== EnvironmentType.DATABRICKS &&
						identity.env_type !== EnvironmentType.SNOWFLAKE &&
						identity.env_type !== EnvironmentType.GITHUB &&
						identity.env_type !== EnvironmentType.SALESFORCE &&
						identity.env_type !== EnvironmentType.ACTIVE_DIRECTORY &&
						identity.env_type !== EnvironmentType.DEMO_ATLASSIAN),
			},
			{
				key: 'Workspaces',
				label: <CollapseLabel icon={<SwitcherOutlined />} title="Workspaces" />,
				children: <SidePanelWorkspaces identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: identity.env_type !== EnvironmentType.DATABRICKS,
			},
			{
				key: 'Risks',
				label: <CollapseLabel icon={<WarningOutlined />} title="Risks" />,
				children: <SidePanelRisks identity={identity} />,
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: false,
			},
			{
				key: 'Ownership',
				label: <CollapseLabel icon={<UserOutlined />} title="Ownership" />,
				children: (
					<SidePanelOwnership
						identity={identity}
						activeOwnershipKeys={activeOwnershipKeys}
						setActiveOwnershipKeys={setActiveOwnershipKeys}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.source === IdentitySource.POSTGRES_ROLE ||
					(identity.env_type !== EnvironmentType.AWS &&
						identity.env_type !== EnvironmentType.AZURE &&
						identity.env_type !== EnvironmentType.GCP &&
						identity.env_type !== EnvironmentType.ENTRA_ID &&
						identity.source !== IdentitySource.DATABRICKS_SERVICE_PRINCIPAL &&
						identity.source !== IdentitySource.SALESFORCE_USER &&
						identity.source !== IdentitySource.SALESFORCE_CONNECTED_APPLICATION &&
						identity.source !== IdentitySource.GITHUB_APP_INSTALLATION &&
						!(identity.source === IdentitySource.ACTIVE_DIRECTORY_USER) &&
						!(identity.source === IdentitySource.DEMO_ATLASSIAN_USER && isDemo) &&
						identity.source !== IdentitySource.SNOWFLAKE_USER),
			},
			{
				key: 'Dependencies',
				label: <CollapseLabel icon={<BuildOutlined />} title="Dependencies" />,
				children: (
					<SidePanelDependencies
						identity={identity}
						activeDependenciesKeys={activeDependenciesKeys}
						setActiveDependenciesKeys={setActiveDependenciesKeys}
						lastSelectedNode={lastSelectedNode}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.env_type !== EnvironmentType.AWS &&
					identity.env_type !== EnvironmentType.AZURE &&
					identity.env_type !== EnvironmentType.GITHUB &&
					identity.source !== IdentitySource.GCP_SERVICE_ACCOUNT &&
					identity.source !== IdentitySource.DATABRICKS_SERVICE_PRINCIPAL &&
					!(identity.source === IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL && isDemo) &&
					!(identity.source === IdentitySource.ACTIVE_DIRECTORY_USER && isDemo) &&
					!(identity.source === IdentitySource.GOOGLE_WORKSPACE && isDemo),
			},
			{
				key: 'Usage',
				label: <CollapseLabel icon={<UsageIcon />} title="Usage" />,
				children: (
					<SidePanelUsage
						identity={identity}
						activeUsageLogsGroupedKeys={activeUsageLogsGroupedKeys}
						setActiveUsageLogsGroupedKeys={setActiveUsageLogsGroupedKeys}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.source === IdentitySource.POSTGRES_ROLE ||
					(identity.env_type !== EnvironmentType.AWS &&
						identity.env_type !== EnvironmentType.AZURE &&
						identity.env_type !== EnvironmentType.GCP &&
						identity.source !== IdentitySource.ENTRA_ID_USER &&
						identity.source !== IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL &&
						identity.source !== IdentitySource.SNOWFLAKE_USER &&
						identity.env_type !== EnvironmentType.GITHUB &&
						identity.env_type !== EnvironmentType.SALESFORCE &&
						identity.source !== IdentitySource.OKTA &&
						identity.source !== IdentitySource.ACTIVE_DIRECTORY_USER &&
						identity.source !== IdentitySource.DEMO_ATLASSIAN_USER),
			},
			{
				key: 'Cross-Context',
				label: <CollapseLabel icon={<PartitionOutlined />} title="Cross-Context" />,
				children: (
					<SidePanelCrossContext
						identity={identity}
						activeCrossContextKeys={activeCrossContextKeys}
						setActiveCrossContextKeys={setActiveCrossContextKeys}
						lastSelectedNode={lastSelectedNode}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide: shouldHideCrossContext,
			},
			{
				key: 'Tags',
				label: <CollapseLabel icon={<IdentityTagIcon />} title="Tags" />,
				children: (
					<SidePanelTags
						identity={identity}
						activeTagsKeys={activeTagsKeys}
						setActiveTagsKeys={setActiveTagsKeys}
					/>
				),
				className: collapseItemClassName,
				style: collapseItemStyle,
				hide:
					identity.source !== IdentitySource.AWS_IAM_USER &&
					identity.source !== IdentitySource.AWS_IAM_ROLE &&
					identity.source !== IdentitySource.AZURE_KV_SECRET &&
					identity.source !== IdentitySource.AZURE_KV_CERTIFICATE &&
					identity.source !== IdentitySource.AZURE_KV_KEY,
			},
		]

		const filteredActiveSidePanelKeys = identityHasOpenIssues
			? activeSidePanelKeys
			: activeSidePanelKeys.filter((activeSidePanelKeys) => activeSidePanelKeys !== 'Issues')

		return (
			<div className="p-2 overflow-y-auto h-full">
				<Collapse
					ghost
					onChange={onCollapseChange}
					activeKey={filteredActiveSidePanelKeys}
					items={collapseItems.filter((item) => !item.hide).map((item) => omit(item, 'hide'))}
					expandIconPosition="end"
					expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
				/>
			</div>
		)
	},
)
