import { useCallback, useMemo } from 'react'
import { ColDef } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Switch, Tooltip, Button } from 'antd'
import {
	ServerNotification,
	NotificationChannel,
	NotificationStatus,
	NotificationChannelNameMap,
} from '../../../schemas/notifications'
import RuleParameters from '../../../assets/notifications/notificationsTableRuleParams.svg?react'
import SlackIcon from '../../../assets/slack_icon.svg?react'
import TeamsIcon from '../../../assets/teams_icon.svg?react'
import JiraIcon from '../../../assets/jira_icon.svg?react'
import NotificationsAddNewIntegrationWireframe from '../../../assets/notifications/NotificationsAddNewIntegrationWireframe.svg?react'
import { PlusOutlined } from '@ant-design/icons'

type NotificationsConfigTableProps = {
	isEditing: boolean
	isFetching: boolean
	notifications: ServerNotification[]
	searchText: string
	onToggleNotification: (notification: ServerNotification) => void
	onNavigateToIntegration: () => void
}

const NotificationChannelIcon = ({ value }: { value: NotificationChannel | undefined }) => {
	const iconMap = {
		[NotificationChannel.SLACK]: SlackIcon,
		[NotificationChannel.DEMO_JIRA]: JiraIcon,
		[NotificationChannel.TEAMS]: TeamsIcon,
	}

	if (!value || !iconMap[value]) {
		return null
	}

	const Icon = iconMap[value]

	return (
		<div className="flex items-center">
			<Tooltip title={NotificationChannelNameMap[value]}>
				<span className="bg-surface-secondary border border-border-primary rounded-full p-1">
					<Icon className="w-5 h-5" />
				</span>
			</Tooltip>
		</div>
	)
}

export const NotificationsConfigTable = ({
	isEditing,
	isFetching,
	notifications,
	searchText,
	onToggleNotification,
	onNavigateToIntegration,
}: NotificationsConfigTableProps) => {
	const columns = useMemo(
		(): ColDef<ServerNotification>[] => [
			{
				headerName: 'Rule Name',
				flex: 0.7,
				sort: 'asc',
				sortIndex: 0,
				field: 'name',
				// TODO: This needs to be dynamic once we allow setting the rule name in the UI
				cellRenderer: ({ data }: { data: ServerNotification }) => (
					<div className="flex items-center h-full">
						<span>{data.name}</span>
					</div>
				),
			},
			{
				headerName: 'Description',
				flex: 1,
				field: 'description',
				// TODO: This needs to be dynamic once we allow setting the description in the UI
				cellRenderer: ({ data }: { data: ServerNotification }) => (
					<span className="flex items-center h-full">{data.description}</span>
				),
			},
			{
				headerName: 'Rule Parameters',
				flex: 0.8,
				field: 'trigger_json',
				valueFormatter: ({ data }) => JSON.stringify(data),
				// TODO: This needs to be dynamic once we have a better way to display the rule parameters from the trigger_json
				cellRenderer: () => (
					<span className="flex items-center h-full">
						<div className="flex items-center">
							<RuleParameters />
						</div>
					</span>
				),
			},
			{
				field: 'integration_type',
				headerName: 'Platform',
				flex: 0.4,
				valueGetter: ({ data }) => data?.integration_type ?? NotificationChannel.SLACK,
				cellRenderer: ({ data }: { data: ServerNotification }) => (
					<span className="flex items-center h-full">
						<NotificationChannelIcon value={data?.integration_type} />
					</span>
				),
			},
			{
				field: 'integration_webhook_channel',
				headerName: 'Destination',
				flex: 0.7,
				valueGetter: ({ data }) => data?.integration_webhook_channel ?? '',
				cellRenderer: ({ data }: { data: ServerNotification }) => (
					<span className="flex items-center h-full">{data.integration_webhook_channel ?? ''}</span>
				),
			},
			{
				headerName: 'Enabled',
				type: 'rightAligned',
				flex: 0.4,
				field: 'status',
				cellRenderer: ({ value, data }: { value: NotificationStatus; data: ServerNotification }) => (
					<Switch
						checked={value === NotificationStatus.ENABLED}
						onChange={() => {
							onToggleNotification(data)
						}}
						loading={isFetching}
						disabled={!isEditing}
					/>
				),
			},
		],
		[isEditing, isFetching],
	)
	const NoRowsOverlay = useCallback(() => {
		return (
			// We need to set pointer-events-auto to make the button clickable as ag-grid sets pointer-events-none by default
			<div className="flex flex-col items-center justify-center h-full gap-8 pointer-events-auto">
				<div className="scale-110">
					<NotificationsAddNewIntegrationWireframe />
				</div>
				<div className="flex flex-col items-center text-center">
					<p className="font-bold text-lg mb-2">No connection yet</p>
					<p className="text-textIcon-secondary">
						Integrate Token with your tools
						<br />
						and start taking control of your non-human identities
					</p>
				</div>
				<Button type="primary" onClick={onNavigateToIntegration} icon={<PlusOutlined />}>
					Add new integration
				</Button>
			</div>
		)
	}, [onNavigateToIntegration])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowData={notifications}
			columnDefs={columns}
			quickFilterText={searchText}
			getRowId={({ data }) => data.playbook_id}
			rowHeight={50}
			noRowsOverlayComponent={NoRowsOverlay}
		/>
	)
}
