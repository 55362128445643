import React from 'react'
import { ServerMetricsTopBar } from '../../../schemas/metrics/metricsTopBar.ts'
import TrendUpIcon from '../../../assets/trend_up_icon.svg?react'
import { Link, LinkProps } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { themeColors } from '../../../utils/colorUtils.ts'
import { LogRocketTrackEvent, trackEvent } from '../../../services/logrocket/logrocket.ts'

const getItdrLinkProps = (): LinkProps => {
	return {
		from: RoutePaths.Metrics,
		to: RoutePaths.Itdr,
	} satisfies LinkProps
}

export const TotalThreats: React.FunctionComponent<{
	metricsTopBar: ServerMetricsTopBar
}> = ({ metricsTopBar }) => {
	const difference = metricsTopBar.itdr_issues_count - metricsTopBar.itdr_issues_count_x_days_ago

	const iconStyle = {
		transform: difference < 0 ? 'rotate(90deg)' : 'none',
	}

	const diffStyle = {
		backgroundColor: difference < 0 ? themeColors.status.activeLowContrast : themeColors.status.highLowContrast,
		color: difference < 0 ? themeColors.status.active : themeColors.status.high,
		padding: '2px 6px',
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		gap: '2px',
		height: 'fit-content',
	}

	return (
		<div className="h-full flex gap-4">
			<div className="flex gap-2 h-min">
				<Link {...getItdrLinkProps()} onClick={() => trackEvent(LogRocketTrackEvent.ITDRTotalThreatsClicked)}>
					{formatNumber(metricsTopBar.itdr_issues_count)}
				</Link>
				<div className="flex self-end">
					<div style={diffStyle}>
						<TrendUpIcon style={iconStyle} />
						<span className="text-xs">
							{difference > 0 ? '+' : ''}
							{formatNumber(difference)}
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}
