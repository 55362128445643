import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { DetailedEntraIdRoleAssignment } from '../../../../../schemas/identities/entraId/entraIdRoleAssignmentsSchemas.ts'
import { BasePermissionNode } from '../../common/baseNodes/BasePermissionNode.tsx'

export type DetailedEntraIdRoleNodeType = Node<{ role: DetailedEntraIdRoleAssignment }, 'detailedEntraIdRole'>
type EntraIdRoleNodeProps = NodeProps<DetailedEntraIdRoleNodeType>

export const DetailedEntraIdRoleNode = ({ data: { role } }: EntraIdRoleNodeProps) => (
	<BasePermissionNode
		label="Entra ID Role"
		name={{ text: role.roleDefinition.displayName || 'Unknown Role' }}
		risks={role.tags}
		icon={<TeamOutlined />}
	/>
)
