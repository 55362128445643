import { NodeProps, Node } from '@xyflow/react'
import { BaseDependenciesNode } from '../common/baseNodes/BaseDependenciesNode.tsx'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { ServerGcpServiceAccountResourceData } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'
import ResourceIcon from '../../../../assets/resources.svg?react'
import React from 'react'

export type GcpResourceNodeType = Node<{ resources: ServerGcpServiceAccountResourceData[] }, 'gcpResource'>
type GcpResourceNodeProps = NodeProps<GcpResourceNodeType>

export const GcpResourceNode = ({ data: { resources } }: GcpResourceNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'gcpResource')
	const resourceIcon: React.ComponentType = () => <ResourceIcon width={20} height={20} />
	return (
		<BaseDependenciesNode
			resources={resources}
			isHighlighted={isHighlighted}
			nodeLabel="GCP Resources"
			tooltipTitle={`Identity is used in ${resources.length} GCP Resources ${
				resources.length === 1 ? 'instance' : 'instances'
			}`}
			Icon={resourceIcon}
		/>
	)
}
