import { IdentityActivityStatus, ServerIdentity } from '../../schemas/identity.ts'
import { StatItem } from '../../components/common/StatItem.tsx'
import { PriorityNameTag } from '../../components/common/PriorityNameTag.tsx'
import { filterOpenIssues, getIssuesMaxPriority } from '../../utils/issueUtils.ts'
import { IdentityTypeTag } from '../../components/common/IdentityTypeTag.tsx'
import { TagIcons } from '../../components/common/TagIcons.tsx'
import { Tag } from '../../schemas/tags.ts'
import { formatDate } from '../../utils.ts'
import TrashIcon from '../../assets/trash_icon_16.svg?react'
import { Tooltip } from 'antd'
import { IdentitySourceWithEnvIcon } from '../Identities/IdentitiesTable/IdentitySourceWithEnvIcon.tsx'

type IdentityStatsProps = {
	identity: ServerIdentity
}

export const IdentityStats = ({ identity }: IdentityStatsProps) => (
	<div className="flex justify-between bg-surface-primary border border-border-secondary rounded-lg py-4 px-6">
		<StatItem title="Risk Score">
			<PriorityNameTag priority={getIssuesMaxPriority(filterOpenIssues(identity.issues))} />
		</StatItem>
		<StatItem title="Identity Source">
			<IdentitySourceWithEnvIcon envType={identity.env_type} origin={identity.source} />
		</StatItem>
		<StatItem title="Open Issues">{filterOpenIssues(identity.issues).length ?? 0}</StatItem>
		<StatItem title="Identity Type">
			<IdentityTypeTag type={identity.type} />
		</StatItem>
		<StatItem title="Risk Type">
			{identity.tags?.length ? (
				<TagIcons tags={identity.tags.map(({ name }) => ({ name: name as Tag }))} />
			) : (
				'N/A'
			)}
		</StatItem>
		{identity?.activity_status === IdentityActivityStatus.DELETED && (
			<StatItem title="Is Deleted">
				<div className="flex items-center gap-1">
					<TrashIcon className="text-textIcon-secondary" />
					Yes
				</div>
			</StatItem>
		)}
		<StatItem title="Date Created">
			<Tooltip title={formatDate(identity.created_at)}>{formatDate(identity.created_at, false)}</Tooltip>
		</StatItem>
		<StatItem title="Date Updated">
			<Tooltip title={formatDate(identity.updated_at || identity.created_at)}>
				{formatDate(identity.updated_at || identity.created_at, false)}
			</Tooltip>
		</StatItem>
		<StatItem title="Last Activity">
			<Tooltip title={formatDate(identity.last_activity)}>{formatDate(identity.last_activity, false)}</Tooltip>
		</StatItem>
	</div>
)
