import { ServerIdentity } from '../../../schemas/identity.ts'
import { getCombinedAwsIamRolePolicy } from '../../../utils/awsIdentityUtils.ts'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { IamPermissionsTable } from './tables/aws/IamPermissionsTable.tsx'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { CellMouseOverEvent } from 'ag-grid-enterprise'
import { CombinedAwsIamRolePolicy } from '../../../schemas/identities/awsIamRoleXcSchema.ts'
import { ConnectedGcpProjectsTable } from './tables/gcp/ConnectedGcpProjectsTable.tsx'
import { DemoDatabricksRolesTable } from './tables/demoDatabricks/DemoDatabricksRolesTable.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { EntraIDRolesTable } from './tables/entraId/EntraIDRolesTable.tsx'
import { SnowflakeRolesTable } from './tables/snowflake/SnowflakeRolesTable.tsx'
import { type ServerSnowflakeRoleXc } from '../../../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { GithubAppPermissionsTable } from './tables/github/GithubAppPermissionsTable.tsx'
import { SalesforcePermissionSetsTable } from './tables/salesforce/SalesforcePermissionSetsTable.tsx'
import { SalesforceProfileTable } from './tables/salesforce/SalesforceProfileTable.tsx'
import { Collapse, CollapseProps } from 'antd'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel.tsx'
import SalesforceIcon from '../../../assets/salesforce_icon.svg?react'
import { SalesforceConnectedMetadataTable } from './tables/salesforce/SalesforceConnectedMetadataTable.tsx'
import { AdACEsTable } from './tables/activeDirectory/AdACEsTable.tsx'
import AdIcon from '../../../assets/active_directory_icon_16.svg?react'
import { AdGroupsTable } from './tables/activeDirectory/AdGroupsTable.tsx'
import { GcpRolesTable } from './tables/gcp/GcpRolesTable.tsx'
import {
	type FlattenedDelegationRow,
	GcpDomainWideDelegationTable,
} from './tables/gcp/GcpDomainWideDelegationTable.tsx'
import {
	RoleWithProjects,
	ServerGcpServiceAccountProjectRole,
} from '../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'
import GoogleWorkspaceIcon from '../../../assets/google_workspace_logo_16.svg?react'
import GcpIcon from '../../../assets/gcp_icon_20.svg?react'
import { DemoJiraRolesTable } from './tables/demoAtlassian/DemoJiraRolesTable.tsx'
import { DemoJiraRoleWithProject } from '../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'

export type PermissionsCollapseItemKeys =
	| 'salesforceProfile'
	| 'salesforcePermissionSet'
	| 'salesforceConnectedAppMetadata'
	| 'adGroups'
	| 'adAces'
	| 'gcpRoles'
	| 'gcpDomainWideDelegation'
	| 'googleWorkspaceOauthApps'

type PermissionsCollapseItem = Required<CollapseProps>['items'][number] & {
	key: PermissionsCollapseItemKeys
}

type SidePanelPermissionsProps = {
	identity: ServerIdentity
	lastSelectedNode?: LastSelectedNode | null
	activePermissionsKeys: PermissionsCollapseItemKeys[]
	setActivePermissionsKeys: Dispatch<SetStateAction<PermissionsCollapseItemKeys[]>>
}

export const SidePanelPermissions = ({
	identity,
	lastSelectedNode,
	activePermissionsKeys,
	setActivePermissionsKeys,
}: SidePanelPermissionsProps) => {
	const awsPolicies = useMemo(
		() =>
			getCombinedAwsIamRolePolicy(
				identity.aws_iam_user?.aws_iam_user_details_xc,
				identity.aws_iam_role?.aws_iam_role_details_xc ||
					identity.aws_key_pair?.aws_iam_role_details_xc ||
					identity.aws_ec2_instance?.aws_iam_role_details_xc,
				identity.aws_iam_role?.permission_boundary,
				identity.aws_iam_user?.permission_boundary,
			),
		[identity],
	)
	const { setHighlightedNodes } = useHighlightedNodesContext()

	const onCellMouseOut = useCallback(() => {
		setHighlightedNodes([])
	}, [setHighlightedNodes])

	const gcpRolesMappings = identity.gcp_service_account?.role_mappings
	const gcpDemoDomainWideDelegation = identity.gcp_service_account?.demo_google_workspace_domain_wide_delegation
	const gcpRolesXc = identity.gcp_service_account?.service_account_projects_roles_xc
	const databricksRoles = identity?.databricks_service_principal?.roles || identity?.databricks_user?.roles
	const entraIdRoles =
		identity.entra_id_user?.entra_id_role_assignments ||
		identity.entra_id_service_principal?.entra_id_role_assignments
	const snowflakeRoles = identity.snowflake_user?.snowflake_roles_xc
	const githubAppPermissions = identity.github_app_installation?.permissions
	const salesforcePermissionSets = identity.salesforce_user?.permission_sets
	const salesforceProfile = identity.salesforce_user?.profile
	const salesforceConnectedAppOAuthMetadata = identity.salesforce_connected_application?.app_metadata
	const adUser = identity.active_directory_user
	const adComputer = identity.active_directory_computer
	const adGroups = adUser?.groups || adComputer?.groups || []
	const adPermissions = adUser?.outbound_permissions || adComputer?.outbound_permissions || []
	const jiraRoles = identity.demo_atlassian_user?.jira_project_roles || {}

	const onGcpRoleMouseOver = useCallback(
		(event: CellMouseOverEvent<RoleWithProjects>) => {
			setHighlightedNodes([{ type: 'gcpRole', id: event.node.id! }])
		},
		[setHighlightedNodes],
	)

	const onGcpProjectMouseOver = useCallback(
		(event: CellMouseOverEvent<ServerGcpServiceAccountProjectRole>) => {
			setHighlightedNodes([{ type: 'gcpProject', id: event.node.id! }])
		},
		[setHighlightedNodes],
	)

	const onGcpScopeMouseOver = useCallback(
		(event: CellMouseOverEvent<FlattenedDelegationRow>) => {
			if (!event.data) return
			setHighlightedNodes([{ type: 'gcpScope', id: event.data.scope_name }])
		},
		[setHighlightedNodes],
	)

	const salesforceItems: PermissionsCollapseItem[] = useMemo(() => {
		const items: PermissionsCollapseItem[] = []

		if (salesforceProfile) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Profile" icon={SalesforceIcon} />,
				key: 'salesforceProfile',
				children: (
					<SalesforceProfileTable
						data={salesforceProfile}
						lastSelectedProfile={
							lastSelectedNode?.type === 'salesforceProfile' ? lastSelectedNode.id : undefined
						}
					/>
				),
			})
		}

		if (salesforcePermissionSets?.length) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Permission Sets" icon={SalesforceIcon} />,
				key: 'salesforcePermissionSet',
				children: (
					<SalesforcePermissionSetsTable
						data={salesforcePermissionSets}
						lastSelectedPermissionSet={
							lastSelectedNode?.type === 'salesforcePermissionSet' ? lastSelectedNode.id : undefined
						}
					/>
				),
			})
		}

		if (salesforceConnectedAppOAuthMetadata) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Connected Application Metadata" icon={SalesforceIcon} />,
				key: 'salesforceConnectedAppMetadata',
				children: (
					<SalesforceConnectedMetadataTable
						data={salesforceConnectedAppOAuthMetadata}
						lastSelectedMetadata={
							lastSelectedNode?.type === 'salesforceConnectedAppMetadata'
								? lastSelectedNode.id
								: undefined
						}
					/>
				),
			})
		}

		return items
	}, [salesforceProfile, salesforcePermissionSets, salesforceConnectedAppOAuthMetadata, lastSelectedNode])

	const adItems: PermissionsCollapseItem[] = useMemo(() => {
		const items: PermissionsCollapseItem[] = []

		if (adGroups.length > 0) {
			items.push({
				label: <CollapsibleItemLabel label="Active Directory Groups" icon={AdIcon} />,
				key: 'adGroups',
				children: (
					<AdGroupsTable
						groups={adGroups}
						lastSelectedGroup={lastSelectedNode?.type === 'adGroup' ? lastSelectedNode.id : undefined}
					/>
				),
			})
		}

		if (adPermissions.length > 0) {
			items.push({
				label: <CollapsibleItemLabel label="Outbound Permissions" icon={AdIcon} />,
				key: 'adAces',
				children: <AdACEsTable aces={adPermissions} />,
			})
		}

		return items
	}, [adGroups, adPermissions, lastSelectedNode])

	const gcpItems: PermissionsCollapseItem[] = useMemo(() => {
		const items: PermissionsCollapseItem[] = []

		if (gcpRolesMappings?.length) {
			items.push({
				label: <CollapsibleItemLabel label="GCP Roles" icon={GcpIcon} />,
				key: 'gcpRoles',
				children: (
					<GcpRolesTable
						gcpServiceAccountRolesMappings={gcpRolesMappings}
						permissionsUsage={identity.gcp_service_account?.permissions_usage || []}
						lastSelectedRole={lastSelectedNode?.type === 'gcpRole' ? lastSelectedNode.id : undefined}
						onCellMouseOver={onGcpRoleMouseOver}
						onGcpProjectMouseOver={onGcpProjectMouseOver}
						onCellMouseOut={onCellMouseOut}
					/>
				),
			})
		}

		if (gcpDemoDomainWideDelegation?.length) {
			items.push({
				label: <CollapsibleItemLabel label="Domain Wide Delegation" icon={GoogleWorkspaceIcon} />,
				key: 'gcpDomainWideDelegation',
				children: (
					<GcpDomainWideDelegationTable
						data={gcpDemoDomainWideDelegation.flatMap((workspace) =>
							workspace.scopes.map((scope) => ({
								workspace_name: workspace.google_workspace_env_name,
								workspace_id: workspace.id,
								scope_name: scope.literal_name,
								scope_friendly_name: scope.friendly_name || scope.literal_name,
								scope_tag: scope.tag,
							})),
						)}
						lastSelectedNode={lastSelectedNode?.type === 'gcpScope' ? lastSelectedNode.id : undefined}
						onCellMouseOver={onGcpScopeMouseOver}
						onCellMouseOut={onCellMouseOut}
					/>
				),
			})
		}

		return items
	}, [
		gcpRolesMappings,
		identity.gcp_service_account?.permissions_usage,
		gcpDemoDomainWideDelegation,
		lastSelectedNode,
		onGcpRoleMouseOver,
		onGcpProjectMouseOver,
		onCellMouseOut,
		onGcpScopeMouseOver,
	])

	const onPermissionsCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList = Array.isArray(key)
				? (key as PermissionsCollapseItemKeys[])
				: [key as PermissionsCollapseItemKeys]
			setActivePermissionsKeys(keyList)
		},
		[setActivePermissionsKeys],
	)

	if (
		!awsPolicies?.length &&
		!gcpRolesXc?.length &&
		!gcpRolesMappings?.length &&
		!gcpDemoDomainWideDelegation?.length &&
		!databricksRoles?.length &&
		!entraIdRoles?.length &&
		!snowflakeRoles?.length &&
		!githubAppPermissions?.length &&
		!salesforcePermissionSets?.length &&
		!salesforceProfile &&
		!salesforceConnectedAppOAuthMetadata &&
		!adGroups.length &&
		!adPermissions.length &&
		!Object.keys(jiraRoles).length &&
		!gcpDemoDomainWideDelegation?.length
	) {
		return (
			<div className="flex gap-1">
				<span>No permissions found for identity</span>
				<span className="font-semibold">{identity.literal_friendly_name || identity.literal}</span>
			</div>
		)
	}

	if (gcpItems.length > 1) {
		return (
			<Collapse
				onChange={onPermissionsCollapseChange}
				activeKey={activePermissionsKeys}
				items={gcpItems}
				className="bg-surface-primary"
				expandIconPosition="end"
			/>
		)
	} else if (gcpItems.length === 1) {
		return gcpItems[0].children
	}

	if (gcpRolesXc?.length) {
		return <ConnectedGcpProjectsTable gcpProjectsXc={gcpRolesXc} />
	}

	if (databricksRoles?.length) {
		return <DemoDatabricksRolesTable data={databricksRoles} />
	}

	if (entraIdRoles?.length) {
		return (
			<EntraIDRolesTable
				data={entraIdRoles}
				lastSelectedRole={lastSelectedNode?.type === 'detailedEntraIdRole' ? lastSelectedNode.id : undefined}
			/>
		)
	}

	if (githubAppPermissions?.length) {
		return <GithubAppPermissionsTable data={githubAppPermissions} />
	}

	if (snowflakeRoles?.length) {
		return (
			<SnowflakeRolesTable
				data={snowflakeRoles}
				lastSelectedRole={lastSelectedNode?.type === 'snowflakeRole' ? lastSelectedNode.id : undefined}
				onCellMouseOver={(event: CellMouseOverEvent<ServerSnowflakeRoleXc>) => {
					setHighlightedNodes([{ type: 'snowflakeRole', id: event.node.id! }])
				}}
				onCellMouseOut={onCellMouseOut}
			/>
		)
	}

	if (salesforcePermissionSets?.length || salesforceProfile || salesforceConnectedAppOAuthMetadata) {
		return (
			<Collapse
				onChange={onPermissionsCollapseChange}
				activeKey={activePermissionsKeys}
				items={salesforceItems}
				className="bg-surface-primary"
				expandIconPosition="end"
			/>
		)
	}

	if (adGroups.length || adPermissions.length) {
		return (
			<Collapse
				onChange={onPermissionsCollapseChange}
				activeKey={activePermissionsKeys}
				items={adItems}
				className="bg-surface-primary"
				expandIconPosition="end"
			/>
		)
	}

	if (Object.keys(jiraRoles).length) {
		return (
			<DemoJiraRolesTable
				projectRoles={jiraRoles}
				lastSelectedRole={lastSelectedNode?.type === 'jiraRole' ? lastSelectedNode.id : undefined}
				onCellMouseOver={(event: CellMouseOverEvent<DemoJiraRoleWithProject>) => {
					setHighlightedNodes([{ type: 'jiraRole', id: event.node.id! }])
				}}
				onCellMouseOut={onCellMouseOut}
			/>
		)
	}

	return (
		<IamPermissionsTable
			data={awsPolicies}
			lastSelectedPolicyId={lastSelectedNode?.type === 'awsPolicy' ? lastSelectedNode.id : undefined}
			onCellMouseOver={(event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => {
				setHighlightedNodes([{ type: 'awsPolicy', id: event.node.id! }])
			}}
			onCellMouseOut={onCellMouseOut}
		/>
	)
}
