import { z } from 'zod'
import { DateSchema } from '../../common'
import { TagSchema } from '../../tags'
import { ServerGcpPrincipalInfoXcSchema } from './gcpProjectXcSchema.ts'
import { ServerGcpProjectSchema } from '../../environments/gcpProject.ts'
import { ServerEndpointAccessXcSchema } from '../endpointAccessXcSchema.ts'
import { ServerKubernetesResourceXcSchema } from '../kubernetes/kubernetesResourceXcSchema.ts'
import { ServerGCPUserUsageLogSchema } from './GcpUserUsageLogsSchema.ts'
import { ServerGCPUserChangeLogsSchema } from './gcpUserChangeLogs.ts'
import GceIcon from '../../../assets/actionNodesIcons/action_gce_logo_gcp.svg?react'
import SqlIcon from '../../../assets/actionNodesIcons/action_cloudSql_logo_gcp.svg?react'
import GkeIcon from '../../../assets/actionNodesIcons/action_k8s_logo_gcp.svg?react'
import RunIcon from '../../../assets/actionNodesIcons/action_cloudRun_logo_gcp.svg?react'
import FunctionIcon from '../../../assets/actionNodesIcons/action_cloudFunction_logo_gcp.svg?react'
import PubSubIcon from '../../../assets/actionNodesIcons/action_pubsub_logo_gcp.svg?react'
import DataflowIcon from '../../../assets/actionNodesIcons/action_dataflow_logo_gcp.svg?react'
import CloudSchedulerIcon from '../../../assets/actionNodesIcons/action_cloudScheduler_logo_gcp.svg?react'

export const GcpServiceAccountKeySchema = z
	.object({
		disabled: z.boolean(),
		keyAlgorithm: z.string(),
		keyOrigin: z.string(),
		keyType: z.string(),
		name: z.string(),
		validAfterTime: DateSchema,
		validBeforeTime: DateSchema,
	})
	.transform((data) => ({
		...data,
		accessKeyId: data.name.split('/').pop() || '',
	}))

export type GcpServiceAccountKey = z.infer<typeof GcpServiceAccountKeySchema>

export const ServerGcpRoleSchema = z.object({
	id: z.string(),
	customer_id: z.string(),
	environment_id: z.string(),
	name: z.string(),
	title: z.string(),
	description: z.string().nullable(),
	included_permissions: z.array(z.string()),
	stage: z.string().nullable(),
	deleted: z.boolean(),
	tags: z.array(TagSchema),
})

export type ServerGcpRole = z.infer<typeof ServerGcpRoleSchema>

export const GcpServiceAccountProjectRoleSchema = z.object({
	id: z.string(),
	service_account_row_id: z.string(),
	role_row_id: z.string(),
	project_row_id: z.string(),
	deleted: z.boolean(),
	role: ServerGcpRoleSchema,
	project: ServerGcpProjectSchema,
})

export type ServerGcpServiceAccountProjectRole = z.infer<typeof GcpServiceAccountProjectRoleSchema>

export const ServerGcpServiceAccountDemoDomainWideDelegationScopeSchema = z.object({
	id: z.string(),
	literal_name: z.string(),
	friendly_name: z.string().nullish(),
	tag: TagSchema,
})

export type ServerGcpServiceAccountDemoDomainWideDelegationScope = z.infer<
	typeof ServerGcpServiceAccountDemoDomainWideDelegationScopeSchema
>

export const ServerGcpServiceAccountDemoDomainWideDelegationSchema = z.object({
	id: z.string(),
	google_workspace_env_id: z.string(),
	google_workspace_env_name: z.string(),
	priority: z.number().nullish(),
	scopes: z.array(ServerGcpServiceAccountDemoDomainWideDelegationScopeSchema),
})

export type ServerGcpServiceAccountDemoDomainWideDelegation = z.infer<
	typeof ServerGcpServiceAccountDemoDomainWideDelegationSchema
>

export enum GcpResourceType {
	COMPUTER_INSTANCE = 'compute_instance',
	SQL_INSTANCE = 'sql_instance',
	CONTAINER_CLUSTER = 'container_cluster',
	RUN_SERVICES = 'run_services',
	RUN_JOBS = 'run_jobs',
	FUNCTIONS = 'functions',
	PUBSUB_SUBSCRIPTIONS = 'pubsub_subscriptions',
	CLOUD_SCHEDULER_JOBS = 'cloud_scheduler_jobs',
	DATA_FLOW_JOBS = 'data_flow_jobs',
}

export const GcpResourceTypeIconMap: Record<GcpResourceType, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
	[GcpResourceType.COMPUTER_INSTANCE]: GceIcon,
	[GcpResourceType.SQL_INSTANCE]: SqlIcon,
	[GcpResourceType.CONTAINER_CLUSTER]: GkeIcon,
	[GcpResourceType.RUN_SERVICES]: RunIcon,
	[GcpResourceType.RUN_JOBS]: RunIcon,
	[GcpResourceType.FUNCTIONS]: FunctionIcon,
	[GcpResourceType.PUBSUB_SUBSCRIPTIONS]: PubSubIcon,
	[GcpResourceType.CLOUD_SCHEDULER_JOBS]: CloudSchedulerIcon,
	[GcpResourceType.DATA_FLOW_JOBS]: DataflowIcon,
}

export const GcpResourceTypeMap: Record<GcpResourceType, string> = {
	[GcpResourceType.COMPUTER_INSTANCE]: 'Compute Instance',
	[GcpResourceType.SQL_INSTANCE]: 'SQL Instance',
	[GcpResourceType.CONTAINER_CLUSTER]: 'Container Cluster',
	[GcpResourceType.RUN_SERVICES]: 'Run Services',
	[GcpResourceType.RUN_JOBS]: 'Run Jobs',
	[GcpResourceType.FUNCTIONS]: 'Functions',
	[GcpResourceType.PUBSUB_SUBSCRIPTIONS]: 'PubSub Subscriptions',
	[GcpResourceType.CLOUD_SCHEDULER_JOBS]: 'Cloud Scheduler Jobs',
	[GcpResourceType.DATA_FLOW_JOBS]: 'Dataflow Jobs',
}

export const GcpServiceAccountResourceData = z.object({
	id: z.string(),
	type: z.nativeEnum(GcpResourceType),
	project_id: z.string(),
	name: z.string().nullish(),
	state: z.string().nullish(),
	private_ip: z.string().nullish(),
	tags: z.record(z.string()).nullable(),
	creation_time: DateSchema.nullish(),
})

export type ServerGcpServiceAccountResourceData = z.infer<typeof GcpServiceAccountResourceData>

export const GCPPermissionUsageSchema = z.object({
	permission: z.string(),
	last_used_time: DateSchema,
})

export type GCPPermissionUsage = z.infer<typeof GCPPermissionUsageSchema>

export const ServerGcpServiceAccountSchema = z.object({
	name: z.string().nullish(),
	disabled: z.boolean().nullish(),
	environment_id: z.string(),
	unique_id: z.string(),
	email: z.string(),
	service_account_keys_xc: GcpServiceAccountKeySchema.array().nullish(),
	last_activity: DateSchema.nullish(),
	gcp_project: ServerGcpProjectSchema.nullish(),
	service_account_projects_roles_xc: z.array(ServerGcpPrincipalInfoXcSchema).nullish(),
	endpoint_access_xc: z.array(ServerEndpointAccessXcSchema).nullish(),
	usage_logs: z.array(ServerGCPUserUsageLogSchema).nullish(),
	change_logs: z.array(ServerGCPUserChangeLogsSchema).nullish(),
	kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
	role_mappings: z.array(GcpServiceAccountProjectRoleSchema),
	permissions_usage: z.array(GCPPermissionUsageSchema).default([]),
	service_account_resources_xc: z.array(GcpServiceAccountResourceData).nullish(),
	demo_google_workspace_domain_wide_delegation: z
		.array(ServerGcpServiceAccountDemoDomainWideDelegationSchema)
		.nullish(),
})

export type ServerGcpServiceAccount = z.infer<typeof ServerGcpServiceAccountSchema>

export interface RoleWithProjects {
	role: ServerGcpRole
	projects: ServerGcpServiceAccountProjectRole[]
}
