import React, { ReactNode } from 'react'
import { useOnboarding } from '../api/onboarding'
import { Button } from 'antd'
import { OnboardingStateType } from '../schemas/onboardingState'
import EmptyStateAddIntegrationsAnimation from '../assets/EmptyStateAddIntegrations.svg?react'
import TokenLogoAnimationOnWhiteScreen from '../assets/TokenLogoAnimationOnWhiteScreen.gif'
import EmptyStateFailedCollection from '../assets/EmptyStateFailedCollection.svg?react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { RoutePaths } from '../routes/RoutePaths'
import { Link } from '@tanstack/react-router'
import { useEntitlementToShowOnboardingState } from '../services/auth/featureFlags'
import { isDemo } from '../utils/demoUtils'
import cx from 'classnames'

interface StateContainerProps {
	children: ReactNode
	increaseGap?: boolean
}

const StateContainer: React.FC<StateContainerProps> = ({ children, increaseGap = false }) => {
	return (
		<div
			className={cx(
				'flex flex-col items-center bg-white border border-border-primary rounded-lg justify-center h-full',
				{
					'gap-8': increaseGap,
				},
			)}
		>
			{children}
		</div>
	)
}

interface OnboardingStateRendererProps {
	children: ReactNode
}

const WelcomeState: React.FC = () => {
	return (
		<StateContainer increaseGap>
			<EmptyStateAddIntegrationsAnimation />
			<div className="flex flex-col items-center text-center">
				<p className=" text-3xl mb-3">Welcome to Token !</p>
				<p className="text-textIcon-secondary text-lg">
					Begin your journey by integrating your
					<br />
					environments with our platform.
				</p>
			</div>
			<Button type="primary" icon={<PlusOutlined />}>
				<Link to={RoutePaths.Integrations}>Add Integration</Link>
			</Button>
		</StateContainer>
	)
}

const DataCollectionState: React.FC = () => {
	return (
		<StateContainer>
			<div className="w-32 h-32 mb-8 p-4 ">
				<img src={TokenLogoAnimationOnWhiteScreen} />
			</div>
			<p className=" text-xl text-center">
				We're running the first data collection.
				<br />
				Everything will be visible on the platform shortly.
			</p>
		</StateContainer>
	)
}

const DataCollectionFailedState: React.FC = () => {
	return (
		<StateContainer>
			<div className="mb-8 ">
				<EmptyStateFailedCollection />
			</div>
			<div className="text-center">
				<p className=" text-xl">Oops... Something went wrong</p>
				<p className=" text-textIcon-secondary text-lg">
					We're working on fixing the issue.
					<br />
					Please try again later and if the error persists, contact us.
				</p>
			</div>
		</StateContainer>
	)
}

export const OnboardingStateRenderer: React.FC<OnboardingStateRendererProps> = ({ children }) => {
	const { isEntitled: isEntitledToShowOnboardingState } = useEntitlementToShowOnboardingState()
	if (isDemo || !isEntitledToShowOnboardingState) {
		return children
	}

	return <InnerOnboardingStateRenderer>{children}</InnerOnboardingStateRenderer>
}

const InnerOnboardingStateRenderer: React.FC<OnboardingStateRendererProps> = ({ children }) => {
	const { data: onboardingState, isPending, isError } = useOnboarding()

	if (isPending) {
		return (
			<div className="flex flex-col items-center justify-center h-full">
				<LoadingOutlined className="text-textIcon-primary text-4xl" />
			</div>
		)
	}

	if (isError) {
		return children
	}

	switch (onboardingState.status) {
		case OnboardingStateType.PENDING:
			return <WelcomeState />
		case OnboardingStateType.INTEGRATING:
			return <DataCollectionState />
		case OnboardingStateType.FAILED:
			return <DataCollectionFailedState />
		case OnboardingStateType.INTEGRATED:
			return children
		default:
			return children
	}
}
