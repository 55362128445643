import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import GoogleWorkspaceIcon from '../../../../assets/google_workspace_logo_16.svg?react'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'
import { ServerGoogleWorkspaceEnvironment } from '../../../../schemas/environments/googleWorkspaceEnvironment.ts'
import { defaultGoogleWorkspaceCustomerId } from '../configurations/GoogleWorkspaceIntegration.tsx'

type GoogleWorkspaceSinglePageIntegrationBodyProps = {
	googleWorkspaceEnvironments: ServerGoogleWorkspaceEnvironment[]
}

export const GoogleWorkspaceSinglePageIntegrationBody: React.FC<GoogleWorkspaceSinglePageIntegrationBodyProps> = ({
	googleWorkspaceEnvironments,
}) => {
	return (
		<IntegrationsSection>
			{googleWorkspaceEnvironments?.map((environment) => {
				const cardConfig = {
					title: environment.name,
					logo: <IntegrationIcon leftIcon={GoogleWorkspaceIcon} />,
					fields: [
						{ label: 'Customer ID', value: environment.gw_customer_id ?? defaultGoogleWorkspaceCustomerId },
						{ label: 'Subject', value: environment.subject },
					],
				}
				return (
					<SingleIntegrationGenericCard
						key={environment.id}
						title={cardConfig.title}
						logo={cardConfig.logo}
						fields={cardConfig.fields}
					/>
				)
			})}
		</IntegrationsSection>
	)
}
