import { UserOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { BaseNode, BaseNodeProps } from './BaseNode'
import { nodeColorClassNames } from '../../graphUtils/nodeColors'
import { ComponentType, ReactNode } from 'react'
import { NodeLabelProps } from '../NodeLabel.tsx'

type BaseIdentityNodeProps = {
	isMainIdentity?: boolean
	name?: BaseNodeProps['name']
	labelProps: NodeLabelProps | Array<NodeLabelProps>
	isHighlighted?: boolean
	children?: ReactNode
	Icon?: ComponentType<{ className?: string }>
}

export const BaseIdentityNode = ({
	name,
	labelProps,
	isMainIdentity,
	isHighlighted,
	children,
	Icon,
}: BaseIdentityNodeProps) => {
	const iconClassName = cx('text-2xl', { '-rotate-45': !!isMainIdentity })
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.identity.bgHover} ${nodeColorClassNames.identity.text} transition-colors duration-300 w-[50px] h-[50px] flex justify-center items-center `,
		{
			[nodeColorClassNames.identity.bg]: !isHighlighted,
			[nodeColorClassNames.identity.bgHighlighted]: isHighlighted,
			'rotate-45 rounded-lg': !!isMainIdentity,
			'rounded-full': !isMainIdentity,
		},
	)

	return (
		<BaseNode name={name} labelProps={labelProps}>
			{children}
			<div className={iconWrapperClassName}>
				{Icon ? <Icon className={iconClassName} /> : <UserOutlined className={iconClassName} />}
			</div>
		</BaseNode>
	)
}
