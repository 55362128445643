import { EnvironmentType } from '../../schemas/envType'
import { IdentitySource } from '../../schemas/identity'
import { IssueName } from '../../schemas/issue'
import { isDemo } from '../../utils/demoUtils.ts'

export interface RemediationStep {
	title: string
	content: string
}

type EnvironmentTypeMap<T> = Partial<Record<EnvironmentType, T>>
type IssueNameMap<T> = Partial<Record<IssueName, T>>
type IdentitySourceMap<T> = Partial<Record<IdentitySource, T>>
type RemediationStepsMap = EnvironmentTypeMap<IssueNameMap<IdentitySourceMap<RemediationStep[] | null>>>

export const issuesRemediationStepsMap: RemediationStepsMap = {
	[EnvironmentType.AWS]: {
		[IssueName.InactiveAccessKey]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Navigate to the IAM Console',
					content: `\
1.	Sign in to the AWS Management Console.
2.	Go to the IAM dashboard.`,
				},
				{
					title: 'Select Users',
					content: `\
1.	Click on “Users” in the navigation pane.`,
				},
				{
					title: 'Choose the IAM User',
					content: `\
1.	Select the user whose access keys you want to manage.`,
				},
				{
					title: 'Go to the Security Credentials Tab',
					content: `\
1.	Click on the “Security credentials” tab.`,
				},
				{
					title: 'Manage Access Keys',
					content: `\
1.	In the “Access keys” section, find the access key you want to disable.
2.	Under the “Status” column, you will see the option to make the key “Inactive”. Click the button to disable the key.`,
				},
			],
		},
		[IssueName.EKSAccessKeyReferencedInPod]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Create an IAM Policy',
					content: `First, create an IAM policy that grants the necessary permissions to the service account.
1. Create the IAM Policy
\`\`\`json
{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": [
        "s3:ListBucket",
        "s3:GetObject"
      ],
      "Resource": [
        "arn:aws:s3:::your-bucket-name",
        "arn:aws:s3:::your-bucket-name/*"
      ]
    }
  ]
}
\`\`\`
2. Create the policy in AWS IAM
\`\`\`bash
aws iam create-policy --policy-name EKSServiceAccountPolicy --policy-document file://eks-policy.json
\`\`\`
`,
				},
				{
					title: 'Create an IAM Role and Associate it with the Policy',
					content: `Create an IAM role and associate it with the policy you just created. The role will be used by the EKS service account.
1.	Create a trust relationship file (trust-relationship.json):
\`\`\`json
{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Principal": {
        "Federated": "arn:aws:iam::<AWS_ACCOUNT_ID>:oidc-provider/<OIDC_PROVIDER>"
      },
      "Action": "sts:AssumeRoleWithWebIdentity",
      "Condition": {
        "StringEquals": {
          "<OIDC_PROVIDER>:sub": "system:serviceaccount:<NAMESPACE>:<SERVICE_ACCOUNT_NAME>"
        }
      }
    }
  ]
}
\`\`\`
2. Create the IAM Role
\`\`\`bash
aws iam create-role --role-name EKSServiceAccountRole --assume-role-policy-document file://trust-relationship.json
\`\`\`
3. Attach the IAM Policy to the role
\`\`\`bash
aws iam attach-role-policy --role-name EKSServiceAccountRole --policy-arn arn:aws:iam::<AWS_ACCOUNT_ID>:policy/EKSServiceAccountPolicy
\`\`\`
`,
				},
				{
					title: 'Create a Kubernetes Service Account',
					content: `Create a Kubernetes service account and associate it with the IAM role.
1. Annotate the Service Account with the IAM Role
\`\`\`yaml
apiVersion: v1
kind: ServiceAccount
metadata:
  name: <SERVICE_ACCOUNT_NAME>
  namespace: <NAMESPACE>
  annotations:
	eks.amazonaws.com/role-arn: arn:aws:iam::<AWS_ACCOUNT_ID>:role/EKSServiceAccountRole
\`\`\`
2. Apply the Service Account YAML
\`\`\`bash
kubectl apply -f service-account.yaml
\`\`\`
`,
				},
				{
					title: 'Update Your Existing YAML Using the Service Account',
					content: `Update your existing YAML to use the service account you just created.
1. Update the YAML Spec. This is an example of a pod spec using the service account:
\`\`\`yaml
apiVersion: v1
kind: Pod
metadata:
  name: my-pod
  namespace: default
spec:
  serviceAccountName: <SERVICE_ACCOUNT_NAME> # <--- Add this line
  containers:
  - name: my-container
    image: nginx
\`\`\`
2. Apply the Updated YAML
\`\`\`bash
kubectl apply -f pod.yaml
\`\`\`

By following these steps, you will have created a service account in Kubernetes, associated it with an IAM role in AWS, and used it in a pod on your EKS cluster.
`,
				},
			],
		},
		[IssueName.AccessKeyNotRotated]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Create a New Access Key',
					content: `\
1.	Sign in to the AWS Management Console.
2.	Open the IAM console at https://console.aws.amazon.com/iam/.
3.	In the navigation pane, choose Users.
4.	Choose the user.
5.	Choose the Security credentials tab.
6.	In the Access keys section, choose Create access key.
7.	Note down the Access key ID and Secret access key. Download the .csv file if provided.`,
				},
				{
					title: 'Update Applications/Scripts with the New Access Key',
					content: `Update your applications, scripts, or environments to use the new access key and secret access key. This step depends on where and how the keys are used. Ensure all references to the old keys are replaced with the new keys.`,
				},
				{
					title: 'Verify the New Access Key',
					content:
						'Test your applications and scripts to ensure they work correctly with the new access key. This is a critical step to ensure there is no downtime or disruption in services.',
				},
				{
					title: 'Delete the Old Access Key',
					content: `\
1.	Return to the IAM console.
2.	In the Access keys section, locate the old access key.
3.	Choose Make inactive to ensure that it is no longer in use.
4.	After confirming that the new access key is working correctly and there are no issues, choose Delete next to the old access key.`,
				},
			],
		},
		[IssueName.InactiveIdentity]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Sign in to the AWS Management Console',
					content: 'Go to the IAM Console.',
				},
				{
					title: 'Navigate to Users',
					content: 'In the navigation pane, choose "Users".',
				},
				{
					title: 'Select the User',
					content: 'Find the user you want to delete and click on their name to open the user details page.',
				},
				{
					title: 'Remove Access Keys',
					content: `Go to the "Security credentials” tab.
Under "Access keys”, find each access key associated with the user and click "Delete” to remove them.`,
				},
				{
					title: 'Delete the User',
					content: `After removing all access keys, go back to the "Users” list.
Select the user again and choose the "Delete user” button.
Confirm the deletion in the confirmation dialog.`,
				},
			],
			[IdentitySource.AWS_IAM_ROLE]: [
				{
					title: 'Sign in to the AWS Management Console',
					content: 'Go to the IAM Console.',
				},
				{
					title: 'Navigate to Roles',
					content: 'In the navigation pane, choose "Roles".',
				},
				{
					title: 'Select the Role',
					content: 'Find the role you want to delete and click on its name to open the role details page.',
				},
				{
					title: 'Detach Attached Policies',
					content: `Go to the "Permissions” tab.
Under "Managed policies”, click "Detach policy” for each attached policy to remove them.`,
				},
				{
					title: 'Remove Inline Policies',
					content: `Still under the "Permissions” tab, scroll down to "Inline policies”.
For each inline policy, click "Remove" to delete them.`,
				},
				{
					title: 'Update Trust Relationships',
					content: `Navigate to the "Trust relationships” tab.
Click "Edit trust relationship” and ensure that no services or accounts are trusting this role.
If necessary, modify the trust policy to remove any trusted entities.`,
				},
				{
					title: 'Delete the Role',
					content: `After detaching all policies and updating trust relationships, return to the "Roles” list.
Select the role again and choose the "Delete role” button.
Confirm the deletion in the confirmation dialog.`,
				},
			],
		},
		[IssueName.CloudtrailIamUserUsedFromEndpoint]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Identify the User and Access Key',
					content: `1. Sign in to the AWS Management Console.
2. Open the IAM console at https://console.aws.amazon.com/iam/.
3. In the navigation pane, choose "Users."
4. Select the user associated with the reported access key.`,
				},
				{
					title: 'Investigate the Security Incident',
					content: `1. Review the recent activity for the user to identify any unauthorized or suspicious actions.
2. Check the endpoint from which the access key was used to ensure it is not compromised. Perform a security scan and review logs for anomalies.`,
				},
				{
					title: 'Create a New Access Key',
					content: `1. In the "Security credentials" tab, choose "Create access key."
2. Note down the Access key ID and Secret access key. Download the .csv file if provided.`,
				},
				{
					title: 'Update Applications and Systems with the New Access Key',
					content: `1. Update your applications, scripts, or environments to use the new access key and secret access key. This step is critical and should be done carefully to ensure no service disruption.
2. Ensure all references to the old keys are replaced with the new keys.`,
				},
				{
					title: 'Verify the New Access Key',
					content: `1. Test your applications and scripts to ensure they work correctly with the new access key.
2. Ensure there is no downtime or disruption in services.`,
				},
				{
					title: 'Deactivate the Old Access Key',
					content: `1. Return to the "Security credentials" tab.
2. In the "Access keys" section, locate the old access key.
3. Choose "Make inactive" to ensure that it is no longer in use.`,
				},
				{
					title: 'Delete the Old Access Key',
					content: `1. After confirming the new access key is working correctly and there are no issues, return to the "Access keys" section.
2. Choose "Delete" next to the old access key to permanently remove it.`,
				},
				{
					title: 'Update Security Policies',
					content: `1. Ensure that new access keys are not stored on insecure endpoints.
2. Implement IAM roles with temporary credentials for tasks that do not require long-term credentials.
3. Enforce strict access controls and regularly review permissions to ensure that only necessary and active accounts have access.
4. Use AWS Secrets Manager or AWS Systems Manager Parameter Store to securely manage and rotate access keys.
5. Implement network restrictions to prevent the usage of IAM user access keys from non-approved IP addresses, such as corporate VPN IP addresses only.`,
				},
			],
		},
		[IssueName.MissingMFA]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Sign in to the AWS Management Console',
					content:
						'1. Open the AWS Management Console at https://console.aws.amazon.com/.\n' +
						'2. In the navigation pane, choose "IAM."',
				},
				{
					title: 'Navigate to Users',
					content:
						'1. In the IAM dashboard, choose "Users" from the navigation pane.\n' +
						'2. Select the user who does not have MFA enabled.',
				},
				{
					title: 'Enable MFA for the IAM User',
					content:
						'1. On the user details page, go to the "Security credentials" tab.\n' +
						'2. In the "Multi-factor authentication (MFA)" section, choose "Manage."',
				},
				{
					title: 'Choose the MFA Device Type',
					content:
						'1. Choose the type of MFA device you want to use: \n' +
						'\t- **Virtual MFA device**: For smartphone apps like Google Authenticator or Authy.\n' +
						`\t- **U2F security key**: For hardware security keys like YubiKey.\n` +
						`\t- **Hardware MFA device**: For dedicated hardware MFA devices. \n` +
						`2. Follow the prompts to configure the chosen MFA device. \n`,
				},
				{
					title: 'Configure the Virtual MFA Device (if applicable)',
					content:
						'1. If using a virtual MFA device, scan the QR code with your MFA app.\n' +
						'2. Enter two consecutive MFA codes from the app to complete the setup.',
				},
				{
					title: 'Verify and Save',
					content:
						'1. After entering the MFA codes, verify that MFA is enabled.\n' +
						'2. Save the changes to complete the MFA setup.',
				},
				{
					title: `Test the MFA Configuration`,
					content:
						`1. Sign out of the AWS Management Console.\n` +
						`2. Sign back in as the IAM user.\n` +
						`3. Verify that you are prompted for an MFA code during the sign-in process.`,
				},
				{
					title: 'Implement Security Policies',
					content:
						`1. Enforce MFA for all IAM users within the organization.\n` +
						`2. Regularly review and audit IAM user accounts to ensure MFA compliance.\n` +
						`3. Use AWS IAM policies to mandate MFA usage for accessing sensitive resources.`,
				},
			],
		},
		[IssueName.PasswordRotation]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Sign in to the AWS Management Console',
					content: `
1. Open the AWS Management Console at https://console.aws.amazon.com/.
2. In the navigation pane, choose "IAM."
`,
				},
				{
					title: 'Navigate to Users',
					content: `
1. In the IAM dashboard, choose "Users" from the navigation pane.
2. Select the user who has not rotated their password in more than 90 days.
`,
				},
				{
					title: "Change the User's Password",
					content: `
1. On the user details page, go to the "Security credentials" tab.
2. In the "Password" section, choose "Manage."
3. Select the option to reset the password:
    - Generate a new password automatically, or
    - Provide a new password manually.
`,
				},
				{
					title: 'Notify the User',
					content: `
1. Inform the user of the password change and provide them with the new password if it was set manually.
2. Instruct the user to log in and change the password to one of their choice immediately.
`,
				},
				{
					title: 'User Changes the Password',
					content: `
1. The user should sign in to the AWS Management Console.
2. The user should navigate to "My Security Credentials."
3. Under the "Password" section, choose "Change password."
4. The user should enter the current password (provided in step 4) and then enter a new, strong password.
`,
				},
				{
					title: 'Implement a Password Policy',
					content: `
1. Open the IAM console at https://console.aws.amazon.com/iam/.
2. In the navigation pane, choose "Account settings."
3. Under "Password policy," select "Edit password policy."
4. Set the password policy to enforce regular password rotation:
    - Require users to change their password every 90 days.
    - Enable password expiration and require administrative reset.
    - Set a minimum password length.
    - Require specific character types (uppercase, lowercase, numbers, and symbols).
    - Prevent password reuse.
`,
				},
				{
					title: 'Communicate the New Policy',
					content: `
1. Inform all IAM users of the new password policy.
2. Provide guidelines on creating strong passwords and the importance of regular password rotation.
`,
				},
				{
					title: 'Regularly Review Password Rotation Compliance',
					content: `
1. Set up regular audits to ensure all users comply with the password rotation policy.
2. Use AWS Config rules or IAM reports to monitor password rotation status.
`,
				},
			],
		},
		[IssueName.UnFederatedIdentity]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Create a Federated User',
					content: `
1. Set up federated access using your organization's identity provider (IdP). This could be AWS Single Sign-On (SSO), Active Directory, or another SAML-compliant IdP.
2. Ensure the federated user has the necessary permissions to access AWS resources by assigning appropriate roles and policies.
`,
				},
				{
					title: 'Configure AWS SSO or Federated Access',
					content: `
1. Open the AWS SSO console at https://console.aws.amazon.com/singlesignon/.
2. Configure AWS SSO with your identity provider if it is not already configured.
3. Create user groups and assign them to the necessary AWS accounts and permission sets.
4. Add the user to the appropriate group to grant them the necessary permissions.
`,
				},
				{
					title: 'Notify the User',
					content: `
1. Inform the user about the migration to federated access.
2. Provide instructions on how to access the AWS Management Console using federated login via the organization's identity provider.
`,
				},
				{
					title: 'Verify Federated Access',
					content: `
1. Have the user log in through the federated access method to ensure it is working correctly.
2. Confirm that the user can access the necessary AWS resources without issues.
`,
				},
				{
					title: 'Sign in to the AWS Management Console',
					content: `
1. Open the AWS Management Console at https://console.aws.amazon.com/.
2. In the navigation pane, choose "IAM."
`,
				},
				{
					title: 'Navigate to Users',
					content: `
1. In the IAM dashboard, choose "Users" from the navigation pane.
2. Select the user with console access.
`,
				},
				{
					title: 'Disable Console Access for the IAM User',
					content: `
1. On the user details page, go to the "Security credentials" tab.
2. In the "Console password" section, choose "Manage."
3. Select the option to disable console access for the user.
`,
				},
				{
					title: 'Remove Existing IAM User Access',
					content: `
1. If the IAM user has any active access keys, follow the steps to deactivate and delete them:
    - Go to the "Security credentials" tab.
    - In the "Access keys" section, choose "Make inactive" for each access key.
    - After confirming that federated access is working, choose "Delete" next to each access key.
2. Revoke any other permissions or policies assigned directly to the IAM user.
`,
				},
				{
					title: 'Implement Security Policies',
					content: `
1. Enforce the policy to avoid creating IAM users with console access.
2. Regularly review and audit IAM user accounts to ensure compliance with the federated access policy.
3. Use AWS Config rules or IAM Access Analyzer to monitor and detect IAM users with console access.
`,
				},
			],
			[IdentitySource.POSTGRES_ROLE]: [
				{
					title: 'Review the Existing Role',
					content: `1. Sign in to the AWS Management Console.
2. Open the RDS console at https://console.aws.amazon.com/rds/.
3. In the navigation pane, choose "Databases."
4. Select the RDS instance where the unfederated role is detected.
5. Connect to the RDS instance using a suitable SQL client.`,
				},
				{
					title: 'Create a New IAM Role for RDS Access',
					content: `1. Open the IAM console at https://console.aws.amazon.com/iam/.
2. In the navigation pane, choose "Roles," and then choose "Create role."
3. Select "AWS service" and then "RDS."
4. Choose "RDS - Add Role to Database."
5. Follow the prompts to create a new IAM role with the necessary permissions for accessing the RDS instance.
6. Attach the policy \`AmazonRDSFullAccess\` or create a custom policy with specific permissions required for the database role.`,
				},
				{
					title: 'Attach the IAM Role to the RDS Instance',
					content: `1. Return to the RDS console.
2. In the "Databases" section, select the RDS instance.
3. Choose "Modify."
4. In the "Database options" section, under "IAM DB authentication," select the newly created IAM role.
5. Save the changes and apply them immediately or during the next maintenance window.`,
				},
				{
					title: 'Migrate the Unfederated Role to Use IAM Authentication',
					content: `1. Connect to the RDS instance using a suitable SQL client.
2. Create a new user or update the existing unfederated role to use IAM authentication:
\`\`\`sql
CREATE USER new_db_role WITH LOGIN;
GRANT rds_iam TO new_db_role;
\`\`\`
3. Transfer the necessary permissions from the old unfederated role to the new IAM-authenticated role:
\`\`\`sql
GRANT SELECT, INSERT, UPDATE, DELETE ON ALL TABLES IN SCHEMA public TO new_db_role;
\`\`\`
`,
				},
				{
					title: 'Update Applications and Services',
					content: `1. Update your applications and services to use the new IAM-authenticated role.
2. Ensure that the connection strings and configurations are updated to support IAM database authentication:
    - For example, in JDBC:
\`\`\`bash
jdbc:postgresql://<your-db-instance>.rds.amazonaws.com:5432/<your-db-name>?ssl=true&sslfactory=org.postgresql.ssl.NonValidatingFactory&user=new_db_role

\`\`\`
	`,
				},
				{
					title: 'Verify the New IAM-Authenticated Role',
					content: `1. Test your applications and services to ensure they work correctly with the new IAM-authenticated role.
2. Confirm there is no downtime or disruption in services.`,
				},
				{
					title: 'Deactivate the Old Unfederated Role',
					content: `1. Connect to the RDS instance using a suitable SQL client.
2. Revoke all permissions from the old unfederated role:
\`\`\`sql
REVOKE ALL PRIVILEGES ON ALL TABLES IN SCHEMA public FROM old_db_role;
\`\`\`
`,
				},
				{
					title: 'Remove the Old Unfederated Role',
					content: `1. After verifying that the new IAM-authenticated role is functioning correctly, drop the old unfederated role:
\`\`\`sql
DROP ROLE old_db_role;
\`\`\``,
				},
				{
					title: 'Implement Security Policies',
					content: `1. Ensure that all database roles use IAM authentication for secure access management.
2. Regularly review and audit database roles and permissions.
3. Implement automated monitoring and alerts for any roles not using IAM authentication.
4. Enforce the use of IAM roles for database access and restrict traditional username/password logins.`,
				},
			],
		},
		[IssueName.MultipleAccessKeys]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Sign in to the AWS Management Console',
					content: `
1. Open the AWS Management Console at https://console.aws.amazon.com/.
2. In the navigation pane, choose "IAM."
`,
				},
				{
					title: 'Navigate to Users',
					content: `
1. In the IAM dashboard, choose "Users" from the navigation pane.
2. Select the user who has multiple active access keys.
`,
				},
				{
					title: 'Review and Identify Active Access Keys',
					content: `
1. On the user details page, go to the "Security credentials" tab.
2. In the "Access keys" section, identify all active access keys for the user.
`,
				},
				{
					title: 'Determine Key Usage',
					content: `
1. Review the usage of each active access key to determine which key is currently in use and necessary for operations.
2. Identify any access keys that are no longer needed or have been inactive.
`,
				},
				{
					title: 'Move All Usage to One Access Key',
					content: `
1. Choose one access key to keep active.
2. Update your applications, scripts, or environments to use this key exclusively.
3. Ensure all references to the other keys are replaced with this key.
4. Verify that the updated configuration works correctly and that there is no downtime or disruption in services.
`,
				},
				{
					title: 'Verify the New Configuration',
					content: `
1. Test your applications and scripts to ensure they work correctly with the active access key.
2. Confirm there is no downtime or disruption in services.
`,
				},
				{
					title: 'Deactivate and Delete the Other Access Keys',
					content: `
1. Return to the "Security credentials" tab.
2. In the "Access keys" section, for each access key that should no longer be active:
    - Choose "Make inactive" to ensure that it is no longer in use.
    - After confirming the active access key is working correctly and there are no issues, choose "Delete" next to the inactive access key to permanently remove it.
`,
				},
				{
					title: 'Ensure a Single Active Access Key',
					content: `
1. Confirm that the IAM user now has only one active access key.
`,
				},
			],
		},
		[IssueName.ShadowAdmin]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Review Finding Details',
					content: `
1. Examine the finding details provided.
2. Identify the specific API permissions that allow privilege escalation.
3. Note down all problematic IAM calls mentioned in the description.
`,
				},
				{
					title: 'Determine Service Account Needs',
					content: `
1. For each problematic IAM call, determine the precise needs of the service account:
   - Identify target resources required.
   - Consider any IAM policy constraints that can be applied. For permissions like iam:PassRole, specify only the roles that need to be passed.
`,
				},
				{
					title: 'Modify IAM Policy',
					content: `
1. Navigate to the IAM service in the AWS portal or access your organization's Infrastructure as Code (IaC) system, depending on how the identity is managed.
2. Locate the account's IAM policy.
3. Edit the policy to limit the problematic permissions:
   - Apply the constraints determined in the previous step.
   - Ensure the policy only grants the minimum necessary permissions.
4. If using the AWS portal:
   - Go to the user's policies in the IAM service.
   - Make the necessary changes directly in the policy editor.
5. If using IaC:
   - Edit the account's definitions in your IaC files.
   - Follow your organization's process for reviewing and applying IaC changes.
6. Save and apply the changes.
`,
				},
			],
		},
		[IssueName.ServiceAccountSuspiciousBehavior]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Review Suspicious Activity',
					content: `
1. Review the suspicious activity in the usage tab to assess whether it is malicious or not.
2. If the access key is determined to be possibly compromised, continue to the next steps.
`,
				},
				{
					title: 'Rotate and Delete Compromised Access Key',
					content: `
1. Rotate the suspected compromised access key.
2. Delete the old, potentially compromised access key.
`,
				},
				{
					title: 'Delete Resources Created During Suspicious Activity',
					content: `
1. Identify resources created as part of the suspicious activity.
2. Delete these resources to prevent further unauthorized access or actions.
`,
				},
				{
					title: 'Recover and Revert Changes',
					content: `
1. Identify changes made to other resources as part of the suspicious activity.
2. Recover and revert these changes to restore the affected resources to their proper state.
`,
				},
			],
		},
		[IssueName.UserUsedFromAttackOrPentestingFramework]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Review Suspicious Activity',
					content: `
	1. Review the suspicious activity in the usage tab to assess whether it is malicious or not.
	2. If the access key is determined to be possibly compromised, continue to the next steps.
	`,
				},
				{
					title: 'Rotate and Delete Compromised Access Key',
					content: `
	1. Rotate the suspected compromised access key.
	2. Delete the old, potentially compromised access key.
	`,
				},
				{
					title: 'Delete Resources Created During Suspicious Activity',
					content: `
	1. Identify resources created as part of the suspicious activity.
	2. Delete these resources to prevent further unauthorized access or actions.
	`,
				},
				{
					title: 'Recover and Revert Changes',
					content: `
	1. Identify changes made to other resources as part of the suspicious activity.
	2. Recover and revert these changes to restore the affected resources to their proper state.
	`,
				},
			],
		},
		[IssueName.CrossAccountAccess]: {
			[IdentitySource.AWS_IAM_ROLE]: [
				{
					title: 'Identify and Document the Vulnerable IAM Role',
					content: `
1. Locate the IAM role in the affected account and review its trust relationships.
2. Command:
   \`aws iam get-role --role-name <IAM_ROLE_NAME> --query 'Role.AssumeRolePolicyDocument'\`
`,
				},
				{
					title: 'Determine Unauthorized or Untrusted Accounts',
					content: `
1. Assess if the trust relationship includes accounts that should not have access based on your security hierarchy.
2. Confirm with the customer or security policy team if these accounts need to be excluded.
`,
				},
				{
					title: 'Remove the Unsafe Trust Relationship',
					content: `
1. Delete the untrusted account(s) from the trust relationship to eliminate unauthorized access.
2. Leave a placeholder for the new trusted accounts (if any are to be added).
3. Command:
   \`aws iam update-assume-role-policy --role-name <ROLE_NAME> --policy-document '<NEW_POLICY_DOCUMENT_PLACEHOLDER>'\`
`,
				},
				{
					title: 'Confirm the Changes to the Trust Policy',
					content: `
1. Verify that the IAM role’s trust relationship no longer includes unauthorized accounts.
2. Command:
   \`aws iam get-role --role-name <ROLE_NAME> --query 'Role.AssumeRolePolicyDocument'\`
`,
				},
				{
					title: 'Review Other IAM Roles for Similar Trust Relationships',
					content: `
1. Identify additional IAM roles with similar issues across all accounts to prevent privilege escalation risks.
2. Command:
   \`aws iam list-roles --query "Roles[?AssumeRolePolicyDocument.Statement[?Principal.AWS=='arn:aws:iam::<UNTRUSTED_ACCOUNT_ID>:root']].RoleName"\`
`,
				},
			],
		},
	},
	[EnvironmentType.RDS_POSTGRES]: {
		[IssueName.UnFederatedIdentity]: {
			[IdentitySource.POSTGRES_ROLE]: [
				{
					title: 'Review the Existing Role',
					content: `1. Sign in to the AWS Management Console.
2. Open the RDS console at https://console.aws.amazon.com/rds/.
3. In the navigation pane, choose "Databases."
4. Select the RDS instance where the unfederated role is detected.
5. Connect to the RDS instance using a suitable SQL client.`,
				},
				{
					title: 'Create a New IAM Role for RDS Access',
					content: `1. Open the IAM console at https://console.aws.amazon.com/iam/.
2. In the navigation pane, choose "Roles," and then choose "Create role."
3. Select "AWS service" and then "RDS."
4. Choose "RDS - Add Role to Database."
5. Follow the prompts to create a new IAM role with the necessary permissions for accessing the RDS instance.
6. Attach the policy \`AmazonRDSFullAccess\` or create a custom policy with specific permissions required for the database role.`,
				},
				{
					title: 'Attach the IAM Role to the RDS Instance',
					content: `1. Return to the RDS console.
2. In the "Databases" section, select the RDS instance.
3. Choose "Modify."
4. In the "Database options" section, under "IAM DB authentication," select the newly created IAM role.
5. Save the changes and apply them immediately or during the next maintenance window.`,
				},
				{
					title: 'Migrate the Unfederated Role to Use IAM Authentication',
					content: `1. Connect to the RDS instance using a suitable SQL client.
2. Create a new user or update the existing unfederated role to use IAM authentication:
\`\`\`sql
CREATE USER new_db_role WITH LOGIN;
GRANT rds_iam TO new_db_role;
\`\`\`
3. Transfer the necessary permissions from the old unfederated role to the new IAM-authenticated role:
\`\`\`sql
GRANT SELECT, INSERT, UPDATE, DELETE ON ALL TABLES IN SCHEMA public TO new_db_role;
\`\`\`
`,
				},
				{
					title: 'Update Applications and Services',
					content: `1. Update your applications and services to use the new IAM-authenticated role.
2. Ensure that the connection strings and configurations are updated to support IAM database authentication:
    - For example, in JDBC:
\`\`\`bash
jdbc:postgresql://<your-db-instance>.rds.amazonaws.com:5432/<your-db-name>?ssl=true&sslfactory=org.postgresql.ssl.NonValidatingFactory&user=new_db_role

\`\`\`
	`,
				},
				{
					title: 'Verify the New IAM-Authenticated Role',
					content: `1. Test your applications and services to ensure they work correctly with the new IAM-authenticated role.
2. Confirm there is no downtime or disruption in services.`,
				},
				{
					title: 'Deactivate the Old Unfederated Role',
					content: `1. Connect to the RDS instance using a suitable SQL client.
2. Revoke all permissions from the old unfederated role:
\`\`\`sql
REVOKE ALL PRIVILEGES ON ALL TABLES IN SCHEMA public FROM old_db_role;
\`\`\`
`,
				},
				{
					title: 'Remove the Old Unfederated Role',
					content: `1. After verifying that the new IAM-authenticated role is functioning correctly, drop the old unfederated role:
\`\`\`sql
DROP ROLE old_db_role;
\`\`\``,
				},
				{
					title: 'Implement Security Policies',
					content: `1. Ensure that all database roles use IAM authentication for secure access management.
2. Regularly review and audit database roles and permissions.
3. Implement automated monitoring and alerts for any roles not using IAM authentication.
4. Enforce the use of IAM roles for database access and restrict traditional username/password logins.`,
				},
			],
		},
		[IssueName.MultipleAccessKeys]: {
			[IdentitySource.AWS_IAM_USER]: [
				{
					title: 'Sign in to the AWS Management Console',
					content: `
1. Open the AWS Management Console at https://console.aws.amazon.com/.
2. In the navigation pane, choose "IAM."
`,
				},
				{
					title: 'Navigate to Users',
					content: `
1. In the IAM dashboard, choose "Users" from the navigation pane.
2. Select the user who has multiple active access keys.
`,
				},
				{
					title: 'Review and Identify Active Access Keys',
					content: `
1. On the user details page, go to the "Security credentials" tab.
2. In the "Access keys" section, identify all active access keys for the user.
`,
				},
				{
					title: 'Determine Key Usage',
					content: `
1. Review the usage of each active access key to determine which key is currently in use and necessary for operations.
2. Identify any access keys that are no longer needed or have been inactive.
`,
				},
				{
					title: 'Move All Usage to One Access Key',
					content: `
1. Choose one access key to keep active.
2. Update your applications, scripts, or environments to use this key exclusively.
3. Ensure all references to the other keys are replaced with this key.
4. Verify that the updated configuration works correctly and that there is no downtime or disruption in services.
`,
				},
				{
					title: 'Verify the New Configuration',
					content: `
1. Test your applications and scripts to ensure they work correctly with the active access key.
2. Confirm there is no downtime or disruption in services.
`,
				},
				{
					title: 'Deactivate and Delete the Other Access Keys',
					content: `
1. Return to the "Security credentials" tab.
2. In the "Access keys" section, for each access key that should no longer be active:
    - Choose "Make inactive" to ensure that it is no longer in use.
    - After confirming the active access key is working correctly and there are no issues, choose "Delete" next to the inactive access key to permanently remove it.
`,
				},
				{
					title: 'Ensure a Single Active Access Key',
					content: `
1. Confirm that the IAM user now has only one active access key.
`,
				},
			],
		},
	},
	[EnvironmentType.SNOWFLAKE]: {
		[IssueName.MissingNetworkPolicy]: {
			[IdentitySource.SNOWFLAKE_USER]: [
				{
					title: 'Sign in to the Snowflake Web Interface',
					content: `
1. Open the Snowflake web interface at https://<your-account-name>.snowflakecomputing.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Identify the Service Account',
					content: `
1. Navigate to the "Account" tab.
2. Go to the "Users" section and locate the service account that does not have a defined network policy.
`,
				},
				{
					title: 'Identify Network Sources',
					content: `
1. Review the access logs to identify the IP addresses or ranges from which the service account has been accessed.
    - Use the QUERY_HISTORY and LOGIN_HISTORY views to gather this information:

        \`\`\`sql
        SELECT DISTINCT CLIENT_IP
        FROM SNOWFLAKE.ACCOUNT_USAGE.QUERY_HISTORY
        WHERE USER_NAME = '<service_account_name>';

        SELECT DISTINCT CLIENT_IP
        FROM SNOWFLAKE.ACCOUNT_USAGE.LOGIN_HISTORY
        WHERE USER_NAME = '<service_account_name>';
        \`\`\`
`,
				},
				{
					title: 'Review Existing Network Policies',
					content: `
1. In the "Account" tab, go to the "Network Policies" section.
2. Review any existing network policies to understand their configurations and determine if an existing policy can be applied to the service account.
`,
				},
				{
					title: 'Create a New Network Policy (if needed)',
					content: `
1. If no existing network policy is suitable, create a new network policy:
    - Navigate to the "Network Policies" section.
    - Choose "Create Network Policy."
    - Define the allowed IP addresses or IP ranges identified in Step 3.
    - Example configuration:

        \`\`\`sql
        CREATE NETWORK POLICY secure_network_policy
        ALLOWED_IP_LIST = ('192.168.1.0/24', '203.0.113.0/24')
        BLOCKED_IP_LIST = ('0.0.0.0/0');
        \`\`\`
`,
				},
				{
					title: 'Apply the Network Policy to the Service Account',
					content: `
1. Assign the newly created or an existing network policy to the service account:
    - Navigate to the "Users" section.
    - Select the service account.
    - Apply the network policy:

        \`\`\`sql
        ALTER USER <service_account_name> SET NETWORK_POLICY = '<network_policy_name>';
        \`\`\`
`,
				},
				{
					title: 'Verify the Network Policy',
					content: `
1. Confirm that the network policy is correctly applied to the service account:
    - Check the user's properties to ensure the network policy is listed.
    - Test access from allowed and blocked IP addresses to verify the policy's effectiveness.
`,
				},
				{
					title: 'Monitor and Audit Network Policies',
					content: `
1. Regularly review and update network policies to ensure they meet current security requirements.
2. Implement monitoring to detect and alert on any unauthorized access attempts.
3. Periodically audit service accounts and their associated network policies to maintain compliance.
`,
				},
				{
					title: 'Communicate the Policy Requirements',
					content: `
1. Inform relevant stakeholders about the importance of network policies and ensure they understand how to apply and manage them.
2. Update internal documentation to include guidelines on creating and applying network policies in Snowflake.
`,
				},
				{
					title: 'Implement Security Best Practices',
					content: `
1. Enforce the use of network policies for all service accounts and users.
2. Use multi-factor authentication (MFA) and other security measures to further secure access to the Snowflake environment.
3. Regularly review and adjust network policies based on changes in the network architecture or security requirements.
`,
				},
			],
		},
		[IssueName.InactiveIdentity]: {
			[IdentitySource.SNOWFLAKE_USER]: [
				{
					title: 'Sign in to the Snowflake Admin Console',
					content: `
1. Go to Users & Roles under the Admin tab.
`,
				},
				{
					title: 'Locate the User to Deactivate',
					content: `
1. Under "Users", search for the user you wish to deactivate.`,
				},
				{
					title: 'Take action',
					content: `
Click the extra options button (three dots \`…\`) next to the relevant user to view available actions:

- **Delete**: extra options (\`…\`) → Drop.
- **Disable**: extra options (\`…\`) → Disable.
- **Limit role access**: extra options (\`…\`) → Revoke a Role → Choose Role to revoke.
`,
				},
			],
		},

		[IssueName.IdentityUsingWeakAuthentication]: {
			[IdentitySource.SNOWFLAKE_USER]: [
				{
					title: 'Generate an RSA key pair',
					content: `
1. Generate a private and public RSA key and store them securely.
\n
   For more details, see the [Snowflake documentation](https://docs.snowflake.com/en/user-guide/key-pair-auth#generate-the-private-key).
`,
				},
				{
					title: 'Assign the RSA public key to a user',
					content: `
1. Use this command to set the RSA Public key to the user:
\`\`\`sql
ALTER USER example_user SET RSA_PUBLIC_KEY=<public_RSA_key>;
\`\`\`
`,
				},
				{
					title: 'Replace Authentication Method',
					content: `
1. Click the extra options button (three dots \`…\`) next to the relevant service account to view available actions:

	**Change Authentication Method**: extra options (\`…\`) → Change Authentication Method.
`,
				},
				{
					title: 'Revoke the password',
					content: `
1. Revoke the password for the service account by using the following command:
\`\`\`sql
ALTER USER example_user UNSET PASSWORD;
\`\`\`
`,
				},
			],
		},

		[IssueName.PasswordRotation]: {
			[IdentitySource.SNOWFLAKE_USER]: [
				{
					title: 'Sign in to the Snowflake Admin Console',
					content: `
1. Go to Users & Roles under the Admin tab.
`,
				},
				{
					title: 'Identify users with un-rotated passwords',
					content: `
1. Locate the users who have not rotated their passwords as required.`,
				},
				{
					title: 'Reset the password',
					content: `
1. Click the extra options button (three dots \`…\`) next to the relevant user to view available actions:

	- **Force Password Reset**: extra options (\`…\`) → Reset Password.
	- **Notify User**: Contact the user to inform them about the need to change their password.
`,
				},
			],
		},
		[IssueName.IDPBypass]: {
			[IdentitySource.SNOWFLAKE_USER]: [
				{
					title: 'Sign in to the Snowflake Admin Console',
					content: `
1. Go to Users & Roles under the Admin tab.
`,
				},
				{
					title: 'Locate the relevant user',
					content: `
1. Find the user who has both SSO access through an IDP and a password..`,
				},
				{
					title: 'Revoke password',
					content: `
1. Click the extra options button (three dots \`…\`) next to the relevant user to view available actions:

	- **Remove Password**: Disable password access to ensure the user can only authenticate via SSO.
`,
				},
			],
		},
		[IssueName.UnFederatedIdentity]: {
			[IdentitySource.SNOWFLAKE_USER]: [
				{
					title: 'Sign in to the Snowflake Admin Console',
					content: `
1. Go to Users & Roles under the Admin tab.
`,
				},
				{
					title: 'Locate the relevant user',
					content: `
1. Find the relevant user with a password and no SSO access through IDP.`,
				},
				{
					title: 'Decide Next Steps',
					content: `
You have a few options:

- Unset the user’s password
- Enforce MFA for this user
- Add the user to the IDP
`,
				},
				{
					title: 'Unset password',
					content: `
1. If you wish to **unset the password**:
	Click the extra options button (three dots \`…\`) next to the relevant user to view available actions:

	**Remove Password**: Disable password access to ensure the user can only authenticate via SSO.`,
				},
				{
					title: 'Enforce MFA',
					content: `
If you wish to **enforce MFA**:

1. Create an MFA policy:

\`\`\`sql

CREATE AUTHENTICATION POLICY mfa_enforcement_policy
  MFA_ENROLLMENT = 'REQUIRED'
  MFA_AUTHENTICATION_METHODS = ('PASSWORD');

\`\`\`

2. Apply the policy on a user:

\`\`\`sql

ALTER USER <user_name> SET AUTHENTICATION POLICY mfa_enforcement_policy;

\`\`\`

**Note - You can also enforce the MFA policy on your account.**
`,
				},
				{
					title: 'Add user to the IDP',
					content: `
1. Create a user for the un federated identity in your IDP and add it to your Snowflake app.`,
				},
			],
		},
		[IssueName.MissingMFA]: {
			[IdentitySource.SNOWFLAKE_USER]: [
				{
					title: 'Sign in to the Snowflake Admin Console',
					content: `
1. Go to Users & Roles under the Admin tab.
`,
				},
				{
					title: 'Locate the relevant user',
					content: `
1. Find the user with no MFA set.`,
				},
				{
					title: 'Enforce MFA',
					content: `
If you wish to **enforce MFA**:

1. Create an MFA policy:

\`\`\`sql

CREATE AUTHENTICATION POLICY mfa_enforcement_policy
  MFA_ENROLLMENT = 'REQUIRED'
  MFA_AUTHENTICATION_METHODS = ('PASSWORD');

\`\`\`

2. Apply the policy on a user:

\`\`\`sql

ALTER USER <user_name> SET AUTHENTICATION POLICY mfa_enforcement_policy;

\`\`\`

**Note - You can also enforce the MFA policy on your account.**
`,
				},
			],
		},
	},
	[EnvironmentType.DATABRICKS]: {
		[IssueName.MissingNetworkPolicy]: {
			[IdentitySource.DATABRICKS_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to the Databricks Workspace',
					content: `
1. Open the Databricks workspace at https://<your-databricks-instance>.azuredatabricks.net/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to the Service Principals Section',
					content: `
1. In the workspace, go to the "Settings" tab.
2. Select "User Management" and then "Service Principals".
`,
				},
				{
					title: 'Identify the Service Principal',
					content: `
1. Locate the service principal that does not have a defined network policy.
2. Note down its details for reference.
`,
				},
				{
					title: 'Create a Network Policy',
					content: `
1. In the "Settings" tab, go to "Network Policies".
2. Choose "Create Network Policy".
3. Define the allowed IP addresses or IP ranges that the service principal should be allowed to access.
4. Example configuration:

    \`\`\`json
    {
      "allowedIpRanges": [
        "192.168.1.0/24",
        "203.0.113.0/24"
      ],
      "blockedIpRanges": [
        "0.0.0.0/0"
      ]
    }
    \`\`\`
`,
				},
				{
					title: 'Apply the Network Policy to the Service Principal',
					content: `
1. Return to the "Service Principals" section.
2. Select the identified service principal.
3. Apply the created network policy by specifying the policy name in the service principal's settings.
    - Example command:

    \`\`\`bash
    databricks iam service-principals update --id <service_principal_id> --network-policy <network_policy_name>
    \`\`\`
`,
				},
				{
					title: 'Verify the Network Policy',
					content: `
1. Confirm that the network policy is correctly applied to the service principal.
2. Test access from allowed and blocked IP addresses to verify the policy's effectiveness.
`,
				},
				{
					title: 'Monitor and Audit Network Policies',
					content: `
1. Regularly review and update network policies to ensure they meet current security requirements.
2. Implement monitoring to detect and alert on any unauthorized access attempts.
3. Periodically audit service principals and their associated network policies to maintain compliance.
`,
				},
				{
					title: 'Communicate the Policy Requirements',
					content: `
1. Inform relevant stakeholders about the importance of network policies and ensure they understand how to apply and manage them.
2. Update internal documentation to include guidelines on creating and applying network policies in Databricks.
`,
				},
				{
					title: 'Implement Security Best Practices',
					content: `
1. Enforce the use of network policies for all service principals and users.
2. Use multi-factor authentication (MFA) and other security measures to further secure access to the Databricks environment.
3. Regularly review and adjust network policies based on changes in the network architecture or security requirements.
`,
				},
			],
		},
		[IssueName.InactivePersonalAccessTokens]: {
			[IdentitySource.DATABRICKS_USER]: [
				{
					title: 'Notify the User',
					content: 'Inform the user associated with the unused token you intend to revoke it.',
				},
				{
					title: 'Locate the Token using the CLI',
					content: `For this stage, you will need to use a user / service principal with workspace admin privileges.
Using the Databricks CLI, run:
\`\`\`
databricks token-management list
\`\`\`
Or in case you want to only search the specific user:
\`\`\`
databricks token-management list --created-by-username user@company.com
\`\`\`
`,
				},
				{
					title: 'Revoke the Token',
					content: `Using the Databricks CLI, run:
\`\`\`
databricks token-management delete TOKEN_ID
\`\`\`
`,
				},
			],
		},
		[IssueName.InactiveIdentity]: {
			[IdentitySource.DATABRICKS_SERVICE_PRINCIPAL]: [
				{
					title: 'Notify the Owner',
					content:
						'Using the Token platform, make sure who is the owner of this identity and inform them about the unused identity.',
				},
				{
					title: 'Locate the Identity in the Admin Console',
					content: `Log in to the Databricks console and navigate to:
\`User Management\` > \`Service principals\`
Choose the identity you wish to delete.`,
				},
				{
					title: 'Delete the Identity',
					content: `After clicking on the identity’s name, click on more options (…) and choose \`Delete\`.`,
				},
			],
			[IdentitySource.DATABRICKS_USER]: [
				{
					title: 'Notify the Owner',
					content:
						'Using the Token platform, make sure who is the owner of this identity and inform them about the unused identity.',
				},
				{
					title: 'Locate the Identity in the Admin Console',
					content: `Log in to the Databricks console and navigate to:
\`User Management\` > \`Users\`
Choose the identity you wish to delete.`,
				},
				{
					title: 'Delete the Identity',
					content: `After clicking on the identity’s name, click on more options (…) and choose \`Delete\`.`,
				},
			],
		},
		[IssueName.MultipleAccessTokens]: {
			[IdentitySource.DATABRICKS_USER]: [
				{
					title: 'Look for Unnecessary Tokens',
					content: 'Consider reducing the number of tokens, use the same token for different integrations.',
				},
				{
					title: 'Validate Before Auditing',
					content:
						'Make sure there are no consequences to deleting the access tokens. They might be in use and other processes could rely on them.',
				},
				{
					title: 'Delete the Key',
					content: `If you wish to delete the personal access token you will need to use a user / service principal with workspace admin privileges. Using the CLI, run:
\`\`\`
databricks token-management delete TOKEN_ID
\`\`\`
`,
				},
			],
		},
		[IssueName.ServiceAccountWithoutNetworkPolicy]: {
			[IdentitySource.DATABRICKS_SERVICE_PRINCIPAL]: [
				{
					title: 'Define IP Access Lists',
					content:
						'Determine the IP addresses and subnets that should be allowed or blocked. This can include corporate networks, VPNs, and other secure environments.',
				},
				{
					title: 'Check if your Workspace has the IP Access List Feature Enabled',
					content: `1. Log in to the Databricks workspace.
2. Navigate to the "Admin Console."
3. Select "Workspace Settings."
4. Ensure that the IP access lists feature is enabled.`,
				},
				{
					title: 'Configure IP Access Lists for the Account Console Using the UI',
					content: `1. Log in to the Databricks account console.
2. Navigate to the "Settings" tab.
3. Select "IP Access Lists."
4. Click on "Add IP Access List."
5. Enter the IP addresses or subnets you want to allow or block.
6. Save the changes.`,
				},
				{
					title: 'Configure IP Access Lists for Workspaces Using the UI',
					content: `1. Log in to the Databricks workspace.
2. Navigate to the "Admin Console."
3. Select "IP Access Lists."
4. Click on "Add IP Access List."
5. Enter the IP addresses or subnets you want to allow or block.
6. Save the changes.`,
				},
				{
					title: 'Refer to Databricks Documentation',
					content:
						'For more details on configuring IP access lists in Databricks, refer to the [Databricks documentation](https://docs.databricks.com/en/security/network/front-end/ip-access-list.html).',
				},
			],
		},
		[IssueName.AccessTokenEmbeddedInCode]: {
			[IdentitySource.DATABRICKS_USER]: [
				{
					title: 'Revoke the Exposed Personal Access Token',
					content: `Run the following command to revoke the compromised token:
\`\`\`
databricks token-management delete TOKEN_ID
\`\`\`
`,
				},
				{
					title: 'Create a New Personal Access Token',
					content: `Run the following command to create a new token to replace the revoked one:
\`\`\`
databricks token-management create-obo-token --comment <token-comment>
\`\`\`
`,
				},
				{
					title: 'Remove the Exposed Token from Code',
					content: `It's important to remove the exposed personal access token from any code repositories to prevent unauthorized access.`,
				},
			],
		},
		[IssueName.IDPBypass]: {
			[IdentitySource.DATABRICKS_USER]: [
				{
					title: 'Locate the Tokens Using the CLI',
					content: `For this, you will need to use a user or service principal with workspace admin privileges. Using the Databricks CLI, run:
\`\`\`
databricks token-management list
\`\`\`
Or, to search for the specific user:
\`\`\`
databricks token-management list --created-by-username user@company.com
\`\`\`
`,
				},
				{
					title: 'Revoke the Tokens',
					content: `Using the Databricks CLI, run:
\`\`\`
databricks token-management delete TOKEN_ID
\`\`\`
`,
				},
			],
		},
		[IssueName.PermanentToken]: {
			[IdentitySource.DATABRICKS_USER]: [
				{
					title: 'Revoke the Permanent Token',
					content: `Using the Databricks CLI, run:
\`\`\`
databricks token-management delete TOKEN_ID
\`\`\`
This command revokes the permanent token.`,
				},
				{
					title: 'Create a New Token with an Expiration Date',
					content: `Encourage the token owner to create a new token with a defined expiration date. Using the Databricks CLI, run:
\`\`\`
databricks tokens create --comment <token-comment> --lifetime-seconds <lifetime-in-seconds>
\`\`\`
`,
				},
				{
					title: 'Update Any Processes or Integrations',
					content: `Ensure that any processes or integrations using the old permanent token are updated to use the new token with an expiration date.`,
				},
			],
		},
		[IssueName.ServicePrincipalCreatedByOffboardedEmployee]: {
			[IdentitySource.DATABRICKS_USER]: [
				{
					title: 'Identify the Service Principal',
					content: `Using the Databricks console, navigate to:
\`User Management\` > \`Service principals\`
Locate the service principal created by the off-boarded employee.`,
				},
				{
					title: 'Evaluate the Usage',
					content: `Determine if the service principal is still in use and if there are any dependencies on it. Coordinate with relevant teams to assess the impact of its removal. Use the Ownership feature in the Token platform to identify the relevant contact.`,
				},
				{
					title: 'Reassign or Revoke Permissions',
					content: `If the service principal is still needed, reassign its permissions to a current employee or a new service principal. Otherwise, proceed to revoke its access.`,
				},
				{
					title: 'Delete the Service Principal',
					content: `After ensuring there are no dependencies, delete the service principal from the Databricks console by navigating to:
\`User Management\` > \`Service principals\`
Select the service principal, click on more options (…), and choose \`Delete\`.`,
				},
			],
		},
		[IssueName.ServicePrincipalUsedFromEmployeesEndpoint]: {
			[IdentitySource.DATABRICKS_SERVICE_PRINCIPAL]: [
				{
					title: 'Reassign the Endpoint',
					content:
						"Ensure that the service principal is used from a secure, managed endpoint rather than an individual employee's device.",
				},
				{
					title: 'Rotate Credentials',
					content:
						'Rotate the credentials associated with the service principal to prevent potential misuse.',
				},
				{
					title: 'Update Documentation',
					content:
						'Document the changes and inform relevant teams about the updated endpoint usage to ensure compliance with security protocols.',
				},
			],
		},
	},
	[EnvironmentType.GITHUB]: {
		[IssueName.InactivePersonalAccessTokens]: {
			[IdentitySource.GITHUB_USER]: [
				{
					title: 'Sign in to GitHub',
					content: `
1. Open the GitHub website at https://github.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to the Organization Settings',
					content: `
1. In the upper right corner, click on your profile picture.
2. Select "Your organizations".
3. Choose the organization you want to manage.
4. Click on "Settings" in the organization navigation bar.
`,
				},
				{
					title: 'Navigate to Third-party Access',
					content: `
1. In the left-hand sidebar, select "Third-party access".
2. Under this section, click on "Active tokens".
`,
				},
				{
					title: 'Identify Inactive Personal Access Token',
					content: `
1. Review the list of active personal access tokens associated with the organization.
2. Identify tokens that have not been used for an extended period or are no longer needed.
`,
				},
				{
					title: 'Revoke the Inactive Personal Access Token',
					content: `
1. Select the token you want to revoke by clicking on it.
2. Click the "Revoke" button.
3. Confirm the revocation in the prompt that appears.
`,
				},
				{
					title: 'Notify Relevant Stakeholders',
					content: `
1. Inform any team members or stakeholders that the token has been revoked.
2. Ensure they are aware of any potential impacts or required updates to their workflows.
`,
				},
				{
					title: 'Audit Other Personal Access Tokens',
					content: `
1. Review other personal access tokens for activity and relevance.
2. Revoke any additional inactive or unnecessary tokens following the same steps.
`,
				},
				{
					title: 'Implement Security Best Practices',
					content: `
1. Encourage the use of fine-grained personal access tokens with minimal necessary permissions.
2. Set expiration dates for personal access tokens to ensure they are regularly reviewed and renewed if needed.
3. Implement multi-factor authentication (MFA) for GitHub accounts to enhance security.
4. Regularly review and audit personal access tokens for activity and validity.
`,
				},
			],
		},
		[IssueName.InactiveAccessKey]: {
			[IdentitySource.GITHUB_USER]: [
				{
					title: 'Sign in to GitHub',
					content: `
1. Open the GitHub website at https://github.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to the Organization Settings',
					content: `
1. In the upper right corner, click on your profile picture.
2. Select "Your organizations".
3. Choose the organization you want to manage.
4. Click on "Settings" in the organization navigation bar.
`,
				},
				{
					title: 'Navigate to Third-party Access',
					content: `
1. In the left-hand sidebar, select "Third-party access".
2. Under this section, click on "Active tokens".
`,
				},
				{
					title: 'Revoke old Personal Access Token',
					content: `
1. Select the token you want to revoke by clicking on it.
2. Click the "Revoke" button.
3. Confirm the revocation in the prompt that appears.
`,
				},
				{
					title: 'Notify Relevant Stakeholders',
					content: `
1. Inform any team members or stakeholders that the token has been revoked.
2. Ensure they are aware of any potential impacts or required updates to their workflows.
`,
				},
				{
					title: 'Implement Security Best Practices',
					content: `
1. Encourage the use of fine-grained personal access tokens with minimal necessary permissions.
2. Set expiration dates for personal access tokens to ensure they are regularly reviewed and renewed if needed.
3. Implement multi-factor authentication (MFA) for GitHub accounts to enhance security.
4. Regularly review and audit personal access tokens for activity and validity.
`,
				},
			],
		},
		[IssueName.OverextendedSecret]: {
			[IdentitySource.GITHUB_USER]: [
				{
					title: 'Sign in to GitHub',
					content: `
1. Open the GitHub website at https://github.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to the Organization Settings',
					content: `
1. In the upper right corner, click on your profile picture.
2. Select "Your organizations".
3. Choose the organization you want to manage.
4. Click on "Settings" in the organization navigation bar.
`,
				},
				{
					title: 'Navigate to Third-party Access',
					content: `
1. In the left-hand sidebar, select "Third-party access".
2. Under this section, click on "Active tokens".
`,
				},
				{
					title: 'Revoke overextended Personal Access Token',
					content: `
1. Select the token you want to revoke by clicking on it.
2. Click the "Revoke" button.
3. Confirm the revocation in the prompt that appears.
`,
				},
				{
					title: 'Notify Relevant Stakeholders',
					content: `
1. Inform any team members or stakeholders that the token has been revoked.
2. Ensure they are aware of any potential impacts or required updates to their workflows.
`,
				},
				{
					title: 'Implement Security Best Practices',
					content: `
1. Encourage the use of fine-grained personal access tokens with minimal necessary permissions.
2. Set expiration dates for personal access tokens to ensure they are regularly reviewed and renewed if needed.
3. Implement multi-factor authentication (MFA) for GitHub accounts to enhance security.
4. Regularly review and audit personal access tokens for activity and validity.
`,
				},
			],
		},
	},
	[EnvironmentType.ENTRA_ID]: {
		[IssueName.CredentialCreatedByOffboardedEmployee]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
                    `,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
                    `,
				},
				{
					title: 'Access App Registrations',
					content: `
1. Under "Applications", select "App registrations".
2. In the "App registrations" pane, click on "All applications" to list all service principals.
                    `,
				},
				{
					title: 'Identify Credentials Created by Offboarded Employees',
					content: `
1. Use the filters to identify service principals with credentials created by offboarded (disabled) employees.
                    `,
				},
				{
					title: 'Select the Service Principal',
					content: `
1. Click on the service principal whose credentials were created by an offboarded employee.
                    `,
				},
				{
					title: 'Remove or Rotate the Credentials',
					content: `
1. On the "Certificates & secrets" tab, remove or rotate the credentials created by the offboarded employee.
                    `,
				},
			],
		},
		[IssueName.InactiveIdentity]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
`,
				},
				{
					title: 'Access App Registrations',
					content: `
1. Under "Applications", select "App registrations".
2. In the "App registrations" pane, click on "All applications" to list all service principals.
`,
				},
				{
					title: 'Identify Your Inactive Service Principals',
					content: `
1. Use the filters to identify your service principal.
`,
				},
				{
					title: 'Select your Service Principal',
					content: `
1. Click on the inactive service principal you wish to delete.
`,
				},
				{
					title: 'Delete the Service Principal',
					content: `
1. On the top of the screen, click on the "Delete" button.
2. Confirm the deletion when prompted.
`,
				},
			],
			[IdentitySource.ENTRA_ID_USER]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
                    `,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
                    `,
				},
				{
					title: 'Access User Management',
					content: `
1. Under "Users", select "All users".
                    `,
				},
				{
					title: 'Identify Inactive Users',
					content: `
1. Use the filters to identify inactive users in your organization.
                    `,
				},
				{
					title: 'Select the Inactive User',
					content: `
1. Click on the inactive user you wish to manage.
                    `,
				},
				{
					title: 'Disable or Remove the User Account',
					content: `
1. On the top of the screen, click on the "Delete" or "Disable" button to manage the inactive user account.
2. Confirm the action when prompted.
                    `,
				},
			],
		},
		[IssueName.IdentityUsingWeakAuthentication]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
`,
				},
				{
					title: 'Access App Registrations',
					content: `
1. Under "Applications", select "App registrations".
2. In the "App Registrations" pane, click on "All applications" to list all service principals.
`,
				},
				{
					title: 'Identify Your Service Principals',
					content: `
1. Use the filters to identify your service principal.
`,
				},
				{
					title: 'Select the Service Principal',
					content: `
1. Click on the service principal whose client secret you wish to delete.
`,
				},
				{
					title: 'Delete the client secret',
					content: `
1. In the service principal's 'Certificates & secrets' tab on the left, identify the client secret under the "Client Secrets" pane.
2. Press the trash bin icon to delete the client secret.
2. Confirm the deletion when prompted.
`,
				},
			],
		},
		[IssueName.AccessKeyNotRotated]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
`,
				},
				{
					title: 'Access App Registrations',
					content: `
1. Under "Applications", select "App registrations".
2. In the "App Registrations" pane, click on "All applications" to list all service principals.
`,
				},
				{
					title: 'Identify Your Service Principals',
					content: `
1. Use the filters to identify your service principal.
`,
				},
				{
					title: 'Select the Service Principal',
					content: `
1. Click on the service principal whose client secret you wish to delete.
`,
				},
				{
					title: 'Delete the client secret',
					content: `
1. In the service principal's 'Certificates & secrets' tab on the left, identify the client secret under the "Client Secrets" pane.
2. Press the trash bin icon to delete the client secret.
2. Confirm the deletion when prompted.
`,
				},
			],
		},
		[IssueName.OverextendedSecret]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
`,
				},
				{
					title: 'Access App Registrations',
					content: `
1. Under "Applications", select "App registrations".
2. In the "App Registrations" pane, click on "All applications" to list all service principals.
`,
				},
				{
					title: 'Identify Your Service Principals',
					content: `
1. Use the filters to identify your service principal.
`,
				},
				{
					title: 'Select the Service Principal',
					content: `
1. Click on the service principal whose client secret you wish to delete.
`,
				},
				{
					title: 'Delete the client secret',
					content: `
1. In the service principal's 'Certificates & secrets' tab on the left, identify the client secret under the "Client Secrets" pane.
2. Press the trash bin icon to delete the client secret.
2. Confirm the deletion when prompted.
`,
				},
			],
		},
		[IssueName.MultipleAccessKeys]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
`,
				},
				{
					title: 'Access App Registrations',
					content: `
1. Under "Applications", select "App registrations".
2. In the "App Registrations" pane, click on "All applications" to list all service principals.
`,
				},
				{
					title: 'Identify Your Service Principals',
					content: `
1. Use the filters to identify your service principal.
`,
				},
				{
					title: 'Select the Service Principal',
					content: `
1. Click on the service principal whose client secret you wish to delete.
`,
				},
				{
					title: 'Delete the client secret',
					content: `
1. In the service principal's 'Certificates & secrets' tab on the left, identify the client's unnecessary secret under the "Client Secrets" pane.
2. Press the trash bin icon to delete the client secret.
2. Confirm the deletion when prompted.
`,
				},
			],
		},
		[IssueName.MissingMFA]: {
			[IdentitySource.ENTRA_ID_USER]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
                    `,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
                    `,
				},
				{
					title: 'Access User Management',
					content: `
1. Under "Users", select "All users".
                    `,
				},
				{
					title: 'Identify Users Missing MFA',
					content: `
1. Use the filters to identify users who do not have Multi-Factor Authentication (MFA) enabled.
                    `,
				},
				{
					title: 'Select the User',
					content: `
1. Click on the user you wish to enable MFA for.
                    `,
				},
				{
					title: 'Enable MFA',
					content: `
1. In the user settings, navigate to "Authentication methods".
2. Enable Multi-Factor Authentication for the user.
                    `,
				},
			],
		},
		[IssueName.PasswordRotation]: {
			[IdentitySource.ENTRA_ID_USER]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
                    `,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
                    `,
				},
				{
					title: 'Access User Management',
					content: `
1. Under "Users", select "All users".
                    `,
				},
				{
					title: 'Identify Users with Unrotated Passwords',
					content: `
1. Use the filters to identify users whose passwords have not been rotated in a specified period.
                    `,
				},
				{
					title: 'Select the User',
					content: `
1. Click on the user whose password needs rotation.
                    `,
				},
				{
					title: 'Rotate the Password',
					content: `
1. In the user settings, navigate to "Password" and initiate a password change.
2. Ensure the user updates their password promptly.
                    `,
				},
			],
		},
		[IssueName.InactiveCredential]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to Microsoft Entra Admin Center',
					content: `
1. Open the Microsoft Entra Admin Center at https://entra.microsoft.com/.
2. Log in with your administrative credentials.
`,
				},
				{
					title: 'Navigate to Identity tab',
					content: `
1. In the left-hand navigation pane, select "Identity".
`,
				},
				{
					title: 'Access App Registrations',
					content: `
1. Under "Applications", select "App registrations".
2. In the "App Registrations" pane, click on "All applications" to list all service principals.
`,
				},
				{
					title: 'Identify Your Service Principals',
					content: `
1. Use the filters to identify your service principal.
`,
				},
				{
					title: 'Select the Service Principal',
					content: `
1. Click on the service principal whose client secret you wish to delete.
`,
				},
				{
					title: 'Delete the client secret',
					content: `
1. In the service principal's 'Certificates & secrets' tab on the left, identify the client's unnecessary secret under the "Client Secrets" pane.
2. Press the trash bin icon to delete the client secret.
2. Confirm the deletion when prompted.
`,
				},
			],
		},
		[IssueName.HighRiskSignInForCloudAdmin]: {
			[IdentitySource.ENTRA_ID_USER]: [
				{
					title: 'Review sign-in activity',
					content: `
1. Review the sign-in activity from the suspicious session in the usage tab (activity from IP 192.240.106.189) to assess whether it could be malicious.
2. If the user is determined to be possibly compromised, continue to the next steps.
`,
				},
				{
					title: 'Rotate user password',
					content: `
1. Rotate the compromised user's password.
`,
				},
				{
					title: 'Review and secure federated applications',
					content: `
1. Repeat the process for any of the applications the actor signed into through Entra ID during the session, by following the federated user's activity sessions in those applications.
`,
				},
			],
		},
		[IssueName.ClientSecretReferencedInPod]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the AKS Cluster',
					content:
						'```az aks list --query "[?contains(name, \'aks\')].{Name:name, ResourceGroup:resourceGroup}"```',
				},
				{
					title: 'Locate the Secret in AKS Pods',
					content:
						'```kubectl get pods --all-namespaces -o json | jq \'.items[] | select(.spec.containers[].env[]?.value | contains("13411ade-4d71-b654-a5ba-9912fabfa8d1")) | {namespace: .metadata.namespace, pod: .metadata.name}\'```',
				},
				{
					title: 'Create a New Secret in Azure Key Vault',
					content:
						'```az keyvault secret set --vault-name <yourKeyVaultName> --name production-aks-principal --value <yourSecretValue>```',
				},
				{
					title: 'Grant AKS Access to the Key Vault',
					content:
						'```az aks update -n <aksClusterName> -g <resourceGroupName> --attach-acr <yourKeyVaultName>```',
				},
				{
					title: 'Update Kubernetes Secrets to Use Key Vault',
					content:
						'```kubectl create secret generic <secretName> --from-literal=secret=$(az keyvault secret show --name production-aks-principal --vault-name <yourKeyVaultName> --query value -o tsv)```',
				},
				{
					title: 'Update AKS Pod Environment Variables',
					content: '```kubectl set env deployment/<deploymentName> --from=secret/<secretName>```',
				},
				{
					title: 'Verify Pod Environment Variable Update',
					content:
						'```kubectl get pods --all-namespaces -o json | jq \'.items[] | select(.spec.containers[].env[]?.valueFrom.secretKeyRef.name == "<secretName>") | {namespace: .metadata.namespace, pod: .metadata.name}\'```',
				},
				{
					title: 'Remove the Client Secret from Environment Variables',
					content: '```kubectl set env deployment/<deploymentName> <envVarName>-```',
				},
			],
		},
		[IssueName.InactiveClientSecret]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Sign in to the Azure Portal.',
					content: 'Go to Azure Portal.',
				},
				{
					title: 'Navigate to Azure Active Directory (Entra ID)',
					content: 'In the top search bar, search for Entra ID and click it.',
				},
				{
					title: 'Navigate to Enterprise Applications',
					content: 'In the left navigation menu, click on Enterprise applications.',
				},
				{
					title: ' Select the application / service principal',
					content: 'Find the user you want to delete and click on their name to open the user details page.',
				},
				{
					title: 'Remove Client Secrets',
					content: `Under Certificates & secrets, find the Client secrets section.
For each secret listed, click the Delete button to remove it.`,
				},
			],
		},
		[IssueName.AzureCrossAccountAccess]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the Service Principal',
					content: '```az ad sp show --id <servicePrincipalId>```',
				},
				{
					title: 'List Role Assignments',
					content: '```az role assignment list --assignee <servicePrincipalId>```',
				},
				{
					title: 'Remove Role Assignment in Higher Sensitivity Tenant',
					content:
						'```az role assignment delete --assignee <servicePrincipalId> --role <roleName> --scope <scope>```',
				},
				{
					title: 'Verify Role Assignment Removal',
					content: '```az role assignment list --assignee <servicePrincipalId>```',
				},
				{
					title: 'Restrict Permissions in Lower Sensitivity Tenant',
					content:
						'```az role assignment delete --assignee <servicePrincipalId> --role <roleName> --scope <scope>```',
				},
				{
					title: 'Ensure Least Privilege Permissions',
					content: '```az role assignment list --assignee <servicePrincipalId>```',
				},
				{
					title: 'Review Audit Logs',
					content:
						'```az monitor activity-log list --resource-id <resourceId> --start-time <startTime> --end-time <endTime>```',
				},
			],
		},
		[IssueName.ClientSecretNotRotated]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the Service Principal with the Client Key',
					content: '```az ad sp credential list --id <servicePrincipalId>```',
				},
				{
					title: 'Add a New Client Key to the Service Principal',
					content: '```az ad sp credential reset --name <servicePrincipalId> --append --years 1```',
				},
				{
					title: 'Update Services with the New Client Key',
					content: '```<update services with new client key command or steps>```',
				},
				{
					title: 'Remove the Old Client Key',
					content: '```az ad sp credential delete --id <servicePrincipalId> --key-id <oldKeyId>```',
				},
				{
					title: 'Verify the Key Rotation',
					content: '```az ad sp credential list --id <servicePrincipalId>```',
				},
				{
					title: 'Set Up Key Rotation Policy',
					content:
						'```az policy assignment create --name \'RotateClientKeys\' --policy definition <policyDefinitionId> --params \'{"daysSinceLastRotation":{"value":90}}\' --scope <scope>```',
				},
				{
					title: 'Monitor Key Rotation',
					content:
						'```az monitor activity-log list --resource-id <resourceId> --start-time <startTime> --end-time <endTime>```',
				},
			],
		},
		[IssueName.ClientSecretUsedFromEndpoint]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the Service Principal and Client Secret',
					content: '```az ad sp credential list --id <servicePrincipalId>```',
				},
				{
					title: 'Invalidate the Compromised Client Secret',
					content:
						'```az ad sp credential delete --id <servicePrincipalId> --key-id 4a075312-b654-4d71-a2be-86acf8bfa8a4```',
				},
				{
					title: 'Create a New Client Secret',
					content: '```az ad sp credential reset --name <servicePrincipalId> --append --years 1```',
				},
				{
					title: 'Update Services with the New Client Secret',
					content: '```<update services with new client secret command or steps>```',
				},
				{
					title: 'Notify Users of the Incident',
					content:
						'```Send an email to dan@synergy.com, alice@synergy.com, bob@synergy.com informing them of the security incident and the necessary steps to take.```',
				},
				{
					title: 'Monitor for Any Further Unauthorized Access',
					content:
						'```az monitor activity-log list --resource-id <resourceId> --start-time <startTime> --end-time <endTime>```',
				},
				{
					title: 'Implement Endpoint Security Measures',
					content:
						'```Ensure all endpoints used by employees have updated security measures, such as endpoint protection software and regular security audits.```',
				},
			],
		},
		[IssueName.CloudtrailResourceCreatedByOffBoardedEmployee]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the Service Principal and Client Secret',
					content: '```az ad sp credential list --id <servicePrincipalId>```',
				},
				{
					title: 'Invalidate the Compromised Client Secret',
					content:
						'```az ad sp credential delete --id <servicePrincipalId> --key-id 4a075312-b654-4d71-a2be-86acf8bfa8a4```',
				},
				{
					title: 'Create a New Client Secret',
					content: '```az ad sp credential reset --name <servicePrincipalId> --append --years 1```',
				},
				{
					title: 'Update Services with the New Client Secret',
					content: '```<update services with new client secret command or steps>```',
				},
				{
					title: 'Review and Update Access Policies',
					content: '```az role assignment list --assignee <servicePrincipalId>```',
				},
				{
					title: 'Remove Access for Former Employee',
					content: '```az ad user delete --id lucas.grant@synergy.com```',
				},
				{
					title: 'Notify Relevant Teams',
					content:
						'```Send an email to the security and IT teams informing them of the security incident and the steps taken.```',
				},
				{
					title: 'Monitor for Any Unauthorized Access',
					content:
						'```az monitor activity-log list --resource-id <resourceId> --start-time <startTime> --end-time <endTime>```',
				},
			],
		},

		[IssueName.SharedAccount]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the Managed Identity and Its Usage',
					content: '```az identity show --name synappid --resource-group <resourceGroupName>```',
				},
				{
					title: 'List Role Assignments for the Managed Identity',
					content: '```az role assignment list --assignee <managedIdentityId>```',
				},
				{
					title: 'Remove Over-Privileged Roles from the Managed Identity',
					content:
						"```az role assignment delete --assignee <managedIdentityId> --role 'User Administrator'```",
				},
				{
					title: 'Assign Least Privilege Roles to the Managed Identity',
					content:
						"```az role assignment create --assignee <managedIdentityId> --role 'Storage Blob Data Contributor' --scope <storageAccountResourceId>```",
				},
				{
					title: 'Create Separate Managed Identities for Different Tasks',
					content: '```az identity create --name <newIdentityName> --resource-group <resourceGroupName>```',
				},
				{
					title: 'Assign Appropriate Roles to New Managed Identities',
					content:
						"```az role assignment create --assignee <newIdentityId1> --role 'Virtual Machine Contributor' --scope <vmResourceId>``` \n" +
						"```az role assignment create --assignee <newIdentityId2> --role 'Storage Blob Data Contributor' --scope <storageAccountResourceId>```",
				},
				{
					title: 'Update Virtual Machines and Functions to Use New Managed Identities',
					content: '```<update VMs and Functions configuration to use new managed identities>```',
				},
				{
					title: 'Verify the New Managed Identities and Role Assignments',
					content: '```az role assignment list --assignee <newIdentityId1>```',
				},
				{
					title: 'Monitor for Any Unauthorized Access',
					content:
						'```az monitor activity-log list --resource-id <resourceId> --start-time <startTime> --end-time <endTime>```',
				},
			],
		},
		[IssueName.AzureKVSecretUnRotated]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the Non-Rotated Secret',
					content: '```az keyvault secret show --name {secret_name} --vault-name {vault_name}```',
				},
				{
					title: 'Rotate the Secret Manually',
					content:
						'```az keyvault secret set --name {secret_name} --vault-name {vault_name} --value <newSecretValue>```',
				},
				{
					title: 'Implement an Automated Rotation Policy',
					content: '```<configure a rotation policy using Azure Automation or Key Vault policy>```',
				},
				{
					title: 'Audit Secret Rotation History',
					content:
						'```az keyvault secret list-versions --name {secret_name} --vault-name {vault_name} --query "[?attributes.updated<=\'<thresholdDate>\']"```',
				},
				{
					title: 'Notify the Relevant Teams About Rotation',
					content: '```<notify relevant teams or systems that the secret has been rotated>```',
				},
				{
					title: 'Monitor Secret Rotation for Compliance',
					content:
						'```az monitor activity-log list --resource-id <keyVaultResourceId> --start-time <startTime> --end-time <endTime>```',
				},
				{
					title: 'Establish a Regular Rotation Schedule',
					content: '```set up a regular rotation schedule to ensure secrets are rotated consistently.```',
				},
			],
		},
		[IssueName.AzureKVSecretPermanent]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the Permanent Secret',
					content: '```az keyvault secret show --name {secret_name} --vault-name {vault_name}```',
				},
				{
					title: 'Set an Expiration Date for the Secret',
					content:
						'```az keyvault secret set-attributes --name {secret_name} --vault-name {vault_name} --expires <expirationDate>```',
				},
				{
					title: 'Implement a Rotation Policy for the Secret',
					content: '```<configure a rotation policy using Azure Automation or Key Vault policy>```',
				},
				{
					title: 'Audit All Secrets Without Expiration Dates',
					content:
						'```az keyvault secret list --vault-name {vault_name} --query "[?attributes.expires==null]"```',
				},
				{
					title: 'Notify the Relevant Teams About Expiration',
					content: '```<notify relevant teams or systems to set expiration dates for secrets>```',
				},
				{
					title: 'Monitor Secrets Without Expiration Dates',
					content:
						'```az monitor activity-log list --resource-id <keyVaultResourceId> --start-time <startTime> --end-time <endTime>```',
				},
				{
					title: 'Establish a Regular Review Schedule for Secrets',
					content: '```set up a schedule to regularly review and update secrets without expiration dates```',
				},
			],
		},
		[IssueName.MissingNetworkPolicy]: {
			[IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL]: [
				{
					title: 'Identify the Secret Without Network Restrictions',
					content: '```az keyvault secret show --name {secret_name} --vault-name {vault_name}```',
				},
				{
					title: 'Apply Network Restrictions to the Secret',
					content:
						'```az keyvault update --name {vault_name} --resource-group <resourceGroupName> --default-action Deny``` \n' +
						'```az keyvault network-rule add --name {vault_name} --resource-group <resourceGroupName> --ip-address <ipAddress>```',
				},
				{
					title: 'Review and Audit Network Rules',
					content:
						'```az keyvault network-rule list --name {vault_name} --resource-group <resourceGroupName>```',
				},
				{
					title: 'Notify Relevant Teams About Applied Restrictions',
					content: '```<notify relevant teams that network restrictions have been applied to the secret>```',
				},
				{
					title: 'Monitor Network Access to the Secret',
					content:
						'```az monitor activity-log list --resource-id <keyVaultResourceId> --start-time <startTime> --end-time <endTime>```',
				},
				{
					title: 'Regularly Review Network Restrictions',
					content:
						'```<schedule regular reviews of network restrictions to ensure they meet security requirements>```',
				},
			],
			[IdentitySource.AZURE_KV_CERTIFICATE]: [
				{
					title: 'Identify the Certificate Without Network Restrictions',
					content: '```az keyvault certificate show --name {certificate_name} --vault-name {vault_name}```',
				},
				{
					title: 'Apply Network Restrictions to the Certificate',
					content:
						'```az keyvault update --name {vault_name} --resource-group <resourceGroupName> --default-action Deny``` \n' +
						'```az keyvault network-rule add --name {vault_name} --resource-group <resourceGroupName> --ip-address <ipAddress>```',
				},
				{
					title: 'Review and Audit Network Rules',
					content:
						'```az keyvault network-rule list --name {vault_name} --resource-group <resourceGroupName>```',
				},
				{
					title: 'Notify Relevant Teams About Applied Restrictions',
					content:
						'```<notify relevant teams that network restrictions have been applied to the certificate>```',
				},
				{
					title: 'Monitor Network Access to the Certificate',
					content:
						'```az monitor activity-log list --resource-id <keyVaultResourceId> --start-time <startTime> --end-time <endTime>```',
				},
				{
					title: 'Regularly Review Network Restrictions',
					content:
						'```<schedule regular reviews of network restrictions to ensure they meet security requirements>```',
				},
			],
			[IdentitySource.AZURE_KV_KEY]: [
				{
					title: 'Identify the Encryption Key Without Network Restrictions',
					content: '```az keyvault key show --name {key_name} --vault-name {vault_name}```',
				},
				{
					title: 'Apply Network Restrictions to the Encryption Key',
					content:
						'```az keyvault update --name {vault_name} --resource-group <resourceGroupName> --default-action Deny``` \n' +
						'```az keyvault network-rule add --name {vault_name} --resource-group <resourceGroupName> --ip-address <ipAddress>```',
				},
				{
					title: 'Review and Audit Network Rules',
					content:
						'```az keyvault network-rule list --name {vault_name} --resource-group <resourceGroupName>```',
				},
				{
					title: 'Notify Relevant Teams About Applied Restrictions',
					content:
						'```<notify relevant teams that network restrictions have been applied to the encryption key>```',
				},
				{
					title: 'Monitor Network Access to the Encryption Key',
					content:
						'```az monitor activity-log list --resource-id <keyVaultResourceId> --start-time <startTime> --end-time <endTime>```',
				},
				{
					title: 'Regularly Review Network Restrictions',
					content:
						'```<schedule regular reviews of network restrictions to ensure they meet security requirements>```',
				},
			],
		},
	},
	[EnvironmentType.GCP]: {
		[IssueName.InactiveIdentity]: {
			[IdentitySource.GCP_SERVICE_ACCOUNT]: isDemo
				? [
						{
							title: 'Sign in to Google Cloud Console',
							content: 'Navigate to the Google Cloud Console at https://console.cloud.google.com/.',
						},
						{
							title: 'Access the Service Accounts Page',
							content:
								'In the left-hand navigation pane, click on "IAM & Admin" and then "Service Accounts".',
						},
						{
							title: 'Identify the Inactive Service Account',
							content: 'Locate the service account that you want to delete.',
						},
						{
							title: 'Remove Access Keys',
							content:
								'Click on the service account, then click on the "Keys" tab. Delete any inactive keys.',
						},
						{
							title: 'Delete the Service Account',
							content: 'Go back to the service account page, then click on the "Delete" button.',
						},
					]
				: null,
		},
		[IssueName.MultipleAccessKeys]: {
			[IdentitySource.GCP_SERVICE_ACCOUNT]: isDemo
				? [
						{
							title: 'Sign in to Google Cloud Console',
							content: 'Navigate to the Google Cloud Console at https://console.cloud.google.com/.',
						},
						{
							title: 'Access the Service Accounts Page',
							content:
								'In the left-hand navigation pane, click on "IAM & Admin" and then "Service Accounts".',
						},
						{
							title: 'Identify the Service Account',
							content: 'Locate the service account that has multiple access keys.',
						},
						{
							title: 'Remove Unused Access Keys',
							content:
								'Click on the service account, then click on the "Keys" tab. Delete any unused keys.',
						},
					]
				: null,
		},
		[IssueName.AccessKeyNotRotated]: {
			[IdentitySource.GCP_SERVICE_ACCOUNT]: isDemo
				? [
						{
							title: 'Sign in to Google Cloud Console',
							content: 'Navigate to the Google Cloud Console at https://console.cloud.google.com/.',
						},
						{
							title: 'Access the Service Accounts Page',
							content:
								'In the left-hand navigation pane, click on "IAM & Admin" and then "Service Accounts".',
						},
						{
							title: 'Identify the Service Account',
							content: 'Locate the service account that has an access key that needs rotation.',
						},
						{
							title: 'Rotate the Access Key',
							content: 'Click on the service account, then click on the "Keys" tab. Create a new key.',
						},
						{
							title: 'Update Services with the New Key',
							content:
								'Update any services or applications that use the service account with the new key.',
						},
						{
							title: 'Remove the Old Access Key',
							content:
								'Once you have updated all services, delete the old access key from the "Keys" tab.',
						},
					]
				: null,
		},
		[IssueName.GcpServiceAccountUsedFromEndpoint]: {
			[IdentitySource.GCP_SERVICE_ACCOUNT]: [
				{
					title: 'Review Service Account Permissions',
					content: `
1. Identify the service account being used and review its current permissions.
2. Ensure that the service account has the least privilege necessary to perform its tasks.
            `,
				},
				{
					title: 'Revoke Unauthorized Access',
					content: `
1. If the service account is being used from an unexpected endpoint, revoke any unauthorized access.
2. Update the IAM policy or remove the service account from roles it shouldn't have.
            `,
				},
				{
					title: 'Rotate Service Account Keys',
					content: `
1. Rotate any keys associated with the service account to prevent continued use of potentially compromised keys.
2. Ensure that key rotation is enforced periodically.
            `,
				},
				{
					title: 'Monitor Service Account Activity',
					content: `
1. Set up monitoring and alerting for any suspicious or unexpected use of the service account.
2. Regularly review audit logs for actions performed by the service account.
            `,
				},
				{
					title: 'Implement Endpoint Restrictions',
					content: `
1. Restrict the use of the service account to specific trusted endpoints, such as IP allowlisting or VPC Service Controls.
            `,
				},
				{
					title: 'Review and Update Policies',
					content: `
1. Ensure that IAM policies are updated to reflect the necessary restrictions.
2. Regularly audit and update policies to adhere to security best practices.
            `,
				},
			],
		},
		[IssueName.ServiceAccountKeyReferencedInPod]: {
			[IdentitySource.GCP_SERVICE_ACCOUNT]: [
				{
					title: 'Identify and Review Referenced Keys',
					content: `
1. Locate the service account key that is being referenced in the Pod.
2. Review the usage and necessity of the key for the application.
            `,
				},
				{
					title: 'Migrate to Workload Identity',
					content: `
1. Consider migrating to Google Kubernetes Engine Workload Identity, which allows Pods to authenticate as service accounts without needing keys.
2. Update your application to use Workload Identity for accessing GCP resources.
            `,
				},
				{
					title: 'Rotate or Delete Exposed Keys',
					content: `
1. If the service account key has been exposed in the Pod configuration, rotate or delete the key to prevent misuse.
2. Ensure that new keys are securely stored and managed.
            `,
				},
				{
					title: 'Monitor Pod Activity',
					content: `
1. Set up monitoring to track usage of the service account key within your cluster.
2. Configure alerts for any unexpected use of the key.
            `,
				},
				{
					title: 'Review GCP Policies',
					content: `
1. Review and update GCP policies to ensure that the service account has the least privilege necessary for its tasks.
2. Regularly audit and refine policies to enhance security.
            `,
				},
			],
		},
		[IssueName.CrossProjectPrivilegeEscalation]: {
			[IdentitySource.GCP_SERVICE_ACCOUNT]: [
				{
					title: 'Navigate to IAM Console',
					content: 'Go to the GCP Console and navigate to IAM & Admin → IAM.',
				},
				{
					title: 'Identify Service Account with Impersonation',
					content:
						'Locate the service account that has impersonation roles (e.g., "Service Account Token Creator" or "Service Account User").',
				},
				{
					title: 'Select Principals to Remove',
					content: 'Check the box next to the principal(s) assigned these roles.',
				},
				{
					title: 'Revoke Access',
					content: 'Click on "Remove access" to revoke impersonation privileges.',
				},
			],
		},
		[IssueName.SameProjectPrivilegeEscalation]: {
			[IdentitySource.GCP_SERVICE_ACCOUNT]: [
				{
					title: 'Navigate to IAM Console',
					content: 'Go to GCP Console → IAM & Admin → IAM',
				},
				{
					title: 'Identify Service Accounts',
					content:
						'Find any service accounts that have elevated permissions like "iam.serviceAccounts.actAs" OR "iam.serviceAccounts.getAccessToken"',
				},
				{
					title: 'Edit Service Account Permissions',
					content: 'Click the edit (pencil) icon next to each identified service account',
				},
				{
					title: 'Remove Excessive Permissions',
					content: 'Remove any impersonation permissions from the service account',
				},
				{
					title: 'Apply Least Privilege Access',
					content:
						'Add only the specific minimum permissions needed for the service account to perform its required tasks',
				},
			],
		},
		[IssueName.GcpServiceAccountAccessKeyCreatedByOffBoardedEmployee]: {
			[IdentitySource.GCP_SERVICE_ACCOUNT]: [
				{
					title: 'Disable the Compromised Key',
					content:
						'Immediately disable the compromised access key from the "Keys" tab under IAM & Admin > Service Accounts.',
				},
				{
					title: 'Rotate the Access Key',
					content:
						'Generate a new key for the affected service account and securely store it in a secrets manager.',
				},
				{
					title: 'Update Services with the New Key',
					content:
						'Update all dependent services and applications to use the new key. Ensure they do not store keys on endpoints.',
				},
				{
					title: 'Revoke the Old Key',
					content:
						'Once all services have been updated, delete the compromised access key from the service account.',
				},
			],
		},
	},
	[EnvironmentType.OKTA]: {
		[IssueName.HighRiskSignInForCloudAdmin]: {
			[IdentitySource.OKTA]: [
				{
					title: 'Review Suspicious Activity',
					content: `
1. Navigate to the usage tab.
2. Identify and review all activity from IP 109.76.160.39.
3. Assess whether the activity could be malicious.
4. If the user is determined to be possibly compromised, proceed to the next steps.
`,
				},
				{
					title: 'Rotate Credentials',
					content: `
1. Access the user management system for the potentially compromised account.
2. Reset the user's password to a new, strong password.
3. Identify and revoke any API tokens created by the user.
4. Generate new API tokens if necessary, ensuring they are securely stored and communicated.
`,
				},
				{
					title: 'Secure Federated Platforms',
					content: `
1. Review Okta logs to identify all platforms the actor signed into during the suspicious session.
2. For each identified platform:\n
   a. Access the platform's admin console.

   b. Locate the federated user's activity sessions.

   c. Review these sessions for any suspicious activity.

   d. If suspicious activity is found, follow that platform's security protocols for potentially compromised accounts.
3. Consider temporarily revoking the user's access to these platforms until the investigation is complete.
`,
				},
			],
		},
	},
	[EnvironmentType.SALESFORCE]: {
		[IssueName.InactiveIdentity]: {
			[IdentitySource.SALESFORCE_USER]: [
				{
					title: 'Sign in to Salesforce',
					content: 'Log in to Salesforce with administrator credentials.',
				},
				{
					title: 'Access Setup',
					content: 'Click the gear icon in the upper-right corner and select "Setup" from the dropdown menu.',
				},
				{
					title: 'Navigate to Users',
					content:
						'In the "Quick Find" search box on the left, type "Users" and select "Users" under the "Users" section.',
				},
				{
					title: 'Select the User',
					content: 'Locate the inactive user in the list and click on "Edit" next to their name.',
				},
				{
					title: 'Deactivate the User',
					content: `Uncheck the "Active" checkbox to deactivate the user.
Click "Save" to apply the changes.`,
				},
			],
		},
		[IssueName.SalesforceUserMultipleConnectedApps]: {
			[IdentitySource.SALESFORCE_USER]: [
				{
					title: 'Understand Salesforce Recommendation',
					content: `
Salesforce recommends that each service account user should be associated with only one connected app. This enhances security and simplifies management.`,
				},
				{
					title: 'Access Salesforce Setup',
					content: `
1. Log in to Salesforce as an administrator.
2. Click the gear icon and select 'Setup'.`,
				},
				{
					title: 'Identify Users with Multiple Connected Apps',
					content: `
1. In the Quick Find box, type 'Users' and select 'Users'.
2. Click on each user's name to view their details.
3. Scroll to the 'Connected Apps OAuth Usage' section.
4. Note any users who have access to multiple connected apps.`,
				},
				{
					title: 'Create Dedicated Users for Each Connected App',
					content: `
For each connected app requiring a service account user:
1. Navigate to 'Users' in Setup.
2. Click 'New User'.
3. Fill in the user details, naming the user to reflect the connected app (e.g., 'AppName_ServiceUser').
4. Assign appropriate profiles and permission sets specific to the connected app.
5. Click 'Save' to create the user.`,
				},
				{
					title: 'Assign Connected Apps to New Users',
					content: `
1. On the new user's detail page, go to 'Permission Set Assignments' and click 'Edit Assignments'.
2. Assign permission sets that grant access to the specific connected app.
3. Click 'Save' to confirm the assignments.`,
				},
				{
					title: 'Update Connected App Configurations',
					content: `
1. Configure each connected app to use the credentials of its dedicated user.
2. Update any authentication settings or integration points accordingly.`,
				},
				{
					title: 'Remove Unnecessary Connected Apps from Original Users',
					content: `
1. Return to the original users with multiple connected apps.
2. In their 'Connected Apps OAuth Usage' section, click 'Revoke' next to any connected apps no longer needed.
3. Confirm the revocation when prompted.`,
				},
				{
					title: 'Verify Access and Functionality',
					content: `
1. Ensure that each connected app operates correctly with its new dedicated user.
2. Confirm that original users no longer have access to the disconnected apps.`,
				},
				{
					title: 'Document and Communicate Changes',
					content: `
1. Document all changes made, including new users created and access modifications.
2. Inform relevant team members or stakeholders about the updates.
3. Provide guidelines for requesting future access or creating new service account users.`,
				},
				{
					title: 'Implement Ongoing Access Management',
					content: `
1. Establish a policy to create a dedicated user for each new connected app.
2. Schedule regular reviews to ensure compliance with Salesforce best practices.
3. Educate administrators on managing connected apps and service account users effectively.`,
				},
			],
		},

		[IssueName.UnFederatedIdentity]: {
			[IdentitySource.SALESFORCE_USER]: [
				{
					title: 'Access Salesforce Setup',
					content: `
1. Log in to your Salesforce org as an administrator.
2. Click on the gear icon and select 'Setup'.`,
				},
				{
					title: 'Locate the relevant user',
					content: `
1. In the Quick Find box, type 'Users'.
2. Click on 'Users' under 'Administration'.
3. Find the relevant user who is not using Single Sign-On (SSO).`,
				},
				{
					title: 'Decide Next Steps',
					content: `
You have two main options:

- Set up Single Sign-On (SSO) and add the user to your Identity Provider (IdP)
- Freeze the user account until SSO can be implemented`,
				},
				{
					title: 'Set up Single Sign-On',
					content: `
1. From Setup, enter 'Single Sign-On' in the Quick Find box, then select 'Single Sign-On Settings'.
2. Click 'Edit' and select 'SAML Enabled'.
3. Configure your SAML settings according to your Identity Provider's instructions.
4. Click 'Save'.

After setting up SSO:

1. Go back to the Users page.
2. Click 'Edit' next to the relevant user.
3. Scroll to 'Single Sign-On' section.
4. Enter the Federation ID that matches the user's identity in your IdP.
5. Click 'Save'.`,
				},
				{
					title: 'Freeze the user account',
					content: `
If you need to temporarily disable the account until SSO can be implemented:

1. From the Users page, click on the name of the relevant user.
2. Click 'Freeze' at the top of the user detail page.
3. Confirm the action when prompted.

Note: Remember to unfreeze the account once SSO is properly set up for this user.`,
				},
			],
		},
		[IssueName.AccessKeyNotRotated]: {
			[IdentitySource.SALESFORCE_CONNECTED_APPLICATION]: [
				{
					title: 'Access Salesforce Setup',
					content: `\
1. Log in to your Salesforce org as an administrator.
2. Click on the gear icon in the top right corner and select 'Setup'.
3. In the Quick Find box, type 'App Manager' and select it from the results.`,
				},
				{
					title: 'Locate the Connected App',
					content: `\
1. In the App Manager, find the Connected App that needs its consumer key and secret rotated.
2. Click on the dropdown next to the app name and select 'View'.`,
				},
				{
					title: 'Rotate the Consumer Key and Secret',
					content: `\
1. In the API (Enable OAuth Settings) section, locate the 'Consumer Key' and 'Consumer Secret' fields.
2. Click on 'New Consumer Secret' button.
3. A confirmation dialog will appear. Read the warning carefully as this action will invalidate the existing consumer secret.
4. If you're sure you want to proceed, click 'OK' to confirm.
5. Salesforce will generate a new Consumer Secret. The Consumer Key will remain the same.
6. Immediately copy and securely store the new Consumer Secret. It will only be shown once for security reasons.`,
				},
				{
					title: 'Update Client Applications',
					content: `\
1. Identify all client applications, services, or integrations that use this Connected App.
2. Update these clients with the new Consumer Secret.
3. If using OAuth 2.0 JWT Bearer flow, you'll need to create new JWTs using the new Consumer Secret.
4. If using OAuth 2.0 Web Server flow, you'll need to update any stored Consumer Secret values in your application.`,
				},
				{
					title: 'Verify the New Consumer Secret',
					content: `\
1. Test the Connected App to ensure it's working correctly with the new Consumer Secret.
2. This may involve running test authentications or operations from client applications.
3. Monitor for any authentication errors that might indicate issues with the new secret.`,
				},
				{
					title: 'Rotate the Consumer Key (Optional, but Recommended)',
					content: `\
1. While Salesforce doesn't provide a direct way to rotate the Consumer Key, you can effectively do so by creating a new Connected App.
2. Create a new Connected App with the same settings as the current one.
3. This will generate both a new Consumer Key and Consumer Secret.
4. Update all client applications to use the new Connected App's credentials.
5. Once all clients are updated and verified, you can safely delete the old Connected App.`,
				},
				{
					title: 'Monitor and Plan Regular Rotations',
					content: `\
1. Regularly monitor the usage and security of your Connected App.
2. Implement a policy for regular rotation of Consumer Secrets (and optionally, Consumer Keys via new Connected Apps).
3. Document the rotation process and maintain a secure record of when rotations occur.`,
				},
			],
		},
	},
	[EnvironmentType.AZURE_DEVOPS]: {
		[IssueName.SecretExposedInCode]: {
			[IdentitySource.AZURE_DEVOPS_SECRET]: [
				{
					title: 'Sign  in to Azure DevOps',
					content: `
	1. Open the Azure DevOps portal at https://dev.azure.com/.
	2. Log in with your administrative credentials.
					`,
				},
				{
					title: 'Locate the relevant secret',
					content: `
	1. In the Azure DevOps portal, navigate to the project where the secret is located.
	2. Click on the "Project Settings" icon in the top-right corner.
	3. Select "Repositories" from the dropdown menu.
	4. Find the repository where the secret is exposed.
	5. Click on the repository to view its details.
	6. Locate the file and line number where the secret is exposed.
					`,
				},
				{
					title: 'Rotate the secret',
					content: `
	1. Per key type, go to the relevent source and rotate it.
					`,
				},
				{
					title: 'Store the new secret in your favorite secrets manager',
					content: `
	1. Store the new secret in your favorite secrets manager.
					`,
				},
				{
					title: 'Update the code to use the new secret',
					content: `
	1. Remove the old secret from the codebase.
	2. Update the code to read the new secret from your secrets manager.
					`,
				},
			],
		},
		[IssueName.UnrotatedPAT]: {
			[IdentitySource.AZURE_DEVOPS_USER]: [
				{
					title: 'Sign in to Azure DevOps',
					content: `
1. Navigate to your Azure DevOps organization (https://dev.azure.com/<your-organization>)
2. Sign in with your credentials.
3. Ensure you have the necessary permissions - you must be a member of the Project Collection Administrators group.`,
				},
				{
					title: 'Revoke OAuth Authorizations',
					content: `
1. To revoke OAuth authorizations including PATs for your organization's users, navigate to Token Revocations.
2. Review the list of tokens to identify those that need to be revoked.`,
				},
				{
					title: 'Use PowerShell Script for Automation',
					content: `
1. If you need to revoke multiple tokens, use the PowerShell script provided by Microsoft here:\n
 https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/admin-revoke-user-pats?view=azure-devops\n
2. The script can be used in two ways:
   - Pass a list of user principal names (UPNs)
   - Specify a date range if you don't know the UPN of the user who created the PAT`,
				},
				{
					title: 'Notify Users',
					content: `
1. After successfully revoking the affected PATs, inform your users.
2. Users can recreate their tokens as necessary for their work.
3. Recommend that users:
   - Create tokens with shorter expiration periods
   - Only select required scopes
   - Follow security best practices`,
				},
				{
					title: 'Monitor Token Status',
					content: `
1. Be aware that there might be a delay of up to one hour before the PAT becomes inactive.
2. This latency period persists until the disable or delete operation is fully processed in Microsoft Entra ID.
3. If your organization is connected to Microsoft Entra ID, use audit logs to track the revocation process.`,
				},
				{
					title: 'Verify and Document Changes',
					content: `
1. Confirm that the revoked tokens are no longer active.
2. Document the actions taken and maintain records of:
   - Which tokens were revoked
   - When the revocation occurred
   - Affected users and systems
3. Update security documentation as needed.`,
				},
				{
					title: 'Implement Best Practices',
					content: `
1. Encourage users to:
   - Set appropriate token expiration dates (not exceeding one year)
   - Use shorter time periods for token validity
   - Generate new PATs upon expiry
2. Ensure users are aware they will receive notification emails one week before token expiration
3. Regularly audit and review token usage across your organization`,
				},
			],
		},
		[IssueName.OverExtendedPAT]: {
			[IdentitySource.AZURE_DEVOPS_USER]: [
				{
					title: "This needs to be done from the PAT creator's account",
					content: `
1. Navigate to your Azure DevOps organization (https://dev.azure.com/<your-organization>).
2. Log in with the account that created the Personal Access Token (PAT).
3. Ensure you have the necessary permissions to manage PATs.`,
				},
				{
					title: 'Log in to your account and go to User Settings',
					content: `
1. Log in to your Azure DevOps account.
2. Select "User settings" from the dropdown menu.
3. Click on "Personal access tokens" in the left-hand menu.`,
				},
				{
					title: 'Select the relevant token and click Edit',
					content: `
1. Locate the relevant token in the list.
2. Select the token to view its details and Click "Edit".
`,
				},
				{
					title: 'Choose a new expiration date\n',
					content: `
1. In the "Edit Personal Access Token" dialog, locate the "Expiration" field.
2. Select a new expiration date from the dropdown menu.
3. Ensure the new expiration date aligns with your organization's security policies.
4. Click "Save" to apply the changes.`,
				},
				{
					title: 'Click save',
					content: `
1. Click Save`,
				},
			],
		},
	},
	[EnvironmentType.ACTIVE_DIRECTORY]: {
		[IssueName.UnrotatedPassword]: {
			[IdentitySource.ACTIVE_DIRECTORY_USER]: [
				{
					title: 'Launch Active Directory Users and Computers interface',
					content: '1. On the Domain Controller, launch **Active Directory Users and Computers** interface.',
				},
				{
					title: 'Select User and Access Properties',
					content: '1. Pick the user, right click→Properties',
				},
				{
					title: 'Go to Account Tab',
					content: '1. Go to the Account tab',
				},
				{
					title: 'Force Password Change',
					content: '1. In user options, select **User must change password at next logon**',
				},
				{
					title: 'Apply Changes',
					content: '1. Click apply',
				},
			],
		},
		[IssueName.InactiveIdentity]: {
			[IdentitySource.ACTIVE_DIRECTORY_USER]: [
				{
					title: 'Launch Active Directory Users and Computers interface',
					content: '1. On the Domain Controller, launch **Active Directory Users and Computers** interface.',
				},
				{
					title: 'Select User and Access Properties',
					content: '1. Pick the user, right click→Properties',
				},
				{
					title: 'Go to Account Tab',
					content: '1. Go to the Account tab',
				},
				{
					title: 'Disable Account',
					content: '1. In user options, select **Account is disabled**',
				},
				{
					title: 'Apply Changes',
					content: '1. Click apply',
				},
			],
		},
		[IssueName.PermanentPassword]: {
			[IdentitySource.ACTIVE_DIRECTORY_USER]: [
				{
					title: 'Launch Active Directory Users and Computers interface',
					content: '1. On the Domain Controller, launch **Active Directory Users and Computers** interface.',
				},
				{
					title: 'Select User and Access Properties',
					content: '1. Pick the user, right click→Properties',
				},
				{
					title: 'Go to Account Tab',
					content: '1. Go to the Account tab',
				},
				{
					title: 'Disable Password Never Expires',
					content: '1. In user options, deselect **Password never expires**',
				},
				{
					title: 'Apply Changes',
					content: '1. Click apply',
				},
			],
		},
	},
	[EnvironmentType.DEMO_ATLASSIAN]: {
		[IssueName.InactiveIdentity]: {
			[IdentitySource.DEMO_ATLASSIAN_USER]: [
				{
					title: 'Ensure this user needs access',
					content: `\
1. Verify if the inactive user still requires access to Jira.
2. If access is no longer needed, proceed with removal.`,
				},
				{
					title: 'Delete this user',
					content: `\
1. Go to the Admin Console and navigate to the Directory section.
2. Locate the relevant user and click on their name.
3. Click on the \`...\` menu on the right side and select Remove user.`,
				},
				{
					title: 'Automatically deprovision the user',
					content: `\
1. If using SAML SSO federation, remove the user from the relevant group associated with Jira.
2. If SCIM provisioning is enabled, this will automatically deactivate the user.`,
				},
			],
		},
		[IssueName.OffBoardedEmployee]: {
			[IdentitySource.DEMO_ATLASSIAN_USER]: [
				{
					title: 'Disable or Remove the User',
					content: `\
1. Go to Admin Console → Directory.
2. Suspend or remove the user.`,
				},
				{
					title: 'Revoke Jira Access',
					content: `\
Remove the user from Jira product access and Jira groups (e.g., jira-software-users).`,
				},
				{
					title: 'Revoke API Tokens and OAuth Access',
					content: `\
1. Go to Admin Console → Security → API Tokens and revoke any active tokens.
2. Remove the user from OAuth application links.`,
				},
			],
		},
		[IssueName.DemoServiceAccountCreatedByOffboardedEmployee]: {
			[IdentitySource.DEMO_ATLASSIAN_USER]: [
				{
					title: 'Identify all functionalities using this service account',
					content: `\
1. Verify if the service account is actively used in automated processes.
2. Ensure that deleting existing credentials will not disrupt any workflows.`,
				},
				{
					title: 'Generate new authentication credentials for the service account',
					content: `\
1. Instead of creating a new user and reassigning permissions, generate new credentials for the existing account.
2. If the service account uses OAuth, create a new OAuth token with the necessary scopes.
3. If the service account uses an API token, generate a new API token and update all dependencies.`,
				},
				{
					title: 'Replace old credentials and validate functionality',
					content: `\
1. Update all services to use the new credentials.
2. Test automated processes to confirm continued operation.
3. Revoke the previous credentials to prevent unauthorized access.`,
				},
			],
		},
		[IssueName.DemoServiceAccountHasConsoleAccess]: {
			[IdentitySource.DEMO_ATLASSIAN_USER]: [
				{
					title: 'Go to the Atlassian Admin Console',
					content: `\
1. Navigate to Admin Console → Directory.
2. Find the service account user.`,
				},
				{
					title: 'Remove UI access',
					content: `\
1. Click on the user’s name to view their profile.
2. Under Product Access, remove the user from Jira Software, Jira Service Management, or Jira Work Management.
3. This prevents UI access but retains API access via tokens.`,
				},
			],
		},
		[IssueName.DemoAdminApiKeyCreatedByOffboardedEmployee]: {
			[IdentitySource.DEMO_ATLASSIAN_USER]: [
				{
					title: 'Check if this API key is not used for essential functions in your organization',
					content: `\
You can view the usage graph for this API key and observe its daily use.`,
				},
				{
					title: 'Delete the API key',
					content: `\
1. Using an organization admin, navigate to the Atlassian administration dashboard.
2. Go to \`Settings\` → \`API Keys\`.
3. Locate the API key and click \`Revoke\`.`,
				},
				{
					title: 'Consider creating a new API key',
					content: `\
1. Create a new key if you need to replace the key for automated processes to continue functioning.
2. In the same API Keys dashboard, click \`Create API Key\`.
3. Copy the Organization ID and the API key values and save them in a secure location.`,
				},
			],
		},
		[IssueName.DemoOverExtendedAdminApiKey]: {
			[IdentitySource.DEMO_ATLASSIAN_USER]: [
				{
					title: 'Create a new admin API key',
					content: `\
1. Go to the Atlassian administration dashboard.
2. Navigate to \`Settings\` → \`API Keys\`.
3. Click \`Create API Key\`. Copy the Organization ID and the API key values and save them in a secure location.`,
				},
				{
					title: 'Revoke the original admin API key',
					content: `\
1. Go to the same API keys dashboard.
2. Locate the API key and click \`Revoke\`.`,
				},
				{
					title: 'Consider creating a new API key',
					content: `\
1. Create a new key if you need to replace the key for automated processes to continue functioning.
2. In the same API Keys dashboard, click \`Create API Key\`.
3. Copy the Organization ID and the API key values and save them in a secure location.`,
				},
			],
		},
	},
}
