import { Button, Modal, Typography, Checkbox, Spin } from 'antd'
import { useState, lazy, Suspense } from 'react'
import { NewFeatureAnnouncement, FeatureId } from '../../schemas/newFeatureAnnouncement'
import { CloseOutlined } from '@ant-design/icons'
import { useActiveFeature } from '../../hooks/useActiveFeature'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket'

const { Title } = Typography

const UsageGraphGif = lazy(() =>
	import('../../assets/newFeaturesAnnouncements/usage_graph_gif_for_new_feature.gif').then((module) => ({
		default: () => {
			// Add a timestamp to the gif URL to prevent caching, making the gif restart on each modal re-open
			const gifUrl = `${module.default}?t=${Date.now()}`
			return <img src={gifUrl} alt="Usage Graph" className="w-full" />
		},
	})),
)

const FEATURE_ID_TO_GIF_COMPONENT: Record<FeatureId, React.LazyExoticComponent<() => JSX.Element>> = {
	[FeatureId.NEW_FEATURE_ANNOUNCEMENT_USAGE_GRAPH]: UsageGraphGif,
}

type ModalContentProps = {
	feature: NewFeatureAnnouncement
}

const ModalContent = ({ feature }: ModalContentProps) => {
	const FeatureGif = FEATURE_ID_TO_GIF_COMPONENT[feature.id]

	return (
		<div className="flex flex-col gap-4">
			<span>{feature.description}</span>
			<div className="rounded-lg overflow-hidden">
				<Suspense fallback={<Spin className="w-full h-full m-4" />}>
					<FeatureGif />
				</Suspense>
			</div>
		</div>
	)
}

type ModalFooterProps = {
	onClose: (shouldDismiss?: boolean) => void
}

const ModalFooter = ({ onClose }: ModalFooterProps) => {
	const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(false)

	const handleClick = () => {
		onClose(isDontShowAgainChecked)
	}

	return (
		<div className="flex justify-between items-center px-2 pt-4 border-t border-border-secondary">
			<Checkbox checked={isDontShowAgainChecked} onChange={(e) => setIsDontShowAgainChecked(e.target.checked)}>
				Don't show this again
			</Checkbox>
			<Button type="primary" onClick={handleClick}>
				Got It
			</Button>
		</div>
	)
}

export const NewFeatureAnnouncementButton = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const { activeFeature: feature, dismissFeature } = useActiveFeature()

	if (!feature) {
		return null
	}

	const handleClose = (shouldDismiss = false) => {
		if (shouldDismiss) {
			trackEvent(LogRocketTrackEvent.NewFeatureAnnouncementModalDismissed, {
				feature_id: feature.id,
			})
			dismissFeature(feature.id)
		}
		setIsModalOpen(false)
	}

	const modalTitle = (
		<div className="flex items-center gap-2 mb-2">
			<span className="text-xl">🎉</span>
			<Title level={4} className="!m-0">
				Say Hello To <span className="text-textIcon-brand">{feature.title}!</span>
			</Title>
		</div>
	)

	return (
		<>
			<Button
				onClick={() => {
					trackEvent(LogRocketTrackEvent.NewFeatureAnnouncementModalButtonClicked, {
						feature_id: feature.id,
					})
					setIsModalOpen(true)
				}}
				icon={<span className="mr-1">🎉</span>}
				className="flex justify-center items-center text-center gap-1 text-textIcon-brand bg-surface-brand border-border-brand hover:!bg-dsBlue-200"
			>
				{feature.buttonTitle || feature.title}
			</Button>

			<Modal
				title={modalTitle}
				open={isModalOpen}
				onCancel={() => handleClose()}
				footer={[<ModalFooter key="footer" onClose={handleClose} />]}
				width={800}
				closeIcon={<CloseOutlined />}
				centered
			>
				<ModalContent feature={feature} />
			</Modal>
		</>
	)
}
