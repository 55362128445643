import React, { useState, Fragment } from 'react'
import { Divider, Modal, Typography } from 'antd'
import { integrationConfigsByCategory } from '../integrationsRegistry.ts'
import { useEnvironments } from '../../../../api/environments.ts'

import {
	ConnectableEnvironmentConfig,
	EnvironmentIntegrationCategory,
	EnvironmentIntegrationFormData,
	isIntegrationConfigConnectable,
	SinglePageIntegrationEnabledEnvironments,
} from '../environmentIntegrationsTypes.ts'
import { IntegrationStatus } from '../../common/integrationTypes.ts'
import { IntegrationsError, IntegrationsSkeleton } from '../../common/components/IntegrationStates.tsx'
import { IntegrationCard } from '../../common/components/IntegrationCard.tsx'
import { EnvironmentIntegrationForm } from './EnvironmentIntegrationForm.tsx'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../../RoutePaths.tsx'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { useEnvironmentIntegrationFunctions } from '../environmentIntegrationUtils.ts'
import { EnvironmentTestConnectionResponsePayload } from '../../../../schemas/environments/environments.ts'
import { IntegrationModalTitle } from './IntegrationModalTitle.tsx'

interface EnvironmentIntegrationsProps {
	isModalVisible: boolean
	setIsModalVisible: (visible: boolean) => void
}

const environmentIntegrationCategories = Object.values(EnvironmentIntegrationCategory)

export const EnvironmentIntegrations: React.FC<EnvironmentIntegrationsProps> = ({
	isModalVisible,
	setIsModalVisible,
}) => {
	const { handleConnectSubmit, handleTestConnection, isCreatingEnvironment, testConnection } =
		useEnvironmentIntegrationFunctions()
	const { data: environments, isPending, error, isError } = useEnvironments()
	const navigate = useNavigate()
	const [selectedIntegration, setSelectedIntegration] = useState<ConnectableEnvironmentConfig | null>(null)

	const handleConnect = (integration: ConnectableEnvironmentConfig) => {
		setSelectedIntegration(integration)
		setIsModalVisible(true)
	}

	const handleCloseModal = () => {
		setIsModalVisible(false)
		setSelectedIntegration(null)
	}

	const handleIntegrationCardClick = (integrationKey: EnvironmentType) => {
		navigate({
			to: RoutePaths.Integration,
			params: {
				integrationEnvironmentType: integrationKey,
			},
		})
	}

	const handleSubmit = (
		formData: EnvironmentIntegrationFormData,
		testResultPayload: EnvironmentTestConnectionResponsePayload,
	) => {
		// Selected integration is not null because we render the form only if there is an integration selected
		handleConnectSubmit(selectedIntegration!, formData, handleCloseModal, testResultPayload)
	}

	if (isPending) {
		return <IntegrationsSkeleton />
	}

	if (isError) {
		return <IntegrationsError error={error instanceof Error ? error : new Error('An unknown error occurred')} />
	}

	return (
		<>
			{environmentIntegrationCategories.map((category, categoryIndex) => {
				const categoryIntegrationConfigs = integrationConfigsByCategory[category]
				if (!categoryIntegrationConfigs?.length) {
					return null
				}

				return (
					<Fragment key={category}>
						<Typography.Title level={5}>{category}</Typography.Title>
						<div className="flex flex-wrap gap-5">
							{categoryIntegrationConfigs.map((integrationConfig) => {
								const environmentNames = integrationConfig.environmentNamesResolver(environments)
								return (
									<IntegrationCard
										key={integrationConfig.key}
										logo={integrationConfig.logo}
										name={integrationConfig.name}
										description={integrationConfig.description}
										status={
											environmentNames.length > 0
												? IntegrationStatus.ACTIVE
												: IntegrationStatus.INACTIVE
										}
										environmentsConfig={{
											names: environmentNames,
											logsConnected: integrationConfig.logEnvironmentsCounter?.(environments),
										}}
										onConnect={
											isIntegrationConfigConnectable(integrationConfig)
												? () => handleConnect(integrationConfig)
												: undefined
										}
										onCardClick={
											SinglePageIntegrationEnabledEnvironments.includes(integrationConfig.key) &&
											environmentNames.length > 0
												? () => handleIntegrationCardClick(integrationConfig.key)
												: undefined
										}
									/>
								)
							})}
						</div>
						{categoryIndex !== environmentIntegrationCategories.length - 1 && <Divider />}
					</Fragment>
				)
			})}
			<Modal
				open={isModalVisible}
				onCancel={handleCloseModal}
				footer={null}
				width={530}
				destroyOnClose={true}
				title={selectedIntegration ? <IntegrationModalTitle integration={selectedIntegration} /> : null}
				maskClosable={false}
			>
				{selectedIntegration && (
					<EnvironmentIntegrationForm
						key={selectedIntegration.key}
						integration={selectedIntegration}
						onSubmit={handleSubmit}
						onTestConnection={handleTestConnection}
						onClose={handleCloseModal}
						isConnecting={isCreatingEnvironment}
						isTestingConnection={testConnection.isPending}
					/>
				)}
			</Modal>
		</>
	)
}
