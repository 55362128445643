import React, { useState, useEffect, useMemo } from 'react'
import { useParams, Link } from '@tanstack/react-router'
import { RoutePaths } from '../../../RoutePaths'
import { EnvironmentType, EnvironmentTypeMap } from '../../../../schemas/envType'
import { Input, Button, Modal, Breadcrumb, Tooltip } from 'antd'
import { LeftOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons'
import { useEnvironments } from '../../../../api/environments'
import { EnvironmentIntegrationForm } from '../components/EnvironmentIntegrationForm'
import {
	ConnectableEnvironmentConfig,
	EnvironmentConfig,
	EnvironmentIntegrationFormData,
	isIntegrationConfigConnectable,
} from '../environmentIntegrationsTypes'
import { integrationConfigs } from '../integrationsRegistry'
import { IntegrationsSkeleton } from '../../common/components/IntegrationStates'
import {
	EnvironmentTestConnectionResponsePayload,
	ServerEnvironments,
} from '../../../../schemas/environments/environments'
import { SinglePageIntegrationBody } from './SinglePageIntegrationBody'
import { useEnvironmentIntegrationFunctions } from '../environmentIntegrationUtils.ts'
import { PageHeader } from '../../../../components/common/PageHeader.tsx'
import { IntegrationModalTitle } from '../components/IntegrationModalTitle.tsx'
import { Page } from '../../../../components/common/Page.tsx'

export const SinglePageIntegration: React.FC = () => {
	const [searchText, setSearchText] = useState('')
	const { data: environments, isPending, isError } = useEnvironments()
	const { integrationEnvironmentType } = useParams({ from: RoutePaths.Integration })
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedIntegration, setSelectedIntegration] = useState<
		ConnectableEnvironmentConfig | EnvironmentConfig | null
	>(null)
	const { handleConnectSubmit, handleTestConnection, isCreatingEnvironment, testConnection } =
		useEnvironmentIntegrationFunctions()
	const [filteredEnvironments, setFilteredEnvironments] = useState<ServerEnvironments[keyof ServerEnvironments]>([])

	useEffect(() => {
		const integrationConfig = integrationConfigs.find((config) => config.key === integrationEnvironmentType)
		setSelectedIntegration(integrationConfig ?? null)

		if (environments && integrationConfig) {
			setFilteredEnvironments(integrationConfig.filterEnvironments(environments, searchText))
		}
	}, [integrationEnvironmentType, environments, searchText])

	const handleConnect = (integration: ConnectableEnvironmentConfig) => {
		setSelectedIntegration(integration)
		setIsModalVisible(true)
	}

	const handleCloseModal = () => {
		setIsModalVisible(false)
	}

	const handleSubmit = (
		formData: EnvironmentIntegrationFormData,
		testResultPayload: EnvironmentTestConnectionResponsePayload,
	) => {
		if (integrationEnvironmentType === EnvironmentType.AWS && environments) {
			const awsEnvironments = environments[EnvironmentType.AWS]
			const matchingEnv = awsEnvironments.find((env) => env.name === formData.environmentName)
			if (matchingEnv) {
				formData.environmentRowId = matchingEnv.id
			}
		}
		handleConnectSubmit(
			selectedIntegration as ConnectableEnvironmentConfig,
			formData,
			handleCloseModal,
			testResultPayload,
		)
	}

	const breadcrumbItems = useMemo(
		() => [
			{
				title: (
					<Link
						from={RoutePaths.Integration}
						to={RoutePaths.Integrations}
						className="!flex items-center !h-full hover:!bg-surface-tertiary !text-textIcon-secondary hover:!text-textIcon-linkHover font-normal"
					>
						<LeftOutlined className="mr-2" />
						All Integrations
					</Link>
				),
			},
			{ title: <span>{EnvironmentTypeMap[integrationEnvironmentType]}</span> },
		],
		[integrationEnvironmentType],
	)

	return (
		<Page>
			<PageHeader>
				<Breadcrumb items={breadcrumbItems} />
			</PageHeader>
			<div className="flex justify-between items-center pl-3">
				<span className="text-lg text-textIcon-primary font-medium">My Accounts</span>
				<div className="flex gap-2">
					{selectedIntegration && isIntegrationConfigConnectable(selectedIntegration) ? (
						<Button
							onClick={() => handleConnect(selectedIntegration)}
							type="primary"
							icon={<PlusOutlined />}
						>
							Add Account
						</Button>
					) : (
						<Tooltip title="If you wish to add an account, please contact the Token team.">
							<Button disabled={true} type="primary" icon={<PlusOutlined />}>
								Add Account
							</Button>
						</Tooltip>
					)}
					<Input
						value={searchText}
						size="small"
						onChange={(e) => {
							setSearchText(e.target.value)
						}}
						allowClear={true}
						placeholder="Search"
						prefix={<SearchOutlined />}
					/>
				</div>
			</div>
			<div className="w-full overflow-y-auto">
				{isPending ? (
					<div className="flex flex-col gap-1 bg-surface-primary border border-border-secondary rounded-lg p-6">
						<IntegrationsSkeleton />
					</div>
				) : isError ? (
					<div>Error loading environments</div>
				) : (
					<SinglePageIntegrationBody
						integrationEnvironmentType={integrationEnvironmentType}
						singlePageFilteredEnvironments={filteredEnvironments}
						singlePageEnvironments={environments[integrationEnvironmentType as keyof ServerEnvironments]}
					/>
				)}
			</div>
			<Modal
				open={isModalVisible}
				onCancel={handleCloseModal}
				footer={null}
				width={530}
				destroyOnClose={true}
				title={selectedIntegration ? <IntegrationModalTitle integration={selectedIntegration} /> : null}
				maskClosable={false}
			>
				{selectedIntegration && isIntegrationConfigConnectable(selectedIntegration) && (
					<EnvironmentIntegrationForm
						key={selectedIntegration.key}
						integration={selectedIntegration}
						onSubmit={handleSubmit}
						onClose={handleCloseModal}
						isConnecting={isCreatingEnvironment}
						onTestConnection={handleTestConnection}
						isTestingConnection={testConnection.isPending}
					/>
				)}
			</Modal>
		</Page>
	)
}
