import React from 'react'
import {
	CollaborationIntegrationCreateRequest,
	CollaborationIntegrationTestConnectionRequest,
} from '../../../schemas/integrations/integrations.ts'
import { IntegrationType } from '../../../jiraIntegration/schemas/common.ts'
import { FormField } from '../EnvironmentIntegrations/environmentIntegrationsTypes.ts'

export const ConnectableCollaborationIntegrations = [IntegrationType.SLACK, IntegrationType.JIRA]

export interface CollaborationIntegrationConfig {
	key: IntegrationType
	name: string
	description: string
	logo: React.ReactNode
}

export interface ConnectableCollaborationIntegrationConfig extends CollaborationIntegrationConfig {
	guideFileName?: string
	formFields?: FormField[]
	modalWidth?: number
	createIntegrationPayload?: (
		formData: CollaborationIntegrationFormData,
	) => CollaborationIntegrationCreateRequest | null
	createTestConnectionPayload?: (
		formData?: CollaborationIntegrationFormData,
	) => CollaborationIntegrationTestConnectionRequest | null
}

export interface CollaborationIntegrationFormData {
	[key: string]: string | number
}

export function isCollaborationIntegrationConnectable(
	integration: CollaborationIntegrationConfig,
): integration is ConnectableCollaborationIntegrationConfig {
	if ('createIntegrationPayload' in integration) {
		return true
	}

	return false
}
