import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { isDev } from '../utils/devUtils.ts'

const axiosInstance = axios.create({
	baseURL: window.location.origin,
})

async function getAppVersion(): Promise<string> {
	const { data } = await axiosInstance.get<string>(`/index.html`)
	return data
}

export const useAppVersion = () => {
	return useQuery({
		queryKey: ['version'],
		queryFn: () => getAppVersion(),
		refetchOnWindowFocus: 'always',
		refetchInterval: 60 * 1000, // 1 minute
		enabled: !isDev,
	})
}
