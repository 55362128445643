import { ServerGoogleWorkspaceUserDemoOauthAppsXcInput } from '../../schemas/identities/googleWorkspaceUserSchema.ts'

export const demoOauthAppsXcData = {
	'1': {
		id: '1',
		name: 'Document Automation Tool',
		created_at: '2021-01-01',
		updated_at: '2021-01-01',
		is_internal: true,
		scopes: ['https://www.googleapis.com/auth/drive'],
	},
	'2': {
		id: '2',
		name: 'HR Onboarding Dashboard',
		created_at: '2021-01-01',
		updated_at: '2021-01-01',
		is_internal: true,
		scopes: ['https://www.googleapis.com/auth/calendar.events'],
	},
	'3': {
		id: '3',
		name: 'Slack',
		created_at: '2021-01-01',
		updated_at: '2021-01-01',
		is_internal: false,
		scopes: ['https://www.googleapis.com/auth/drive'],
	},
	'4': {
		id: '4',
		name: 'Zoom',
		created_at: '2021-01-01',
		updated_at: '2021-01-01',
		is_internal: false,
		scopes: [
			'https://www.googleapis.com/auth/calendar.events',
			'https://www.googleapis.com/auth/gmail.addons.current.message.readonly',
			'https://www.googleapis.com/auth/gmail.send',
		],
	},
} satisfies Record<string, ServerGoogleWorkspaceUserDemoOauthAppsXcInput>
