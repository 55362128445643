import { Button } from 'antd'
import cx from 'classnames'
import { CloseOutlined } from '@ant-design/icons'

export type FilterChipValueProps = {
	title: string
	children?: React.ReactNode
	onRemove?: () => void
}

export const FilterChipValue = ({ title, onRemove, children }: FilterChipValueProps) => {
	const containerClassName = cx(
		'flex items-center gap-0.5 py-[2px] bg-surface-tertiary rounded text-textIcon-primary text-xs max-w-36 truncate basis-full justify-between',
		{ 'px-1': !onRemove, 'pl-1': !!onRemove },
	)

	return (
		<span className={containerClassName} title={title}>
			{children ?? <span className="truncate">{title}</span>}
			{!!onRemove && (
				<Button
					type="text"
					size="small"
					className="!p-0 !m-0 !w-4 !h-4 flex items-center justify-center hover:!bg-surface-tertiary bg-surface-tertiary shrink-0 text-textIcon-secondary hover:!text-textIcon-primary"
					icon={<CloseOutlined className="hover:!text-textIcon-primary h-3 w-3" />}
					onClick={onRemove}
				/>
			)}
		</span>
	)
}
