import React from 'react'
import { FormInstance } from 'antd'
import {
	EnvironmentCreateRequest,
	EnvironmentTestConnectionRequest,
	EnvironmentTestConnectionResponsePayload,
	ServerEnvironments,
} from '../../../schemas/environments/environments.ts'
import { EnvironmentType } from '../../../schemas/envType.ts'

export enum EnvironmentIntegrationCategory {
	CLOUD_PROVIDERS = 'Cloud Providers',
	IDENTITY_ACCESS_MANAGEMENT = 'Identity & Access Management',
	CORPORATE_SASS = 'Corporate SaaS',
	DEVELOPER_TOOLS = 'Developer Tools',
}

export const SinglePageIntegrationEnabledEnvironments = [
	EnvironmentType.ENTRA_ID,
	EnvironmentType.AZURE_DEVOPS,
	EnvironmentType.GITHUB,
	EnvironmentType.SALESFORCE,
	EnvironmentType.AWS,
	EnvironmentType.SNOWFLAKE,
	EnvironmentType.GOOGLE_WORKSPACE,
	EnvironmentType.JUMPCLOUD,
	EnvironmentType.ACTIVE_DIRECTORY,
	EnvironmentType.OKTA,
	EnvironmentType.GCP,
]

// Base form field
interface BaseFormField {
	name: string
	label: string
	required: boolean
	placeholder?: string
	dependencies?: string[]
}

interface ValidationRule {
	validator: (value: string, form: FormInstance) => Promise<void> | void
	message?: string
}

// Simple form field
export interface SimpleFormField extends BaseFormField {
	type: 'text' | 'password' | 'number' | 'url'
	fieldType: 'simple'
	rules?: ValidationRule[]
}

// Select form field
export interface SelectFormField extends BaseFormField {
	fieldType: 'select'
	options: (environments?: ServerEnvironments) => Array<{ label: string; value: string }>
	allowCreate?: boolean
	inputPlaceholder?: string
}

// Group of related form fields
export interface FormFieldGroup extends BaseFormField {
	fieldType: 'group'
	fields: FormField[]
	guideFile?: string
	guideFileName?: string
}

// Generated field that requires special handling
// e.g AWS External ID
export interface GeneratedFormField extends BaseFormField {
	fieldType: 'generated'
	generateAction: (form?: FormInstance) => string | Promise<string>
	copyable?: boolean
	getExistingId?: (environments?: ServerEnvironments) => string | undefined
	icon?: React.ReactNode
	tooltip?: string
	inputClassName?: string
}

export interface TextAreaFormField extends BaseFormField {
	fieldType: 'textArea'
	minHeight?: number
	rules?: ValidationRule[]
}

export interface FileUploadField extends BaseFormField {
	fieldType: 'fileUpload'
	accept?: string
	buttonText?: string
	rules?: ValidationRule[]
	className?: string
	buttonClassName?: string
}

export interface GroupedTabsField extends BaseFormField {
	fieldType: 'groupedTabs'
	tabs: Array<{
		key: string
		title: string
		fields: FormField[]
	}>
	rules?: ValidationRule[]
	defaultActiveKey?: string
}

// Union type of all possible field types
export type FormField =
	| SimpleFormField
	| FormFieldGroup
	| GeneratedFormField
	| SelectFormField
	| TextAreaFormField
	| FileUploadField
	| GroupedTabsField

export interface EnvironmentIntegrationFormData {
	[key: string]: string | number | object
}

export interface EnvironmentConfig {
	key: keyof ServerEnvironments
	name: string
	description: string
	logo: React.ReactNode
	category: EnvironmentIntegrationCategory
	environmentNamesResolver: (environments: ServerEnvironments) => Array<string>
	logEnvironmentsCounter?: (environments: ServerEnvironments) => number | boolean
	filterEnvironments: (
		environments: ServerEnvironments,
		searchText: string,
	) => ServerEnvironments[keyof ServerEnvironments]
}

export interface ConnectableEnvironmentConfig extends EnvironmentConfig {
	guideFileName: string
	formFields: FormField[]
	createEnvironmentPayload: (
		formData: EnvironmentIntegrationFormData,
		testResultPayload?: EnvironmentTestConnectionResponsePayload,
	) => EnvironmentCreateRequest | null
	createTestConnectionPayload?: (formData: EnvironmentIntegrationFormData) => EnvironmentTestConnectionRequest
	onFormClose?: (formData?: EnvironmentIntegrationFormData) => void
}

export function isIntegrationConfigConnectable(
	integration: EnvironmentConfig,
): integration is ConnectableEnvironmentConfig {
	if ('guideFileName' in integration && 'formFields' in integration && 'createEnvironmentPayload' in integration) {
		return true
	}

	return false
}
