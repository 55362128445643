import { createContext } from 'react'
import { type ITenantsResponseV2 } from '@frontegg/rest-api/tenants/interfaces'
import { type User } from '@frontegg/redux-store/auth/interfaces'
import { type TenantsState } from '@frontegg/redux-store/auth/TenantsState/interfaces'

export interface AuthContextState {
	user: User
	tenantsState: TenantsState
	activeTenant: ITenantsResponseV2
	isAdmin: boolean
	isImpersonating: boolean
}

export const AuthContext = createContext<AuthContextState | null>(null)
