import AIRemediationIcon from '../../../../../assets/ai_remediation.svg?react'

export const AIRemediationEmptyErrorState = ({ isError }: { isError?: boolean }) => (
	<div className="grow flex flex-col items-center justify-center gap-2">
		<AIRemediationIcon />
		<span className="text-textIcon-secondary">
			{isError
				? 'Could not get AI remediation for this issue. Please try again and if the error persists, contact us'
				: 'Choose a target platform on the left-hand side to get AI remediation for this issue...'}
		</span>
	</div>
)
