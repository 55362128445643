import React, { useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-enterprise'
import { AdUsageLogsAggregatedSchema } from '../../../../../schemas/identities/activeDirectory/adUsageSchema'
import { formatDate } from '../../../../../utils.ts'
import { eventIdDescriptions } from './adConstants'

export const AdAggregatedUsageTable: React.FunctionComponent<{
	data: AdUsageLogsAggregatedSchema[]
}> = ({ data }) => {
	const columnDefs = useMemo<ColDef<AdUsageLogsAggregatedSchema>[]>(() => {
		return [
			{
				field: 'account_name',
				headerName: 'Actor Name ',
				flex: 1,
			},
			{
				field: 'service_name',
				headerName: 'Service Name',
				flex: 1,
				valueGetter: (params: { data?: AdUsageLogsAggregatedSchema }) => {
					if (!params.data || !params.data.service_name) return '-'
					return params.data.service_name
				},
			},
			{
				field: 'network_address',
				headerName: 'Source IP',
				flex: 1,
			},
			{
				headerName: 'Action',
				flex: 2,
				valueGetter: (params: { data?: AdUsageLogsAggregatedSchema }) => {
					if (!params.data) return ''
					return params.data.event_ids
						.map((eventId) => {
							return eventIdDescriptions[eventId.toString()] || 'Unknown event'
						})
						.join(', ')
				},
			},
			{
				field: 'first_time',
				headerName: 'First Day',
				flex: 1,
				cellRenderer: (params: { value: Date }) => (
					<div className="text-textIcon-secondary">{formatDate(params.value.toISOString(), false)}</div>
				),
			},
			{
				field: 'last_time',
				headerName: 'Last Day',
				flex: 1,
				cellRenderer: (params: { value: Date }) => (
					<div className="text-textIcon-secondary">{formatDate(params.value.toISOString(), false)}</div>
				),
			},
			{
				field: 'count',
				headerName: 'Event Count',
				flex: 1,
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	if (!data.length) {
		return <div className={'text-center'}>No Active Directory usage data available</div>
	}

	return (
		<AgGridReact
			className="ag-theme-alpine w-full"
			rowData={data}
			rowHeight={54}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
		/>
	)
}
