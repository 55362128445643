import { RemediationType } from '../../../../../schemas/issue.ts'
import TerraformIcon from '../../../../../assets/terraform_icon.svg?react'
import CloudFormationIcon from '../../../../../assets/cloudformation_icon.svg?react'
import PulumiIcon from '../../../../../assets/pulumi_icon.svg?react'
import AwsIcon from '../../../../../assets/aws_icon.svg?react'
import AzureIcon from '../../../../../assets/azure_icon.svg?react'
import GcpIcon from '../../../../../assets/gcp_icon_20.svg?react'
import EntraIdIcon from '../../../../../assets/entra_id_icon_16.svg?react'
import GoogleWorkspaceIcon from '../../../../../assets/google_workspace_logo_16.svg?react'
import ClassificationIcon from '../../../../../assets/insight_icon_20.svg?react'

export const RemediationTypeToDisplayName: Record<RemediationType, string> = {
	[RemediationType.TERRAFORM]: 'Terraform',
	[RemediationType.CLOUDFORMATION_JSON]: 'CloudFormation (JSON)',
	[RemediationType.CLOUDFORMATION_YAML]: 'CloudFormation (YAML)',
	[RemediationType.PULUMI_GO]: 'Pulumi (Go)',
	[RemediationType.PULUMI_PYTHON]: 'Pulimi (Python)',
	[RemediationType.AWS_CLI]: 'AWS CLI',
	[RemediationType.AZURE_CLI]: 'Azure CLI',
	[RemediationType.GCP_CLI]: 'GCP CLI',
	[RemediationType.ENTRA_CLI]: 'Entra ID CLI',
	[RemediationType.GAM_CLI]: 'GAM CLI',
	[RemediationType.AWS_CONSOLE]: 'AWS Console',
	[RemediationType.AZURE_CONSOLE]: 'Azure Console',
	[RemediationType.GCP_CONSOLE]: 'GCP Console',
	[RemediationType.ENTRA_CONSOLE]: 'Entra ID Console',
	[RemediationType.GOOGLE_WORKSPACE_CONSOLE]: 'Google Workspace Console',
	[RemediationType.CLASSIFICATION]: 'Classification',
	[RemediationType.BULK_CLASSIFICATION]: 'Bulk Classification',
}

export const RemediationTypeToIcon: Record<RemediationType, React.ComponentType<{ className?: string }>> = {
	[RemediationType.TERRAFORM]: TerraformIcon,
	[RemediationType.CLOUDFORMATION_JSON]: CloudFormationIcon,
	[RemediationType.CLOUDFORMATION_YAML]: CloudFormationIcon,
	[RemediationType.PULUMI_GO]: PulumiIcon,
	[RemediationType.PULUMI_PYTHON]: PulumiIcon,
	[RemediationType.AWS_CLI]: AwsIcon,
	[RemediationType.AZURE_CLI]: AzureIcon,
	[RemediationType.GCP_CLI]: GcpIcon,
	[RemediationType.ENTRA_CLI]: EntraIdIcon,
	[RemediationType.GAM_CLI]: GoogleWorkspaceIcon,
	[RemediationType.AWS_CONSOLE]: AwsIcon,
	[RemediationType.AZURE_CONSOLE]: AzureIcon,
	[RemediationType.GCP_CONSOLE]: GcpIcon,
	[RemediationType.ENTRA_CONSOLE]: EntraIdIcon,
	[RemediationType.GOOGLE_WORKSPACE_CONSOLE]: GoogleWorkspaceIcon,
	[RemediationType.CLASSIFICATION]: ClassificationIcon,
	[RemediationType.BULK_CLASSIFICATION]: ClassificationIcon,
}
