import { FronteggUser, useFronteggUsers } from '../../../api/fronteggApi.ts'
import React, { useMemo } from 'react'
import { FronteggUserAvatar } from '../../../components/common/FronteggUserAvatar.tsx'
import { Select } from 'antd'
import { useIssue, useUpdateIssue } from '../../../api/issues.ts'
import { ServerIssue } from '../../../schemas/issue.ts'

const selectClassName = 'w-[240px]'

interface FronteggUserOption extends Partial<FronteggUser> {
	label: React.ReactNode
	value: string
}

const filterAssigneeOption = (input: string, option?: FronteggUserOption): boolean => {
	const lowerInput = input.toLowerCase()
	return (
		option?.email?.toLowerCase().includes(lowerInput) || option?.name?.toLowerCase().includes(lowerInput) || false
	)
}

type SelectAssigneeProps = {
	issueId: string
	currentIssue?: ServerIssue
}

export const SelectAssignee = ({ issueId, currentIssue }: SelectAssigneeProps) => {
	const { mutate: updateIssue, isPending: isIssueUpdating } = useUpdateIssue()
	const {
		data: issue,
		isPending: isIssuePending,
		isError: isIssueError,
	} = useIssue(issueId, { enabled: Boolean(!currentIssue) })
	const { data: fronteggUsers, isPending: isFronteggUsersPending, isError: isFronteggUsersError } = useFronteggUsers()
	const options: FronteggUserOption[] = useMemo(
		() =>
			fronteggUsers
				? Object.values(fronteggUsers).map((fronteggUser) => ({
						...fronteggUser,
						value: fronteggUser.id,
						label: <FronteggUserAvatar user={fronteggUser} compact />,
					}))
				: [],
		[fronteggUsers],
	)

	if (isFronteggUsersError || isIssueError) {
		return null
	}

	if (isFronteggUsersPending || (!currentIssue && isIssuePending)) {
		return <Select disabled loading className={selectClassName} placeholder="Loading..." />
	}

	const assignee = currentIssue?.assignee || issue?.assignee || null

	const value: FronteggUserOption | null = assignee
		? options.find((option) => option.value === assignee) || {
				value: assignee,
				label: <FronteggUserAvatar compact />,
			}
		: null

	return (
		<Select
			className={selectClassName}
			showSearch
			placeholder="Select assignee"
			labelInValue
			loading={isIssueUpdating}
			disabled={isIssueUpdating}
			onChange={(newValue?: FronteggUserOption | null) => {
				updateIssue({ issueId, issueUpdate: { assignee: newValue?.value ?? null } })
			}}
			value={value}
			filterOption={filterAssigneeOption}
			options={options}
			allowClear
		/>
	)
}
