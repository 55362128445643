import LogRocket from 'logrocket'
import { settings } from '../../env-settings.ts'
import * as Sentry from '@sentry/react'
import { isDev } from '../../utils/devUtils.ts'
import { type ITenantsResponseV2 } from '@frontegg/rest-api/tenants/interfaces'
import { type User } from '@frontegg/redux-store/auth/interfaces'
import { router } from '../../routes/Router.ts'

export const initSentry = (currentTenant: ITenantsResponseV2, user: User) => {
	if (!isDev && settings.viteSentryDsn) {
		Sentry.init({
			dsn: settings.viteSentryDsn,
			environment: settings.mode,
			tracePropagationTargets: [
				/^https:\/\/api\.dev\.token\.security\/api/,
				/^https:\/\/api\.app\.token\.security\/api/,
			],
			integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)], // Performance Monitoring
			tracesSampleRate: 1.0, // Capture 100% of the transactions
			release: __VITE_SENTRY_RELEASE__,
		})

		Sentry.setTag('tenantId', currentTenant.tenantId)
		Sentry.setTag('tenantName', currentTenant.name)
		Sentry.setTag('userEmail', user.email)
		Sentry.setTag('impersonatingUserId', user.act?.sub)

		LogRocket.getSessionURL((sessionURL) => {
			Sentry.getCurrentScope().setExtra('sessionURL', sessionURL)
		})

		const defaultErrorHandler = console.error
		console.error = (...args: unknown[]) => {
			Sentry.captureException(args)
			defaultErrorHandler.apply(console, args)
		}
	}
}
