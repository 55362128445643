import {
	NotificationActionType,
	NotificationChannel,
	NotificationStatus,
	ServerNotificationInput,
} from '../../schemas/notifications.ts'

export const notifications: Array<ServerNotificationInput> = [
	{
		playbook_id: '943c7b19-3d3a-407b-becb-287b0f2572c4',
		name: 'High or Critical Issues',
		description: 'Alerts on any High or Critical new findings in my environment',
		action_type: NotificationActionType.SEND_MESSAGE,
		status: NotificationStatus.ENABLED,
		trigger_json: {
			filter: {
				AND: [
					{
						op: 'gt',
						field: 'created_at',
						value: 'now - 1 days',
					},
					{
						op: 'gt',
						field: 'priority',
						value: '2',
					},
				],
			},
		},
		integration_id: 'ddbdf897-fb8e-4f26-a27e-89c81737d4cf',
		integration_type: NotificationChannel.SLACK,
		integration_name: 'slack',
		integration_data: {
			ok: true,
			team: {
				id: 'T03MYNJH3HC',
				name: 'Token Security',
			},
			scope: 'channels:read,chat:write,im:write,incoming-webhook,users:read,users:read.email,files:write,links:write,links.embed:write,chat:write.public,reactions:write',
			app_id: 'A07M0G33QKW',
			enterprise: null,
			token_type: 'bot',
			authed_user: {
				id: 'U06R1V969JN',
			},
			bot_user_id: 'U07LXUH1K1R',
			access_token: 'xoxb-550-122-11',
			incoming_webhook: {
				url: 'https://hooks.slack.com/services/T03MYNJH3HC/1/TCloZOF6oCZrhkfgYXx0aq6i',
				channel: '#slack-token-integration',
				channel_id: '1',
				configuration_url: 'https://token-security.slack.com/services/1',
			},
			is_enterprise_install: false,
		},
		integration_enabled: true,
		integration_webhook_channel: '#production-issues',
	},
	{
		playbook_id: '943c7b19-3d3a-407b-becb-287b0f2572c3',
		name: 'High or Critical Issues',
		description: 'Alerts on any High or Critical new findings in my environment',
		action_type: NotificationActionType.SEND_MESSAGE,
		status: NotificationStatus.ENABLED,
		trigger_json: {
			filter: {
				AND: [
					{
						op: 'gt',
						field: 'created_at',
						value: 'now - 1 days',
					},
					{
						op: 'gt',
						field: 'priority',
						value: '2',
					},
				],
			},
		},
		integration_id: 'ddbdf897-fb8e-4f26-a27e-89c81737d4cd',
		integration_type: NotificationChannel.DEMO_JIRA,
		integration_name: 'jira',
		integration_data: {},
		integration_enabled: true,
		integration_webhook_channel: 'Security project',
	},
	{
		playbook_id: '943c7b19-3d3a-407b-becb-287b0f2572c2',
		name: 'High or Critical Issues',
		description: 'Alerts on any High or Critical new findings in my environment',
		action_type: NotificationActionType.SEND_MESSAGE,
		status: NotificationStatus.ENABLED,
		trigger_json: {
			filter: {
				AND: [
					{
						op: 'gt',
						field: 'created_at',
						value: 'now - 1 days',
					},
					{
						op: 'gt',
						field: 'priority',
						value: '2',
					},
				],
			},
		},
		integration_id: 'ddbdf897-fb8e-4f26-a27e-89c81737d4ce',
		integration_type: NotificationChannel.TEAMS,
		integration_name: 'teams',
		integration_data: {},
		integration_enabled: true,
		integration_webhook_channel: 'Security Alerts',
	},
]
