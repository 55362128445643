import { z } from 'zod'
import { IntegrationType } from '../../jiraIntegration/schemas/common.ts'
import { DateSchema } from '../common.ts'

export const SlackIntegrationConfigSchema = z.object({
	type: z.literal(IntegrationType.SLACK),
})

export type SlackIntegrationConfig = z.infer<typeof SlackIntegrationConfigSchema>

export const JiraIntegrationConfigSchema = z.object({
	type: z.literal(IntegrationType.JIRA),
	base_url: z.string(),
	user_name: z.string(),
	api_token: z.string(),
})

export type JiraIntegrationConfig = z.infer<typeof JiraIntegrationConfigSchema>

export const TeamsIntegrationConfigSchema = z.object({
	type: z.literal(IntegrationType.TEAMS),
	webhook_url: z.string(),
})

export type TeamsIntegrationConfig = z.infer<typeof TeamsIntegrationConfigSchema>

export const CollaborationIntegrationTestConnectionRequestSchema = z.object({
	integration_type: z.nativeEnum(IntegrationType),
	config: z.discriminatedUnion('type', [
		JiraIntegrationConfigSchema,
		SlackIntegrationConfigSchema,
		TeamsIntegrationConfigSchema,
	]),
})

export type CollaborationIntegrationTestConnectionRequest = z.infer<
	typeof CollaborationIntegrationTestConnectionRequestSchema
>

export const CollaborationIntegrationCreateRequestSchema = z.object({
	integration_type: z.nativeEnum(IntegrationType),
	name: z.string(),
	config: z.discriminatedUnion('type', [
		JiraIntegrationConfigSchema,
		SlackIntegrationConfigSchema,
		TeamsIntegrationConfigSchema,
	]),
})

export type CollaborationIntegrationCreateRequest = z.infer<typeof CollaborationIntegrationCreateRequestSchema>

export const CollaborationIntegrationTestConnectionResponseSchema = z.object({
	success: z.boolean(),
	message: z.string(),
})

export type CollaborationIntegrationTestConnectionResponse = z.infer<
	typeof CollaborationIntegrationTestConnectionResponseSchema
>

export const BaseIntegrationResponseSchema = z.object({
	id: z.string().uuid(),
	customer_id: z.string().uuid(),
	name: z.string(),
	enabled: z.boolean(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
})

export type BaseIntegrationResponse = z.infer<typeof BaseIntegrationResponseSchema>

export const SlackIntegrationResponseSchema = BaseIntegrationResponseSchema.extend({
	team_id: z.string().nullable(),
	team_name: z.string().nullable(),
	scope: z.string().nullable(),
	app_id: z.string().nullable(),
	bot_user_id: z.string().nullable(),
	webhook_channel: z.string().nullable(),
	webhook_channel_id: z.string().nullable(),
	webhook_configuration_url: z.string().nullable(),
	authed_user_id: z.string().nullable(),
	token_type: z.string().nullable(),
	enterprise: z.record(z.any()).nullable(),
	is_enterprise_install: z.boolean().nullable(),
})

export type SlackIntegrationResponse = z.infer<typeof SlackIntegrationResponseSchema>

export const JiraIntegrationResponseSchema = BaseIntegrationResponseSchema.extend({
	base_url: z.string().nullable(),
})

export type JiraIntegrationResponse = z.infer<typeof JiraIntegrationResponseSchema>

export const CollaborationIntegrationResponseSchema = z.object({
	integration_type: z.nativeEnum(IntegrationType),
	integration: z.union([
		SlackIntegrationResponseSchema,
		JiraIntegrationResponseSchema,
		BaseIntegrationResponseSchema,
	]),
})

export type CollaborationIntegrationResponse = z.infer<typeof CollaborationIntegrationResponseSchema>

export const SlackIntegrationsSearchSchema = z.object({
	integrationStatus: z.string().optional(),
})

export type SlackIntegrationsSearch = z.infer<typeof SlackIntegrationsSearchSchema>

export enum IntegrationResultStatus {
	SUCCESS = 'success',
	FAIL = 'fail',
	EXISTS = 'exists',
}

export const IntegrationsSearchSchema = z.object({
	integrationType: z.string().optional(),
	integrationStatus: z.nativeEnum(IntegrationResultStatus).optional(),
	openModal: z.string().optional(),
})

export type IntegrationsSearch = z.infer<typeof IntegrationsSearchSchema>

export const CollaborationIntegrationCreateResponseSchema = z.object({
	integration_id: z.string().uuid().nullable(),
	message: z.string(),
})

export type CollaborationIntegrationCreateResponse = z.infer<typeof CollaborationIntegrationCreateResponseSchema>
