import { type FC } from 'react'
import { Tooltip } from 'antd'
import cx from 'classnames'
import { highlightSearchText } from '../../utils/highlightUtils'

interface Props {
	owners: string[]
	maxVisible?: number
	searchText?: string
}

const getDisplayText = (identifier: string): string => {
	if (identifier.toLowerCase().startsWith('arn:')) {
		if (identifier.endsWith(':root')) {
			return 'R'
		}
		return (identifier.split('/').pop() ?? '').slice(0, 2).toUpperCase()
	}

	return identifier.slice(0, 2).toUpperCase()
}

export const OverlappingOwnershipTags: FC<Props> = ({ owners, maxVisible = 3, searchText }) => {
	if (!owners.length) return null

	const visibleTags = owners.slice(0, maxVisible)
	const remainingOwners = owners.slice(maxVisible)
	const remainingCount = remainingOwners.length

	const getOuterTagClasses = cx(
		'flex items-center justify-center relative',
		'w-7 h-7',
		'border shadow rounded',
		'bg-status-infoLowContrast text-status-info border-dsBlue-100',
		'text-xs',
		'transition-transform hover:scale-105 hover:z-10',
	)

	const getInnerTagClasses = (owner: string) =>
		cx(
			'absolute',
			'top-[5px] bottom-[5px] left-[5px] right-[5px]',
			'flex items-center justify-center',
			searchText && owner.toLowerCase().includes(searchText.toLowerCase()) ? 'bg-dsPink-400/20' : '',
		)

	return (
		<div className={'flex items-center gap-1'}>
			<div className="flex gap-1 px-1">
				{visibleTags.map((owner, index) => (
					<Tooltip key={`${owner}-${index}`} title={highlightSearchText(owner, searchText)}>
						<div className={getOuterTagClasses}>
							<div className={getInnerTagClasses(owner)} />
							<span className="z-10">{getDisplayText(owner)}</span>
						</div>
					</Tooltip>
				))}
			</div>

			{remainingCount > 0 && (
				<Tooltip
					title={
						<div className="flex flex-col gap-1">
							{remainingOwners.map((owner, index) => (
								<span key={index}>{highlightSearchText(owner, searchText)}</span>
							))}
						</div>
					}
				>
					<span className="text-textIcon-secondary text-sm ml-0.5 hover:text-textIcon-primary">
						+{remainingCount}
					</span>
				</Tooltip>
			)}
		</div>
	)
}
