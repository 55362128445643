import React from 'react'
import { SingleIntegrationGenericCard } from './SingleIntegrationGenericCard'
import { IntegrationIcon } from '../../common/components/IntegrationIcon'
import SnowflakeIcon from '../../../../assets/snowflake_icon.svg?react'
import { ServerSnowflakeEnvironment } from '../../../../schemas/environments/snowflakeEnvironment.ts'
import { IntegrationsSection } from '../components/IntegrationsSection.tsx'
import { IntegrationCardLogsContent } from '../../common/components/IntegrationCardLogsContent.tsx'

type SnowflakeSinglePageIntegrationBodyProps = {
	snowflakeEnvironments: ServerSnowflakeEnvironment[]
}

export const SnowflakeSinglePageIntegrationBody: React.FC<SnowflakeSinglePageIntegrationBodyProps> = ({
	snowflakeEnvironments,
}) => {
	return (
		<IntegrationsSection>
			{snowflakeEnvironments?.map((environment) => {
				const cardConfig = {
					title: environment.name,
					logo: <IntegrationIcon leftIcon={SnowflakeIcon} />,
					fields: [
						{ label: 'Account', value: environment.account_identifier },
						{
							label: 'Logs',
							disableTooltip: true,
							value: <IntegrationCardLogsContent logsConnected={true} environmentCount={1} />,
						},
					],
				}
				return (
					<SingleIntegrationGenericCard
						key={environment.id}
						title={cardConfig.title}
						logo={cardConfig.logo}
						fields={cardConfig.fields}
					/>
				)
			})}
		</IntegrationsSection>
	)
}
