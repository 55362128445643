import { z } from 'zod'

export const ServerMetricsTopBarSchema = z.object({
	identity_posture_score: z.number(),
	total_identities: z.number(),
	admin_count: z.number(),
	admin_high_issue_count: z.number(),
	admin_critical_issue_count: z.number(),
	high_count_latest: z.number(),
	critical_count_latest: z.number(),
	total_identities_x_days_ago: z.number(),
	high_count_x_days_ago: z.number(),
	critical_count_x_days_ago: z.number(),
	itdr_issues_count: z.number(),
	itdr_issues_count_x_days_ago: z.number(),
})

export type ServerMetricsTopBarInput = z.input<typeof ServerMetricsTopBarSchema>
export type ServerMetricsTopBar = z.infer<typeof ServerMetricsTopBarSchema>
