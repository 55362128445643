import { z } from 'zod'
import { UsageAssociationToActionsSchema } from './usageActionItemSchema'
import { UsageAssociationItemSchema } from './usageAssociationItemSchema'

export const ServerUsageLogGroupedSchema = z.object({
	user_identifier: z.string(),
	key: z.string(),
	associations: z.array(UsageAssociationItemSchema),
	events: UsageAssociationToActionsSchema,
})
export type ServerUsageLogGroupedInput = z.input<typeof ServerUsageLogGroupedSchema>
export type ServerUsageLogGrouped = z.infer<typeof ServerUsageLogGroupedSchema>
