import { Node, NodeProps } from '@xyflow/react'
import { GroupOutlined } from '@ant-design/icons'
import { ServerAzureManagementGroup } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { BasePermissionNode } from '../../common/baseNodes/BasePermissionNode.tsx'

export type AzureManagementGroupNodeType = Node<{ managementGroup: ServerAzureManagementGroup }, 'azureManagementGroup'>
type AzureManagementGroupNodeProps = NodeProps<AzureManagementGroupNodeType>

export const AzureManagementGroupNode = ({ data: { managementGroup } }: AzureManagementGroupNodeProps) => (
	<BasePermissionNode
		label="Azure Management Group"
		name={{
			text: managementGroup.display_name || 'Unnamed Management Group',
			tooltipText: `ID: ${managementGroup.id || 'N/A'}`,
		}}
		icon={<GroupOutlined />}
	/>
)
