import { api } from './api'
import {
	EnvironmentCreateRequest,
	EnvironmentCreateResponse,
	ServerEnvironmentsSchema,
	ServerEnvironments,
	EnvironmentTestConnectionRequest,
	EnvironmentTestConnectionResponse,
	EnvironmentTestConnectionResponseSchema,
} from '../schemas/environments/environments'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Account, AccountSchema } from '../schemas/environments/accounts'

export const environmentsApi = {
	async getAll(): Promise<ServerEnvironments> {
		const { data: serverEnvironments } = await api.get<ServerEnvironments>(`/api/environments`)
		return ServerEnvironmentsSchema.parse(serverEnvironments)
	},
	async testConnection(request: EnvironmentTestConnectionRequest): Promise<EnvironmentTestConnectionResponse> {
		const { data } = await api.post<EnvironmentTestConnectionResponse>('/api/environments/test-connection', request)
		return EnvironmentTestConnectionResponseSchema.parse(data)
	},
	async create(request: EnvironmentCreateRequest): Promise<EnvironmentCreateResponse> {
		const { data } = await api.post<EnvironmentCreateResponse>('/api/environments', request)
		return data
	},
	async generateGcpServiceAccount(orgId: string): Promise<string> {
		const { data } = await api.post<string>('/api/environments/gcp/service-account', {
			org_id: orgId,
		})

		return data
	},
	async deleteGcpServiceAccount(serviceAccountEmail: string): Promise<void> {
		try {
			await api.delete('/api/environments/gcp/service-account', {
				data: {
					service_account_email: serviceAccountEmail,
				},
			})
		} catch (error) {
			console.error('Error deleting GCP service account:', error)
		}
	},
}

const accountsApi = {
	async getAll(): Promise<Account[]> {
		const { data: serverAccounts } = await api.get<Account[]>(`/api/accounts`)
		return serverAccounts.map((serverAccount) => AccountSchema.parse(serverAccount))
	},
	async updateAccounts(accounts: Account[]): Promise<void> {
		await api.put(`/api/accounts`, accounts)
	},
}

export function useEnvironments() {
	return useQuery({
		queryKey: ['environments'],
		gcTime: Infinity,
		staleTime: Infinity,
		queryFn: async () => {
			return await environmentsApi.getAll()
		},
	})
}

export function useAccounts() {
	return useQuery({
		queryKey: ['accounts'],
		gcTime: Infinity,
		staleTime: Infinity,
		queryFn: async () => {
			return await accountsApi.getAll()
		},
	})
}

export function useUpdateAccounts() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['updateAccounts'],
		mutationFn: async (accounts: Account[]) => {
			await accountsApi.updateAccounts(accounts)
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: ['accounts'], refetchType: 'active' })
		},
	})
}

export function useTestConnection() {
	return useMutation({
		mutationFn: async (request: EnvironmentTestConnectionRequest) => {
			return await environmentsApi.testConnection(request)
		},
	})
}

export function useCreateEnvironment() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: async (request: EnvironmentCreateRequest) => {
			return await environmentsApi.create(request)
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: ['environments'] })
			void queryClient.invalidateQueries({ queryKey: ['customerIntegrationStatus'] })
		},
	})
}
