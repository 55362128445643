import { IssueName, accessKeyIssueNames } from '../../../../schemas/issue.ts'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { AccessKeyNodeType } from '../aws/AccessKeyNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { getCombinedAwsIamRolePolicy, getPolicyUniqueKey } from '../../../../utils/awsIdentityUtils.ts'
import { CombinedAwsIamRolePolicy } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { EdgeType, getEdge } from '../graphUtils/nodesAndEdges.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { AwsRoleNodeType } from '../aws/AwsRoleNode.tsx'
import { AwsPolicyNodeType } from '../aws/AwsPolicyNode.tsx'
import { AwsIamUserNodeType } from '../aws/AwsIamUserNode.tsx'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	accessKeyIssue: 1,
	identity: 2,
	accessKey: 3,
	user: 4,
	role: 5,
	policy: 6,
}

const identityNodeRowIndex = 0
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getKubernetesNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Array<EdgeType>] => {
	const edges: Array<EdgeType> = []
	const identityNodes: Array<BareNodeType<IdentityNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]
	const xcIdentityNodes: Array<BareNodeType<AwsIamUserNodeType> | BareNodeType<AwsRoleNodeType>> = []

	const accessKeyIssueNodes: BareNodeType<IssueNodeType>[] = []
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issue_name && accessKeyIssueNames.includes(issue.issue_name)) {
			accessKeyIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.accessKeyIssue}-${accessKeyIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})

	const accessKeyNodes: BareNodeType<AccessKeyNodeType>[] = identity.kubernetes_resource?.access_keys
		? [
				{
					type: 'accessKey',
					data: { accessKey: identity.kubernetes_resource.access_keys },
					id: `${nodeLogicalTypeToColumnId.accessKey}-0`,
				},
			]
		: []

	const policies: CombinedAwsIamRolePolicy[] = []

	if (identity.kubernetes_resource?.aws_iam_users_xc?.length) {
		const iAmUsers = identity.kubernetes_resource.aws_iam_users_xc
		iAmUsers.forEach((iamUserXc) => {
			policies.push(...getCombinedAwsIamRolePolicy(iamUserXc))
			const userNodeId = `${nodeLogicalTypeToColumnId.user}-${xcIdentityNodes.length}`
			xcIdentityNodes.push({
				type: 'awsIamUser',
				id: userNodeId,
				data: {
					awsIamUserXc: iamUserXc,
				},
			})

			edges.push(
				getEdge({
					source: identityNodeId,
					target: userNodeId,
				}),
			)
		})
	}

	const policyNodes: BareNodeType<AwsPolicyNodeType>[] = policies?.map((policy, index) => ({
		type: 'awsPolicy',
		data: { policy },
		id: `${nodeLogicalTypeToColumnId.policy}-${index}`,
	}))

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	accessKeyNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.accessKey}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	accessKeyIssueNodes.forEach((issueNode, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKeyIssue}-${index}`
		// There are no access key nodes - connect the issue node to the identity node.
		if (!accessKeyNodes.length) {
			edges.push(getEdge({ source: source, target: identityNodeId }))
			return
		}

		// There are access keys and the issue is multiple access keys - connect the issue to all access key nodes.
		if (issueNode.data.issue.issue_name === IssueName.MultipleAccessKeys) {
			accessKeyNodes.forEach((_, accessKeyIndex) => {
				const target = `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyIndex}`
				edges.push(getEdge({ source, target }))
			})
		} else {
			// There are access keys and the issue node should connect to one of them - try and find the relevant
			//  access key node. If it is not found (e.g. the access key was already deleted) - connect the issue node
			//  to the identity node.

			const accessKeysNodeIndices: number[] = []

			accessKeyNodes.forEach((accessKeyNode, accessKeyNodeIndex) => {
				if (
					!!accessKeyNode.data.accessKey?.access_key_id &&
					issueNode.data.issue.description?.includes(accessKeyNode.data.accessKey?.access_key_id)
				) {
					accessKeysNodeIndices.push(accessKeyNodeIndex)
				}
			})

			// If we found relevant credentials nodes - connect the issue to each of them.
			if (accessKeysNodeIndices.length) {
				accessKeysNodeIndices.forEach((accessKeyNodeIndex) => {
					const target = `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyNodeIndex}`
					edges.push(getEdge({ source, target }))
				})
			} else {
				// If no relevant credentials are found for this issue - connect it to the identity node.
				edges.push(getEdge({ source, target: identityNodeId }))
			}
		}
	})

	// Handle aws key pair base identity - multiple roles and policies possible
	const roleNodes: BareNodeType<AwsRoleNodeType>[] = []
	const policyToRoleNodes: BareNodeType<AwsPolicyNodeType>[] = []
	if (identity.kubernetes_resource?.aws_iam_role_details_xc?.length) {
		const roleDetails = identity.kubernetes_resource?.aws_iam_role_details_xc
		roleDetails.forEach((role) => {
			roleNodes.push({
				type: 'awsIamRole',
				data: { awsIamRoleXc: role },
				id: `${nodeLogicalTypeToColumnId.role}-${roleNodes.length}`,
			})

			// Create policy nodes and edges to the role
			const policies = getCombinedAwsIamRolePolicy(null, role)
			policies.forEach((policy) => {
				if (
					!policyToRoleNodes.find(
						(node) => getPolicyUniqueKey(node.data.policy) === getPolicyUniqueKey(policy),
					)
				)
					policyToRoleNodes.push({
						type: 'awsPolicy',
						data: { policy },
						id: `${nodeLogicalTypeToColumnId.policy}-${policyToRoleNodes.length}`,
					})
				const index = policyToRoleNodes.findIndex(
					(node) => getPolicyUniqueKey(node.data.policy) === getPolicyUniqueKey(policy),
				)
				// Add edge between role and policy based on direction
				edges.push(
					getEdge({
						source: `${nodeLogicalTypeToColumnId.role}-${roleNodes.length - 1}`,
						target: `${nodeLogicalTypeToColumnId.policy}-${index}`,
					}),
				)
			})
		})
		// Connect roles to identity node
		roleNodes.forEach((_, index) => {
			const target = `${nodeLogicalTypeToColumnId.role}-${index}`
			edges.push(getEdge({ source: identityNodeId, target }))
		})
	}

	xcIdentityNodes.forEach((_, xcIdentityIndex) => {
		policies.forEach((policy) => {
			if (!policyToRoleNodes.find((node) => getPolicyUniqueKey(node.data.policy) === getPolicyUniqueKey(policy)))
				policyToRoleNodes.push({
					type: 'awsPolicy',
					data: { policy },
					id: `${nodeLogicalTypeToColumnId.policy}-${policyToRoleNodes.length}`,
				})
			const index = policyToRoleNodes.findIndex(
				(node) => getPolicyUniqueKey(node.data.policy) === getPolicyUniqueKey(policy),
			)
			const source = `${nodeLogicalTypeToColumnId.user}-${xcIdentityIndex}`
			const target = `${nodeLogicalTypeToColumnId.policy}-${index}`

			edges.push(getEdge({ source, target }))
		})
	})

	// Each item represents a column in the graph. Order in the array will be the order in the graph (left->right)
	return [
		[
			{ yPosition: 'top', nodes: [...accessKeyIssueNodes, ...generalIssueNodes] },
			{ yPosition: 'center', nodes: identityNodes },
			{ yPosition: 'center', nodes: accessKeyNodes },
			{ yPosition: 'center', nodes: xcIdentityNodes },
			{ yPosition: 'center', nodes: policyToRoleNodes.length > 0 ? policyToRoleNodes : policyNodes },
		],
		edges,
	]
}
