import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-enterprise'
import { Tooltip } from 'antd'
import { DemoAtlassianAggregatedUsageLog } from '../../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'

type DemoAtlassianUsageTableProps = {
	entries: Array<DemoAtlassianAggregatedUsageLog>
}

const defaultColDef: ColDef = {
	sortable: true,
	resizable: true,
	filter: true,
	cellStyle: () => ({
		display: 'flex',
		alignItems: 'center',
	}),
	flex: 1,
}

export const DemoAtlassianUsageTable = ({ entries }: DemoAtlassianUsageTableProps) => {
	const columnDefs: ColDef<DemoAtlassianAggregatedUsageLog>[] = [
		{
			field: 'ip',
			headerName: 'IP',
		},
		{
			field: 'associations',
			headerName: 'Association',
			filter: 'agTextColumnFilter',
			flex: 0.7,
		},
		{
			field: 'events',
			headerName: 'Actions',
			filter: 'agTextColumnFilter',
			cellRenderer: (params: { value: string }) => {
				const splitValue = params.value.split(', ')
				if (splitValue.length < 2) {
					return splitValue[0]
				}

				return (
					<Tooltip
						placement="topLeft"
						title={
							<div className="flex flex-col">
								{splitValue.map((action) => (
									<span key={action}>{action}</span>
								))}
							</div>
						}
					>
						<span>{params.value}</span>
					</Tooltip>
				)
			},
		},
		{
			field: 'events_count',
			headerName: 'Events Count',
			sort: 'desc',
		},
		{
			field: 'key_name',
			headerName: 'Key Name',
		},
		{
			field: 'first_day',
			headerName: 'First Day',
		},
		{
			field: 'last_day',
			headerName: 'Last Day',
		},
	]

	return (
		<div className="h-48">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				rowHeight={54}
				rowData={entries}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				enableCellTextSelection
				suppressHorizontalScroll={false}
			/>
		</div>
	)
}
