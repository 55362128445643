import { Collapse, CollapseProps } from 'antd'
import { ServerIdentity, IdentitySource } from '../../../schemas/identity.ts'
import { ServerAwsTag } from '../../../schemas/identities/awsPoliciesSchema.ts'
import { AwsTagsTable } from './tables/aws/AwsTagsTable.tsx'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel.tsx'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import IdentityTagsIcon from '../../../assets/identity_tags_icon_16.svg?react'
import VaultTagsIcon from '../../../assets/vault_tags_icon_16.svg?react'

const transformAzureKVTagsToAwsTags = (tags: Record<string, string> | null | undefined): ServerAwsTag[] => {
	if (!tags) return []
	return Object.entries(tags).map(([key, value]) => ({
		Key: key,
		Value: value,
	}))
}

export type TagsCollapseItemKeys = 'base-identity-tags' | 'azure-vault-tags'

type TagsCollapseItem = Required<CollapseProps>['items'][number] & {
	key: TagsCollapseItemKeys
}

type SidePanelTagsProps = {
	identity: ServerIdentity
	activeTagsKeys: TagsCollapseItemKeys[]
	setActiveTagsKeys: Dispatch<SetStateAction<TagsCollapseItemKeys[]>>
}

export const SidePanelTags = ({ identity, activeTagsKeys, setActiveTagsKeys }: SidePanelTagsProps) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList = typeof key === 'string' ? [key as TagsCollapseItemKeys] : (key as TagsCollapseItemKeys[])
			setActiveTagsKeys(keyList)
		},
		[setActiveTagsKeys],
	)

	// Compute items only when identity changes
	const items = useMemo((): TagsCollapseItem[] => {
		if (identity.source === IdentitySource.AWS_IAM_USER || identity.source === IdentitySource.AWS_IAM_ROLE) {
			return []
		}

		const items: TagsCollapseItem[] = []

		const azureKvTags =
			identity.azure_kv_secret?.tags || identity.azure_kv_certificate?.tags || identity.azure_kv_key?.tags

		if (azureKvTags && Object.keys(azureKvTags).length > 0) {
			items.push({
				label: <CollapsibleItemLabel label={'Identity Tags'} icon={IdentityTagsIcon} />,
				key: 'base-identity-tags',
				children: <AwsTagsTable tags={transformAzureKVTagsToAwsTags(azureKvTags)} />,
			})
		}

		const azureVault =
			identity.azure_kv_secret?.key_vault ||
			identity.azure_kv_certificate?.key_vault ||
			identity.azure_kv_key?.key_vault

		if (azureVault && azureVault.tags && Object.keys(azureVault.tags).length > 0) {
			items.push({
				label: <CollapsibleItemLabel label={'Vault Tags'} icon={VaultTagsIcon} />,
				key: 'azure-vault-tags',
				children: <AwsTagsTable tags={transformAzureKVTagsToAwsTags(azureVault.tags)} />,
			})
		}

		return items
	}, [identity])

	// Render content based on computed items
	const content = useMemo((): JSX.Element | string | undefined => {
		switch (identity.source) {
			case IdentitySource.AWS_IAM_USER:
			case IdentitySource.AWS_IAM_ROLE: {
				const tags =
					identity.aws_iam_user?.aws_iam_user_details_xc?.Tags ||
					identity.aws_iam_role?.aws_iam_role_details_xc?.Tags ||
					[]

				if (!tags.length) {
					return 'No tags data'
				}
				return <AwsTagsTable tags={tags} />
			}

			case IdentitySource.AZURE_KV_SECRET:
			case IdentitySource.AZURE_KV_CERTIFICATE:
			case IdentitySource.AZURE_KV_KEY: {
				if (!items.length) {
					return 'No tags data'
				}

				return (
					<div onMouseLeave={() => setHighlightedNodes([])}>
						<Collapse
							onChange={onCollapseChange}
							activeKey={activeTagsKeys}
							className="bg-surface-primary"
							expandIconPosition={'end'}
							items={items}
						/>
					</div>
				)
			}

			default:
				console.error(`Tags are not supported for ${identity.source}`)
		}
	}, [identity.source, items, activeTagsKeys, onCollapseChange, setHighlightedNodes])

	return content ?? 'No tags data'
}
