import { RoutePaths } from '../RoutePaths.tsx'
import { inventoryUrlQuickSearchFilterParameter } from '../../schemas/identity.ts'
import { TableTextFilterEntry } from '../../schemas/tableFilter.ts'
import { useSearch } from '@tanstack/react-router'

export const useIdentitiesQuickSearchText = (): string => {
	const { identityFilter: searchIdentityFilter } = useSearch({ from: RoutePaths.Identities })
	const filterSearchText = searchIdentityFilter?.[inventoryUrlQuickSearchFilterParameter] as TableTextFilterEntry
	const searchText = filterSearchText?.filter
	return searchText ?? ''
}
