import { Node, NodeProps } from '@xyflow/react'
import { UserOutlined } from '@ant-design/icons'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { ServerAwsIamUserPermission } from '../../../../schemas/identities/awsIamUserXcSchema'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode'

export type AwsIamUserNodeType = Node<{ awsIamUserXc: ServerAwsIamUserPermission }, 'awsIamUser'>
type AwsIamUserNodeProps = NodeProps<AwsIamUserNodeType>

export const AwsIamUserNode = ({ data: { awsIamUserXc } }: AwsIamUserNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'awsIamUser' && highlightedNode.id === awsIamUserXc.UserId,
	)

	return (
		<BasePermissionNode
			label="AWS IAM User"
			name={{ text: awsIamUserXc.UserName || 'Unknown IAM user' }}
			icon={<UserOutlined />}
			isHighlighted={isHighlighted}
		/>
	)
}
