import { PriorityTypeEnum, PriorityTypeNameToNumber } from '../../../schemas/issue.ts'
import React from 'react'
import { ServerMetricsTopBar } from '../../../schemas/metrics/metricsTopBar.ts'
import { Tooltip } from 'antd'
import { PriorityBar } from '../../../components/common/PriorityBar.tsx'
import { formatNumber } from '../../../utils/numberUtils.ts'
import { Link, LinkProps, useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { IdentitiesPageSearch } from '../../../schemas/identity.ts'
import { Tag } from '../../../schemas/tags.ts'

const getIdentitiesLinkProps = (priorities?: string[]): LinkProps => {
	const search: IdentitiesPageSearch = {
		identityFilter: { tags_names: { filterType: 'set', values: [Tag.ADMIN] } },
	}
	if (priorities) {
		const convertedPriorities = priorities.map((priority) => {
			return `${PriorityTypeNameToNumber[priority as PriorityTypeEnum]}`
		})

		search.identityFilter!.max_priority = { filterType: 'set', values: convertedPriorities }
	}

	return {
		from: RoutePaths.Metrics,
		to: RoutePaths.Identities,
		search,
	} satisfies LinkProps
}

export const TotalAdminsAtRisk: React.FunctionComponent<{
	metricsTopBar: ServerMetricsTopBar
}> = ({ metricsTopBar }) => {
	const navigate = useNavigate({ from: RoutePaths.Metrics })

	return (
		<div className="h-full flex gap-4">
			<div className="flex gap-1 items-end self-start">
				<Link {...getIdentitiesLinkProps([PriorityTypeEnum.HIGH, PriorityTypeEnum.CRITICAL])}>
					{formatNumber(metricsTopBar.admin_high_issue_count + metricsTopBar.admin_critical_issue_count)}
				</Link>
				<span className="text-textIcon-tertiary text-base">
					{'/ '}
					<Tooltip title="Total admins">
						<span>
							<Link {...getIdentitiesLinkProps()}>{formatNumber(metricsTopBar.admin_count)}</Link>
						</span>
					</Tooltip>
				</span>
			</div>
			<PriorityBar
				findingsByPriority={[
					{ priority: PriorityTypeEnum.CRITICAL, count: metricsTopBar.admin_critical_issue_count },
					{ priority: PriorityTypeEnum.HIGH, count: metricsTopBar.admin_high_issue_count },
				]}
				onPriorityNameTagClick={(priority) => {
					void navigate(getIdentitiesLinkProps([priority]))
				}}
			/>
		</div>
	)
}
