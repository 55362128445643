import { z } from 'zod'
import { DateSchema } from '../../../common.ts'

const ServerAzureKeyVaultPermissionsSchema = z.object({
	keys: z.array(z.string()).nullish(),
	secrets: z.array(z.string()).nullish(),
	storage: z.array(z.string()).nullish(),
	certificates: z.array(z.string()).nullish(),
})

const PrincipalTypeEnum = z.enum(['User', 'ServicePrincipal'])

export const ServerAzureKeyVaultAccessPolicySchema = z.object({
	object_id: z.string(),
	tenant_id: z.string(),
	permissions: ServerAzureKeyVaultPermissionsSchema,
	principal_name: z.string(),
	principal_type: PrincipalTypeEnum,
})

export type ServerAzureKeyVaultAccessPolicy = z.infer<typeof ServerAzureKeyVaultAccessPolicySchema>

const ServerAzureKeyVaultIpRuleSchema = z.object({
	value: z.string(),
})

const ServerAzureKeyVaultNetworkAclsSchema = z.object({
	bypass: z.string().nullable(),
	default_action: z.string().nullable(),
	ip_rules: z.array(ServerAzureKeyVaultIpRuleSchema).nullable(),
	virtual_network_rules: z.array(z.unknown()).nullable(),
})

export const ServerAzureKeyVaultSchema = z.object({
	id: z.string().uuid(),
	customer_id: z.string().uuid(),
	vault_id: z.string(),
	sub_env_id: z.string().uuid(),
	subscription_id: z.string().uuid(),
	name: z.string(),
	resource_group: z.string().nullable(),
	type: z.string().nullable(),
	location: z.string().nullable(),
	tags: z.record(z.string(), z.string()).nullable(),
	created_at: DateSchema.nullable(),
	created_by: z.string().nullable(),
	created_by_type: z.string().nullable(),
	last_modified_at: DateSchema.nullable(),
	last_modified_by: z.string().nullable(),
	last_modified_by_type: z.string().nullable(),
	access_policies: z.array(ServerAzureKeyVaultAccessPolicySchema).nullable(),
	network_acls: ServerAzureKeyVaultNetworkAclsSchema.nullable(),
	enabled_for_deployment: z.boolean().nullable(),
	enabled_for_disk_encryption: z.boolean().nullable(),
	enabled_for_template_deployment: z.boolean().nullable(),
	enable_soft_delete: z.boolean().nullable(),
	enable_rbac_authorization: z.boolean().nullable(),
	provisioning_state: z.string().nullable(),
	public_network_access: z.string().nullable(),
})

export type ServerAzureKeyVault = z.infer<typeof ServerAzureKeyVaultSchema>
