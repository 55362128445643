import { z } from 'zod'

export enum SettingsTabNames {
	ISSUE_CONFIG = 'issueConfig',
	INTEGRATIONS = 'integrations',
	ACCOUNT_CONFIG = 'accountConfig',
	NOTIFICATIONS = 'notifications',
}

const defaultSettingsTab: SettingsTabNames = SettingsTabNames.ISSUE_CONFIG

export const SettingsSearchSchema = z.object({
	tab: z.nativeEnum(SettingsTabNames).catch(defaultSettingsTab),
})
