import { Node, NodeProps } from '@xyflow/react'
import { ServerGcpRole } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema'
import { ServerTag } from '../../../../schemas/tags.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BasePermissionNode } from '../common/baseNodes/BasePermissionNode.tsx'

export type GcpRolesNodeType = Node<
	{
		roles: ServerGcpRole[]
	},
	'gcpRoles'
>

export const GcpRolesNode = ({ data: { roles } }: NodeProps<GcpRolesNodeType>) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'gcpRole')

	const badgeTooltipContent = roles.map((role) => {
		const segments = role.name.split('/')
		const lastSegment = segments.pop()
		return <span key={role.name}>{lastSegment || 'Unknown role'}</span>
	})

	const uniqueRiskTags = roles.reduce((tags, role) => {
		role.tags.forEach((tag) => {
			if (tags.find((t) => t.name === tag.name)) {
				return tags
			}

			tags.push(tag)
		})

		return tags
	}, [] as ServerTag[])

	return (
		<BasePermissionNode
			label="GCP Roles"
			isHighlighted={isHighlighted}
			risks={uniqueRiskTags}
			groupProps={{ count: roles.length, tooltipContent: badgeTooltipContent }}
		/>
	)
}
