export const RoutePaths = {
	Any: '/*',
	Index: '/',
	Findings: '/findings',
	Identities: '/identities',
	Metrics: '/metrics',
	Logout: '/logout',
	Identity: '/identities/$identityId',
	Swagger: '/swagger',
	Settings: '/settings',
	Integrations: '/integrations',
	Integration: '/integrations/$integrationEnvironmentType',
	SlackIntegration: '/slack-integration',
	Itdr: '/itdr',
} as const

export type PathWithIssueIdSearch =
	| typeof RoutePaths.Findings
	| typeof RoutePaths.Identities
	| typeof RoutePaths.Identity
	| typeof RoutePaths.Itdr
