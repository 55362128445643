import React from 'react'
import { Tooltip } from 'antd'

interface FormFieldValueRendererProps {
	value: string | React.ReactNode
	disableTooltip?: boolean
}

export const FormFieldValueRenderer: React.FC<FormFieldValueRendererProps> = ({ value, disableTooltip }) => {
	const ValueSpan = (
		<span className="flex-1 text-start overflow-hidden text-ellipsis whitespace-nowrap w-[150px]">{value}</span>
	)

	if (disableTooltip) {
		return ValueSpan
	}

	return (
		<Tooltip placement="topLeft" title={value}>
			{ValueSpan}
		</Tooltip>
	)
}
