import { IdentityActivityStatus, IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { ServerUsageLogGroupedInput } from '../../schemas/identities/groupedUsageLogsSchema.ts'
import { DemoServerIdentityInput } from '../schemas.ts'

export const atlassianUsersDataWithIssues = {
	jira71: {
		id: 'jira71',
		literal: 'amy.ford@synergy.com@synergy.atlassian.net',
		literal_friendly_name: 'Amy Ford',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2023-08-24T17:54:15Z',
		env_type: EnvironmentType.DEMO_ATLASSIAN,
		type: IdentityType.Human,
		source: IdentitySource.DEMO_ATLASSIAN_USER,
		account_literal_friendly_name: 'Synergy Jira',
		account_literal: 'https://synergy.atlassian.net/',
		activity_status: IdentityActivityStatus.INACTIVE,
		tags: [
			{
				id: 'asd-dsa-asd',
				related_resource_type: 'ATLASSIAN',
				name: 'ADMIN',
			},
			{
				id: 'asd-fas-asd',
				related_resource_type: 'ATLASSIAN',
				name: 'DATA_ACCESS',
			},
			{
				id: 'asd-fas-fas',
				related_resource_type: 'ATLASSIAN',
				name: 'RESOURCE_EXPOSURE',
			},
		],
		demo_atlassian_user: {
			name: 'Amy Ford',
			email: 'amy.ford@synergy.com',
			api_tokens: [
				{
					name: 'Amy API access',
					// Next month
					expires_on: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString(),
				},
				{
					name: 'Automation',
					// Last year
					expires_on: new Date(new Date().setDate(new Date().getDate() - 365)).toISOString(),
				},
			],
			admin_api_keys: [
				{
					name: 'Amy admin API',
					// In four months
					expires_on: new Date(new Date().setDate(new Date().getDate() + 122)).toISOString(),
				},
			],
			has_console_access: true,
			jira_project_roles: {
				'synergy-dev': [
					{
						name: 'Admin',
						tags: [
							{
								id: 'asd-dsa-asd',
								related_resource_type: 'ATLASSIAN',
								name: 'ADMIN',
							},
						],
					},
					{
						name: 'Full-access user',
						tags: [
							{
								id: 'asd-fas-asd',
								related_resource_type: 'ATLASSIAN',
								name: 'DATA_ACCESS',
							},
							{
								id: 'asd-fas-fas',
								related_resource_type: 'ATLASSIAN',
								name: 'RESOURCE_EXPOSURE',
							},
						],
					},
				],
			},
			okta_user: {
				id: '92',
				name: 'amy.ford@synergy.com',
			},
			okta_user_xc: {
				activated: '2023-05-18T14:43:35Z',
				profile: {
					displayName: 'Amy Ford',
					email: 'amy.ford@synergy.com',
					title: 'DevOps Engineer',
					firstName: 'Amy',
					lastName: 'Ford',
				},
				status: 'INACTIVE',
				lastLogin: '2023-08-24T17:54:15Z',
				lastUpdated: '2024-04-16T13:36:34.195058Z',
			},
			demo_usage_logs: [
				{
					ip: '41.110.39.172',
					associations: 'Synergy Jira Permissions Service',
					association_type: 'EC2',
					events: 'Jira Update User Permissions',
					events_count: 3,
					key_name: 'Amy admin API',
					first_day: '2023-05-18T14:43:32Z',
					last_day: '2024-02-01T17:54:15Z',
				},
				{
					ip: '5.182.58.142',
					associations: 'Amy Ford',
					association_type: 'HUMAN',
					events: 'Jira Create User',
					events_count: 1,
					key_name: 'Amy API access',
					first_day: '2023-05-18T14:43:32Z',
					last_day: '2024-02-01T17:54:15Z',
				},
			],
		},
	},
	jira72: {
		id: 'jira72',
		literal: 'issue-status-appender-bot@synergy.atlassian.net',
		literal_friendly_name: 'issue-status-appender-bot',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2025-02-01T17:54:15Z',
		env_type: EnvironmentType.DEMO_ATLASSIAN,
		type: IdentityType.Hybrid,
		source: IdentitySource.DEMO_ATLASSIAN_USER,
		account_literal_friendly_name: 'Synergy Jira',
		account_literal: 'https://synergy.atlassian.net/',
		activity_status: IdentityActivityStatus.ACTIVE,
		tags: [
			{
				id: 'asd-fas-asd',
				related_resource_type: 'ATLASSIAN',
				name: 'DATA_ACCESS',
			},
		],
		demo_atlassian_user: {
			name: 'issue-status-appender-bot',
			has_console_access: true,
			oauth_tokens: [
				{
					name: 'ISA Token',
					scopes: ['read:jira-work', 'write:jira-work'],
				},
			],
			jira_project_roles: {
				'synergy-dev': [
					{
						name: 'Issues read-write',
						tags: [
							{
								id: 'asd-fas-asd',
								related_resource_type: 'ATLASSIAN',
								name: 'DATA_ACCESS',
							},
						],
					},
				],
			},
			demo_change_logs: [
				{
					event_time: '2023-05-18T14:43:32Z',
					actor_name: 'Amy Ford',
					action: 'user_invite_to_org',
					attributes: 'synergy',
				},
				{
					event_time: '2023-05-18T14:45:32Z',
					actor_name: 'Amy Ford',
					action: 'user_oauth_token_created',
					attributes: 'ISA Token',
				},
				{
					event_time: '2023-05-19T14:45:32Z',
					actor_name: 'Amy Ford',
					action: 'user_granted_jira_role',
					attributes: 'Issues read-write',
				},
			],
			demo_usage_logs: [
				{
					ip: '52.250.95.168',
					associations: 'GitHub',
					association_type: 'GLOBAL:GITHUB',
					events: 'Jira Create Issue, Jira Update Issue',
					events_count: 5,
					key_name: 'ISA Token',
					first_day: '2023-05-18T14:43:32Z',
					last_day: '2024-02-01T17:54:15Z',
				},
				{
					ip: '52.250.95.174',
					associations: 'GitHub',
					association_type: 'GLOBAL:GITHUB',
					events: 'Jira Create Issue, Jira Update Issue',
					events_count: 5,
					key_name: 'ISA Token',
					first_day: '2024-02-01T17:54:15Z',
					last_day: '2025-02-01T17:54:15Z',
				},
				{
					ip: '5.182.58.142',
					associations: 'Amy Ford',
					association_type: 'HUMAN',
					events: 'Jira Create Issue',
					events_count: 2,
					key_name: 'ISA Token',
					first_day: '2023-05-18T14:43:32Z',
					last_day: '2023-05-19T14:43:32Z',
				},
			],
		},
	},
} satisfies Record<string, DemoServerIdentityInput>

export const atlassianUsersDataWithNoIssues = {
	jira73: {
		noIssueConnectedToIdentity: true,
		id: 'jira73',
		literal: 'alice.jones@synergy.com@synergy.atlassian.net',
		literal_friendly_name: 'Alice Jones',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2025-01-24T17:54:15Z',
		env_type: EnvironmentType.DEMO_ATLASSIAN,
		type: IdentityType.Human,
		source: IdentitySource.DEMO_ATLASSIAN_USER,
		account_literal_friendly_name: 'Synergy Jira',
		account_literal: 'https://synergy.atlassian.net/',
		activity_status: IdentityActivityStatus.ACTIVE,
		demo_atlassian_user: {
			name: 'Alice Jones',
			email: 'alice.jones@synergy.com',
			has_console_access: true,
			jira_project_roles: {
				'synergy-dev': [
					{
						name: 'Read-only user',
					},
				],
			},
		},
	},
	jira74: {
		noIssueConnectedToIdentity: true,
		id: 'jira74',
		literal: 'george.brown@synergy.com@synergy.atlassian.net',
		literal_friendly_name: 'George Brown',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2025-01-24T17:54:15Z',
		env_type: EnvironmentType.DEMO_ATLASSIAN,
		type: IdentityType.Human,
		source: IdentitySource.DEMO_ATLASSIAN_USER,
		account_literal_friendly_name: 'Synergy Jira',
		account_literal: 'https://synergy.atlassian.net/',
		activity_status: IdentityActivityStatus.ACTIVE,
		demo_atlassian_user: {
			name: 'George Brown',
			email: 'george.brown@synergy.com',
			has_console_access: true,
			jira_project_roles: {
				'synergy-dev': [
					{
						name: 'Read-only user',
					},
				],
			},
		},
	},
	jira75: {
		noIssueConnectedToIdentity: true,
		id: 'jira75',
		literal: 'alex.sigdag@synergy.com@synergy.atlassian.net',
		literal_friendly_name: 'Alex Sigdag',
		created_at: '2023-05-18T14:43:32Z',
		updated_at: '2024-04-16T13:36:34.195058Z',
		last_activity: '2025-01-24T17:54:15Z',
		env_type: EnvironmentType.DEMO_ATLASSIAN,
		type: IdentityType.Human,
		source: IdentitySource.DEMO_ATLASSIAN_USER,
		account_literal_friendly_name: 'Synergy Jira',
		account_literal: 'https://synergy.atlassian.net/',
		activity_status: IdentityActivityStatus.ACTIVE,
		demo_atlassian_user: {
			name: 'Alex Sigdag',
			email: 'alex.sigdag@synergy.com',
			has_console_access: true,
			jira_project_roles: {
				'synergy-dev': [
					{
						name: 'Read-only user',
					},
				],
			},
		},
	},
} satisfies Record<string, DemoServerIdentityInput>

export const atlassianGroupedUsageData: Array<{ id: string; usage_logs_grouped: ServerUsageLogGroupedInput[] }> = [
	{
		id: 'jira72',
		usage_logs_grouped: [
			{
				user_identifier: 'issue-status-appender-bot@synergy.atlassian.net',
				key: 'ISA Token',
				associations: [
					{
						name: 'HUMAN',
						instances_count: 1,
						total_events_count: 2,
					},
					{
						name: 'GLOBAL:GITHUB',
						instances_count: 2,
						total_events_count: 10,
					},
				],
				events: {
					'GLOBAL:GITHUB': ['Jira Create Issue', 'Jira Update Issue'],
					HUMAN: ['Jira Create Issue'],
				},
			},
		],
	},
	{
		id: 'jira71',
		usage_logs_grouped: [
			{
				user_identifier: 'amy.ford@synergy.com@synergy.atlassian.net',
				key: 'Amy admin API',
				associations: [
					{
						name: 'EC2',
						instances_count: 1,
						total_events_count: 3,
					},
				],
				events: {
					EC2: ['Jira Update User Permissions'],
				},
			},
			{
				user_identifier: 'amy.ford@synergy.com@synergy.atlassian.net',
				key: 'Amy API access',
				associations: [
					{
						name: 'HUMAN',
						instances_count: 1,
						total_events_count: 1,
					},
				],
				events: {
					HUMAN: ['Jira Create User'],
				},
			},
		],
	},
]
