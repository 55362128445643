import { z } from 'zod'

export const ServerAzureTenantSchema = z.object({
	tenant_id: z.string(),
	tenant_name: z.string(),
})

export type ServerAzureTenantInput = z.input<typeof ServerAzureTenantSchema>
export type ServerAzureTenant = z.infer<typeof ServerAzureTenantSchema>

export const ServerAzureSubscriptionSchema = z.object({
	subscription_id: z.string(),
	subscription_name: z.string(),
})

export type ServerAzureSubscriptionInput = z.input<typeof ServerAzureSubscriptionSchema>
export type ServerAzureSubscription = z.infer<typeof ServerAzureSubscriptionSchema>

export const ServerAzureVirtualMachineSchema = z.object({
	name: z.string(),
	status: z.union([z.literal('Running'), z.literal('Stopped')]),
	resource_group: z.string(),
	location: z.string(),
	tenant: ServerAzureTenantSchema,
	subscription: ServerAzureSubscriptionSchema,
	public_ip: z.string().nullish(),
})

export type ServerAzureVirtualMachineInput = z.input<typeof ServerAzureVirtualMachineSchema>
export type ServerAzureVirtualMachine = z.infer<typeof ServerAzureVirtualMachineSchema>
