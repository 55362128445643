import { NodeProps, Node } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'
import { DemoAtlassianOAuthToken } from '../../../../schemas/identities/demoAtlassian/demoAtlassianUserSchema.ts'

export type DemoAtlassianOAuthTokenNodeType = Node<DemoAtlassianOAuthToken, 'atlassianOAuthToken'>

type DemoAtlassianOAuthTokenNodeProps = NodeProps<DemoAtlassianOAuthTokenNodeType>

export const DemoAtlassianOAuthTokenNode = ({ data: { name, scopes } }: DemoAtlassianOAuthTokenNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'atlassianOAuthToken' && highlightedNode.id === name,
	)

	const tooltipText = (
		<div className="flex flex-col">
			<span className="font-medium mb-1">OAuth Scopes:</span>
			{scopes.map((scope) => (
				<span key={scope} className="font-mono">
					{scope}
				</span>
			))}
		</div>
	)

	return <BaseCredentialsNode isHighlighted={isHighlighted} label="OAuth Token" name={{ text: name, tooltipText }} />
}
