import { Node, NodeProps } from '@xyflow/react'
import JiraIcon from '../../../../assets/jira_icon.svg?react'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode.tsx'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'

export type DemoJiraProjectNodeType = Node<{ projectName: string }, 'jiraProject'>
type DemoJiraProjectNodeProps = NodeProps<DemoJiraProjectNodeType>

export const DemoJiraProjectNode = ({ data: { projectName } }: DemoJiraProjectNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'jiraProject' && highlightedNode.id === projectName,
	)

	return (
		<BaseEnvironmentNode
			label="Jira Project"
			name={{ text: projectName }}
			icon={<JiraIcon />}
			isHighlighted={isHighlighted}
		/>
	)
}
