import React from 'react'
import { SwapOutlined } from '@ant-design/icons'

interface IntegrationIconProps {
	leftIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>
	rightIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
	iconSize?: number
}

export const IntegrationIcon: React.FC<IntegrationIconProps> = ({
	leftIcon: LeftIcon,
	rightIcon: RightIcon,
	iconSize = 24,
}) => {
	return (
		<div className="flex items-center gap-1">
			<LeftIcon width={iconSize} height={iconSize} />
			{RightIcon && (
				<>
					<SwapOutlined className="text-textIcon-tertiary" />
					<RightIcon width={iconSize} height={iconSize} />
				</>
			)}
		</div>
	)
}
