import cx from 'classnames'
import { RemediationType } from '../../../../../schemas/issue.ts'
import { RemediationTypeToDisplayName, RemediationTypeToIcon } from './remediationTypeMapping.ts'

type AIRemediationTargetPlatformProps = {
	remediationType: RemediationType
	isSelected?: boolean
	onClick: () => void
}

export const AIRemediationTargetPlatform = ({
	remediationType,
	isSelected,
	onClick,
}: AIRemediationTargetPlatformProps) => {
	const label = RemediationTypeToDisplayName[remediationType]
	const Icon = RemediationTypeToIcon[remediationType]
	const className = cx(
		'flex min-w-32 px-2 py-1 gap-2 items-center rounded cursor-pointer hover:bg-surface-tertiary',
		{
			'bg-surface-brand hover:bg-surface-brand': isSelected,
		},
	)

	const labelClassName = cx('font-medium', { 'text-textIcon-brand': isSelected })
	return (
		<div className={className} onClick={onClick}>
			<Icon className="w-4 h-4" />
			<span className={labelClassName}>{label}</span>
		</div>
	)
}
