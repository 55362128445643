import { ServerIdentity } from '../../../schemas/identity'
import { ServerKubernetesResourceXc } from '../../../schemas/identities/kubernetes/kubernetesResourceXcSchema'
import { ServerAwsEc2InstanceXc } from '../../../schemas/identities/awsEc2InstanceXcSchema'
import { useHighlightedNodesContext } from '../HighlightedNodesContext'
import { KubernetesResourcesTable } from './tables/kubernetes/KubernetesResourcesTable.tsx'
import { Collapse, CollapseProps } from 'antd'
import GoogleWorkspaceIcon from '../../../assets/google_workspace_logo_16.svg?react'
import { Ec2InstancesTable } from './tables/aws/Ec2InstancesTable.tsx'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import KubernetesIcon from '../../../assets/kubernetes_icon_20.svg?react'
import MachineIcon from '../../../assets/machine_icon_20.svg?react'
import ServerIcon from '../../../assets/server_icon_black_20.svg?react'
import AwsIcon from '../../../assets/aws_icon_16.svg?react'
import AzureVmIcon from '../../../assets/azure_virtual_machine.svg?react'
import { DemoAzureVirtualMachineTable } from './tables/azure/DemoAzureVirtualMachineInstance.tsx'
import { ServerEndpointAccessXc } from '../../../schemas/identities/endpointAccessXcSchema'
import { EndpointAccessContextTable } from './tables/EndpointAccessTable.tsx'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel'
import { DemoAdEndpointsTable } from './tables/activeDirectory/DemoAdEndpointsTable'
import { DemoAdServersTable } from './tables/activeDirectory/DemAdServersTable.tsx'
import { GcpSaDependenciesTable } from './tables/gcp/GcpSaDependenciesTable.tsx'
import lodash from 'lodash'
import {
	GcpResourceType,
	GcpResourceTypeIconMap,
	GcpResourceTypeMap,
} from '../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'
import { GoogleWorkspaceOAuthAppsTable } from './tables/gcp/GoogleWorkspaceOAuthAppsTable.tsx'
import { CellMouseOverEvent } from 'ag-grid-enterprise'
import { ServerGoogleWorkspaceUserDemoOauthAppsXc } from '../../../schemas/identities/googleWorkspaceUserSchema.ts'
import { LastSelectedNode } from '../IdentityPageBody.tsx'

export type DependenciesCollapseItemKeys =
	| 'kubernetes'
	| 'ec2'
	| 'azureVms'
	| 'endpointAccess'
	| 'demoAdEndpoints'
	| 'demoAdServers'
	| `gcpSaResources-${GcpResourceType}`
	| 'googleWorkspaceOauthApps'

type DependenciesCollapseItem = Required<CollapseProps>['items'][number] & {
	key: DependenciesCollapseItemKeys
}

type SidePanelDependenciesProps = {
	identity: ServerIdentity
	lastSelectedNode?: LastSelectedNode | null
	activeDependenciesKeys: DependenciesCollapseItemKeys[]
	setActiveDependenciesKeys: Dispatch<SetStateAction<DependenciesCollapseItemKeys[]>>
}

export const SidePanelDependencies = ({
	identity,
	lastSelectedNode,
	activeDependenciesKeys,
	setActiveDependenciesKeys,
}: SidePanelDependenciesProps) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList =
				typeof key === 'string'
					? [key as DependenciesCollapseItemKeys]
					: (key as DependenciesCollapseItemKeys[])
			setActiveDependenciesKeys(keyList)
		},
		[setActiveDependenciesKeys],
	)

	const kubernetesResourcesXc: ServerKubernetesResourceXc[] =
		identity.aws_iam_user?.kubernetes_resources_xc ||
		identity.aws_iam_role?.kubernetes_resources_xc ||
		identity.databricks_service_principal?.kubernetes_resources_xc ||
		identity.entra_id_service_principal?.kubernetes_resources_xc ||
		identity.gcp_service_account?.kubernetes_resources_xc ||
		identity.postgres_role?.kubernetes_resources_xc ||
		[]
	const awsEc2InstancesXc: ServerAwsEc2InstanceXc[] =
		identity.aws_iam_role?.aws_ec2_instances_xc ||
		identity.aws_key_pair?.aws_ec2_instances_xc ||
		identity.github_user?.aws_ec2_instances_xc ||
		identity.postgres_role?.aws_ec2_instances_xc ||
		[]
	const endpointAccessXc: ServerEndpointAccessXc[] =
		identity.gcp_service_account?.endpoint_access_xc || identity.github_user?.endpoint_access_xc || []
	const azureVms =
		identity.entra_id_service_principal?.virtual_machines || identity.postgres_role?.virtual_machines || []
	const adEndpoints = identity.active_directory_user?.demo_endpoints_dependencies || []
	const adServers = identity.active_directory_user?.demo_servers_dependencies || []
	const gcpSaResources = identity.gcp_service_account?.service_account_resources_xc || []
	const googleWorkspaceOAuthApps = identity.google_workspace_user?.demo_oauth_apps_xc || []

	const items: DependenciesCollapseItem[] = useMemo(() => {
		const ret: DependenciesCollapseItem[] = []
		if (kubernetesResourcesXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Kubernetes'} icon={KubernetesIcon} />,
				key: 'kubernetes',
				children: <KubernetesResourcesTable kubernetesResourcesXc={kubernetesResourcesXc} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'kubernetesResources', id: '' }])
				},
			})
		}

		if (awsEc2InstancesXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'EC2 Instances'} icon={AwsIcon} />,
				key: 'ec2',
				children: <Ec2InstancesTable awsEc2InstancesXc={awsEc2InstancesXc} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'ec2Instances', id: '' }])
				},
			})
		}

		if (endpointAccessXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Endpoint Access'} icon={MachineIcon} />,
				key: 'endpointAccess',
				children: <EndpointAccessContextTable endpointAccessXc={endpointAccessXc} />,
			})
		}

		if (azureVms.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Virtual Machines'} icon={AzureVmIcon} />,
				key: 'azureVms',
				children: <DemoAzureVirtualMachineTable azureVirtualMachines={azureVms} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'azureVirtualMachines', id: '' }])
				},
			})
		}

		if (adEndpoints.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Endpoints'} icon={MachineIcon} />,
				key: 'demoAdEndpoints',
				children: <DemoAdEndpointsTable data={adEndpoints} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'demoAdEndpoints', id: '' }])
				},
			})
		}

		if (adServers.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Servers'} icon={ServerIcon} />,
				key: 'demoAdServers',
				children: <DemoAdServersTable data={adServers} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'demoAdServers', id: '' }])
				},
			})
		}

		if (gcpSaResources.length) {
			const groupedResources = lodash.groupBy(gcpSaResources, 'type')

			Object.entries(groupedResources).forEach(([resourceType, resources]) => {
				ret.push({
					label: (
						<CollapsibleItemLabel
							label={GcpResourceTypeMap[resourceType as GcpResourceType]}
							icon={GcpResourceTypeIconMap[resourceType as GcpResourceType]}
						/>
					),
					key: `gcpSaResources-${resourceType as GcpResourceType}`,
					headerClass: '!items-center',
					children: <GcpSaDependenciesTable gcpSaResourceData={resources} />,
				})
			})
		}

		if (googleWorkspaceOAuthApps?.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Google Workspace OAuth Apps'} icon={GoogleWorkspaceIcon} />,
				key: 'googleWorkspaceOauthApps',
				children: (
					<GoogleWorkspaceOAuthAppsTable
						data={googleWorkspaceOAuthApps}
						lastSelectedNode={lastSelectedNode?.type === 'gwOAuthApp' ? lastSelectedNode.id : undefined}
						onCellMouseOver={(event: CellMouseOverEvent<ServerGoogleWorkspaceUserDemoOauthAppsXc>) => {
							setHighlightedNodes([{ type: 'gwOAuthApp', id: event.node.id! }])
						}}
						onCellMouseOut={() => setHighlightedNodes([])}
					/>
				),
			})
		}

		return ret
	}, [
		kubernetesResourcesXc,
		awsEc2InstancesXc,
		endpointAccessXc,
		azureVms,
		adEndpoints,
		adServers,
		gcpSaResources,
		googleWorkspaceOAuthApps,
		setHighlightedNodes,
	])

	if (
		!kubernetesResourcesXc.length &&
		!awsEc2InstancesXc.length &&
		!azureVms.length &&
		!endpointAccessXc.length &&
		!adEndpoints.length &&
		!adServers.length &&
		!gcpSaResources.length &&
		!googleWorkspaceOAuthApps.length
	) {
		return 'No dependencies data'
	}

	return (
		<div
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			<Collapse
				onChange={onCollapseChange}
				activeKey={activeDependenciesKeys}
				className="bg-surface-primary"
				expandIconPosition={'end'}
				items={items}
			/>
		</div>
	)
}
